export enum LabelEntityType {
  UNKNOWN_ENTITY_TYPE = 'UNKNOWN_ENTITY_TYPE',
  Unknown = 'UNKNOWN',
  Address = 'ADDRESS',
  Transaction = 'TRANSACTION',
  Block = 'BLOCK',
  Url = 'URL'
}

// Backend plans
export enum SubscriptionType {
  General = 'general',
  SybilDefender = 'sybil_defender',
  RugPull = 'rug_pull',
  SpamFeed = 'spam_feed',
  AttackFeed = 'attack_feed',
  ScamFeed = 'scam_feed'
}

// Plans that don't exist on the backend
export enum FrontendOnlySubscriptionType {
  MaliciousBytecodeFeed = 'malicious_bytecode_feed'
}

export enum BotProtocolVersion {
  V0 = 0, // V1 and External bots
  V2 = 2 // V2 Bots
}

export enum BotType {
  V1 = 'V1',
  V2 = 'V2',
  External = 'External'
}
