import urlRegex from 'url-regex';
import { animateScroll } from 'react-scroll';
import { isAddress as _isAddress } from '@ethersproject/address';

export function extractUrl(text: string): string | undefined {
  return text.match(urlRegex({ strict: false }))?.[0];
}

export const isAddress = (address: string): boolean => {
  return _isAddress(address);
};

export const isSHA256Hash = (hash: string): boolean => {
  return /\b[A-Fa-f0-9]{64}\b/.test(hash);
};

export const is0xHash = (hash: string, chars = 64): boolean => {
  return (
    hash.slice(0, 2) === '0x' &&
    new RegExp(`\\b[A-Fa-f0-9]{${chars}}\\b`).test(hash.slice(2))
  );
};

export const isTransactionId = (txId: string): boolean => {
  return is0xHash(txId);
};

export const isAgentId = (agentId: string): boolean => {
  return is0xHash(agentId);
};

export function isDeveloperId(hash: string): boolean {
  return is0xHash(hash, 40);
}

export const isScanNodeId = (nodeId: string): boolean => {
  return is0xHash(nodeId, 40);
};

export const isAlertId = (alertId: string): boolean => {
  return is0xHash(alertId);
};

export const convertMagnitude = (value: number): string => {
  if (value > 1e6) {
    return (value / 1e6).toFixed(1) + 'm';
  }
  if (value > 1e3) {
    return (value / 1e3).toFixed(0) + 'k';
  }
  return value.toString();
};

export const MINUTE_TIME = 1000 * 60;
export const DAY_TIME = MINUTE_TIME * 60 * 24;

export const shortenHash = (hash: string, symbols = 4): string =>
  `${hash.slice(0, 2 + symbols)}...${hash.slice(-symbols)}`;

export const getRepositoryHref = (_url?: string): string => {
  let url = _url || '';
  url = url.replace('http://', 'https://');
  url = url.startsWith('https://') ? url : `https://${url}`;
  if (!url) return '';
  if (
    url.startsWith('https://github.com/') ||
    url.startsWith('https://gitlab.com/')
  )
    return url;
  return '';
};

export const delay = (ms: number): Promise<unknown> =>
  new Promise((res) => setTimeout(res, ms));

export async function retry<T>(
  fn: () => Promise<T>,
  opts?: { attempts?: number; wait?: number }
): Promise<T> {
  const { attempts = 3, wait = 5 * 1000 } = opts || {};
  let attempt = 1;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      return await fn();
    } catch (e: unknown) {
      // eslint-disable-next-line no-console
      console.error(`Attempt (${attempt}/${attempts}):`, e);
      if (attempt >= attempts) {
        throw e;
      }
      attempt++;
      await delay(wait);
    }
  }
}

// https://stackoverflow.com/a/53758827
export function shuffle<T>(arr: T[], seed: number): T[] {
  let m = arr.length;
  let t;
  let i;

  const random = (seed: number): number => {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };

  // while there remain elements to shuffle…
  while (m) {
    // pick a remaining element…
    i = Math.floor(random(seed) * m--);

    // and swap it with the current element.
    t = arr[m];
    arr[m] = arr[i];
    arr[i] = t;
    ++seed;
  }

  return arr;
}

export function scrollToElement(id: string, yOffset = -24, minDiff = 80): void {
  const element = document.getElementById(id);
  if (element) {
    const elementTop = element.getBoundingClientRect().top;
    const top = Math.max(0, elementTop + window.scrollY + yOffset);
    if (Math.abs(elementTop) >= minDiff) animateScroll.scrollTo(top);
  }
}

export function formatPoolId(id: string): string {
  return id.padStart(4, '0');
}

export function capitalize(input: string): string {
  return input
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function parseMetadata(
  stringifiedJson = '{}'
): Record<string, string | Record<string, unknown>> {
  let metadata: Record<string, string | Record<string, unknown>> = {};
  try {
    metadata = JSON.parse(stringifiedJson || '{}');
  } catch (e) {
    console.error(e);
  }

  for (const [key, value] of Object.entries(metadata || {})) {
    if (
      typeof value === 'string' &&
      (value.indexOf('[') === 0 || value.indexOf('{') === 0)
    ) {
      try {
        metadata[key] = JSON.parse(value);
      } catch (e) {
        console.error(e);
        // ignore
      }
    }
  }

  return metadata || {};
}

export function formatFileSize(bytes: number): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export function readFileAsync(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result as string);
    };

    reader.onerror = reject;

    reader.readAsText(file);
  });
}

export function sanitizeUrl(url: string): string {
  // Regex for valid domain and TLD
  const domainPattern = /^([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

  // List of allowed protocols
  const allowedProtocols = ['http:', 'https:'];

  // Default protocol to use if none is provided
  const defaultProtocol = 'https:';

  let urlToCheck = url.trim();

  // Check if the URL starts with a protocol
  const hasProtocol = /^[a-z]+:\/\//.test(urlToCheck);

  if (!hasProtocol) {
    // If no protocol, check if it's a valid domain
    if (domainPattern.test(urlToCheck)) {
      // Prepend the default protocol
      urlToCheck = `${defaultProtocol}//${urlToCheck}`;
    } else {
      // Not a valid domain without protocol
      return '';
    }
  }

  try {
    // Parse the URL
    const parsedUrl = new URL(urlToCheck);

    // Check if the protocol is allowed
    if (!allowedProtocols.includes(parsedUrl.protocol)) {
      return '';
    }

    // Reconstruct the URL
    return parsedUrl.toString();
  } catch (error) {
    console.error('Invalid URL:', error);
    return '';
  }
}
