import React from 'react';
import cn from 'classnames';

import Scanner, { ScannerEntityLinkProps } from '../Scanner/Scanner';

import { formatPoolId } from 'common/lib/utils';

type PoolEntityProps = ScannerEntityLinkProps & {
  chainId: number;
  id: string;
  className?: string;
};

function PoolEntity(props: PoolEntityProps): JSX.Element {
  const { to, href, target, chainId, id, className } = props;

  return (
    <Scanner
      to={to}
      href={href}
      target={target}
      chainId={chainId}
      className={cn('PoolEntity', className)}
    >
      {typeof id === 'string' ? formatPoolId(id) : id}
    </Scanner>
  );
}

export default PoolEntity;
