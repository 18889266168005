import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useMemo
} from 'react';
import cn from 'classnames';

import BaseButton from '../Button/BaseButton';

import './Menu.scss';

const MenuContext = React.createContext<{ onClose?: () => unknown }>({
  onClose: undefined
});

type MenuProps = {
  children: ReactNode;
  onClose?: () => unknown;
  className?: string;
};

type MenuSubComponents = {
  Item: typeof MenuItem;
};

type MenuComponentType = FunctionComponent<MenuProps> & MenuSubComponents;

const Menu: MenuComponentType = function Menu(props: MenuProps): JSX.Element {
  const { children, onClose, className } = props;

  const context = { onClose };

  return (
    <MenuContext.Provider value={context}>
      <ul role="menu" className={cn('MenuList', className)}>
        {children}
      </ul>
    </MenuContext.Provider>
  );
};

type MenuItemProps = {
  children: ReactNode;
  href?: string;
  to?: string;
  onClick?: () => unknown;
  closeOnClick?: boolean;
  className?: string;
  disabled?: boolean;
};

function MenuItem(props: MenuItemProps): JSX.Element {
  const {
    to,
    href,
    closeOnClick = true,
    children,
    className,
    disabled
  } = props;
  const { onClose } = useContext(MenuContext);

  const onClick = useMemo(() => {
    if (!props.onClick || !closeOnClick) return props.onClick;

    return function handler() {
      if (props.onClick) props.onClick();
      if (onClose) onClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClick, onClose, closeOnClick]);

  return (
    <li role="menuitem" className={cn('MenuListItem', className)}>
      <BaseButton
        type="button"
        to={to}
        href={href}
        onClick={onClick}
        className="MenuListItem__action"
        disabled={disabled}
      >
        {children}
      </BaseButton>
    </li>
  );
}

Menu.Item = MenuItem;

export default Menu;
