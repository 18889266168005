import {
  Buffer,
  Container,
  DataGrid,
  Divider,
  FortEntry,
  Row,
  Subtitle,
  TextEntry,
  Title
} from './ScannerPoolInfoBox';
import { Allocation } from './AllocateStakeButton';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import CommissionDisplay from './CommissionDisplay';

// type socialOptions = 'discord' | 'twitter' | 'web' | 'telegram';

// type socialMappings = {
//   [K in socialOptions]+?: string;
// };

// interface SocialsGroupProps {
//   socials: socialMappings;
// }

// function SocialsGroup(props: SocialsGroupProps): JSX.Element {
//   const imageSrcMap: { [K in socialOptions as string]+?: JSX.Element } = {
//     discord: discordIcon,
//     twitter: twitterIcon,
//     web: webIcon,
//     telegram: telegramIcon
//   };

//   if (props.socials) {
//     return (
//       <>
//         {Object.keys(props.socials).map<JSX.Element>((social, index) => {
//           return (
//             <SocialItem key={index}>
//               <Icon>{imageSrcMap[social]}</Icon>
//               <Value>{props.socials[social as socialOptions]}</Value>
//               <Edit />
//             </SocialItem>
//           );
//         })}
//       </>
//     );
//   } else {
//     return <></>;
//   }
// }

export default function ScannerPoolInfo(props: {
  allocation: Allocation;
  scannerPool: ScannerPool;
}): JSX.Element {
  const { allocation, scannerPool } = props;
  return (
    <Row>
      {/* <Container>
        <Title>About</Title>
        <Description>
          I am an ethereum developer and forta node runner. In my free time I
          enjoy hiking, skiing, and scanning for MEV
        </Description>
        <SocialItem>
          <Icon>
            <img alt="loading..." src="/forta_asterisk_light_64.png"></img>
          </Icon>
          <Value>Owned by someone.eth</Value>
        </SocialItem>
        <Divider />
        <SocialsGroup
          socials={{
            web: 'https://developermessaiah.org',
            discord: 'developooor#3874',
            twitter: '@devking',
            telegram: '@devking2'
          }}
        />
      </Container> */}
      <Container>
        <Title>Position</Title>
        <Divider />
        <DataGrid>
          <Subtitle>Active Since</Subtitle>
          <Subtitle>Commission</Subtitle>
          <TextEntry>-</TextEntry>
          <TextEntry>
            <CommissionDisplay pool={scannerPool} />
          </TextEntry>
          <Buffer />
          <Subtitle>Staking</Subtitle>
          <TextEntry />
          <TextEntry>Own Stake</TextEntry>
          <FortEntry value={allocation.stakeOwned} />
          <TextEntry>Delegated</TextEntry>
          <FortEntry value={allocation.stakeDelegated} />
          <TextEntry>Allocated</TextEntry>
          <FortEntry value={allocation.stakeAllocated} />
          <TextEntry>Total Staked</TextEntry>
          <FortEntry
            value={allocation.stakeOwned.add(allocation.stakeDelegated)}
          />
        </DataGrid>
      </Container>
    </Row>
  );
}
