import React, { ChangeEvent } from 'react';
import cn from 'classnames';

import { FormElementProps } from '../form';

import InputComponent, {
  InputComponentRenderProps
} from './Component/InputComponent';
import { calcPadding, PADDING_X } from './utils';
import './Input.scss';

import Button from 'common/components-v2/Button/Button';
import { CrossIcon } from 'common/components/Icons';

export enum InputVariant {
  Dark = 'dark',
  Light = 'light',
  Gray = 'gray',
  DarkBig = 'dark-big'
}

export type InputProps = FormElementProps<string> & {
  type?: string;
  variant: 'dark' | 'light' | 'gray' | 'dark-big';
  placeholder?: string;
  pattern?: string;
  icon?: {
    left?: JSX.Element;
    right?: JSX.Element;
  };
  disabled?: boolean;
  resettable?: boolean;
  invalid?: boolean;
  components?: {
    left?: InputComponentRenderProps[];
    right?: InputComponentRenderProps[];
  };
  className?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  props,
  ref
): JSX.Element {
  const {
    name,
    type,
    variant,
    icon,
    placeholder,
    pattern,
    invalid = false,
    disabled = false,
    resettable = false,
    className,
    onBlur,
    onChange,
    value,
    components,
    ...restProps
  } = props;

  const normalizedValue = value || '';
  const normalizedComponents = {
    left: [...(components?.left || [])],
    right: [...(components?.right || [])]
  };

  if (icon) {
    const getIconComponent = (
      element: JSX.Element,
      position: 'left' | 'right'
    ): InputComponentRenderProps => ({
      width: 20,
      noMouse: true,
      marginLeft: position === 'left' ? PADDING_X : undefined,
      marginRight: position === 'right' ? PADDING_X : undefined,
      render: function InputIcon(): JSX.Element {
        return <div className="TextField__icon">{element}</div>;
      }
    });

    if (icon.left) {
      normalizedComponents.left.unshift(getIconComponent(icon.left, 'left'));
    }

    if (icon.right) {
      normalizedComponents.right.unshift(getIconComponent(icon.right, 'right'));
    }
  }

  if (resettable && normalizedValue) {
    normalizedComponents.right.push({
      width: 36,
      render: function InputButton(): JSX.Element {
        return (
          <Button
            size="sm"
            type="button"
            icon={CrossIcon}
            variant="tertiary"
            testId="reset-button"
            className="TextField__clear-button"
            onClick={() => props.onChange({ target: { name, value: '' } })}
          />
        );
      }
    });
  }

  const inputStyle = {
    paddingLeft: calcPadding(normalizedComponents.left, 'left', PADDING_X),
    paddingRight: calcPadding(normalizedComponents.right, 'right', PADDING_X)
  };

  const classNames = cn('TextField', 'TextField--' + variant, className, {
    'TextField--invalid': invalid,
    'TextField--disabled': disabled
  });

  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    if (onChange) {
      onChange({
        target: {
          name,
          value: e.target.value
        }
      });
    }
  }

  return (
    <div className={classNames}>
      <div className="TextField__container">
        {normalizedComponents.left.map((component, i) => (
          <InputComponent
            key={i}
            position="left"
            component={component}
            previousComponents={normalizedComponents.left.slice(0, i)}
            inputState={{ invalid, value: normalizedValue }}
          />
        ))}
        <input
          ref={ref}
          type={type}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          style={inputStyle}
          value={normalizedValue}
          pattern={pattern}
          className="TextField__input"
          onChange={handleChange}
          onBlur={onBlur}
          {...restProps}
        />
        {normalizedComponents.right.map((component, i) => (
          <InputComponent
            key={i}
            position="right"
            component={component}
            previousComponents={normalizedComponents.right.slice(0, i)}
            inputState={{ invalid, value: normalizedValue }}
          />
        ))}
      </div>
    </div>
  );
});

export type { InputComponentRenderProps };

export default Input;
