import React from 'react';
import cn from 'classnames';
import ReactJson from '@microlink/react-json-view';

import './Metadata.scss';

type MetadataProps = {
  collapsed?: number | boolean;
  value: Record<string, unknown>;
  className?: string;
};

const Metadata = React.forwardRef<HTMLElement, MetadataProps>(function Metadata(
  props: MetadataProps,
  ref
): JSX.Element {
  const { value, collapsed = 2, className } = props;

  return (
    <div
      ref={ref as React.Ref<HTMLDivElement>}
      className={cn('Metadata', className)}
    >
      <ReactJson
        src={value}
        collapsed={collapsed}
        name={false}
        style={{
          fontSize: 14,
          background: 'transparent'
        }}
        displayDataTypes={false}
        displayObjectSize={false}
        indentWidth={4}
        iconStyle="square"
        theme="monokai"
      />
    </div>
  );
});

export default Metadata;
