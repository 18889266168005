import { useCallback, useEffect, useMemo, useState } from 'react';
import { BigNumber, providers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { toast } from 'react-toastify';
import BigNumberJs from 'bignumber.js';

import './StakingForm.scss';

import StakingContract, {
  getSubjectTypeLabel,
  SubjectType
} from 'forta-app/lib/contract-interactors/stakingContract';
import { SubjectResult } from 'forta-app/lib/apis/subgraphAPI';
import InformationBox from 'common/components/InformationBox';
import { formatFORT } from 'forta-app/lib/utils';
import { Allocation } from '../scan-node-pool/AllocateStakeButton';
import { shortenHash } from 'common/lib/utils';
import CopyButton from 'common/components/CopyButton';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import CommissionDisplay from '../scan-node-pool/CommissionDisplay';
import DepositForm from '../delegations/DepositForm/DepositForm';
import useDepositForm from '../delegations/DepositForm/useDepositForm';
import useWallet from 'common/hooks/useWallet';
import config from 'common/config';

function getDelegationSubject(subjectType: SubjectType): SubjectType {
  if (subjectType === SubjectType.SCANNERPOOL)
    return SubjectType.SCANNERPOOL_DELEGATOR;
  else return subjectType;
}

interface StakerFormProps {
  subject: SubjectResult;
  delegatedStakingProps?: DelegatedFormProps;
  onSuccess: (tx?: providers.TransactionResponse) => void;
}

export interface DelegatedFormProps {
  allocation: Allocation;
  commission: string;
  owner: string;
  pool: ScannerPool;
}

interface CommonFormProps {
  subject: SubjectResult;
  delegatedStakingProps?: DelegatedFormProps;

  allowance: BigNumber;
  balance: BigNumber | null;
  isLoading: boolean;
  inputValue: string;
  currentUserShares: BigNumber;
  handleInputChange: (value: string) => void;
  handleApprove: () => Promise<providers.TransactionResponse>;
  handleApproveDone: () => unknown;
  handleDeposit: () => Promise<providers.TransactionResponse>;
  handleDepositDone: () => unknown;
}

interface DelegatedStakingViewProps
  extends Omit<CommonFormProps, 'currentUserShares'> {
  delegatedStakingProps: DelegatedFormProps;
}

export function StakingForm({
  subject,
  delegatedStakingProps,
  onSuccess
}: StakerFormProps): JSX.Element {
  const {
    web3React,
    address: walletAddress,
    signed,
    ensureConnection
  } = useWallet();

  const {
    inputValue,
    inputValueWei,
    balance,
    allowance,
    fetchBalance,
    fetchAllowance,
    onInputValueChange
  } = useDepositForm({
    contractAddress: config.stakingContractAddress,
    walletAddress: walletAddress
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userShares, setUserShares] = useState<BigNumber>(BigNumber.from(0));

  const stakingContract = useMemo(
    () => new StakingContract(web3React),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3React.account, web3React.chainId]
  );

  const fetchUserShares = useCallback(async () => {
    if (!(signed && subject.id)) return;

    try {
      setIsLoading(true);
      const userShares = await stakingContract.getSharesOf(
        subject.subjectType,
        subject.id,
        web3React.account || ''
      );
      setUserShares(userShares);
      setIsLoading(false);
    } catch (err) {
      toast.warn('Unable to get staking information');
      setIsLoading(false);
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signed, subject.id]);

  const handleApprove =
    useCallback(async (): Promise<providers.TransactionResponse> => {
      await ensureConnection();
      return await stakingContract.approveTokens(inputValueWei);
    }, [ensureConnection, inputValueWei, stakingContract]);

  const handleApproveDone = useCallback(async () => {
    fetchAllowance();
  }, [fetchAllowance]);

  const handleDeposit =
    useCallback(async (): Promise<providers.TransactionResponse> => {
      await ensureConnection();
      const subjectType = delegatedStakingProps
        ? getDelegationSubject(subject.subjectType)
        : subject.subjectType;
      return stakingContract.deposit(subjectType, subject.id, inputValueWei);
    }, [
      ensureConnection,
      subject,
      delegatedStakingProps,
      inputValueWei,
      stakingContract
    ]);

  const handleDepositDone = useCallback(async () => {
    fetchUserShares();
    fetchAllowance();
    fetchBalance();
    onSuccess();
  }, [fetchAllowance, fetchBalance, fetchUserShares, onSuccess]);

  useEffect(() => {
    fetchUserShares();
    fetchAllowance();
    fetchBalance();
  }, [fetchAllowance, fetchBalance, fetchUserShares]);

  return delegatedStakingProps ? (
    <DelegatedStakingView
      subject={subject}
      balance={balance}
      allowance={allowance}
      isLoading={isLoading}
      inputValue={inputValue}
      delegatedStakingProps={delegatedStakingProps}
      handleInputChange={onInputValueChange}
      handleApprove={handleApprove}
      handleApproveDone={handleApproveDone}
      handleDeposit={handleDeposit}
      handleDepositDone={handleDepositDone}
    />
  ) : (
    <StakingView
      subject={subject}
      balance={balance}
      allowance={allowance}
      isLoading={isLoading}
      inputValue={inputValue}
      currentUserShares={userShares}
      handleApprove={handleApprove}
      handleApproveDone={handleApproveDone}
      handleDeposit={handleDeposit}
      handleDepositDone={handleDepositDone}
      handleInputChange={onInputValueChange}
    />
  );
}

const StakingView = (props: CommonFormProps): JSX.Element => {
  const {
    subject,
    inputValue,
    balance,
    currentUserShares,
    isLoading,
    allowance,
    handleApprove,
    handleApproveDone,
    handleDeposit,
    handleDepositDone,
    handleInputChange
  } = props;

  const inputValueBN = useMemo(() => {
    try {
      return parseEther(inputValue || '0');
    } catch (e) {
      console.error(e);
      return BigNumber.from(0);
    }
  }, [inputValue]);

  const inputPercentage =
    !BigNumber.from(subject.activeStake || 0).isZero() || Number(inputValue)
      ? inputValueBN
          .mul(100)
          .div(BigNumber.from(subject.activeStake || 0).add(inputValueBN))
          .toNumber()
      : 0;

  const userShare =
    Number(
      currentUserShares
        .mul(10000)
        .div(
          BigNumber.from(subject.activeShares || 0).isZero()
            ? 1
            : BigNumber.from(subject.activeShares || 0)
        )
    ) / 100;

  return (
    <div className="StakeModal__stake-form">
      <div className="StakeModal__description">
        Enter the amount of FORT you want to stake on behalf of this{' '}
        {getSubjectTypeLabel(subject.subjectType).toLocaleLowerCase()}.
      </div>
      <div className="StakingForm__content">
        <div className="StakingForm__information">
          <InformationBox.Container className="StakingForm__infobox">
            <InformationBox.Title>Total • Active Stake</InformationBox.Title>
            <InformationBox.Value>
              {formatFORT(subject.activeStake)} <small>FORT</small>
            </InformationBox.Value>
            <InformationBox.Group>
              <InformationBox.Item>
                <InformationBox.Label>Your Active Stake</InformationBox.Label>
                <InformationBox.Value>
                  {formatFORT(
                    new BigNumberJs(subject.activeStake || 0)
                      .multipliedBy(Math.floor(userShare * 1000))
                      .div(100)
                      .div(1000)
                  )}{' '}
                  <small>FORT</small>
                </InformationBox.Value>
              </InformationBox.Item>
              <InformationBox.Item>
                <InformationBox.Label>Your Active Share</InformationBox.Label>
                <InformationBox.Value>
                  {Math.floor(userShare * 100) / 100}%
                </InformationBox.Value>
              </InformationBox.Item>
            </InformationBox.Group>
            <InformationBox.Group>
              <InformationBox.Item>
                <InformationBox.Label>Subject Type</InformationBox.Label>
                <InformationBox.Value>
                  {getSubjectTypeLabel(subject.subjectType)}
                </InformationBox.Value>
              </InformationBox.Item>
              <InformationBox.Item>
                <InformationBox.Label>Subject ID</InformationBox.Label>
                <InformationBox.Value>
                  <CopyButton text={subject.id}>
                    {subject.id.length > 10
                      ? shortenHash(subject.id)
                      : subject.id}
                  </CopyButton>
                </InformationBox.Value>
              </InformationBox.Item>
            </InformationBox.Group>
          </InformationBox.Container>
        </div>
        <div className="StakingForm__form-container">
          <div className="StakingForm__form">
            <div className="StakingForm__amount-visual">
              <div
                className={`StakingForm__amount-visual-graph c100 p${inputPercentage} green`}
              >
                <span>~{inputPercentage}%</span>
                <div className="slice">
                  <div className="bar"></div>
                  <div className="fill"></div>
                </div>
              </div>
              <div className="StakingForm__amount-visual-title">
                Share of the staking pool
              </div>
            </div>
            <DepositForm
              formTitleText="Stake"
              formSubmitButtonText="Add stake"
              actionName="stake"
              inputValue={inputValue}
              balance={balance}
              allowance={allowance}
              onInputValueChange={handleInputChange}
              onApprove={handleApprove}
              onApproveDone={handleApproveDone}
              onDeposit={handleDeposit}
              onDepositDone={handleDepositDone}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DelegatedStakingView = (
  props: DelegatedStakingViewProps
): JSX.Element => {
  const {
    subject,
    delegatedStakingProps,

    inputValue,
    allowance,
    balance,

    isLoading,

    handleApprove,
    handleApproveDone,
    handleDeposit,
    handleDepositDone,
    handleInputChange
  } = props;

  const { allocation, owner, pool } = delegatedStakingProps;

  return (
    <div className="StakeModal__stake-form">
      <div className="StakeModal__description">
        Enter the amount of FORT you want to stake on behalf of this{' '}
        {getSubjectTypeLabel(subject.subjectType).toLocaleLowerCase()}.
      </div>
      <div className="StakingForm__content">
        <div className="StakingForm__information">
          <InformationBox.Container>
            <InformationBox.Title>Delegation Parameters</InformationBox.Title>
            <InformationBox.Title>{`Owned by ${owner}`}</InformationBox.Title>
            <InformationBox.Group>
              <InformationBox.Item>
                <InformationBox.Label>Commission</InformationBox.Label>
                <InformationBox.Value>
                  <CommissionDisplay pool={pool} />
                </InformationBox.Value>
              </InformationBox.Item>
              <InformationBox.Item>
                <InformationBox.Label>Est. Annual Rewards</InformationBox.Label>
                <InformationBox.Value>
                  {pool.apyForLastEpoch.toString() + '%'}
                </InformationBox.Value>
              </InformationBox.Item>
            </InformationBox.Group>
            <InformationBox.Group>
              <InformationBox.Item>
                <InformationBox.Label>Total Staked</InformationBox.Label>
                <InformationBox.Value>
                  {`${formatFORT(
                    allocation.stakeOwned.add(allocation.stakeDelegated)
                  )}`}{' '}
                  <small>FORT</small>
                </InformationBox.Value>
              </InformationBox.Item>
              <InformationBox.Item>
                <InformationBox.Label>Own Stake</InformationBox.Label>
                <InformationBox.Value>
                  {`${formatFORT(allocation.stakeOwned)}`} <small>FORT</small>
                </InformationBox.Value>
              </InformationBox.Item>
              <InformationBox.Item>
                <InformationBox.Label>Delegated</InformationBox.Label>
                <InformationBox.Value>
                  {`${formatFORT(allocation.stakeDelegated)}`}{' '}
                  <small>FORT</small>
                </InformationBox.Value>
              </InformationBox.Item>
              <InformationBox.Item>
                <InformationBox.Label>Allocated</InformationBox.Label>
                <InformationBox.Value>
                  {`${formatFORT(allocation.stakeAllocated)}`}{' '}
                  <small>FORT</small>
                </InformationBox.Value>
              </InformationBox.Item>
            </InformationBox.Group>
          </InformationBox.Container>
        </div>
        <div className="StakingForm__form-container">
          <div className="StakingForm__form">
            <DepositForm
              formTitleText="Delegate"
              formSubmitButtonText="Delegate"
              actionName="delegate"
              inputValue={inputValue}
              balance={balance}
              allowance={allowance}
              onInputValueChange={handleInputChange}
              onApprove={handleApprove}
              onApproveDone={handleApproveDone}
              onDeposit={handleDeposit}
              onDepositDone={handleDepositDone}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
