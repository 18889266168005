import React from 'react';

function DataPlanLine(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1264 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="7"
        x2="1264"
        y2="7"
        stroke="url(#paint0_radial_501_450)"
        strokeDasharray="4 4"
      />
      <defs>
        <radialGradient
          id="paint0_radial_501_450"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(632 7.5) rotate(-180) scale(604 115.832)"
        >
          <stop stopColor="#266073" stopOpacity="0.9" />
          <stop offset="1" stopColor="#20748B" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default DataPlanLine;
