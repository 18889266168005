import React from 'react';

import './ScannerPoolInfoBox.scss';

import { formatFORT } from 'forta-app/lib/utils';
import { BigNumber } from 'ethers';

export const Row = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => <div className="ScannerPoolInfo__row">{children}</div>;

export const Container = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => <div className="ScannerPoolInfo">{children}</div>;

export const Title = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => <div className="ScannerPoolInfo__title">{children}</div>;

export const Subtitle = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => <div className="ScannerPoolInfo__subtitle">{children}</div>;

export const Description = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <div className="ScannerPoolInfo__description">{children}</div>
);

export const SocialItem = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <div className="ScannerPoolInfo__item-social">{children}</div>
);

export const DataGrid = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => <div className="ScannerPoolInfo__grid">{children}</div>;

export const Divider = (): JSX.Element => (
  <div aria-hidden="true" className="ScannerPoolInfo__divider"></div>
);

export const Icon = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => <div className="ScannerPoolInfo__item-icon">{children}</div>;

export const Edit = (): JSX.Element => (
  <div className="ScannerPoolInfo__item-value ScannerPoolInfo__item-value--edit">
    Edit
  </div>
);

export const Buffer = (): JSX.Element => (
  <div className="ScannerPoolInfo__grid-buffer"></div>
);

export const TextEntry = ({
  children
}: {
  children?: React.ReactNode;
}): JSX.Element => (
  <div className="ScannerPoolInfo__item-value ScannerPoolInfo__item-value--entry">
    {children}
  </div>
);

export const FortEntry = ({
  value
}: {
  value?: string | BigNumber;
}): JSX.Element => (
  <div className="ScannerPoolInfo__item-value ScannerPoolInfo__item-value--entry">
    {formatFORT(value)} FORT
  </div>
);

export const Value = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <div className="ScannerPoolInfo__item-value">{children}</div>
);

export default {
  Buffer,
  Container,
  DataGrid,
  Description,
  Divider,
  Edit,
  TextEntry,
  FortEntry,
  Icon,
  Row,
  SocialItem,
  Subtitle,
  Title,
  Value
};
