import React, { useMemo } from 'react';
import { range } from 'lodash';
import cn from 'classnames';

import './Consensus.scss';
import BaseFilterModal, { FilterModalDescription } from '../Base/Base';

import Select from 'common/components-v2/Form/Select/Select';
import {
  DEFAULT_BOT_ASSIGNED_SCANNER_COUNT,
  NotificationScannerFilter
} from 'forta-app/slices/notificationSlice';
import Tabs, { Tab } from 'common/components-v2/Tabs/Tabs';

const IS_NUMBER = /^[0-9\b]+$/;

type ConsensusModalProps = {
  open?: boolean;
  value?: NotificationScannerFilter;
  onChange: (value?: NotificationScannerFilter) => unknown;
  onClose: () => unknown;
  className?: string;
};

function ConsensusModal(props: ConsensusModalProps): JSX.Element {
  const { open, value, onChange, onClose, className } = props;

  const numberOptions = useMemo(
    () =>
      range(DEFAULT_BOT_ASSIGNED_SCANNER_COUNT).map((num) => ({
        value: (num + 1).toString(),
        label: (num + 1).toString()
      })),
    []
  );

  const percentageOptions = useMemo(
    () =>
      range(DEFAULT_BOT_ASSIGNED_SCANNER_COUNT).map((num) => ({
        value: Math.floor(
          ((num + 1) / DEFAULT_BOT_ASSIGNED_SCANNER_COUNT) * 100
        ).toString(),
        label: `${Math.floor(
          ((num + 1) / DEFAULT_BOT_ASSIGNED_SCANNER_COUNT) * 100
        )}%`
      })),
    []
  );

  return (
    <BaseFilterModal
      open={open}
      value={value}
      title="Consensus"
      className={cn('ConsensusFilterModal', className)}
      onChange={onChange}
      onClose={onClose}
    >
      {({ value, setValue }) => {
        const getConsensusDefaultValue = (type: string): string =>
          type === 'number'
            ? numberOptions[0].value
            : percentageOptions[0].value;

        const consensusType = value?.valueType || 'percentage';
        const consensusDefaultValue = getConsensusDefaultValue(consensusType);
        const consensusValue: string = value?.value || consensusDefaultValue;

        return (
          <>
            <FilterModalDescription>
              Consensus indicate how many scan nodes have confirmed an alert.
            </FilterModalDescription>
            <Tabs
              value={consensusType}
              onChange={(type) =>
                setValue({
                  value: getConsensusDefaultValue(type),
                  valueType: type
                })
              }
            >
              <Tab label="Percentage (%)" value="percentage" />
              <Tab label="Number" value="number" />
            </Tabs>
            <br />
            <Select
              name="forta-notification-source-type"
              clearable={false}
              options={
                consensusType === 'number' ? numberOptions : percentageOptions
              }
              value={consensusValue}
              onChange={(event) => {
                const value = event.target.value;

                if (IS_NUMBER.test(value)) {
                  setValue({
                    value,
                    valueType: consensusType
                  });
                }
              }}
              placeholder={consensusType === 'number' ? '1' : '16%'}
            />
          </>
        );
      }}
    </BaseFilterModal>
  );
}

export default ConsensusModal;
