import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from 'forta-app/app/hooks';
import StartBuildingCTA from 'forta-app/components/notifications/StartBuildingCTA';

import './OldHomePage.scss';
import { subscribeToAgent } from './NotificationsPage';

export default function OldHomePage(): JSX.Element {
  const dispatch = useAppDispatch();
  const web3React = useWeb3React();

  const onAddNotificationClick = (): void => {
    subscribeToAgent('', dispatch, web3React);
  };

  return (
    <div className="OldHomePage">
      <StartBuildingCTA />
      <p>
        Do you already know what you&apos;re looking for?&nbsp;
        <button onClick={onAddNotificationClick}>Subscribe to Alerts</button>
      </p>
    </div>
  );
}
