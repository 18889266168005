import config from 'common/config';
import stakeAllocatorABI from 'forta-app/data/abis/stakeAllocatorABI.json';
import { ContractContext as StakeAllocatorContract } from 'forta-app/data/abis/stake-allocator-contract';
import ContractInteractor from './contractInteractor';
import { SubjectType } from './stakingContract';
import { BigNumber, ContractTransaction } from 'ethers';
import addProtocolTransaction from '../transactions-storage/addProtocolTransaction';
import { Web3ContextType } from '@web3-react/core';

export default class StakeAllocator extends ContractInteractor<StakeAllocatorContract> {
  constructor(web3React: Web3ContextType) {
    super(web3React, stakeAllocatorABI, config.stakeAllocatorAddress);
  }

  public async allocateOwnStake(
    subject: string,
    amount: BigNumber
  ): Promise<ContractTransaction> {
    const gas = await this.contract.estimateGas.allocateOwnStake(
      SubjectType.SCANNERPOOL,
      subject,
      amount
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.allocateOwnStake(
      SubjectType.SCANNERPOOL,
      subject,
      amount,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }

  public async allocateDelegatorStake(
    subject: string,
    amount: BigNumber
  ): Promise<ContractTransaction> {
    const gas = await this.contract.estimateGas.allocateDelegatorStake(
      SubjectType.SCANNERPOOL,
      subject,
      amount
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.allocateDelegatorStake(
      SubjectType.SCANNERPOOL,
      subject,
      amount,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }

  public async unallocateOwnStake(
    subject: string,
    amount: BigNumber
  ): Promise<ContractTransaction> {
    const gas = await this.contract.estimateGas.unallocateOwnStake(
      SubjectType.SCANNERPOOL,
      subject,
      amount
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.unallocateOwnStake(
      SubjectType.SCANNERPOOL,
      subject,
      amount,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }

  public async unallocateDelegatorStake(
    subject: string,
    amount: BigNumber
  ): Promise<ContractTransaction> {
    const gas = await this.contract.estimateGas.unallocateDelegatorStake(
      SubjectType.SCANNERPOOL,
      subject,
      amount
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.unallocateDelegatorStake(
      SubjectType.SCANNERPOOL,
      subject,
      amount,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }
}
