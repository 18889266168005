export enum MetricInterval {
  Hour = 'hour',
  Day = 'day',
  Week = 'week'
}

export enum MetricMeasure {
  Sum = 'sum',
  Max = 'max',
  Avg = 'avg'
}

export interface MetricInfo {
  key: MetricKey;
  label: string;
  description: string;
  measures: MetricMeasure[];
}

export const MetricMeasureLabels: Record<MetricMeasure, string> = {
  [MetricMeasure.Avg]: 'avg',
  [MetricMeasure.Sum]: 'count',
  [MetricMeasure.Max]: 'max'
};

export enum MetricKey {
  'TxRequest' = 'tx.request',
  'TxSuccess' = 'tx.success',
  'TxError' = 'tx.error',
  'TxLatency' = 'tx.latency',
  'TxDrop' = 'tx.drop',
  'BlockRequest' = 'block.request',
  'BlockSuccess' = 'block.success',
  'BlockError' = 'block.error',
  'BlockLatency' = 'block.latency',
  'BlockDrop' = 'block.drop',
  'CombinerRequest' = 'combiner.request',
  'CombinerSuccess' = 'combiner.success',
  'CombinerError' = 'combiner.error',
  'CombinerLatency' = 'combiner.latency',
  'CombinerDrop' = 'combiner.drop',
  'AgentStart' = 'agent.status.attached',
  'Finding' = 'finding',
  'JsonRpcCacheHit' = 'jsonrpc.cache.hit.*',
  'JsonRpcCacheMiss' = 'jsonrpc.cache.miss.*',
  'JsonRpcRequest' = 'jsonrpc.request.*',
  'JsonRpcSuccess' = 'jsonrpc.success.*',
  'JsonRpcLatency' = 'jsonrpc.latency.*',
  'AgentErrorCombinerInvoke' = 'agent.error.combiner.invoke',
  'AgentErrorBlockInvoke' = 'agent.error.block.invoke',
  'AgentErrorTxInvoke' = 'agent.error.tx.invoke'
}

export const metricsMap: Record<MetricKey, MetricInfo> = {
  [MetricKey.AgentErrorBlockInvoke]: {
    key: MetricKey.AgentErrorBlockInvoke,
    label: 'Bot: Block Error',
    description: 'Number of failed interactions with a block handler.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.AgentErrorTxInvoke]: {
    key: MetricKey.AgentErrorTxInvoke,
    label: 'Bot: Transaction Error',
    description: 'Number of failed interactions with a transaction handler.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.AgentErrorCombinerInvoke]: {
    key: MetricKey.AgentErrorCombinerInvoke,
    label: 'Bot: Combiner Error',
    description: 'Number of failed interactions with an alert handler.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.TxRequest]: {
    key: MetricKey.TxRequest,
    label: 'Transactions: Received',
    description:
      'Number of transactions the bot was sent by the node operator.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.TxSuccess]: {
    key: MetricKey.TxSuccess,
    label: 'Transactions: Success',
    description:
      'Number of transactions for which the bot responded successfully.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.TxError]: {
    key: MetricKey.TxError,
    label: 'Transactions: Error',
    description:
      'Number of transactions for which the bot responded with an error.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.TxLatency]: {
    key: MetricKey.TxLatency,
    label: 'Transactions: Latency',
    description: 'Execution time of the bot for a transaction.',
    measures: [MetricMeasure.Max, MetricMeasure.Avg]
  },
  [MetricKey.TxDrop]: {
    key: MetricKey.TxDrop,
    label: 'Transactions: Dropped',
    description: 'Number of transactions skipped by this block.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.BlockRequest]: {
    key: MetricKey.BlockRequest,
    label: 'Blocks: Received',
    description: 'Number of blocks the bot was sent by the node operator.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.BlockSuccess]: {
    key: MetricKey.BlockSuccess,
    label: 'Blocks: Success',
    description: 'Number of blocks for which the bot responded successfully.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.BlockError]: {
    key: MetricKey.BlockError,
    label: 'Blocks: Error',
    description: 'Number of blocks for which the bot responded with an error.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.BlockLatency]: {
    key: MetricKey.BlockLatency,
    label: 'Blocks: Latency',
    description: 'Execution time of the bot for a block.',
    measures: [MetricMeasure.Max, MetricMeasure.Avg]
  },
  [MetricKey.BlockDrop]: {
    key: MetricKey.BlockDrop,
    label: 'Blocks: Dropped',
    description: 'Number of blocks skipped by this bot.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.CombinerRequest]: {
    key: MetricKey.CombinerRequest,
    label: 'Combiner: Received',
    description: 'Number of combiners the bot was sent by the node operator.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.CombinerSuccess]: {
    key: MetricKey.CombinerSuccess,
    label: 'Combiner: Success',
    description:
      'Number of combiners for which the bot responded successfully.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.CombinerError]: {
    key: MetricKey.CombinerError,
    label: 'Combiner: Error',
    description:
      'Number of combiners for which the bot responded with an error.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.CombinerLatency]: {
    key: MetricKey.CombinerLatency,
    label: 'Combiner: Latency',
    description: 'Execution time of the bot for a combiner.',
    measures: [MetricMeasure.Max, MetricMeasure.Avg]
  },
  [MetricKey.CombinerDrop]: {
    key: MetricKey.CombinerDrop,
    label: 'Combiner: Dropped',
    description: 'Number of combiners skipped by this bot.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.AgentStart]: {
    key: MetricKey.AgentStart,
    label: 'Bot: Starts',
    description: 'Number of times the bot was started.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.Finding]: {
    key: MetricKey.Finding,
    label: 'Bot: Alerts',
    description: 'Number of alerts that were emitted.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.JsonRpcRequest]: {
    key: MetricKey.JsonRpcRequest,
    label: 'JSON RPC: Received',
    description:
      'Number of JSON RPC requests the bot was sent by the node operator.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.JsonRpcSuccess]: {
    key: MetricKey.JsonRpcSuccess,
    label: 'JSON RPC: Success',
    description:
      'Number of JSON RPC requests for which the bot responded successfully.',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.JsonRpcLatency]: {
    key: MetricKey.JsonRpcLatency,
    label: 'JSON RPC: Latency',
    description: 'Execution time of the bot for a JSON RPC request.',
    measures: [MetricMeasure.Max, MetricMeasure.Avg]
  },
  [MetricKey.JsonRpcCacheHit]: {
    key: MetricKey.JsonRpcCacheHit,
    label: 'JSON RPC Cache: Hit',
    description:
      'Number of JSON RPC requests that were retrieved from the cache',
    measures: [MetricMeasure.Sum]
  },
  [MetricKey.JsonRpcCacheMiss]: {
    key: MetricKey.JsonRpcCacheMiss,
    label: 'JSON RPC Cache: Miss',
    description:
      'Number of JSON RPC requests that were not retrieved from the cache',
    measures: [MetricMeasure.Sum]
  }
};

export function getMetricInfo(key: MetricKey): MetricInfo {
  return (
    metricsMap[key] || {
      label: key,
      description: key,
      measures: MetricMeasure.Sum
    }
  );
}

export const metrics: MetricInfo[] = Object.values(metricsMap);
