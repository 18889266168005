import { gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';
import { toast } from 'react-toastify';

export interface APIEvent {
  id: string;
  timestamp: string;
  event_name: string;
  details: {
    action: string;
    timestamp: string;
    source: {
      txHash: string;
      blockNumber: number;
      blockHash: string;
      timestamp: string;
    };
    scannerId?: string;
    agentId?: string;
  };
}

export interface GetEventsInput {
  scannerId?: string;
  agentIds?: string[];
  includeLinks?: boolean;
  size?: number;
  searchAfter?: string;
}

export async function getEvents(input: GetEventsInput): Promise<APIEvent[]> {
  try {
    const client = getExplorerAPIClient();

    const apiResult = await client.query<{ getEvents: APIEvent[] }>({
      variables: {
        getEventsInput: input
      },
      query: gql`
        query Retrive($getEventsInput: GetEventsInput) {
          getEvents(input: $getEventsInput) {
            id
            timestamp
            event_name
            details {
              action
              timestamp
              source {
                txHash
                blockNumber
                blockHash
                timestamp
              }
              scannerId
              agentId
            }
          }
        }
      `
    });

    return apiResult?.data?.getEvents || [];
  } catch (error) {
    console.error(error);
    toast.error('Error trying to get events');
    return [];
  }
}
