import { TransactionResponse } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import Button from 'common/components-v2/Button/Button';
import Modal from 'common/components/Modal';
import ScannerPoolRegistry from 'forta-app/lib/contract-interactors/scannerPoolRegistry';
import { useState } from 'react';
import ChainSelect from './ChainSelect';
import './AddScanNodePoolButton.scss';
import ContractCallButton from '../ContractCallButton';

type AddScanNodePoolButtonProps = {
  onCompleted?: (tx: TransactionResponse) => unknown;
};

export default function AddScanNodePoolButton(
  props: AddScanNodePoolButtonProps
): JSX.Element {
  const { onCompleted } = props;

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedChainId, setSelectedChainId] = useState<number>(0);
  const web3React = useWeb3React();
  const scannerPoolRegistry = new ScannerPoolRegistry(web3React);

  function handleComplete(tx: TransactionResponse): void {
    setModalOpened(false);
    if (onCompleted) {
      onCompleted(tx);
    }
  }

  return (
    <div className="AddNodePool">
      <Button variant="primary" size="md" onClick={() => setModalOpened(true)}>
        Add Scanner Pool
      </Button>
      <Modal
        className="AddNodePool__modal"
        opened={modalOpened}
        shouldCloseOnOverlayClick
        title="Create Scan Node Pool"
        onCloseModal={() => setModalOpened(false)}
      >
        <div className="AddNodePool__modal-content">
          <div className="AddNodePool__modal-description">
            A scan node pool is a group of scan nodes for a specific chain.
            <br /> You can assign scan nodes, stake, delegate and earn rewards
            from it.
          </div>
          <div className="AddNodePool__modal-form">
            <div className="AddNodePool__modal-chain">
              <div className="AddNodePool__modal-chain-description">
                What chain are your scan nodes going to monitor?
              </div>
              <ChainSelect
                value={selectedChainId}
                onChange={(value: number) => setSelectedChainId(value)}
              />
            </div>
            <ContractCallButton
              disabled={!selectedChainId}
              onCompleted={handleComplete}
              onCall={() =>
                scannerPoolRegistry.registerScannerPool(selectedChainId)
              }
            >
              Register Scan Node Pool
            </ContractCallButton>
          </div>
        </div>
      </Modal>
    </div>
  );
}
