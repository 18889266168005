import plans, { getPlanByType } from 'forta-app/data/plans';
import { useMemo } from 'react';
import useUnlockMembershipsQuery from './useUnlockMembershipsQuery';
import { Agent } from 'common/lib/apis/agentAPI';
import { useWeb3React } from '@web3-react/core';
import { DataPlan } from 'forta-app/data/plans/types';

export type useBotPlanResult = {
  plan: DataPlan | null | undefined;
  isFreePlan: boolean;
  isPlanPurchased: boolean;
};

function useBotPlan(props: { bot?: Agent }): useBotPlanResult {
  const { bot } = props;

  const web3React = useWeb3React();

  const plan = useMemo(() => {
    if (!bot) return null;

    if (plans.length > 0) {
      // First, search by id to find frontend-overwritten plans (frontend-only plans)
      const plan = plans.find((plan) => plan.botId === bot.id);

      if (plan) return plan;
    }
    // If nothing is found, search by the type of bot subscription that is specified on the backend
    return getPlanByType(bot?.subscription_type);
  }, [bot]);

  const isFreePlan = useMemo(() => {
    if (!plan) return false;

    return plan.price.USDC === 0 && plan.price.FORT === 0;
  }, [plan]);

  const { activeMembershipAddresses } = useUnlockMembershipsQuery({
    params: {
      owner: web3React.account || ''
    }
  });

  const isPlanPurchased = plan
    ? activeMembershipAddresses.includes(
        plan.unlockAddresses.FORT.toLowerCase()
      ) ||
      activeMembershipAddresses.includes(
        plan.unlockAddresses.USDC.toLowerCase()
      ) ||
      activeMembershipAddresses.includes(
        plan.unlockAddresses.trial.toLowerCase()
      )
    : false;

  return {
    plan,
    isFreePlan,
    isPlanPurchased
  };
}

export default useBotPlan;
