import { BigNumber, providers } from 'ethers';
import { useWeb3React } from '@web3-react/core';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { parseEther } from 'ethers/lib/utils';

import ContractCallButton from '../ContractCallButton';

import './WithdrawModal.scss';

import Modal from 'common/components/Modal';
import InformationBox from 'common/components/InformationBox';
import InfoPopover from 'common/components/InfoPopover';
import Input from 'common/components/Input';
import Loader from 'common/components/Loader';
import Button from 'common/components-v2/Button/Button';
import StakingContract from 'forta-app/lib/contract-interactors/stakingContract';
import { useJWT } from 'forta-app/app/hooks';
import { Stake } from 'forta-app/lib/apis/subgraphAPI';
import { safeFormatEther, toFORTPrecision } from 'forta-app/lib/utils';

interface WithdrawModalProps {
  opened: boolean;
  stake?: Stake;
  onClose: (tx?: providers.TransactionResponse) => void;
}

function WithdrawModal(props: WithdrawModalProps): JSX.Element {
  const { opened, onClose } = props;

  const jwt = useJWT();
  const web3React = useWeb3React();
  const [withdrawable, setWithdrawable] = useState(false);
  const [toInactiveModalOpened, setToInactiveModalOpened] = useState(false);
  const [toInactiveInputValue, setToInactiveInputValue] = useState('');

  const { account } = web3React;
  const stakingContract = useMemo(
    () => new StakingContract(web3React),
    [web3React]
  );

  const stake = useMemo(() => {
    const stake = props.stake;

    if (!stake) return null;

    const subject = {
      id: stake.subject.id,
      subjectType: stake.subject.subjectType || 0,
      activeShares: BigNumber.from(stake.subject.activeShares || 0),
      inactiveShares: BigNumber.from(stake.subject.inactiveShares || 0),
      activeStake: BigNumber.from(stake.subject.activeStake || 0),
      inactiveStake: BigNumber.from(stake.subject.inactiveStake || 0)
    };

    const totalActiveShares = subject.activeShares.isZero()
      ? BigNumber.from(1)
      : subject.activeShares;
    const totalInactiveShares = subject.inactiveShares.isZero()
      ? BigNumber.from(1)
      : subject.inactiveShares;
    const totalActiveStake = subject.activeStake.isZero()
      ? BigNumber.from(1)
      : subject.activeStake;

    return {
      subject,
      shares: BigNumber.from(stake.shares),
      inactiveShares: BigNumber.from(stake.inactiveShares),
      totalActiveShares,
      totalInactiveShares,
      totalActiveStake
    };
  }, [props.stake]);

  const testWithdrawable = useCallback(async () => {
    if (!stake || !account || !jwt) {
      setWithdrawable(false);
      return;
    }

    try {
      await stakingContract.testWithdraw(
        stake.subject.subjectType,
        stake.subject.id
      );
      setWithdrawable(true);
    } catch (e) {
      setWithdrawable(false);
    }
  }, [stakingContract, account, jwt, stake]);

  const handleMoveToInactiveClick = useCallback(
    async function (): Promise<providers.TransactionResponse | null> {
      if (!account || !jwt || !stake) return null;

      const _sharesToInactivate = parseEther(toInactiveInputValue)
        .mul(stake.totalActiveShares)
        .div(stake.totalActiveStake);
      const sharesToInactivate = _sharesToInactivate.gt(stake.shares)
        ? stake.shares
        : _sharesToInactivate;
      return await stakingContract.initiateWithdrawal(
        stake.subject.subjectType,
        stake.subject.id,
        sharesToInactivate
      );
    },
    [account, stakingContract, jwt, stake, toInactiveInputValue]
  );

  function handleMoveToInactiveDone(tx: providers.TransactionResponse): void {
    setToInactiveModalOpened(false);
    setToInactiveInputValue('');
    onClose(tx);
  }

  const handleWithdrawClick =
    useCallback(async (): Promise<providers.TransactionResponse | null> => {
      if (!account || !jwt || !stake) return null;

      return stakingContract.withdraw(
        stake.subject.subjectType,
        stake.subject.id
      );
    }, [account, jwt, stake, stakingContract]);

  function handleWithdrawDone(tx: providers.TransactionResponse): void {
    onClose(tx);
  }

  useEffect(() => {
    testWithdrawable();
  }, [stake, testWithdrawable]);

  return (
    <Modal
      opened={opened}
      title="Withdraw Staked FORT"
      onCloseModal={() => onClose()}
      className="WithdrawModal__container"
    >
      <div className="WithdrawModal">
        {stake ? (
          <>
            <InformationBox.Container>
              <InformationBox.Title>Withdraw Staked FORT</InformationBox.Title>
              <InformationBox.Group>
                <InformationBox.Item>
                  <InformationBox.Label>Active Stake</InformationBox.Label>
                  <InformationBox.Value>
                    <div className="WithdrawModal__stake-amount">
                      {toFORTPrecision(
                        safeFormatEther(
                          stake.shares
                            .mul(stake.subject.activeStake)
                            .div(stake.totalActiveShares)
                        )
                      ).toLocaleString()}{' '}
                      FORT
                      <InfoPopover
                        content={`${safeFormatEther(stake.shares)} shares`}
                      />
                    </div>
                  </InformationBox.Value>
                  <InformationBox.Value>
                    <Button
                      size="sm"
                      variant="primary"
                      disabled={stake.shares
                        .mul(stake.subject.activeStake)
                        .div(stake.totalActiveShares)
                        .isZero()}
                      onClick={() => {
                        setToInactiveModalOpened(true);
                        setToInactiveInputValue('');
                      }}
                    >
                      Initiate Withdrawal
                    </Button>
                  </InformationBox.Value>
                </InformationBox.Item>
                <InformationBox.Item>
                  <InformationBox.Label>Inactive Stake</InformationBox.Label>
                  <InformationBox.Value>
                    <div className="WithdrawModal__stake-amount">
                      {toFORTPrecision(
                        safeFormatEther(
                          stake.inactiveShares
                            .mul(stake.subject.inactiveStake)
                            .div(stake.totalInactiveShares)
                        )
                      ).toLocaleString()}{' '}
                      FORT
                      <InfoPopover
                        content={`${safeFormatEther(
                          stake.inactiveShares
                        )} shares`}
                      />
                    </div>
                  </InformationBox.Value>
                  <InformationBox.Value>
                    <ContractCallButton
                      disabled={
                        !withdrawable ||
                        stake.inactiveShares
                          .mul(stake.subject.inactiveStake)
                          .div(stake.totalInactiveShares)
                          .isZero()
                      }
                      onCall={handleWithdrawClick}
                      onCompleted={handleWithdrawDone}
                    >
                      Withdraw
                    </ContractCallButton>
                  </InformationBox.Value>
                </InformationBox.Item>
              </InformationBox.Group>
            </InformationBox.Container>
            <Modal
              opened={toInactiveModalOpened}
              title="Move stake to inactive"
              className="ToInactiveModal__container"
              onCloseModal={() => setToInactiveModalOpened(false)}
            >
              <div className="ToInactiveModal">
                <div className="ToInactiveModal__description">
                  In order to withdraw your stake, you need to move it to
                  &quot;inactive&quot; first. After being locked for 10 days,
                  you will be able to withdraw your tokens.
                </div>
                <div className="ToInactiveModal__input-group">
                  <div className="ToInactiveModal__input">
                    <Input
                      onChange={(value: string) =>
                        setToInactiveInputValue(value)
                      }
                      value={toInactiveInputValue}
                      placeholder="0.0"
                      pattern="^[0-9]*[.]?[0-9]*$"
                      name="fort-amount-stake"
                    />
                    <div className="ToInactiveModal__amount-label">FORT</div>
                  </div>
                  <div className="ToInactiveModal__max">
                    <button
                      onClick={() =>
                        setToInactiveInputValue(
                          Number(
                            safeFormatEther(
                              stake.shares
                                .mul(stake.subject.activeStake)
                                .div(stake.totalActiveShares)
                            )
                          ).toString()
                        )
                      }
                    >
                      Max
                    </button>
                  </div>
                </div>
                <div className="ToInactiveModal__action">
                  <ContractCallButton
                    disabled={!Number(toInactiveInputValue)}
                    onCall={handleMoveToInactiveClick}
                    onCompleted={handleMoveToInactiveDone}
                  >
                    Initiate Withdrawal
                  </ContractCallButton>
                </div>
              </div>
            </Modal>
          </>
        ) : (
          <div className="WithdrawModal__loader">
            <Loader />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default WithdrawModal;
