import { TransactionResponse } from '@ethersproject/providers';
import { BigNumber, ethers } from 'ethers';

import config from 'common/config';
import stakingContractABI from 'forta-app/data/abis/stakingContractABI.json';
import ERC20ABI from 'forta-app/data/abis/ERC20ABI.json';
import { ContractContext as FortaStaking } from 'forta-app/data/abis/staking-contract';
import { ContractContext as ERC20Contract } from 'forta-app/data/abis/erc20-contract';
import ContractInteractor from './contractInteractor';
import addProtocolTransaction from '../transactions-storage/addProtocolTransaction';
import { Web3ContextType } from '@web3-react/core';

export enum SubjectType {
  SCANNER = 0,
  BOT = 1,
  SCANNERPOOL = 2,
  SCANNERPOOL_DELEGATOR = 3
}

export const getSubjectTypeLabel = (subjectType: SubjectType): string => {
  switch (subjectType) {
    case SubjectType.BOT:
      return 'Bot';
    case SubjectType.SCANNER:
      return 'Scan node';
    case SubjectType.SCANNERPOOL:
      return 'Scan node Pool';
    case SubjectType.SCANNERPOOL_DELEGATOR:
      return 'Scan node Pool';
  }
};

export default class StakingContract extends ContractInteractor<FortaStaking> {
  private FORTContract: ERC20Contract;

  constructor(web3React: Web3ContextType) {
    super(web3React, stakingContractABI, config.stakingContractAddress);

    this.FORTContract = new ethers.Contract(
      config.FORTContractAddress,
      ERC20ABI,
      this.web3React.provider?.getSigner(web3React.account || '')
    ) as unknown as ERC20Contract;
  }

  public async approveTokens(value: BigNumber): Promise<TransactionResponse> {
    return await this.FORTContract.approve(
      config.stakingContractAddress,
      value
    );
  }

  public async allowance(): Promise<BigNumber> {
    return await this.FORTContract.allowance(
      this.web3React.account || '',
      config.stakingContractAddress
    );
  }

  public async deposit(
    subjectType: SubjectType,
    subject: string,
    value: BigNumber
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.deposit(
      subjectType,
      subject,
      value
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.deposit(
      subjectType,
      subject,
      value,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }

  public async testWithdraw(
    subjectType: SubjectType,
    subject: string
  ): Promise<BigNumber> {
    return await this.contract.estimateGas.withdraw(subjectType, subject);
  }

  public async withdraw(
    subjectType: SubjectType,
    subject: string
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.withdraw(subjectType, subject);
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.withdraw(subjectType, subject, txOptions);
    addProtocolTransaction(tx);
    return tx;
  }

  public async initiateWithdrawal(
    subjectType: SubjectType,
    subject: string,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.initiateWithdrawal(
      subjectType,
      subject,
      amount
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.initiateWithdrawal(
      subjectType,
      subject,
      amount,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }

  public async getTotalShares(
    subjectType: SubjectType,
    subject: string
  ): Promise<BigNumber> {
    const result = await this.contract.totalShares(subjectType, subject);
    return result;
  }

  public async getSharesOf(
    subjectType: SubjectType,
    subject: string,
    account: string
  ): Promise<BigNumber> {
    const result = await this.contract.sharesOf(subjectType, subject, account);
    return result;
  }

  public async getInactiveSharesOf(
    subjectType: SubjectType,
    subject: string,
    account: string
  ): Promise<BigNumber> {
    const result = await this.contract.inactiveSharesOf(
      subjectType,
      subject,
      account
    );
    return result;
  }

  public async getActiveStake(
    subjectType: SubjectType,
    subject: string
  ): Promise<BigNumber> {
    const result = await this.contract.activeStakeFor(subjectType, subject);
    return result;
  }
}

export class ScannerStaking extends StakingContract {
  async depositInScanner(
    id: string,
    value: BigNumber
  ): Promise<TransactionResponse> {
    return this.deposit(SubjectType.SCANNER, id, value);
  }

  async getScannerActiveStake(id: string): Promise<BigNumber> {
    return this.getActiveStake(SubjectType.SCANNER, id);
  }

  async getTotalScannerShares(id: string): Promise<BigNumber> {
    return this.getTotalShares(SubjectType.SCANNER, id);
  }

  async getScannerSharesOf(id: string, account: string): Promise<BigNumber> {
    return this.getSharesOf(SubjectType.SCANNER, id, account);
  }

  async getScannerInactiveSharesOf(
    id: string,
    account: string
  ): Promise<BigNumber> {
    return this.getInactiveSharesOf(SubjectType.SCANNER, id, account);
  }

  async testScannerWithdraw(subject: string): Promise<BigNumber> {
    return this.testWithdraw(SubjectType.SCANNER, subject);
  }

  async scannerWithdraw(subject: string): Promise<TransactionResponse> {
    return this.withdraw(SubjectType.SCANNER, subject);
  }

  async scannerInitiateWithdrawal(
    subject: string,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    return this.initiateWithdrawal(SubjectType.SCANNER, subject, amount);
  }
}

export class BotStaking extends StakingContract {
  async getBotActiveStake(
    subjectType: SubjectType,
    subject: string
  ): Promise<BigNumber> {
    return this.getActiveStake(SubjectType.BOT, subject);
  }
}
