import { gql } from '@apollo/client';

import useGraphQuery, { GraphQueryResult } from './useGraphQuery';
import { ApolloClientName } from '../components/ApolloClientProvider';
import { Stake, toEthersHexString } from 'forta-app/lib/apis/subgraphAPI';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';

export const STAKE_QUERY = gql`
  query StakeQuery(
    $stakerId: String!
    $first: Int
    $skip: Int
    $subjectTypes: [Int]
  ) {
    staker(id: $stakerId) {
      id
      stakes(
        first: $first
        skip: $skip
        where: { subject_: { subjectType_in: $subjectTypes } }
      ) {
        subject {
          id
          subjectType
          activeShares
          activeStake
          inactiveShares
          inactiveStake
        }
        shares
        inactiveShares
      }
    }
  }
`;

export type UseStakeQueryParams = {
  address: string;
  first?: number;
  skip?: number;
  subjectTypes?: SubjectType[];
};

type RequestVariables = {
  stakerId: string;
  first?: number;
  skip?: number;
  subjectTypes?: SubjectType[];
};

type StakerData = {
  id: string;
  stakes: Stake[];
};

type ResponseData = {
  staker?: StakerData;
};

export type UseStakeQueryResult = GraphQueryResult<
  RequestVariables,
  ResponseData
> & { stakes: Stake[]; hasNextPage: boolean };

function useStakeQuery(opts: {
  params: UseStakeQueryParams;
  enabled?: boolean;
  enablePolling?: boolean;
}): UseStakeQueryResult {
  const { params, enabled, enablePolling } = opts;
  const address = params.address.toLowerCase();

  const first = params.first ?? 10;
  const skip = params.skip ?? 0;
  const subjectTypes = params.subjectTypes || [
    SubjectType.BOT,
    SubjectType.SCANNERPOOL,
    SubjectType.SCANNERPOOL_DELEGATOR
  ];

  const query = useGraphQuery<RequestVariables, ResponseData>({
    enabled,
    query: STAKE_QUERY,
    variables: {
      stakerId: address,
      subjectTypes,
      // add additional item to check if there is next page
      first: first + 1,
      skip
    },
    enablePolling,
    clientName: ApolloClientName.Subgraph
  });

  // check if response is less than requested items
  const hasNextPage = (query.data?.staker?.stakes?.length || 0) === first + 1;

  const stakes: Stake[] =
    query.data?.staker?.stakes?.slice(0, first).map((i) => ({
      ...i,
      subject: {
        ...i.subject,
        id: i.subject.id ? toEthersHexString(i.subject.id) : ''
      }
    })) || [];

  return {
    ...query,
    stakes: stakes,
    hasNextPage: hasNextPage
  };
}

export default useStakeQuery;
