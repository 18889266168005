/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import './Tabs.scss';

type ContextType<T> = {
  value?: T;
  onChange?: (t: T) => unknown;
};

export const TabsContext = React.createContext<ContextType<any>>({});

export type TabItem<T> = {
  label: string;
  value: T;
  badge?: string | number;
  disabled?: boolean;
};

type TabsProps<T> = {
  value?: T;
  children: React.ReactNode;
  className?: string;
  onChange?: (v: T) => unknown;
};

function Tabs<T>(props: TabsProps<T>): JSX.Element {
  const { value, onChange, children, className } = props;

  const context = { value, onChange };

  return (
    <TabsContext.Provider value={context}>
      <div className={cn('Tabs', className)}>
        <div role="tablist" className="Tabs__container">
          {children}
        </div>
        <div aria-hidden={true} className="Tabs__line" />
      </div>
    </TabsContext.Provider>
  );
}

type TabProps<T> = {
  label: string;
  value: T;
  disabled?: boolean;
  icon?: JSX.Element;
  badge?: string;
  testId?: string;
};

export function Tab<T>(props: TabProps<T>): JSX.Element {
  const { icon, label, badge, testId, disabled } = props;
  const { value, onChange } = useContext(TabsContext);
  const isActive = value === props.value;

  function handleChange(item: T): void {
    if (onChange) {
      onChange(item);
    }
  }

  return (
    <button
      type="button"
      role="tab"
      aria-selected={isActive}
      data-label={label}
      data-testid={testId}
      tabIndex={isActive ? 0 : -1}
      className={cn('Tab', {
        ['Tab--active']: isActive,
        ['Tab--disabled']: disabled
      })}
      disabled={disabled}
      onClick={() => handleChange(props.value)}
    >
      {icon && <span className="Tab__icon">{icon}</span>}
      <span data-label={label}>{label}</span>
      {badge && <span className="Tab__badge">{badge}</span>}
    </button>
  );
}

type NavTabProps = {
  to: string;
  exact?: boolean;
  label: string;
  disabled?: boolean;
  badge?: string;
};

export function NavTab(props: NavTabProps): JSX.Element {
  const { to, exact, label, disabled, badge } = props;

  let Component: any;
  let componentProps: { [attr: string]: string | number | boolean | undefined };

  if (disabled) {
    Component = 'a';
    componentProps = {
      href: '#',
      className: cn('Tab', 'Tab--disabled')
    };
  } else {
    Component = NavLink;
    componentProps = {
      className: 'Tab',
      activeClassName: 'Tab--active',
      to: to,
      exact: exact
    };
  }

  return (
    <Component role="tab" {...componentProps}>
      <span data-label={label}>{label}</span>
      {badge && <span className="Tab__badge">{badge}</span>}
    </Component>
  );
}

export default Tabs;
