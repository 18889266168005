import dayjs, { OpUnitType } from 'dayjs';
import numbro from 'numbro';
import {
  CountableTimeInterval,
  timeDay,
  timeHour,
  timeMinute,
  timeMonth,
  timeSecond
} from 'd3-time';

export type ChartInterval = { value: number; unit: OpUnitType };

export type DataRecord = {
  [key: string]: number;
};

export type TickFormatter = (value: number | string, index?: number) => string;

export enum TimeInterval {
  Year = 'year',
  Month = 'month',
  Week = 'week',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute'
}

// https://day.js.org/docs/en/display/format
export function getTickTimeFormat(interval: TimeInterval): string {
  const format = {
    [TimeInterval.Year]: 'MMM YYYY',
    [TimeInterval.Month]: 'MMM D',
    [TimeInterval.Week]: 'ddd D',
    [TimeInterval.Day]: 'h a',
    [TimeInterval.Hour]: 'h:mm a',
    [TimeInterval.Minute]: 'mm:ss'
  }[interval];

  if (!format) throw new Error('Unknown time interval: ' + interval);

  return format;
}

export function getTickInterval(interval: TimeInterval): CountableTimeInterval {
  return {
    [TimeInterval.Minute]: timeSecond,
    [TimeInterval.Hour]: timeMinute,
    [TimeInterval.Day]: timeHour,
    [TimeInterval.Week]: timeDay,
    [TimeInterval.Month]: timeDay,
    [TimeInterval.Year]: timeMonth
  }[interval];
}

export function getTickTimeFormatter(interval: TimeInterval): TickFormatter {
  const format = getTickTimeFormat(interval);

  return (value) =>
    dayjs(Number(value) * 1000 /* unix seconds to js timestamp */).format(
      format
    );
}

export function getTickNumberFormatter(
  domain: [number, number],
  average = true
): TickFormatter {
  const maxNumber = Math.max(domain[0], domain[1]);

  let formatProps: numbro.Format = { thousandSeparated: true };

  if (average && Math.round(maxNumber).toString().length > 6) {
    formatProps = {
      ...formatProps,
      average: true,
      mantissa: 2,
      totalLength: 3,
      trimMantissa: true
    };
  }

  return (value) => {
    if (Number(value) === 0) return '0';

    return numbro(value).format(formatProps);
  };
}

// determine the observed time interval
export function calcTimeInterval(domain: [number, number]): TimeInterval {
  const secondsDiff = Math.abs(domain[1] - domain[0]);

  if (secondsDiff <= dayjs(0).add(1, 'minute').unix())
    return TimeInterval.Minute;
  if (secondsDiff <= dayjs(0).add(1, 'hour').unix()) return TimeInterval.Hour;
  if (secondsDiff <= dayjs(0).add(1, 'day').unix()) return TimeInterval.Day;
  if (secondsDiff <= dayjs(0).add(1, 'week').unix()) return TimeInterval.Week;
  if (secondsDiff <= dayjs(0).add(1, 'month').unix()) return TimeInterval.Month;
  if (secondsDiff <= dayjs(0).add(1, 'year').unix()) return TimeInterval.Year;
  return TimeInterval.Year;
}

// find min and max values
export function calcDomains(
  records: DataRecord[],
  xAccessors: string[],
  yAccessors: string[]
): {
  yDomain: [number, number];
  xDomain: [number, number];
} {
  let xDomain: [number, number] = [0, 0];
  let yDomain: [number, number] = [0, 0];

  let yMinValue;
  let yMaxValue;
  let xMinValue;
  let xMaxValue;

  for (const record of records) {
    for (const xAccessor of xAccessors) {
      const xValue = record[xAccessor];
      if (xMinValue == null || xMinValue > xValue) xMinValue = xValue;
      if (xMaxValue == null || xMaxValue < xValue) xMaxValue = xValue;
    }

    for (const yAccessor of yAccessors) {
      const yValue = record[yAccessor];
      if (yMinValue == null || yMinValue > yValue) yMinValue = yValue;
      if (yMaxValue == null || yMaxValue < yValue) yMaxValue = yValue;
    }
  }

  if (yMinValue != null && yMaxValue != null) {
    yDomain = [yMinValue, yMaxValue];
  }

  if (xMinValue != null && xMaxValue != null) {
    xDomain = [xMinValue, xMaxValue];
  }

  return { xDomain, yDomain };
}
