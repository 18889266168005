import CreatableSelectFilter, { Option } from './CreatableSelectFilter';

interface FilterParams {
  onChange: (selection: string[]) => void;
  selection: string[];
}

const createOption = (label: string): Option => ({
  label,
  value: label
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function AgentFilter({
  onChange,
  selection
}: FilterParams): JSX.Element {
  const styles = {
    multiValue: (styles: any /*, { data }: any*/) => {
      return {
        ...styles,
        backgroundColor: 'white',
        cursor: 'pointer'
      };
    },
    multiValueLabel: (styles: any /*, { data }: any*/) => ({
      ...styles,
      color: 'black',
      backgroundColor: 'white'
    }),
    multiValueRemove: (styles: any /*, { data }: any*/) => ({
      ...styles,
      color: '#444',
      backgroundColor: 'white',
      borderRadius: '0 50px 50px 0',
      paddingRight: '8px',
      marginRight: '2px',
      ':hover': {
        color: '#000',
        cursor: 'pointer'
      }
    }),
    control: (styles: any) => ({
      ...styles,
      padding: '10px'
    })
  };

  return (
    <CreatableSelectFilter
      onChange={onChange}
      selection={selection}
      selectionToOption={createOption}
      styles={styles}
      placeholder="Search by bot id..."
    />
  );
}
