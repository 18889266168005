import cn from 'classnames';

import { NetworkInfo } from 'common/lib/networks';

export default function ChainIcon({
  network,
  big,
  className,
  testId
}: {
  network: NetworkInfo;
  className?: string;
  big?: boolean;
  testId?: string;
}): JSX.Element {
  const src = big
    ? `/chain-icons/big/${network.icon}.png`
    : `/chain-icons/${network.icon}.jpg`;
  return (
    <img
      className={cn('ChainIcon', className)}
      src={src}
      alt={network.label}
      style={{ borderRadius: '50%' }}
      data-testid={testId}
    />
  );
}
