import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

type ScrollToTopProps = {
  location: {
    pathname: string;
  };
  children?: React.ReactElement | React.ReactNode;
};

type ScrollToTopContextState = {
  disabled: boolean;
};

const ScrollToTopContext = React.createContext<ScrollToTopContextState>({
  disabled: false
});

// Used to disable "scrollToTop" behavior on pages with tabs that change the pathname
export function useScrollToTop(params: { disabled: boolean }): void {
  const context = useContext(ScrollToTopContext);

  useLayoutEffect(() => {
    const before = context.disabled;

    if (params.disabled) {
      context.disabled = params.disabled;
    }

    return () => {
      context.disabled = before;
    };
  }, [context, params.disabled]);
}

function ScrollToTop(props: ScrollToTopProps): React.ReactElement {
  const { children, location } = props;

  const stateRef = useRef<ScrollToTopContextState>({
    disabled: false
  });

  useEffect(() => {
    if (!stateRef.current.disabled) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <ScrollToTopContext.Provider value={stateRef.current}>
      {children}
    </ScrollToTopContext.Provider>
  );
}

export default withRouter(ScrollToTop);
