/* eslint-disable */
import config from 'common/config';
import { ethers } from 'ethers';
import { SiweMessage } from 'siwe';
import { getNetworkByChainId, Network } from '../../common/lib/networks';

if (window.location.search.includes('MOCK_METAMASK=1')) {
  (async function () {
    const provider = new ethers.providers.JsonRpcProvider(
      getNetworkByChainId(Network.PolygonMainnet).rpcProviderUrl
    );

    const PRIVATE_KEY =
      '376dfac97bf5796e3ddc90fee46f5b19a3474d243869b08de1fa4d20e0e8a403';
    const wallet = new ethers.Wallet(PRIVATE_KEY);

    const siweMessage = new SiweMessage({
      domain: window.location.host,
      address: wallet.address,
      statement: 'I accept the Forta Terms of Use: https://forta.org/legal/',
      uri: window.location.origin,
      version: '1',
      chainId: config.chainId,
      nonce: 'kUeAxk5ht0KCPd3g1'
    });
    const message = siweMessage.prepareMessage();
    const signature = await wallet.signMessage(message);

    window.ethereum = undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.web3MockMessage = message;
    window.ethereum = {
      isMetaMask: true,
      chainId: '0x89',
      selectedAddress: wallet.address,
      networkVersion: '3', // for Ropsten
      autoRefreshOnNetworkChange: false,
      isConnected: () => true,
      request: (args: { method: string }) => {
        switch (args.method) {
          case 'eth_requestAccounts':
            return Promise.resolve([wallet.address]);
          case 'eth_sign':
          case 'personal_sign':
            // You can use the same mock for both `eth_sign` and `personal_sign`.
            // If you need them to behave differently, separate the cases.
            return Promise.resolve(signature);
          case 'eth_chainId':
            return Promise.resolve('0x89');
          case 'eth_blockNumber':
            return Promise.resolve('0x123456');
          case 'eth_accounts':
            return Promise.resolve([wallet.address]);
          default:
            throw new Error(`Unhandled method: ${args.method}`);
        }
      },
      on: (event: string) => {
        console.log(`Subscribed to event: ${event}`);
      },
      off: (event: string) => {
        console.log(`Unsubscribed from event: ${event}`);
      },
      send: (method: string, params: Record<string, unknown>) => {
        // @ts-ignore
        return this.request({ method, params });
      }
    } as unknown as any;
  })();
}
