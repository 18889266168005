import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { TransactionResponse } from '@ethersproject/providers';
import Table from 'common/components-v2/Table/Table';
import TableHeading, {
  TableActionHeading
} from 'common/components-v2/Table/TableHeading';
import TableHead from 'common/components-v2/Table/TableHead';
import TableSubHeading from 'common/components-v2/Table/TableSubHeading';
import TableRow from 'common/components-v2/Table/TableRow';
import TableCell from 'common/components-v2/Table/TableCell';
import TableSubCell, {
  TableSubCellWithHealthIndicator
} from 'common/components-v2/Table/TableSubCell';
import ActionCell from 'common/components-v2/Table/ActionCell';
import Button from 'common/components-v2/Button/Button';
import Modal from 'common/components/Modal';
import ContractCallButton from '../ContractCallButton';
import ScannerPoolRegistry from 'forta-app/lib/contract-interactors/scannerPoolRegistry';
import TextArea from 'common/components-v2/Form/TextArea/TextArea';
import InformationBox from 'common/components/InformationBox';
import ChainIcon from 'common/components/stats/ChainIcon';
import { getNetworkByChainId } from 'common/lib/networks';
import './ScanNodesList.scss';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import TableBody from 'common/components-v2/Table/TableBody';
import { useJWT } from 'forta-app/app/hooks';
import NodeEntity from '../../../common/components-v2/Entities/Node/Node';
import {
  ScannerMultiSlaResult,
  getMultiSLAs
} from 'forta-app/lib/apis/getMultiSLAs';
import { useQuery } from '@tanstack/react-query';
import { formatFORTToNumber, formatNumber } from 'forta-app/lib/utils';

interface ScanNodeRegistrationData {
  registrationInput: {
    chainId: number;
    scannerPoolId: number;
    scanner: string;
    metadata: string;
    timestamp: number;
  };
  signature: string;
}

function AddScanNodeButton({
  scannerPool
}: {
  scannerPool: ScannerPool;
}): JSX.Element {
  const jwt = useJWT();
  const web3React = useWeb3React();
  const scannerPoolRegistry =
    web3React.account && new ScannerPoolRegistry(web3React);
  const [opened, setOpened] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [willNewScannerShutdownPool, setWillNewScannerShutdownPool] =
    useState<boolean>(true);

  useEffect(() => {
    if (jwt && web3React.account && web3React.isActive) {
      const _scannerPoolRegistry = new ScannerPoolRegistry(web3React);
      _scannerPoolRegistry
        .willNewScannerShutdownPool(scannerPool.id)
        .then((willShutdown) => {
          setWillNewScannerShutdownPool(willShutdown);
        });
    } else {
      setWillNewScannerShutdownPool(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, web3React.account, web3React.isActive, scannerPool.id]);

  const parsedValue: ScanNodeRegistrationData | null = (function () {
    try {
      const parsed = JSON.parse(atob(value));

      return {
        registrationInput: {
          scanner: String(parsed.registrationInput.scanner),
          scannerPoolId: Number(parsed.registrationInput.scannerPoolId),
          chainId: Number(parsed.registrationInput.chainId),
          metadata: String(parsed.registrationInput.metadata),
          timestamp: Number(parsed.registrationInput.timestamp)
        },
        signature: String(parsed.signature)
      };
    } catch (e) {
      return null;
    }
  })();

  const validation: string = (function () {
    if (parsedValue) {
      if (parsedValue.registrationInput.chainId !== scannerPool.chainId)
        return 'Chain ID does not match the scanner pool';
      if (
        parsedValue.registrationInput.scannerPoolId !== Number(scannerPool.id)
      )
        return 'Scanner.scss pool ID does not match the scanner pool';
      if (willNewScannerShutdownPool) {
        return 'Please stake more FORT tokens to avoid shutting down the scanner pool';
      }
      return '';
    }
    return '';
  })();

  const registerScanNode = async (): Promise<TransactionResponse | null> => {
    if (!parsedValue) return null;
    if (scannerPoolRegistry) {
      return scannerPoolRegistry.registerScanNode(
        parsedValue?.registrationInput,
        parsedValue?.signature
      );
    }
    return null;
  };

  return (
    <>
      <Button
        className="AddScanNodeModal__button"
        variant="primary"
        size="md"
        onClick={() => setOpened(true)}
        disabled={willNewScannerShutdownPool}
      >
        Add Scan Node
      </Button>
      {willNewScannerShutdownPool ? (
        <div className="AddScanNodeModal__missing-stake">
          Please stake more FORT tokens
        </div>
      ) : null}
      <Modal
        opened={opened}
        onCloseModal={() => setOpened(false)}
        title="Add a scan node"
      >
        <div className="AddScanNodeModal">
          <div className="AddScanNodeModal__description">
            To add a scan node, please generate the authorization data and paste
            it here.
          </div>
          <div className="AddScanNodeModal__text">
            <TextArea
              name="authorizationData"
              placeholder="base64 encoded authorization data..."
              minRows={5}
              maxRows={5}
              className={classNames({
                AddScanNodeModal__text__input: true,
                'AddScanNodeModal__text__input--yellow':
                  parsedValue && validation,
                'AddScanNodeModal__text__input--red': !parsedValue,
                'AddScanNodeModal__text__input--green':
                  parsedValue && !validation
              })}
              onChange={(event) => setValue(event.target.value)}
              value={value}
            />
          </div>
          <div>
            {parsedValue ? (
              <InformationBox.Container>
                <InformationBox.Title>Authorization Data</InformationBox.Title>
                <InformationBox.Group>
                  <InformationBox.Item>
                    <InformationBox.Label>Scan Node</InformationBox.Label>
                    <InformationBox.Value>
                      <div className="AddScanNodeModal__address">
                        <ChainIcon
                          network={getNetworkByChainId(
                            parsedValue.registrationInput.chainId
                          )}
                        />
                        {parsedValue.registrationInput.scanner}
                      </div>
                    </InformationBox.Value>
                  </InformationBox.Item>
                  <InformationBox.Item>
                    <InformationBox.Label>
                      For Scan Node Pool
                    </InformationBox.Label>
                    <InformationBox.Value>
                      #{parsedValue.registrationInput.scannerPoolId}
                    </InformationBox.Value>
                  </InformationBox.Item>
                  <InformationBox.Item>
                    <InformationBox.Label>Metadata</InformationBox.Label>
                    <InformationBox.Value>
                      {parsedValue.registrationInput.metadata || 'None'}
                    </InformationBox.Value>
                  </InformationBox.Item>
                  <InformationBox.Item>
                    <InformationBox.Label>
                      Signature timestamp
                    </InformationBox.Label>
                    <InformationBox.Value>
                      {moment(
                        parsedValue.registrationInput.timestamp * 1000
                      )?.fromNow()}
                    </InformationBox.Value>
                  </InformationBox.Item>
                </InformationBox.Group>
              </InformationBox.Container>
            ) : (
              <div className="AddScanNodeModal__error">
                Invalid authorization data
              </div>
            )}
          </div>
          {validation ? (
            <div className="AddScanNodeModal__error">{validation}</div>
          ) : null}

          {!validation && parsedValue ? (
            <div className="AddScanNodeModal__contract-call">
              <ContractCallButton
                onCall={registerScanNode}
                onCompleted={() => setOpened(false)}
                disabled={willNewScannerShutdownPool}
                size="lg"
              >
                Add Scan Node
              </ContractCallButton>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
}

function ToogleScanNodeEnableModal({
  opened,
  onClose,
  id,
  enabled
}: {
  opened: boolean;
  onClose: () => void;
  id: string;
  enabled: boolean;
}): JSX.Element {
  const jwt = useJWT();
  const web3React = useWeb3React();
  const scannerPoolRegistry =
    jwt &&
    web3React.account &&
    web3React.isActive &&
    new ScannerPoolRegistry(web3React);

  const onButtonCall = (): Promise<TransactionResponse> | undefined => {
    if (scannerPoolRegistry) {
      if (enabled) {
        return scannerPoolRegistry.disableScanNode(id);
      } else {
        return scannerPoolRegistry.enableScanNode(id);
      }
    }
    return undefined;
  };

  return (
    <Modal
      className="EnableDisableModal"
      title={enabled ? 'Disable Scan Node' : 'Enable Scan Node'}
      opened={opened}
      onCloseModal={onClose}
    >
      <div className="EnableDisableModal__description">
        {enabled
          ? 'Are you sure you want to disable this scan node?'
          : 'Are you sure you want to enable this scan node?'}
      </div>
      {onButtonCall && (
        <ContractCallButton onCall={onButtonCall} size="lg">
          {enabled ? 'Disable' : 'Enable'}
        </ContractCallButton>
      )}
    </Modal>
  );
}

export default function ScanNodesList({
  scannerPool
}: {
  scannerPool: ScannerPool;
}): JSX.Element {
  const jwt = useJWT();
  const web3React = useWeb3React();
  const account = web3React.account || '';
  const isOwner = scannerPool.owner.id.toLowerCase() === account.toLowerCase();

  const numOfEnabledNodes = scannerPool.scanNodes.filter(
    (node) => node.enabled === true
  ).length;
  const scanNodesNonOperational =
    formatFORTToNumber(scannerPool.stakeOwnedAllocated) / numOfEnabledNodes <
    2500;

  const [enableModalOpened, setEnableModalOpened] = useState<boolean>(false);
  const [selectedScanNodeEnabled, setSelectedScanNodeEnabled] =
    useState<boolean>(true);
  const [selectedScanNodeId, setSelectedScanNodeId] = useState<string>('');

  const onEditScanNode = (id: string, enabled = true): void => {
    setSelectedScanNodeId(id);
    setSelectedScanNodeEnabled(enabled);
    setEnableModalOpened(true);
  };

  const shouldShowActions = jwt && isOwner;

  const scanner_ids = scannerPool.scanNodes.map((scanNode) => scanNode.id);
  const { isLoading, data } = useQuery({
    queryKey: ['slaResults', scanner_ids],
    queryFn: async () => await getMultiSLAs({ scanner_ids })
  });

  const setSubCellValue = (
    id: string,
    data: ScannerMultiSlaResult[] | undefined,
    type: 'sla' | 'uptime'
  ): string => {
    const dataForId = data?.find((slaResult) => slaResult.id === id);

    if (dataForId === undefined) {
      return 'No Data Available';
    }

    if (type === 'sla' && 'avgSLA' in dataForId.slaAvgOverTime.sla) {
      return formatNumber(dataForId.slaAvgOverTime.sla.avgSLA, 2);
    } else if (
      type === 'uptime' &&
      'avgUptime' in dataForId.slaAvgOverTime.uptime
    ) {
      return formatNumber(dataForId.slaAvgOverTime.uptime.avgUptime * 100, 0);
    } else {
      return 'No Data Available';
    }
  };

  // Sort by enabled
  const sortedScanNodes = useMemo(() => {
    return [...scannerPool.scanNodes].sort((a, b) => {
      return b.enabled === a.enabled ? 0 : b.enabled ? 1 : -1;
    });
  }, [scannerPool.scanNodes]);

  return (
    <div className="ScannerPoolPage__scan-nodes">
      <Table minWidth={600}>
        <TableHead>
          <TableHeading
            title="Scan Nodes"
            flex={shouldShowActions ? 14 : 13}
            tooltipEnabled={scanNodesNonOperational}
            tooltip="Warning! The pool owner stake does not satisfy the minimum amount of FORT required for this pool. All nodes are considered non-operational and will not generate any rewards."
          >
            <TableSubHeading title="Address" flex={10} />
            <TableSubHeading
              title="Uptime"
              tooltip="The rate of online hours over the past 168hr period divided by 168 (current hour not included)."
              tooltipTopPositioned={true}
              flex={4}
            />
            <TableSubHeading
              title="SLA"
              tooltip="Average scanner SLA score during the online hours over the past 168hr period (current hour not included)."
              tooltipTopPositioned={true}
              flex={4}
            />
            <TableSubHeading
              title="Status"
              tooltip="Scan nodes could be non-operational and no rewards generated when the pool is below the minimum stake requirement, even if the node operatator has enabled the scan nodes."
              tooltipTopPositioned={true}
              flex={4}
            />
          </TableHeading>
          {shouldShowActions && <TableActionHeading />}
        </TableHead>
        <TableBody empty={scannerPool.scanNodes.length === 0}>
          {sortedScanNodes.map((scanNode) => (
            <TableRow key={`scan-node-row-${scanNode.id}-${scannerPool.id}`}>
              <TableCell flex={shouldShowActions ? 14 : 13}>
                <TableSubCell
                  value={
                    <NodeEntity
                      target="_blank"
                      address={scanNode.id}
                      enabled={scanNode.enabled}
                      chainId={scannerPool.chainId}
                      href={`/scan-node/${scanNode.id}`}
                    />
                  }
                  flex={10}
                />
                <TableSubCellWithHealthIndicator
                  value={setSubCellValue(scanNode.id, data, 'uptime' as const)}
                  flex={4}
                  type="uptime"
                  isLoading={isLoading}
                />
                <TableSubCellWithHealthIndicator
                  value={setSubCellValue(scanNode.id, data, 'sla' as const)}
                  flex={4}
                  type={'sla'}
                  isLoading={isLoading}
                />
                <TableSubCell
                  value={scanNode.enabled ? 'Enabled' : 'Disabled'}
                  flex={4}
                />
              </TableCell>
              {shouldShowActions && (
                <ActionCell
                  id={scanNode.id}
                  options={[
                    {
                      label: 'Enable',
                      disabled: scanNode.enabled,
                      onClick: () =>
                        onEditScanNode(scanNode.id, scanNode.enabled)
                    },
                    {
                      label: 'Disable',
                      disabled: !scanNode.enabled,
                      onClick: () =>
                        onEditScanNode(scanNode.id, scanNode.enabled)
                    }
                  ]}
                />
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ToogleScanNodeEnableModal
        opened={enableModalOpened}
        id={selectedScanNodeId}
        enabled={selectedScanNodeEnabled}
        onClose={() => setEnableModalOpened(false)}
      />
      <AddScanNodeButton scannerPool={scannerPool} />
    </div>
  );
}
