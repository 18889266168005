import React from 'react';
import cn from 'classnames';

import './Severity.scss';
import BaseFilterModal, { FilterModalDescription } from '../Base/Base';

import Select from 'common/components-v2/Form/Select/Select';
import {
  AlertSeverities,
  AlertSeverity
} from 'forta-app/slices/notificationSlice';

type SeverityModalProps = {
  open?: boolean;
  value?: AlertSeverity;
  onChange: (severity?: AlertSeverity) => unknown;
  onClose: () => unknown;
  className?: string;
};

function SeverityModal(props: SeverityModalProps): JSX.Element {
  const { open, value, onChange, onClose, className } = props;

  return (
    <BaseFilterModal
      open={open}
      value={value}
      title="Minimum Severity"
      className={cn('SeverityFilterModal', className)}
      onChange={onChange}
      onClose={onClose}
    >
      {({ value, setValue }) => (
        <>
          <FilterModalDescription>
            Severity indicates the impact level of the alert. <br />
            Ex: If you select &quot;Medium&quot; any alert below
            &quot;Medium&quot; severity will not be included in this
            subscription.
          </FilterModalDescription>
          <Select<AlertSeverity | undefined>
            clearable
            name="forta-notification-source-type"
            value={value}
            menuPosition="fixed"
            options={AlertSeverities.map((element) => ({
              value: element,
              label: element.toUpperCase()
            }))}
            onChange={(event) => setValue(event.target.value)}
            placeholder="Any severity..."
          />
        </>
      )}
    </BaseFilterModal>
  );
}

export default SeverityModal;
