import * as isIPFS from 'is-ipfs';

import {
  DocumentationItem,
  isDocumentationFileItem,
  isDocumentationIpfsItem
} from './BotEditForm';

import { getRepositoryHref, readFileAsync } from 'common/lib/utils';

const SIZE_1_MB = 1048576;

// https://stackoverflow.com/a/5717133
const urlRegex = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i'
);

export const validations: {
  [name: string]: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    val: any
  ) => string | undefined | Promise<string | undefined>;
} = {
  validateName(name: string) {
    if (name.length > 60) return 'Name is too long';
    const validCharRegex = RegExp(/([0-9A-Za-zÀ-ÖØ-öø-ÿ_. -]+)/);
    const validCharRes = validCharRegex.exec(name);
    if (!validCharRes || validCharRes[0] !== name) {
      return 'Invalid bot name';
    }
  },
  validateDescription(description: string) {
    if (description.length > 100) return 'Too long description';
  },
  validateLongDescription(description: string) {
    if (description.length > 1000) return 'Too long description';
  },
  validateRepository(repository: string) {
    if (repository.length === 0) return undefined;
    if (!getRepositoryHref(repository))
      return 'Only GitHub or GitLab repository HTTPS URLs';
  },
  validateLicenseUrl(url: string) {
    if ((url.length != 0 && !urlRegex.test(url)) || url.length > 1000)
      return 'Not a valid url';
  },
  validateImage(image: string) {
    if (image.length === 0) return 'Image cannot be empty';
    const imageRegex = RegExp(/^[a-z0-9]{59}@sha256:[a-f0-9]{64}$/);
    if (!image.match(imageRegex)) return 'Invalid format';
    if (!isIPFS.base32cid(image.substring(0, image.indexOf('@sha256:')))) {
      return 'Invalid format';
    }
  },
  validateVersion(version: string) {
    if (version?.length === 0) return 'No version';

    const validCharRegex = RegExp(/([0-9A-Za-z.]+)/);
    const validCharRes = validCharRegex.exec(version);
    if (!validCharRes || validCharRes[0] !== version) {
      return 'Invalid format';
    }
  },
  validatePromoUrl(url: string) {
    if (url.length === 0) return;
    if (!urlRegex.test(url) || url.length > 1000) {
      return 'Not a valid url';
    }
  },
  validateSelectedNetworks(items: Array<number>) {
    if (items.length === 0) return 'At least one network required';
  },
  validateNetworksToShard(networks: string) {
    if (networks.length === 0) return;
    const pattern = new RegExp('\\d+(,\\s*\\d+)*', 'g');
    // Convert matchAll results to an array
    const matches = Array.from(networks.matchAll(pattern));
    // Check if there's exactly one match that covers the entire networks string
    let isValid = false;
    if (matches.length === 1) {
      const [match] = matches;
      isValid = match[0] === networks;
    }
    if (!isValid) return 'Invalid pattern';
  },
  async validateDocumentation(
    items: DocumentationItem[]
  ): Promise<string | undefined> {
    if (items.length === 0)
      return 'At least one documentation file must be uploaded';

    const names = new Set<string>();
    for (const item of items) {
      const title = item.title.trim();

      if (names.has(title)) {
        return 'Multiple files have the same title';
      }

      if (isDocumentationFileItem(item)) {
        if (!item.file) {
          return 'There is no documentation file';
        }

        const content = await readFileAsync(item.file);

        if (content.trim().length === 0) {
          return 'Documentation file is empty';
        }
      }

      names.add(title);
    }
  },
  validateDocumentationItem(item: DocumentationItem) {
    if (!item.title.trim()) return 'File must be named';

    if (item.title.length < 4) return 'Too short title';
    if (item.title.length > 20) return 'Too long title';

    if (isDocumentationIpfsItem(item)) {
      return;
    } else {
      if (!item.file) return 'No file selected';

      if (item.file.size > SIZE_1_MB) return 'File is too large (max 1MB).';
    }
  }
};
