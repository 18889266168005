import { ethers } from 'ethers';
import { BaseContract } from '@ethersproject/contracts/src.ts';
import { TransactionResponse } from '@ethersproject/providers';
import { toast } from 'react-toastify';

const GAS_MULTIPLIER = 1.15;
const GAS_PRICE_MULTIPLIER = 1.5;

export default class ContractInteractor<T> {
  protected provider: ethers.providers.JsonRpcProvider;
  protected signer?: ethers.providers.JsonRpcSigner;
  protected contract: BaseContract & T;

  constructor(
    provider: ethers.providers.JsonRpcProvider,
    contractAbi: ethers.ContractInterface,
    address: string,
    signer?: ethers.providers.JsonRpcSigner
  ) {
    this.provider = provider;
    this.signer = signer;
    this.contract = new ethers.Contract(
      address,
      contractAbi,
      signer || provider
    ) as unknown as BaseContract & T;
  }

  protected async getTxOptions(
    gasLimit: ethers.BigNumber
  ): Promise<{ gasLimit: number; gasPrice: number }> {
    const gasPrice = await this.provider.getGasPrice();
    return {
      gasLimit: Math.round(gasLimit.toNumber() * GAS_MULTIPLIER),
      gasPrice: Math.round(gasPrice.toNumber() * GAS_PRICE_MULTIPLIER)
    };
  }

  public async handleTransaction(
    tx: TransactionResponse | Promise<TransactionResponse>
  ): Promise<ethers.providers.TransactionReceipt | null> {
    try {
      return (await tx).wait();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.code === 'ACTION_REJECTED') {
        // toast.error('Action rejected');
      } else {
        toast.error(`Transaction failed: ${e.message || 'Unknown reason'}`);
      }

      throw e;
    }
  }
}
