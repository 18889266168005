import { InputComponentRenderProps } from './Component/InputComponent';

export const PADDING_X = 20; // left and right input paddings
export const COMPONENTS_GAP = 10; // gap between components

export function calcPadding(
  components: InputComponentRenderProps[],
  direction: 'left' | 'right',
  minValue = 0
): number {
  const [prevKey, nextKey]: ('marginLeft' | 'marginRight')[] =
    direction === 'left'
      ? ['marginLeft', 'marginRight']
      : ['marginRight', 'marginLeft'];

  let sum = components[0]?.[prevKey] ?? COMPONENTS_GAP;
  for (let i = 0; i < components.length; i++) {
    const current = components[i];
    const next = components[i + 1];
    sum +=
      current.width +
      Math.max(
        current[nextKey] ?? COMPONENTS_GAP,
        next?.[prevKey] ?? COMPONENTS_GAP
      );
  }

  return Math.max(sum, minValue);
}
