import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import './TextArea.scss';

type TextAreaProps = {
  name: string;
  value?: string;
  minRows: number;
  maxRows: number;
  placeholder?: string;
  className: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
};

const TextArea = ({
  name,
  value = '',
  minRows = 4,
  maxRows = 12,
  placeholder,
  className,
  onChange
}: TextAreaProps): JSX.Element => {
  if (!value) {
    value = '';
  }

  const classNames = cn('TextArea', className, {});

  return (
    <TextareaAutosize
      name={name}
      id={name}
      placeholder={placeholder}
      className={classNames}
      value={value}
      minRows={minRows}
      maxRows={maxRows}
      onChange={onChange}
    />
  );
};

export default TextArea;
