import { SubjectType } from '../contract-interactors/stakingContract';
import { TransactionResponse } from '@ethersproject/providers';
import {
  ProtocolTransaction,
  TransactionType,
  getProtocolTransactionStorage,
  transactionTypeDetails
} from './TransactionsStorage';

export function parseProtocolTransaction(tx: {
  hash: string;
  blockNumber?: number;
  data: string;
  confirmed?: boolean;
}): ProtocolTransaction | null {
  const { hash, blockNumber, data, confirmed } = tx;
  const methodId = data.slice(0, 10) as string;
  const transactionType = Object.values(transactionTypeDetails).find(
    (_transactionType) => _transactionType.methodId === methodId
  );
  if (!transactionType) return null;
  const arg1 = data.slice(10, 74);
  const arg2 = data.slice(74, 138);
  const arg3 = data.slice(138);
  let subject = '';
  let amount = '0';
  let subjectType = SubjectType.BOT;

  switch (transactionType.id) {
    case TransactionType.STAKE_DEPOSIT:
      subjectType = Number(arg1) as SubjectType;
      subject = arg2.replace(/^0+/, '');
      amount = arg3;
      break;
    case TransactionType.STAKE_INITIATED_WITHDRAW:
    case TransactionType.STAKE_WITHDRAW:
      subjectType = Number(arg1) as SubjectType;
      subject = arg2.replace(/^0+/, '');
      break;
    case TransactionType.BOT_CREATED:
    case TransactionType.BOT_UPDATED:
    case TransactionType.BOT_ENABLED:
    case TransactionType.BOT_DISABLED:
      subjectType = SubjectType.BOT;
      subject = arg1;
      break;
    case TransactionType.NODEPOOL_CREATED:
      subjectType = SubjectType.SCANNERPOOL;
      subject = arg1;
      break;
    case TransactionType.NODEPOOL_COMISSION:
      subjectType = SubjectType.SCANNERPOOL;
      subject = arg2;
      amount = arg3;
      break;
    case TransactionType.NODEPOOL_ENABLE_SCANNER:
    case TransactionType.NODEPOOL_DISABLE_SCANNER:
      subjectType = SubjectType.SCANNER;
      subject = arg1;
      break;
    case TransactionType.CLAIM_REWARD:
      subjectType = Number(arg1) as SubjectType;
      subject = arg2;
      amount = arg3;
      break;
    case TransactionType.ALLOCATE_OWN_STAKE:
    case TransactionType.ALLOCATE_DELEGATED_STAKE:
    case TransactionType.UNALLOCATE_OWN_STAKE:
    case TransactionType.UNALLOCATE_DELEGATED_STAKE:
      subjectType = Number(arg1) as SubjectType;
      subject = arg2;
      amount = arg3;
      break;
    case TransactionType.NODEPOOL_NODE_REGISTERED:
      subjectType = SubjectType.SCANNERPOOL;
      break;
    default:
      return null;
  }

  return {
    hash,
    blockNumber: blockNumber || Number.MAX_SAFE_INTEGER,
    transactionType: transactionType.id,
    subjectId: subject,
    subjectType,
    amount: '0x' + amount,
    timestamp: Date.now(),
    confirmed: !!confirmed
  };
}

export default function addProtocolTransaction(tx: TransactionResponse): void {
  const storage = getProtocolTransactionStorage(tx.from);
  const protocolTransaction = parseProtocolTransaction(tx);
  if (protocolTransaction) {
    storage.addTransaction(protocolTransaction);
    tx.wait(1).then((receipt) => {
      storage.confirmTransaction(protocolTransaction.hash, receipt.blockNumber);
    });
  }
}
