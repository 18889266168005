import chroma from 'chroma-js';

import SelectFilter from 'common/components/SelectFilter';
import { SeverityIcon } from 'common/components/Icons';

import { SeveritiesInfo } from 'common/lib/apis/alertAPI';

interface FilterParams {
  onChange: (selection: string[]) => void;
  selection: string[];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const colorStyles = {
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? 'white'
        : isFocused
        ? color
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : isFocused
        ? 'white'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color)
      }
    };
  },
  multiValue: (styles: any, { data }: any) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color,
      cursor: 'pointer'
    };
  },
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    backgroundColor: data.color,
    borderRadius: '50px 0 0 50px',
    color: 'white',
    fontWeight: '600',
    paddingLeft: '10px'
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: data.color,
    borderRadius: '0 50px 50px 0',
    paddingRight: '8px',
    marginRight: '2px',
    ':hover': {
      color: '#ccc',
      cursor: 'pointer'
    }
  })
};

export default function SeverityFilter({
  onChange,
  selection
}: FilterParams): JSX.Element {
  const options = Object.keys(SeveritiesInfo).map((severityName) => ({
    value: severityName,
    label: SeveritiesInfo[severityName].label,
    color: SeveritiesInfo[severityName].color
  }));

  return (
    <SelectFilter
      options={options}
      placeholder="Filter by severity"
      isSearchable={false}
      onSelect={onChange}
      defaultValue={options.filter((o) => selection.includes(o.value))}
      icon={SeverityIcon}
      styles={colorStyles}
    />
  );
}
