export const currentENV = (() => {
  switch (process.env.CONTEXT) {
    case 'production':
      return 'production';
    case 'local':
      return 'local';
    default:
      return 'staging';
  }
})();

export interface ConfigEnvType {
  apiEndpoint: string;
  subgraphAPIEndpoint: string;
  unlockSubgraphAPIEndpoint: string;
  agentRegistryContractAddress: string;
  stakingContractAddress: string;
  FORTContractAddress: string;
  dispatchContractAddress: string;
  scannerPoolRegistryAddress: string;
  rewardsDistributorAddress: string;
  stakeAllocatorAddress: string;
  stakingVault: string;
  appEndpoint: string;
  explorerEndpoint: string;
  wizardEndpoint: string;
  rpcEndpoint: string;
  walletConnectProjectId: string;
  chainExplorer: string;
  chainLabel: string;
  chainNativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  chainId: number;
}

export const configs: {
  local: ConfigEnvType;
  staging: ConfigEnvType;
  production: ConfigEnvType;
} = {
  local: {
    apiEndpoint:
      process.env.EXPLORER_API ||
      'https://explorer-api-dev.forta.network/graphql',
    subgraphAPIEndpoint:
      'https://testnets.graph-eu.p2pify.com/880f4d3d7cd9b4b7ed8217d45c5c6a80/forta-dev-base-sepolia',
    unlockSubgraphAPIEndpoint:
      'https://api.studio.thegraph.com/query/65299/unlock-protocol-base-sepolia/version/latest',
    appEndpoint: 'https://app-dev.forta.network',
    explorerEndpoint: 'https://explorer-dev.forta.network',
    wizardEndpoint: 'https://wizard-dev.forta.network',
    stakingVault: '0x4E29Cea6D64be860f5eAba110686DcB585f393D6',
    agentRegistryContractAddress: '0x0C8B8DeC21Aa72179e8C4703a9B55d9Db061D423',
    stakingContractAddress: '0x1ed86971E4C4f3d13b6b8030D80C7D609de7139c',
    FORTContractAddress: '0x38C0F07ECA98b87757Fb76C56Be779b79C676062',
    scannerPoolRegistryAddress: '0x786e4446bB39F5480310FE98c48d2d5e6129A4cC',
    rewardsDistributorAddress: '0x414E8777Df3e3Af4Ed3e4bA9A2266779F86558e4',
    stakeAllocatorAddress: '0xc054a3DABfFEb0279E62669FB699a33D4cC5fC62',
    dispatchContractAddress: '0x452C7044B9977de45ed7621ba0Bb77E312663157',
    rpcEndpoint: 'https://base-sepolia.blockpi.network/v1/rpc/public',
    walletConnectProjectId: '07bc5ac193e5170f13ed9eac02af80f3',
    chainExplorer: 'https://sepolia.basescan.org',
    chainLabel: 'Base Sepolia',
    chainNativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    chainId: 84532
  },
  staging: {
    apiEndpoint: 'https://explorer-api-dev.forta.network/graphql',
    subgraphAPIEndpoint:
      'https://testnets.graph-eu.p2pify.com/880f4d3d7cd9b4b7ed8217d45c5c6a80/forta-dev-base-sepolia',
    unlockSubgraphAPIEndpoint:
      'https://api.studio.thegraph.com/query/65299/unlock-protocol-base-sepolia/version/latest',
    appEndpoint: 'https://app-dev.forta.network',
    explorerEndpoint: 'https://explorer-dev.forta.network',
    wizardEndpoint: 'https://wizard-dev.forta.network',
    stakingVault: '0x4E29Cea6D64be860f5eAba110686DcB585f393D6',
    agentRegistryContractAddress: '0x0C8B8DeC21Aa72179e8C4703a9B55d9Db061D423',
    stakingContractAddress: '0x1ed86971E4C4f3d13b6b8030D80C7D609de7139c',
    FORTContractAddress: '0x38C0F07ECA98b87757Fb76C56Be779b79C676062',
    scannerPoolRegistryAddress: '0x786e4446bB39F5480310FE98c48d2d5e6129A4cC',
    rewardsDistributorAddress: '0x414E8777Df3e3Af4Ed3e4bA9A2266779F86558e4',
    stakeAllocatorAddress: '0xc054a3DABfFEb0279E62669FB699a33D4cC5fC62',
    dispatchContractAddress: '0x452C7044B9977de45ed7621ba0Bb77E312663157',
    rpcEndpoint: 'https://base-sepolia.blockpi.network/v1/rpc/public',
    walletConnectProjectId: '07bc5ac193e5170f13ed9eac02af80f3',
    chainExplorer: 'https://sepolia.basescan.org',
    chainLabel: 'Base Sepolia',
    chainNativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    chainId: 84532
  },
  production: {
    apiEndpoint: 'https://explorer-api.forta.network/graphql',
    subgraphAPIEndpoint:
      'https://api.studio.thegraph.com/query/32147/forta-network/version/latest',
    unlockSubgraphAPIEndpoint:
      'https://api.studio.thegraph.com/query/65299/unlock-protocol-polygon/version/latest',
    appEndpoint: 'https://app.forta.network',
    explorerEndpoint: 'https://explorer.forta.network',
    wizardEndpoint: 'https://wizard.forta.network',
    agentRegistryContractAddress: '0x61447385B019187daa48e91c55c02AF1F1f3F863',
    stakingContractAddress: '0xd2863157539b1d11f39ce23fc4834b62082f6874',
    stakingVault: '0xF22F690A41d22496496d4959acFFf0f3baCC24F1',
    FORTContractAddress: '0x9ff62d1fc52a907b6dcba8077c2ddca6e6a9d3e1',
    scannerPoolRegistryAddress: '0x90ff9c193d6714e0e7a923b2bd481fb73fec731d',
    rewardsDistributorAddress: '0xf7239f26b79145297737166b0c66f4919af9c507',
    stakeAllocatorAddress: '0x5B73756e637A77Fa52e5Ce71EC6189A4C775c6FA',
    dispatchContractAddress: '0xd46832F3f8EA8bDEFe5316696c0364F01b31a573',
    rpcEndpoint: 'https://polygon-bor-rpc.publicnode.com',
    walletConnectProjectId: 'f5f36d453ad86cebcba25654de7fd527',
    chainExplorer: 'https://polygonscan.com/',
    chainLabel: 'Polygon',
    chainNativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'MATIC',
      decimals: 18
    },
    chainId: 137
  }
};

export default configs[currentENV];
