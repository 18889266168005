import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import './NetworkPage.scss';

import { getNetworkByChainId } from 'common/lib/networks';
import Loader from 'common/components/Loader';
import InfoPopover from 'common/components/InfoPopover';

import {
  ChainAPIReponse,
  getChains,
  getChainScanners,
  ScannerAPIResponse
} from 'forta-app/lib/apis/networkAPI';
import ChainIcon from 'common/components/stats/ChainIcon';
import classNames from 'classnames';
import { shortenHash } from 'common/lib/utils';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

function ChainList(params: {
  chains: ChainAPIReponse[];
  loading: boolean;
  onSelectChain: (chainId: number) => void;
}): JSX.Element {
  return (
    <div className="ChainList">
      <div className="ChainList__list">
        <div className="ChainList__item ChainList__list-header">
          <div className="ChainList__item__name">Name</div>
          <div className="ChainList__item__scanners">
            Node operators{' '}
            <InfoPopover
              content="Number of active nodes in the network."
              rightPositioned
            />
          </div>
          <div className="ChainList__item__agents">
            Detection Bots{' '}
            <InfoPopover
              content="Number of detection bots running on the network."
              rightPositioned
            />
          </div>
        </div>
        {params.chains.map((item, index) => {
          const network = getNetworkByChainId(item.chainId);
          return (
            <div className="ChainList__item" key={`chain-list=${item.chainId}`}>
              <div className="ChainList__item__name">
                <div className="ChainList__item__number">{index + 1}</div>
                <div className="ChainList__item__icon">
                  <ChainIcon network={network} />
                </div>
                <button
                  className="ChainList__item__label"
                  onClick={() => params.onSelectChain(network.chainId)}
                >
                  {network.label}
                </button>
              </div>
              <div className="ChainList__item__scanners">{item.scanners}</div>
              <div className="ChainList__item__agents">{item.bots}</div>
            </div>
          );
        })}
        {params.loading ? <Loader /> : null}
      </div>
    </div>
  );
}

export function ScannerList({
  chains,
  selectedChain,
  onSelectedChainChange,
  scanners,
  loading
}: {
  chains: ChainAPIReponse[];
  selectedChain: number;
  onSelectedChainChange?: (value: number) => void;
  scanners: ScannerAPIResponse[];
  loading: boolean;
}): JSX.Element {
  return (
    <div className="ScannerList">
      {chains.length > 1 ? (
        <div className="ButtonGroupMenu">
          {chains
            .map((chain) => getNetworkByChainId(chain.chainId))
            .map((network) => {
              return (
                <button
                  key={`chain-menu-item__${network.name}`}
                  className={classNames({
                    ButtonGroupMenu__item: true,
                    ButtonGroupMenu__item__selected:
                      selectedChain === network.chainId
                  })}
                  onClick={onSelectedChainChange?.bind(null, network.chainId)}
                >
                  {network.label}
                </button>
              );
            })}
        </div>
      ) : null}
      <div className="ScannerList__list">
        <div className="ScannerList__item ScannerList__list-header">
          <div className="ScannerList__item__id">Node operator</div>
          <div className="ScannerList__item__date">Creation Date</div>
        </div>
        {scanners.map((scanner) => {
          return (
            <div
              key={`scanner-item-${selectedChain}-${scanner.id}`}
              className="ScannerList__item"
            >
              <div className="ScannerList__item__id">
                <NavLink to={`/scan-node/${scanner.id}`}>
                  <span className="ScannerList__item__id__hash">
                    {scanner.id}
                  </span>
                  <span className="ScannerList__item__id__hash-short">
                    {shortenHash(scanner.id)}
                  </span>
                </NavLink>
              </div>
              <div className="ScannerList__item__date">
                <span className="ScannerList__item__id__hash">
                  {moment(scanner.created_at).fromNow()}
                </span>
              </div>
            </div>
          );
        })}
        {loading ? <Loader /> : null}
      </div>
    </div>
  );
}

export default function NetworkPage(): JSX.Element {
  const [chains, setChains] = useState<ChainAPIReponse[]>([]);
  const [loadingChains, setLoadingChains] = useState<boolean>(false);
  const [selectedChain, setSelectedChain] = useState<number>(1);
  const [scanners, setScanners] = useState<ScannerAPIResponse[]>([]);
  const [loadingScanners, setLoadingScanners] = useState<boolean>(false);

  const loadChain = useCallback(async (chain_id: number): Promise<void> => {
    setSelectedChain(chain_id);
    setLoadingScanners(true);
    const chainScanners = await getChainScanners(chain_id);
    setScanners(chainScanners);
    setLoadingScanners(false);
  }, []);

  useEffect(() => {
    loadChain(1);
    setLoadingChains(true);
    getChains()
      .then((_chains) => {
        setChains(_chains);
        setLoadingChains(false);
      })
      .catch((error) => {
        toast.error('Error trying to retrive the list of covered blockchains.');
        console.error(error);
        setLoadingChains(false);
      });
  }, [loadChain]);

  return (
    <div className="NetworkPage">
      <div className="NetworkPage__header">
        <div className="NetworkPage__title">
          Network <span className="color-purple">Activity</span>
        </div>
        <div className="NetworkPage__description">
          Information about the health and activity of the Forta Network,
          including blockchain coverage and active node operators
        </div>
      </div>
      <ChainList
        chains={chains}
        loading={loadingChains}
        onSelectChain={(chainId) => loadChain(chainId)}
      />
      <ScannerList
        chains={chains}
        selectedChain={selectedChain}
        scanners={scanners}
        loading={loadingChains || loadingScanners}
        onSelectedChainChange={(chainId) => loadChain(chainId)}
      />
    </div>
  );
}
