import { useState } from 'react';
import StakeModal from '../staking/StakingModal';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import { useJWT } from 'forta-app/app/hooks';
import Button from 'common/components-v2/Button/Button';
import './ScanNodePoolActionsButton.scss';
import { DelegatedFormProps } from '../staking/StakingForm';
import { useConnectModal } from '../wallet/useConnectModal';

interface StakeModalProps {
  opened: boolean;
  scannerPoolId: string;
  delegation?: boolean;
  delegatedForm?: DelegatedFormProps;
  onClose: () => void;
}

export const StakeScanNodePoolModal = (props: StakeModalProps): JSX.Element => {
  return (
    <div className="ScannerPoolStakingModal">
      <StakeModal
        opened={props.opened}
        subjectId={props.scannerPoolId}
        subjectType={
          props.delegation
            ? SubjectType.SCANNERPOOL_DELEGATOR
            : SubjectType.SCANNERPOOL
        }
        delegatedForm={props.delegatedForm}
        onClose={props.onClose}
      />
    </div>
  );
};

export const AddScanNodePoolStakeButton = ({
  scannerPoolId,
  isOwner,
  scanNodes,
  delegatedForm
}: {
  scannerPoolId: string;
  isOwner: boolean;
  delegatedForm?: DelegatedFormProps;
  scanNodes: number;
}): JSX.Element => {
  const jwt = useJWT();
  const { open: openConnectModal } = useConnectModal();
  const [opened, setOpened] = useState<boolean>(false);
  const isDelegation = !isOwner;
  const stakingDisabledReason = ((): string => {
    if (isDelegation && scanNodes === 0) return 'Pool has no scan nodes';
    if (isDelegation && delegatedForm?.allocation.stakeOwnedAllocated?.isZero())
      return 'Owner is not staking.';
    return '';
  })();
  const stakingDisabled = !!stakingDisabledReason;

  const handleClick = (): void => {
    if (jwt) {
      setOpened(true);
    } else {
      openConnectModal();
    }
  };

  return (
    <div className="ActionButton">
      <Button
        className="ActionButton__button"
        size="lg"
        variant="primary"
        disabled={stakingDisabled}
        onClick={handleClick}
      >
        {!isOwner ? 'Delegate' : 'Add Stake'}
      </Button>
      {stakingDisabled ? (
        <div className="ActionButton__disabled-text">
          {stakingDisabledReason}
        </div>
      ) : null}
      <StakeScanNodePoolModal
        opened={opened}
        scannerPoolId={scannerPoolId}
        delegation={isDelegation}
        delegatedForm={isDelegation ? delegatedForm : undefined}
        onClose={() => setOpened(false)}
      />
    </div>
  );
};
