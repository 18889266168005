import React, { useCallback } from 'react';
import queryString from 'query-string';

import './Filters.scss';

import { trackEvent } from 'common/lib/analytics';
import SeverityFilter from './SeverityFilter';
import TransactionFilter from './TransactionFilter';
import DateFilter from './DateFilter';
import AddressFilter from './AddressFilter';
import AgentFilter from './AgentFilter';
import TextFilter from './TextFilter';
import MutedFilter from './MutedFilter';
import SavedFilters from './SavedFilters';
import { AlertsApiParams, Severities } from 'common/lib/apis/alertAPI';
import { DEFAULT_FILTER } from 'forta-app/components/tools/alert-combiner/AnalysisResult';

export const getDefaultState: () => AlertsApiParams = () => DEFAULT_FILTER;

export function getStateFromQuery(query: string): AlertsApiParams {
  const params = queryString.parse(query, { arrayFormat: 'bracket' });
  const defaultState = getDefaultState();

  const getSeverity = (list: string | (string | null)[] | null): string[] => {
    if (Array.isArray(list))
      return Severities.filter((item) => list.includes(item));
    else return defaultState.severity || [];
  };

  return {
    ...defaultState,
    severity: getSeverity(params['severity']),
    startDate: Array.isArray(params['startDate'])
      ? String(params['startDate'])
      : defaultState.startDate,
    endDate: Array.isArray(params['endDate'])
      ? String(params['endDate'])
      : defaultState.endDate,
    addresses: Array.isArray(params['addresses'])
      ? (params['addresses'] as string[])
      : defaultState.addresses,
    agents: Array.isArray(params['agents'])
      ? (params['agents'] as string[])
      : defaultState.agents,
    txHash: params['txHash'] ? `${params['txHash']}` : defaultState.txHash,
    text: params['text'] ? `${params['text']}` : defaultState.text,
    project: params['project'] ? `${params['project']}` : defaultState.project,
    muted: Array.isArray(params['muted'])
      ? (params['muted'] as string[])
      : defaultState.muted,
    sort: params['sort'] ? `${params['sort']}` : defaultState.sort
  };
}

export default function AdvancedFilters({
  filters,
  onChange
}: {
  onChange: (filters: AlertsApiParams) => void;
  filters: AlertsApiParams;
}): JSX.Element {
  const triggerFilter = useCallback(
    (action: { type: string; filters: AlertsApiParams }): void => {
      trackEvent('filter_search', { label: action.type });
      onChange(action.filters);
    },
    [onChange]
  );

  return (
    <div className="Filters">
      <div className="Filters__option-panel">
        <div className="Filters__date">
          <DateFilter
            onChange={(value) =>
              triggerFilter({
                type: 'filter_dateRange',
                filters: {
                  ...filters,
                  startDate: value[0] ? String(value[0]) : undefined,
                  endDate: value[1] ? String(value[1]) : undefined
                }
              })
            }
            dateRange={[
              Number(filters?.startDate) || undefined,
              Number(filters?.endDate) || undefined
            ]}
          />
        </div>
        <div className="Filters__severity">
          <SeverityFilter
            onChange={(value) =>
              triggerFilter({
                type: 'filter_severity',
                filters: {
                  ...filters,
                  severity: value
                }
              })
            }
            selection={filters.severity || []}
          />
        </div>
        <div className="Filters__saved">
          <SavedFilters
            onSelect={(value) =>
              triggerFilter({
                type: 'filter_custom',
                filters: getStateFromQuery(value)
              })
            }
          />
        </div>
      </div>
      <div className="Filters__row">
        <div className="Filters__addresses">
          <AddressFilter
            onChange={(value) =>
              triggerFilter({
                type: 'filter_addresses',
                filters: {
                  ...filters,
                  addresses: value
                }
              })
            }
            selection={filters.addresses || []}
          />
        </div>
        <div className="Filters__agents">
          <AgentFilter
            onChange={(value) =>
              triggerFilter({
                type: 'filter_agents',
                filters: {
                  ...filters,
                  agents: value
                }
              })
            }
            selection={filters.agents || []}
          />
        </div>
      </div>
      <div className="Filters__row">
        <div className="Filters__text">
          <TextFilter
            onChange={(value) =>
              triggerFilter({
                type: 'filter_text',
                filters: {
                  ...filters,
                  text: value
                }
              })
            }
            selection={filters.text || ''}
            name="text-filter"
            placeholder="Search by text..."
          />
        </div>
        <div className="Filters__transaction">
          <TransactionFilter
            onChange={(value) =>
              triggerFilter({
                type: 'filter_transaction',
                filters: {
                  ...filters,
                  txHash: value
                }
              })
            }
            selection={filters.txHash || ''}
          />
        </div>
      </div>
      <div className="Filters__row">
        <div className="Filters__project">
          <TextFilter
            onChange={(value) =>
              triggerFilter({
                type: 'filter_project',
                filters: {
                  ...filters,
                  project: value
                }
              })
            }
            selection={filters.project || ''}
            name="project-filter"
            placeholder="Search by project..."
          />
        </div>
        <div className="Filters__alert-id">
          <TextFilter
            name="alert-filter"
            onChange={(value) => {
              triggerFilter({
                type: 'filter_alert_hash',
                filters: {
                  ...filters,
                  hash: value.trim() ? value.trim() : undefined
                }
              });
            }}
            selection={filters.hash || ''}
            placeholder="Search by alert hash"
          />
        </div>
      </div>
      {(filters.muted || []).length > 0 && (
        <div className="Filters__muted">
          <MutedFilter
            onChange={(value) =>
              triggerFilter({
                type: 'filter_muted',
                filters: {
                  ...filters,
                  muted: value
                }
              })
            }
            selection={filters.muted || []}
          />
        </div>
      )}
    </div>
  );
}
