import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import BotOverview from './Overview/BotOverview';
import BotHealth from './Health/BotHealth';
import BotStake from './Stake/BotStake';
import BotDocumentation from './Documentation/Documentation';
import './BotPage.scss';

import { isAgentId } from 'common/lib/utils';
import Tabs, { NavTab } from 'common/components-v2/Tabs/Tabs';
import { Routes } from 'common/routes';
import highlightedProjects, { developers } from 'forta-app/data/projects';
import BotDetails from './Details/BotDetails';
import { useScrollToTop } from 'common/components-v2/ScrollToTop/ScrollToTop';
import useBotsQuery from 'forta-app/hooks/useBotsQuery';
import { getBotIdByUrlPath } from 'forta-app/data/plans';
import BotData from './Data/BotData';

type BotPageProps = {
  match: {
    params: {
      id: string;
    };
    path: string;
  };
};

function checkIfNethermind(botId: string): boolean {
  let whitelistedDeveloper = '';
  highlightedProjects.forEach((project) => {
    project.agents.forEach((agent) => {
      if (agent.id.toLowerCase() === botId.toLowerCase()) {
        whitelistedDeveloper =
          developers.find(
            (developer) =>
              developer.address.toLowerCase() === agent.developer.toLowerCase()
          )?.name || '';
      }
    });
  });
  return whitelistedDeveloper === 'Nethermind';
}

function BotPage(props: BotPageProps): JSX.Element {
  const history = useHistory();
  let botId = '';
  let shortenedPath = '';
  if (getBotIdByUrlPath(props.match.path)) {
    botId = getBotIdByUrlPath(props.match.path);
    shortenedPath = props.match.path.slice(1);
  } else {
    botId = isAgentId(props.match.params.id) ? props.match.params.id : '';
  }
  const isNethermindBot = useMemo(() => checkIfNethermind(botId), [botId]);
  const { bots, loading } = useBotsQuery({
    params: { id: botId }
  });
  const bot = bots?.length ? bots[0] : undefined;

  // disable scroll to top on tabs navigation
  useScrollToTop({ disabled: true });

  // but scroll on initial load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!loading && !bot) {
      history.push('/page-not-found');
    }
  }, [history, loading, bot]);

  if (!botId) return <Redirect to={'/page-not-found'} />;

  return (
    <div className="BotPage">
      <Helmet>
        <title>{bot?.name || `Bot ${botId}`} | Forta Explorer</title>
        {isNethermindBot && (
          <>
            <meta
              property="twitter:image"
              content="/forta_alerts_nethermind.png"
            />
            <meta property="og:image" content="/forta_alerts_nethermind.png" />
          </>
        )}
      </Helmet>
      <BotDetails bot={bot} />
      <Tabs className="BotPage__tabs">
        <NavTab
          label="Overview"
          to={
            shortenedPath
              ? Routes.bot.shortenedDetails({
                  shortenedPath: shortenedPath || '#'
                })
              : Routes.bot.details({ id: bot?.id || '#' })
          }
          exact
        />
        {(!bot || bot?.doc_ipfs) && (
          <NavTab
            label="Documentation"
            to={
              shortenedPath
                ? Routes.bot.shortenedDocumentation({
                    shortenedPath: shortenedPath || '#'
                  })
                : Routes.bot.documentation({ id: bot?.id || '#' })
            }
            exact
          />
        )}
        {!bot?.external && (
          <NavTab
            label="Bot Health"
            to={
              shortenedPath
                ? Routes.bot.shortenedHealth({
                    shortenedPath: shortenedPath || '#'
                  })
                : Routes.bot.health({ id: bot?.id || '#' })
            }
            exact
          />
        )}
        <NavTab
          label="Stake"
          to={
            shortenedPath
              ? Routes.bot.shortenedStake({
                  shortenedPath: shortenedPath || '#'
                })
              : Routes.bot.stake({ id: bot?.id || '#' })
          }
          exact
        />
      </Tabs>
      <Switch>
        <Route
          path={
            shortenedPath
              ? Routes.bot.shortenedDocumentationPath
              : Routes.bot.documentationPath
          }
        >
          <BotDocumentation
            documentation={bot?.doc_ipfs}
            developer={bot?.developer}
          />
        </Route>
        <Route
          path={
            shortenedPath
              ? Routes.bot.shortenedHealthPath
              : Routes.bot.healthPath
          }
        >
          <BotHealth botId={botId} />
        </Route>
        <Route
          path={
            shortenedPath ? Routes.bot.shortenedStakePath : Routes.bot.stakePath
          }
        >
          <BotStake bot={bot} />
        </Route>
        <Route
          path={
            shortenedPath
              ? Routes.bot.shortenedDetailsPath
              : Routes.bot.detailsPath
          }
        >
          <BotOverview bot={bot} />
        </Route>
      </Switch>
      <BotData botId={bot?.id} className="BotPage__data" />
    </div>
  );
}

export default BotPage;
