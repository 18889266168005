import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';

import './BotCard.scss';

import CopyButton from 'common/components/CopyButton';
import ChainIcon from 'common/components/stats/ChainIcon';
import { shortenHash } from 'common/lib/utils';
import { Agent } from 'common/lib/apis/agentAPI';
import { getNetworkByChainId } from 'common/lib/networks';
import PlanLabel from 'common/components-v2/PlanLabel/PlanLabel';
import InfoPopover from 'common/components/InfoPopover';
import useBotChains from 'common/hooks/useBotChains';
import useBotPlan from 'common/hooks/useBotPlan';
import { ExternalBotIcon } from '../../components/Icons';

type BotCardProps = {
  bot: Agent;
  className?: string;
};

function BotCard(props: BotCardProps): JSX.Element {
  const { bot, className } = props;

  const { isFreePlan } = useBotPlan({ bot });
  const { chainIds, isChainIdsFetching } = useBotChains({ bot });

  return (
    <div className={cn('BotCard', className)}>
      <div className="BotCard__id">
        {shortenHash(bot.id)} <CopyButton text={bot.id} />{' '}
        {!isFreePlan && (
          <PlanLabel
            planType={bot.subscription_type}
            className="BotCard__plan"
          />
        )}
      </div>
      <div className="BotCard__heading">
        <h3 className="BotCard__title">
          <NavLink to={`/bot/${bot.id}`}>{bot.name}</NavLink>
        </h3>
        <div className="BotCard__chains">
          {bot.external ? (
            <InfoPopover content="External Bot">
              <div className="BotCard__chain">{ExternalBotIcon}</div>
            </InfoPopover>
          ) : isChainIdsFetching ? (
            <>
              <div className="BotCard__chain">
                <Skeleton
                  containerTestId="chain-skeleton"
                  borderRadius={50}
                  width={24}
                  height={24}
                />
              </div>
              <div className="BotCard__chain">
                <Skeleton
                  containerTestId="chain-skeleton"
                  borderRadius={50}
                  width={24}
                  height={24}
                />
              </div>
              <div className="BotCard__chain">
                <Skeleton
                  containerTestId="chain-skeleton"
                  borderRadius={50}
                  width={24}
                  height={24}
                />
              </div>
            </>
          ) : (
            chainIds.map((chainId) => {
              const network = getNetworkByChainId(Number(chainId));
              return (
                <InfoPopover
                  key={`network-${network.chainId}`}
                  content={network.label}
                >
                  <div className="BotCard__chain">
                    <ChainIcon testId="chain-icon" network={network} />
                  </div>
                </InfoPopover>
              );
            })
          )}
        </div>
      </div>
      <div className="BotCard__description">
        {bot.description || 'No description provided yet'}
      </div>
      <div className="BotCard__meta">
        <div className="MetaField">
          <div className="MetaField__label">Developer:</div>
          <div className="MetaField__value">
            {shortenHash(bot.developer)} <CopyButton text={bot.developer} />
          </div>
        </div>
        <div className="MetaField">
          <div className="MetaField__label">Alerts:</div>
          <div className="MetaField__value">
            {(bot.alerts || 0) >= 10_000 ? '10000+' : bot.alerts}
          </div>
        </div>
        <div className="MetaField">
          <div className="MetaField__label">Status:</div>
          <div className="MetaField__value">
            <div
              className={cn('BotStatus', {
                ['BotStatus--enabled']: bot.enabled
              })}
            >
              {bot.enabled ? 'Enabled' : 'Disabled'}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="BotCard__actions">
        <Button
          round
          size="md"
          variant="primary"
          href={`/notifications?scopeId=agent|${bot.id}`}
          target="_blank"
          className="BotCard__action"
        >
          Subscribe
        </Button>
      </div> */}
    </div>
  );
}

export function BotCardSkeleton({
  className
}: {
  className?: string;
}): JSX.Element {
  return (
    <div className={cn('BotCard', className)}>
      <div className="BotCard__id">
        <Skeleton width={120} />
      </div>
      <div className="BotCard__heading">
        <h3 className="BotCard__title">
          <Skeleton width={200} />
        </h3>
      </div>
      <div className="BotCard__description">
        <Skeleton />
        <Skeleton width="80%" />
      </div>
      <div className="BotCard__meta">
        <div className="MetaField">
          <div className="MetaField__label">
            <Skeleton width={80} />
          </div>
          <div className="MetaField__value">
            <Skeleton width={140} />
          </div>
        </div>
        <div className="MetaField">
          <div className="MetaField__label">
            <Skeleton width={40} />
          </div>
          <div className="MetaField__value">
            <Skeleton width={40} />
          </div>
        </div>
        <div className="MetaField">
          <div className="MetaField__label">
            <Skeleton width={60} />
          </div>
          <div className="MetaField__value">
            <Skeleton width={100} />
          </div>
        </div>
      </div>
      <div className="BotCard__actions">
        <Skeleton
          width="100%"
          height={40}
          borderRadius={40}
          style={{ minWidth: 110 }}
        />
      </div>
    </div>
  );
}

export default BotCard;
