import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';

import './InfoPopover.scss';

import { InfoIcon } from 'common/components/Icons';

type InfoPopoverProps = {
  content: string;
  rightPositioned?: boolean;
  bottomPositioned?: boolean;
  topPositioned?: boolean;
  children?: React.ReactNode;
  className?: string;
  arrow?: boolean;
  onClick?: () => void;
};

export default function InfoPopover({
  content,
  children,
  rightPositioned,
  topPositioned,
  className,
  arrow,
  onClick
}: InfoPopoverProps): JSX.Element {
  if (children) {
    return (
      <Tooltip
        title={content}
        placement="top"
        arrow={arrow}
        disableInteractive
        className={cn('InfoPopover', className)}
        onClick={onClick}
      >
        <div>{children}</div>
      </Tooltip>
    );
  }

  let placement: 'right' | 'top' | undefined = undefined;

  if (rightPositioned) {
    placement = 'right';
  } else if (topPositioned) {
    placement = 'top';
  }

  return (
    <Tooltip
      title={content}
      placement={placement}
      className={cn(
        'InfoPopover',
        onClick && 'InfoPopover__onClick',
        className
      )}
      onClick={onClick}
    >
      {InfoIcon}
    </Tooltip>
  );
}
