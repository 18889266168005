import { ApolloClient, gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';

export const GET_PROJECTS_QUERY = gql`
  query Retrive($getProjectsInput: GetProjectsInput) {
    getProjects(input: $getProjectsInput) {
      projects {
        name
        projectId
        token {
          address
        }
      }
    }
  }
`;

export interface Project {
  projectId: string;
  name: string;
  token: {
    address: string;
  };
}

export interface GetProjectsResult {
  projects: Project[];
}

export async function getProjects(
  query: string,
  apolloClient?: ApolloClient<unknown>
): Promise<GetProjectsResult> {
  const client = apolloClient || getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getProjectsInput: {
        text: query
      }
    },
    query: GET_PROJECTS_QUERY
  });
  const projects: Project[] = apiResult?.data?.getProjects?.projects || [];

  return {
    projects
  };
}
