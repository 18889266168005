import React from 'react';
import cn from 'classnames';

import './Chip.scss';
import { CrossIcon } from '../../components/Icons';
import BaseButton from '../Button/BaseButton';

type ChipProps = {
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  variant?: 'default' | 'outline';
  size?: 'sm' | 'md';
  active?: boolean;
  removable?: boolean;
  children: React.ReactNode;
  onClick: () => void;
  onRemove?: () => unknown;
  className?: string;
};

function Chip(props: ChipProps): JSX.Element {
  const {
    startIcon,
    endIcon,
    variant = 'default',
    size = 'sm',
    active,
    children,
    removable,
    onClick,
    onRemove,
    className
  } = props;

  return (
    <div className={cn('ChipWrapper', className)}>
      <button
        type="button"
        onClick={onClick}
        className={cn('Chip', 'Chip--' + variant, {
          ['Chip--active']: active,
          ['Chip--removable']: removable,
          [`Chip--${size}`]: size
        })}
      >
        {startIcon && <div className="Chip__icon">{startIcon}</div>}
        {children}
        {endIcon && <div className="Chip__icon">{endIcon}</div>}
      </button>
      {removable && (
        <BaseButton
          type="button"
          className="ChipWrapper__remove-button"
          onClick={onRemove}
        >
          {CrossIcon}
        </BaseButton>
      )}
    </div>
  );
}

type ChipContainerProps = {
  children: React.ReactNode;
  alignment: 'row' | 'column';
  className?: string;
};

function ChipContainer(props: ChipContainerProps): JSX.Element {
  const { children, className, alignment } = props;

  return (
    <div
      className={cn('ChipContainer', 'ChipContainer--' + alignment, className)}
    >
      {children}
    </div>
  );
}

export { ChipContainer };

export default Chip;
