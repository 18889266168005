import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';

import './Period.scss';

import Date, { DatePickerMode } from 'common/components-v2/Form/Date/Date';
import Popover from 'common/components-v2/Popover/Popover';
import Button from 'common/components-v2/Button/Button';
import { DEFAULT_DATE_FORMAT } from 'common/constants';

interface PeriodProps {
  name: string;
  value: [string | undefined, string | undefined];
  minDate?: string | Date;
  maxDate?: string | Date;
  renderElement: (props: {
    ref: React.RefObject<unknown>;
    toggle: () => void;
  }) => JSX.Element;
  onChange: (event: {
    target: { name: string; value: [string | undefined, string | undefined] };
  }) => void;
  className?: string;
}

const Period: FC<PeriodProps> = ({
  name,
  value,
  minDate,
  maxDate,
  renderElement,
  onChange,
  className
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [tempValue, setTempValue] =
    useState<[string | undefined, string | undefined]>(value);

  useLayoutEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleChange = (
    value: [string | undefined, string | undefined]
  ): void => {
    onChange({
      target: {
        name,
        value
      }
    });
    setIsOpen(false);
  };

  return (
    <div ref={containerRef} className={cn('Period', className)}>
      {renderElement({ ref: elementRef, toggle: () => setIsOpen((v) => !v) })}
      <Popover
        open={isOpen}
        strategy="absolute"
        anchorElRef={elementRef}
        containerElRef={containerRef}
        placement="bottom-start"
        className="Period__container"
        onClose={() => setIsOpen(false)}
        maxHeight={600}
        preferredWidth={NaN}
      >
        <div className="Period__row">
          <h4 className="Period__subtitle">Presets</h4>
          <div className="Period__shortcuts">
            <Button
              size="sm"
              type="button"
              variant="secondary"
              onClick={() =>
                setTempValue([
                  dayjs().startOf('day').format(DEFAULT_DATE_FORMAT),
                  undefined
                ])
              }
            >
              Today
            </Button>
            <Button
              size="sm"
              type="button"
              variant="secondary"
              onClick={() =>
                setTempValue([
                  dayjs()
                    .startOf('day')
                    .subtract(1, 'week')
                    .format(DEFAULT_DATE_FORMAT),
                  undefined
                ])
              }
            >
              7 days
            </Button>
            <Button
              size="sm"
              type="button"
              variant="secondary"
              onClick={() =>
                setTempValue([
                  dayjs()
                    .startOf('day')
                    .subtract(1, 'month')
                    .format(DEFAULT_DATE_FORMAT),
                  undefined
                ])
              }
            >
              1 month
            </Button>
            <Button
              size="sm"
              type="button"
              variant="secondary"
              onClick={() =>
                setTempValue([
                  dayjs()
                    .startOf('day')
                    .subtract(3, 'month')
                    .format(DEFAULT_DATE_FORMAT),
                  undefined
                ])
              }
            >
              3 months
            </Button>
          </div>
        </div>
        <div className="Period__row">
          <h4 className="Period__subtitle">
            Time range{' '}
            {tempValue?.[0] && tempValue?.[1] && (
              <span className="Period__duration">
                (
                {dayjs(tempValue[1], DEFAULT_DATE_FORMAT).diff(
                  dayjs(tempValue[0], DEFAULT_DATE_FORMAT),
                  'days'
                ) + 1}{' '}
                days)
              </span>
            )}
          </h4>
          <div className="Period__dates">
            <Date
              inline
              resettable
              name="startDate"
              dateFormat={DEFAULT_DATE_FORMAT}
              value={tempValue[0] || null}
              mode={DatePickerMode.Single}
              placeholder="Start Date"
              minDate={minDate}
              maxDate={
                tempValue[1] &&
                dayjs(tempValue[1], DEFAULT_DATE_FORMAT).isBefore(maxDate)
                  ? tempValue[1]
                  : maxDate
              }
              onChange={(e) =>
                setTempValue((v) => [e.target.value || undefined, v[1]])
              }
            />
            <Date
              inline
              resettable
              name="endDate"
              value={tempValue[1] || null}
              dateFormat={DEFAULT_DATE_FORMAT}
              mode={DatePickerMode.Single}
              placeholder="End Date"
              minDate={tempValue[0] || undefined}
              maxDate={maxDate}
              onChange={(e) =>
                setTempValue((v) => [v[0], e.target.value || undefined])
              }
            />
          </div>
        </div>
        <div className="Period__controls">
          <Button
            size="md"
            type="button"
            variant="secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            size="md"
            type="button"
            variant="secondary"
            onClick={() => setTempValue([undefined, undefined])}
          >
            Clear
          </Button>
          <Button
            size="md"
            type="button"
            variant="primary"
            className="Period__apply-button"
            onClick={() => handleChange(tempValue)}
          >
            Apply
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default Period;
