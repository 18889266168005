import { ExternalLinkIcon } from 'common/components/Icons';
import InfoPopover from 'common/components/InfoPopover';
import Loader from 'common/components/Loader';
import config from 'common/config';
import { getNetworkByChainId } from 'common/lib/networks';
import { shortenHash } from 'common/lib/utils';
import {
  getEvents as _getEvents,
  APIEvent
} from 'forta-app/lib/apis/eventsAPI';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import './EventList.scss';

export interface EventListProps {
  agentId?: string;
  scannerId?: string;
}

export default function EventList({
  agentId,
  scannerId
}: EventListProps): JSX.Element {
  const [events, setEvents] = useState<APIEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState<boolean>(false);
  const [includeLinks, setIncludeLinks] = useState<boolean>(false);
  const network = getNetworkByChainId(config.chainId);

  const getEvents = useCallback(
    (filters = {}): Promise<APIEvent[]> =>
      _getEvents({
        ...filters,
        scannerId,
        agentIds: agentId ? [agentId] : undefined,
        includeLinks
      }),
    [agentId, scannerId, includeLinks]
  );

  const onLoadMoreClick = useCallback(async () => {
    if (events.length) {
      setLoadingMore(true);
      const _events = await getEvents({
        searchAfter: events[events.length - 1].timestamp
      });
      setLoadingMore(false);
      setLoadMoreEnabled(!!_events.length);
      setEvents([...events, ..._events]);
    }
  }, [events, getEvents]);

  useEffect(() => {
    setLoading(true);
    getEvents().then((_events) => {
      setLoading(false);
      setLoadingMore(false);
      setLoadMoreEnabled(!!_events.length);
      setEvents(_events);
    });
  }, [getEvents]);

  return (
    <div className="EventList">
      <div className="EventList__filters">
        <div className="EventList__title">Events</div>
        <label className="EventList__include-links">
          <input
            type="checkbox"
            onChange={(event) => setIncludeLinks(event.target.checked)}
            checked={includeLinks}
            disabled={loading}
          />
          Include link events{' '}
          <InfoPopover
            content="Include assignments/unassignments of bots to scan nodes"
            rightPositioned
          />
        </label>
      </div>
      <div className="EventList__heading">
        <div className="EventList__heading-item">Event name</div>
        <div className="EventList__heading-item">Date</div>
      </div>
      <div className="EventList__content">
        <div className="EventList__events">
          {events.map((event) => (
            <div
              className="EventList__event"
              key={`event-${event.id}-${event.timestamp}`}
            >
              <div className="EventList__event-type">
                {event.event_name}
                {(function () {
                  const detail = scannerId
                    ? event.details.agentId
                    : event.details.scannerId;
                  return detail ? (
                    <span className="EventList__event-detail">
                      {shortenHash(detail)}
                    </span>
                  ) : null;
                })()}
              </div>
              <div className="EventList__event-date">
                {moment(event.details.source.timestamp).unix() > 0
                  ? moment(event.details.source.timestamp).fromNow()
                  : '-'}
              </div>
              <div className="EventList__event-action">
                {event.details.source.txHash ? (
                  <a
                    href={`${network.txExplorerUrl}/${event.details.source.txHash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {ExternalLinkIcon}
                  </a>
                ) : null}
              </div>
            </div>
          ))}
        </div>
        {loadMoreEnabled ? (
          <button
            className="EventList__load-more"
            onClick={onLoadMoreClick}
            disabled={loadingMore}
          >
            {loadingMore ? <Loader /> : 'Load More'}
          </button>
        ) : (
          <button
            className="EventList__load-more EventList__load-more--no-events"
            disabled={true}
          >
            No more events
          </button>
        )}
        {loading ? (
          <div className="EventList__loading">
            <Loader />
            <div className="EventList__loading-text">loading events...</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
