import React from 'react';
import cn from 'classnames';

import './Alert.scss';
import BaseFilterModal, { FilterModalDescription } from '../Base/Base';
import Input from 'common/components-v2/Form/Input/Input';

type AlertModalProps = {
  open?: boolean;
  value?: string;
  onChange: (alert?: string) => unknown;
  onClose: () => unknown;
  className?: string;
};

function AlertModal(props: AlertModalProps): JSX.Element {
  const { open, value, onChange, onClose, className } = props;

  return (
    <BaseFilterModal
      open={open}
      value={value}
      title="Alert ID"
      className={cn('AlertFilterModal', className)}
      onChange={onChange}
      onClose={onClose}
    >
      {({ value, setValue }) => (
        <>
          <FilterModalDescription>
            Unique string to identify this class of finding, primarily used to
            group similar findings.
          </FilterModalDescription>
          <Input
            variant="dark"
            name={`alertId`}
            placeholder="Any alert id..."
            value={value || ''}
            resettable={true}
            onChange={(event) => setValue(event.target.value)}
          />
        </>
      )}
    </BaseFilterModal>
  );
}

export default AlertModal;
