import { gql } from '@apollo/client';

import { Manifest } from 'forta-app/components/agent-management/BotEditForm';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';

interface UploadAgentManifestParams {
  manifest: Manifest;
  signature: string;
}

export async function uploadAgentManifest(
  params: UploadAgentManifestParams
): Promise<{ ipfsHash: string }> {
  const client = getExplorerAPIClient();

  const apiResult = await client.mutate({
    variables: {
      input: {
        manifest: params.manifest,
        manifestSignature: params.signature
      }
    },
    mutation: gql`
      mutation UploadManifest($input: UploadAgentManifestInput) {
        uploadAgentManifest(input: $input) {
          ipfsHash
        }
      }
    `
  });

  return {
    ipfsHash: apiResult.data.uploadAgentManifest.ipfsHash
  };
}

interface UploadDocumentationParams {
  file: File;
}

export async function uploadDocumentation(
  params: UploadDocumentationParams
): Promise<{ ipfsHash: string }> {
  const client = getExplorerAPIClient();
  const apiResult = await client.mutate({
    variables: {
      input: {
        content: await params.file.text()
      }
    },
    mutation: gql`
      mutation UploadDocumentation($input: UploadDocumentationInput) {
        uploadDocumentation(input: $input) {
          ipfsHash
        }
      }
    `
  });

  return {
    ipfsHash: apiResult.data.uploadDocumentation.ipfsHash
  };
}
