import { gql } from '@apollo/client';
import useGraphQuery from './useGraphQuery';
import { ApolloClientName } from '../components/ApolloClientProvider';

export const BOT_COUNT_QUERY = gql`
  query AgentCount($input: AgentCountInput) {
    getAgentCount(input: $input) {
      agentCount
      enabledAgentCount
    }
  }
`;

export type UseBotCountParams = {
  developer: string;
};

export type UseBotCountInput = {
  input: {
    developer: string;
  };
};

export type UseBotCountResult = {
  getAgentCount: {
    agentCount: number;
    enabledAgentCount: number;
  };
};

function useBotCount(opts: { enabled?: boolean; params: UseBotCountParams }): {
  botCount: number | null;
  enabledBotCount: number | null;
} {
  const { enabled = true, params } = opts;

  const query = useGraphQuery<UseBotCountInput, UseBotCountResult>({
    query: BOT_COUNT_QUERY,
    variables: {
      input: {
        developer: params.developer
      }
    },
    enabled: enabled,
    clientName: ApolloClientName.Forta
  });

  const { data } = query;

  return {
    botCount: data?.getAgentCount?.agentCount ?? null,
    enabledBotCount: data?.getAgentCount?.enabledAgentCount ?? null
  };
}

export default useBotCount;
