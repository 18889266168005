import React, { ChangeEvent } from 'react';
import cn from 'classnames';

import Mark from '../Mark/Mark';

import './Checkbox.scss';

type CheckboxProps = {
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  label?: string | React.ReactNode;
  onChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
  className?: string;
};

function Checkbox(props: CheckboxProps): JSX.Element {
  const {
    name,
    value,
    label,
    checked = false,
    disabled = false,
    invalid = false,
    className,
    onChange
  } = props;

  return (
    <label
      className={cn('Checkbox', className, {
        ['Checkbox--disabled']: disabled,
        ['Checkbox--checked']: checked
      })}
    >
      <input
        name={name}
        type="checkbox"
        checked={checked}
        value={value}
        disabled={disabled}
        aria-invalid={invalid}
        className="Checkbox__input"
        onChange={onChange}
      />
      <div className="Checkbox__container">
        <Mark
          variant="checkbox"
          checked={checked}
          disabled={disabled}
          className="Checkbox__mark"
        />
        {label != null && <div className="Checkbox__body">{label}</div>}
      </div>
    </label>
  );
}

export default Checkbox;
