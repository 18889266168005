import React, { useEffect, useMemo } from 'react';
import { formatEther } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';
import { useWeb3React } from '@web3-react/core';

import Table from 'common/components-v2/Table/Table';
import TableHead from 'common/components-v2/Table/TableHead';
import TableBody from 'common/components-v2/Table/TableBody';
import TableHeading, {
  TableActionHeading
} from 'common/components-v2/Table/TableHeading';
import TableSubHeading from 'common/components-v2/Table/TableSubHeading';
import TableRow from 'common/components-v2/Table/TableRow';
import TableCell from 'common/components-v2/Table/TableCell';
import TableSubCell from 'common/components-v2/Table/TableSubCell';
import ActionCell from 'common/components-v2/Table/ActionCell';
import Pagination from 'common/components-v2/Pagination/Pagination';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import { formatLargeNumber } from 'forta-app/lib/utils';
import { UseOffsetPaginationResult } from 'common/hooks/useOffsetPagination';
import {
  STAKE_QUERY_POLL_INTERVAL,
  TotalStake
} from 'common/hooks/useAccountStakeQuery';
import PoolEntity from '../../../common/components-v2/Entities/Pool/Pool';
import StakingContract from 'forta-app/lib/contract-interactors/stakingContract';
import CommissionDisplay from '../scan-node-pool/CommissionDisplay';

export type ScannerPoolDelegation = ScannerPool & {
  stake: TotalStake;
};

type ScannerPoolsTableProps = {
  id?: string;
  scannerPools: ScannerPoolDelegation[];
  pagination: UseOffsetPaginationResult & { hasNextPage: boolean };
  loading?: boolean;
  empty?: boolean;
  onDelegate: (delegation: ScannerPoolDelegation) => unknown;
  onUnDelegate: (delegation: ScannerPoolDelegation) => unknown;
  className?: string;
};

function DelegationsTable(props: ScannerPoolsTableProps): JSX.Element {
  const {
    id,
    empty,
    loading,
    pagination,
    scannerPools,
    onDelegate,
    onUnDelegate,
    className
  } = props;

  return (
    <section id={id} className={className}>
      <Table minWidth={1350}>
        <TableHead>
          <TableHeading title="Pool" flex={15}>
            <TableSubHeading title="Id" flex={4} />
            <TableSubHeading
              title="Est. Annual Rewards"
              tooltip="Estimated annual rewards for delegators in this pool. This is based on last epoch's data, extrapolated over a year. This is subject to change based on factors such as the node's performance, commission changes, allocated stake, among others."
              flex={6}
            />
            <TableSubHeading title="Commission" flex={4} />
          </TableHeading>
          <TableHeading title="All Pool Stake" flex={14}>
            <TableSubHeading title="Owned" flex={5} />
            <TableSubHeading title="Delegated" flex={5} />
            <TableSubHeading title="Allocated" flex={4} />
          </TableHeading>
          <TableHeading title="My Delegation" flex={10}>
            <TableSubHeading title="Delegating" flex={4} />
            <TableSubHeading title="Status" flex={6} />
          </TableHeading>
          <TableActionHeading />
        </TableHead>
        <TableBody empty={empty} loading={loading}>
          {scannerPools.map((pool) => (
            <PoolDelegationRow
              key={pool.id}
              onDelegate={onDelegate}
              onUnDelegate={onUnDelegate}
              pool={pool}
            />
          ))}
        </TableBody>
      </Table>
      {scannerPools.length > 0 && (
        <Pagination
          loading={loading}
          page={pagination.page}
          limit={pagination.pageSize}
          itemsCount={scannerPools.length}
          hasNextPage={pagination.hasNextPage}
          onPrevPage={pagination.goPrevPage}
          onNextPage={pagination.goNextPage}
        />
      )}
    </section>
  );
}

export default DelegationsTable;

export function StatusDot({ color }: { color: string }): JSX.Element {
  return (
    <span
      style={{
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: color,
        marginRight: '8px',
        boxShadow: `${color} 0px 0px 2px, ${color} 0px 0px 8px`
      }}
    />
  );
}

function PoolDelegationRow({
  onDelegate,
  onUnDelegate,
  pool
}: {
  onDelegate: (delegation: ScannerPoolDelegation) => unknown;
  onUnDelegate: (delegation: ScannerPoolDelegation) => unknown;
  pool: ScannerPoolDelegation;
}): JSX.Element {
  const web3React = useWeb3React();
  const stakingContract = useMemo(
    () => new StakingContract(web3React),
    [web3React]
  );
  const [withdrawable, setWithdrawable] = React.useState(false);

  useEffect(() => {
    const checkWithdraw = (): void => {
      stakingContract
        .testWithdraw(pool.stake.subject.subjectType, pool.stake.subject.id)
        .then(() => setWithdrawable(true))
        .catch(() => setWithdrawable(false));
    };
    checkWithdraw();
    const interval = setInterval(checkWithdraw, STAKE_QUERY_POLL_INTERVAL);
    return () => clearInterval(interval);
  }, [pool.stake.subject.id, pool.stake.subject.subjectType, stakingContract]);

  const delatationStatus = (function () {
    if (withdrawable) {
      return (
        <span>
          <StatusDot color="cyan" /> Withdrawable
        </span>
      );
    }
    if (!withdrawable && BigNumber.from(pool.stake.inactiveShares).gt(0)) {
      return (
        <span>
          <StatusDot color="magenta" /> Withdrawing
        </span>
      );
    }
    if (
      BigNumber.from(pool.stakeAllocated).sub(pool.stakeOwnedAllocated).gte(0)
    ) {
      return (
        <span>
          <StatusDot color="lime" /> Delegating
        </span>
      );
    }
    return (
      <span>
        <StatusDot color="yellow" /> Not Delegating
      </span>
    );
  })();

  return (
    <TableRow>
      <TableCell flex={15}>
        <TableSubCell
          value={
            <PoolEntity
              chainId={pool.chainId}
              id={pool.id}
              to={`/nodePool/${pool.id}/`}
            />
          }
          flex={4}
        />
        <TableSubCell
          value={
            pool.apyForLastEpoch > 0
              ? pool.apyForLastEpoch.toString() + '%'
              : '-'
          }
          flex={6}
        />
        <TableSubCell value={<CommissionDisplay pool={pool} />} flex={4} />
      </TableCell>
      <TableCell flex={14}>
        <TableSubCell
          value={`${formatLargeNumber(formatEther(pool.stakeOwned))} FORT`}
          flex={5}
        />
        <TableSubCell
          value={`${formatLargeNumber(formatEther(pool.stakeDelegated))} FORT`}
          flex={5}
        />
        <TableSubCell
          value={`${formatLargeNumber(formatEther(pool.stakeAllocated))} FORT`}
          flex={4}
        />
      </TableCell>
      <TableCell flex={10}>
        <TableSubCell
          value={`${formatLargeNumber(formatEther(pool.stake.amount))} FORT`}
          flex={4}
        />
        <TableSubCell value={delatationStatus} flex={6} />
      </TableCell>
      <ActionCell
        options={[
          {
            label: 'Delegate',
            onClick: () => onDelegate(pool)
          },
          {
            label: 'Undelegate',
            disabled: pool.stake.amount.isZero(),
            onClick: () => onUnDelegate(pool)
          }
        ]}
      />
    </TableRow>
  );
}
