import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { scrollToElement } from '../lib/utils';
import { NetworkStatus } from '@apollo/client';

type useCursorPaginationProps<T> = {
  queryResult: { networkStatus: NetworkStatus };
  pageValues: [T | undefined, T | undefined];
  onPageValuesChange: (pageValue: T | undefined, newPage: number) => unknown;
  scrollToElementId?: string;
};

function useCursorPagination<T>(props: useCursorPaginationProps<T>): {
  page: number;
  goNextPage: () => void;
  goPrevPage: () => void;
  resetPagination: () => void;
} {
  const [page, setPage] = useState(0);
  const stateRef = useRef<{ pageValues: (T | undefined)[] }>({
    pageValues: []
  });

  const { queryResult, pageValues, onPageValuesChange, scrollToElementId } =
    props;
  const { networkStatus } = queryResult;

  const scroll = useCallback(() => {
    if (scrollToElementId) {
      setTimeout(() => {
        scrollToElement(scrollToElementId);
      }, 10);
    }
  }, [scrollToElementId]);

  useLayoutEffect(() => {
    if (networkStatus === NetworkStatus.ready) {
      if (page === 0 && stateRef.current.pageValues.length == 0) {
        stateRef.current.pageValues = [pageValues[0], pageValues[1]];
      } else if (
        page === stateRef.current.pageValues.length - 1 &&
        pageValues[1]
      ) {
        stateRef.current.pageValues = [
          ...stateRef.current.pageValues,
          pageValues[1]
        ];
      }
    }
  }, [networkStatus, page, pageValues]);

  const goNextPage = useCallback((): void => {
    const newPage = page + 1;

    setPage(newPage);
    onPageValuesChange(stateRef.current.pageValues[newPage], newPage);
    scroll();
  }, [page, onPageValuesChange, scroll]);

  const goPrevPage = useCallback((): void => {
    const newPage = page - 1;

    if (newPage < 0) return;

    setPage(newPage);
    onPageValuesChange(stateRef.current.pageValues[newPage], newPage);
    scroll();
  }, [page, onPageValuesChange, scroll]);

  const resetPagination = useCallback((): void => {
    stateRef.current.pageValues = [];
    setPage(0);
  }, []);

  return {
    page,
    goNextPage,
    goPrevPage,
    resetPagination
  };
}

export default useCursorPagination;
