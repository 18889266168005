import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import './TableSubCell.scss';

interface TableSubCellProps {
  value: string | number | JSX.Element;
  alignment?: 'left' | 'right';
  button?: React.ReactNode;
  flex?: number;
  width?: number;
  title?: string;
  scroll?: boolean;
  ellipsis?: boolean;
  className?: string;
}

interface TableSubCellWithLinkProps extends TableSubCellProps {
  to: string;
}

interface TableSubCellWithHealthIndicatorProps {
  type: 'sla' | 'uptime';
  isLoading: boolean;
  value: string;
  flex?: number;
  className?: string;
}

type HealthIndicators = 'excellent' | 'good' | 'unsatisfactory' | 'poor';

const TableSubCell = ({
  value,
  button,
  flex,
  width,
  title,
  scroll = false,
  ellipsis = false,
  alignment = 'left',
  className
}: TableSubCellProps): JSX.Element => {
  return (
    <div
      title={title}
      className={cn('TableSubCell', `TableSubCell--${alignment}`, className, {
        ['TableSubCell--ellipsis']: ellipsis,
        ['TableSubCell--scroll']: scroll
      })}
      style={{ flex, width }}
    >
      {ellipsis ? (
        <span className="TableSubCell__value TableSubCell__value--ellipsis">
          {value}
        </span>
      ) : scroll ? (
        <span>{value}</span>
      ) : (
        value
      )}
      {button && <div className="button">{button}</div>}
    </div>
  );
};

export const TableSubCellWithLink = ({
  to,
  value,
  flex
}: TableSubCellWithLinkProps): JSX.Element => {
  return (
    <div className="TableSubCell" style={{ flex }}>
      <Link to={to}>{value}</Link>
    </div>
  );
};

export const TableSubCellWithHealthIndicator = ({
  value,
  flex,
  isLoading,
  type,
  className
}: TableSubCellWithHealthIndicatorProps): JSX.Element => {
  if (isLoading) {
    return (
      <div className={cn('TableSubCell', className)} style={{ flex }}>
        <div>
          <Skeleton width={50} />
        </div>
      </div>
    );
  } else if (isNaN(Number(value))) {
    return (
      <div className={cn('TableSubCell', className)} style={{ flex }}>
        <div>{value}</div>
      </div>
    );
  }

  const setHealth = (
    value: number,
    type: 'sla' | 'uptime'
  ): HealthIndicators => {
    if (type === 'sla' && value >= 0.8) {
      return 'excellent';
    } else if (type === 'sla' && value >= 0.75 && value < 0.8) {
      return 'good';
    } else if (type === 'sla') {
      return 'poor';
    }

    if (type === 'uptime' && value >= 75) {
      return 'excellent';
    } else if (type === 'uptime' && value < 75 && value >= 50) {
      return 'good';
    } else if (type === 'uptime' && value < 50 && value >= 25) {
      return 'unsatisfactory';
    } else {
      return 'poor';
    }
  };

  return (
    <div className={cn('TableSubCell', className)} style={{ flex }}>
      <div
        className={cn('TableSubCell__healthIndicator', {
          'TableSubCell__healthIndicator--excellent':
            setHealth(Number(value), type) === 'excellent',
          'TableSubCell__healthIndicator--good':
            setHealth(Number(value), type) === 'good',
          'TableSubCell__healthIndicator--unsatisfactory':
            setHealth(Number(value), type) === 'unsatisfactory',
          'TableSubCell__healthIndicator--poor':
            setHealth(Number(value), type) === 'poor'
        })}
      >
        {type === 'uptime' ? value + '%' : value}
      </div>
    </div>
  );
};

export default TableSubCell;
