import React from 'react';
import { InfoIcon, WarningIcon } from './Icons';
import cn from 'classnames';

import './MessageBox.scss';

type MessageTypes = 'warning' | 'info';

export default function MessageBox({
  title,
  messageType = 'info',
  children
}: {
  title?: string;
  messageType?: MessageTypes;
  children: React.ReactNode;
}): JSX.Element {
  const pickIcon = (messageType: MessageTypes): JSX.Element => {
    switch (messageType) {
      case 'warning':
        return WarningIcon;
      case 'info':
        return InfoIcon;
      default:
        return InfoIcon;
    }
  };

  return (
    <div
      className={cn('MessageBox', {
        'MessageBox--warning': messageType === 'warning'
      })}
    >
      {title && (
        <div className="MessageBox__header">
          <div
            className={cn('MessageBox__header-icon', {
              'MessageBox__header-icon--warning': messageType === 'warning'
            })}
          >
            {pickIcon(messageType)}
          </div>
          <div className="MessageBox__header-title">{title}</div>
        </div>
      )}
      <div className="MessageBox__description">{children}</div>
    </div>
  );
}
