import React from 'react';
import cn from 'classnames';

import './Field.scss';

import InfoPopover from 'common/components/InfoPopover';

type FormFieldProps = {
  label?: string | JSX.Element;
  tooltip?: string;
  children: React.ReactNode;
  errors?: (string | JSX.Element)[];
  className?: string;
};

function FormField(props: FormFieldProps): JSX.Element {
  const { label, tooltip, errors, children, className } = props;

  return (
    <div className={cn('FormField', className)}>
      <div className="FormField__label">
        <div>{label}</div>
        {tooltip != null && <InfoPopover content={tooltip} rightPositioned />}
      </div>
      <div className="FormField__element">{children}</div>
      {errors?.length && (
        <ul className="FormField__errors">
          {errors.map((error, i) => (
            <li
              key={typeof error === 'string' ? error : i}
              className="FormField__error"
            >
              {error}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default FormField;
