import { ResponsiveCalendar } from '@nivo/calendar';
import chroma from 'chroma-js';
import React from 'react';

import './Calendar.scss';

export interface DayValue {
  value: number;
  day: string;
}

export interface CalendarInput {
  data: DayValue[];
  from: string | Date;
  to: string | Date;
  valueLabel: string;
  onClick?: (
    date: string,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ) => void;
}

const color = '#f47560';

const Calendar = (props: CalendarInput): JSX.Element => (
  <ResponsiveCalendar
    theme={{ labels: { text: { fill: '#999' } } }}
    data={props.data}
    from={props.from}
    to={props.to}
    emptyColor="#161b22"
    colors={[
      chroma(color).alpha(0.2).hex(),
      chroma(color).alpha(0.4).hex(),
      chroma(color).alpha(0.6).hex(),
      chroma(color).alpha(0.8).hex(),
      color
    ]}
    margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
    yearSpacing={40}
    monthBorderColor="#272727"
    dayBorderWidth={2}
    dayBorderColor="#272727"
    onClick={({ day }, e) => props.onClick && props.onClick(day, e)}
    tooltip={({ value, day }) => {
      return (
        <div className="Calendar__tooltip">
          {value} {props.valueLabel} ({day})
        </div>
      );
    }}
  />
);

export default Calendar;
