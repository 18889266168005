import { useWeb3React } from '@web3-react/core';
import config from 'common/config';
import { useJWT } from 'forta-app/app/hooks';
import './WizardPage.scss';

function WizardContent(): JSX.Element {
  const jwt = useJWT();
  const web3React = useWeb3React();
  const chainIdValid = config.chainId === web3React.chainId;

  if (chainIdValid && jwt) {
    return (
      <div className="WizardPage__iframe-container">
        <iframe
          title="Bot Wizard"
          src={config.wizardEndpoint}
          className="WizardPage__iframe"
          frameBorder="0"
        ></iframe>
      </div>
    );
  } else {
    return <div className="WizardPage__not-logged">Please sign in first.</div>;
  }
}

export function WizardPage(): JSX.Element {
  return (
    <div className="WizardPage">
      <div className="WizardPage__header">
        <div className="WizardPage__title">
          Detection Bot Wizard{' '}
          <div className="WizardPage__beta-badge">Beta</div>
        </div>
        <div className="WizardPage__description">
          Create a Detection Bot for your smart contract using the template
          collection. Lean how to use it by reading the{' '}
          <a
            href="https://docs.forta.network/en/latest/wizard/"
            target="_blank"
            rel="noreferrer"
          >
            documentation
          </a>
          .
        </div>
      </div>
      <WizardContent />
    </div>
  );
}
