import { useState } from 'react';

import { scrollToElement } from '../lib/utils';

export type UseOffsetPaginationResult = {
  page: number;
  pageSize: number;
  offset: { skip: number; first: number };
  goPrevPage: () => void;
  goNextPage: () => void;
  setPage: (value: ((prevState: number) => number) | number) => void;
};

type useOffsetPaginationOptions = {
  initialPage?: number;
  scrollToElementId?: string;
};

function useOffsetPagination(
  pageSize = 20,
  opts?: useOffsetPaginationOptions
): UseOffsetPaginationResult {
  const { initialPage = 0, scrollToElementId } = opts || {};
  const [page, setPage] = useState(initialPage);

  function handlePageChange(page: number): void {
    setPage(page);
    if (scrollToElementId) {
      setTimeout(() => {
        scrollToElement(scrollToElementId);
      }, 10);
    }
  }

  return {
    page,
    pageSize,
    offset: { first: pageSize, skip: page * pageSize },
    setPage,
    goPrevPage: () => handlePageChange(page - 1),
    goNextPage: () => handlePageChange(page + 1)
  };
}

export default useOffsetPagination;
