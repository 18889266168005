import React, { useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import Autocomplete from 'common/components-v2/Form/Autocomplete/Autocomplete';
import useBotsQuery from 'forta-app/hooks/useBotsQuery';
import plans from 'forta-app/data/plans';
import { FormElementProps } from 'common/components-v2/Form/form';
import { InputVariant } from 'common/components-v2/Form/Input/Input';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from 'common/constants';
import { isAgentId } from 'common/lib/utils';

import './BotAutocomplete.scss';

type BotAutocompleteProps = FormElementProps<string> & {
  className?: string;
};

function BotAutocomplete(props: BotAutocompleteProps): JSX.Element {
  const { value, name, onChange, className } = props;

  const [searchValue] = useDebounce(value, DEFAULT_SEARCH_DEBOUNCE_TIME);
  const { isEmptyValue, isAddress } = useMemo(
    () => ({
      isEmptyValue: searchValue.trim() == '',
      isAddress: searchValue.trim().indexOf('0x') === 0
    }),
    [searchValue]
  );

  const highlightedBots = useMemo(
    () =>
      plans
        .map((v) => ({ agent_id: v.botId, name: v.name }))
        .filter((v) => !!v.agent_id)
        .slice(0, 5),
    []
  );

  const { bots, loading } = useBotsQuery({
    enabled: !isAddress,
    params: useMemo(
      () => ({
        text: searchValue,
        ids: isEmptyValue
          ? highlightedBots.map((v) => v.agent_id || '')
          : undefined,
        pageSize: 5
      }),
      [isEmptyValue, searchValue, highlightedBots]
    )
  });

  return (
    <Autocomplete
      resettable
      showSearchIndicator={!isAgentId(value)}
      name={name}
      value={value}
      variant={InputVariant.Dark}
      items={loading || searchValue != value ? [] : bots}
      loading={loading}
      placeholder="Enter Bot ID or start typing name..."
      getItemValue={(item) => item?.agent_id || ''}
      renderItem={(item) => (
        <div className="BotItem">
          <div className="BotItem__name">{item.name}</div>
          <div className="BotItem__id">{item.agent_id}</div>
        </div>
      )}
      onChange={onChange}
      className={className}
    />
  );
}

export default BotAutocomplete;
