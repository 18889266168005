import React, { useMemo, useState } from 'react';
import { BigNumber } from 'ethers';

import './RewardsTable.scss';

import Checkbox from 'common/components-v2/Form/Checkbox/Checkbox';
import Button from 'common/components-v2/Button/Button';
import Table from 'common/components-v2/Table/Table';
import TableHead from 'common/components-v2/Table/TableHead';
import TableSubHeading from 'common/components-v2/Table/TableSubHeading';
import TableHeading, {
  TableCheckableHeading
} from 'common/components-v2/Table/TableHeading';
import TableBody from 'common/components-v2/Table/TableBody';
import TableSubCell from 'common/components-v2/Table/TableSubCell';
import TableCell, {
  TableCheckableCell
} from 'common/components-v2/Table/TableCell';
import TableRow from 'common/components-v2/Table/TableRow';
import TableExpandableRow, {
  TableExpandSubCell,
  TableExpandSubHeading
} from 'common/components-v2/Table/TableExpandableRow';
import PoolEntity from 'common/components-v2/Entities/Pool/Pool';
import { formatFORT, formatLargeNumber } from 'forta-app/lib/utils';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import { formatEther } from 'ethers/lib/utils';
import ClaimRewardsModal from './ClaimRewardsModal';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import { toast } from 'react-toastify';

export type Epoch = {
  epoch: number;
  timestamp: number;
  rewards: string;
};

type EpochsTableProps = {
  epochs: Epoch[];
  isOwner: boolean;
  pool: ScannerPool;
};

const MAX_SELECTED_EPOCHS = 20;

function EpochsTable(props: EpochsTableProps): JSX.Element {
  const [selectedEpochs, setSelectedEpochs] = useState<number[]>([]);
  const [isModalOpened, setModalOpened] = useState<boolean>(false);

  const epochs: (Epoch & { endTimestamp: number })[] = useMemo(() => {
    // TODO calc end timestamp
    return props.epochs.map((e) => ({
      ...e,
      endTimestamp: e.timestamp
    }));
  }, [props.epochs]);

  const { isOwner, pool } = props;

  function handleClaim(): void {
    setModalOpened(true);
  }

  function handleEpochToggle(epoch: Epoch): void {
    const isChecked = selectedEpochs.includes(epoch.epoch);
    setSelectedEpochs(
      isChecked
        ? selectedEpochs.filter((number) => epoch.epoch !== number)
        : [...selectedEpochs, epoch.epoch]
    );
  }

  const isAllEpochsChecked =
    selectedEpochs.length >= Math.min(epochs.length, MAX_SELECTED_EPOCHS);
  const totalSelectedRewards = epochs
    .filter((e) => selectedEpochs.includes(e.epoch))
    .reduce((acc, v) => {
      return acc.add(BigNumber.from(v.rewards));
    }, BigNumber.from(0));

  return (
    <section className="EpochsTable">
      {epochs.length > 0 && (
        <div className="EpochsTable__header">
          <Checkbox
            label={`Select All (max. ${MAX_SELECTED_EPOCHS})`}
            checked={isAllEpochsChecked}
            onChange={() =>
              setSelectedEpochs(
                isAllEpochsChecked
                  ? []
                  : epochs
                      .filter((v, i) => i < MAX_SELECTED_EPOCHS)
                      .map((e) => e.epoch)
              )
            }
          />
          <Button
            size="md"
            variant="primary"
            disabled={selectedEpochs.length === 0}
            onClick={() => handleClaim()}
          >
            Claim selected{' '}
            {selectedEpochs.length > 0
              ? `(${formatFORT(totalSelectedRewards)} FORT)`
              : ''}
          </Button>
        </div>
      )}
      <Table variant="transparent" className="EpochsTable__table">
        <TableHead>
          <TableCheckableHeading />
          <TableHeading>
            <TableSubHeading title="Epoch" flex={6} />
            <TableSubHeading title="Rewards" flex={4} />
            <TableSubHeading title="" flex={5} />
            <TableSubHeading width={120} />
          </TableHeading>
        </TableHead>
        <TableBody>
          {epochs.map((epoch) => {
            const isChecked = selectedEpochs.includes(epoch.epoch);

            return (
              <TableRow
                clickable
                variant="transparent"
                key={epoch.epoch}
                highlighted={isChecked}
                onClick={() => handleEpochToggle(epoch)}
              >
                <TableCheckableCell
                  checked={isChecked}
                  onChangedChange={() => handleEpochToggle(epoch)}
                />
                <TableCell>
                  <TableSubCell value={epoch.epoch} flex={6} />
                  <TableSubCell
                    value={`${formatFORT(epoch.rewards)} FORT`}
                    flex={11}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ClaimRewardsModal
        opened={isModalOpened}
        subjectType={
          isOwner ? SubjectType.SCANNERPOOL : SubjectType.SCANNERPOOL_DELEGATOR
        }
        pool={pool}
        epochs={epochs.filter((e) => selectedEpochs.includes(e.epoch))}
        onClose={() => {
          setModalOpened(false);
        }}
        onSuccess={(tx) => {
          if (tx) {
            tx.wait(1).then(() => {
              toast.success(() => <div>FORT rewards claimed!</div>);
            });
          }
        }}
      />
    </section>
  );
}

export type RewardedScannerPool = ScannerPool & {
  epochs: Epoch[];
  stake: BigNumber;
};

type RewardsTableProps = {
  loading?: boolean;
  refetching?: boolean;
  empty?: boolean;
  scannerPools: RewardedScannerPool[];
  address: string;
};

function RewardsTable(props: RewardsTableProps): JSX.Element {
  const { scannerPools, loading, empty, address } = props;

  return (
    <Table>
      <TableHead>
        <TableHeading>
          <TableSubHeading title="Pool Address" flex={6} />
          <TableSubHeading title="Stake" flex={4} />
          <TableSubHeading title="Available Rewards" flex={5} />
          <TableExpandSubHeading />
        </TableHeading>
      </TableHead>
      <TableBody
        loading={loading}
        empty={empty}
        emptyMessage="No available rewards"
      >
        {scannerPools.map((p) => {
          const availableRewards = p.epochs.reduce((acc, v) => {
            return acc.add(BigNumber.from(v.rewards));
          }, BigNumber.from(0));

          const isOwner = p.owner.id.toLowerCase() === address.toLowerCase();

          return (
            <TableExpandableRow
              key={p.id}
              size="lg"
              clickable={true}
              content={
                <EpochsTable pool={p} epochs={p.epochs} isOwner={isOwner} />
              }
            >
              <TableCell>
                <TableSubCell
                  value={<PoolEntity chainId={p.chainId} id={p.id} />}
                  flex={6}
                />
                <TableSubCell
                  value={`${formatLargeNumber(formatEther(p.stake))} FORT ${
                    isOwner ? '' : ' (delegated)'
                  }`}
                  flex={4}
                />
                <TableSubCell
                  value={`${formatFORT(availableRewards)} FORT`}
                  flex={5}
                />
                <TableExpandSubCell />
              </TableCell>
            </TableExpandableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default RewardsTable;
