import cn from 'classnames';

import './Tooltip.scss';

type ChartTooltipProps = {
  title?: string;
  subtitle?: string;
  records?: {
    label: string;
    value: string | number | undefined;
    color?: string;
  }[];
  recordsDirection?: 'row' | 'column';
  children?: never;
  className?: string;
};

function ChartTooltip(props: ChartTooltipProps): JSX.Element {
  const {
    title,
    subtitle,
    records = [],
    recordsDirection = 'row',
    children,
    className
  } = props;

  return (
    <div className={cn('ChartTooltip', className)}>
      {title && <h3 className="ChartTooltip__title">{title}</h3>}
      {subtitle && <h4 className="ChartTooltip__sub-title">{subtitle}</h4>}
      {records.length > 0 && (
        <ul
          className="ChartTooltip__records"
          style={{
            gridAutoFlow: records.length > 8 ? 'column' : undefined,
            gridTemplateRows:
              records.length > 8
                ? // We make it so that at least two items are located on the other column
                  `repeat(${Math.min(10, records.length - 2)}, 1fr)`
                : undefined
          }}
        >
          {records.map((record) => (
            <li
              key={record.label}
              className={cn(
                'TooltipRecord',
                `TooltipRecord--${recordsDirection}`
              )}
            >
              <div
                className="TooltipRecord__label"
                style={{ color: record.color }}
              >
                {record.label}
              </div>
              <div
                className="TooltipRecord__value"
                style={{ color: record.color }}
              >
                {record.value}
              </div>
            </li>
          ))}
        </ul>
      )}
      {children}
    </div>
  );
}

export default ChartTooltip;
