import React from 'react';

import './TableHead.scss';

interface TableHeadProps {
  children: React.ReactNode;
}

const TableHead = (props: TableHeadProps): JSX.Element => {
  const { children } = props;
  return <div className="TableHead">{children}</div>;
};

export default TableHead;
