import { BigNumber } from 'ethers';
import { useParams } from 'react-router-dom';

import './ScannerPoolPage.scss';

import ScannerPoolInfo from 'forta-app/components/scan-node-pool/ScannerPoolInfo';
import ChainIcon from 'common/components/stats/ChainIcon';
import ScanNodePoolActionsButton from 'forta-app/components/scan-node-pool/ScanNodePoolActionsButton';
import ScanNodesList from 'forta-app/components/scan-node-pool/ScanNodesList';
import NotFoundPage from 'common/pages/NotFoundPage';
import Loader from 'common/components/Loader';
import useScannerPoolsWithApyQuery from 'common/hooks/useScannerPoolsWithApyQuery';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import { Allocation } from 'forta-app/components/scan-node-pool/AllocateStakeButton';
import { formatPoolId } from 'common/lib/utils';
import { getNetworkByChainId } from 'common/lib/networks';

export default function ScannerPoolPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { scannerPools, loading } = useScannerPoolsWithApyQuery({
    params: { id_in: [id], first: 1 }
  });

  const scannerPool = scannerPools[0];

  if (loading) {
    return <Loader />;
  }

  if (scannerPools.length === 0 && !loading) {
    return <NotFoundPage />;
  }

  const allocation: Allocation = {
    stakeAllocated: BigNumber.from(scannerPool.stakeAllocated),
    stakeDelegated: BigNumber.from(scannerPool.stakeDelegated),
    stakeOwned: BigNumber.from(scannerPool.stakeOwned),
    stakeOwnedAllocated: BigNumber.from(scannerPool.stakeOwnedAllocated)
  };

  return (
    <div className="ScannerPoolPage">
      <ScannerPoolIdCard scannerPool={scannerPool} />
      <ScannerPoolInfo allocation={allocation} scannerPool={scannerPool} />
      <ScanNodesList scannerPool={scannerPool} />
    </div>
  );
}

function ScannerPoolIdCard({
  scannerPool
}: {
  scannerPool: ScannerPool;
}): JSX.Element {
  return (
    <div className="ScannerPoolIdCard">
      <div className="ScannerPoolIdCard__content">
        <ChainIcon network={getNetworkByChainId(scannerPool.chainId)} big />
        <div className="ScannerPoolIdCard__textContainer">
          <div className="ScannerPoolIdCard__title">Scanner Pool</div>
          <div className="ScannerPoolIdCard__id">
            ID {formatPoolId(scannerPool.id)}
          </div>
          <div className="ScannerPoolIdCard__owner">
            Owned by {scannerPool.owner.id}
          </div>
        </div>
      </div>
      <div className="ScannerPoolIdCard__buttonContainer">
        <ScanNodePoolActionsButton scannerPool={scannerPool} />
      </div>
    </div>
  );
}
