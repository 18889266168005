import { useCallback, useLayoutEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3ContextType } from '@web3-react/core/dist/provider';
import { getJWTAddress } from 'forta-app/slices/walletSlice';
import { useAppSelector } from 'forta-app/app/hooks';
import config from '../config';
import {
  ConnectionType,
  switchNetwork
} from '../../forta-app/components/wallet/connections';
import ls, { CONNECTION_TYPE } from '../lib/localStorage';
import { Web3Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';

export type WalletConnection = {
  jwt: string;
  address: string;
  signed: boolean;
  initialized: boolean;
  web3React: Web3ContextType;
  ensureConnection: () => Promise<{
    provider: Web3Provider;
    signer: ethers.providers.JsonRpcSigner;
  }>;
};

function useWallet(): WalletConnection {
  const jwt = useAppSelector((state) => state.wallet.jwt);
  const jwtAddress = getJWTAddress(jwt).toLowerCase();
  const web3React = useWeb3React();

  const [isInitialized, setIsInitialized] = useState<boolean>(
    web3React.isActive
  );

  async function initialize(): Promise<void> {
    setIsInitialized(false);
    await web3React.connector.connectEagerly?.();
    setIsInitialized(true);
  }

  const ensureConnection = useCallback(async (): Promise<{
    provider: Web3Provider;
    signer: ethers.providers.JsonRpcSigner;
  }> => {
    if (web3React.chainId !== config.chainId) {
      await switchNetwork(
        config.chainId,
        ls.getItem(CONNECTION_TYPE) as ConnectionType
      );
    }

    const provider = web3React.provider;
    const signer = web3React.provider?.getSigner();

    if (!signer || !provider) {
      throw new Error('Cannot get wallet signer or provider');
    }

    return {
      provider,
      signer
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3React.chainId]);

  useLayoutEffect(() => {
    if (web3React.isActive) {
      setIsInitialized(true);
      return;
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3React.isActive]);

  if (!isInitialized) {
    return {
      jwt: jwt,
      address: jwtAddress,
      signed: false,
      initialized: false,
      web3React,
      ensureConnection
    };
  }

  return {
    jwt: jwt,
    address: jwtAddress,
    signed:
      !!jwtAddress &&
      !!web3React.account &&
      jwtAddress === web3React.account?.toLowerCase(),
    initialized: isInitialized,
    web3React,
    ensureConnection
  };
}

export default useWallet;
