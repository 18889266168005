import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import './Documentation.scss';
import useDocumentationItems from './useDocumentationItems';

import Markdown from 'common/components-v2/Markdown/Markdown';
import BaseButton from 'common/components-v2/Button/BaseButton';
import { DocumentationIpfsItem } from 'forta-app/components/agent-management/BotEditForm';
import Fade from 'common/components-v2/Transitions/Fade';
import { scrollToElement } from 'common/lib/utils';
import { ArrowUpIcon } from 'common/components/Icons';
import { currentENV } from 'common/config';
import { developers } from '../../../data/projects';

type BotDocumentationProps = {
  documentation?: string;
  developer?: string;
};

function BotDocumentation(props: BotDocumentationProps): JSX.Element {
  const { developer } = props;

  const documentationItems = useDocumentationItems<DocumentationIpfsItem>(
    props.documentation
  );

  const [currentDocumentationItem, setCurrentDocumentationItem] = useState(
    documentationItems[0]
  );

  useLayoutEffect(() => {
    setCurrentDocumentationItem(documentationItems[0]);
  }, [documentationItems]);

  const rootRef = useRef<HTMLDivElement>(null);
  const [shouldDisplayScrollToTop, setShouldDisplayScrollToTop] =
    useState(false);

  const isTrusted = useMemo(() => {
    const trustedDevelopers = new Set(
      developers.map((d) => d.address.toLowerCase())
    );
    return trustedDevelopers.has(developer?.toLowerCase() || '');
  }, [developer]);

  const { data: documentation, isPreviousData } = useQuery<string>(
    [currentDocumentationItem?.ipfsHash],
    async ({ queryKey }) => {
      let gateway = `https://ipfs.io/ipfs/`;

      if (currentENV === 'production') {
        gateway = `https://ipfs.forta.network/ipfs/`;
      } else if (currentENV === 'staging') {
        gateway = `https://ipfs-dev.forta.network/ipfs/`;
      }

      const { data } = await axios.get(gateway + queryKey[0], {
        headers: { 'Content-Type': 'text/plain' }
      });

      return data;
    },
    { keepPreviousData: true, enabled: !!currentDocumentationItem }
  );

  useEffect(() => {
    function handleScroll(): void {
      if (rootRef.current) {
        // Get the element's distance from the top of the viewport
        const elTop = rootRef.current.getBoundingClientRect().top;

        if (elTop < -140) {
          setShouldDisplayScrollToTop(true);
        } else {
          setShouldDisplayScrollToTop(false);
        }
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function scrollToTop(): void {
    scrollToElement('markdown-container', -120, 0);
  }

  return (
    <div className="BotDocumentation" ref={rootRef}>
      {documentationItems.length > 1 && (
        <div className="BotDocumentation__tabs">
          {documentationItems.map((item) => (
            <BaseButton
              key={item.title}
              onClick={() => setCurrentDocumentationItem(item)}
              className={cn('BotDocumentation__tab', {
                ['BotDocumentation__tab--active']:
                  currentDocumentationItem === item
              })}
            >
              {item.title}
            </BaseButton>
          ))}
        </div>
      )}
      <div className="BotDocumentation__documentation">
        <div
          id="markdown-container"
          className="BotDocumentation__markdown-container"
        >
          {documentation && !isPreviousData ? (
            <Markdown
              trusted={isTrusted}
              className="BotDocumentation__markdown"
            >
              {documentation}
            </Markdown>
          ) : (
            <div className="BotDocumentation__skeleton">
              <Skeleton
                style={{
                  width: '100%',
                  maxWidth: 350,
                  fontSize: 32,
                  marginBottom: 20
                }}
              />
              <Skeleton
                style={{
                  width: '100%',
                  maxWidth: 450,
                  fontSize: 24,
                  marginBottom: 30
                }}
              />
              <Skeleton width="100%" count={5} />
              <br />
              <Skeleton width="100%" count={5} />
              <br />
              <Skeleton width="100%" count={5} />
            </div>
          )}
        </div>
        <button
          className={cn('BotDocumentation__scroll')}
          disabled={!shouldDisplayScrollToTop}
          onClick={scrollToTop}
        >
          <Fade visible={shouldDisplayScrollToTop}>
            {(ref) => (
              <div ref={ref} className="BotDocumentation__scroll-label">
                Scroll to top
                <div>{ArrowUpIcon}</div>
              </div>
            )}
          </Fade>
        </button>
      </div>
    </div>
  );
}

export default BotDocumentation;
