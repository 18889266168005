import React, { useMemo } from 'react';
import cn from 'classnames';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent';
import { scaleTime } from 'd3-scale';
import { useResizeDetector } from 'react-resize-detector';
import { CountableTimeInterval } from 'd3-time';

import ChartTooltip from '../Tooltip/Tooltip';
import {
  calcDomains,
  calcTimeInterval,
  DataRecord,
  getTickInterval,
  getTickNumberFormatter,
  getTickTimeFormatter,
  TickFormatter
} from '../utils';

import './LineChart.scss';

import { CssColor, CssFont } from 'common/lib/css';
import Loader from 'common/components/Loader';
import Fade from 'common/components-v2/Transitions/Fade';

export type LineItem = {
  label: string;
  yAccessor: string;
  labelColor?: string;
  strokeColor?: string;
  formatter?: (value: number | string) => string;
};

type LineChartProps = {
  type?: 'time' | 'number';
  data: DataRecord[];
  lines: LineItem[];
  // common accessor for all the lines (data key)
  xAccessor: string;
  tooltipTitle?: TickFormatter;
  tooltipSubTitle?: TickFormatter;
  xTickFormatter?: TickFormatter;
  yTickFormatter?: TickFormatter;
  loading?: boolean;
  empty?: boolean;
  emptyStateMessage?: string;
  renderTooltip?: (props: TooltipProps<ValueType, NameType>) => JSX.Element;
  yAxisVisible?: boolean;
  xAxisVisible?: boolean;
  fillOpacity?: number;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number;
  className?: string;
};

export const TICK_STYLE = {
  fontSize: 11,
  fontWeight: 400,
  fontFamily: CssFont.NeueHaasDisplay,
  fill: CssColor.Neutral50
};

export const LINE_STYLE = {
  stroke: CssColor.Neutral30
};

const DEFAULT_TICK_WIDTH = 100;

function LineChart(props: LineChartProps): JSX.Element {
  const {
    data = [],
    type = 'number',
    minWidth,
    minHeight = 300,
    maxHeight,
    lines,
    xAccessor,
    loading,
    empty,
    yAxisVisible = true,
    xAxisVisible = true,
    fillOpacity = 1,
    emptyStateMessage = 'No data',
    className
  } = props;

  const { ref, width } = useResizeDetector({
    refreshMode: 'debounce',
    handleHeight: false
  });

  const lineByYAccessor = useMemo(() => {
    const map: { [accessor: string]: LineItem } = {};
    for (const line of lines) {
      map[line.yAccessor] = line;
    }
    return map;
  }, [lines]);

  // calculate min and max values
  const { xDomain, yDomain } = useMemo(() => {
    if (empty) {
      return {
        xDomain: [0, 1000] as [number, number],
        yDomain: [0, 100] as [number, number]
      };
    }

    return calcDomains(
      data,
      [xAccessor],
      lines.map((l) => l.yAccessor)
    );
  }, [data, empty, lines, xAccessor]);

  const { xTickInterval, xTickFormatter, yTickFormatter } = useMemo(() => {
    if (!xDomain || !yDomain) return {};

    let xTickFormatter: TickFormatter;
    let xTickInterval: CountableTimeInterval | undefined;

    if (props.xTickFormatter) {
      xTickFormatter = props.xTickFormatter;
    } else if (type === 'time') {
      // determine the observed time interval
      const xTimeInterval = calcTimeInterval(xDomain);
      // determine time frame
      xTickInterval = getTickInterval(xTimeInterval);
      xTickFormatter = getTickTimeFormatter(xTimeInterval);
    } else {
      xTickFormatter = getTickNumberFormatter(xDomain, false);
    }

    const yTickFormatter: TickFormatter =
      props.yTickFormatter || getTickNumberFormatter(yDomain);

    return {
      xTickInterval,
      xTickFormatter,
      yTickFormatter
    };
  }, [props.xTickFormatter, props.yTickFormatter, type, xDomain, yDomain]);

  // Unfortunately, the recharts library has many problems with the display of ticks.
  // The ticks can be missing or misaligned relative to the tick interval,
  // i.e. https://github.com/recharts/recharts/issues/1330.
  // Unfortunately, this cannot be solved with just library configuration,
  // for example setting interval={0} makes the chart not responsive at all.
  // ------
  // Based on the amount of issues I've encountered, it was decided to implement custom logic of tick rendering.
  const xTicks = useMemo(() => {
    if (!xDomain || !xTickFormatter || !xTickInterval || width == null) return;

    const scale = scaleTime().domain(xDomain.map((v) => Number(v) * 1000));

    let ticks: Date[] = [];
    for (let every = 1; every < 10; every++) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ticks = scale.ticks(xTickInterval.every(every)! || 0);
      if (ticks.length * DEFAULT_TICK_WIDTH <= width) break;
    }

    return ticks.map((v) => v.valueOf() / 1000);
  }, [xDomain, xTickFormatter, xTickInterval, width]);

  // set up default tooltip if the render function is empty
  const renderTooltip: NonNullable<typeof props.renderTooltip> = useMemo(() => {
    const tooltipTitle = props.tooltipTitle || xTickFormatter;
    const tooltipSubTitle = props.tooltipSubTitle;
    return (
      props.renderTooltip ||
      ((props) => (
        <ChartTooltip
          title={tooltipTitle ? tooltipTitle(props.label) : props.label}
          subtitle={tooltipSubTitle ? tooltipSubTitle(props.label) : undefined}
          records={props.payload
            ?.map((i) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const line = lineByYAccessor[i.dataKey!];
              return {
                label: line.label,
                color: line.labelColor,
                value:
                  line.formatter?.(i.value as string) || (i.value as string)
              };
            })
            // The values at the very bottom have the highest rendering priority because they override the others,
            // so we show them at the very beginning of the tooltip.
            .reverse()}
          recordsDirection={lines.length > 1 ? 'row' : 'column'}
        />
      ))
    );
  }, [
    props.renderTooltip,
    props.tooltipTitle,
    props.tooltipSubTitle,
    xTickFormatter,
    lines,
    lineByYAccessor
  ]);

  const emptyStateData = useMemo(() => {
    if (lines.length == 0) return [];

    return [
      { [xAccessor]: 0, [lines[0].yAccessor]: 25 },
      { [xAccessor]: 250, [lines[0].yAccessor]: 50 },
      { [xAccessor]: 500, [lines[0].yAccessor]: 30 },
      { [xAccessor]: 750, [lines[0].yAccessor]: 70 },
      { [xAccessor]: 1000, [lines[0].yAccessor]: 80 }
    ];
  }, [xAccessor, lines]);

  return (
    <div ref={ref} className={cn('LineChart', className)}>
      <ResponsiveContainer
        width="100%"
        height="100%"
        minWidth={minWidth}
        minHeight={minHeight}
        maxHeight={maxHeight}
      >
        <AreaChart
          data={empty ? emptyStateData : data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="#8884d8"
                stopOpacity={0.2 * fillOpacity}
              />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          {xAxisVisible && (
            <XAxis
              dataKey={xAccessor}
              type="number"
              scale={type === 'time' ? 'time' : 'linear'}
              domain={['auto', 'auto']}
              tick={TICK_STYLE}
              ticks={xTicks}
              tickFormatter={xTickFormatter}
              axisLine={LINE_STYLE}
              tickLine={LINE_STYLE}
              minTickGap={10}
            />
          )}
          {yAxisVisible && (
            <YAxis
              scale="linear"
              tick={TICK_STYLE}
              tickFormatter={yTickFormatter}
              axisLine={LINE_STYLE}
              tickLine={LINE_STYLE}
            />
          )}
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            content={(props) => renderTooltip(props)}
          />
          {lines.map((line) => (
            <Area
              key={line.yAccessor}
              name={line.label}
              dataKey={line.yAccessor}
              type="monotone"
              stroke={line.strokeColor || '#6855fa'}
              strokeWidth={2}
              fill="url(#colorUv)"
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
      <Fade visible={empty}>
        {(ref) => (
          <div ref={ref} className="LineChart__empty-state">
            <div className="LineChart__notification">{emptyStateMessage}</div>
          </div>
        )}
      </Fade>
      <Fade visible={loading}>
        {(ref) => (
          <div ref={ref} className="LineChart__loader">
            <Loader className="LineChart__loader-spinner" />
          </div>
        )}
      </Fade>
    </div>
  );
}

export default LineChart;
