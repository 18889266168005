import cn from 'classnames';
import { FunctionComponent } from 'react';

import { BaseComponentProps, createSubComponent } from '../../utils';

import './FancyContainer.scss';

type FancyContainerProps = BaseComponentProps & {
  variant?: 'default' | 'gradient';
};

type FancyContainerSubComponents = {
  Title: typeof FancyContainerTitle;
  Filters: typeof FancyContainerFilters;
  FullWidth: typeof FancyContainerFullWidth;
};

type FancyContainerType = FunctionComponent<FancyContainerProps> &
  FancyContainerSubComponents;

const FancyContainer: FancyContainerType = function FancyContainer(
  props: FancyContainerProps
): JSX.Element {
  const { variant = 'default', children, className } = props;
  return (
    <div
      className={cn('FancyContainer', `FancyContainer--${variant}`, className)}
    >
      {children}
    </div>
  );
};

const FancyContainerTitle = createSubComponent(
  'FancyContainerTitle',
  'FancyContainer__title'
);
const FancyContainerFilters = createSubComponent(
  'FancyContainerFilters',
  'FancyContainer__filters'
);
const FancyContainerFullWidth = createSubComponent(
  'FancyContainerFullWidth',
  'FancyContainer__full-width'
);

FancyContainer.Title = FancyContainerTitle;
FancyContainer.Filters = FancyContainerFilters;
FancyContainer.FullWidth = FancyContainerFullWidth;

export default FancyContainer;
