const currentVersion = localStorage.getItem('VERSION');

export const FILTERS_LS_KEY = 'saved-filters';
export const CONNECTION_TYPE = 'connectionType';
export const WALLET_CONNECT_SESSION = 'wc@2:client:0.3//session';

export const clearLS = (whitelist: string[]): void => {
  const whitelistValues: (string | null)[] = whitelist.map((item) =>
    localStorage.getItem(item)
  );
  localStorage.clear();
  whitelist.forEach((name, index) => {
    localStorage.setItem(name, whitelistValues[index] || '');
  });
};

if (currentVersion != process.env.VERSION) {
  clearLS([FILTERS_LS_KEY, CONNECTION_TYPE, WALLET_CONNECT_SESSION]);
  localStorage.setItem('VERSION', process.env.VERSION || '');
}

export default {
  saveNetworkState(networkState: { nodeUrl: string }): void {
    localStorage.setItem('network-state', JSON.stringify(networkState));
  },
  getNetworkState(defaultState: { nodeUrl: string }): { nodeUrl: string } {
    return JSON.parse(
      localStorage.getItem('network-state') || JSON.stringify(defaultState)
    );
  },
  setItem(name: string, value: string): void {
    localStorage.setItem(name, value);
  },
  getItem(name: string): string | null {
    return localStorage.getItem(name);
  }
};
