import React, { createContext, ReactNode, useContext, useState } from 'react';
import ConnectModal from './ConnectModal';

export interface ConnectModalContextProps {
  opened: boolean;
  open: () => void;
  close: () => void;
}

const ConnectModalContext = createContext<ConnectModalContextProps | undefined>(
  undefined
);

export const useConnectModal = (): ConnectModalContextProps => {
  const context = useContext(ConnectModalContext);
  if (!context) {
    throw new Error('useConnectModal must be used within a ModalProvider');
  }
  return context;
};

export function ConnectModalProvider({
  children
}: {
  children: ReactNode;
}): JSX.Element {
  const [opened, setOpened] = useState<boolean>(false);

  const open = (): void => {
    setOpened(true);
  };

  const close = (): void => {
    setOpened(false);
  };

  return (
    <ConnectModalContext.Provider value={{ opened, open, close }}>
      {children}
      <ConnectModal opened={opened} setOpened={setOpened} />
    </ConnectModalContext.Provider>
  );
}
