import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { chunk, range } from 'lodash';

import config from '../config';
import useProvider from './useProvider';
import Dispatch from 'forta-app/lib/contract-interactors-2/dispatch';
import ScannerPoolRegistry from 'forta-app/lib/contract-interactors-2/scanner-pool-registry';
import { retry } from '../lib/utils';

type ScannerMap = {
  [network: number]: string[];
};

async function getScannerMap(
  botId: string,
  provider: ethers.providers.JsonRpcProvider
): Promise<ScannerMap> {
  const batchProvider = new ethers.providers.JsonRpcBatchProvider(
    provider.connection
  );
  const dispatch = new Dispatch(batchProvider);
  const scannerPoolRegistry = new ScannerPoolRegistry(batchProvider);

  const map: ScannerMap = {};

  const scannerCount = (await dispatch.numScannersFor(botId)).toNumber();

  const limit = 50;

  const scanners: string[] = [];
  for (const batch of chunk(range(scannerCount), limit)) {
    const result: string[] = await Promise.all(
      batch.map((i) => retry(() => dispatch.scannerAt(botId, i)))
    ).then((v) => v.map((v) => ethers.utils.hexZeroPad(v.toHexString(), 20)));
    scanners.push(...result);
  }

  const chainIds: number[] = [];
  for (const batch of chunk(scanners, limit)) {
    const result: number[] = await Promise.all(
      batch.map((s) => retry(() => scannerPoolRegistry.getScannerState(s)))
    ).then((v) => v.map((v) => v.chainId.toNumber()));
    chainIds.push(...result);
  }

  for (let i = 0; i < scanners.length; i++) {
    const chainId = chainIds[i];
    const scanner = scanners[i];

    const arr = map[chainId] || [];
    arr.push(scanner);
    map[chainId] = arr;
  }

  return map;
}

function useBotScanners(botId: string): {
  scannersByChainId: ScannerMap | undefined;
  loading: boolean;
  fetched: boolean;
} {
  const provider = useProvider(config.chainId);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [scannersByChainId, setScannersByChainId] = useState<ScannerMap>();

  useEffect(() => {
    if (!botId) return;

    (async () => {
      try {
        setIsLoading(true);
        setScannersByChainId(await getScannerMap(botId, provider));
        setIsFetched(true);
      } catch (e) {
        console.error(e);
        setScannersByChainId({});
      } finally {
        setIsLoading(false);
      }
    })();
  }, [botId, provider]);

  return {
    loading: isLoading,
    fetched: isFetched,
    scannersByChainId: scannersByChainId
  };
}

export default useBotScanners;
