import {
  BoltIcon,
  FingerPrintIcon,
  FlagIconBlue,
  LifebuoyIcon,
  PhotoIcon,
  PuzzleColorIcon
} from 'common/components/Icons';
import { DataPlan } from 'forta-app/data/plans/types';
import { FrontendOnlySubscriptionType, SubscriptionType } from 'common/enums';

export const ScamDetectorPlan: DataPlan = {
  name: 'Scam Detector',
  description:
    'Provides real-time intelligence on scammers (EOAs, contracts and URLs) engaged in over 10 unique scam types including ice phishing, rug pulls and fraudulent NFT orders. Currently used by Web3 wallets, centralized exchanges, security tools and AML and Compliance platforms, among others.',
  icon: FlagIconBlue,
  botId: '0x5db613fb7fe6521a58f25c4239e0f24a8e83d9c5c95ff69f4af683244442444a',
  link: '/scam-detector',
  author: 'Forta Community',
  attributes: [],
  price: {
    USDC: 899,
    FORT: 0
  },
  unlockAddresses: {
    USDC: '0x2d34416aEc9587d9E997287fa679673459A76fb0',
    FORT: '',
    trial: '0x2c057a51a3cE817BFd2000FFe40dC4D437723460'
  },
  network: 84532,
  skipRecipient: true,
  subscription_type: SubscriptionType.ScamFeed,
  showOnDataPlanList: true
};
export const AttackDetectorPlan: DataPlan = {
  name: 'Attack Detector',
  description:
    'Detects smart contract attacks in real-time using a combination of heuristics and machine learning. This bot can detect attack activity before funds are stolen, giving teams and end users an opportunity to react before a loss occurs.',
  icon: BoltIcon,
  botId: '0x6f383b3ac9981d614feb91b0a96b76ce19c766ae475f7a5cd0d2039723b2f2dc',
  link: '/attack-detector',
  author: 'Forta Community',
  attributes: [],
  price: {
    USDC: 399,
    FORT: 0
  },
  unlockAddresses: {
    USDC: '0xf442c1Da837faD9f7C6090450fE985125E340106',
    FORT: '',
    trial: '0x8C034D406fBfBF95Ea441f44E025ce758852A56d'
  },
  network: 84532,
  skipRecipient: true,
  subscription_type: SubscriptionType.AttackFeed,
  showOnDataPlanList: false
};
export const SpamDetectorPlan: DataPlan = {
  name: 'Spam Detector',
  description:
    'Detects spam tokens that are airdropped to users without their consent. Some spam tokens are malicious, others are benign. This data feed can help wallets, marketplaces, AMMs and others detect and filter out unwanted tokens.',
  icon: PhotoIcon,
  botId: '0x075384e577be5c17efdc6cec5e80b3f84fb5b40257d8ecdd8ab0a542f8a6e084',
  link: '/spam-detector',
  author: 'Forta Community',
  attributes: [],
  price: {
    USDC: 599,
    FORT: 0
  },
  unlockAddresses: {
    USDC: '0x74Ed5063A84FfBD31619352e6058E8128EE4F85C',
    FORT: '',
    trial: '0x16006FAbFbf51633EfceEeb879d2c5bf648D3d9c'
  },
  network: 84532,
  skipRecipient: true,
  subscription_type: SubscriptionType.SpamFeed,
  showOnDataPlanList: true
};
export const TokenSnifferPlan: DataPlan = {
  name: 'Token Sniffer Rug Pull Detector',
  description:
    'This bot is an extension of Token Sniffer, the first and most-used token security platform, powered by Solidus Labs. Token Sniffer’s smart contract scanner analyzes token contract code and functionality, testing for malicious patterns to assess whether or not a token is a hard rug pull.',
  icon: LifebuoyIcon,
  botId: '0x0ae7201488b4a7722f7eff41484dbb2b8b71bbd79d14595ab5db234c3077901e',
  link: '/rug-pull-detector',
  author: 'Solidus Labs',
  attributes: [],
  price: {
    USDC: 899,
    FORT: 0
  },
  unlockAddresses: {
    USDC: '0xE33504324d1235f385dD392BdC93534bb1f8a9Be',
    FORT: '',
    trial: '0x4fF572103Dad51decbFc6F94E5fcA08256a34a57'
  },
  network: 84532,
  skipRecipient: true,
  subscription_type: SubscriptionType.RugPull,
  showOnDataPlanList: true
};
export const SybilDefenderPlan: DataPlan = {
  name: 'Sybil Defender',
  description:
    'This is a cutting-edge tool designed to provide real-time alerts on potential Sybil attacks across multiple blockchains. Sybil Defender monitors on-chain transactions to detect and analyze suspicious patterns indicative of Sybil behavior, such as Airdrop Farming and Wash Trading.',
  icon: FingerPrintIcon,
  botId: '0x7aad3bf11e445294f6806ae544dd68b2421f2dec84dfb4e2c2d2ff63f9e85cb5',
  link: '/sybil-defender',
  author: 'frwd labs',
  attributes: [],
  price: {
    USDC: 899,
    FORT: 0
  },
  unlockAddresses: {
    USDC: '0xD9b9ef8ec304c2F8D423457176089FD482416F2f',
    FORT: '',
    trial: '0x7D27521980C18b7D4646Cee49C26De119CbAa1d6'
  },
  network: 84532,
  skipRecipient: true,
  subscription_type: SubscriptionType.SybilDefender,
  showOnDataPlanList: true
};
export const GeneralPlan: DataPlan = {
  name: 'General Data',
  description:
    'Access to 99%+ of the Network’s hundreds of detection bots (ie. everything except Premium Feeds). Discover and select which bots are best for you and get a customized experience.',
  icon: PuzzleColorIcon,
  author: 'Forta Community',
  link: '/bots',
  attributes: [
    {
      label: 'All comunity bots',
      checked: true
    },
    {
      label: 'No premium bots',
      checked: false
    }
  ],
  price: {
    USDC: 0,
    FORT: 250
  },
  unlockAddresses: {
    USDC: '',
    FORT: '0x044a8E16e23BF392783CaDe147df16e3C2005347',
    trial: '0x1bE01B0A6372f6c85275770F9CBD4BBC63f35251'
  },
  network: 84532,
  skipRecipient: true,
  subscription_type: SubscriptionType.General,
  showOnDataPlanList: true
};
export const AiMaliciousBytecodeDetectorPlan: DataPlan = {
  name: 'AI Malicious Bytecode/Opcode Detector',
  description: '',
  botId: '0x15dd9eb127055f0adcad32ee57bea181901c24acbbdca65e68f0323479a18417',
  attributes: [],
  price: {
    USDC: 0,
    FORT: 0
  },
  unlockAddresses: {
    USDC: '0xDF652a20964Bc69cca1a04337DF0dD553A59bb73',
    FORT: '',
    trial: ''
  },
  network: 80001,
  subscription_type: FrontendOnlySubscriptionType.MaliciousBytecodeFeed,
  skipRecipient: true,
  showOnDataPlanList: false
};

const plans: DataPlan[] = [
  ScamDetectorPlan,
  AttackDetectorPlan,
  SpamDetectorPlan,
  TokenSnifferPlan,
  SybilDefenderPlan,
  AiMaliciousBytecodeDetectorPlan,
  GeneralPlan
];

export const trials: string[] = [
  '0xdd41c8982a7ec6709a558c05f05dc828db97e23c',
  '0xb08488110e29e7d598d1dd57cdcaf8f392248a9a',
  '0x6a915656a2bf09a91cd2f6aa53f42a9084447cd6',
  '0x7534a97e9b69a6565983d914cd798076fff672c3',
  '0x646007E846317B3DcD35200F40e5dc783C24a5Be',
  '0xcd30DF4E96814Ee257C2c1c87d61D08A5c211Ec1'
];

export default plans;
