import React, { MouseEventHandler } from 'react';
import cn from 'classnames';

import Checkbox from '../Form/Checkbox/Checkbox';

import './TableCell.scss';

interface TableCellProps {
  flex?: number;
  children: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

function TableCell(props: TableCellProps): JSX.Element {
  const { flex = 1, children, className, ...rest } = props;
  return (
    <div className={cn('TableCell', className)} style={{ flex }} {...rest}>
      {children}
    </div>
  );
}

type TableCheckableCellProps = {
  checked?: boolean;
  onChangedChange?: (checked: boolean) => unknown;
};

export function TableCheckableCell(
  props: TableCheckableCellProps
): JSX.Element {
  const { checked, onChangedChange } = props;

  function handleToggleCheck(): void {
    if (onChangedChange) onChangedChange(!checked);
  }

  return (
    <TableCell
      className="TableCheckableCell"
      flex={0}
      onClick={(e) => e.stopPropagation()}
    >
      <Checkbox checked={checked} onChange={handleToggleCheck} />
    </TableCell>
  );
}

export default TableCell;
