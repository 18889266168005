import { gql } from '@apollo/client';
import useGraphQuery, { GraphQueryResult } from 'common/hooks/useGraphQuery';

export const BOT_PERFORMANCE_QUERY = gql`
  query BotPerformanceQuery($input: GetAgentPerformanceInput) {
    getAgentPerformanceMetrics(input: $input) {
      transactions {
        timestamp
        value
      }
    }
  }
`;

type RequestVariables = {
  input: {
    agentId: string;
  };
};

type PerformanceItem = {
  timestamp: number;
  value: number;
};

type ResponseData = {
  getAgentPerformanceMetrics: {
    transactions: PerformanceItem[];
  };
};

function useBotPerformance(opts: {
  botId: string;
  enabled?: boolean;
}): GraphQueryResult<RequestVariables, ResponseData> & {
  transactions: PerformanceItem[];
} {
  const { botId, enabled } = opts;

  const query = useGraphQuery<RequestVariables, ResponseData>({
    enabled: enabled,
    query: BOT_PERFORMANCE_QUERY,
    variables: {
      input: { agentId: botId }
    }
  });

  return {
    ...query,
    transactions: query.data?.getAgentPerformanceMetrics?.transactions || []
  };
}

export default useBotPerformance;
