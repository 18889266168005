import React from 'react';
import cn from 'classnames';

import './Severity.scss';

import { SeveritiesInfo } from 'common/lib/apis/alertAPI';
import { capitalize } from 'common/lib/utils';

type SeverityProps = {
  severity: string;
  className?: string;
};

function Severity(props: SeverityProps): JSX.Element {
  const { severity, className } = props;

  const severityInfo = SeveritiesInfo[severity];

  return (
    <div
      className={cn('Severity', className)}
      style={{ color: severityInfo.color }}
    >
      {capitalize(severity)}
    </div>
  );
}

export default Severity;
