import React, { useState } from 'react';
import cn from 'classnames';

import './Chain.scss';
import BaseFilterModal, { FilterModalDescription } from '../Base/Base';

import Select from 'common/components-v2/Form/Select/Select';
import Input from 'common/components-v2/Form/Input/Input';
import { getNetworkByChainId } from 'common/lib/networks';
import FormField from 'common/components-v2/Form/Field/Field';

type ChainModalProps = {
  variant: 'select' | 'input';
  open?: boolean;
  value?: string;
  chainIds?: number[];
  onChange: (chainId?: string) => unknown;
  onClose: () => unknown;
  className?: string;
};

function ChainModal(props: ChainModalProps): JSX.Element {
  const { variant, open, value, chainIds, onChange, onClose, className } =
    props;

  const [error, setError] = useState<string>();

  return (
    <BaseFilterModal
      open={open}
      value={value}
      title="Chain"
      disabled={!!error}
      onChange={onChange}
      onClose={onClose}
      className={cn('FilterChainModal', className)}
    >
      {({ value, setValue }) => {
        function handleManualInput(value: string): void {
          setValue(value);

          const regex = /^\d+$/gm;

          if (value.trim().length === 0 || regex.test(value)) {
            setError(undefined);
          } else {
            setError('Invalid chain ID');
          }
        }

        return (
          <>
            <FilterModalDescription>
              EIP155 identifier of the chain. Alerts returned will only be from
              the specific chain Id.
            </FilterModalDescription>
            {variant === 'input' ? (
              <FormField label="Chain ID" errors={error ? [error] : undefined}>
                <Input
                  resettable
                  variant="dark"
                  name="forta-notification-source-input"
                  value={value || ''}
                  placeholder="Enter chain ID..."
                  onChange={(e) => handleManualInput(e.target.value)}
                />
              </FormField>
            ) : (
              <Select
                searchable={false}
                name="forta-notification-source-type"
                value={value || ''}
                options={
                  chainIds?.map((chainId) => {
                    const network = getNetworkByChainId(chainId);
                    return {
                      value: chainId.toString(),
                      label: network.label
                    };
                  }) || []
                }
                onChange={(event) => setValue(event.target.value)}
                placeholder="Select a Network..."
              />
            )}
          </>
        );
      }}
    </BaseFilterModal>
  );
}

export default ChainModal;
