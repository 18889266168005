import React, { useState } from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import './VaultSection.scss';

import Button from 'common/components-v2/Button/Button';
import useVault from 'common/hooks/useVault';
import useWallet from 'common/hooks/useWallet';
import { formatFORT } from 'forta-app/lib/utils';
import { useConnectModal } from 'forta-app/components/wallet/useConnectModal';
import VaultStakeModal from 'forta-app/components/staking/VaultStakingModal';
import { trackEvent } from '../../../../common/lib/analytics';

type VaultSectionProps = {
  className?: string;
};

function VaultSection(props: VaultSectionProps): JSX.Element {
  const { className } = props;

  const { signed, address } = useWallet();
  const { open: openConnectModal, opened: isConnectModalOpen } =
    useConnectModal();
  const [vaultStakeModalOpened, setVaultStakeModalOpened] =
    useState<boolean>(false);

  const { apy, userAssets, totalAssets, fetchAssets } = useVault({
    account: address
  });

  async function handleDeposit(): Promise<void> {
    if (!signed) {
      openConnectModal();
      return;
    }

    trackEvent(`stake_page_vault_deposit_click`, {});

    setVaultStakeModalOpened(true);
  }

  function handleModalClose(): void {
    setVaultStakeModalOpened(false);
    fetchAssets();
  }

  return (
    <div className={cn('VaultSection', className)}>
      <div className="VaultSection__container">
        <div className="VaultSection__header">
          <h2 className="VaultSection__title">Simplified Vault</h2>
          <div className="VaultSection__description">
            Simply deposit your FORT in the vault to automatically earn rewards.
          </div>
        </div>
        <div className="VaultSection__stats">
          <div className="VaultStat VaultStat--highlighted">
            <div className="VaultStat__label">Est. Annual Rewards</div>
            <div className="VaultStat__value">
              {apy != null ? `${apy}%` : <Skeleton width={50} />}
            </div>
          </div>
          <div className="VaultStat">
            <div className="VaultStat__label">Your Holdings</div>
            <div className="VaultStat__value">
              {userAssets ? (
                `${formatFORT(userAssets.toString())} FORT`
              ) : (
                <Skeleton width={100} />
              )}{' '}
            </div>
          </div>
          <div className="VaultStat">
            <div className="VaultStat__label">Total in Vault</div>
            <div className="VaultStat__value">
              {totalAssets ? (
                formatFORT(totalAssets.toString()) + ' FORT'
              ) : (
                <Skeleton width={100} />
              )}{' '}
            </div>
          </div>
        </div>
        <Button
          round
          variant="primary"
          size="lg"
          className="VaultSection__deposit-button"
          onClick={handleDeposit}
          loading={isConnectModalOpen}
          disabled={isConnectModalOpen}
        >
          Deposit
        </Button>
      </div>
      <VaultStakeModal
        opened={vaultStakeModalOpened}
        onClose={handleModalClose}
      />
    </div>
  );
}

export default VaultSection;
