import React from 'react';
import cn from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './FileUploader.scss';
import { DocumentationItem, isDocumentationIpfsItem } from './BotEditForm';

import FileSelector from 'common/components/FileSelector';
import FileItem from 'common/components-v2/Form/File/FileItem/FileItem';
import { formatFileSize } from 'common/lib/utils';

type FileUploaderProps = {
  error?: string;
  items: DocumentationItem[];
  itemErrors?: (string | undefined)[];
  onItemsChange: (items: DocumentationItem[]) => unknown;
  className?: string;
};

function FileUploader(props: FileUploaderProps): JSX.Element {
  const { itemErrors = [], items, error, onItemsChange, className } = props;

  function handleAddFile(file: File | null): void {
    if (!file) return;

    onItemsChange([
      ...items,
      {
        file: file,
        title: file.name
      }
    ]);
  }

  function handleFileEdit(item: DocumentationItem, title: string): void {
    const newItems: DocumentationItem[] = [];
    for (const oldItem of items) {
      if (oldItem === item) {
        const newItem: DocumentationItem = {
          ...item,
          title: title
        };
        newItems.push(newItem);
      } else {
        newItems.push(oldItem);
      }
    }

    onItemsChange(newItems);
  }

  return (
    <div className={cn('FileUploader', className)}>
      <div className="FileField">
        <div className="FileField__item">
          <FileSelector
            name={null}
            onChange={handleAddFile}
            description={`Uploading multiple files will create subcategory tabs within the documentation section of your bot's page`}
            className="FileUploader__selector"
          />
        </div>
        {!!error && (
          <div className="FileField__message FileField__message--error">
            {error}
          </div>
        )}
      </div>
      <TransitionGroup component="ul" className="FileUploader__list">
        {items.map((item, i) => {
          let title = '';
          let subtitle = '';
          if (isDocumentationIpfsItem(item)) {
            title = `File image #${i + 1}`;
            subtitle = `IPFS: ${item.ipfsHash.slice(0, 14)}...`;
          } else {
            title = item.file?.name || 'Local file';
            subtitle = `Size: ${formatFileSize(item.file?.size || 0)}`;
          }

          const error = itemErrors[i];

          return (
            <CSSTransition key={i} timeout={500} classNames="file-item">
              <li key={i} className="FileField">
                <FileItem
                  input={{
                    name: 'file-' + i.toString(),
                    value: item.title,
                    placeholder: 'Documentation title',
                    onChange: (e) => handleFileEdit(item, e.target.value)
                  }}
                  inputVisible={items.length > 1}
                  invalid={!!error}
                  title={title}
                  subtitle={subtitle}
                  icon={isDocumentationIpfsItem(item) ? 'ipfs' : 'file'}
                  description={
                    'File name will reflect what is shown on documentation subcategory tabs'
                  }
                  onRemove={() =>
                    onItemsChange(items.filter((i) => i !== item))
                  }
                  className="FileField__item"
                />
                {!!error && (
                  <div className="FileField__message FileField__message--error">
                    {error}
                  </div>
                )}
              </li>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
}

export default FileUploader;
