import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { BigNumber } from 'ethers';
import useOutsideClick from 'react-cool-onclickoutside';

import './ScanNodePoolActionsButton.scss';
import { UpdateCommissionButton } from './UpdateCommissionButton';
import { AddScanNodePoolStakeButton } from './AddScanNodePoolStakeButton';
import { AllocateStakeButton, Allocation } from './AllocateStakeButton';

import { ArrowDownIcon, ArrowUpIcon } from 'common/components/Icons';
import Button from 'common/components-v2/Button/Button';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import { DelegatedFormProps } from '../staking/StakingForm';
import WithdrawButton from '../staking/WithdrawButton';
import { getStakesOf, Stake } from 'forta-app/lib/apis/subgraphAPI';
import { useJWT } from 'forta-app/app/hooks';
import useAccountStakeQuery from 'common/hooks/useAccountStakeQuery';

export default function ScanNodePoolActionsButton({
  scannerPool
}: {
  scannerPool: ScannerPool;
}): JSX.Element {
  const rootElRef = useRef<HTMLDivElement>(null);
  const jwt = useJWT();
  const web3React = useWeb3React();
  const account = web3React.account || '';
  const isOwner = scannerPool.owner.id.toLowerCase() === account.toLowerCase();
  const [opened, setOpened] = useState<boolean>(false);
  const [stake, setStake] = useState<Stake>({} as Stake);

  const allocation: Allocation = {
    stakeAllocated: BigNumber.from(scannerPool.stakeAllocated),
    stakeDelegated: BigNumber.from(scannerPool.stakeDelegated),
    stakeOwned: BigNumber.from(scannerPool.stakeOwned),
    stakeOwnedAllocated: BigNumber.from(scannerPool.stakeOwnedAllocated)
  };

  const delegationForm: DelegatedFormProps = {
    allocation,
    commission: scannerPool.commission,
    owner: scannerPool.owner.id,
    pool: scannerPool
  };

  const updateStake = useCallback(async () => {
    if (account && jwt) {
      try {
        const result = await getStakesOf(account);
        setStake(
          result?.stakes?.find(
            (stake) => stake.subject?.id === scannerPool.id
          ) || ({} as Stake)
        );
      } catch (err) {
        toast.error('Error retrieving stakes');
        console.error(err);
        setStake({} as Stake);
      }
    }
  }, [jwt, account, scannerPool.id]);

  useEffect(() => {
    updateStake();
  }, [updateStake]);

  // Ideally, we would rewrite this component to use the existing "Menu" component,
  // but to do so, we would need to move/merge the logic of the nested button-components into this parent component.
  // This is very time-consuming and cause new bugs, so as a quick solution,
  // I decided to implement a separate logic for closing this menu,
  // while keeping the current component-design.
  // ----
  // However, in the future, when changing the logic, it makes sense to do it the right way, using the Menu component.
  useOutsideClick(() => setOpened(false), {
    refs: [rootElRef],
    ignoreClass: 'ReactModalPortal'
  });

  const accountStakeQuery = useAccountStakeQuery({
    params: { address: account },
    enabled: true
  });

  const delegations = accountStakeQuery?.stake?.delegations;

  const delegation = delegations?.find(
    (delegation) => delegation.subject?.subjectId === scannerPool.id
  ) as Stake;

  return (
    <div ref={rootElRef} className="ScanNodePoolActionsButton">
      <div className="ScanNodePoolActionsButton__stake">
        <AddScanNodePoolStakeButton
          scannerPoolId={scannerPool.id}
          isOwner={isOwner}
          scanNodes={scannerPool.scanNodes.length}
          delegatedForm={delegationForm}
        />
      </div>
      <div className="ScanNodePoolActionsButton__options-dropdown">
        <Button
          size="lg"
          variant="primary"
          startIcon={opened ? ArrowUpIcon : ArrowDownIcon}
          onClick={() => setOpened(!opened)}
          className="ScanNodePoolActionsButton__options-button"
          disabled={!isOwner && _.isEmpty(delegation)}
        />
        {opened && (
          <div className="ScanNodePoolActionsButton__options">
            {isOwner ? (
              <>
                <UpdateCommissionButton
                  pool={scannerPool}
                  className="ScanNodePoolActionsButton__options__item"
                />
                <AllocateStakeButton
                  allocation={allocation}
                  scannerPoolId={scannerPool.id}
                  scanNodes={scannerPool.scanNodes.length}
                  className="ScanNodePoolActionsButton__options__item"
                />
                {!_.isEmpty(stake) && (
                  <WithdrawButton
                    className="ScanNodePoolActionsButton__options__item"
                    stake={stake}
                    onDone={updateStake}
                  />
                )}
              </>
            ) : (
              !_.isEmpty(delegation) && (
                <WithdrawButton
                  className="ScanNodePoolActionsButton__options__item"
                  stake={delegation}
                  onDone={updateStake}
                />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}
