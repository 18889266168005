import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import slugify from 'slugify';

import './AttackDetector.scss';
import useAttackDetectorData from './useAttackDetectorData';
import { DetectedExploits } from './constants';

import NethermindLogo from './images/nethermind-logo.png';
import BlocksecLogo from './images/blocksec-logo.png';
import GradientImage from './images/line-gradient.png';
import LineImage from './images/line.png';
import DiscordImage from './images/endpoints/discord.png';
import EmailImage from './images/endpoints/email.png';
import TelegramImage from './images/endpoints/telegram.png';
import WebhookImage from './images/endpoints/webhook.png';
import OpenZeppelinImage from './images/endpoints/openzeppelin.png';
import HackenImage from './images/endpoints/hacken.png';
import SlackImage from './images/endpoints/slack.png';
import DemoImage from './images/demo.png';
import LifeCycleImage from './images/lifecycle.png';
import LifeCycleCompactImage from './images/lifecycle-compact.png';

import { ArrowRight2Icon, BoltIcon } from 'common/components/Icons';
import Link from 'common/components-v2/Button/Link';
import Button from 'common/components-v2/Button/Button';
import BaseButton from 'common/components-v2/Button/BaseButton';
import { Routes } from 'common/routes';
import { getNetworkByChainId } from 'common/lib/networks';
import InfoPopover from 'common/components/InfoPopover';
import PieChart from 'common/components-v2/Chart/Pie/Pie';
import ChainIcon from 'common/components/stats/ChainIcon';
import { getPlanEntities } from 'forta-app/data/plans';
import useBotsQuery from 'forta-app/hooks/useBotsQuery';
import { trackEvent } from 'common/lib/analytics';
import useBotSubscription from 'common/hooks/useBotSubscription';
import useWallet from 'common/hooks/useWallet';
import { useConnectModal } from 'forta-app/components/wallet/useConnectModal';
import SuccessDataPlanModal from 'forta-app/components/account/SuccessDataPlanModal';
import Loader from 'common/components/Loader';
import { formatNumber } from 'forta-app/lib/utils';

function AttackDetectorPage(): JSX.Element {
  const { address: walletAddress, web3React, ensureConnection } = useWallet();
  const { open: openConnectModal } = useConnectModal();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [metricsElRef, isMetricsVisible] = useInView({
    triggerOnce: true
  });

  const plan = useMemo(() => getPlanEntities().AttackDetectorPlan, []);

  const { bots } = useBotsQuery({
    params: { id: plan?.botId || '' },
    enabled: !!plan
  });
  const bot = bots[0];

  const { chains, metrics, data } = useAttackDetectorData({
    params: {
      botId: plan?.botId || ''
    },
    enabled: !!plan
  });

  const { isPaid, openCheckoutModal } = useBotSubscription({
    params: {
      bot: bot,
      account: walletAddress,
      provider: web3React.provider,
      onSuccess: () => setIsSuccessModalOpen(true)
    }
  });

  useEffect(() => {
    const bodyEl = document.querySelector('#FortaApp');

    if (bodyEl) {
      bodyEl.classList.add('overflow-x-hidden');

      return () => bodyEl.classList.remove('overflow-x-hidden');
    }
  }, []);

  async function handleBuyPlan(): Promise<void> {
    if (!walletAddress) {
      openConnectModal();
      return;
    }

    await ensureConnection();

    trackEvent(
      `data_plan_product_page_click_${slugify(plan?.name || '', {
        lower: true
      })}`,
      {}
    );

    openCheckoutModal();
  }

  if (!data || !metrics || !plan || !bot) {
    return (
      <div className="AttackDetectorPage">
        <Loader className="AttackDetectorPage__loader" />
      </div>
    );
  }

  return (
    <div className={cn('AttackDetectorPage')}>
      <div className="Hero">
        <div className="Hero__gradient-1" />
        <div className="Hero__gradient-2" />
        <div className="Hero__gradient-3" />
        <div className="Hero__info">
          <BaseButton
            to={Routes.bot.details({ id: bot.agent_id || '#' })}
            className="BotLink"
          >
            <div className="BotLink__icon">{BoltIcon}</div>
            <div className="BotLink__name">Attack Detector</div>
          </BaseButton>
          <h1 className="Hero__title">
            Proactive Threat Intelligence: Detecting hacks before they occur
          </h1>
          <p className="Hero__description">
            The Attack Detector provides real-time alerts regarding smart
            contract exploits prior to the exploit transaction. Utilizing
            cutting edge smart contract exploit and advanced anomaly detection,
            the Attack Detector scans every contract deployed to identify
            malicious functions.
          </p>
          <div className="Hero__price">
            <span className="Hero__price-value">
              {plan.price.USDC ? (
                <>{formatNumber(plan.price.USDC)} USDC</>
              ) : (
                <>{formatNumber(plan.price.FORT)} FORT</>
              )}
            </span>
            <span className="Hero__price-period">/ month</span>
          </div>
          <div className="Hero__actions">
            {isPaid ? (
              <Button
                round
                variant="primary"
                size="lg"
                endIcon={ArrowRight2Icon}
                to={Routes.bot.details({ id: bot.agent_id || '#' })}
              >
                Visit bot&apos;s page
              </Button>
            ) : (
              <>
                <Button
                  variant="primary"
                  size="lg"
                  className="Hero__buy-button"
                  onClick={handleBuyPlan}
                  round
                >
                  Buy Plan
                </Button>
                <Button
                  round
                  variant="outline"
                  size="lg"
                  endIcon={ArrowRight2Icon}
                  to={Routes.bot.details({ id: bot.agent_id || '#' })}
                >
                  Visit bot&apos;s page
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="Hero__image">
          <img src={DemoImage} alt="Alert" />
        </div>
      </div>
      <div className="AttackDetectorPage__container">
        <div className="MaintenanceInfo">
          <span>Maintained alongside</span>
          <div className="MaintenanceInfo__partner">
            <img src={BlocksecLogo} alt="Blocksec logo" />
          </div>
          <span>and</span>
          <div className="MaintenanceInfo__partner">
            <img src={NethermindLogo} alt="Nethermind logo" />
          </div>
        </div>
        <section className="Highlights">
          <h2 className="Highlights__title">Real-Time Threat Monitoring </h2>
          <p className="Highlights__description">
            The Attack Detector is built by the community for the community,
            setting the standard for web3 protocol security.
          </p>
          <div className="Highlights__networks">
            {chains.map((v) => {
              const network = getNetworkByChainId(v.chainId);
              return (
                <InfoPopover
                  key={`network-${network.chainId}`}
                  content={network.label}
                >
                  <ChainIcon
                    network={network}
                    className="Highlights__network"
                  />
                </InfoPopover>
              );
            })}
          </div>
          <div className="Highlights__metrics">
            <div className="KeyMetric">
              <div className="KeyMetric__value">{'< 400'}</div>
              <div className="KeyMetric__label">Alerts per month</div>
            </div>
            <div aria-hidden="true" className="Highlights__metrics-separator" />
            <div className="KeyMetric">
              <div className="KeyMetric__value">
                {(metrics.precision * 100).toFixed(0)}%
              </div>
              <div className="KeyMetric__label">Precision percentage</div>
            </div>
            <div aria-hidden="true" className="Highlights__metrics-separator" />
            <div className="KeyMetric">
              <div className="KeyMetric__value">99.99%</div>
              <div className="KeyMetric__label">Uptime</div>
            </div>
          </div>
        </section>
        <section className="HowItWorks">
          <h2 className="HowItWorks__title">ML-Powered Foresight</h2>
          <p className="HowItWorks__description">
            Powered by machine learning, the Attack Detector alerts on hacks{' '}
            <span style={{ color: '#73E371', fontWeight: 500 }}>
              before the exploitation stage
            </span>{' '}
            — enabling an opportunity for intervention by protocols.
          </p>
          <div className="HowItWorks__demo">
            <img
              src={LifeCycleImage}
              alt="Attack LifeCycle"
              className="HowItWorks__lifecycle-image"
            />
            <img
              src={LifeCycleCompactImage}
              alt="Attack LifeCycle"
              className="HowItWorks__lifecycle-compact-image"
            />
          </div>
          <p className="HowItWorks__description-2">
            The decentralized Forta Network offers subscriptions for incoming
            exploit detection monitoring, providing intel through APIs or direct
            bot alerts.
          </p>
          <div className="HowItWorks__features">
            <div className="FeatureCard">
              <div className="FeatureCard__title">Alert Notification</div>
              <div className="FeatureCard__description">
                The Forta Network allows users to access the intel generated by
                detection bots through a variety of methods. Intel can be
                accessed through the GraphQL API (pull) or by subscribing to a
                bot directly (push) and receiving notifications.
              </div>
              <div className="FeatureCard__integrations">
                {[
                  {
                    name: 'Discord',
                    image: DiscordImage
                  },
                  {
                    name: 'Email',
                    image: EmailImage
                  },
                  {
                    name: 'Telegram',
                    image: TelegramImage
                  },
                  {
                    name: 'Slack',
                    image: SlackImage
                  },
                  {
                    name: 'Webhook',
                    image: WebhookImage
                  }
                ].map((v) => (
                  <InfoPopover key={v.name} content={v.name}>
                    <div key={v.name} className="FeatureCard__integration">
                      <img src={v.image} alt={v.name} />
                    </div>
                  </InfoPopover>
                ))}
              </div>
              <Link
                showArrow
                target="_blank"
                href={Routes.docs.subscribingToAlerts}
                className="FeatureCard__link"
              >
                Read more about subscriptions
              </Link>
            </div>
            <div className="FeatureCard">
              <div className="FeatureCard__title">Automated Pause</div>
              <div className="FeatureCard__description">
                Natively integrated into OpenZeppelin Defender or Hacken
                Extractor, set up working automated incident response workflows
                (with a pause function) in minutes!
              </div>
              <div className="FeatureCard__integrations">
                {[
                  {
                    name: 'Webhook',
                    image: WebhookImage
                  },
                  {
                    name: 'OpenZeppelin Defender',
                    image: OpenZeppelinImage
                  },
                  {
                    name: 'Hacken Extractor',
                    image: HackenImage
                  }
                ].map((v) => (
                  <InfoPopover key={v.name} content={v.name}>
                    <div key={v.name} className="FeatureCard__integration">
                      <img src={v.image} alt={v.name} />
                    </div>
                  </InfoPopover>
                ))}
              </div>
              <Link
                showArrow
                target="_blank"
                href={Routes.blog.automaticPausing}
                className="FeatureCard__link"
              >
                Read more about automation
              </Link>
            </div>
          </div>
        </section>
        <section ref={metricsElRef} className="Metrics">
          <div className="Metrics__gradient-1" />
          <div className="Metrics__gradient-2" />
          <h2 className="Metrics__title">Metrics</h2>
          {isMetricsVisible && (
            <div className="Metrics__data">
              <div className="Metrics__grid">
                <div className="Metric">
                  <div className="Metric__label">Precision</div>
                  <div className="Metric__value">
                    {(metrics.precision * 100).toFixed(0)}%
                  </div>
                </div>
                <div className="Metric">
                  <div className="Metric__label">Alerts / 24h</div>
                  <div className="Metric__value">{metrics.alertsPerDay}</div>
                </div>
                <div className="Metric">
                  <div className="Metric__label">Transaction processed</div>
                  <div className="Metric__value">20B+</div>
                </div>
                <div className="Metric">
                  <div className="Metric__label">Node operators</div>
                  <div className="Metric__value">{metrics.nodeOperators}</div>
                </div>
                <div className="Metric">
                  <div className="Metric__label">
                    Assets could have been saved
                  </div>
                  <div className="Metric__value">
                    {metrics.fundsCouldBeSaved}
                  </div>
                </div>
              </div>
              <div className="Metrics__chart">
                <div className="NetworkChart">
                  <h3 className="NetworkChart__title">Most active chains</h3>
                  <p className="NetworkChart__description">
                    Below is the number of alerts per month.
                  </p>
                  <div className="NetworkChart__data">
                    <div className="NetworkChart__list">
                      {chains.map((v) => {
                        const network = getNetworkByChainId(v.chainId);

                        return (
                          <div
                            key={network.chainId}
                            className="NetworkChartItem"
                          >
                            <div
                              className="NetworkChartItem__icon"
                              style={{ backgroundColor: network.color }}
                            />
                            <div className="NetworkChartItem__name">
                              {network.label}
                            </div>
                            <div className="NetworkChartItem__value">
                              {v.alertsPerMonth}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="NetworkChart__pie">
                      <PieChart
                        data={chains.map((v) => {
                          const network = getNetworkByChainId(
                            Number(v.chainId)
                          );

                          return {
                            color: network.color,
                            value: v.alertsPerMonth,
                            label: network.label
                          };
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <section className="Exploits">
          <div className="Exploits__gradient-1" />
          <div className="Exploits__gradient-2" />
          <h2 className="Exploits__title">Exploits Detected</h2>
          <ul className="Exploits__list">
            {DetectedExploits.map((v) => (
              <li key={v.project} className="Exploits__item">
                <BaseButton
                  href={v.sourceLink}
                  target="_blank"
                  className="ExploitCard"
                >
                  <div className="ExploitCard__project">
                    <div className="ExploitCard__logo">
                      <img src={v.image} alt={v.project} />
                    </div>
                    {v.project}
                  </div>
                  <div className="ExploitCard__value">{v.losses}</div>
                  <div className="ExploitCard__description">
                    {v.description}
                  </div>
                  <div className="ExploitCard__meta">
                    <div className="ExploitCard__date">{v.date}</div>
                    <div className="ExploitCard__source">{v.sourceName}</div>
                  </div>
                </BaseButton>
              </li>
            ))}
          </ul>
        </section>
        <section className="FinalCta">
          <h2 className="FinalCta__title">
            Protect yourself from tomorrow’s hacks, today
          </h2>
          <p className="FinalCta__description">
            Get access to real-time threat intelligence about EOAs and smart
            contracts involved in malicious actions.
          </p>
          <div className="FinalCta__price">
            <span className="FinalCta__price-value">
              {plan.price.USDC ? (
                <>{formatNumber(plan.price.USDC)} USDC</>
              ) : (
                <>{formatNumber(plan.price.FORT)} FORT</>
              )}
            </span>
            <span className="FinalCta__price-period">/ month</span>
          </div>
          <div className="FinalCta__actions">
            <Button
              round
              variant="primary"
              size="lg"
              className="FinalCta__buy-button"
              onClick={handleBuyPlan}
            >
              Buy Plan
            </Button>
          </div>
          <img
            src={GradientImage}
            className="FinalCta__image"
            aria-hidden="true"
            alt="Decoration"
          />
          <img
            src={LineImage}
            className="FinalCta__image"
            aria-hidden="true"
            alt="Decoration"
          />
        </section>
        <section className="ContactUs">
          <div className="ContactUs__title">Still have any questions?</div>
          <div className="ContactUs__description">
            Our team will be happy to advise you on whether this solution is
            suitable for you, as well as guide you through the integration into
            your application.
          </div>
          <div className="ContactUs__actions">
            <Button
              href="mailto:info@forta.org"
              target="_blank"
              variant="outline"
              size="lg"
              round
            >
              Contact Us
            </Button>
            <Link
              to={Routes.bot.details({ id: bot.agent_id || '#' })}
              showArrow
            >
              Visit bot&apos;s page
            </Link>
          </div>
        </section>
      </div>
      <SuccessDataPlanModal
        planName={plan.name}
        opened={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
      />
    </div>
  );
}

export default AttackDetectorPage;
