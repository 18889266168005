import React from 'react';
import { NavLink } from 'react-router-dom';
import slugify from 'slugify';
import cn from 'classnames';

import './DataPlanCard.scss';

import {
  ArrowRightLinkIcon,
  CheckIcon,
  CrossIcon
} from 'common/components/Icons';
import { formatNumber } from 'forta-app/lib/utils';
import Button from 'common/components-v2/Button/Button';
import { trackEvent } from 'common/lib/analytics';
import { Routes } from 'common/routes';

export interface DataPlanCardProps {
  title: string;
  icon: React.ReactNode;
  author: string;
  description: string;
  customPlan?: boolean;
  priceUSDC: number;
  priceFORT?: number;
  link?: string;
  linkLabel?: string;
  unlockAddress: string;
  renderActions?: () => React.ReactNode;
  attributes?: {
    label: string;
    checked?: boolean;
  }[];
  className?: string;
}

function _DataPlanCard(props: DataPlanCardProps): JSX.Element {
  return (
    <div className={cn('DataPlanCard', props.className)}>
      <div className="DataPlanCard__content">
        {props.icon && <div className="DataPlanCard__icon">{props.icon}</div>}
        <div className="DataPlanCard__title">{props.title}</div>
        {props.author ? (
          <div className="DataPlanCard__author">
            by{' '}
            <span className="DataPlanCard__author-value">{props.author}</span>
          </div>
        ) : (
          <div className="DataPlanCard__author"></div>
        )}
        <div className="DataPlanCard__description">
          {props.description}{' '}
          {props.customPlan && (
            <div
              data-testid="data-card-extra-description"
              className="DataPlanCard__extra-description"
            >
              Do you need a specific bot and would love the community to build
              it?{' '}
              <a
                className="DataPlanCard__share-bot-ideas"
                href={Routes.forms.premiumFeeds}
                target="_blank"
                rel="noreferrer"
              >
                Share your monitoring ideas
              </a>
              , from Threat Detection to operational monitoring.
            </div>
          )}
        </div>

        <div className="DataPlanCard__attributes">
          {props.attributes?.map((attribute, index) => (
            <div
              className="DataPlanCard__attribute"
              key={`${props.title}-${index}-${attribute.label}`}
            >
              <span className={attribute.checked ? 'checked' : 'unchecked'}>
                {attribute.checked ? CheckIcon : CrossIcon}
              </span>
              {attribute.label}
            </div>
          )) || null}
        </div>
        <div className="DataPlanCard__footer">
          <div className="DataPlanCard__price">
            {props.priceUSDC ? (
              <>
                {formatNumber(props.priceUSDC)} USDC
                <span className="DataPlanCard__price-period"> /month</span>
              </>
            ) : props.priceFORT ? (
              <>
                {formatNumber(props.priceFORT)} FORT
                <span className="DataPlanCard__price-period"> /month</span>
              </>
            ) : null}
          </div>
          {typeof props.renderActions === 'function' ? (
            <div className="DataPlanCard__actions">{props.renderActions()}</div>
          ) : (
            <div className="DataPlanCard__cta">
              <Button
                size="sm"
                variant="light-outline"
                className="DataPlanCard__cta-button"
                endIcon={ArrowRightLinkIcon}
              >
                {props.linkLabel ? props.linkLabel : 'Learn more'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default function DataPlanCard(props: DataPlanCardProps): JSX.Element {
  if (props.link) {
    return (
      <NavLink
        to={props.link}
        onClick={() =>
          trackEvent(
            `data_plan_card_click_${slugify(props.title, { lower: true })}`,
            {}
          )
        }
      >
        <_DataPlanCard {...props} />
      </NavLink>
    );
  } else {
    return <_DataPlanCard {...props} />;
  }
}
