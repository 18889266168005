import React from 'react';
import cn from 'classnames';

import './FileItem.scss';

import { CrossIcon, DocumentIcon } from 'common/components/Icons';
import Button from 'common/components-v2/Button/Button';
import Input from 'common/components/Input';
import { OnChangeHandler } from '../../form';
import InfoPopover from 'common/components/InfoPopover';

type FileItemProps = {
  title: string;
  subtitle: string;
  icon: 'file' | 'ipfs';
  input?: {
    name: string;
    value: string;
    placeholder?: string;
    onChange: OnChangeHandler<string>;
  };
  inputVisible?: boolean;
  description?: string;
  error?: string;
  invalid?: boolean;
  onRemove?: () => void;
  className?: string;
};

function FileItem(props: FileItemProps): JSX.Element {
  const {
    title,
    subtitle,
    icon,
    input,
    inputVisible = !!input,
    description,
    invalid,
    onRemove,
    className
  } = props;

  return (
    <div
      className={cn('FileItem', className, {
        ['FileItem--invalid']: invalid
      })}
    >
      <div className="FileItem__source">
        <div className="FileItem__icon">
          {icon === 'file' ? DocumentIcon : DocumentIcon}
        </div>
        <div className="FileItem__file">
          <div className="FileItem__file-name">{title}</div>
          <div className="FileItem__file-subtitle">{subtitle}</div>
        </div>
      </div>
      {input && inputVisible && (
        <>
          <div className="FileItem__separator"></div>
          <div className="FileItem__name-input">
            <Input
              name={`${input.name}-name-input`}
              value={input.value}
              placeholder={input.placeholder || ''}
              onChange={(value) =>
                input?.onChange({ target: { name: input.name, value } })
              }
            />
          </div>
          {description && (
            <InfoPopover className="FileItem__tooltip" content={description} />
          )}
        </>
      )}
      <Button
        round
        variant="tertiary"
        size="md"
        type="button"
        startIcon={CrossIcon}
        onClick={onRemove}
        className="FileItem__remove-button"
      />
    </div>
  );
}

export default FileItem;
