import { ResponsivePie } from '@nivo/pie';

export interface PieDataItem {
  id: string;
  label: string;
  value: number;
  color: string;
}

interface PieParams {
  data: PieDataItem[];
  legendsOffset?: number;
}

export const Pie: (params: PieParams) => JSX.Element = (params) => {
  const { data } = params;
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
      colors={(item) => item.data.color}
      enableArcLabels={false}
      innerRadius={0.6}
      padAngle={0.7}
      cornerRadius={2}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      enableArcLinkLabels={false}
      arcLabel={(d) => `${d.value}`}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor="#FFFFFF"
      tooltip={(item) => (
        <div
          style={{
            color: 'black',
            backgroundColor: 'white',
            padding: 5,
            borderRadius: 3
          }}
        >
          {item.datum.label}
        </div>
      )}
      legends={[]}
    />
  );
};
