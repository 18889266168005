import React from 'react';
import cn from 'classnames';
import { useCombobox } from 'downshift';

import './Autocomplete.scss';
import Input, { InputProps, InputVariant } from '../Input/Input';

import Spinner from 'common/components-v2/Spinner/Spinner';
import { SearchIcon } from 'common/components/Icons';

type AutocompleteProps<T> = InputProps & {
  items: T[];
  loading?: boolean;
  empty?: boolean;
  waiting?: boolean;
  searchIcon?: JSX.Element;
  showSearchIndicator?: boolean;
  getItemValue: (item: T | null) => string;
  renderItem: (item: T) => React.ReactNode;
  onSelect?: (item: T | null | undefined) => unknown;
};

function Autocomplete<T>(props: AutocompleteProps<T>): JSX.Element {
  const {
    name,
    variant = InputVariant.Dark,
    items,
    value,
    loading,
    searchIcon,
    showSearchIndicator,
    renderItem,
    getItemValue,
    onSelect,
    onChange,
    className,
    ...restProps
  } = props;

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem
  } = useCombobox({
    inputValue: value,
    defaultHighlightedIndex: 0,
    defaultSelectedItem: null,
    items,
    itemToString: getItemValue,
    onSelectedItemChange: onSelect
      ? ({ selectedItem }) => onSelect(selectedItem)
      : undefined,
    onInputValueChange({ inputValue }) {
      onChange({
        target: {
          name: name,
          value: inputValue || ''
        }
      });
    }
  });

  return (
    <div className={cn('AutocompleteInput', className)}>
      <Input
        {...restProps}
        {...getInputProps()}
        icon={{
          left: showSearchIndicator ? (
            loading ? (
              <Spinner variant="dual-ring" size="sm" color="gray" />
            ) : (
              searchIcon || SearchIcon
            )
          ) : (
            restProps.icon?.left
          ),
          right: restProps.icon?.right
        }}
        name={name}
        variant={variant}
        className="AutocompleteInput__input"
      />
      <div
        className={cn('AutocompleteInput__suggestion-list', 'SuggestionList', {
          ['SuggestionList--loading']: loading,
          ['SuggestionList--visible']: isOpen && items.length > 0
        })}
      >
        <div className="SuggestionList__container">
          <ul {...getMenuProps()} className={cn('SuggestionList__list')}>
            {items.map((item, index) => (
              <li
                key={index}
                {...getItemProps({ item, index })}
                className={cn('SuggestionList__item', {
                  ['SuggestionList__item--highlighted']:
                    highlightedIndex === index,
                  ['SuggestionList__item--selected']: selectedItem === item
                })}
              >
                {renderItem(item)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Autocomplete;
