import { ContractTransaction, ethers } from 'ethers';
import BigNumberJs from 'bignumber.js';

import ContractInteractor from './contract-interactor';

import config from 'common/config';
import StakingVaultABI from 'forta-app/data/abis/StakingVault.json';
import { StakingVault as StakingVaultContract } from '../../data/abis/staking-vault';

export default class StakingVault extends ContractInteractor<StakingVaultContract> {
  constructor(props: {
    provider: ethers.providers.JsonRpcProvider;
    contractAddress?: string;
    signer?: ethers.providers.JsonRpcSigner;
  }) {
    const { contractAddress = config.stakingVault, provider, signer } = props;

    super(provider, StakingVaultABI, contractAddress, signer);
  }

  async totalAssets(): Promise<BigNumberJs> {
    const totalAssets = await this.contract.totalAssets();

    return new BigNumberJs(totalAssets.toString());
  }

  // returns shares (not assets)
  async balanceOf(address: string): Promise<BigNumberJs> {
    const shares = await this.contract.balanceOf(address);

    return new BigNumberJs(shares.toString());
  }

  // returns assets
  async assetsOf(address: string): Promise<BigNumberJs> {
    const shares = await this.contract.balanceOf(address);
    const assets = await this.contract.convertToAssets(shares);

    return new BigNumberJs(assets.toString());
  }

  async deposit(
    assets: string | ethers.BigNumber,
    receiver: string
  ): Promise<ContractTransaction> {
    return this.contract.deposit(assets, receiver);
  }

  // returns assets (FORT tokens)
  async previewRedeem(shares: BigNumberJs | string): Promise<BigNumberJs> {
    const assets = await this.contract.previewRedeem(shares.toString(10));

    return new BigNumberJs(assets.toString());
  }

  async redeem(
    shares: string,
    receiver: string,
    owner: string = receiver
  ): Promise<ContractTransaction> {
    return this.contract.redeem(shares, receiver, owner);
  }

  async getExpectedAssets(address: string): Promise<BigNumberJs> {
    try {
      const shares = await this.contract.getExpectedAssets(address);

      return new BigNumberJs(shares.toString());
    } catch {
      return new BigNumberJs(0);
    }
  }

  async claimRedeem(receiver: string): Promise<ContractTransaction> {
    return this.contract.claimRedeem(receiver);
  }

  async claimableAssets(receiver: string): Promise<BigNumberJs> {
    try {
      const iface = this.contract.interface;
      const data = iface.encodeFunctionData('claimRedeem', [receiver]);
      const claimableAssets = await this.provider.call({
        from: receiver,
        data: data,
        to: this.contract.address
      });

      console.debug(`Claimable assets: ${claimableAssets}`);

      if (claimableAssets == '0x') return new BigNumberJs(0);

      return new BigNumberJs(claimableAssets);
    } catch (e) {
      console.debug('Error when trying to query claimable assets', e);
      return new BigNumberJs(0);
    }
  }

  async convertToShares(assets: string | BigNumberJs): Promise<BigNumberJs> {
    const shares = await this.contract.convertToShares(assets.toString(10));

    return new BigNumberJs(shares.toString());
  }

  async convertToAssets(shares: string | BigNumberJs): Promise<BigNumberJs> {
    const assets = await this.contract.convertToAssets(shares.toString(10));

    return new BigNumberJs(assets.toString());
  }
}
