import React, { useContext } from 'react';
import cn from 'classnames';

import './TableSubHeading.scss';
import { TableContext } from './Table';

import { ArrowRight2Icon } from 'common/components/Icons';
import InfoPopover from 'common/components/InfoPopover';

interface TableSubHeadingProps {
  title?: string;
  name?: string;
  sortable?: boolean;
  flex?: number;
  width?: number;
  tooltip?: string;
  alignment?: 'left' | 'right';
  className?: string;
  tooltipTopPositioned?: boolean;
}

const TableSubHeading = (props: TableSubHeadingProps): JSX.Element => {
  const {
    title = '',
    tooltip,
    sortable = false,
    alignment = 'left',
    name = title.toLowerCase().replace(' ', ''),
    flex,
    width,
    className,
    tooltipTopPositioned
  } = props;

  const { sorting, onSortingChange } = useContext(TableContext);
  const isOrdered = sortable && sorting?.orderBy === name;
  const orderDirection = isOrdered ? sorting?.orderDirection || 'asc' : 'asc';

  const Element = sortable ? 'button' : 'div';

  function handleClick(): void {
    if (!onSortingChange || !sortable) return;

    if (isOrdered) {
      onSortingChange({
        orderBy: name,
        orderDirection: sorting.orderDirection === 'asc' ? 'desc' : 'asc'
      });
    } else {
      onSortingChange({
        orderBy: name,
        orderDirection: 'asc'
      });
    }
  }

  return (
    <Element
      data-name={name || undefined}
      style={{ flex, width }}
      className={cn('TableSubHeading', className, {
        ['TableSubHeading--sortable']: sortable,
        ['TableSubHeading--ordered']: isOrdered,
        [`TableSubHeading--${alignment}`]: alignment
      })}
      onClick={sortable ? handleClick : undefined}
    >
      {title}
      {tooltip && (
        <InfoPopover
          content={tooltip}
          className="TableSubHeading__tooltip"
          topPositioned={tooltipTopPositioned}
        />
      )}
      {sortable && (
        <span
          aria-hidden="true"
          className={cn('TableSubHeading__sort-icon', {
            ['TableSubHeading__sort-icon--desc']: orderDirection === 'desc'
          })}
        >
          {ArrowRight2Icon}
        </span>
      )}
    </Element>
  );
};

export default TableSubHeading;
