import { useWeb3React } from '@web3-react/core';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from 'forta-app/app/store';
import { getJWTAddress, isJWTExpired } from 'forta-app/slices/walletSlice';

export const useAppDispatch: () => AppDispatch = () =>
  useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useJWT = (): string => {
  const web3React = useWeb3React();
  const jwt = useAppSelector((state) => state.wallet.jwt);
  if (!jwt || getJWTAddress(jwt) !== web3React.account || isJWTExpired(jwt)) {
    return '';
  }
  return jwt;
};
