import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import notificationSlice from 'forta-app/slices/notificationSlice';
import walletSlice from 'forta-app/slices/walletSlice';

export const store = configureStore({
  reducer: {
    notification: notificationSlice,
    wallet: walletSlice
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
