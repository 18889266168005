import { useMemo } from 'react';

import { useWindowWidth } from './useWindowSize';

export type ViewPortState = {
  width: number;
  isXsDown: boolean;
  isSmDown: boolean;
  isMdDown: boolean;
  isLgDown: boolean;
  isXlDown: boolean;
};

function getState(windowWidth: number): ViewPortState {
  return {
    width: windowWidth,
    isXsDown: windowWidth <= 575,
    isSmDown: windowWidth <= 768,
    isMdDown: windowWidth <= 1050,
    isLgDown: windowWidth <= 1470,
    isXlDown: windowWidth <= 1920
  };
}

function useViewPort(): ViewPortState {
  const windowWidth = useWindowWidth();

  return useMemo(() => getState(windowWidth), [windowWidth]);
}

export default useViewPort;
