import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { BigNumber } from 'ethers';
import { useQueryParam } from 'use-query-params';

import DelegationsTable, {
  ScannerPoolDelegation
} from 'forta-app/components/delegations/DelegationsTable';
import useOffsetPagination from 'common/hooks/useOffsetPagination';
import { SortingParam } from 'common/lib/query-params';
import useAccountStakeQuery from 'common/hooks/useAccountStakeQuery';
import config from 'common/config';
import useScannerPoolsWithApyQuery from 'common/hooks/useScannerPoolsWithApyQuery';

// This is a draft version of a page that is designed to simplify the management of the FORT Staking Vault.
// This page is not intended for public use.

type VaultDashboardProps = {
  className?: string;
};

const TABLE_EL_ID = 'delegations-table';

function VaultDashboardPage(props: VaultDashboardProps): JSX.Element {
  const { className } = props;

  const [sortingParam] = useQueryParam('sorting', SortingParam);

  const sorting = sortingParam || {
    orderBy: 'apyForLastEpoch',
    orderDirection: 'desc'
  };

  const stakeQuery = useAccountStakeQuery({
    params: {
      address: config.stakingVault
    }
  });

  const { stake, fetched: isStakeFetched } = stakeQuery;

  // filter out empty pools
  const relevantScannerPoolSubjectIds = useMemo(
    () =>
      (
        stake?.delegations.filter(
          (d) =>
            !BigNumber.from(d.shares).isZero() ||
            !BigNumber.from(d.inactiveShares).isZero()
        ) || []
      ).map((v) => v.subject.subjectId),
    [stake]
  );

  const scannerPoolsPagination = useOffsetPagination(30, {
    scrollToElementId: TABLE_EL_ID
  });

  const apyQuery = useScannerPoolsWithApyQuery({
    params: {
      skip: scannerPoolsPagination.offset.skip,
      first: scannerPoolsPagination.offset.first,
      id_in: relevantScannerPoolSubjectIds,
      orderBy: sorting.orderBy,
      orderDirection: sorting.orderDirection
    },
    enabled: isStakeFetched && relevantScannerPoolSubjectIds.length > 0
  });

  const {
    refetching: isScannerPoolsRefetching,
    loading: isScannerPoolsFetching,
    hasNextPage: hasScannerPoolsNextPage
  } = apyQuery;

  // make sure we only display non-empty pools
  const scannerPools = useMemo(
    () =>
      apyQuery.scannerPools.filter((s) =>
        relevantScannerPoolSubjectIds.includes(s.id)
      ),
    [apyQuery.scannerPools, relevantScannerPoolSubjectIds]
  );

  const scannerPoolDelegations = useMemo(() => {
    if (!stake || !scannerPools.length) return [];

    const delegations: ScannerPoolDelegation[] = [];
    for (const pool of scannerPools) {
      const delegationStake = stake.delegations.find(
        (p) => p.subject.subjectId === pool.id
      );

      if (!delegationStake) {
        console.error(
          `Something went wrong, we cannot find stake data for the pool ID: ${pool.id}`,
          { pool, stake }
        );
        continue;
      }

      delegations.push({
        ...pool,
        stake: delegationStake
      });
    }

    return delegations;
  }, [scannerPools, stake]);

  useEffect(() => {
    if (!stakeQuery.loading && !stakeQuery.refetching && stakeQuery.data) {
      stakeQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn('VaultDashboard', className)}>
      <DelegationsTable
        id={TABLE_EL_ID}
        loading={isScannerPoolsRefetching || isScannerPoolsFetching}
        empty={scannerPoolDelegations.length === 0}
        scannerPools={scannerPoolDelegations}
        pagination={{
          ...scannerPoolsPagination,
          hasNextPage: hasScannerPoolsNextPage
        }}
        onDelegate={() => void 0}
        onUnDelegate={() => void 0}
        className="ProfileDelegations__section"
      />
    </div>
  );
}

export default VaultDashboardPage;
