import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import moment from 'moment/moment';

import './LabelTable.scss';

import Source from 'common/components-v2/Entities/Source/Source';
import TableBody from '../../components-v2/Table/TableBody';
import TableHeading from '../../components-v2/Table/TableHeading';
import TableSubHeading from '../../components-v2/Table/TableSubHeading';
import TableHead from '../../components-v2/Table/TableHead';
import Table from '../../components-v2/Table/Table';
import TableCell from '../../components-v2/Table/TableCell';
import TableSubCell from '../../components-v2/Table/TableSubCell';
import TableExpandableRow from 'common/components-v2/Table/TableExpandableRow';
import Button from '../../components-v2/Button/Button';
import EntityBadge from './EntityBadge/EntityBadge';
import Score, { ScoreColor } from 'common/components-v2/Entities/Score/Score';
import { LabelEntityType } from '../../enums';
import MetadataContainer from '../../components-v2/Metadata/Container';
import { Label } from '../../hooks/useLabelQuery';
import CopyButton from '../CopyButton';

type LabelTableProps = {
  labels: Label[];
  loading?: boolean;
  empty?: boolean;
  showConfidence?: boolean;
  className?: string;
};

function LabelTable(props: LabelTableProps): JSX.Element {
  const { labels, loading, empty, showConfidence, className } = props;

  const [expandedLabelIds, setExpandedLabelIds] = useState<string[]>([]);

  return (
    <div className={cn('LabelTable', className)}>
      <Table size="sm" minWidth={1200}>
        <TableHead>
          <TableHeading>
            <TableSubHeading title="Entity type" flex={3} />
            <TableSubHeading title="Label" flex={4} />
            <TableSubHeading title="Entity" flex={9} />
            <TableSubHeading title="Source" flex={3} />
            {showConfidence && <TableSubHeading title="Confidence" flex={3} />}
            <TableSubHeading title="Date" flex={2.5} />
            <TableSubHeading title="" flex={2} />
          </TableHeading>
        </TableHead>
        <TableBody loading={loading} empty={empty}>
          {labels.map((l) => {
            const isExpanded = expandedLabelIds.includes(l.id);

            return (
              <LabelRow
                key={l.id}
                label={l}
                expanded={isExpanded}
                showConfidence={showConfidence}
                onExpandClick={() =>
                  isExpanded
                    ? setExpandedLabelIds(
                        expandedLabelIds.filter((v) => v !== l.id)
                      )
                    : setExpandedLabelIds([...expandedLabelIds, l.id])
                }
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

type LabelRowProps = {
  label: Label;
  expanded?: boolean;
  showConfidence?: boolean;
  onExpandClick: () => void;
};

function LabelRow(props: LabelRowProps): JSX.Element {
  const { expanded, label, showConfidence, onExpandClick } = props;

  const confidenceColor = useMemo(() => {
    const confidence = label.label.confidence;

    let color: ScoreColor = 'grey';
    if (confidence >= 0.75) {
      color = 'green';
    } else if (confidence >= 0.5) {
      color = 'yellow';
    }

    return color;
  }, [label.label.confidence]);

  // TODO handle when labels have no chain id

  return (
    <TableExpandableRow
      expanded={expanded}
      content={
        <div className="LabelRow__details">
          <div className="LabelRow__metadata">
            <MetadataContainer
              value={label.label.metadata}
              className="LabelTable__metadata-container"
            />
          </div>
        </div>
      }
      size="md"
      className="LabelRow"
    >
      <TableCell>
        <TableSubCell
          value={
            <EntityBadge entity={label.label.entityType as LabelEntityType} />
          }
          flex={3}
        />
        <TableSubCell
          ellipsis
          title={label.label.label}
          value={
            <span className="LabelRow__label-title">{label.label.label}</span>
          }
          flex={4}
        />
        <TableSubCell
          ellipsis
          value={
            <CopyButton ellipsis text={label.label.entity}>
              {label.label.entity}
            </CopyButton>
          }
          flex={9}
        />
        <TableSubCell
          value={
            label.source.chainId ? (
              <Source chainId={label.source.chainId || 1} />
            ) : (
              'Not defined'
            )
          }
          flex={3}
        />
        {showConfidence && (
          <TableSubCell
            value={
              <Score
                value={label.label.confidence}
                digits={2}
                color={confidenceColor}
              />
            }
            flex={3}
          />
        )}
        <TableSubCell
          ellipsis
          title={label.createdAt}
          value={moment(label.createdAt).fromNow()}
          flex={2.5}
        />
        <TableSubCell
          alignment="right"
          value={
            <Button
              variant="default"
              size="sm"
              className="LabelRow__details-button"
              onClick={onExpandClick}
            >
              {expanded ? 'Collapse' : 'Details'}
            </Button>
          }
          flex={2}
        />
      </TableCell>
    </TableExpandableRow>
  );
}

export default LabelTable;
