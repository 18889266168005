import { useDebounce } from 'use-debounce';
import React, { useMemo, useState } from 'react';

import './BotAlerts.scss';

import Button from 'common/components-v2/Button/Button';
import Alerts from 'common/components-v2/Alerts/Alerts';
import Input from 'common/components-v2/Form/Input/Input';
import useAlertsQuery from 'forta-app/hooks/useAlertsQuery';
import { AlertsApiParams } from 'common/lib/apis/alertAPI';
import { DEFAULT_FILTER } from 'forta-app/components/tools/alert-combiner/AnalysisResult';
import { RefreshIcon, SearchIcon } from 'common/components/Icons';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from 'common/constants';

import { Routes } from 'common/routes';

// TODO Redirect to the new alerts page when the merge is ready

export default function BotAlerts({ botId }: { botId: string }): JSX.Element {
  const [searchInput, setSearchInput] = useState('');
  const [searchValue] = useDebounce(searchInput, DEFAULT_SEARCH_DEBOUNCE_TIME);

  const filterParams: AlertsApiParams = useMemo(() => {
    return {
      ...DEFAULT_FILTER,
      agents: [botId],
      text: searchValue.trim(),
      limit: 10
    };
  }, [botId, searchValue]);

  const { alerts, loading, refetching, refetch } = useAlertsQuery({
    params: filterParams
  });

  return (
    <div className="BotAlerts">
      <div className="BotAlerts__filters">
        <Input
          resettable
          name="label-search"
          variant="gray"
          value={searchInput}
          icon={{ left: SearchIcon }}
          placeholder="Search by Text"
          onChange={(e) => setSearchInput(e.target.value)}
          className="BotAlerts__search-input"
        />
        <Button
          variant="default"
          size="md"
          startIcon={RefreshIcon}
          disabled={refetching || loading}
          loadingPosition="start"
          loading={refetching || loading}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </div>
      <Alerts
        alerts={alerts}
        empty={alerts.length === 0 && !loading}
        loading={loading}
        currentSort={''}
        mutable={false}
      />
      <Button
        className="BotAlerts__see-all"
        variant="default"
        size="md"
        href={Routes.alerts.index({ agents: [botId] })}
      >
        See all alerts
      </Button>
    </div>
  );
}
