import classNames from 'classnames';
import Select, {
  components as SelectComponents,
  GroupTypeBase,
  InputActionMeta,
  KeyboardEventHandler,
  OptionsType,
  OptionTypeBase,
  StylesConfig,
  ValueContainerProps
} from 'react-select';

import './SelectFilter.scss';

interface FilterParams {
  testId?: string;
  onSelect: (selection: string[]) => void;
  placeholder: string;
  icon: JSX.Element;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  defaultValue: OptionTypeBase | GroupTypeBase<OptionTypeBase>;
  onKeyDown?: KeyboardEventHandler;
  errored?: boolean;
  subtext?: string;
  inputValue?: string;
  isSearchable?: boolean;
  options?: (OptionTypeBase | GroupTypeBase<OptionTypeBase>)[];
  className?: string;
  styles?:
    | StylesConfig<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>
    | undefined;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function SelectFilter({
  testId,
  onSelect,
  onInputChange,
  onKeyDown,
  inputValue,
  isSearchable,
  options,
  placeholder,
  defaultValue,
  styles,
  errored,
  subtext,
  icon,
  className
}: FilterParams): JSX.Element {
  const handleChange = (value: OptionsType<any>): void => {
    onSelect(value.map((v: OptionTypeBase) => v.value));
  };

  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<any, true, GroupTypeBase<any>>): JSX.Element =>
    SelectComponents.ValueContainer && (
      <SelectComponents.ValueContainer {...props}>
        <div className="ValueContainer__icon">{icon}</div>
        <div className="ValueContainer__children">{children}</div>
      </SelectComponents.ValueContainer>
    );
  /* eslint-enable @typescript-eslint/no-explicit-any */

  return (
    <div
      className={classNames(
        ['SelectFilter__container', errored ? 'errored' : ''],
        className
      )}
    >
      <Select
        testId={testId}
        className="SelectFilter react-select-container"
        classNamePrefix="react-select"
        options={options}
        styles={styles || {}}
        value={defaultValue}
        onChange={handleChange}
        isClearable
        inputValue={inputValue}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={{
          ValueContainer,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null
        }}
        isMulti
      />
      <div className="SelectFilter__subtext">{subtext}</div>
    </div>
  );
}
