import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import {
  ArrowRightIconFilled,
  ArrowRightLinkIcon,
  ArrowsPointingIn,
  BellAlert,
  BlueFortaLogo,
  BoltIcon,
  CheckCircle,
  CodeIconWhite,
  ExternalLinkIcon,
  GreenFortaLogo,
  LargeBackButton
} from 'common/components/Icons';

import './DataFeedsPage.scss';

import { useAppDispatch, useAppSelector, useJWT } from 'forta-app/app/hooks';
import {
  closeModal,
  NotificationModalState,
  retrieveNotifications,
  setModalState
} from 'forta-app/slices/notificationSlice';
import EndpointSelector, {
  getTelegramParamValues
} from 'forta-app/components/notifications/EndpointSelector';
import {
  addNotification,
  NotificationEndpointType
} from 'forta-app/lib/apis/notificationAPI';
import { toast } from 'react-toastify';
import { signMessage } from 'forta-app/components/wallet/WalletConnector';
import { loginAddress } from 'forta-app/lib/apis/loginAPI';
import { setLoginData } from 'forta-app/slices/walletSlice';
import { isAddress } from 'ethers/lib/utils';
import { currentENV } from 'common/config';
import TextArea from 'common/components-v2/Form/TextArea/TextArea';
import DataPlanList from 'forta-app/components/account/DataPlanList';
import Button from 'common/components-v2/Button/Button';
import { Routes } from '../../common/routes';
import DataPlanLine from 'forta-app/components/get-started/DataPlanLine';
import { getPlanEntities } from '../data/plans';
import { formatNumber } from '../lib/utils';

const TX_HASH_REGEX = /^0x([A-Fa-f0-9]{64})$/;
const IS_HTTP = (url: string): boolean =>
  url.indexOf('http://') == 0 || url.indexOf('https://') == 0;
const VALIDATE_EMAIL = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const VALIDATE_TELEGRAM_API = (url: string): boolean => {
  if (!IS_HTTP(url)) return false;
  const [token, chatId] = getTelegramParamValues(url);
  if (!token || !chatId) return false;
  return true;
};

const ENDPOINT_TYPES: { value: NotificationEndpointType; label: string }[] = [
  { value: 'SLACK', label: 'Slack' },
  { value: 'DISCORD', label: 'Discord' },
  { value: 'EMAIL', label: 'Email' },
  { value: 'TELEGRAM', label: 'Telegram' },
  { value: 'WEBHOOK', label: 'Webhook' }
];

enum State {
  Intro,
  SubscribeContracts,
  SubscribeWallets,
  Confirmation
}

type ErrorsType = { error: boolean; errors: Record<string, boolean> };

const getErrors = (modalState: NotificationModalState): ErrorsType => {
  const validations = new Map<
    'sources' | 'endpoint',
    // eslint-disable-next-line
    (value: any) => boolean
  >();
  validations.set('sources', (values: string[]): boolean => {
    const result = values.reduce((prev, value) => {
      if (!value) return prev;
      const cur =
        modalState.notificationType !== 'BOT'
          ? isAddress(value)
          : TX_HASH_REGEX.test(value);
      return prev && cur;
    }, true);
    return result;
  });
  validations.set('endpoint', (value: string): boolean => {
    if (modalState.endpointType === 'EMAIL') return VALIDATE_EMAIL(value);
    if (modalState.endpointType === 'TELEGRAM')
      return VALIDATE_TELEGRAM_API(value);
    return IS_HTTP(value);
  });

  const errors: ErrorsType = { error: false, errors: {} };

  validations.forEach((validation, key) => {
    if (!validation(modalState[key])) {
      errors.error = true;
      errors.errors[key] = true;
    }
  });

  return errors;
};

export default function DataFeedsPage(): JSX.Element {
  useEffect(() => {
    const bodyEl = document.querySelector('#FortaApp');

    if (bodyEl) {
      bodyEl.classList.add('overflow-x-hidden');

      return () => bodyEl.classList.remove('overflow-x-hidden');
    }
  }, []);

  return (
    <div className="GetStartedPage">
      <MonitorElem />
    </div>
  );
}

function MonitorElem(): JSX.Element {
  const [currentState, setCurrentState] = useState<State>(State.Intro);
  const [prevState, setPrevState] = useState<State>(State.Intro);

  const attackDetectorPlan = useMemo(
    () => getPlanEntities().AttackDetectorPlan,
    []
  );

  const introContent = (
    <>
      <div className="GetStartedPage__monitorBlock-title">
        <span className="GetStartedPage__monitorBlock-title--bold">Forta </span>
        <span>Threat Intelligence</span>
      </div>
      <div className="GetStartedPage__monitorBlock-subtitle">
        Discover and purchase data feeds created and maintained by a community
        of developers and security experts
      </div>
      {attackDetectorPlan && (
        <div className="GetStartedPage__big-plan-list">
          <div className="GetStartedPage__decoration-2" />
          <div className="GetStartedPage__decoration-3" />
          <div className="BigPlanCard">
            <div className="BigPlanCard__icon">{BoltIcon}</div>
            <div className="BigPlanCard__title">{attackDetectorPlan.name}</div>
            <div className="BigPlanCard__author">
              by{' '}
              <span className="BigPlanCard__author-value">
                {attackDetectorPlan.author}
              </span>
            </div>
            <div className="BigPlanCard__description">
              {attackDetectorPlan.description}
            </div>
            <div className="BigPlanCard__price">
              <span>
                {attackDetectorPlan.price.USDC ? (
                  <>{formatNumber(attackDetectorPlan.price.USDC)} USDC</>
                ) : (
                  <>{formatNumber(attackDetectorPlan.price.FORT)} FORT</>
                )}
              </span>{' '}
              <span className="BigPlanCard__price-period">/month</span>
            </div>
            <div className="BigPlanCard__actions">
              <Button
                to={attackDetectorPlan.link}
                size="sm"
                variant="light-outline"
                endIcon={ExternalLinkIcon}
                round
              >
                Explore product
              </Button>
              <Button
                to={Routes.bot.details({
                  id: attackDetectorPlan.botId || '#'
                })}
                size="sm"
                variant="light-outline"
                endIcon={ArrowRightLinkIcon}
                round
              >
                Visit bot&apos;s page
              </Button>
            </div>
          </div>
        </div>
      )}
      <h3 className="GetStartedPage__sub-title">Popular Data Feeds</h3>
      <div className="GetStartedPage__plan-list">
        <div className="GetStartedPage__decorations">
          <div className="GetStartedPage__line">
            <DataPlanLine />
          </div>
          <div className="GetStartedPage__gradient-1" />
          <div className="GetStartedPage__gradient-2" />
          <div className="GetStartedPage__gradient-3" />
        </div>
        <DataPlanList
          cardClassName="GetStartedPage__card"
          className="GetStartedPage__cards"
        />
        <div className="GetStartedPage__actions">
          <Button round variant="outline" size="md" to={Routes.bots.index({})}>
            Explore all Intel Feeds
          </Button>
        </div>
      </div>
    </>
  );

  const contractsContent = (
    <>
      <div className="GetStartedPage__monitorBlock">
        <div className="GetStartedPage__monitorBlock-backButton">
          <button onClick={() => setCurrentState(State.Intro)}>
            {LargeBackButton}
          </button>
        </div>
        <div className="GetStartedPage__monitorBlock-header">
          <h2 className="GetStartedPage__monitorBlock-h2">
            Enter Smart Contract Address
          </h2>
        </div>
        <GettingStartedCTA
          currentState={State.SubscribeContracts}
          setPrevState={setPrevState}
          setCurrentState={setCurrentState}
        />
      </div>
    </>
  );

  const walletsContent = (
    <>
      <div className="GetStartedPage__wallet-gradient"></div>
      <div className="GetStartedPage__monitorBlock">
        <div className="GetStartedPage__monitorBlock-header">
          <div className="GetStartedPage__monitorBlock-align">
            <div className="GetStartedPage__monitorBlock-headerWrapper-buttonW">
              <button
                className="GetStartedPage__monitorBlock-backButton"
                onClick={() => setCurrentState(State.Intro)}
              >
                {LargeBackButton}
              </button>
            </div>
            <h2 className="GetStartedPage__monitorBlock-h2">
              Enter Your Wallet Address
            </h2>
          </div>
        </div>
        <GettingStartedCTA
          currentState={State.SubscribeWallets}
          setPrevState={setPrevState}
          setCurrentState={setCurrentState}
        />
      </div>
    </>
  );

  const confirmationContent = (
    <>
      <div className="GetStartedPage__monitorBlock">
        <div className="GetStartedPage__monitorBlock-header">
          <div className="GetStartedPage__monitorBlock-backButton">
            <button onClick={() => setCurrentState(prevState)}>
              {LargeBackButton}
            </button>
          </div>
          <div className="GetStartedPage__monitorBlock-icon">
            {prevState === State.SubscribeContracts
              ? BlueFortaLogo
              : GreenFortaLogo}
          </div>
          <h2 className="GetStartedPage__monitorBlock-h3">
            You are now subscribed to receive{' '}
            {prevState === State.SubscribeContracts ? (
              <span className="SC">Forta alerts</span>
            ) : (
              <span className="wallet">Forta alerts</span>
            )}{' '}
            on your{' '}
            {prevState === State.SubscribeContracts
              ? 'smart contracts.'
              : 'wallet.'}
          </h2>
        </div>
        <div className="GetStartedPage__monitorBlock-finalCTA">
          <div className="GetStartedPage__monitorBlock-finalCTA-item">
            <p className="GetStartedPage__monitorBlock-finalCTA-item-text">
              Discover how top protocols are using Forta
            </p>
            <div className="GetStartedPage__monitorBlock-finalCTA-item-clickMe">
              <a
                href="https://app.forta.network/discover"
                target="_blank"
                rel="noreferrer"
              >
                <p>Discover</p>
                <div>{ArrowRightIconFilled}</div>
              </a>
            </div>
          </div>
          <div className="GetStartedPage__monitorBlock-finalCTA-item">
            <p className="GetStartedPage__monitorBlock-finalCTA-item-text">
              Are you a dev? Learn how to deploy custom bots!
            </p>
            <div className="GetStartedPage__monitorBlock-finalCTA-item-clickMe">
              <a
                href="https://docs.forta.network/"
                target="_blank"
                rel="noreferrer"
              >
                <p>Learn More</p>
                <div>{ArrowRightIconFilled}</div>
              </a>
            </div>
          </div>
          <div className="GetStartedPage__monitorBlock-finalCTA-item">
            <p className="GetStartedPage__monitorBlock-finalCTA-item-text">
              Any questions? Contact us at info@forta.org
            </p>
            <div className="GetStartedPage__monitorBlock-finalCTA-item-clickMe">
              <a href="mailto:info@forta.org" target="_blank" rel="noreferrer">
                <p>Reach Out</p>
                <div>{ArrowRightIconFilled}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const bottomComponent = (): JSX.Element => {
    return (
      <div className="FortaFeatures">
        <div className="FortaFeatures__title">Included with all feeds</div>
        <div className="FortaFeatures__list">
          <div className="FortaFeatures__box">
            <div className="FortaFeatures__box-title">
              <div className="FortaFeatures__box-title-icon">
                {CodeIconWhite}
              </div>
              API Integration
            </div>
            <div className="FortaFeatures__box-content">
              Unlimited calls to Forta’s GraphQL API
            </div>
          </div>
          {/*<div className="FortaFeatures__box-verticalLine"></div>*/}
          <div className="FortaFeatures__box">
            <div className="FortaFeatures__box-title">
              <div className="FortaFeatures__box-title-icon">{BellAlert}</div>
              Custom Notifications
            </div>
            <div className="FortaFeatures__box-content">
              Select the best notification channels and filters for your team
            </div>
          </div>
          {/*<div className="FortaFeatures__box-verticalLine"></div>*/}
          <div className="FortaFeatures__box">
            <div className="FortaFeatures__box-title">
              <div className="FortaFeatures__box-title-icon">
                {ArrowsPointingIn}
              </div>
              Multi-chain Support
            </div>
            <div className="FortaFeatures__box-content">
              The Forta Network supports all EVM compatible chains
            </div>
          </div>
          {/*<div className="FortaFeatures__box-verticalLine"></div>*/}
          <div className="FortaFeatures__box">
            <div className="FortaFeatures__box-title">
              <div className="FortaFeatures__box-title-icon">{CheckCircle}</div>
              Decentralize Execution
            </div>
            <div className="FortaFeatures__box-content FortaFeatures__box-content--long">
              Forta bots run on a decentralized network comprised of thousands
              of scan nodes
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="GetStartedPage__monitor">
      {currentState.valueOf() === State.Intro
        ? introContent
        : currentState.valueOf() === State.SubscribeContracts
        ? contractsContent
        : currentState.valueOf() === State.SubscribeWallets
        ? walletsContent
        : confirmationContent}
      {bottomComponent()}
    </div>
  );
}

type gettingStartedProps = {
  currentState: State;
  setPrevState: React.Dispatch<React.SetStateAction<State>>;
  setCurrentState: React.Dispatch<React.SetStateAction<State>>;
};

function GettingStartedCTA({
  currentState,
  setPrevState,
  setCurrentState
}: gettingStartedProps): JSX.Element {
  let jwt = useJWT();
  const web3React = useWeb3React();
  const modalState = useAppSelector((state) => state.notification.modal);
  const { id, sources, endpointType, endpoint, notificationType } = modalState;
  const dispatch = useAppDispatch();
  const onChange = useCallback(
    (name: string, value: string | string[]): void => {
      dispatch(setModalState({ [name]: value }));
    },
    [dispatch]
  );
  const errors = getErrors(modalState);
  const isUpdate = id !== -1;

  const onSubmit = async (
    event: React.FormEvent<HTMLElement>
  ): Promise<void> => {
    event.preventDefault();

    if (errors.error) {
      Object.keys(errors.errors).forEach((key) => {
        if (errors.errors[key]) {
          key === 'sources'
            ? toast.error(`Invalid address`, { hideProgressBar: true })
            : toast.error(`Invalid ${key}`, { hideProgressBar: true });
        }
      });
      return;
    }

    // is logged in?
    if (!jwt) {
      const result = await signMessage(web3React);
      if (result) {
        jwt = await loginAddress(result);
        dispatch(
          setLoginData({
            jwt
          })
        );
      }
    }

    if (jwt) {
      dispatch(setModalState({ loading: true }));
      try {
        const results = await Promise.all(
          sources.map(async (source) => {
            const groupID = [''];
            if (currentENV === 'production') {
              if (currentState === State.SubscribeContracts) {
                groupID[0] = 'global|113a1635-3dc4-11ed-b277-0a58a9feac02';
              } else {
                groupID[0] = 'global|c88bfc0c-3dc3-11ed-bd66-0a58a9feac02';
              }
            } else if (currentENV === 'staging') {
              if (currentState === State.SubscribeContracts) {
                groupID[0] = 'global|35a738ec-3dc4-11ed-82f2-0a58a9feac02';
              } else {
                groupID[0] = 'global|211d59bd-3dc4-11ed-82f2-0a58a9feac02';
              }
            } else {
              if (currentState === State.SubscribeContracts) {
                groupID[0] = 'global|35a738ec-3dc4-11ed-82f2-0a58a9feac02';
              } else {
                groupID[0] = 'global|211d59bd-3dc4-11ed-82f2-0a58a9feac02';
              }
            }

            return await addNotification({
              notificationSourceType: 'ADDRESS',
              notificationSourceValue: source,
              notificationEndpointType: endpointType,
              notificationEndpointValue: endpoint,
              groupIds: groupID,
              jwt
            });
          })
        );
        const result = results.reduce(
          (prev, cur) => (!cur.success ? cur : prev),
          { success: true, error: '' }
        );
        if (result.success) {
          toast.success(`Subscription added`);
          setPrevState(currentState);
          setCurrentState(State.Confirmation);
          dispatch(retrieveNotifications({ jwt }));
          dispatch(closeModal());
        } else {
          toast.error(result.error);
          dispatch(setModalState({ loading: false }));
        }
      } catch (err) {
        dispatch(setModalState({ loading: false }));
        console.error(err);
        toast.error(`An error occurred, check the console`, {
          hideProgressBar: true
        });
      }
    } else {
      toast.error(`Connection expired. Please, Sign in again.`, {
        hideProgressBar: true
      });
    }
  };

  const onPasteWalletClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    onChange(
      'sources',
      isUpdate
        ? [web3React.account || '']
        : sources.length && !sources[sources.length - 1]
        ? [...sources.slice(0, sources.length - 1), web3React.account || '']
        : [...sources, web3React.account || '']
    );
  };

  let prompt = '';
  if (currentState === State.SubscribeContracts) {
    prompt = 'Enter Smart Contract Address - One Address Per Line';
  } else if (currentState === State.SubscribeWallets) {
    prompt = 'Enter Wallet Address - One Address Per Line';
  }

  return (
    <form className="GettingStartedCTA__form" onSubmit={onSubmit}>
      {(function () {
        const walletInputNode = (
          <div className="GettingStartedCTA__form-address">
            <div className="GettingStartedCTA__form-source">
              <div className="GettingStartedCTA__form-source-value">
                <TextArea
                  placeholder={prompt}
                  name="forta-notification-source"
                  value={sources.join('\n')}
                  onChange={(e) =>
                    onChange('sources', e.target.value.split('\n'))
                  }
                  className="GettingStartedCTA__Input"
                  minRows={1}
                  maxRows={5}
                />
                {web3React.account &&
                !sources.includes(web3React.account) &&
                currentState === State.SubscribeWallets ? (
                  <button
                    className="GettingStartedCTA__fill-wallet-btn"
                    onClick={onPasteWalletClick}
                  >
                    Paste my wallet
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        );

        switch (notificationType) {
          case 'ADDRESS':
            return walletInputNode;
        }
      })()}
      {currentState === State.SubscribeContracts ? (
        <div className="GettingStartedCTA__form-SCEndpoint">
          <EndpointSelector
            value={endpoint}
            valueType={
              ENDPOINT_TYPES.find((item) => item.value === endpointType)
                ?.value || 'EMAIL'
            }
            onChange={(value) => onChange('endpoint', value)}
            onTypeChange={(option) => onChange('endpointType', option || '')}
            title="Where do you want to receive your alerts?"
          />
        </div>
      ) : (
        <div className="GettingStartedCTA__form-walletEndpoint">
          <EndpointSelector
            value={endpoint}
            valueType={
              ENDPOINT_TYPES.find((item) => item.value === endpointType)
                ?.value || 'EMAIL'
            }
            onChange={(value) => onChange('endpoint', value)}
            onTypeChange={(option) => onChange('endpointType', option || '')}
            title="Where do you want to receive your alerts?"
          />
        </div>
      )}
      {currentState === State.SubscribeContracts ? (
        <div className="GettingStartedCTA__submit-SCButton">
          <button type="submit">
            <p className="GettingStartedCTA__text">
              Start <strong>Monitoring</strong>
            </p>
          </button>
        </div>
      ) : (
        <div className="GettingStartedCTA__submit-walletButton">
          <button type="submit">
            <p className="GettingStartedCTA__text">
              Start <strong>Monitoring</strong>
            </p>
          </button>
        </div>
      )}
    </form>
  );
}
