import React, { useEffect, useState } from 'react';

import RewardsTable, {
  RewardedScannerPool
} from 'forta-app/components/rewards/RewardsTable';
import TabIntro from 'forta-app/components/TabIntro';
import useScannerPoolsQuery from 'common/hooks/useScannerPoolsQuery';
import { toast } from 'react-toastify';
import { getRewards } from 'forta-app/lib/apis/rewardsAPI';
import { AccountStakeQueryData } from 'common/hooks/useAccountStakeQuery';
import { BigNumber } from 'ethers';

type ProfileRewardsPageProps = {
  address: string;
  stakeQuery: AccountStakeQueryData;
  currentEpochNumber?: number;
};

interface Subject {
  id: string;
  subjectType: number;
  stakeAmount: BigNumber;
}

function ProfileRewardsPage(props: ProfileRewardsPageProps): JSX.Element {
  const { address, currentEpochNumber, stakeQuery } = props;
  const [isLoadingRewards, setIsLoadingRewards] = useState<boolean>(true);
  const [poolsWithRewards, setPoolsWithRewards] = useState<
    RewardedScannerPool[]
  >([]);

  const { stake } = stakeQuery;

  const nodeSubjects: { [key: string]: Subject } = {};

  stake?.delegations.forEach(
    (d) =>
      (nodeSubjects[d.subject.subjectId] = {
        id: d.subject.subjectId,
        subjectType: d.subject.subjectType,
        stakeAmount: d.amount
      })
  ) || [];
  stake?.pools.forEach(
    (d) =>
      (nodeSubjects[d.subject.subjectId] = {
        id: d.subject.subjectId,
        subjectType: d.subject.subjectType,
        stakeAmount: d.amount
      })
  ) || [];

  // load scanner pools as many as possible
  // Load pools owned by address + delegations
  const scannerPoolsQuery = useScannerPoolsQuery({
    params: {
      id_in: [...Object.keys(nodeSubjects)],
      first: Object.keys(nodeSubjects).length,
      skip: 0
    }
  });

  useEffect(() => {
    const loadRewards = async (): Promise<void> => {
      setIsLoadingRewards(true);
      if (!currentEpochNumber) return;

      try {
        const allRewards: RewardedScannerPool[] = [];

        for (const pool of scannerPoolsQuery.scannerPools) {
          const getOwnerRewards = {
            subjectType: 2,
            address,
            poolId: parseInt(pool.id),
            fromEpoch: currentEpochNumber - 52,
            toEpoch: currentEpochNumber - 1
          };
          const delegatedRewards = {
            subjectType: 3,
            address,
            poolId: parseInt(pool.id),
            fromEpoch: currentEpochNumber - 52,
            toEpoch: currentEpochNumber - 1
          };

          if (pool.owner.id.toLowerCase() === address.toLowerCase()) {
            const ownerRewards = await getRewards(getOwnerRewards);
            if (ownerRewards.length > 0) {
              allRewards.push({
                ...pool,
                epochs: [...ownerRewards],
                stake: nodeSubjects[pool.id].stakeAmount
              } as RewardedScannerPool);
            }
          } else {
            const delegateRewards = await getRewards(delegatedRewards);
            if (delegateRewards.length > 0) {
              allRewards.push({
                ...pool,
                epochs: [...delegateRewards],
                stake: nodeSubjects[pool.id].stakeAmount
              } as RewardedScannerPool);
            }
          }
        }

        setPoolsWithRewards(allRewards);
        setIsLoadingRewards(false);
      } catch (err) {
        toast.error('Error loading unclaimed rewards');
      }
    };
    loadRewards();
    //eslint-disable-next-line
  }, [scannerPoolsQuery.scannerPools.length]);

  const isLoading = isLoadingRewards;
  const isRefetching = scannerPoolsQuery.refetching;
  const isEmpty = scannerPoolsQuery.fetched && poolsWithRewards.length === 0;

  return (
    <div className="ProfileRewardsPage">
      <TabIntro>
        <TabIntro.Content>
          <TabIntro.Title>Staking rewards</TabIntro.Title>
          <TabIntro.Description>
            Check your unclaimed rewards from previous epochs for each of the
            scan pools where you participated and claim available rewards
          </TabIntro.Description>
        </TabIntro.Content>
      </TabIntro>
      <RewardsTable
        empty={isEmpty}
        loading={isLoading}
        refetching={isRefetching}
        scannerPools={poolsWithRewards || []}
        address={address}
      />
    </div>
  );
}

export default ProfileRewardsPage;
