import InfoPopover from 'common/components/InfoPopover';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import { formatNumber, getCurrentEpoch } from 'forta-app/lib/utils';

import './CommissionDisplay.scss';

export const formatCommission = (commission: number | string): string =>
  `${formatNumber(Number(commission) / 100, 4)}%`;

export default function CommissionDisplay({
  pool
}: {
  pool: ScannerPool;
}): JSX.Element {
  const isCommissionPending = pool.commissionSinceEpoch > getCurrentEpoch();
  let commissionInfo = null;
  if (isCommissionPending) {
    commissionInfo = (
      <InfoPopover
        content={`Current commission is ${formatCommission(
          pool.oldCommission
        )}. It will be updated to ${formatCommission(
          pool.commission
        )} in the next epoch.`}
      />
    );
  }
  return (
    <div className="CommissionDisplay">
      {formatCommission(pool.commission)}
      {commissionInfo}
    </div>
  );
}
