import { useMemo } from 'react';
import { BigNumber } from 'ethers';
import { gql } from '@apollo/client';

import { ApolloClientName } from '../components/ApolloClientProvider';

import useGraphQuery, { GraphQueryResult } from './useGraphQuery';
import useScannerPoolsQuery from './useScannerPoolsQuery';

import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import { Stake } from 'forta-app/lib/apis/subgraphAPI';

export const STAKE_QUERY = gql`
  query AccountStakeQuery($id: ID!) {
    account(id: $id) {
      id
      claimedRewardEvents {
        timestamp
        value
        subject {
          subjectType
        }
      }
      staker {
        stakes(where: { subject_: { subjectType_not: 0 } }) {
          shares
          inactiveShares
          subject {
            id
            subjectId
            subjectType
            activeShares
            activeStake
            inactiveShares
            inactiveStake
            slashedTotal
          }
          stakeDepositedEvents {
            timestamp
            amount
          }
          withdrawalExecutedEvents {
            timestamp
            amount
          }
        }
      }
    }
  }
`;

export const STAKE_QUERY_POLL_INTERVAL = 5000;

type StakeEvent = {
  timestamp: string;
  amount: string;
  subject: {
    subjectId: string;
    subjectType: SubjectType;
  };
};

type RewardClaimedEvent = {
  timestamp: string;
  value: string;
  subject: {
    subjectType: SubjectType;
  };
};

type Account = {
  claimedRewardEvents: RewardClaimedEvent[];
  staker: {
    nodePools: {
      id: string;
    }[];
    stakes: (Stake & {
      stakeDepositedEvents: StakeEvent[];
      withdrawalExecutedEvents: StakeEvent[];
    })[];
  };
};

export type StakeRecord = { amount: BigNumber; timestamp: number };
export type ClaimedRewardRecord = {
  amount: BigNumber;
  timestamp: number;
  subjectType: number;
};

export type TotalStake = Stake & {
  amount: BigNumber;
};

export type AccountStakeQueryResult = {
  totalStake: BigNumber;
  totalBotsStake: BigNumber;
  totalPoolsStake: BigNumber;
  totalDelegationsStake: BigNumber;
  pools: TotalStake[];
  delegations: TotalStake[];
  claimedRewards: ClaimedRewardRecord[];
  history: {
    total: StakeRecord[];
    bots: StakeRecord[];
    pools: StakeRecord[];
    delegations: StakeRecord[];
  };
};

type StakeQueryParams = {
  address: string;
};

type RequestVariables = {
  id: string;
};

type ResponseData = { account: Account };

export type AccountStakeQueryData = GraphQueryResult<
  RequestVariables,
  ResponseData
> & { stake?: AccountStakeQueryResult };

function useAccountStakeQuery(opts: {
  params: StakeQueryParams;
  enabled?: boolean;
  enablePolling?: boolean;
}): AccountStakeQueryData {
  const { enabled, enablePolling } = opts;
  const address = opts.params.address.toLowerCase();

  // TODO Handle large responses that needs to use pagination
  const query = useGraphQuery<RequestVariables, ResponseData>({
    enabled,
    query: STAKE_QUERY,
    variables: { id: address },
    enablePolling,
    pollInterval: STAKE_QUERY_POLL_INTERVAL,
    clientName: ApolloClientName.Subgraph
  });

  // TODO Remove this temporary fix for the subjectType field once it is fixed in the subgraph.
  const {
    scannerPools,
    loading: isScannerPoolsLoading,
    fetched: isScannerPoolsFetched
  } = useScannerPoolsQuery({
    params: { owner: address }
  });

  const stake = useMemo(() => {
    if (!query.data?.account?.staker || !isScannerPoolsFetched) return;

    const claimedRewards: ClaimedRewardRecord[] = query.data.account
      .claimedRewardEvents
      ? query.data.account.claimedRewardEvents.map((event) => {
          return {
            timestamp: Number(event.timestamp),
            amount: BigNumber.from(event.value),
            subjectType: event.subject.subjectType.valueOf()
          };
        })
      : [];

    const botRecords: StakeRecord[] = [];
    const delegationRecords: StakeRecord[] = [];
    const poolRecords: StakeRecord[] = [];
    const totalRecords: StakeRecord[] = [];

    const recordsBySubjectType: { [key: string]: StakeRecord[] } = {
      [SubjectType.BOT]: botRecords,
      [SubjectType.SCANNERPOOL]: poolRecords,
      [SubjectType.SCANNERPOOL_DELEGATOR]: delegationRecords
    };

    const totalStakeBySubjectId: {
      [subjectId: string]: TotalStake;
    } = {};

    const ownerPoolIds = scannerPools.map((s) => s.id.toLowerCase());

    for (const stake of query.data.account.staker.stakes) {
      let type = stake.subject.subjectType;

      if (
        !ownerPoolIds.includes(stake.subject.subjectId) &&
        type === SubjectType.SCANNERPOOL
      ) {
        type = SubjectType.SCANNERPOOL_DELEGATOR;
      }

      const records = recordsBySubjectType[type];

      if (!records) continue;

      const totalStake = totalStakeBySubjectId[stake.subject.subjectId] || {
        ...stake,
        subject: {
          ...stake.subject,
          subjectType: type
        },
        amount: BigNumber.from(0)
      };

      for (const depositEvent of stake.stakeDepositedEvents || []) {
        records.push({
          amount: BigNumber.from(depositEvent.amount || 0),
          timestamp: Number(depositEvent.timestamp)
        });
        totalStake.amount = totalStake.amount.add(depositEvent.amount);
      }
      for (const withdrawalEvent of stake.withdrawalExecutedEvents || []) {
        records.push({
          amount: BigNumber.from(withdrawalEvent.amount || 0).mul(-1),
          timestamp: Number(withdrawalEvent.timestamp)
        });
        totalStake.amount = totalStake.amount.sub(withdrawalEvent.amount || 0);
      }

      totalStakeBySubjectId[stake.subject.subjectId] = totalStake;
    }

    for (const records of Object.values(recordsBySubjectType)) {
      totalRecords.push(...records);
    }

    const sort = (r1: StakeRecord, r2: StakeRecord): number =>
      r1.timestamp - r2.timestamp;

    botRecords.sort(sort);
    delegationRecords.sort(sort);
    poolRecords.sort(sort);
    totalRecords.sort(sort);
    const sum = (arr: StakeRecord[]): BigNumber =>
      arr.reduce((acc, s) => acc.add(s.amount), BigNumber.from(0));

    return {
      totalStake: sum(totalRecords),
      totalBotsStake: sum(botRecords),
      totalPoolsStake: sum(poolRecords),
      totalDelegationsStake: sum(delegationRecords),
      pools: Object.values(totalStakeBySubjectId).filter(
        (v) => v.subject.subjectType === SubjectType.SCANNERPOOL
      ),
      delegations: Object.values(totalStakeBySubjectId).filter(
        (v) => v.subject.subjectType === SubjectType.SCANNERPOOL_DELEGATOR
      ),
      history: {
        bots: botRecords,
        pools: poolRecords,
        delegations: delegationRecords,
        total: totalRecords
      },
      claimedRewards: claimedRewards
    };
  }, [isScannerPoolsFetched, query.data, scannerPools]);

  return {
    ...query,
    fetched: isScannerPoolsFetched && query.fetched,
    loading: isScannerPoolsLoading || query.loading,
    stake
  };
}

export default useAccountStakeQuery;
