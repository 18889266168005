import { QueryParamConfig } from 'serialize-query-params/src/types';

import { TableSorting } from '../components-v2/Table/Table';

export const SortingParam: QueryParamConfig<TableSorting | undefined> = {
  encode(sorting): string {
    if (!sorting) return '';

    return `${sorting.orderDirection === 'asc' ? '' : '-'}${sorting.orderBy}`;
  },
  decode(value) {
    if (!value || value.length === 0) return undefined;

    const str = Array.isArray(value) ? value[0] || '' : value;

    const sorting: TableSorting = {
      orderDirection: str.includes('-') ? 'desc' : 'asc',
      orderBy: str.replace('-', '')
    };

    return sorting;
  }
};
