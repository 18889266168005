import { useEffect, useMemo } from 'react';
import { Web3Provider } from '@ethersproject/providers';

import useUnlockMembershipsQuery from './useUnlockMembershipsQuery';

import { Agent } from 'common/lib/apis/agentAPI';
import plans, { getUnlockConfig, paywall } from 'forta-app/data/plans';
import { useAppSelector } from 'forta-app/app/hooks';
import { DataPlan } from 'forta-app/data/plans/types';
import { Notification } from 'forta-app/lib/apis/notificationAPI';

function useBotSubscription(props: {
  params: {
    bot?: Agent;
    account?: string;
    provider?: Web3Provider;
    onSuccess?: (txHash: string) => unknown;
  };
}): {
  plan: undefined | DataPlan;
  isPaid: boolean;
  isSubscribed: boolean;
  subscription: undefined | Notification;
  loading: boolean;
  openCheckoutModal: () => void;
} {
  const { params } = props;
  const { bot, account, provider, onSuccess } = params;

  const { notifications, notificationsLoading } = useAppSelector(
    (state) => state.notification
  );

  const { activeMembershipAddresses, loading: isUnlockMembershipLoading } =
    useUnlockMembershipsQuery({
      params: {
        owner: account || ''
      }
    });

  const { plan, isPaid, isSubscribed, subscription } = useMemo(() => {
    if (!bot) {
      return {
        plan: undefined,
        isPaid: false,
        isSubscribed: false,
        subscription: undefined
      };
    }

    const plan = plans.find(
      (plan) => plan.subscription_type === bot.subscription_type
    );

    const hasPlan = plan
      ? activeMembershipAddresses.includes(
          plan.unlockAddresses.FORT.toLowerCase()
        ) ||
        activeMembershipAddresses.includes(
          plan.unlockAddresses.USDC.toLowerCase()
        ) ||
        activeMembershipAddresses.includes(
          plan.unlockAddresses.trial.toLowerCase()
        )
      : false;

    const subscription = notifications.find(
      (notification) =>
        notification.source.type === 'AGENT' &&
        notification.source.value === bot?.id
    );

    return {
      plan: plan,
      isPaid: hasPlan,
      isSubscribed: !!subscription,
      subscription: subscription
    };
  }, [activeMembershipAddresses, bot, notifications]);

  function buyPlan(): void {
    if (!plan || !account) return;
    if (!provider) {
      console.error('Provider is undefined');
      return;
    }

    const unlockConfig = getUnlockConfig([
      {
        ...plan,
        unlockAddresses: {
          USDC: plan.unlockAddresses['USDC'],
          FORT: plan.unlockAddresses['FORT'],
          trial: plan.unlockAddresses['trial']
        }
      }
    ]);

    paywall.connect(provider);
    paywall.loadCheckoutModal(unlockConfig);
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = function (e: any): void {
      // Transaction Hash of the unlock plan purchase
      const transactionHash: string = e.detail?.hash;
      if (transactionHash && onSuccess) {
        onSuccess(transactionHash);
      }
    };

    window.addEventListener('unlockProtocol.transactionSent', listener);
    return () =>
      window.removeEventListener('unlockProtocol.transactionSent', listener);
  }, [onSuccess]);

  const loading = notificationsLoading || isUnlockMembershipLoading;

  return {
    plan,
    isPaid,
    isSubscribed,
    subscription,
    loading,
    openCheckoutModal: buyPlan
  };
}

export default useBotSubscription;
