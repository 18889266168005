import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';

import './NotificationModal.scss';
import BotAutocomplete from './BotAutocomplete/BotAutocomplete';

import { isAddress } from 'common/lib/utils';
import { useAppDispatch, useAppSelector, useJWT } from 'forta-app/app/hooks';
import {
  addNotification,
  NotificationEndpointType,
  updateNotification
} from 'forta-app/lib/apis/notificationAPI';
import {
  closeModal,
  getInclusionFiltersFromState,
  NotificationModalFilterState,
  NotificationModalState,
  retrieveNotifications,
  setModalState
} from 'forta-app/slices/notificationSlice';
import { loginAddress } from 'forta-app/lib/apis/loginAPI';
import { setLoginData } from 'forta-app/slices/walletSlice';
import { signMessage } from 'forta-app/components/wallet/WalletConnector';
import EndpointSelector, { getTelegramParamValues } from './EndpointSelector';
import Button from 'common/components-v2/Button/Button';
import TextArea from 'common/components-v2/Form/TextArea/TextArea';
import ModalV2 from 'common/components-v2/Modal/ModalV2';
import FormField from 'common/components-v2/Form/Field/Field';
import FilterPanel from './Filters/Panel/FilterPanel';
import Link from 'common/components-v2/Button/Link';
import { Routes } from 'common/routes';

const TX_HASH_REGEX = /^0x([A-Fa-f0-9]{64})$/;
const IS_HTTP = (url: string): boolean =>
  url.indexOf('http://') == 0 || url.indexOf('https://') == 0;
const VALIDATE_EMAIL = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const VALIDATE_TELEGRAM_API = (url: string): boolean => {
  if (!IS_HTTP(url)) return false;
  const [token, chatId] = getTelegramParamValues(url);
  if (!token || !chatId) return false;
  return true;
};

const ENDPOINT_TYPES: { value: NotificationEndpointType; label: string }[] = [
  { value: 'SLACK', label: 'Slack' },
  { value: 'DISCORD', label: 'Discord' },
  { value: 'EMAIL', label: 'Email' },
  { value: 'TELEGRAM', label: 'Telegram' },
  { value: 'WEBHOOK', label: 'Webhook' }
];

type ErrorsType = { error: boolean; errors: Record<string, boolean> };

const getErrors = (modalState: NotificationModalState): ErrorsType => {
  const validations = new Map<
    'sources' | 'endpoint' | 'filter',
    // eslint-disable-next-line
    (value: any) => boolean
  >();
  validations.set('sources', (_values: string[]): boolean => {
    const values = _values.filter((_value) => _value);
    const result = values.reduce((prev, value) => {
      if (!value) return prev;
      const cur =
        modalState.notificationType !== 'BOT'
          ? isAddress(value)
          : TX_HASH_REGEX.test(value);
      return prev && cur;
    }, !!values.length);
    return result;
  });
  validations.set('endpoint', (value: string): boolean => {
    if (modalState.endpointType === 'EMAIL') return VALIDATE_EMAIL(value);
    if (modalState.endpointType === 'TELEGRAM')
      return VALIDATE_TELEGRAM_API(value);
    return IS_HTTP(value);
  });

  const errors: ErrorsType = { error: false, errors: {} };

  validations.forEach((validation, key) => {
    if (!validation(modalState[key])) {
      errors.error = true;
      errors.errors[key] = true;
    }
  });

  return errors;
};

export default function NotificationModal(): JSX.Element {
  let jwt = useJWT();
  const web3React = useWeb3React();
  const botGroups = useAppSelector((state) => state.notification.botGroups);
  const botGroupsLoading = useAppSelector(
    (state) => state.notification.botGroupsLoading
  );
  const modalState = useAppSelector((state) => state.notification.modal);
  const {
    id,
    opened,
    loading,
    sources,
    endpointType,
    endpoint,
    notificationType,
    filter,
    groupId
  } = modalState;
  const { botIds } = filter;

  const dispatch = useAppDispatch();
  const onChange = useCallback(
    (
      name: string,
      value: string | string[] | Partial<NotificationModalFilterState>
    ): void => {
      dispatch(setModalState({ [name]: value }));
    },
    [dispatch]
  );
  const errors = getErrors(modalState);
  const isUpdate = id !== -1;

  const handleSubmit = async (
    event: React.FormEvent<HTMLElement>
  ): Promise<void> => {
    event.preventDefault();

    if (errors.error) {
      Object.keys(errors.errors).forEach((_key) => {
        if (errors.errors[_key]) {
          const key =
            {
              sources:
                notificationType === 'KIT' || notificationType === 'ADDRESS'
                  ? 'Address'
                  : 'Bot ID',
              endpoint: endpointType.toLowerCase()
            }[_key] || _key;
          toast.error(`Invalid ${key}`, { hideProgressBar: true });
        }
      });
      return;
    }

    if (!jwt) {
      const result = await signMessage(web3React);
      if (result) {
        jwt = await loginAddress(result);
        dispatch(
          setLoginData({
            jwt
          })
        );
      }
    }

    if (jwt) {
      dispatch(setModalState({ loading: true }));
      try {
        const results = await Promise.all(
          sources.map(async (source) => {
            if (!isUpdate) {
              return await addNotification({
                notificationSourceType:
                  notificationType === 'BOT' ? 'AGENT' : 'ADDRESS',
                notificationSourceValue: source,
                notificationEndpointType: endpointType,
                notificationEndpointValue: endpoint,
                groupIds: notificationType === 'KIT' ? [groupId] : undefined,
                notificationFilter: getInclusionFiltersFromState(
                  filter,
                  notificationType,
                  sources
                ),
                jwt
              });
            } else {
              return await updateNotification({
                notificationId: id,
                notificationSourceType:
                  notificationType === 'BOT' ? 'AGENT' : 'ADDRESS',
                notificationSourceValue: source,
                notificationEndpointType: endpointType,
                notificationEndpointValue: endpoint,
                notificationFilter: getInclusionFiltersFromState(
                  filter,
                  notificationType,
                  sources
                ),
                groupIds: notificationType === 'KIT' ? [groupId] : undefined,
                jwt
              });
            }
          })
        );
        const result = results.reduce(
          (prev, cur) => (!cur.success ? cur : prev),
          { success: true, error: '' }
        );
        if (result.success) {
          toast.success(`Subscription ${isUpdate ? 'updated' : 'added'}`);
          dispatch(retrieveNotifications({ jwt }));
          dispatch(closeModal());
        } else {
          toast.error(result.error);
          dispatch(setModalState({ loading: false }));
        }
      } catch (err) {
        dispatch(setModalState({ loading: false }));
        console.error(err);
        toast.error(`An error occurred, check the console`, {
          hideProgressBar: true
        });
      }
    } else {
      toast.error(`Connection expired. Please, Sign in again.`, {
        hideProgressBar: true
      });
    }
  };

  useEffect(() => {
    if (botGroups.length) {
      onChange('groupId', botGroups[0].id);
    }
  }, [botGroups, onChange]);

  return (
    <ModalV2
      open={opened}
      title="Subscribe to Alerts"
      loading={loading || botGroupsLoading}
      onClose={() => dispatch(closeModal())}
      className="NotificationModal"
    >
      <form className="NotificationModal__form" onSubmit={handleSubmit}>
        <div className="NotificationModal__description">
          Subscribe to a Detection Bot to start monitoring for threats and
          anomalies.
        </div>
        <div className="NotificationModal__description"></div>
        <FormField
          label="Bot to watch"
          tooltip="Indicate the Bot ID you want to subscribe to"
        >
          <BotAutocomplete
            name="forta-notification-source"
            value={sources[0]}
            onChange={(event) => {
              onChange('sources', [event.target.value]);
            }}
          />
        </FormField>
        <div className="NotificationModal__description">
          Not sure what to subscribe to? Find all bots{' '}
          <Link to={Routes.bots.index({})} target="_blank">
            here.
          </Link>
        </div>
        <div>
          <h3 className="NotificationModal__subtitle">Alert Filters</h3>
          <div className="NotificationModal__description">
            Filter the alerts you receive to customize your experience. Skip it
            if you do not need to filter the alerts.
          </div>
          <FilterPanel
            source={sources[0]}
            filter={filter}
            onChange={(filter) => onChange('filter', filter)}
          />
        </div>
        <div className="NotificationModal__subscription-filter">
          <div className="NotificationModal__filter-form">
            {(function () {
              switch (notificationType) {
                case 'KIT':
                  return <></>;
                case 'ADDRESS':
                  return (
                    <>
                      <FormField
                        label="Bots to watch"
                        tooltip="One Bot ID per line. You'll be notified on alerts associated with these Bot IDs."
                      >
                        <TextArea
                          placeholder="Enter botIds (one per line)..."
                          name="forta-notification-source"
                          value={botIds?.join('\n')}
                          onChange={(event) =>
                            onChange('filter', {
                              ...filter,
                              botIds: event.target.value.split('\n')
                            })
                          }
                          className="NotificationModal__textarea"
                          minRows={1}
                          maxRows={5}
                        />
                      </FormField>
                    </>
                  );
                case 'BOT':
                  return (
                    <FormField
                      label={
                        isUpdate ? 'Address to watch' : 'Addresses to watch'
                      }
                      tooltip={
                        isUpdate
                          ? 'Indicate the address you want to be notified about.'
                          : "One address per line. You'll be notified on these addresses."
                      }
                    >
                      <TextArea
                        placeholder={
                          isUpdate
                            ? 'Enter Wallet / Contract address...'
                            : 'Enter Wallet / Contract addresses (one per line)...'
                        }
                        name="forta-notification-source"
                        value={filter.addresses?.join('\n')}
                        className="NotificationModal__textarea"
                        onChange={(event) => {
                          onChange('filter', {
                            ...filter,
                            addresses: event.target.value.split('\n')
                          });
                        }}
                        minRows={1}
                        maxRows={5}
                      />
                    </FormField>
                  );
              }
            })()}
          </div>
        </div>
        <div className="NotificationModal__endpoint">
          <EndpointSelector
            value={endpoint}
            valueType={
              ENDPOINT_TYPES.find((item) => item.value === endpointType)
                ?.value || 'EMAIL'
            }
            onChange={(value) => onChange('endpoint', value)}
            onTypeChange={(option) => onChange('endpointType', option || '')}
          />
        </div>
        <div className="NotificationModal__submit-section">
          <Button
            variant="primary"
            size="lg"
            className="NotificationModal__submit-button"
          >
            {id === -1 ? 'Subscribe' : 'Update'}
          </Button>
        </div>
      </form>
    </ModalV2>
  );
}
