import { gql } from '@apollo/client';

import useGraphQuery, { GraphQueryResult } from 'common/hooks/useGraphQuery';
import { ApolloClientName } from 'common/components/ApolloClientProvider';
import { MetricInterval } from 'forta-app/data/metrics';

export const BOT_STATS_QUERY = gql`
  query BotStatsQuery($input: AgentStatsInput!) {
    getAgentStats(input: $input) {
      alertCount
      labelCount
    }
  }
`;

type RequestVariables = {
  input: {
    timeframe: MetricInterval;
    agentId: string;
  };
};

type Stats = {
  alertCount: number;
  labelCount: number;
};

type ResponseData = {
  getAgentStats: Stats;
};

function useBotStats(opts: {
  botId?: string;
  timeframe?: MetricInterval;
  enabled?: boolean;
}): GraphQueryResult<RequestVariables, ResponseData> & {
  stats?: Stats;
} {
  const { botId, timeframe = MetricInterval.Day, enabled = !!botId } = opts;

  const query = useGraphQuery<RequestVariables, ResponseData>({
    query: BOT_STATS_QUERY,
    variables: {
      input: {
        timeframe: timeframe,
        agentId: botId || ''
      }
    },
    enabled: enabled,
    clientName: ApolloClientName.Forta
  });

  return {
    ...query,
    stats: query.data?.getAgentStats
  };
}

export default useBotStats;
