import { Web3Provider } from '@ethersproject/providers';

import config from 'common/config';
import rewardsDistributorABI from 'forta-app/data/abis/rewardsDistributorABI.json';
import { ContractContext as RewardsDistributorContract } from 'forta-app/data/abis/rewards-distributor-contract';
import ContractInteractor from './contractInteractor';
import { SubjectType } from './stakingContract';
import { ContractTransaction } from 'ethers';
import addProtocolTransaction from '../transactions-storage/addProtocolTransaction';
import { Web3ContextType } from '@web3-react/core';

export default class RewardsDistributor extends ContractInteractor<RewardsDistributorContract> {
  private provider: Web3Provider;
  constructor(web3React: Web3ContextType) {
    super(web3React, rewardsDistributorABI, config.rewardsDistributorAddress);
    this.provider = web3React.provider as Web3Provider;
  }

  public async getCurrentEpochNumber(): Promise<number> {
    return await this.contract.getCurrentEpochNumber();
  }

  public async canEditCommission(
    commissionSinceEpoch: number
  ): Promise<boolean> {
    const currentEpoch = await this.contract.getCurrentEpochNumber();
    const delegationParamsEpochDelay = (
      await this.contract.delegationParamsEpochDelay()
    ).toNumber();
    return commissionSinceEpoch + delegationParamsEpochDelay < currentEpoch;
  }

  public async setDelegationFeeBps(
    scannerPoolId: string,
    fee: string
  ): Promise<ContractTransaction> {
    const gas = await this.contract.estimateGas.setDelegationFeeBps(
      SubjectType.SCANNERPOOL,
      scannerPoolId,
      fee
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.setDelegationFeeBps(
      SubjectType.SCANNERPOOL,
      scannerPoolId,
      fee,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }

  public async claimRewards(
    subjectType: SubjectType,
    scannerPoolId: string,
    epochs: Array<number>
  ): Promise<ContractTransaction> {
    const tx = await this.contract.claimRewards(
      subjectType,
      scannerPoolId,
      epochs
    );
    addProtocolTransaction(tx);
    return tx;
  }
}
