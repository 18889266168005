function getCookieValues(name: string): string[] {
  const cookieList = document.cookie.split('; ');
  const values: string[] = [];

  cookieList.forEach((cookie) => {
    const [cookieName, cookieValue] = cookie.split('=');

    if (cookieName === name) {
      values.push(cookieValue);
    }
  });

  return values;
}

export default {
  getJWT(): string {
    const jwtCookies = getCookieValues('jwt');
    return jwtCookies.find((value: string) => value.length) || '';
  }
};
