import config from 'common/config';
import ContractInteractor from './contractInteractor';
import { ContractContext as ERC20Contract } from 'forta-app/data/abis/erc20-contract';
import ERC20ABI from 'forta-app/data/abis/ERC20ABI.json';

import { BigNumber, ethers } from 'ethers';
import { Web3ContextType } from '@web3-react/core';

export default class Erc20Contract extends ContractInteractor<ERC20Contract> {
  private tokenContract: ERC20Contract;

  constructor(
    web3React: Web3ContextType,
    contractAddress = config.FORTContractAddress
  ) {
    super(web3React, ERC20ABI, contractAddress);

    this.tokenContract = new ethers.Contract(
      contractAddress,
      ERC20ABI,
      this.web3React.provider?.getSigner(web3React.account || '')
    ) as unknown as ERC20Contract;
  }

  public async balanceOf(_owner: string): Promise<BigNumber> {
    return this.tokenContract.balanceOf(_owner);
  }

  public async allowance(_owner: string, _spender: string): Promise<BigNumber> {
    return this.tokenContract.allowance(_owner, _spender);
  }

  public async approve(
    _spender: string,
    _value: ethers.BigNumberish
  ): Promise<ethers.ContractTransaction> {
    return this.tokenContract.approve(_spender, _value);
  }
}
