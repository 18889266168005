import { useQuery } from '@tanstack/react-query';

import useProvider from './useProvider';
import { Network } from '../lib/networks';

type UseENSNameOptions = {
  enabled?: boolean;
};

type UseENSNameResult = {
  name: undefined | string | null;
  isRefetching: boolean;
  isFetching: boolean;
  isLoading: false | true;
  isFetched: boolean;
  refetch: () => Promise<unknown>;
  error: unknown;
};

function useENSName(
  address: string,
  opts?: UseENSNameOptions
): UseENSNameResult {
  const { enabled = true } = opts || {};

  const provider = useProvider(Network.Mainnet);

  const ensQuery = useQuery({
    queryKey: ['ens-lookup', address],
    queryFn: async () => {
      return await provider.lookupAddress(address);
    },
    enabled
  });

  return {
    ...ensQuery,
    name: ensQuery.data
  };
}

export default useENSName;
