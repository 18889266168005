import React, { useMemo } from 'react';
import cn from 'classnames';
import {
  Cell,
  Pie,
  PieChart as PieRechart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps
} from 'recharts';
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent';

import './Pie.scss';
import ChartTooltip from '../Tooltip/Tooltip';
import { TickFormatter } from '../utils';

type PieItem = {
  value: number;
  color: string;
  label: string;
};

type PieChartProps = {
  data: PieItem[];
  tooltipTitle?: TickFormatter;
  tooltipSubTitle?: TickFormatter;
  renderTooltip?: (props: TooltipProps<ValueType, NameType>) => JSX.Element;
  className?: string;
};

function PieChart(props: PieChartProps): JSX.Element {
  const { data, className } = props;

  // set up default tooltip if the render function is empty
  const renderTooltip: NonNullable<typeof props.renderTooltip> = useMemo(() => {
    const tooltipTitle = props.tooltipTitle;
    const tooltipSubTitle = props.tooltipSubTitle;

    return (
      props.renderTooltip ||
      ((props) => (
        <ChartTooltip
          title={tooltipTitle ? tooltipTitle(props.label) : props.label}
          subtitle={tooltipSubTitle ? tooltipSubTitle(props.label) : undefined}
          records={
            props.active
              ? [
                  {
                    label: props.payload?.[0].payload.label || 'Value',
                    value: props.payload?.[0].value?.toString(),
                    color: props.payload?.[0].color
                  }
                ]
              : undefined
          }
          recordsDirection="column"
        />
      ))
    );
  }, [props.renderTooltip, props.tooltipTitle, props.tooltipSubTitle]);

  return (
    <ResponsiveContainer>
      <PieRechart className={cn('Pie', className)}>
        <Pie
          isAnimationActive={true}
          dataKey="value"
          valueKey="tooltip"
          nameKey={'tooltip'}
          data={data}
          innerRadius={'68%'}
          outerRadius={'100%'}
          fill="grey"
          strokeWidth={0}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={(props) => renderTooltip(props)}
        />
      </PieRechart>
    </ResponsiveContainer>
  );
}

export default PieChart;
