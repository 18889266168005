import React, { useState } from 'react';
import { providers } from 'ethers';
import classNames from 'classnames';

import WithdrawModal from './WithdrawModal';
import './WithdrawButton.scss';

import Button from 'common/components-v2/Button/Button';
import { Stake } from 'forta-app/lib/apis/subgraphAPI';

export default function WithdrawButton({
  stake,
  onDone,
  className
}: {
  stake: Stake;
  onDone: (tx: providers.TransactionResponse) => void;
  className?: string;
}): JSX.Element {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <div className={classNames('WithdrawButton', className)}>
      <Button size="sm" variant="primary" onClick={() => setOpened(true)}>
        Withdraw
      </Button>
      <WithdrawModal
        opened={opened}
        stake={stake}
        onClose={async (tx?: providers.TransactionResponse): Promise<void> => {
          setOpened(false);
          if (tx) {
            onDone(tx);
          }
        }}
      />
    </div>
  );
}
