import React from 'react';

import { GlobalNodePoolsPage } from './GlobalNodePoolsPage';
import './DelegationPage.scss';

export const DelegationPage = (): JSX.Element => {
  return (
    <div className="DelegationPage">
      <GlobalNodePoolsPage />
    </div>
  );
};
