import React from 'react';
import { formatEther } from 'ethers/lib/utils';

import './BotStake.scss';
import useSubjectStake from '../useSubjectStake';

import {
  Group,
  Item,
  Label,
  Title,
  Value
} from 'common/components/InformationBox';
import { Agent } from 'common/lib/apis/agentAPI';
import { toFORTPrecision } from 'forta-app/lib/utils';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';

type BotStakeProps = {
  bot?: Agent;
};

function BotStake(props: BotStakeProps): JSX.Element {
  const { bot } = props;

  const { stakeSubject: subject } = useSubjectStake({
    params: { botId: bot?.id || '', subjectType: SubjectType.BOT },
    enabled: !!bot
  });

  return (
    <div className="BotStake">
      <div className="BotStake__container">
        <Title>STAKED • TOTAL</Title>
        <Value>
          {subject ? (
            <>
              {toFORTPrecision(formatEther(subject?.activeStake))}{' '}
              <small>FORT</small>
            </>
          ) : (
            '-'
          )}
        </Value>
        <Group>
          <Item>
            <Label>Active Stake</Label>
            <Value>
              {subject ? (
                <>
                  {toFORTPrecision(formatEther(subject?.activeStake))}{' '}
                  <small>FORT</small>
                </>
              ) : (
                '-'
              )}
            </Value>
          </Item>
          <Item>
            <Label>Inactive Stake</Label>
            <Value>
              {subject ? (
                <>
                  {toFORTPrecision(formatEther(subject?.inactiveStake))}{' '}
                  <small>FORT</small>
                </>
              ) : (
                '-'
              )}
            </Value>
          </Item>
          <Item>
            <Label>Slashed</Label>
            <Value>
              {subject ? (
                <>
                  {toFORTPrecision(formatEther(subject?.slashedTotal))}{' '}
                  <small>FORT</small>
                </>
              ) : (
                '-'
              )}
            </Value>
          </Item>
          <Item>
            <Label>Node Operators</Label>
            <Value>{bot?.scanners?.length ?? 0}</Value>
          </Item>
          <Item>
            <Label>Frozen Stake</Label>
            <Value>{subject?.isFrozen ? 'Yes' : 'No'}</Value>
          </Item>
        </Group>
      </div>
    </div>
  );
}

export default BotStake;
