import { gql } from '@apollo/client';

import useGraphQuery, { GraphQueryResult } from './useGraphQuery';
import { ApolloClientName } from '../components/ApolloClientProvider';

const GET_CHAINS_QUERY = gql`
  query BotChains($input: GetAgentMetricChainsInput) {
    getAgentMetricChains(input: $input) {
      chains
    }
  }
`;

export type UseBotChainsQueryParams = {
  botId: string;
  timeFrame?: 'hour' | 'day' | 'week' | 'month';
};

export type UseBotChainsQueryResult = {
  getAgentMetricChains: {
    chains: number[];
  };
};

type UseBotChainsInput = {
  input: {
    timeFrame: 'hour' | 'day' | 'week' | 'month';
    agentId: string;
  };
};

function useBotMetricChains(opts: {
  params: UseBotChainsQueryParams;
  enabled?: boolean;
  preservePreviousData?: boolean;
}): GraphQueryResult<UseBotChainsInput, UseBotChainsQueryResult> & {
  chainIds: number[];
} {
  const { params, preservePreviousData, enabled } = opts;

  const query = useGraphQuery<UseBotChainsInput, UseBotChainsQueryResult>({
    query: GET_CHAINS_QUERY,
    preservePreviousData: preservePreviousData,
    variables: {
      input: {
        timeFrame: params.timeFrame || 'month',
        agentId: params.botId
      }
    },
    enabled: enabled,
    clientName: ApolloClientName.Forta
  });

  return {
    ...query,
    chainIds: query.data?.getAgentMetricChains.chains || []
  };
}

export default useBotMetricChains;
