import { TransactionResponse } from '@ethersproject/providers';

import config from 'common/config';
import scannerPoolRegistryABI from 'forta-app/data/abis/scannerPoolRegistryABI.json';
import {
  RegisterScannerNodeRequest,
  ContractContext as ScannerPoolRegistryContract
} from 'forta-app/data/abis/scanner-pool-registry-contract';
import ContractInteractor from './contractInteractor';
import addProtocolTransaction from '../transactions-storage/addProtocolTransaction';
import { Web3ContextType } from '@web3-react/core';

export default class ScannerPoolRegistry extends ContractInteractor<ScannerPoolRegistryContract> {
  constructor(web3React: Web3ContextType) {
    super(web3React, scannerPoolRegistryABI, config.scannerPoolRegistryAddress);
  }

  async registerScannerPool(chainId: number): Promise<TransactionResponse> {
    const address = this.web3React?.account;
    if (!address) throw 'Address does not exist.';
    const gas = await this.contract.estimateGas.registerScannerPool(chainId);
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.registerScannerPool(chainId, txOptions);
    addProtocolTransaction(tx);
    return tx;
  }

  async registerScanNode(
    calldata: RegisterScannerNodeRequest,
    signature: string
  ): Promise<TransactionResponse> {
    const address = this.web3React?.account;
    if (!address) throw 'Address does not exist.';
    const gas = await this.contract.estimateGas.registerScannerNode(
      calldata,
      signature
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.registerScannerNode(
      calldata,
      signature,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }

  async enableScanNode(scanNodeId: string): Promise<TransactionResponse> {
    const address = this.web3React?.account;
    if (!address) throw 'Address does not exist.';
    const gas = await this.contract.estimateGas.enableScanner(scanNodeId);
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.enableScanner(scanNodeId, txOptions);
    addProtocolTransaction(tx);
    return tx;
  }

  async disableScanNode(scanNodeId: string): Promise<TransactionResponse> {
    const address = this.web3React?.account;
    if (!address) throw 'Address does not exist.';
    const gas = await this.contract.estimateGas.disableScanner(scanNodeId);
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.disableScanner(scanNodeId, txOptions);
    addProtocolTransaction(tx);
    return tx;
  }

  async willNewScannerShutdownPool(scanNodeId: string): Promise<boolean> {
    return this.contract.willNewScannerShutdownPool(scanNodeId);
  }
}
