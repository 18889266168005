import React, { useState } from 'react';
import chroma from 'chroma-js';
import cn from 'classnames';

import './BotEditForm.scss';
import FileUploader from './FileUploader';
import { validations } from './validations';

import CopyButton from 'common/components/CopyButton';
import InfoPopover from 'common/components/InfoPopover';
import Input from 'common/components/Input';
import SelectFilter from 'common/components/SelectFilter';
import ProjectSelector from './ProjectSelector';
import Button from 'common/components-v2/Button/Button';
import Tabs, { Tab } from 'common/components-v2/Tabs/Tabs';
import { V1_BOT_NETWORK_ENTITIES } from 'common/lib/networks';
import { BranchIcon } from 'common/components/Icons';
import { BotType } from 'common/enums';

// TODO Update form elements to "components v2" to make design consistent
// TODO Refactor with yap & formik

export type DocumentationItem = DocumentationIpfsItem | DocumentationFileItem;

export type DocumentationIpfsItem = {
  title: string;
  ipfsHash: string;
};

export type DocumentationFileItem = {
  title: string;
  file: File | null;
};

export const isDocumentationIpfsItem = (
  item: DocumentationItem
): item is DocumentationIpfsItem => !!(item as DocumentationIpfsItem).ipfsHash;
export const isDocumentationFileItem = (
  item: DocumentationItem
): item is DocumentationFileItem => !!(item as DocumentationFileItem).file;

export interface BotFormData {
  id: string;
  name: string;
  description: string;
  longDescription: string;
  promoUrl: string;
  licenseUrl: string;
  selectedNetworks: number[];
  networksToShard: string;
  projects: string[];
  version: string;
  repository: string;
  image: string;
  documentationItems: DocumentationItem[];
  botType: BotType;
}

export interface Manifest {
  agentId: string;
  from: string;
  name: string;
  description?: string;
  longDescription?: string;
  agentIdHash: string;
  projects: string[];
  version: string;
  timestamp: string;
  promoUrl?: string;
  licenseUrl?: string;
  imageReference: string;
  documentation: string;
  repository?: string;
  chainIds: number[];
  publishedFrom: string;
  external: boolean;
  protocolVersion: number;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const blockchainStyles = {
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? 'white'
        : isFocused
        ? '#27272a80'
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : isFocused
        ? 'white'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color)
      }
    };
  },
  multiValue: (styles: any, { data }: any) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color,
      cursor: 'pointer'
    };
  },
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    backgroundColor: data.color,
    borderRadius: '50px 0 0 50px',
    color: 'white',
    fontWeight: '600',
    paddingLeft: '10px'
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: data.color,
    borderRadius: '0 50px 50px 0',
    paddingRight: '8px',
    marginRight: '2px',
    ':hover': {
      color: '#ccc',
      cursor: 'pointer'
    }
  })
};

type ErrorContainer = Record<string, (string | undefined)[]>;

type BotEditFormProps = {
  formData: BotFormData;
  onChange: (formData: BotFormData) => void;
  onSubmit: () => void;
  className?: string;
};

export default function BotEditForm({
  formData,
  onChange,
  onSubmit,
  className
}: BotEditFormProps): JSX.Element {
  const [currentTab, setCurrentTab] = useState(formData.botType);

  const handleTabChange = (tab: BotType): void => {
    setCurrentTab(tab);
    onChange({ ...formData, botType: tab });
  };

  const [errors, setErrors] = useState<ErrorContainer>({});

  const handleSignClick = async (): Promise<void> => {
    const errors: Record<string, (string | undefined)[]> = {
      name: [await validations.validateName(formData.name)],
      description: [
        await validations.validateDescription(formData.description)
      ],
      longDescription: [
        await validations.validateLongDescription(formData.longDescription)
      ],
      licenseUrl: [await validations.validateLicenseUrl(formData.licenseUrl)],
      promoUrl: [await validations.validatePromoUrl(formData.promoUrl)],
      selectedNetworks: [
        currentTab === BotType.V1
          ? await validations.validateSelectedNetworks(
              formData.selectedNetworks
            )
          : undefined
      ],
      networksToShard: [
        currentTab === BotType.V2
          ? await validations.validateNetworksToShard(formData.networksToShard)
          : undefined
      ],
      version: [await validations.validateVersion(formData.version)],
      image: [
        currentTab === BotType.External
          ? undefined
          : await validations.validateImage(formData.image)
      ],
      repository: [await validations.validateRepository(formData.repository)],
      documentation: [
        await validations.validateDocumentation(formData.documentationItems)
      ],
      documentationItems: await Promise.all(
        formData.documentationItems.map(validations.validateDocumentationItem)
      )
    };

    setErrors(errors);

    if (!Object.values(errors).some((value) => value.some((v) => !!v))) {
      onSubmit();
    }
  };

  const handleClear = (): void => {
    onChange({
      id: formData.id,
      name: '',
      description: '',
      longDescription: '',
      licenseUrl: '',
      promoUrl: '',
      selectedNetworks: [],
      networksToShard: '',
      projects: [],
      version: '',
      repository: '',
      image: '',
      documentationItems: [],
      botType: currentTab
    });
  };

  return (
    <div className={cn('AgentForm', className)}>
      <Tabs<BotType>
        value={currentTab}
        onChange={handleTabChange}
        className="AgentForm__tabs"
      >
        <Tab label="V2 Bot" value={BotType.V2} testId="v2-bot-tab" />
        <Tab label="V1 Bot" value={BotType.V1} testId="v1-bot-tab" />
        <Tab
          label="External Bot"
          value={BotType.External}
          testId="external-bot-tab"
        />
      </Tabs>
      {currentTab === BotType.V1 && (
        <div
          className="AgentForm__description"
          data-testid="v1-bot-description"
        >
          The original Forta bot. These bots are reliant on receiving their
          blockchain data from nodes on the Forta Network. V1 bots are limited
          to the 7 EVM chains supported by community nodes. Please note that V1
          bot support is sunsetting in the near future. We encourage all
          developers to build V2 bots.
        </div>
      )}
      {currentTab === BotType.V2 && (
        <div
          className="AgentForm__description"
          data-testid="v2-bot-description"
        >
          The upgraded Forta bot. These bots do not receive blockchain data from
          the nodes running on the Forta Network. Running a V2 bot requires the
          developer to bring their own RPC endpoint. This ensures greater
          reliability, performance and flexibility. V2 bots are not limited to
          the 7 EVM chains, but can support any chain or data source.
        </div>
      )}
      {currentTab === BotType.External && (
        <div
          className="AgentForm__description"
          data-testid="external-bot-description"
        >
          External Bots allow you to plug in any external data source (e.g. a
          Twitter feed, gossip network or other security database) that run
          outside of the Forta Network (i.e. not on a scan node) but can still
          submit alerts. This can be helpful if you want to run a detection bot
          using your private IP while still contributing intel. External bots
          are still required to register on-chain in order to submit alerts.
        </div>
      )}
      <div className="AgentForm__field">
        <div className="AgentForm__label" data-testid="bot-id-label">
          ID (auto generated)
        </div>
        <div className="AgentForm__id">
          {formData.id} <CopyButton text={formData.id} />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">
          Name your detection bot <span className="asterisk">*</span>
        </div>
        <div className="AgentForm__input">
          <Input
            testId="bot-name-input"
            placeholder="Your bot's name"
            name="agent-deployment-name"
            value={formData.name}
            errored={!!errors?.name?.[0]}
            subtext={errors?.name?.[0]}
            onChange={(value) => onChange({ ...formData, name: value })}
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">Short description</div>
        <div className="AgentForm__input">
          <Input
            testId="bot-short-description-input"
            placeholder="Short description (up to 100 characters)"
            name="agent-deployment-short-description"
            value={formData.description}
            errored={!!errors?.description?.[0]}
            subtext={errors?.description?.[0]}
            onChange={(description) => onChange({ ...formData, description })}
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">Long description</div>
        <div className="AgentForm__input">
          <Input
            textarea
            testId="bot-long-description-input"
            placeholder="Longer description, key metrics, highlights & selling points (eg. precision, recall, protected value, etc.)"
            name="agent-deployment-long-description"
            value={formData.longDescription}
            errored={!!errors?.longDescription?.[0]}
            rows={5}
            subtext={errors?.longDescription?.[0]}
            onChange={(longDescription) =>
              onChange({ ...formData, longDescription })
            }
          />
        </div>
      </div>
      {currentTab === BotType.V1 && (
        <div className="AgentForm__field">
          <div className="AgentForm__label" data-testid="bot-chains-label">
            Covered blockchains <span className="asterisk">*</span>
          </div>
          <div className="AgentForm__input">
            <SelectFilter
              testId="bot-chains-input"
              options={V1_BOT_NETWORK_ENTITIES}
              placeholder="Select blockchains to watch"
              isSearchable={false}
              onSelect={(value) =>
                onChange({
                  ...formData,
                  selectedNetworks: value.map((item) => Number(item))
                })
              }
              defaultValue={formData.selectedNetworks
                .map((chainId) =>
                  V1_BOT_NETWORK_ENTITIES.find(
                    (_network) => chainId === _network.chainId
                  )
                )
                .filter((_) => _)}
              errored={!!errors?.selectedNetworks?.[0]}
              subtext={errors?.selectedNetworks?.[0]}
              icon={BranchIcon}
              styles={blockchainStyles}
            />
          </div>
        </div>
      )}
      {currentTab === BotType.V2 && (
        <div className="AgentForm__field">
          <div className="AgentForm__label" data-testid="bot-shards-label">
            Blockchains to shard{' '}
            <InfoPopover content="Fill this field to enable chain sharding such that each bot instance will scan only a single chain e.g. 1, 137, 8453" />
          </div>
          <Input
            testId="bot-shards-input"
            placeholder="Blockchains to shard (e.g. 1, 137, 8453)"
            name="agent-deployment-blockchain-to-shard"
            errored={!!errors?.networksToShard?.[0]}
            subtext={errors?.networksToShard?.[0]}
            value={formData.networksToShard}
            onChange={(networksToShard) =>
              onChange({
                ...formData,
                networksToShard
              })
            }
          />
        </div>
      )}
      <div className="AgentForm__field">
        <div className="AgentForm__label">
          Projects{' '}
          <InfoPopover content="Projects from ethereum-lists Github's repository" />
        </div>
        <div className="AgentForm__input">
          <ProjectSelector
            onChange={(projects) => onChange({ ...formData, projects })}
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">Promo link</div>
        <div className="AgentForm__input">
          <Input
            testId="bot-promo-url-input"
            placeholder="A doc or page showcasing how the bot works, performance, developer info, or more"
            name="agent-deployment-promo-url"
            value={formData.promoUrl}
            errored={!!errors?.promoUrl?.[0]}
            subtext={errors?.promoUrl?.[0]}
            onChange={(promoUrl) => onChange({ ...formData, promoUrl })}
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">License</div>
        <div className="AgentForm__input">
          <Input
            testId="bot-license-input"
            placeholder="Link to license text"
            name="agent-deployment-license"
            value={formData.licenseUrl}
            errored={!!errors?.licenseUrl?.[0]}
            subtext={errors?.licenseUrl?.[0]}
            onChange={(licenseUrl) => onChange({ ...formData, licenseUrl })}
          />
        </div>
      </div>
      <div className="AgentForm__field-group">
        <div className="AgentForm__field AgentForm__field-version">
          <div className="AgentForm__label">
            Version <span className="asterisk">*</span>
          </div>
          <div className="AgentForm__input">
            <Input
              testId="bot-version-input"
              placeholder="0.0.1"
              name="agent-deployment-version"
              value={formData.version}
              errored={!!errors?.version?.[0]}
              subtext={errors?.version?.[0]}
              onChange={(value) => onChange({ ...formData, version: value })}
            />
          </div>
        </div>
        <div className="AgentForm__field AgentForm__field-repository">
          <div className="AgentForm__label">Repository</div>
          <div className="AgentForm__input">
            <Input
              testId="bot-repository-input"
              placeholder="GitHub or GitLab repository URL..."
              name="agent-deployment-repository"
              value={formData.repository}
              errored={!!errors?.repository?.[0]}
              subtext={errors?.repository?.[0]}
              onChange={(value) => onChange({ ...formData, repository: value })}
            />
          </div>
        </div>
      </div>
      {currentTab !== BotType.External && (
        <div className="AgentForm__field">
          <div className="AgentForm__label">
            Docker Image <span className="asterisk">*</span>{' '}
          </div>
          <div className="AgentForm__input">
            <Input
              testId="bot-image-input"
              placeholder="Add an image hash"
              name="agent-deployment-image"
              value={formData.image}
              errored={!!errors?.image?.[0]}
              subtext={errors?.image?.[0]}
              onChange={(value) => onChange({ ...formData, image: value })}
            />
          </div>
        </div>
      )}
      <div className="AgentForm__field">
        <div className="AgentForm__label">
          Documentation <span className="asterisk">*</span>
        </div>
        <div className="AgentForm__file">
          <FileUploader
            error={errors?.documentation?.[0]}
            itemErrors={errors?.documentationItems}
            items={formData.documentationItems}
            onItemsChange={(items) => {
              onChange({ ...formData, documentationItems: items });
            }}
          />
        </div>
      </div>
      <div className="AgentForm__actions">
        <Button
          variant="tertiary"
          size="sm"
          className="AgentForm__cancel-button"
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          testId="bot-edit-form-sign-button"
          variant="primary"
          size="sm"
          className="AgentForm__submit-button"
          onClick={handleSignClick}
        >
          Sign to proceed
        </Button>
      </div>
    </div>
  );
}
