import dayjs, { OpUnitType } from 'dayjs';
import BigNumber from 'bignumber.js';
import { BigNumber as EthersBigNumber } from 'ethers';
import { useMemo } from 'react';
import { scaleTime } from 'd3-scale';
import {
  CountableTimeInterval,
  timeDay,
  timeHour,
  timeMinute,
  timeMonth
} from 'd3-time';

import { calcDomains, ChartInterval } from './utils';

type TimeBounds = {
  startDate?: number;
  endDate?: number;
};

type InputRecord<
  TXAccessor extends string,
  TYAccessor extends string,
  TYValue = EthersBigNumber | BigNumber
> = {
  [yAccessor in TYAccessor]: TYValue;
} & {
  [xAccessor in TXAccessor]: number;
};

type TimeSeriesDataOptions<
  TXAccessor extends string,
  TYAccessor extends string
> = {
  xAccessor: TXAccessor;
  yAccessor: TYAccessor | TYAccessor[];
  cumulativeSum?: boolean;
  timeframe?: OpUnitType | ChartInterval;
  bounds?: TimeBounds | ((domain: [number, number]) => TimeBounds);
  filter?: (r: InputRecord<TXAccessor, TYAccessor>) => boolean;
  prepare?: (
    record: InputRecord<TXAccessor, TYAccessor, BigNumber>
  ) => Record<TXAccessor | TYAccessor, number>;
  deps: Array<unknown>;
};

function useTimeSeries<TXAccessor extends string, TYAccessor extends string>(
  data: InputRecord<TXAccessor, TYAccessor>[] = [],
  opts: TimeSeriesDataOptions<TXAccessor, TYAccessor>
): Record<TXAccessor | TYAccessor, number>[] {
  const { filter, cumulativeSum = false, timeframe = 'day', deps = [] } = opts;

  const xAccessor = opts.xAccessor;
  const yAccessors: TYAccessor[] = Array.isArray(opts.yAccessor)
    ? opts.yAccessor
    : [opts.yAccessor];

  const prepare =
    opts.prepare ||
    ((record) => {
      const result: Record<string, number> = { ...record };
      for (const accessor of yAccessors)
        result[accessor] = record[accessor].toNumber();
      return result;
    });

  return useMemo(() => {
    const { xDomain } = calcDomains(data, [xAccessor], []);

    const optsBounds: TimeBounds =
      typeof opts.bounds === 'function'
        ? opts.bounds(xDomain)
        : opts.bounds || {};

    const safeBounds: Required<TimeBounds> = {
      startDate: optsBounds.startDate ?? xDomain[0],
      endDate: optsBounds.endDate ?? xDomain[1]
    };

    const unifiedTimeframe =
      typeof timeframe === 'string' ? { unit: timeframe, value: 1 } : timeframe;

    const map = {
      minute: timeMinute,
      hour: timeHour,
      day: timeDay,
      month: timeMonth
    } as Record<OpUnitType, CountableTimeInterval>;
    const timeInterval = map[unifiedTimeframe.unit] || timeHour;

    const scale = scaleTime()
      // normalize to JS Date
      .domain([safeBounds.startDate * 1000, safeBounds.endDate * 1000])
      // extend the domain to the nearest interval points
      .nice(timeInterval);
    const xTimestamps = scale
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .ticks(timeInterval.every(unifiedTimeframe.value)!)
      // normalize to unix format
      .map((v) => v.valueOf() / 1000)
      // nice() extends the domain to the nearest interval point, so that sometimes the last tick is outside the bounds
      .filter((v) => v <= safeBounds.endDate);

    let records: InputRecord<TXAccessor, TYAccessor, BigNumber>[] = [];
    const sumsByYAccessor: { [accessor: string]: BigNumber } = {};
    for (let t = 0, d = 0; t < xTimestamps.length; t++) {
      const currTimestamp = xTimestamps[t];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const record: any = {
        [xAccessor]: currTimestamp
      };

      for (const yAccessor of yAccessors) {
        record[yAccessor] =
          (cumulativeSum && sumsByYAccessor[yAccessor]) || new BigNumber(0);
      }

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const point = data[d];

        const nextTimestamp =
          timeInterval.ceil(new Date((currTimestamp + 1) * 1000)).valueOf() /
          1000;

        if (point && point[xAccessor] < nextTimestamp) {
          for (const yAccessor of yAccessors) {
            record[yAccessor] = record[yAccessor].plus(
              (point[yAccessor] || new BigNumber(0)).toString(10)
            );
            sumsByYAccessor[yAccessor] = record[yAccessor];
          }
          d++;
        } else {
          break;
        }
      }

      records.push(record);
    }

    if (filter) {
      records = records.filter(filter);
    }

    return records.map(prepare);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, data]);
}

export function boundsFromNow(opts: {
  interval?: ChartInterval | null;
  minInterval?: ChartInterval;
  timeframe?: OpUnitType | { value: number; unit: OpUnitType };
}): (domain: [number, number]) => { endDate: number; startDate: number } {
  const {
    interval,
    minInterval = { value: 1, unit: 'week' },
    timeframe = 'day'
  } = opts;

  return (domain: [number, number]) => {
    // When we subtract the interval from the current date,
    // we get the startDate one frame earlier than the desired interval
    const normalizer =
      typeof timeframe === 'string' ? { value: 1, unit: timeframe } : timeframe;

    let startDate = dayjs()
      .subtract(minInterval.value, minInterval.unit)
      .add(normalizer.value, normalizer.unit)
      .unix();

    if (interval == null) {
      if (domain[0] !== 0) {
        startDate = Math.min(domain[0], startDate);
      }
    } else {
      startDate = dayjs()
        .subtract(interval.value, interval.unit)
        .add(normalizer.value, normalizer.unit)
        .unix();
    }

    return {
      startDate: startDate,
      endDate: dayjs().unix()
    };
  };
}

export default useTimeSeries;
