import { FormElementProps, OnChangeHandler } from '../form';
import Input from './Input';

type NumberInputProps = FormElementProps<string> & {
  type?: 'percentage' | 'fort' | 'any';
};

const NumberInput = (props: NumberInputProps): JSX.Element => {
  const handleInputChange: OnChangeHandler<string> = (event) => {
    const _value = event.target.value;
    if (isNaN(Number(_value)) || Number(_value) < 0 || _value.includes(' '))
      return;
    props.onChange &&
      props.onChange({
        target: {
          name: props.name,
          value: _value
        }
      });
  };

  let icon:
    | {
        left?: JSX.Element | undefined;
        right?: JSX.Element | undefined;
      }
    | undefined = undefined;

  switch (props.type) {
    case 'percentage':
      icon = { right: <span>%</span> };
      break;
    case 'fort':
      icon = { right: <span>FORT</span> };
      break;
  }

  return (
    <Input
      variant="dark-big"
      name={props.name}
      value={props.value}
      className="NumberInput"
      type="text"
      placeholder="0.0"
      icon={icon}
      pattern="^[0-9]*[.]?[0-9]*$"
      onChange={handleInputChange}
    />
  );
};

export default NumberInput;
