import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import './DateFilter.scss';

import { CalendarIcon } from 'common/components/Icons';
import Period from 'common/components-v2/Form/Period/Period';
import { APP_DATE_FORMAT, DEFAULT_DATE_FORMAT } from 'common/constants';

export type DateRange = (number | undefined)[];

interface DateFilterProps {
  dateRange: DateRange;
  onChange: (date: DateRange) => void;
}

export default function DateFilter({
  onChange,
  dateRange
}: DateFilterProps): JSX.Element {
  const handleChange = (
    value: [string | undefined, string | undefined]
  ): void => {
    const range = value.map((d) =>
      d ? dayjs(d, APP_DATE_FORMAT).valueOf() : d
    ) as DateRange;

    if (range[1]) {
      range[1] = dayjs(range[1]).add(1, 'day').subtract(1, 'ms').valueOf();
    }

    onChange(range);
  };

  const periodFormattedValue = useMemo(() => {
    const [startDate, endDate] = dateRange;

    const formatDate = (v: number | undefined): string =>
      dayjs(v).format(APP_DATE_FORMAT);

    if (startDate && !endDate) return formatDate(startDate) + ' - now';
    if (!startDate && endDate) return 'To ' + formatDate(endDate);
    if (startDate && endDate && startDate === endDate)
      return formatDate(endDate);
    if (startDate && endDate)
      return formatDate(startDate) + ' - ' + formatDate(endDate);

    return 'Latest';
  }, [dateRange]);

  return (
    <Period
      value={[
        dateRange[0]
          ? dayjs(dateRange[0]).format(DEFAULT_DATE_FORMAT)
          : undefined,
        dateRange[1]
          ? dayjs(dateRange[1]).format(DEFAULT_DATE_FORMAT)
          : undefined
      ]}
      name="period"
      maxDate={new Date()}
      renderElement={({ ref, toggle }) => (
        <button
          ref={ref as React.RefObject<HTMLButtonElement>}
          onClick={toggle}
          className="DateFilter"
        >
          {CalendarIcon}
          <span className="DateFilter__value">{periodFormattedValue}</span>
        </button>
      )}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
}
