import { initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';

import { Connection, ConnectionType, onConnectionError } from './connections';
import config from 'common/config';

export function buildWalletConnectConnector(): Connection {
  const [walletConnectV2, walletConnectV2Hooks] =
    initializeConnector<WalletConnectV2>(
      (actions) =>
        new WalletConnectV2({
          actions,
          options: {
            projectId: config.walletConnectProjectId,
            chains: [config.chainId],
            showQrModal: true
          },
          onError: onConnectionError
        })
    );
  const walletConnectConnection: Connection = {
    connector: walletConnectV2,
    hooks: walletConnectV2Hooks,
    type: ConnectionType.WALLET_CONNECT
  };
  return walletConnectConnection;
}
