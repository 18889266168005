/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useCallback, useLayoutEffect, useState } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import cn from 'classnames';
import Flatpickr from 'flatpickr';
import ReactFlatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/dark.css';

import './Date.scss';
import { FormElementProps } from '../form';

import Button from 'common/components-v2/Button/Button';
import Input, {
  InputComponentRenderProps
} from 'common/components-v2/Form/Input/Input';
import { CalendarIcon, CrossIcon } from 'common/components/Icons';
import { APP_DATE_FORMAT } from 'common/constants';

dayjs.extend(advancedFormat);

export enum DatePickerMode {
  Single = 'single',
  Range = 'range',
  Multiple = 'multiple'
}

type SinglePickerProps = FormElementProps<string | null> & {
  mode: DatePickerMode.Single;
  onFormatValue?: (value: Date) => string;
};

type MultiPickerProps = FormElementProps<string[]> & {
  mode: DatePickerMode.Range | DatePickerMode.Multiple;
  onFormatValue?: (value: Date[]) => string;
};

type DateInputProps = (SinglePickerProps | MultiPickerProps) & {
  placeholder?: string;
  resettable?: boolean;
  showMonths?: number;
  dateFormat?: string;
  minDate?: string | Date;
  maxDate?: string | Date;
  inline?: boolean;
  className?: string;
};

// type guard
function isSingleMode(props: DateInputProps): props is SinglePickerProps {
  return props.mode === DatePickerMode.Single;
}

function formatToAppDate(val: Date): string {
  if (!val) return '';
  return dayjs(val).format(APP_DATE_FORMAT);
}

export const FORMATTER_BY_MODE = {
  [DatePickerMode.Single]: (value: Date): string => formatToAppDate(value),
  [DatePickerMode.Range]: (value: Date[]): string => {
    const formattedDates = value.map(formatToAppDate);
    if (formattedDates.length > 0 && formattedDates[0] === formattedDates[1])
      return formattedDates[0];
    return formattedDates.join(' - ');
  },
  [DatePickerMode.Multiple]: (value: Date[]): string =>
    value.map(formatToAppDate).join(', ')
};

function DateInput(props: DateInputProps): JSX.Element {
  const {
    name,
    mode,
    value,
    minDate,
    maxDate,
    dateFormat = 'YYYY-MM-DD',
    inline = false,
    showMonths = 1,
    resettable = true,
    placeholder,
    className,
    onFormatValue = FORMATTER_BY_MODE[mode]
  } = props;
  const components: {
    left: InputComponentRenderProps[];
    right: InputComponentRenderProps[];
  } = { left: [], right: [] };

  const [flatpickr, setFlatpickr] = useState<Flatpickr.Instance | null>(null);

  const formatValueToDate = useCallback(
    function formatValueToDate(
      val: string | string[] | null
    ): Date | Date[] | undefined {
      if (Array.isArray(val)) {
        return val.map((v) => dayjs(v, dateFormat).toDate());
      }
      return val ? dayjs(val, dateFormat).toDate() : undefined;
    },
    [dateFormat]
  );

  function handleChange(value: Date[]): void {
    // type guard
    if (isSingleMode(props)) {
      props.onChange({
        target: {
          name,
          value: value.length ? dayjs(value[0]).format(dateFormat) : null
        }
      });
    } else {
      props.onChange({
        target: {
          name,
          value: value.map((v) => dayjs(v).format(dateFormat))
        }
      });
    }
  }

  function handleReset(): void {
    if (isSingleMode(props)) {
      props.onChange({
        target: { name, value: null }
      });
    } else {
      props.onChange({
        target: { name, value: [] }
      });
    }
  }

  if (
    resettable &&
    (mode !== DatePickerMode.Single && Array.isArray(value)
      ? value.length > 0
      : value)
  ) {
    components.right.push({
      width: 36,
      render: function ClearButton() {
        return (
          <Button
            size="sm"
            type="button"
            icon={CrossIcon}
            variant="tertiary"
            onClick={handleReset}
          />
        );
      }
    });
  }

  // this fixes the flatpickr bug that caused the value not to be shown after closing the calendar
  // this also allows to format the values into a custom format
  useLayoutEffect(() => {
    if (flatpickr) {
      const dateValue = formatValueToDate(value);
      if (dateValue) {
        flatpickr.setDate(dateValue, false);
        // @ts-ignore
        flatpickr.element.value = onFormatValue(dateValue);
      }
    }
  }, [value, flatpickr, onFormatValue, formatValueToDate]);

  return (
    <ReactFlatpickr
      className={cn('DateInput', className)}
      value={formatValueToDate(value)}
      options={{
        mode,
        inline,
        dateFormat: 'm/d/Y',
        disableMobile: true,
        allowInput: false,
        showMonths,
        minDate,
        maxDate
      }}
      onValueUpdate={(dates) => {
        if (flatpickr) {
          // @ts-ignore
          flatpickr.element.value = onFormatValue(
            // @ts-ignore
            mode === DatePickerMode.Single ? dates?.[0] : dates
          );
        }
      }}
      onReady={(v1, v2, flatpickr) => setFlatpickr(flatpickr)}
      onChange={(value) => handleChange(value)}
      onClose={(value) => handleChange(value)}
      // @ts-ignore
      onDestroy={() => setFlatpickr(null)}
      render={({ ...props }, ref) => (
        <Input
          ref={ref}
          name={name}
          type="text"
          variant="dark"
          components={components}
          icon={{ left: CalendarIcon }}
          placeholder={placeholder}
          {...props}
          onChange={(e) => e}
          value=""
        />
      )}
    />
  );
}

export default DateInput;
