import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import ReactModal from 'react-modal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import './Modal.scss';
import Button from '../Button/Button';
import { CrossIcon } from '../../components/Icons';
import Loader from '../../components/Loader';
import Fade from '../Transitions/Fade';

type ModalProps = {
  open?: boolean;
  title?: string;
  size?: 'sm' | 'md' | 'lg';
  padding?: 'md';
  step?: string; // Use this value when you need to make a scroll to the top
  alignment?: 'left';
  children?: JSX.Element | JSX.Element[];
  hasCloseButton?: boolean;
  closable?: boolean;
  loading?: boolean;
  onClose?: () => unknown;
  className?: string;
};

const ANIMATION_TIMEOUT = 400;

function ModalV2(props: ModalProps): JSX.Element {
  const {
    open,
    title,
    size = 'md',
    padding = 'md',
    alignment = 'left',
    children,
    step,
    loading,
    className,
    onClose,
    closable = !!onClose,
    hasCloseButton = closable,
    ...restProps
  } = props;

  const [contentRef, setContentRef] = useState<HTMLDivElement>();

  const rootElRef = useRef<HTMLDivElement>(null);

  const modalClassNames = cn('ModalV2', className, {
    [`ModalV2--${size}`]: size,
    [`ModalV2--p-${padding}`]: padding
  });

  // Scrolls to the top of the modal when the current step changes
  useLayoutEffect(() => {
    if (rootElRef.current) {
      rootElRef.current.scrollTo(0, 0);
    }
  }, [step]);

  useEffect(() => {
    if (!contentRef) return;

    if (open) {
      disableBodyScroll(contentRef, {
        reserveScrollBarGap: true
      });

      return () => enableBodyScroll(contentRef);
    }
  }, [open, contentRef]);

  return (
    <ReactModal
      isOpen={!!open}
      closeTimeoutMS={ANIMATION_TIMEOUT}
      overlayClassName="ModalV2__overlay"
      contentRef={setContentRef}
      className={modalClassNames}
      onRequestClose={closable ? onClose : undefined}
      parentSelector={() => document.body}
      shouldCloseOnEsc={closable}
      shouldCloseOnOverlayClick={closable}
      preventScroll={true}
      htmlOpenClassName={null}
      bodyOpenClassName={null}
      {...restProps}
    >
      <div
        ref={rootElRef}
        className={cn('ModalV2__content', {
          [`ModalV2__content--${alignment}`]: alignment
        })}
      >
        {(title || hasCloseButton) && (
          <div className={cn('ModalV2__header')}>
            {title && <h2 className="ModalV2__title">{title}</h2>}
            {hasCloseButton && (
              <Button
                variant="secondary"
                size="md"
                icon={CrossIcon}
                type="button"
                className="ModalV2__close-button"
                onClick={onClose}
              />
            )}
          </div>
        )}
        <div className={cn('ModalV2__body')}>
          {children}
          <Fade visible={loading}>
            {(ref) => (
              <div ref={ref} className="ModalV2__loader">
                <Loader />
              </div>
            )}
          </Fade>
        </div>
      </div>
    </ReactModal>
  );
}

export default ModalV2;
