import { useWeb3React } from '@web3-react/core';

import Button from 'common/components-v2/Button/Button';
import Modal from 'common/components/Modal';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './DataFeesSubscriptionsAlert.scss';
import useUnlockMembershipsQuery from 'common/hooks/useUnlockMembershipsQuery';
import { isPlanAddress } from 'forta-app/data/plans';

const HIDE_KEY = 'data-fees-subscriptions-modal-hide';

const getModalHideFlag = (): boolean => {
  const hide = localStorage.getItem(HIDE_KEY);
  return hide === 'true';
};

const setModalHideFlag = (hide: boolean): void => {
  localStorage.setItem(HIDE_KEY, hide.toString());
};

export default function DataFeesSubscriptionsAlert(): JSX.Element {
  const [openedIfNoPlan, setOpenedIfNoPlan] = useState<boolean>(
    !getModalHideFlag()
  );
  const web3React = useWeb3React();
  const history = useHistory();

  const { keys, fetched } = useUnlockMembershipsQuery({
    params: {
      owner: web3React.account || ''
    }
  });

  const hasPlans = keys.some((key) => {
    return isPlanAddress(key.lock.address.toLowerCase());
  });

  const onHideModalClick = (): void => {
    setModalHideFlag(true);
    setOpenedIfNoPlan(false);
  };

  const onBuyPlanClick = (): void => {
    history.push(`/profile/${web3React.account || ''}/account`);
    setOpenedIfNoPlan(false);
  };

  const opened = hasPlans || !fetched ? false : openedIfNoPlan;

  return (
    <Modal
      opened={opened}
      shouldCloseOnOverlayClick
      onCloseModal={() => setOpenedIfNoPlan(false)}
      className="DataFeesSubscriptionsAlert__modal"
    >
      <div className="DataFeesSubscriptionsAlert">
        <div className="DataFeesSubscriptionsAlert__title">
          <span role="img" aria-label="waring-emoji">
            🚨
          </span>{' '}
          Update Regarding Your Subscriptions
        </div>
        <div className="DataFeesSubscriptionsAlert__description">
          <p>
            The Forta Network has recently launched network fees and developer
            rewards{' '}
            <span role="img" aria-label="wrap-emoji">
              🎉
            </span>
          </p>
          <p>
            <strong>
              To continue receiving alerts from the Forta Network you must
              purchase a plan
            </strong>
          </p>
          <p>
            The General Data plan is 250 FORT per month
            <br />
            Premium Feeds are individually priced and provide access to specific
            high quality feeds
          </p>
        </div>
        <div className="DataFeesSubscriptionsAlert__actions">
          <Button
            variant="secondary"
            size="sm"
            href="https://docs.forta.network/en/latest/fees-why/#forta-subscription-plans"
            target="_blank"
          >
            Learn More
          </Button>
          <Button variant="primary" size="sm" onClick={onBuyPlanClick}>
            Buy Plan
          </Button>
        </div>
        <Button variant="tertiary" size="sm" onClick={onHideModalClick}>
          Don&apos;t show me this again
        </Button>
      </div>
    </Modal>
  );
}
