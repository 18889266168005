import { gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';

export interface DashboardMetricsResult {
  active_agents: number;
  active_scanners: number;
}

export interface AgentScannerMetric {
  key: string;
  interval: { key: string; sum: number; max: number; avg: number }[];
}

export interface AgentMetricsResult {
  scanners: AgentScannerMetric[];
}

export const GET_METRICS_QUERY = gql`
  query ($getAgentMetricsInput: GetAgentMetricsInput) {
    getAgentMetrics(input: $getAgentMetricsInput) {
      scanners {
        key
        interval {
          key
          sum
          max
          avg
        }
      }
    }
  }
`;

export async function getAgentMetrics(params: {
  agentId: string;
  timeFrame: string;
}): Promise<AgentMetricsResult> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    query: GET_METRICS_QUERY,
    variables: { getAgentMetricsInput: params }
  });
  return {
    scanners: apiResult.data?.getAgentMetrics?.scanners || []
  };
}

export async function getDashboardMetrics(): Promise<DashboardMetricsResult> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getDashboardMetricsInput: {}
    },
    query: gql`
      query ($getDashboardMetricsInput: GetDashboardMatricsInput) {
        getDashboardMetrics(input: $getDashboardMetricsInput) {
          active_agents
          active_scanners
        }
      }
    `
  });
  return (
    apiResult.data.getDashboardMetrics || {
      active_agents: 0,
      active_scanners: 0
    }
  );
}
