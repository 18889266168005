import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import './BotDeploymentPage.scss';
import { Routes } from 'common/routes';
import { BotDeploymentContent } from './BotDeploymentContent';

export default function BotDeploymentPage(): JSX.Element {
  const history = useHistory();
  const web3React = useWeb3React();

  return (
    <div className="AgentDeploymentPage">
      <div className="AgentDeploymentPage__header">
        <div className="AgentDeploymentPage__title">
          Deploy a new Detection Bot
        </div>
        <div className="AgentDeploymentPage__description">
          Use this form to deploy a detection bot to watch threats on the
          blockchain.
        </div>
      </div>
      <BotDeploymentContent
        onDeploy={() => {
          if (!web3React.account) throw new Error('Cannot get account address');

          history.push(Routes.profile.bots({ id: web3React.account }));
        }}
        className="AgentDeploymentPage__form"
      />
    </div>
  );
}
