import { gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';
import { Agent } from 'common/lib/apis/agentAPI';

export interface ChainAPIReponse {
  chainId: number;
  bots: number;
  scanners: number;
}

export async function getChains(): Promise<ChainAPIReponse[]> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    query: gql`
      query Retrive {
        getChains {
          chainId
          bots
          scanners
        }
      }
    `
  });
  return apiResult.data.getChains;
}

export interface ScannerAPIResponse {
  id: string;
  created_at: string;
  chain_id: number;
  pool_id: string;
  enabled: boolean;
}

export interface SingleScannerAPIResponse extends ScannerAPIResponse {
  agents: number;
  stake: string;
  totalShares: string;
}

export async function getChainScanners(
  chain_id: number
): Promise<ScannerAPIResponse[]> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getScannersInput: {
        chain_id
      }
    },
    query: gql`
      query GetScanners($getScannersInput: GetScannersInput) {
        getScanners(input: $getScannersInput) {
          id
          chain_id
          created_at
        }
      }
    `
  });

  return apiResult.data.getScanners || [];
}

interface SingleScannerResponse {
  scanner: SingleScannerAPIResponse;
  sla: {
    score: number;
    hour_bucket: string;
  }[];
  agents: Agent[];
}

export async function getScanner(
  scanner: string
): Promise<SingleScannerResponse> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getScannerInput: {
        id: scanner
      }
    },
    query: gql`
      query GetScanner($getScannerInput: GetScannerInput) {
        getScanner(input: $getScannerInput) {
          scanner {
            id
            enabled
            created_at
            chain_id
            pool_id
            agents
          }
          sla {
            score
            hour_bucket
          }
          agents {
            id
            name
            developer
            chain_ids
            created_at
            updated_at
            version
            enabled
          }
        }
      }
    `
  });

  return apiResult.data.getScanner || [];
}
