import React, { useState } from 'react';
import cn from 'classnames';

import './Alerts.scss';

import { Alert } from 'common/lib/apis/alertAPI';
import AlertRowV2 from './AlertRow/AlertRow';
import Table from '../Table/Table';
import TableHead from '../Table/TableHead';
import TableSubHeading from '../Table/TableSubHeading';
import TableBody from '../Table/TableBody';
import TableHeading from '../Table/TableHeading';

export default function Alerts({
  alerts,
  loading,
  mutable,
  empty,
  onMute,
  className
}: {
  id?: string;
  onRefresh?: () => void;
  onSortChange?: (value: string) => void;
  onMute?: (value: string) => void;
  onPageChange?: (value: number) => void;
  currentSort: string;
  alerts: Alert[];
  loading: boolean;
  mutable: boolean;
  title?: string;
  empty?: boolean;
  limit?: number;
  page?: number;
  className?: string;
}): JSX.Element {
  const [expandedAlertHashes, setExpandedAlertHashes] = useState<string[]>([]);

  return (
    <div className={cn('AlertTable', className)}>
      <Table size="sm" minWidth={1200}>
        <TableHead>
          <TableHeading>
            <TableSubHeading title="Severity" flex={1} />
            <TableSubHeading title="Name" flex={3} />
            <TableSubHeading title="Description" flex={5} />
            <TableSubHeading title="Scanners" flex={1} />
            <TableSubHeading title="Date" flex={1.4} />
            <TableSubHeading title="" flex={1.7} />
          </TableHeading>
        </TableHead>
        <TableBody loading={loading} empty={empty}>
          {alerts.map((a, i) => {
            const isExpanded = expandedAlertHashes.includes(a.hash);

            return (
              <AlertRowV2
                key={a.hash + i}
                alert={a}
                expanded={isExpanded}
                mutable={mutable}
                onMute={() => onMute?.(a.name)}
                onExpand={() =>
                  isExpanded
                    ? setExpandedAlertHashes(
                        expandedAlertHashes.filter((v) => v !== a.hash)
                      )
                    : setExpandedAlertHashes([...expandedAlertHashes, a.hash])
                }
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
