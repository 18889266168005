import { gql } from '@apollo/client';
import useGraphQuery, { GraphQueryResult } from './useGraphQuery';
import { ApolloClientName } from '../components/ApolloClientProvider';

export const SCANNER_POOL_APY_QUERY = gql`
  query NodePoolApyQuery($getNodePoolsInput: GetNodePoolsInput) {
    getNodePools(input: $getNodePoolsInput) {
      scannerPools {
        id
        apy
      }
      hasNextPage
    }
  }
`;

export type NodePool = {
  id: string;
  chain_id: string;
  apy: number;
};

type UseScannerPoolApyParams = {
  nodePoolIds?: string[];
  owner?: string;
  first?: number;
  skip?: number;
  orderBy?: 'apy';
  orderDirection?: 'asc' | 'desc';
  chainId?: number;
};

export type GetNodePoolsInput = {
  getNodePoolsInput: {
    nodePoolIds?: string[];
    chainId?: number;
    first?: number;
    skip?: number;
    owner?: string;
    orderBy?: 'apy';
    orderDirection?: 'asc' | 'desc';
  };
};

export type GetNodePoolsResult = {
  getNodePools: {
    hasNextPage: boolean;
    scannerPools: NodePool[];
  };
};

function useScannerPoolsApyQuery(opts: {
  params: UseScannerPoolApyParams;
  enabled?: boolean;
  enablePolling?: boolean;
}): GraphQueryResult<GetNodePoolsInput, GetNodePoolsResult> & {
  scannerPoolApyList: NodePool[];
  hasNextPage: boolean;
} {
  const { enabled, enablePolling, params } = opts;

  const queryResult = useGraphQuery<GetNodePoolsInput, GetNodePoolsResult>({
    enabled,
    query: SCANNER_POOL_APY_QUERY,
    clientName: ApolloClientName.Forta,
    enablePolling: enablePolling,
    variables: {
      getNodePoolsInput: {
        nodePoolIds: params.nodePoolIds,
        chainId: params.chainId != null ? Number(params.chainId) : undefined,
        first: params.first,
        orderBy: params.orderBy,
        skip: params.skip,
        orderDirection: params.orderDirection
      }
    }
  });

  return {
    ...queryResult,
    scannerPoolApyList: (queryResult.data?.getNodePools.scannerPools || []).map(
      (v) => ({ ...v, apy: parseFloat(v.apy as unknown as string) })
    ),
    hasNextPage: queryResult.data?.getNodePools.hasNextPage || false
  };
}

export default useScannerPoolsApyQuery;
