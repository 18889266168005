/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useMemo } from 'react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { UrlUpdateType } from 'use-query-params/src/types';

import { UseOffsetPaginationResult } from './useOffsetPagination';

type UseQueryPaginationOptions = {
  name?: string;
  updateType?: UrlUpdateType;
};

// TODO Add support for cursor pagination

function useQueryPagination(
  pagination: UseOffsetPaginationResult,
  opts?: UseQueryPaginationOptions
): UseOffsetPaginationResult {
  const { name = 'page', updateType = 'pushIn' } = opts || {};

  const PageParam = useMemo(
    () => withDefault(NumberParam, pagination.page),
    []
  );

  const [queryPage, setQueryPage] = useQueryParam(name, PageParam, {
    updateType
  });

  // override the initial page param if query (url) contains the value of this parameter
  useLayoutEffect(() => {
    if (queryPage !== pagination.page) {
      pagination.setPage(queryPage);
    }
  }, []);

  const { setPage, goPrevPage, goNextPage } = useMemo(() => {
    return {
      setPage: (value: ((prevState: number) => number) | number) => {
        value = typeof value === 'function' ? value(pagination.page) : value;
        pagination.setPage(value);
        setQueryPage(value);
      },
      goNextPage: () => {
        pagination.setPage(queryPage + 1);
        setQueryPage(queryPage + 1);
      },
      goPrevPage: () => {
        pagination.setPage(queryPage - 1);
        setQueryPage(queryPage - 1);
      }
    };
  }, [queryPage]);

  return { ...pagination, setPage, goPrevPage, goNextPage };
}

export default useQueryPagination;
