import jwt_decode from 'jwt-decode';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'forta-app/app/store';
import cookie from 'common/lib/cookies';
import {
  loginAddress,
  LoginAddressParams,
  logoutAddress
} from 'forta-app/lib/apis/loginAPI';

export const isJWTExpired = (jwt: string): boolean => {
  try {
    const decodedJWT: { exp: number } = jwt_decode(jwt);
    return decodedJWT.exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
};

export const getJWTmsToExpiration = (jwt: string): number => {
  try {
    const decodedJWT: { exp: number } = jwt_decode(jwt);
    return decodedJWT.exp * 1000 - Date.now();
  } catch (error) {
    return 0;
  }
};

export const getJWTAddress = (jwt: string): string => {
  try {
    const decodedJWT: { sub: string } = jwt_decode(jwt);
    return decodedJWT.sub;
  } catch (error) {
    return '';
  }
};

export interface WalletSliceState {
  loginLoading: boolean;
  jwt: string;
}

const initialState: WalletSliceState = {
  loginLoading: false,
  jwt: cookie.getJWT() || ''
};

export const login = createAsyncThunk<
  { jwt: string },
  LoginAddressParams,
  { state: RootState }
>('wallet/loginAddress', async (params) => {
  return {
    jwt: await loginAddress(params)
  };
});

export const logout = createAsyncThunk<void, void, { state: RootState }>(
  'wallet/logout',
  async () => {
    await logoutAddress();
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setLoginData: (state, action) => {
      state.loginLoading = false;
      state.jwt = action.payload.jwt;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(login.rejected, (state) => {
        state.loginLoading = false;
        state.jwt = '';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginLoading = false;
        state.jwt = action.payload.jwt;
      })
      .addCase(logout.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(logout.rejected, (state) => {
        state.loginLoading = false;
        state.jwt = '';
      })
      .addCase(logout.fulfilled, (state) => {
        state.loginLoading = false;
        state.jwt = '';
      });
  }
});

export const { setLoginData } = walletSlice.actions;

export default walletSlice.reducer;
