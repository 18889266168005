import cn from 'classnames';

import './Loader.scss';

type LoaderProps = {
  className?: string;
};

export default function Loader({ className }: LoaderProps): JSX.Element {
  return (
    <div className={cn('Loader', className)}>
      <img
        className="Loader__image"
        src="/forta_asterisk_light_64.png"
        alt="loading..."
      />
    </div>
  );
}
