/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment */
import React, { Children, createElement, ReactNode } from 'react';
import GithubSlugger from 'github-slugger';
import type { HeadingProps } from 'react-markdown/lib/ast-to-react';

// https://github.com/tinacms/tina.io/pull/1459/files

function MarkdownHeading({ children, level }: HeadingProps): JSX.Element {
  const value = textFromChildren(children);

  const slugger = new GithubSlugger();
  const slug = slugger.slug(value);

  return createElement(`h${level}`, { id: slug }, <>{children}</>);
}

/**
 * Return the flattened text value for the specified children.
 *
 * @see https://github.com/remarkjs/react-markdown/issues/404#issuecomment-604019030
 */
const textFromChildren = (children: ReactNode | ReactNode[]): string => {
  function flatten(
    text: string,
    child: JSX.Element | string
  ):
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | Record<string, unknown>
    | React.ReactNodeArray
    | React.ReactPortal {
    return typeof child === 'string'
      ? text + child
      : // @ts-ignore
        Children.toArray(child.props.children).reduce(flatten, text);
  }

  // @ts-ignore
  return Children.toArray(children).reduce(flatten, '');
};

export default MarkdownHeading;
