import { gql } from '@apollo/client';
import { BigNumber } from '@ethersproject/bignumber';

import { ApolloClientName } from 'common/components/ApolloClientProvider';
import useGraphQuery, { GraphQueryResult } from 'common/hooks/useGraphQuery';

export const UNLOCK_RECEIPTS_QUERY = gql`
  query GetReceipts($first: Int, $skip: Int, $payer: String!) {
    receipts(
      first: $first
      skip: $skip
      orderBy: timestamp
      orderDirection: desc
      where: { payer: $payer }
    ) {
      id
      sender
      payer
      tokenAddress
      lockAddress
      amountTransferred
      receiptNumber
      timestamp
    }
  }
`;

export const UNLOCK_RECEIPTS_FILTERED_BY_TIMESTAMP_QUERY = gql`
  query GetReceipts($first: Int, $payer: String!, $timestampFilterValue: Int) {
    receipts(
      first: $first
      orderBy: timestamp
      orderDirection: asc
      where: { payer: $payer, timestamp_gt: $timestampFilterValue }
    ) {
      id
      sender
      payer
      tokenAddress
      lockAddress
      amountTransferred
      receiptNumber
      timestamp
    }
  }
`;

export interface UnlockReceiptResult {
  id: string;
  sender: string;
  payer: string;
  tokenAddress: string;
  lockAddress: string;
  amountTransferred: string;
  receiptNumber: string;
  timestamp: string;
}

type ResponseData = { receipts: UnlockReceiptResult[] };

function useUnlockReceiptsQuery(opts: {
  params: {
    payer: string;
    first?: number;
    skip?: number;
    timestampFilterValue?: number;
  };
  enabled?: boolean;
  enablePolling?: boolean;
}): GraphQueryResult<{ payer: string }, ResponseData> & {
  receipts: UnlockReceiptResult[];
} {
  const { enabled, enablePolling, params } = opts;

  const queryResult = useGraphQuery<
    {
      payer: string;
      first?: number;
      skip?: number;
      timestampFilterValue?: number;
    },
    ResponseData
  >({
    enabled,
    clientName: ApolloClientName.Unlock,
    query: UNLOCK_RECEIPTS_QUERY,
    enablePolling,
    variables: {
      first: params.first,
      skip: params.skip,
      payer: params.payer.toLowerCase(),
      timestampFilterValue: params.timestampFilterValue
    }
  });

  // Need to make a copy bc DocumentNode fields are readonly
  const receiptsDeepCopy: UnlockReceiptResult[] = JSON.parse(
    JSON.stringify(queryResult.data?.receipts || [])
  );

  receiptsDeepCopy.map((receipt): UnlockReceiptResult => {
    // Modify timestamp to be in millis
    receipt.timestamp = BigNumber.from(receipt.timestamp).mul(1000).toString();
    return receipt;
  });

  return {
    ...queryResult,
    receipts: receiptsDeepCopy
  };
}

export function useUnlockReceiptsFilteredQuery(opts: {
  params: {
    payer: string;
    first?: number;
    timestampFilterValue?: number;
  };
  enabled?: boolean;
  enablePolling?: boolean;
}): GraphQueryResult<{ payer: string }, ResponseData> & {
  receipts: UnlockReceiptResult[];
} {
  const { enabled, enablePolling, params } = opts;

  const queryResult = useGraphQuery<
    {
      payer: string;
      first?: number;
      timestampFilterValue?: number;
    },
    ResponseData
  >({
    enabled,
    clientName: ApolloClientName.Unlock,
    query: UNLOCK_RECEIPTS_FILTERED_BY_TIMESTAMP_QUERY,
    enablePolling,
    variables: {
      first: params.first,
      payer: params.payer.toLowerCase(),
      timestampFilterValue: params.timestampFilterValue
    }
  });

  // Need to make a copy bc DocumentNode fields are readonly
  const receiptsDeepCopy: UnlockReceiptResult[] = JSON.parse(
    JSON.stringify(queryResult.data?.receipts || [])
  );

  receiptsDeepCopy.map((receipt): UnlockReceiptResult => {
    // Modify timestamp to be in millis
    receipt.timestamp = BigNumber.from(receipt.timestamp).mul(1000).toString();
    return receipt;
  });

  return {
    ...queryResult,
    receipts: receiptsDeepCopy
  };
}

export default useUnlockReceiptsQuery;
