import Select from 'common/components-v2/Form/Select/Select';
import { NetworkInfo, V1_BOT_NETWORK_ENTITIES } from 'common/lib/networks';
import ChainIcon from 'common/components/stats/ChainIcon';
import './ChainSelect.scss';

export default function ChainSelect(props: {
  value: number;
  onChange: (value: number) => void;
}): JSX.Element {
  return (
    <Select<number>
      name="AddNodePool__select-chain"
      value={props.value}
      placeholder="Select chain..."
      onChange={(event: { target: { value: number | null } }): void =>
        props.onChange(event.target.value || 0)
      }
      options={V1_BOT_NETWORK_ENTITIES.map((network: NetworkInfo) => ({
        label: (
          <div className="ChainSelect__option">
            <ChainIcon network={network} />
            {network.label}
          </div>
        ),
        value: network.chainId
      }))}
    />
  );
}
