import React, { useState } from 'react';

import './MyNodePoolsPage.scss';

import AddScanNodePoolButton from 'forta-app/components/scan-node-pool/AddScanNodePoolButton';
import Table from 'common/components-v2/Table/Table';
import TableHeading from 'common/components-v2/Table/TableHeading';
import TableSubHeading from 'common/components-v2/Table/TableSubHeading';
import TableRow from 'common/components-v2/Table/TableRow';
import TableCell from 'common/components-v2/Table/TableCell';
import TableHead from 'common/components-v2/Table/TableHead';
import TableBody from 'common/components-v2/Table/TableBody';
import TableSubCell from 'common/components-v2/Table/TableSubCell';
import { EditIcon } from 'common/components/Icons';
import { UpdateCommissionModal } from 'forta-app/components/scan-node-pool/UpdateCommissionButton';
import { useJWT } from 'forta-app/app/hooks';
import { useWeb3React } from '@web3-react/core';
import { formatFORT } from 'forta-app/lib/utils';
import Pagination from 'common/components-v2/Pagination/Pagination';
import PoolEntity from '../../common/components-v2/Entities/Pool/Pool';
import CommissionDisplay from 'forta-app/components/scan-node-pool/CommissionDisplay';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import useScannerPoolsWithApyQuery from 'common/hooks/useScannerPoolsWithApyQuery';
import useOffsetPagination from 'common/hooks/useOffsetPagination';

const PAGE_SIZE = 10;
const TABLE_ID = 'my-node-pools-table';

export const MyNodePoolsPage = (): JSX.Element => {
  const jwt = useJWT();
  const web3React = useWeb3React();
  const account = (jwt && web3React.account?.toLowerCase()) || '';

  const pagination = useOffsetPagination(PAGE_SIZE, {
    scrollToElementId: TABLE_ID
  });

  const { scannerPools, hasNextPage, fetched, polling, refetch, loading } =
    useScannerPoolsWithApyQuery({
      params: {
        owner: account,
        first: pagination.offset.first,
        skip: pagination.offset.skip
      },
      enablePolling: true
    });

  function handleScannerPoolAdded(): void {
    refetch();
  }

  return (
    <div className="MyNodePoolsPage">
      <div className="MyNodePoolsPage__content">
        <div className="MyNodePoolsPage__content-top">
          <AddScanNodePoolButton onCompleted={handleScannerPoolAdded} />
        </div>
        {jwt && account && (
          <>
            <Table minWidth={1000} id={TABLE_ID}>
              <TableHead>
                <TableHeading title="Pool" flex={16}>
                  <TableSubHeading title="Id" flex={6} />
                  <TableSubHeading
                    name="apyForLastEpoch"
                    title="Est. Annual Rewards"
                    tooltip="Estimated annual rewards for delegators in this pool. This is based on last epoch's data, extrapolated over a year. This is subject to change based on factors such as the node's performance, commission changes, allocated stake, among others."
                    flex={6}
                  />
                  <TableSubHeading title="Commission" flex={4} />
                </TableHeading>
                <TableHeading title="Stake" flex={18}>
                  <TableSubHeading title="Owned" flex={6} />
                  <TableSubHeading title="Delegated" flex={6} />
                  <TableSubHeading title="Allocated" flex={6} />
                </TableHeading>
                {/* <TableHeading flex={1} /> */}
              </TableHead>
              <TableBody
                empty={fetched && scannerPools.length === 0}
                loading={loading && !polling}
              >
                {scannerPools.map((pool, index) => (
                  <TableRow key={index}>
                    <TableCell flex={16}>
                      <TableSubCell
                        value={
                          <PoolEntity
                            id={pool.id}
                            to={`/nodePool/${pool.id}/`}
                            chainId={pool.chainId}
                          />
                        }
                        flex={6}
                      />
                      <TableSubCell
                        value={
                          pool.apyForLastEpoch > 0
                            ? pool.apyForLastEpoch.toString() + '%'
                            : '-'
                        }
                        flex={6}
                      />
                      <TableSubCell
                        value={<CommissionDisplay pool={pool} />}
                        button={<UpdateCommissionButton pool={pool} />}
                        flex={4}
                      />
                    </TableCell>
                    <TableCell flex={18}>
                      <TableSubCell
                        value={`${formatFORT(pool.stakeOwned, 0)} FORT`}
                        flex={6}
                      />
                      <TableSubCell
                        value={`${formatFORT(pool.stakeDelegated, 0)} FORT`}
                        flex={6}
                      />
                      <TableSubCell
                        value={`${formatFORT(pool.stakeAllocated, 0)} FORT`}
                        flex={6}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              page={pagination.page}
              limit={pagination.pageSize}
              itemsCount={scannerPools.length}
              loading={loading && !polling}
              hasNextPage={hasNextPage}
              onNextPage={pagination.goNextPage}
              onPrevPage={pagination.goPrevPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

interface UpdateCommissionButtonProps {
  pool: ScannerPool;
}

export const UpdateCommissionButton = ({
  pool
}: UpdateCommissionButtonProps): JSX.Element => {
  const [opened, setOpened] = useState<boolean>(false);
  return (
    <>
      <button onClick={() => setOpened(true)}>{EditIcon}</button>
      <UpdateCommissionModal
        opened={opened}
        pool={pool}
        onClose={() => setOpened(false)}
      />
    </>
  );
};

export default MyNodePoolsPage;
