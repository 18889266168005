import React, { useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import cn from 'classnames';

import './FilterPanel.scss';

import { FilterPicker } from './FilterPicker';
import SeverityModal from '../Modals/Severity/Severity';

import {
  AlertFilterOption,
  NotificationModalFilterState
} from 'forta-app/slices/notificationSlice';
import Chip from 'common/components-v2/Chip/Chip';
import useBotMetricChains from 'common/hooks/useBotMetricChains';
import ConsensusModal from '../Modals/Consensus/Consensus';
import ChainModal from '../Modals/Chain/Chain';
import AlertModal from '../Modals/Alert/Alert';
import { getNetworkByChainId } from 'common/lib/networks';
import { isAgentId } from 'common/lib/utils';

type FilterPanelProps = {
  source?: string;
  filter: NotificationModalFilterState;
  onChange: (filter: NotificationModalFilterState) => unknown;
  className?: string;
};

export type FilterChipProps = {
  label: string;
  value: string;
  onClick?: () => unknown;
  onRemove?: () => unknown;
};

export function FilterChip(props: FilterChipProps): JSX.Element {
  const { label, value, onClick, onRemove } = props;

  return (
    <Chip
      removable
      variant="outline"
      size="md"
      onClick={onClick || (() => void 0)}
      onRemove={onRemove}
      className="FilterChip"
    >
      <span className="FilterChip__label">{label}</span>
      <span className="FilterChip__value">{value}</span>
    </Chip>
  );
}

export function FilterPanel(props: FilterPanelProps): JSX.Element {
  const { filter, source, onChange, className } = props;

  const isSourceDefined = useMemo(
    () => !!source && isAgentId(source),
    [source]
  );

  const { chainIds, loading: isChainsLoading } = useBotMetricChains({
    params: {
      botId: source || ''
    },
    preservePreviousData: false,
    enabled: isSourceDefined
  });

  const [openedModal, setOpenedModal] = useState<AlertFilterOption>();

  return (
    <div className={cn('AlertFilterPanel', className)}>
      <div className="AlertFilterPanel__chips">
        <FilterPicker
          loading={isChainsLoading}
          disabled={isChainsLoading}
          onClick={(modal) => setOpenedModal(modal)}
        />
        {filter.minimumSeverity && (
          <FilterChip
            label={'Min Severity'}
            value={capitalize(filter.minimumSeverity)}
            onClick={() => setOpenedModal('severity')}
            onRemove={() => onChange({ ...filter, minimumSeverity: undefined })}
          />
        )}
        {filter.minimumScannerConfirmations &&
          filter.minimumScannerConfirmations.value && (
            <FilterChip
              label={'Consensus'}
              value={`${filter.minimumScannerConfirmations.value} ${
                filter.minimumScannerConfirmations.valueType === 'percentage'
                  ? ' %'
                  : ' Nodes'
              }`}
              onClick={() => setOpenedModal('consensus')}
              onRemove={() =>
                onChange({
                  ...filter,
                  minimumScannerConfirmations: undefined
                })
              }
            />
          )}
        {filter.chainId && (
          <FilterChip
            label="Network"
            value={getNetworkByChainId(parseInt(filter.chainId), 'ID').label}
            onClick={() => setOpenedModal('chain')}
            onRemove={() =>
              onChange({
                ...filter,
                chainId: undefined
              })
            }
          />
        )}
        {filter.alertId && (
          <FilterChip
            label="Alert ID"
            value={filter.alertId}
            onClick={() => setOpenedModal('alertId')}
            onRemove={() =>
              onChange({
                ...filter,
                alertId: undefined
              })
            }
          />
        )}
      </div>
      <SeverityModal
        open={openedModal === 'severity'}
        value={filter.minimumSeverity}
        onChange={(severity) => {
          onChange({ ...filter, minimumSeverity: severity });
          setOpenedModal(undefined);
        }}
        onClose={() => setOpenedModal(undefined)}
      />
      <ConsensusModal
        open={openedModal === 'consensus'}
        value={filter.minimumScannerConfirmations}
        onChange={(value) => {
          onChange({ ...filter, minimumScannerConfirmations: value });
          setOpenedModal(undefined);
        }}
        onClose={() => setOpenedModal(undefined)}
      />
      <ChainModal
        open={openedModal === 'chain'}
        variant={chainIds.length === 0 || !isSourceDefined ? 'input' : 'select'}
        value={filter.chainId}
        chainIds={isSourceDefined ? chainIds : []}
        onChange={(value) => {
          onChange({ ...filter, chainId: value });
          setOpenedModal(undefined);
        }}
        onClose={() => setOpenedModal(undefined)}
      />
      <AlertModal
        open={openedModal === 'alertId'}
        value={filter.alertId}
        onChange={(value) => {
          onChange({ ...filter, alertId: value });
          setOpenedModal(undefined);
        }}
        onClose={() => setOpenedModal(undefined)}
      />
    </div>
  );
}

export default FilterPanel;
