import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import cn from 'classnames';
import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';

import GroupPanel from './GroupPanel';
import './AnalysisResult.scss';

import AlertsContainer from 'common/components-v2/Alerts/AlertsContainer/AlertsContainer';
import Pagination from 'common/components-v2/Pagination/Pagination';
import useAlertsQuery from 'forta-app/hooks/useAlertsQuery';
import useCursorPagination from 'common/hooks/useCursorPagination';
import { CombinerGroup } from 'forta-app/pages/tools/AlertCombinerPage';
import { RefreshIcon } from 'common/components/Icons';
import { AlertsApiParams } from 'common/lib/apis/alertAPI';
import { BotKit, botKits } from 'forta-app/data/bots';
import { SYSTEM_DATE_FORMAT } from 'common/constants';

export type Analysis = {
  groups: CombinerGroup[];
  chainId: number;
  botKitKey: string;
  startDate: string;
  endDate: string;
  createdAt: string;
};

type AnalysisResultProps = {
  analysis: Analysis;
  className?: string;
};

export const DEFAULT_FILTER: AlertsApiParams = {
  severity: [],
  addresses: [],
  pageValues: undefined,
  text: '',
  agents: [],
  sort: 'desc',
  muted: [],
  txHash: '',
  limit: 20
};

function AnalysisResultHeading(props: { createdAt: string }): JSX.Element {
  const { createdAt } = props;
  const [timeFromCreated, setTimeFromCreated] = useState('');

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    function loop(): void {
      setTimeFromCreated(dayjs(createdAt, SYSTEM_DATE_FORMAT).fromNow());
      timeout = setTimeout(loop, 1000);
    }

    loop();

    return () => clearTimeout(timeout);
  }, [createdAt]);

  return (
    <div className="AnalysisResult__heading">
      <h3 className="AnalysisResult__title">Results</h3>
      <span className="AnalysisResult__time">Updated {timeFromCreated}</span>
    </div>
  );
}

function AnalysisResult({
  analysis,
  className
}: AnalysisResultProps): JSX.Element {
  const { botKitKey, groups } = analysis;

  const botKit: BotKit = useMemo(
    () => botKits.find((k) => k.key === botKitKey) as BotKit,
    [botKitKey]
  );

  const client = useApolloClient();
  const [groupIndex, setGroupIndex] = useState(0);

  const [filter, setFilter] = useState<AlertsApiParams>(DEFAULT_FILTER);

  const {
    alerts,
    loading,
    refetching,
    queryResult,
    currentPageValues,
    nextPageValues,
    refetch
  } = useAlertsQuery({ params: filter });

  const { page, goPrevPage, goNextPage, resetPagination } = useCursorPagination(
    {
      queryResult,
      pageValues: [currentPageValues, nextPageValues],
      onPageValuesChange: async (pageValues, page) => {
        if (page === 0) {
          // reset cursor, cache and fetch most actual data
          await client.cache.reset();
          resetPagination();
          setFilter((v) => ({ ...v, pageValues: undefined }));
        } else {
          setFilter((v) => ({ ...v, pageValues }));
        }
      }
    }
  );

  const group = useMemo(() => groups[groupIndex] || null, [groups, groupIndex]);

  useLayoutEffect(() => {
    setGroupIndex(0);
  }, [analysis]);

  useLayoutEffect(() => {
    setFilter({
      ...DEFAULT_FILTER,
      startDate: analysis.startDate,
      endDate: analysis.endDate,
      chain_id: analysis.chainId,
      addresses: [groups[groupIndex].address]
    });
  }, [groupIndex, groups, analysis]);

  const changeFilter = useCallback(
    (value: typeof filter) => {
      resetPagination();
      setFilter(value);
    },
    [setFilter, resetPagination]
  );

  return (
    <div className={cn('AnalysisResult', className)}>
      <AnalysisResultHeading createdAt={analysis.createdAt} />
      <GroupPanel
        group={group}
        groupIndex={groupIndex}
        botKit={botKit}
        chainId={analysis.chainId}
        totalGroups={groups.length}
        onGroupIndexChange={setGroupIndex}
        className="AnalysisResult__panel"
      />
      <AlertsContainer
        title="Alerts"
        alerts={alerts}
        filter={filter}
        changeable={true}
        loading={loading || refetching}
        actions={[
          {
            type: 'button',
            label: 'Refresh',
            icon: RefreshIcon,
            loading: refetching,
            disabled: loading || refetching,
            onClick: () => refetch()
          }
        ]}
        onFilterChange={changeFilter}
      />
      {(alerts.length > 0 || page > 0) && (
        <Pagination
          page={page}
          limit={filter.limit}
          itemsCount={alerts.length}
          loading={loading}
          hasNextPage={alerts.length === filter.limit}
          onNextPage={() => goNextPage()}
          onPrevPage={() => goPrevPage()}
        />
      )}
    </div>
  );
}

export default AnalysisResult;
