// You can check format here:
// https://day.js.org/docs/en/display/format

// the format that is displayed to users
export const APP_DATE_FORMAT = 'MM/DD/YYYY';
// the format that is displayed to users
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
// the format that is used inside the application and backend
export const SYSTEM_DATE_FORMAT = 'x'; // https://day.js.org/docs/en/plugin/advanced-format
// Aug 16, 2018 8:02 PM
export const APP_FULL_DATE_FORMAT = 'MMM D, YYYY';
export const APP_FULL_DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

export const DEFAULT_SEARCH_DEBOUNCE_TIME = 500;
