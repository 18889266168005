import cn from 'classnames';

import './Pagination.scss';

import Button from 'common/components-v2/Button/Button';
import { NextIcon, PreviousIcon } from 'common/components/Icons';

type PaginationProps = {
  label?: JSX.Element;
  limit: number;
  page: number;
  itemsCount: number;
  hasNextPage: boolean;
  onNextPage: () => unknown;
  onPrevPage: () => unknown;
  loading?: boolean;
  className?: string;
};

// This component is a presentational version of the "Pager" component.

function Pagination(props: PaginationProps): JSX.Element {
  const {
    page,
    limit,
    label,
    itemsCount,
    hasNextPage,
    onPrevPage,
    onNextPage,
    loading,
    className
  } = props;

  return (
    <div className={cn('Pagination', className)}>
      {label ? (
        label
      ) : (
        <p className="Pagination__label">
          Showing results{' '}
          <span className="Pagination__label-number">{page * limit + 1}</span>{' '}
          to{' '}
          <span className="Pagination__label-number">
            {page * limit + itemsCount}
          </span>
        </p>
      )}
      <nav className="Pagination__nav" aria-label="Pagination">
        <Button
          variant="default"
          size="sm"
          disabled={loading || page <= 0}
          onClick={() => onPrevPage()}
          className="Pagination__nav-button Pagination__nav-button--prev"
        >
          {PreviousIcon}
        </Button>
        <Button
          variant="default"
          size="sm"
          disabled={loading || !hasNextPage}
          onClick={() => onNextPage()}
          className="Pagination__nav-button Pagination__nav-button--next"
        >
          {NextIcon}
        </Button>
      </nav>
    </div>
  );
}

export default Pagination;
