import { ChartInterval } from './utils';

export const CHART_INTERVAL: {
  [key: string]: ChartInterval | null;
} = {
  OneMonth: { value: 1, unit: 'month' },
  OneWeek: { value: 1, unit: 'week' },
  OneDay: { value: 1, unit: 'day' },
  OneHour: { value: 1, unit: 'hour' },
  AllTime: null
};

export const CHART_INTERVAL_OPTIONS = [
  { label: '1w', value: CHART_INTERVAL.OneWeek },
  { label: '1m', value: CHART_INTERVAL.OneMonth },
  { label: 'All Time', value: null }
];
