import Button from 'common/components-v2/Button/Button';
import Modal from 'common/components/Modal';

import './SuccessDataPlanModal.scss';

export default function SuccessDataPlanModal({
  planName,
  onClose,
  opened
}: {
  planName: string;
  onClose: () => void;
  opened: boolean;
}): JSX.Element {
  return (
    <Modal
      opened={opened}
      className="SuccessDataPlanModal__wrapper"
      onCloseModal={onClose}
    >
      <div className="SuccessDataPlanModal">
        <div className="SuccessDataPlanModal__title">You have subscribed!</div>
        <div className="SuccessDataPlanModal__description">
          Thank you for subscribing to{' '}
          <strong>Forta&apos;s {planName} Plan</strong>. Your plan will be
          populated under the &quot;Account&quot; tab in the Forta App within a
          few minutes.
        </div>
        <div className="SuccessDataPlanModal__action">
          <Button size="sm" variant="primary" onClick={onClose}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
}
