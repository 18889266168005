import React from 'react';
import cn from 'classnames';

import './Shimmer.scss';

type ShimmerProps = {
  className?: string;
};

function Shimmer(props: ShimmerProps): JSX.Element {
  const { className } = props;

  return (
    <div className={cn('Shimmer', className)}>
      <div className="Shimmer__gradient" />
    </div>
  );
}

export default Shimmer;
