import CountUp from 'react-countup';
import cn from 'classnames';

export const getNumberMagnitude = (
  value: number,
  minForKShorten = 1e3
): string => {
  if (value > 1e6) {
    return 'm';
  }
  if (value > minForKShorten) {
    return 'k';
  }
  return '';
};

export const getNumberCoefficient = (
  value: number,
  minForKShorten = 1e3
): number => {
  if (value > 1e6) {
    return value / 1e6;
  }
  if (value > minForKShorten) {
    return value / 1e3;
  }
  return value;
};

interface CounterProps {
  duration: number;
  value: number;
  decimals: number;
  minForKShorten?: number;
  className?: string;
}

export default function Counter({
  duration,
  value,
  decimals,
  minForKShorten,
  className
}: CounterProps): JSX.Element {
  const magnitude = getNumberMagnitude(value, minForKShorten);
  const coefficient = getNumberCoefficient(value, minForKShorten);
  return (
    <div className={cn('Counter', className)}>
      <CountUp
        useEasing
        duration={duration}
        decimals={decimals}
        end={coefficient}
      />
      {magnitude}
    </div>
  );
}
