import { gql } from '@apollo/client';
import { ApolloClientName } from 'common/components/ApolloClientProvider';
import useGraphQuery, { GraphQueryResult } from 'common/hooks/useGraphQuery';

const GET_ATTACK_DETECTOR_DATA_QUERY = gql`
  query BotChains($input: GetAttackDetectorDataInput) {
    getAttackDetectorData(input: $input) {
      metrics {
        precision
        nodeOperators
        alertsPerDay
        totalTransactions
        fundsCouldBeSaved
      }
      chains {
        chainId
        alertsPerMonth
      }
    }
  }
`;

export type UseAttackDetectorDataQueryParams = {
  botId: string;
};

export type AttackDetectorMetrics = {
  precision: number;
  nodeOperators: number;
  alertsPerDay: number;
  totalTransactions: string;
  fundsCouldBeSaved: string;
};

export type ChainStat = {
  chainId: number;
  alertsPerMonth: number;
};

export type UseAttackDetectorDataQueryResult = {
  getAttackDetectorData: {
    metrics: AttackDetectorMetrics;
    chains: ChainStat[];
  };
};

type UseAttackDetectorDataInput = {
  input: {
    botId: string;
  };
};

function useAttackDetectorData(opts: {
  params: UseAttackDetectorDataQueryParams;
  enabled?: boolean;
}): GraphQueryResult<
  UseAttackDetectorDataInput,
  UseAttackDetectorDataQueryResult
> & {
  metrics?: AttackDetectorMetrics;
  chains: ChainStat[];
} {
  const { params, enabled = true } = opts;

  const query = useGraphQuery<
    UseAttackDetectorDataInput,
    UseAttackDetectorDataQueryResult
  >({
    query: GET_ATTACK_DETECTOR_DATA_QUERY,
    enabled: enabled,
    variables: {
      input: {
        botId: params.botId
      }
    },
    clientName: ApolloClientName.Forta
  });

  return {
    ...query,
    chains: query.data?.getAttackDetectorData.chains || [],
    metrics: query.data?.getAttackDetectorData.metrics
  };
}

export default useAttackDetectorData;
