import config from 'common/config';
import axios from 'axios';
import Cookies from 'universal-cookie';
export interface LoginAddressParams {
  address: string;
  timestamp: string;
  message: string;
  signature: string;
}

export async function loginAddress(
  params: LoginAddressParams
): Promise<string> {
  try {
    const loginEndpoint = config.apiEndpoint.replace('graphql', 'login');
    const result = await axios.post<string>(loginEndpoint, params, {
      withCredentials: true
    });

    let jwt: string = new Cookies().get('jwt', { doNotParse: true });
    if (!jwt) {
      jwt = result.data;
    }

    return jwt;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return 'An unexpected error occurred';
    }
  }
}

export async function logoutAddress(): Promise<void> {
  try {
    const logoutEndpoint = config.apiEndpoint.replace('graphql', 'logout');

    await axios.post<string>(logoutEndpoint, null, {
      withCredentials: true
    });
  } catch (error) {
    console.error('Unable to logout');
    if (axios.isAxiosError(error)) {
      console.error(error);
    }
  }
}

export async function getNonce(): Promise<string> {
  try {
    const nonceEndpoint = config.apiEndpoint.replace('graphql', 'nonce');
    const { data } = await axios.get<string>(nonceEndpoint, {
      headers: {
        Accept: 'text/plain'
      },
      responseType: 'text'
    });
    return data || '';
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return 'An unexpected error occurred';
    }
  }
}
