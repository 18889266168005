import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import CopyButton from 'common/components/CopyButton';
import ChainIcon from 'common/components/stats/ChainIcon';
import { getNetworkByChainId } from 'common/lib/networks';
import { formatPoolId, isScanNodeId, shortenHash } from 'common/lib/utils';
import Loader from 'common/components/Loader';
import InfoPopover from 'common/components/InfoPopover';
import { Agent } from 'common/lib/apis/agentAPI';
import InformationBox from 'common/components/InformationBox';
import ScanNodeLists from 'forta-app/components/scan-node/ScanNodeLists';
import { getScanner } from 'forta-app/lib/apis/networkAPI';
import './ScanNodePage.scss';
import { NavLink, Redirect } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  ScannerMultiSlaResult,
  getMultiSLAs
} from 'forta-app/lib/apis/getMultiSLAs';
import { formatNumber } from 'forta-app/lib/utils';
import { TableSubCellWithHealthIndicator } from 'common/components-v2/Table/TableSubCell';

export interface ScannerState {
  id: string;
  chain_id: number;
  pool_id: string;
  enabled: boolean;
  loading: boolean;
  loadingStake: boolean;
  error: boolean;
  sla: { score: number; hour_bucket: string }[];
}

interface ScanNodePageProps {
  match: {
    params: {
      id: string;
    };
  };
}

export default function ScanNodePage(props: ScanNodePageProps): JSX.Element {
  const nodeId = isScanNodeId(props.match.params.id)
    ? props.match.params.id
    : '';

  const [scanner, setScanner] = useState<ScannerState>({
    id: nodeId,
    chain_id: 1,
    pool_id: '',
    enabled: true,
    loading: true,
    loadingStake: false,
    error: false,
    sla: []
  });
  const network = getNetworkByChainId(scanner.chain_id);
  const [bots, setBots] = useState<Agent[]>([]);

  // eslint-disable-next-line
  const { isLoading, data } = useQuery({
    queryKey: ['slaResults', nodeId],
    queryFn: async () => await getMultiSLAs({ scanner_ids: [nodeId] }),
    enabled: !!nodeId
  });

  // eslint-disable-next-line
  const setSubCellValue = (
    id: string,
    data: ScannerMultiSlaResult[] | undefined,
    type: 'sla' | 'uptime'
  ): string => {
    const dataForId = data?.find((slaResult) => slaResult.id === id);

    if (dataForId === undefined) {
      return 'No Data Available';
    }

    if (type === 'sla' && 'avgSLA' in dataForId.slaAvgOverTime.sla) {
      return formatNumber(dataForId.slaAvgOverTime.sla.avgSLA, 2);
    } else if (
      type === 'uptime' &&
      'avgUptime' in dataForId.slaAvgOverTime.uptime
    ) {
      return formatNumber(dataForId.slaAvgOverTime.uptime.avgUptime * 100, 0);
    } else {
      return 'No Data Available';
    }
  };

  const updateScannerData = useCallback(async () => {
    if (nodeId) {
      try {
        setScanner((_scanner) => ({
          ..._scanner,
          loading: true,
          loadingStake: true,
          error: false
        }));
        const scannerData = await getScanner(nodeId);
        setScanner((_scanner) => ({
          ..._scanner,
          loadingStake: false,
          id: scannerData.scanner.id,
          chain_id: scannerData.scanner.chain_id,
          enabled: scannerData.scanner.enabled,
          pool_id: scannerData.scanner.pool_id,
          loading: false,
          sla: scannerData.sla
        }));
        setBots(scannerData.agents);
      } catch (error) {
        toast.error('Error trying to get scan node details');
        setScanner((_scanner) => ({
          ..._scanner,
          loading: false,
          loadingStake: false,
          error: true
        }));
      }
    }
    // eslint-disable-next-line
  }, [nodeId]);

  useEffect(() => {
    if (nodeId) updateScannerData();
    // eslint-disable-next-line
  }, [updateScannerData]);

  if (!nodeId) return <Redirect to={'/page-not-found'} />;

  return (
    <div
      className={classNames({
        ScanNodePage: true,
        'ScanNodePage--loading': scanner.loading,
        'ScanNodePage--error': scanner.error
      })}
    >
      <div className="ScanNodePage__header">
        <div className="ScanNodePage__scanner-info">
          <div className="ScanNodePage__scanner-name">
            <div>
              <InfoPopover
                content={`This scan node monitor transactions on ${network.label}`}
              >
                <ChainIcon network={network} />
              </InfoPopover>
            </div>
            <span className="ScanNodePage__scanner-name-label">
              Scan Node {shortenHash(scanner.id)}
            </span>
          </div>
          <div className="ScanNodePage__scanner-hash">
            {scanner.id} <CopyButton text={scanner.id} />
          </div>
        </div>
      </div>
      <div className="ScanNodePage__loader">
        <Loader />
        <div className="ScanNodePage__loader-text">
          loading scanner details...
        </div>
      </div>
      {!scanner.loading && !scanner.loadingStake ? (
        !scanner.error ? (
          <>
            <div className="ScanNodePage__overview">
              <InformationBox.Container>
                <InformationBox.Title>Information</InformationBox.Title>
                <InformationBox.Group>
                  <InformationBox.Item>
                    <InformationBox.Label>Scan node pool</InformationBox.Label>
                    <InformationBox.Value>
                      <NavLink to={`/nodePool/${scanner.pool_id}`}>
                        ID {formatPoolId(scanner.pool_id)}
                      </NavLink>
                    </InformationBox.Value>
                  </InformationBox.Item>
                  <InformationBox.Item>
                    <InformationBox.Label>
                      Status
                      <InfoPopover
                        content={'Operational status of scan node.'}
                        className="InfoPopover--marginLeft"
                      />
                    </InformationBox.Label>
                    <InformationBox.Value>
                      {scanner.enabled ? (
                        <span className="ScanNodePage__stats-operational">
                          Operational
                        </span>
                      ) : (
                        <span className="ScanNodePage__stats-nonoperational">
                          Non-operational
                        </span>
                      )}
                    </InformationBox.Value>
                  </InformationBox.Item>
                  <InformationBox.Item>
                    <InformationBox.Label className="InformationBox__item-label--alignItems">
                      Uptime
                      <InfoPopover
                        content={
                          'The rate of online hours over the past 168hr period divided by 168 (current hour not included).'
                        }
                        className="InfoPopover--marginLeft"
                      />
                    </InformationBox.Label>
                    <InformationBox.Value>
                      <TableSubCellWithHealthIndicator
                        value={setSubCellValue(nodeId, data, 'uptime' as const)}
                        className="TableSubCell--noPadding"
                        type="uptime"
                        isLoading={isLoading}
                      />
                    </InformationBox.Value>
                  </InformationBox.Item>
                  <InformationBox.Item>
                    <InformationBox.Label className="InformationBox__item-label--alignItems">
                      SLA
                      <InfoPopover
                        content={
                          'Average scanner SLA score during the online hours over the past 168hr period (current hour not included).'
                        }
                        className="InfoPopover--marginLeft"
                      />
                    </InformationBox.Label>
                    <InformationBox.Value>
                      <TableSubCellWithHealthIndicator
                        value={setSubCellValue(nodeId, data, 'sla' as const)}
                        className="TableSubCell--noPadding"
                        type="sla"
                        isLoading={isLoading}
                      />
                    </InformationBox.Value>
                  </InformationBox.Item>
                  <InformationBox.Item>
                    <InformationBox.Label>Assigned Bots</InformationBox.Label>
                    <InformationBox.Value>{bots.length}</InformationBox.Value>
                  </InformationBox.Item>
                </InformationBox.Group>
              </InformationBox.Container>
            </div>
            <ScanNodeLists bots={bots} scanner={scanner} />
          </>
        ) : (
          'An error occured'
        )
      ) : null}
    </div>
  );
}
