import { useEffect, useState } from 'react';

import { CrossIcon, SearchIcon } from 'common/components/Icons';
import Input from 'common/components/Input';

interface FilterParams {
  onChange: (value: string) => void;
  selection: string;
  placeholder: string;
  name: string;
  validation?: (value: string) => boolean;
}

export default function TextFilter(params: FilterParams): JSX.Element {
  const { onChange, selection, validation, placeholder, name } = params;
  const [inputValue, setInputValue] = useState(selection);
  const [error, setError] = useState(false);

  useEffect(() => {
    setInputValue(selection);
  }, [selection]);

  const validateAndSetValue = (): void => {
    if (inputValue === '') {
      setError(false);
      onChange(inputValue);
    } else {
      const isValid = validation ? validation(inputValue) : true;
      setError(!isValid);
      if (isValid) {
        onChange(inputValue);
      }
    }
  };

  const handleKeyDown = (key: string): void => {
    if (key === 'Enter') {
      validateAndSetValue();
    }
  };

  function clear(): void {
    onChange('');
    setInputValue('');
  }

  const showClearButton = selection.length > 0 && selection === inputValue;

  const icon = showClearButton ? CrossIcon : SearchIcon;
  const iconAction = showClearButton ? clear : validateAndSetValue;

  return (
    <Input
      placeholder={placeholder}
      onChange={(value) => setInputValue(value)}
      onKeyDown={(key) => handleKeyDown(key)}
      onBlur={() => validateAndSetValue()}
      value={inputValue}
      name={name}
      errored={error}
      icon={icon}
      onIconClick={iconAction}
    />
  );
}
