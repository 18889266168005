import React from 'react';
import cn from 'classnames';

import './EntityBadge.scss';
import { LabelEntityType } from '../../../enums';
import { capitalize } from '../../../lib/utils';

type EntityBadgeProps = {
  entity: LabelEntityType;
  className?: string;
};

function EntityBadge(props: EntityBadgeProps): JSX.Element {
  const { className } = props;

  const entity =
    props.entity === LabelEntityType.UNKNOWN_ENTITY_TYPE
      ? LabelEntityType.Unknown
      : props.entity;

  return (
    <div
      title={entity}
      className={cn('EntityBadge', className, {
        [`EntityBadge--${entity.toLowerCase()}`]: entity
      })}
    >
      <span className="EntityBadge__name">
        {entity === LabelEntityType.Url ? 'URL' : capitalize(entity)}
      </span>
    </div>
  );
}

export default EntityBadge;
