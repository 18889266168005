import useGraphQuery, { GraphQueryResult } from 'common/hooks/useGraphQuery';
import { ApolloClientName } from 'common/components/ApolloClientProvider';
import {
  AgentMetricsResult,
  AgentScannerMetric,
  GET_METRICS_QUERY
} from 'common/lib/apis/metricsAPI';
import { MetricKey } from '../../forta-app/data/metrics';

type UseBotMetricsParams = {
  agentId: string;
  timeFrame: string;
  chainId: number;
  metricKey: MetricKey;
};

type BotMetricsRequestParams = {
  getAgentMetricsInput: UseBotMetricsParams;
};

type BotMetricsResponse = {
  getAgentMetrics: AgentMetricsResult;
};

function useBotMetrics(opts: {
  params: UseBotMetricsParams;
  enabled?: boolean;
}): GraphQueryResult<BotMetricsRequestParams, BotMetricsResponse> & {
  scanners: AgentScannerMetric[];
} {
  const { enabled, params } = opts || {};

  const queryResult = useGraphQuery<
    BotMetricsRequestParams,
    BotMetricsResponse
  >({
    enabled,
    clientName: ApolloClientName.Forta,
    query: GET_METRICS_QUERY,
    variables: { getAgentMetricsInput: params }
  });

  const scanners = queryResult.data?.getAgentMetrics?.scanners || [];

  return { ...queryResult, scanners };
}

export default useBotMetrics;
