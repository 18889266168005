import React, { useRef, useState } from 'react';
import cn from 'classnames';

import './ActionCell.scss';

import Button from 'common/components-v2/Button/Button';
import Popover from 'common/components-v2/Popover/Popover';
import Menu from 'common/components-v2//Menu/Menu';
import { ActionIcon } from 'common/components/Icons';

export type Option = {
  label: string;
  disabled?: boolean;
  onClick: () => void;
};

export type ActionCellProps = {
  id?: string;
  options?: Option[];
  disabled?: boolean;
  preferredWidth?: number;
  className?: string;
};

function ActionCell(props: ActionCellProps): JSX.Element {
  const { options = [], preferredWidth, disabled, className } = props;

  const buttonRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={cn('ActionCell', className)}>
      <div ref={containerRef} className="ActionCell__container">
        <Button
          ref={buttonRef}
          size="sm"
          variant="default"
          icon={ActionIcon}
          onClick={() => setIsExpanded(!isExpanded)}
          className="ActionCell__button"
          disabled={disabled}
        />
        <Popover
          strategy="fixed"
          preferredWidth={preferredWidth}
          open={isExpanded}
          anchorElRef={buttonRef}
          containerElRef={containerRef}
          onClose={() => setIsExpanded(false)}
        >
          <Menu
            className="ActionCell__menu"
            onClose={() => setIsExpanded(false)}
          >
            {options.map((option) => (
              <Menu.Item
                key={option.label}
                onClick={option.onClick}
                disabled={option.disabled}
              >
                {option.label}
              </Menu.Item>
            ))}
          </Menu>
        </Popover>
      </div>
    </div>
  );
}

export default ActionCell;
