import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { UnmountClosed } from 'react-collapse';
import cn from 'classnames';

import './TableExpandableRow.scss';

import { keyDownA11y } from '../utils';
import { ArrowDownIcon } from '../../components/Icons';

import TableSubHeading from './TableSubHeading';
import TableSubCell from './TableSubCell';

type TableExpandableRowProps = {
  children: ReactNode;
  size: 'md' | 'lg';
  expanded?: boolean;
  clickable?: boolean;
  content: ReactNode;
  className?: string;
  style?: Record<string, string | number | undefined>;
  styles?: {
    Row?: string;
  };
};

const TableExpandableRowContext = React.createContext({
  expanded: false
});

function TableExpandableRow(props: TableExpandableRowProps): JSX.Element {
  const {
    children,
    expanded,
    size = 'md',
    clickable = false,
    content,
    style,
    styles = {},
    className
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (expanded != null) {
      setIsExpanded(expanded);
    }
  }, [expanded]);

  function handleExpandToggle(): void {
    if (!clickable) return;
    setIsExpanded(!isExpanded);
  }

  const context = {
    expanded: isExpanded
  };

  return (
    <TableExpandableRowContext.Provider value={context}>
      <div
        style={style}
        className={cn('TableExpandableRow', className, {
          ['TableExpandableRow--expanded']: isExpanded,
          ['TableExpandableRow--clickable']: clickable,
          [`TableExpandableRow--${size}`]: size
        })}
      >
        <div
          role={clickable ? 'button' : undefined}
          tabIndex={clickable ? 0 : undefined}
          className={cn('TableExpandableRow__row', styles.Row)}
          onClick={handleExpandToggle}
          onKeyDown={keyDownA11y(handleExpandToggle)}
        >
          {children}
        </div>
        <UnmountClosed
          isOpened={isExpanded}
          theme={{
            collapse: 'TableExpandableRow__collapse',
            content: 'TableExpandableRow__content'
          }}
        >
          {content}
        </UnmountClosed>
      </div>
    </TableExpandableRowContext.Provider>
  );
}

export function TableExpandSubCell(): JSX.Element {
  const { expanded } = useContext(TableExpandableRowContext);

  return (
    <TableSubCell
      value={
        <span
          className={cn('TableExpandSubCell__icon', {
            ['TableExpandSubCell__icon--expanded']: expanded
          })}
        >
          {ArrowDownIcon}
        </span>
      }
      className="TableExpandSubCell"
    />
  );
}

export function TableExpandSubHeading(): JSX.Element {
  return <TableSubHeading title="" className="TableExpandSubHeading" />;
}

export default TableExpandableRow;
