import { providers } from 'ethers';
import BigNumber from 'bignumber.js';
import Skeleton from 'react-loading-skeleton';

import React, { useCallback, useEffect, useState } from 'react';
import { parseEther } from 'ethers/lib/utils';

import './VaultWithdrawModal.scss';

import Modal from 'common/components/Modal';
import InformationBox from 'common/components/InformationBox';
import InfoPopover from 'common/components/InfoPopover';
import Input from 'common/components/Input';
import Button from 'common/components-v2/Button/Button';
import { formatFORT, safeFormatEther } from 'forta-app/lib/utils';
import useWallet from 'common/hooks/useWallet';
import { UseVaultResult } from 'common/hooks/useVault';
import ContractCallButton from 'forta-app/components/ContractCallButton';
import config from 'common/config';
import ls, { CONNECTION_TYPE } from 'common/lib/localStorage';
import {
  ConnectionType,
  switchNetwork
} from 'forta-app/components/wallet/connections';
import StakingVault from 'forta-app/lib/contract-interactors-2/staking-vault';
import { trackEvent } from '../../../../common/lib/analytics';

interface VaultWithdrawModalProps {
  open: boolean;
  vault: UseVaultResult;
  onClose: (tx?: providers.TransactionResponse) => void;
}

function VaultWithdrawModal(props: VaultWithdrawModalProps): JSX.Element {
  const { open, vault, onClose } = props;

  const { address: account, web3React } = useWallet();

  const userAssets = vault.userAssets || new BigNumber(0);
  const userPendingAssets = vault.userPendingAssets || new BigNumber(0);
  const [claimableAssets, setClaimableAssets] = useState<BigNumber>();
  const [toInactiveModalOpened, setToInactiveModalOpened] = useState(false);
  const [toInactiveInputValue, setToInactiveInputValue] = useState('');

  const getSignedVaultContract =
    useCallback(async (): Promise<StakingVault> => {
      if (web3React.chainId !== config.chainId) {
        await switchNetwork(
          config.chainId,
          ls.getItem(CONNECTION_TYPE) as ConnectionType
        );
      }

      const provider = web3React.provider;
      const signer = web3React.provider?.getSigner();

      if (!signer || !provider) {
        throw new Error('Cannot get wallet signer or provider');
      }

      return new StakingVault({
        provider,
        signer
      });
    }, [web3React.chainId, web3React.provider]);

  const handleMoveToInactiveClick = useCallback(
    async function (): Promise<providers.TransactionResponse | null> {
      trackEvent(`vault_modal_initiate_withdraw_click`, {
        value: toInactiveInputValue
      });

      const vaultContract = await getSignedVaultContract();

      const valueWei = parseEther(toInactiveInputValue);
      const shares = await vaultContract.convertToShares(valueWei.toString());

      return vaultContract.redeem(shares.toString(10), account, account);
    },
    [account, toInactiveInputValue, getSignedVaultContract]
  );

  function handleMoveToInactiveDone(tx: providers.TransactionResponse): void {
    setToInactiveModalOpened(false);
    setToInactiveInputValue('');
    onClose(tx);
  }

  const handleWithdrawClick =
    useCallback(async (): Promise<providers.TransactionResponse | null> => {
      trackEvent(`vault_modal_claim_withdraw_click`, {
        value: claimableAssets?.toString(10) || '0'
      });

      const vaultContract = await getSignedVaultContract();

      return vaultContract.claimRedeem(account);
    }, [account, claimableAssets, getSignedVaultContract]);

  function handleWithdrawDone(tx: providers.TransactionResponse): void {
    onClose(tx);
  }

  useEffect(() => {
    if (!account || !open) return;

    (async () => {
      setClaimableAssets(undefined);
      const vaultContract = await getSignedVaultContract();
      const claimableAssets = await vaultContract.claimableAssets(account);
      setClaimableAssets(claimableAssets);
    })();
  }, [open, account, getSignedVaultContract]);

  return (
    <Modal
      opened={open}
      title="Withdraw FORT"
      className="WithdrawModal__container"
      onCloseModal={() => onClose()}
    >
      <div className="VaultWithdrawModal">
        <div className="VaultWithdrawModal__description">
          Initiating a withdrawal will withdraw a small portion of the
          withdrawal amount instantly, <br />
          the remaining portion is subject to a 10 day cool off period. <br />
          Return in 10 days to claim your remaining assets.
        </div>
        <InformationBox.Container className="VaultWithdrawModal__container">
          <InformationBox.Title className="VaultWithdrawModal__title">
            Withdraw deposited FORT
          </InformationBox.Title>
          <InformationBox.Group className="VaultWithdrawModal__body">
            <InformationBox.Item className="VaultWithdrawModal__item">
              <InformationBox.Label>Active deposit</InformationBox.Label>
              <InformationBox.Value>
                {formatFORT(userAssets)} FORT
                <InfoPopover
                  content={`Initiate withdrawal can only be performed when there is no Pending withdrawal or Claimable assets.`}
                />
              </InformationBox.Value>
              <InformationBox.Value>
                <Button
                  size="sm"
                  variant="primary"
                  disabled={
                    userAssets.isZero() ||
                    !userPendingAssets.isZero() ||
                    !claimableAssets?.isZero()
                  }
                  onClick={() => {
                    setToInactiveModalOpened(true);
                    setToInactiveInputValue('');
                  }}
                >
                  Initiate withdrawal
                </Button>
              </InformationBox.Value>
            </InformationBox.Item>
            <InformationBox.Item className="VaultWithdrawModal__item">
              <InformationBox.Label>Pending withdrawal</InformationBox.Label>
              <InformationBox.Value>
                {formatFORT(userPendingAssets)} FORT
                <InfoPopover
                  content={`${safeFormatEther(
                    userPendingAssets.toString(10) || '0'
                  )} FORT`}
                />
              </InformationBox.Value>
              <InformationBox.Value></InformationBox.Value>
            </InformationBox.Item>
            <InformationBox.Item className="VaultWithdrawModal__item">
              <InformationBox.Label>Claimable assets</InformationBox.Label>
              <InformationBox.Value>
                <div className="WithdrawModal__stake-amount">
                  {claimableAssets ? (
                    formatFORT(claimableAssets) + ' FORT'
                  ) : (
                    <Skeleton width={40} />
                  )}
                  <InfoPopover
                    content={`Assets that can be claimed now after being locked up for 10 days.`}
                  />
                </div>
              </InformationBox.Value>
              <InformationBox.Value>
                <ContractCallButton
                  waitForConfirmation
                  disabled={!claimableAssets || claimableAssets.isZero()}
                  onCall={handleWithdrawClick}
                  onCompleted={handleWithdrawDone}
                >
                  Claim assets
                </ContractCallButton>
              </InformationBox.Value>
            </InformationBox.Item>
          </InformationBox.Group>
        </InformationBox.Container>
        <Modal
          opened={toInactiveModalOpened}
          title="Move deposit to inactive"
          className="ToInactiveModal__container"
          onCloseModal={() => setToInactiveModalOpened(false)}
        >
          <div className="ToInactiveModal">
            <div className="ToInactiveModal__description">
              In order to withdraw your deposit, you need to move it to
              &quot;inactive&quot; first. After being locked for 10 days, you
              will be able to withdraw your tokens.
            </div>
            <div className="ToInactiveModal__input-group">
              <div className="ToInactiveModal__input">
                <Input
                  value={toInactiveInputValue}
                  placeholder="0.0"
                  pattern="^[0-9]*[.]?[0-9]*$"
                  name="fort-amount-stake"
                  onChange={(value: string) => setToInactiveInputValue(value)}
                />
                <div className="ToInactiveModal__amount-label">FORT</div>
              </div>
              <div className="ToInactiveModal__max">
                <button
                  onClick={() =>
                    setToInactiveInputValue(
                      safeFormatEther(userAssets?.toString() || '0')
                    )
                  }
                >
                  Max
                </button>
              </div>
            </div>
            <div className="ToInactiveModal__action">
              <ContractCallButton
                waitForConfirmation
                disabled={!Number(toInactiveInputValue)}
                onCall={handleMoveToInactiveClick}
                onCompleted={handleMoveToInactiveDone}
              >
                Initiate Withdrawal
              </ContractCallButton>
            </div>
          </div>
        </Modal>
      </div>
    </Modal>
  );
}

export default VaultWithdrawModal;
