import { gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';

export interface AlertsPageInfo {
  timestamp: string;
  id: string;
}

export interface AlertListApiResponse {
  alerts: Alert[];
  nextPageValues: AlertsPageInfo;
  currentPageValues: AlertsPageInfo;
}

export interface AlertsApiParams {
  severity?: string[];
  txHash?: string;
  hash?: string;
  text?: string;
  muted?: string[];
  sort: string;
  limit: number;
  startDate?: string;
  chain_id?: number;
  alert_ids?: string[];
  endDate?: string;
  addresses?: string[];
  agents?: string[];
  project?: string;
  pageValues?: {
    timestamp: string;
    id: string;
  };
}

interface SeverityInfo {
  label: string;
  color: string;
}

export const SeveritiesInfo: { [key: string]: SeverityInfo } = {
  CRITICAL: {
    label: 'Critical',
    color: '#CE00E8'
  },
  HIGH: {
    label: 'High',
    color: '#B91C1C'
  },
  MEDIUM: {
    label: 'Medium',
    color: '#D97706'
  },
  LOW: {
    label: 'Low',
    color: '#059669'
  },
  INFO: {
    label: 'Info',
    color: '#2563EB'
  },
  UNKNOWN: {
    label: 'Unknown',
    color: '#666666'
  }
};

export const Severities = [...Object.keys(SeveritiesInfo)] as const;
export type Severity = typeof Severities[number];

export interface Block {
  chain_id: number;
  number: number;
  timestamp: string;
}

export interface Agent {
  id: string;
  image: string;
  imageHash: string;
  developer: string;
}

export enum AlertDocumentType {
  BLOCK = 'BLOCK',
  TRANSACTION = 'TRANSACTION',
  COMBINATION = 'COMBINATION',
  API = 'API'
}

export interface Alert {
  hash: string;
  description: string;
  network: string;
  chain_id: number;
  severity: string;
  protocol: string;
  name: string;
  everest_id: string;
  alert_id: string;
  alert_document_type: AlertDocumentType;
  scanner_count: number;
  addresses: string[];
  type: string;
  alert_timestamp: string;
  metadata?: string;
  detected_at: string;
  source: {
    tx_hash: string;
    agent: Agent;
    block: Block;
    source_alert: {
      hash: string;
      timestamp: string;
      bot_id: string;
    };
    transactions?: {
      chainId: number;
      hash: string;
    }[];
    chains?: {
      chainId: number;
    }[];
    externalLinks?: {
      source: 'twitter';
      path: string;
    }[];
  };
  contracts?: {
    address: string;
    name: string;
  }[];
  projects?: {
    id: string;
    name: string;
  }[];
}

export interface Signature {
  signature: string;
  algorithm: string;
}

export interface APIResponse {
  alerts: Alert[];
}

export interface APIRequest {
  limit?: number;
  startDate?: string;
  endDate?: string;
  severity?: string;
}

export interface ScannerAlertsPagination {
  timestamp: number;
  tx_hash: string;
}

export interface ScannerAlert {
  scanner: string;
  alert_timestamp: string;
  metadata: string;
  batch: {
    tx_hash: string;
    timestamp: string;
    reference: string;
    block: {
      chain_id: number;
    };
  };
  signature: {
    signature?: string;
    algorithm?: string;
    signer?: string;
  };
}

export interface ScannerAlertsAPIResult {
  scannerAlerts: ScannerAlert[];
  nextPageValues: ScannerAlertsPagination;
  currentPageValues: ScannerAlertsPagination;
}

export interface ScannerAlertsApiParams {
  hash: string;
  pageValues?: {
    timestamp: string;
    tx_hash: string;
  };
}

export interface ScannerStatsApiParams {
  startDate?: string;
  endDate?: string;
}

export interface AlertStatsAPIParams extends AlertsApiParams {
  aggregations?: {
    severity?: boolean;
    alerts?: number;
    agents?: number;
    interval?: string;
    cardinalities?: boolean;
  };
}

interface AggregationResult {
  key: string;
  doc_count: number;
}

export interface Aggregations {
  severity?: AggregationResult[];
  alerts?: AggregationResult[];
  agents?: AggregationResult[];
  interval?: AggregationResult[];
  cardinalities?: {
    alerts: number;
    agents: number;
  };
}

export async function getAlertStats(
  params: AlertStatsAPIParams
): Promise<Aggregations> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getListInput: params
    },
    query: gql`
      query Retrive($getListInput: GetAlertsInput) {
        getList(input: $getListInput) {
          aggregations {
            severity {
              key
              doc_count
            }
            alerts {
              key
              doc_count
            }
            agents {
              key
              doc_count
            }
            interval {
              key
              doc_count
            }
            cardinalities {
              agents
              alerts
            }
          }
        }
      }
    `
  });
  const aggregations: Aggregations = apiResult.data.getList.aggregations;

  return aggregations;
}

export async function getAlerts(
  params: AlertsApiParams
): Promise<AlertListApiResponse> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getListInput: params
    },
    query: gql`
      query Retrive($getListInput: GetAlertsInput) {
        getList(input: $getListInput) {
          alerts {
            hash
            chain_id
            description
            severity
            protocol
            name
            everest_id
            alert_id
            scanner_count
            alert_document_type
            alert_timestamp
            source {
              tx_hash
              agent {
                id
                name
              }
              block {
                chain_id
                number
                timestamp
              }
              source_alert {
                bot_id
                hash
                timestamp
              }
            }
            projects {
              id
              name
            }
          }
          nextPageValues {
            timestamp
            id
          }
          currentPageValues {
            timestamp
            id
          }
        }
      }
    `
  });
  const alerts: Alert[] = apiResult.data.getList.alerts;
  const nextPageValues = apiResult.data.getList.nextPageValues;
  const currentPageValues = apiResult.data.getList.currentPageValues;

  return {
    alerts: Array.from(alerts),
    nextPageValues,
    currentPageValues
  };
}

export async function getAlert(hash: string): Promise<Alert> {
  const client = getExplorerAPIClient();

  const apiResult = await client.query({
    variables: {
      getAlertInput: {
        hash
      }
    },
    query: gql`
      query Retrive($getAlertInput: GetAlertInput) {
        getAlert(input: $getAlertInput) {
          hash
          chain_id
          description
          severity
          protocol
          name
          everest_id
          alert_id
          alert_document_type
          scanner_count
          addresses
          alert_timestamp
          detected_at
          metadata
          source {
            tx_hash
            agent {
              id
              name
            }
            block {
              chain_id
              number
              timestamp
            }
            source_alert {
              bot_id
              hash
              timestamp
            }
            transactions {
              chainId
              hash
            }
            chains {
              chainId
            }
            externalLinks {
              source
              path
            }
          }
          contracts {
            address
            name
          }
          projects {
            id
            name
          }
        }
      }
    `
  });

  const alert: Alert = apiResult.data.getAlert;

  return alert;
}

export async function getScannerAlerts(
  params: ScannerAlertsApiParams
): Promise<ScannerAlertsAPIResult> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getScannerAlertsInput: params
    },
    query: gql`
      query Retrive($getScannerAlertsInput: GetScannerAlertsInput) {
        getScannerAlert(input: $getScannerAlertsInput) {
          scannerAlerts {
            scanner
            alert_timestamp
            metadata
            batch {
              tx_hash
              timestamp
              reference
              block {
                chain_id
              }
            }
            signature {
              signature
              algorithm
              signer
            }
          }
          nextPageValues {
            timestamp
            tx_hash
          }
          currentPageValues {
            timestamp
            tx_hash
          }
        }
      }
    `
  });
  const scannerAlerts: ScannerAlert[] =
    apiResult.data.getScannerAlert.scannerAlerts;
  const nextPageValues = apiResult.data.getScannerAlert.nextPageValues;
  const currentPageValues = apiResult.data.getScannerAlert.currentPageValues;

  return {
    scannerAlerts: Array.from(scannerAlerts),
    nextPageValues,
    currentPageValues
  };
}

export async function getScannerStats(
  params: ScannerStatsApiParams
): Promise<{ scanners: number }> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getScannerStatsInput: params
    },
    query: gql`
      query Retrive($getScannerStatsInput: GetScannerStatsInput) {
        getScannerStats(input: $getScannerStatsInput) {
          scanners
        }
      }
    `
  });

  return {
    scanners: apiResult.data.getScannerStats.scanners
  };
}

export async function getImportantAlerts(params: {
  limit?: number;
  startDate: string;
  endDate: string;
  chain_id?: number;
}): Promise<{ alerts: Alert[] }> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getImportantAlertsInput: params
    },
    query: gql`
      query Retrive($getImportantAlertsInput: GetImportantAlertsInput) {
        getImportantAlerts(input: $getImportantAlertsInput) {
          alerts {
            hash
            chain_id
            description
            severity
            protocol
            name
            everest_id
            alert_id
            scanner_count
            alert_document_type
            alert_timestamp
            source {
              tx_hash
              agent {
                id
                name
              }
              block {
                chain_id
                number
                timestamp
              }
              source_alert {
                bot_id
                hash
                timestamp
              }
            }
            projects {
              id
              name
            }
          }
        }
      }
    `
  });
  const alerts: Alert[] = apiResult.data.getImportantAlerts.alerts;

  return {
    alerts: Array.from(alerts)
  };
}

export const getTimestampFromAlert = (alert: Alert): string => {
  if (alert.detected_at) return alert.detected_at;

  // fallback to the previous logic

  const alertType = alert.alert_document_type;

  if (alertType) {
    if (alertType === AlertDocumentType.COMBINATION) {
      return alert?.source?.source_alert?.timestamp;
    }
    if (alertType === 'API') {
      return alert?.alert_timestamp;
    }
  }

  return alert?.source?.block?.timestamp;
};
