import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useWeb3React } from '@web3-react/core';

import './NotificationList.scss';

import { useAppDispatch, useAppSelector, useJWT } from 'forta-app/app/hooks';
import Loader from 'common/components/Loader';
import { Notification } from 'forta-app/lib/apis/notificationAPI';
import {
  getModalFilterFromState,
  retrieveNotifications,
  setModalState
} from 'forta-app/slices/notificationSlice';
import { getTelegramParamValues } from './EndpointSelector';
import PlanLabel from 'common/components-v2/PlanLabel/PlanLabel';
import useUnlockMembershipsQuery from 'common/hooks/useUnlockMembershipsQuery';
import { Dots } from 'common/components/Icons';
import { RenewalStatusCell } from 'forta-app/pages/profile/AccountPage';
import { shortenHash } from 'common/lib/utils';
import CopyButton from '../../../common/components/CopyButton';
import Button from 'common/components-v2/Button/Button';
import Menu from '../../../common/components-v2/Menu/Menu';
import Popover from '../../../common/components-v2/Popover/Popover';
import { useDeleteNotificationModal } from 'forta-app/components/notifications/DeleteNotification';
import {
  daysToRenewPlan,
  getPlanStatusBySubscriptionType,
  getUnlockKeyBySubscriptionType,
  PlanStatus
} from 'forta-app/data/plans';

export default function NotificationList(): JSX.Element {
  const dispatch = useAppDispatch();
  const jwt = useJWT();
  const web3React = useWeb3React();
  const notifications = useAppSelector(
    (state) => state.notification.notifications
  );
  const loading = useAppSelector(
    (state) => state.notification.notificationsLoading
  );
  const botGroups = useAppSelector((state) => state.notification.botGroups);
  const { open: openDeleteNotificationModal } = useDeleteNotificationModal();

  const { keys } = useUnlockMembershipsQuery({
    params: {
      owner: web3React.account || ''
    }
  });

  useEffect(() => {
    if (web3React.isActive && jwt) {
      dispatch(retrieveNotifications({ jwt }));
    }
  }, [web3React.isActive, web3React.account, jwt, dispatch]);

  const onEditNotificationClick = (notification: Notification): void => {
    dispatch(
      setModalState({
        id: notification.id,
        opened: true,
        loading: false,
        notificationType:
          notification.source.type === 'AGENT'
            ? 'BOT'
            : notification.groupIds?.length
            ? 'KIT'
            : 'ADDRESS',
        filter: getModalFilterFromState(notification),
        groupId: notification.groupIds?.length ? notification.groupIds[0] : '',
        sources: [notification.source.value],
        endpointType: notification.endpoint.type,
        endpoint: notification.endpoint.value
      })
    );
  };

  const onRemoveNotificationClick = useCallback(
    (notification: Notification): void => {
      openDeleteNotificationModal(notification.id);
    },
    [openDeleteNotificationModal]
  );

  const SubscriptionActions = ({
    notification
  }: {
    notification: Notification;
  }): JSX.Element => {
    const buttonRef = useRef(null);
    const containerRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    return (
      <div ref={containerRef} className="NotificationMenu">
        <Button
          ref={buttonRef}
          variant="secondary"
          size="md"
          icon={Dots}
          className="PlanStatusActions__dots"
          onClick={() => setIsExpanded(!isExpanded)}
        />
        <Popover
          strategy="fixed"
          preferredWidth={160}
          open={isExpanded}
          anchorElRef={buttonRef}
          containerElRef={containerRef}
          onClose={() => setIsExpanded(false)}
        >
          <Menu onClose={() => setIsExpanded(false)}>
            <Menu.Item onClick={() => onEditNotificationClick(notification)}>
              Edit
            </Menu.Item>
            <Menu.Item
              onClick={() => onRemoveNotificationClick(notification)}
              className="NotificationMenu__unsubscribe-button"
            >
              Unsubscribe
            </Menu.Item>
          </Menu>
        </Popover>
      </div>
    );
  };

  const getBadgeClass = (value: string): string => {
    return classNames({
      NotificationBadge: true,
      'NotificationBadge--slack': value === 'SLACK',
      'NotificationBadge--telegram': value === 'TELEGRAM',
      'NotificationBadge--discord': value === 'DISCORD',
      'NotificationBadge--email': value === 'EMAIL',
      'NotificationBadge--webhook': value === 'WEBHOOK'
    });
  };

  let content = null;

  if (!web3React.isActive || !jwt) {
    content = (
      <div className="NotificationList NotificationList--expired">
        Please sign in to subscribe to notifications on alerts.
      </div>
    );
  } else if (loading) {
    content = (
      <div className="NotificationList NotificationList--loading">
        <Loader />
      </div>
    );
  } else {
    content = (
      <div className="NotificationList__list">
        <div className="NotificationList__head">
          <div className="NotificationList__head-source">Source</div>
          <div className="NotificationList__head-endpoint">Endpoint</div>
          <div className="NotificationList__head-plan">Plan</div>
          <div className="NotificationList__head-renew-date">
            Days to renewal day
          </div>
          <div className="NotificationList__head-renewal-status">
            Renewal Status
          </div>
          <div className="NotificationList__head-actions"></div>
        </div>
        <div className="NotificationList__body">
          {notifications.map((notification) => {
            const planStatus = getPlanStatusBySubscriptionType(
              keys,
              notification.source.subscription_type
            );
            const unlockKey = getUnlockKeyBySubscriptionType(
              keys,
              notification.source.subscription_type
            );
            return (
              <div
                className="NotificationList__notification"
                key={`notification-${notification.id}`}
              >
                <div className="NotificationList__notification-source">
                  <div className={getBadgeClass(notification.source.type)}>
                    {notification.source.type.toLowerCase()}
                  </div>
                  <div>
                    <div className="NotificationList__notification-source-name">
                      {notification.source.name ||
                        (notification.groupIds?.length
                          ? botGroups.find(
                              (group) =>
                                group.id ===
                                ((notification.groupIds &&
                                  notification.groupIds[0]) ||
                                  '')
                            )?.name || notification.groupIds.join(', ')
                          : '')}
                    </div>
                    <div className="NotificationList__notification-source-value">
                      <CopyButton text={notification.source.value}>
                        {shortenHash(notification.source.value, 6)}
                      </CopyButton>
                    </div>
                  </div>
                </div>
                <div className="NotificationList__notification-endpoint">
                  <div className={getBadgeClass(notification.endpoint.type)}>
                    {notification.endpoint.type.toLowerCase()}
                  </div>
                  {notification.endpoint.type === 'TELEGRAM'
                    ? (function () {
                        const telegramParams = getTelegramParamValues(
                          notification.endpoint.value
                        );
                        return `Chat ID: ${telegramParams[1]}`;
                      })()
                    : notification.endpoint.value}
                </div>
                <div className="NotificationList__notification-plan">
                  {notification.source.type === 'AGENT' && (
                    <PlanLabel
                      planType={notification.source.subscription_type}
                    />
                  )}
                </div>
                <div className="NotificationList__notification-renew-date">
                  {notification.source.type === 'AGENT' ? (
                    planStatus === PlanStatus.SUBSCRIBED && unlockKey ? (
                      daysToRenewPlan(unlockKey)
                    ) : planStatus === PlanStatus.EXPIRED ? (
                      <span className="NotificationList__notification-renew-date--expired">
                        Expired
                      </span>
                    ) : (
                      <Button
                        round
                        size="sm"
                        variant="primary"
                        to={`/profile/${web3React.account}/account`}
                        className="NotificationList__notification-renew-date--buy-plan"
                      >
                        Buy Plan
                      </Button>
                    )
                  ) : null}
                </div>
                <div className="NotificationList__notification-renewal-status">
                  {notification.source.type === 'AGENT' && unlockKey && (
                    <RenewalStatusCell unlockKey={unlockKey} />
                  )}
                </div>
                <div className="NotificationList__notification-actions">
                  <SubscriptionActions notification={notification} />
                </div>
              </div>
            );
          })}
          {!notifications.length ? (
            <div className="NotificationList__empty">No subscriptions.</div>
          ) : null}
        </div>
      </div>
    );
  }

  return <div className="NotificationList">{content}</div>;
}
