import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import { TransactionResponse } from '@ethersproject/providers';

import './BotDeploymentPreview.scss';

// import { addPendingAgent } from 'forta-app/lib/pendingAgents';
import Loader from 'common/components/Loader';
import AgentRegistry from 'forta-app/lib/contract-interactors/agentRegistry';
import {
  BotFormData,
  DocumentationIpfsItem,
  DocumentationItem,
  isDocumentationFileItem,
  isDocumentationIpfsItem,
  Manifest
} from 'forta-app/components/agent-management/BotEditForm';
import {
  uploadAgentManifest,
  uploadDocumentation
} from 'forta-app/lib/apis/agentUpload';
import { getNetworkByChainId } from 'common/lib/networks';
import Button from 'common/components-v2/Button/Button';
import { BotType } from 'common/enums';

type BotDeploymentPreviewProps = {
  formData: BotFormData;
  isUpdate: boolean;
  onClose: () => void;
  onDeploy?: (date: number, tx: TransactionResponse) => void;
};

export default function BotDeploymentPreview(
  props: BotDeploymentPreviewProps
): JSX.Element {
  const { formData, isUpdate, onClose, onDeploy } = props;

  const web3React = useWeb3React();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>(
    'uploading manifest to IPFS...'
  );

  async function prepareDocumentation(
    documentation: DocumentationItem[]
  ): Promise<DocumentationIpfsItem[]> {
    const ipfsItems: DocumentationIpfsItem[] = [];
    for (const item of documentation) {
      if (isDocumentationFileItem(item)) {
        if (!item.file) throw new Error('Empty file');

        const { ipfsHash } = await uploadDocumentation({
          file: item.file
        });

        ipfsItems.push({
          title: item.title,
          ipfsHash: ipfsHash
        });
      } else {
        ipfsItems.push(item);
      }
    }

    return ipfsItems;
  }

  async function prepareManifest(
    documentation: DocumentationIpfsItem[]
  ): Promise<{ signature: string; manifest: Manifest }> {
    let chainIds;
    const selectedNetworks = formData.selectedNetworks;
    const networksToShard = formData.networksToShard;
    if (formData.botType === BotType.V1) {
      chainIds = selectedNetworks.sort((a, b) => a - b);
    } else if (formData.botType === BotType.V2) {
      chainIds = Array.from(
        new Set(
          networksToShard
            .split(',')
            .map((id) => parseInt(id))
            .filter((id) => id === id)
        )
      ).sort((a, b) => a - b);
    } else {
      chainIds = [1];
    }
    const manifest: Manifest = {
      agentId: formData.name,
      agentIdHash: formData.id,
      name: formData.name,
      description: formData.description,
      longDescription: formData.longDescription,
      promoUrl: formData.promoUrl,
      licenseUrl: formData.licenseUrl,
      version: formData.version,
      from: web3React.account || '',
      timestamp: new Date().toUTCString(),
      imageReference:
        formData.botType !== BotType.External ? formData.image : '',
      documentation: JSON.stringify(documentation),
      repository: formData.repository,
      projects: formData.projects,
      chainIds,
      publishedFrom: `Forta App 0.2.0`,
      external: formData.botType === BotType.External,
      protocolVersion: formData.botType === BotType.V2 ? 2 : 0
    };

    const manifestBody = JSON.stringify(manifest);
    const manifestSignature =
      (await web3React.provider?.getSigner().signMessage(manifestBody)) || '';

    return {
      manifest: manifest,
      signature: manifestSignature
    };
  }

  const handleDeploy = async (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    try {
      setLoading(true);

      const documentationItems = await prepareDocumentation(
        formData.documentationItems
      );
      const { manifest, signature } = await prepareManifest(documentationItems);
      const { ipfsHash: manifestIpfsHash } = await uploadAgentManifest({
        manifest: manifest,
        signature: signature
      });

      const agentRegistry = new AgentRegistry(web3React);

      const date = Date.now();
      const tx = !isUpdate
        ? await agentRegistry.createAgent(
            formData.id,
            manifestIpfsHash,
            manifest.chainIds.length ? manifest.chainIds.sort() : [1]
          )
        : await agentRegistry.updateAgent(
            formData.id,
            manifestIpfsHash,
            manifest.chainIds.length ? manifest.chainIds.sort() : [1]
          );

      // if (!isUpdate) {
      //   addPendingAgent({
      //     agentId: formData.id,
      //     address: web3React.account || '',
      //     name: formData.name
      //   });
      // }

      setLoading(true);
      setLoadingText(
        'Deploying detection bot to Forta. Waiting for one block confirmation...'
      );
      if (onDeploy) {
        onDeploy(date, tx);
      }

      await tx.wait(1);
      toast.success(
        `Bot '${formData.name}' was ${isUpdate ? 'updated' : 'deployed'}`
      );
    } catch (error) {
      if (error?.code === 4001) {
        toast.warn('Transaction was rejected by the user');
      } else {
        toast.error(
          `Error trying to ${isUpdate ? 'update' : 'deploy'} the detection bot`
        );
        if (error?.code === -32603 || error?.message === 'Internal error') {
          toast.warn(`Your RPC endpoint may not be working correctly.`);
        }
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="AgentDeploymentPreview">
      <div className="AgentDeploymentPreview__container">
        <div className="AgentDeploymentPreview__list">
          <div className="AgentDeploymentPreview__item">
            <div className="AgentDeploymentPreview__item-label">Bot Type</div>
            <div className="AgentDeploymentPreview__item-value">
              {formData.botType}
            </div>
          </div>
          <div className="AgentDeploymentPreview__item">
            <div className="AgentDeploymentPreview__item-label">Bot Id</div>
            <div className="AgentDeploymentPreview__item-value">
              {formData.id}
            </div>
          </div>
          <div className="AgentDeploymentPreview__item">
            <div className="AgentDeploymentPreview__item-label">Bot name</div>
            <div className="AgentDeploymentPreview__item-value">
              {formData.name}
            </div>
          </div>
          {formData.description && (
            <div className="AgentDeploymentPreview__item">
              <div className="AgentDeploymentPreview__item-label">
                Short description
              </div>
              <div className="AgentDeploymentPreview__item-value">
                {formData.description}
              </div>
            </div>
          )}
          {formData.longDescription && (
            <div className="AgentDeploymentPreview__item">
              <div className="AgentDeploymentPreview__item-label">
                Long description
              </div>
              <div className="AgentDeploymentPreview__item-value">
                {formData.longDescription}
              </div>
            </div>
          )}
          {formData.botType !== BotType.External && (
            <div className="AgentDeploymentPreview__item">
              <div className="AgentDeploymentPreview__item-label">
                {formData.botType === BotType.V1
                  ? 'Blockchains'
                  : 'Blockchains to shard'}
              </div>
              <div className="AgentDeploymentPreview__item-value">
                {formData.botType === BotType.V1
                  ? formData.selectedNetworks
                      .map((chainId) => {
                        const network = getNetworkByChainId(chainId);
                        return network.label;
                      })
                      .join(', ')
                  : Array.from(
                      new Set(
                        formData.networksToShard
                          .split(',')
                          .map((id) => parseInt(id))
                          .filter((id) => id === id)
                      )
                    ).join(', ') || <i>None</i>}
              </div>
            </div>
          )}
          {formData.promoUrl && (
            <div className="AgentDeploymentPreview__item">
              <div className="AgentDeploymentPreview__item-label">
                Promo link
              </div>
              <div className="AgentDeploymentPreview__item-value">
                {formData.promoUrl}
              </div>
            </div>
          )}
          {formData.licenseUrl && (
            <div className="AgentDeploymentPreview__item">
              <div className="AgentDeploymentPreview__item-label">License</div>
              <div className="AgentDeploymentPreview__item-value">
                {formData.licenseUrl}
              </div>
            </div>
          )}
          <div className="AgentDeploymentPreview__item">
            <div className="AgentDeploymentPreview__item-label">Version</div>
            <div className="AgentDeploymentPreview__item-value">
              {formData.version}
            </div>
          </div>
          {formData.botType !== BotType.External && (
            <div className="AgentDeploymentPreview__item">
              <div className="AgentDeploymentPreview__item-label">
                Docker Image Hash
              </div>
              <div className="AgentDeploymentPreview__item-value">
                {formData.image}
              </div>
            </div>
          )}
          <div className="AgentDeploymentPreview__item">
            <div className="AgentDeploymentPreview__item-label">Repository</div>
            <div className="AgentDeploymentPreview__item-value">
              {formData.repository || <i>None</i>}
            </div>
          </div>
          <div className="AgentDeploymentPreview__item">
            <div className="AgentDeploymentPreview__item-label">
              Documentation
            </div>
            <div className="AgentDeploymentPreview__item-value">
              <ul className="AgentDeploymentPreview__documentation-list">
                {formData.documentationItems.map((item) => {
                  const source = isDocumentationIpfsItem(item)
                    ? item.ipfsHash
                    : item.file?.name;

                  return (
                    <li
                      key={source}
                      className="AgentDeploymentPreview__documentation-item"
                    >
                      {item.title}{' '}
                      <span className="AgentDeploymentPreview__documentation-source">
                        (
                        {isDocumentationIpfsItem(item) ? (
                          <a
                            href={`https://ipfs.forta.network/ipfs/${item.ipfsHash}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {`${item.ipfsHash.slice(0, 12)}...`}
                          </a>
                        ) : (
                          source
                        )}
                        )
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="AgentDeploymentPreview__actions">
          <Button
            testId="deployment-preview-cancel-button"
            variant="tertiary"
            size="sm"
            onClick={onClose}
            className="AgentDeploymentPreview__cancel-button"
          >
            Cancel
          </Button>
          <Button
            testId="deployment-preview-deploy-button"
            variant="primary"
            size="sm"
            onClick={handleDeploy}
            disabled={loading}
            className="AgentDeploymentPreview__submit-button"
          >
            Deploy Bot
          </Button>
        </div>
      </div>
      {loading && (
        <div className="AgentDeploymentPreview__loader">
          <div>
            <Loader />
            <div data-testid="bot-deployment-loading-text">{loadingText}</div>
          </div>
        </div>
      )}
    </div>
  );
}
