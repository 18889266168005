import React, { useLayoutEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import './BotMetrics.scss';

import Select from 'common/components-v2/Form/Select/Select';
import FancyContainer from 'common/components-v2/Markup/FancyContainer/FancyContainer';
import LineChart from 'common/components-v2/Chart/Line/LineChart';
import { ChartInterval } from 'common/components-v2/Chart/utils';
import { getNetworkByChainId, Network } from 'common/lib/networks';
import {
  getMetricInfo,
  MetricInterval,
  MetricKey,
  MetricMeasure,
  MetricMeasureLabels,
  metrics
} from 'forta-app/data/metrics';
import useBotScanners from 'common/hooks/useBotScanners';
import useBotMetrics from 'forta-app/hooks/useBotMetrics';
import useTimeSeries, {
  boundsFromNow
} from 'common/components-v2/Chart/useTimeSeries';
import { shortenHash } from 'common/lib/utils';
import { formatNumber } from 'forta-app/lib/utils';

dayjs.extend(utc);

const INTERVAL_DATA_MAP: Record<
  MetricInterval,
  {
    label: string;
    timeframe: ChartInterval;
    interval: ChartInterval;
    tooltipFormat: string;
  }
> = {
  [MetricInterval.Hour]: {
    label: 'Last 1h',
    timeframe: { value: 2, unit: 'minute' },
    interval: { value: 1, unit: 'hour' },
    tooltipFormat: 'MMM D h:mm A'
  },
  [MetricInterval.Day]: {
    label: 'Last 24h',
    timeframe: { value: 1, unit: 'hour' },
    interval: { value: 1, unit: 'day' },
    tooltipFormat: 'MMM D h:mm A'
  },
  [MetricInterval.Week]: {
    label: 'Last week',
    timeframe: { value: 1, unit: 'day' },
    interval: { value: 1, unit: 'week' },
    tooltipFormat: 'MMM D, YYYY'
  }
};

const INTERVAL_OPTIONS = Object.entries(INTERVAL_DATA_MAP).map(
  ([interval, data]) => ({
    label: data.label,
    value: interval as MetricInterval
  })
);

const METRIC_OPTIONS = metrics.flatMap((m) =>
  m.measures.map((measure) => ({
    label: `${m.label} (${MetricMeasureLabels[measure]})`,
    value: `${m.key}/${measure}`
  }))
);

type BotMetricsProps = {
  botId: string;
  loading?: boolean;
  chainIds: number[];
  className?: string;
};

function BotMetrics(props: BotMetricsProps): JSX.Element {
  const { botId, chainIds, loading: isBotLoading, className } = props;

  const [selectedChain, setSelectedChain] = useState<Network>(Network.Mainnet);
  const [selectedInterval, setSelectedInterval] = useState(MetricInterval.Day);
  const [selectedMetric, setSelectedMetric] = useState(METRIC_OPTIONS[0].value);

  // Set an available chain if the default one is not supported
  useLayoutEffect(() => {
    if (chainIds.length > 0 && !chainIds.includes(selectedChain)) {
      setSelectedChain(chainIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainIds]);

  const intervalData = INTERVAL_DATA_MAP[selectedInterval];
  const metricData = useMemo(
    () => ({
      key: selectedMetric.split('/')[0] as MetricKey,
      measure: selectedMetric.split('/')[1] as MetricMeasure
    }),
    [selectedMetric]
  );

  const {
    scanners,
    fetched: isMetricsFetched,
    loading: isMetricsLoading
  } = useBotMetrics({
    params: {
      agentId: botId,
      timeFrame: selectedInterval,
      metricKey: metricData.key,
      chainId: selectedChain
    },
    enabled: chainIds.length > 0 && !isBotLoading
  });

  const {
    scannersByChainId,
    loading: isScannersLoading,
    fetched: isScannersFetched
  } = useBotScanners(botId);

  const currentScanners = useMemo(
    () =>
      scannersByChainId ? scannersByChainId[selectedChain] || [] : undefined,
    [scannersByChainId, selectedChain]
  );

  const isEmptyState =
    isMetricsFetched &&
    currentScanners &&
    scanners.length === 0 &&
    currentScanners.length === 0;

  // Example format of the data records:
  // [{
  //   "timestamp": 1680307200,
  //   "0x5efB5fFaf66d803fb12258DBc7F0027B4E1C9938": BigNumber(1234),
  //   "0xB7E0953B970fBb1ABa91f3846F9489E92b3c0002": BigNumber(4321)
  // }];
  const { dataRecords, xAccessor, yAccessors } = useMemo(() => {
    const scannerKeys = [];
    const timestamps = new Set(
      scanners.map((s) => s.interval.map((i) => i.key)).flat()
    );

    // Display the scanners of the current network only if all the data has been downloaded
    if (isMetricsFetched) {
      scannerKeys.push(
        // Push unique scanners
        ...[
          ...new Set([
            ...(currentScanners || []).map((s) => s.toLowerCase()),
            ...scanners.map((s) => s.key.toLowerCase())
          ])
        ]
          // Make the actual scanners at the very bottom, thus overlapping the not actual ones
          .reverse()
      );
    }

    const sortedTimestamps = [...timestamps];
    sortedTimestamps.sort((a, b) => Number(a) - Number(b));

    const records: ({ [Property in typeof scannerKeys[number]]: BigNumber } & {
      timestamp: number;
    })[] = [];
    for (const timestamp of sortedTimestamps) {
      const record = {
        // normalize to unix format
        timestamp: Math.floor(Number(timestamp) / 1000)
      } as typeof records[number];
      for (const scanner of scanners) {
        record[scanner.key.toLowerCase()] = new BigNumber(
          scanner.interval.find((i) => i.key === timestamp)?.[
            metricData.measure
          ] || 0
        );
      }
      records.push(record);
    }

    return {
      xAccessor: 'timestamp' as const,
      yAccessors: scannerKeys,
      dataRecords: records
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanners, isMetricsFetched, isScannersFetched, metricData.measure]);

  const seriesData = useTimeSeries(dataRecords, {
    xAccessor: xAccessor,
    yAccessor: yAccessors,
    timeframe: intervalData.timeframe,
    bounds: boundsFromNow({
      interval: intervalData.interval,
      minInterval: intervalData.interval,
      timeframe: intervalData.timeframe
    }),
    deps: [dataRecords]
  });

  const chainOptions = useMemo(() => {
    const sortedChains = chainIds.slice();
    sortedChains.sort((a, b) => a - b);
    return sortedChains.map((c) => {
      const network = getNetworkByChainId(c);
      return {
        label: network.label,
        value: c
      };
    });
  }, [chainIds]);

  return (
    <div className={cn('BotMetrics', className)}>
      <FancyContainer variant="gradient">
        <div className="BotMetrics__header">
          <h3 className="BotMetrics__title">Detection Bot Health</h3>
          <Select
            name="chain"
            value={selectedInterval}
            options={INTERVAL_OPTIONS}
            clearable={false}
            searchable={false}
            onChange={(e) => setSelectedInterval(e.target.value)}
            className="BotMetrics__interval"
          />
        </div>
        <div className="BotMetrics__controls">
          <div className="BotMetrics__filters">
            <Select
              name="interval"
              value={selectedChain}
              options={chainOptions}
              placeholder={
                isMetricsLoading || isBotLoading
                  ? 'Loading chains...'
                  : 'Select chain'
              }
              disabled={chainIds.length === 0}
              clearable={false}
              searchable={false}
              onChange={(e) => setSelectedChain(e.target.value)}
              className="BotMetrics__chain"
            />
            <Select
              name="metric"
              value={selectedMetric}
              options={METRIC_OPTIONS}
              clearable={false}
              searchable={false}
              onChange={(e) => setSelectedMetric(e.target.value)}
              className="BotMetrics__metric"
            />
          </div>
          <div className="BotMetrics__description">
            {getMetricInfo(metricData.key).description}
          </div>
        </div>
        <FancyContainer.FullWidth>
          <LineChart
            type="time"
            data={seriesData}
            loading={
              !isMetricsFetched ||
              isMetricsLoading ||
              isScannersLoading ||
              chainIds.length === 0
            }
            empty={isEmptyState}
            emptyStateMessage={`No activity found in the ${intervalData.label.toLowerCase()}`}
            xAccessor="timestamp"
            lines={yAccessors.map((node) => {
              const shouldApplyShadowing =
                isScannersFetched && yAccessors.length > 0;
              const isOldNode =
                currentScanners && !currentScanners.includes(node);

              return {
                label: shortenHash(node),
                labelColor:
                  shouldApplyShadowing && isOldNode ? '#787281' : undefined,
                yAccessor: node,
                strokeColor:
                  shouldApplyShadowing && isOldNode ? '#3e3956' : undefined,
                formatter: (v) =>
                  `${formatNumber(v)}${
                    metricData.key.includes('latency') ? ' ms' : ''
                  }`
              };
            })}
            tooltipTitle={(v) =>
              dayjs.unix(Number(v)).format(intervalData.tooltipFormat)
            }
            tooltipSubTitle={(v) =>
              dayjs.unix(Number(v)).utc().format('YYYY-MM-DDTHH:mm:ss')
            }
          />
        </FancyContainer.FullWidth>
        {currentScanners &&
          isMetricsFetched &&
          scanners.find(
            (s) => !currentScanners?.includes(s.key.toLowerCase())
          ) && (
            <div className="LegendContainer">
              <div className="LegendItem">
                <div
                  className="LegendItem__shape LegendItem__shape--line"
                  style={{ background: '#6855fa' }}
                />
                <div className="LegendItem__label">
                  Nodes that are now assigned to this bot
                </div>
              </div>
              <div className="LegendItem">
                <div
                  className="LegendItem__shape LegendItem__shape--line"
                  style={{ background: '#787281' }}
                />
                <div className="LegendItem__label">
                  Nodes that were previously assigned to this bot
                </div>
              </div>
            </div>
          )}
      </FancyContainer>
    </div>
  );
}

export default BotMetrics;
