import React, { useEffect, useMemo, useState } from 'react';
import { BigNumber, providers } from 'ethers';

import './ProfileDelegations.scss';

import Button from 'common/components-v2/Button/Button';
import Loader from 'common/components/Loader';
import Link from 'common/components-v2/Button/Link';
import DelegationsTable, {
  ScannerPoolDelegation
} from 'forta-app/components/delegations/DelegationsTable';
import PoolStakeModal from 'forta-app/components/staking/StakingModal';
import PoolWithdrawModal from 'forta-app/components/staking/WithdrawModal';
import TabIntro from 'forta-app/components/TabIntro';
import useScannerPoolsWithApyQuery from 'common/hooks/useScannerPoolsWithApyQuery';
import useOffsetPagination from 'common/hooks/useOffsetPagination';
import { AccountStakeQueryData } from 'common/hooks/useAccountStakeQuery';
import { Plus2Icon } from 'common/components/Icons';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import { useQueryParam } from 'use-query-params';
import { SortingParam } from 'common/lib/query-params';
import VaultTable from 'forta-app/components/delegations/VaultTable/VaultTable';
import useVault from 'common/hooks/useVault';
import { WalletConnection } from 'common/hooks/useWallet';
import VaultStakeModal from 'forta-app/components/staking/VaultStakingModal';
import VaultWithdrawModal from '../../components/delegations/VaultWithdrawModal/VaultWithdrawModal';
import { trackEvent } from '../../../common/lib/analytics';
import { Routes } from '../../../common/routes';

type ProfileDelegationsProps = {
  stakeQuery: AccountStakeQueryData;
  walletConnection: WalletConnection;
};

const TABLE_EL_ID = 'delegations-table';

function ProfileDelegationsPage(props: ProfileDelegationsProps): JSX.Element {
  const { walletConnection, stakeQuery } = props;

  const {
    stake,
    fetched: isStakeFetched,
    refetching: isStakeRefetching,
    refetch: refetchStake
  } = stakeQuery;

  const [isVaultWithdrawModalOpen, setIsVaultWithdrawModalOpen] =
    useState(false);
  const [scannerPoolToDelegate, setScannerPoolToDelegate] =
    useState<ScannerPoolDelegation | null>(null);
  const [scannerPoolToUnDelegate, setScannerPoolToUnDelegate] =
    useState<ScannerPoolDelegation | null>(null);
  const [vaultStakeModalOpened, setVaultStakeModalOpened] =
    useState<boolean>(false);

  const vault = useVault({
    account: walletConnection.address
  });

  const scannerPoolsPagination = useOffsetPagination(20, {
    scrollToElementId: TABLE_EL_ID
  });
  const [sortingParam] = useQueryParam('sorting', SortingParam);

  const sorting = sortingParam || {
    orderBy: 'apyForLastEpoch',
    orderDirection: 'desc'
  };

  // filter out empty pools
  const relevantScannerPoolSubjectIds = useMemo(
    () =>
      (
        stake?.delegations.filter(
          (d) =>
            !BigNumber.from(d.shares).isZero() ||
            !BigNumber.from(d.inactiveShares).isZero()
        ) || []
      ).map((v) => v.subject.subjectId),
    [stake]
  );

  const apyQuery = useScannerPoolsWithApyQuery({
    params: {
      skip: scannerPoolsPagination.offset.skip,
      first: scannerPoolsPagination.offset.first,
      id_in: relevantScannerPoolSubjectIds,
      orderBy: sorting.orderBy,
      orderDirection: sorting.orderDirection
    },
    enabled: isStakeFetched && relevantScannerPoolSubjectIds.length > 0
  });

  const {
    refetching: isScannerPoolsRefetching,
    loading: isScannerPoolsFetching,
    hasNextPage: hasScannerPoolsNextPage,
    refetch: refetchScannerPools
  } = apyQuery;

  // make sure we only display non-empty pools
  const scannerPools = useMemo(
    () =>
      apyQuery.scannerPools.filter((s) =>
        relevantScannerPoolSubjectIds.includes(s.id)
      ),
    [apyQuery.scannerPools, relevantScannerPoolSubjectIds]
  );

  const scannerPoolDelegations = useMemo(() => {
    if (!stake || !scannerPools.length) return [];

    const delegations: ScannerPoolDelegation[] = [];
    for (const pool of scannerPools) {
      const delegationStake = stake.delegations.find(
        (p) => p.subject.subjectId === pool.id
      );

      if (!delegationStake) {
        console.error(
          `Something went wrong, we cannot find stake data for the pool ID: ${pool.id}`,
          { pool, stake }
        );
        continue;
      }

      delegations.push({
        ...pool,
        stake: delegationStake
      });
    }

    return delegations;
  }, [scannerPools, stake]);

  useEffect(() => {
    if (!stakeQuery.loading && !stakeQuery.refetching && stakeQuery.data) {
      stakeQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCloseModal(tx?: providers.TransactionResponse): void {
    if (tx) {
      refetchStake();
      refetchScannerPools();
    }
    setScannerPoolToDelegate(null);
    setScannerPoolToUnDelegate(null);
  }

  function handleDelegate(delegation: ScannerPoolDelegation): void {
    setScannerPoolToDelegate(delegation);
  }

  function handleUnDelegate(delegation: ScannerPoolDelegation): void {
    setScannerPoolToUnDelegate(delegation);
  }

  function handleVaultDepositModalOpen(): void {
    trackEvent(`my_delegations_vault_deposit_modal_click`, {});
    setVaultStakeModalOpened(true);
  }

  function handleVaultWithdrawModalOpen(): void {
    trackEvent(`my_delegations_vault_withdraw_modal_click`, {});
    setIsVaultWithdrawModalOpen(true);
  }

  function handleVaultWithdrawModalClose(): void {
    setIsVaultWithdrawModalOpen(false);
    vault.fetchAssets();
  }

  const isInitializing = !stake && !isStakeFetched;
  const vaultUserAssetsIsEmpty = vault.userAssets?.isZero();
  const isEmpty =
    (!stake || stake.history.delegations.length === 0) &&
    vaultUserAssetsIsEmpty;

  if (isInitializing) {
    return (
      <div className="ProfileDelegations">
        <div className="ProfileDelegations__loader">
          <Loader />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className="ProfileDelegations">
        <TabIntro>
          <TabIntro.Content>
            <TabIntro.Title>
              Start earning rewards with Forta Staking
            </TabIntro.Title>
            <TabIntro.Description>
              Become a delegator to help secure the Forta network and earn
              rewards.
              <br />
              <Link
                href="https://docs.forta.network/en/latest/delegated-staking-introduction/"
                target="_blank"
              >
                Learn more about the process here.
              </Link>
            </TabIntro.Description>
            <p>
              <Button
                variant="primary"
                size="lg"
                to={Routes.stake.index()}
                disabled={isStakeRefetching}
                loading={isStakeRefetching}
              >
                Become a Delegator
              </Button>
            </p>
          </TabIntro.Content>
        </TabIntro>
      </div>
    );
  }

  return (
    <div className="ProfileDelegations">
      <div className="ProfileDelegations__header">
        <div>
          <h3 className="ProfileDelegations__title">Delegations</h3>
          <p className="ProfileDelegations__description">
            Manage your FORT deposits to the vault or delegations to scan node
            pools
          </p>
        </div>
      </div>
      <section className="ProfileDelegations__section">
        <div className="ProfileDelegations__subheader">
          <h3 className="ProfileDelegations__subheader-title">
            Simplified Vault
          </h3>
          <Button
            size="md"
            variant="primary"
            startIcon={Plus2Icon}
            className="ProfileDelegations__button"
            onClick={() => setVaultStakeModalOpened(true)}
          >
            Deposit
          </Button>
        </div>
        <VaultTable
          apy={vault.apy}
          userAssets={vault.userAssets}
          totalAssets={vault.totalAssets}
          userClaimableAssets={vault.userClaimableAssets}
          userPendingAssets={vault.userPendingAssets}
          actions={[
            { label: 'Deposit', onClick: handleVaultDepositModalOpen },
            {
              label: 'Withdraw',
              onClick: handleVaultWithdrawModalOpen
            }
          ]}
        />
      </section>
      <section className="ProfileDelegations__section">
        <div className="ProfileDelegations__subheader">
          <h3 className="ProfileDelegations__subheader-title">
            Individual Delegation Pools
          </h3>
          <Button
            size="md"
            variant="primary"
            startIcon={Plus2Icon}
            to={Routes.stake.index()}
            className="ProfileDelegations__button"
          >
            Delegate
          </Button>
        </div>
        <DelegationsTable
          id={TABLE_EL_ID}
          loading={isScannerPoolsRefetching || isScannerPoolsFetching}
          empty={scannerPoolDelegations.length === 0}
          scannerPools={scannerPoolDelegations}
          pagination={{
            ...scannerPoolsPagination,
            hasNextPage: hasScannerPoolsNextPage
          }}
          onDelegate={handleDelegate}
          onUnDelegate={handleUnDelegate}
          className="ProfileDelegations__section"
        />
      </section>
      <PoolStakeModal
        opened={!!scannerPoolToDelegate}
        subjectId={scannerPoolToDelegate?.id}
        subjectType={SubjectType.SCANNERPOOL_DELEGATOR}
        delegatedForm={
          scannerPoolToDelegate
            ? {
                pool: scannerPoolToDelegate,
                commission: scannerPoolToDelegate.commission,
                owner: scannerPoolToDelegate.owner.id,
                allocation: {
                  stakeAllocated: BigNumber.from(
                    scannerPoolToDelegate.stakeAllocated
                  ),
                  stakeDelegated: BigNumber.from(
                    scannerPoolToDelegate.stakeDelegated
                  ),
                  stakeOwned: BigNumber.from(scannerPoolToDelegate.stakeOwned),
                  stakeOwnedAllocated: BigNumber.from(
                    scannerPoolToDelegate.stakeOwnedAllocated
                  )
                }
              }
            : undefined
        }
        onClose={handleCloseModal}
      />
      <PoolWithdrawModal
        opened={!!scannerPoolToUnDelegate}
        stake={scannerPoolToUnDelegate?.stake}
        onClose={handleCloseModal}
      />
      <VaultWithdrawModal
        open={isVaultWithdrawModalOpen}
        vault={vault}
        onClose={handleVaultWithdrawModalClose}
      />
      <VaultStakeModal
        opened={vaultStakeModalOpened}
        onClose={() => {
          setVaultStakeModalOpened(false);
          vault.fetchAssets();
        }}
      />
    </div>
  );
}

export default ProfileDelegationsPage;
