import { useMemo } from 'react';
import { gql } from '@apollo/client';

import useGraphQuery, { GraphQueryResult } from './useGraphQuery';
import { ApolloClientName } from '../components/ApolloClientProvider';

const GET_LABELS_QUERY = gql`
  query LabelsQuery($input: LabelsInput) {
    getLabels(input: $input) {
      labels {
        id
        label {
          label
          confidence
          entity
          entityType
          metadata
        }
        source {
          bot {
            id
          }
          alertHash
          alertId
          id
          chainId
        }
        createdAt
      }
      pageInfo {
        endCursor {
          pageToken
        }
        hasNextPage
      }
    }
  }
`;

type LabelBody = {
  label: string;
  confidence: number;
  entity: string;
  entityType: string;
  metadata: string[];
};

export type LabelEvent = {
  id: string;
  createdAt: string;
  label: LabelBody;
  source: {
    bot: {
      id: string;
      // image: string;
      // imageHash: string;
      // manifest: string;
    };
    // alertHash: string;
    alertId: string;
    id: string;
    chainId?: number;
  };
};

export type Label = Omit<LabelEvent, 'label'> & {
  label: Omit<LabelBody, 'metadata'> & {
    metadata: Record<string, unknown>;
  };
};

export type LabelEndCursor = {
  pageToken: string;
};

export type LabelResult = {
  getLabels: {
    pageInfo: {
      endCursor?: LabelEndCursor;
      hasNextPage: boolean;
    };
    labels: LabelEvent[];
  };
};

export type UseLabelQueryParams = {
  botIds: string[];
  first?: number;
  chainIds?: number[];
  labels?: string[];
  entities?: string[];
  after?: LabelEndCursor;
  createdSince?: number;
};

type LabelInput = {
  input: {
    // Request without specified bots causes an error on the backend:
    // "Converting circular structure to JSON..."
    sourceIds: string[];
    labels?: string[];
    first?: number;
    chainIds?: number[];
    entities?: string[];
    createdSince?: number;
    after?: LabelEndCursor;
    state?: boolean;
  };
};

function useLabelQuery(opts: { params: UseLabelQueryParams }): GraphQueryResult<
  LabelInput,
  LabelResult
> & {
  labels: Label[];
  hasNextPage: boolean;
  currPageCursor: { pageToken: string } | undefined;
  nextPageCursor: { pageToken: string } | undefined;
} {
  const { params } = opts;
  const {
    botIds,
    entities,
    first = 10,
    after,
    labels: labelsParam,
    chainIds,
    createdSince = 946684800000 // 2000-01-01T00:00:00Z
  } = params;

  const query = useGraphQuery<LabelInput, LabelResult>({
    query: GET_LABELS_QUERY,
    variables: {
      input: {
        chainIds: chainIds,
        entities: entities,
        sourceIds: botIds,
        labels: labelsParam,
        first: first,
        after: after,
        createdSince,
        state: true
      }
    },
    clientName: ApolloClientName.Forta
  });

  const labels: Label[] = useMemo(() => {
    const labels: Label[] = [];

    if (!query.data) return labels;

    for (const event of query.data.getLabels.labels) {
      const metadata: Record<string, unknown> = {};
      const label: Label = {
        ...event,
        label: {
          ...event.label,
          metadata: metadata
        }
      };

      try {
        for (const prop of event.label.metadata || []) {
          // key=value
          const separatorIndex = prop.indexOf('=');
          const key = prop.slice(0, separatorIndex);
          const value = prop.slice(separatorIndex + 1);

          // Attempt to parse the value as JSON only if it starts with '{' or '['
          if (value.startsWith('{') || value.startsWith('[')) {
            try {
              metadata[key] = JSON.parse(value);
            } catch {
              metadata[key] = value; // Keep the original value if JSON.parse fails
            }
          } else {
            metadata[key] = value;
          }
        }
      } catch (e) {
        console.error(e);
      }

      labels.push(label);
    }

    return labels;
  }, [query.data]);

  // remove __typename
  const getEndCursor = (
    cursor?: LabelEndCursor
  ): LabelEndCursor | undefined => {
    if (!cursor) return undefined;
    return {
      pageToken: cursor.pageToken
    };
  };

  return {
    ...query,
    labels: labels,
    hasNextPage: !!query.data?.getLabels?.pageInfo.hasNextPage || false,
    currPageCursor: after,
    nextPageCursor: getEndCursor(query.data?.getLabels.pageInfo.endCursor)
  };
}

export default useLabelQuery;
