import React from 'react';
import cn from 'classnames';
import { range } from 'lodash';
import Skeleton from 'react-loading-skeleton';

import './NetworkStatus.scss';

import { getNetworkByChainId } from 'common/lib/networks';
import InfoPopover from 'common/components/InfoPopover';
import ChainIcon from 'common/components/stats/ChainIcon';
import { ExternalBotIcon, FortaLightIcon } from 'common/components/Icons';

type NetworkStatusProps = {
  isExternalBot: boolean;
  isFetching: boolean;
  chainIds: number[];
  className?: string;
};

function NetworkStatus(props: NetworkStatusProps): JSX.Element {
  const { isFetching, isExternalBot, chainIds, className } = props;

  const wrap = (children: JSX.Element | JSX.Element[]): JSX.Element => (
    <div className={cn('NetworkStatus', className)}>{children}</div>
  );

  if (isFetching) {
    return wrap(
      range(5).map((i) => (
        <div key={i} className="NetworkStatus__network">
          <Skeleton width={30} height={30} borderRadius={'50%'} />
        </div>
      ))
    );
  }

  if (isExternalBot) {
    return wrap(
      <div className="NetworkStatus__status">
        <InfoPopover content="External Bot">
          <span className="NetworkStatus__pulse">{ExternalBotIcon}</span>
        </InfoPopover>
        <span>Connected via API</span>
      </div>
    );
  }

  if (chainIds.length == 0) {
    return wrap(
      <div className="NetworkStatus__status">
        {FortaLightIcon}
        <span>No data on networks yet</span>
      </div>
    );
  }

  return wrap(
    chainIds.map((chainId) => {
      const network = getNetworkByChainId(Number(chainId));
      return (
        <InfoPopover key={`network-${network.chainId}`} content={network.label}>
          <div className="NetworkStatus__network">
            <ChainIcon
              network={network}
              className="NetworkStatus__network-icon"
            />
          </div>
        </InfoPopover>
      );
    })
  );
}

export default NetworkStatus;
