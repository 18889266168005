/* eslint-disable @typescript-eslint/no-explicit-any */

import { BigNumber, ethers } from 'ethers';
import BigNumberJs from 'bignumber.js';

const FORT_PRECISION = 4;

// Since we often use formatting functions when rendering components,
// it was decided to wrap them in a safe block that wouldn't crash the entire page
// on an overflow/underflow/number parsing error.
const safeCalculation = (fn: () => any): string => {
  try {
    return fn();
  } catch (e: any) {
    console.error('Error when trying to get number', e);
    return '0';
  }
};

export const toFORTPrecision = (
  value: string,
  precision = FORT_PRECISION
): string => {
  return safeCalculation(() => {
    const precisionMagnitude = Math.pow(10, precision);
    return formatNumber(
      Math.floor(Number(value) * precisionMagnitude) / precisionMagnitude
    );
  });
};

export const toFORTPrecisionNumber = (
  value: string,
  precision = FORT_PRECISION
): number => {
  return Number(
    safeCalculation(() => {
      const precisionMagnitude = Math.pow(10, precision);
      return (
        Math.floor(Number(value) * precisionMagnitude) / precisionMagnitude
      );
    })
  );
};

export const formatNumber = (
  value: string | number | BigNumber,
  maximumFractionDigits = FORT_PRECISION
): string => {
  return safeCalculation(() => {
    const number = parseFloat(value.toString());
    return Intl.NumberFormat('en-US', {
      maximumFractionDigits
    }).format(number);
  });
};

export const formatLargeNumber = (value: string | BigNumber): string => {
  const number = parseFloat(value.toString());
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 2
  }).format(number);
};

export const formatFORT = (
  wei?: BigNumber | BigNumberJs | string,
  precision = 2,
  defaultSymbol = '0'
): string => {
  return safeCalculation(() => {
    return wei != null
      ? toFORTPrecision(
          new BigNumberJs(wei.toString(10))
            .div(new BigNumberJs(10).pow(18))
            .toString(10),
          precision
        )
      : defaultSymbol;
  });
};

export const formatFORTToNumber = (
  wei?: BigNumber | BigNumberJs | string,
  precision = FORT_PRECISION,
  defaultNumber = 0
): number => {
  return Number(
    safeCalculation(() => {
      return wei != null
        ? toFORTPrecisionNumber(
            new BigNumberJs(wei.toString(10))
              .div(new BigNumberJs(10).pow(18))
              .toString(10),
            precision
          )
        : defaultNumber;
    })
  );
};

// 4 days timestap offset
const TIMESTAMP_OFFSET = 4 * (24 * 3600 * 1000);
// 7 days epoch length
const EPOCH_LENGTH = 7 * (24 * 3600 * 1000);

export const getCurrentEpoch = (): number => {
  const timestamp = Date.now();
  return Math.floor((timestamp - TIMESTAMP_OFFSET) / EPOCH_LENGTH);
};

export const hasRequiredBotStake = (stake: number | string): boolean => {
  const REQUIRED_BOT_STAKE = 100;
  return Number(stake) >= REQUIRED_BOT_STAKE;
};

export const safeFormatEther = (
  wei: string | ethers.BigNumber | BigNumberJs
): string => {
  return new BigNumberJs(wei.toString(10))
    .div(new BigNumberJs(10).pow(18))
    .toString(10);
};
