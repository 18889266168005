import React from 'react';
import { Link } from 'react-router-dom';

export type BaseButtonProps = {
  as?: keyof JSX.IntrinsicElements;
  type?: 'submit' | 'button';
  to?: string;
  href?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => unknown;
  target?: '_blank' | '_self' | '_parent';
  testId?: string;
  children?: React.ReactNode;
};

const BaseButton = React.forwardRef<HTMLElement, BaseButtonProps>(
  function BaseButton(props, ref) {
    const {
      as,
      to,
      href,
      type,
      disabled,
      children,
      className,
      target,
      testId,
      onClick
    } = props;

    const isLinkComponent =
      (to || href) && !disabled && !['submit', 'button'].includes(type || '');

    /* eslint-disable @typescript-eslint/no-explicit-any */
    let Element: any = isLinkComponent ? Link : 'button';

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const componentProps: any = {};

    if (href) {
      componentProps.href = href;
      componentProps.rel = 'noopener noreferrer';
      Element = 'a';
    }

    if (as) {
      Element = as;
    }

    return (
      <Element
        ref={ref}
        to={to}
        data-testid={testId}
        type={type}
        disabled={disabled}
        target={target}
        className={className}
        onClick={onClick}
        {...componentProps}
      >
        {children}
      </Element>
    );
  }
);

export default BaseButton;
