import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams
} from 'use-query-params';
import { useMemo } from 'react';

import { AlertsApiParams } from 'common/lib/apis/alertAPI';

type UseAlertFilterResult = {
  filter: AlertsApiParams;
  setFilter: (
    filter: AlertsApiParams | ((filter: AlertsApiParams) => AlertsApiParams)
  ) => void;
  filterCount: number;
};

const nonEmptyArray = <T>(
  arr: (T | null)[] | null | undefined
): T[] | undefined => {
  if (arr == null) return undefined;

  return (Array.isArray(arr) ? arr : [arr]).filter((v) => !!v) as T[];
};

type UseAlertFilterOpts = {
  limit: number;
};

function useAlertFilter(opts: UseAlertFilterOpts): UseAlertFilterResult {
  const { limit } = opts;

  const [filter, setFilter] = useQueryParams(
    {
      severity: ArrayParam,
      hash: StringParam,
      txHash: StringParam,
      text: StringParam,
      muted: ArrayParam,
      sort: StringParam,
      startDate: StringParam,
      chain_id: NumberParam,
      alert_ids: ArrayParam,
      endDate: StringParam,
      addresses: ArrayParam,
      agents: ArrayParam,
      project: StringParam
    },
    {
      updateType: 'replaceIn'
    }
  );

  const params: AlertsApiParams = useMemo(() => {
    return {
      severity: nonEmptyArray(filter.severity),
      hash: filter.hash || undefined,
      txHash: filter.txHash || undefined,
      text: filter.text || undefined,
      muted: nonEmptyArray(filter.muted),
      sort: filter.sort || 'desc',
      limit: limit,
      startDate: filter.startDate || undefined,
      endDate: filter.endDate || undefined,
      chain_id: filter.chain_id || undefined,
      alert_ids: nonEmptyArray(filter.alert_ids),
      addresses: nonEmptyArray(filter.addresses),
      agents: nonEmptyArray(filter.agents),
      project: filter.project || undefined
    };
  }, [filter, limit]);

  const filterCount = useMemo(() => {
    return [
      // this filter is outside the filter container
      // filter.chain_id,
      filter.text,
      (filter.agents?.length || 0) > 0,
      (filter.addresses?.length || 0) > 0,
      (filter.muted?.length || 0) > 0,
      filter.hash,
      filter.txHash,
      (filter.alert_ids?.length || 0) > 0,
      filter.startDate || filter.endDate,
      filter.project,
      (filter.severity?.length || 0) > 0
    ].filter((v) => !!v).length;
  }, [filter]);

  // helps to fix typescript issues
  function handleSetFilter(
    filter: AlertsApiParams | ((fn: AlertsApiParams) => AlertsApiParams)
  ): void {
    setFilter(filter as Partial<AlertsApiParams>);
  }

  return {
    filter: params,
    setFilter: handleSetFilter,
    filterCount: filterCount
  };
}

export default useAlertFilter;
