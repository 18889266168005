import { MouseEvent, useState } from 'react';

import './StartBuildingCTA.scss';
import { useAppDispatch, useJWT } from 'forta-app/app/hooks';
import { signMessage } from '../wallet/WalletConnector';
import { useWeb3React } from '@web3-react/core';

import { loginAddress } from 'forta-app/lib/apis/loginAPI';
import { setLoginData } from 'forta-app/slices/walletSlice';
import {
  subscribeToAddress,
  subscribeToGroup
} from 'forta-app/pages/NotificationsPage';
import { currentENV } from 'common/config';

const SECURITY_GROUPS: {
  [environment: string]: { id: string; name: string }[];
} = {
  production: [
    {
      id: 'global|4437798f-cbb4-11ec-a432-0a58a9feac02',
      name: 'NFT'
    },
    {
      id: 'global|a7a4b7ee-bbf6-11ec-ab73-0a58a9feac02',
      name: 'DeFi'
    },
    {
      id: 'global|755c5119-cbb4-11ec-a432-0a58a9feac02',
      name: 'Bridge'
    },
    {
      id: 'global|188bd9f0-d610-11ec-b705-0a58a9feac02',
      name: 'Governance'
    },
    {
      id: 'global|86a74ac3-c722-11ec-bc85-0a58a9feac02',
      name: 'Stablecoin'
    }
  ],
  staging: [
    {
      id: 'global|22fc634c-cbb4-11ec-b238-0a58a9feac02',
      name: 'NFT'
    },
    {
      id: 'global|b08e3293-bf2b-11ec-b63d-0a58a9feac02',
      name: 'DeFi'
    },
    {
      id: 'global|6c8dccde-cbb4-11ec-b238-0a58a9feac02',
      name: 'Bridge'
    },
    {
      id: 'global|0e5a0bf1-d610-11ec-803b-0a58a9feac02',
      name: 'Governance'
    },
    {
      id: 'global|af82de7e-c722-11ec-bc3d-0a58a9feac02',
      name: 'Stablecoin'
    }
  ]
};

function MyWalletCTA({
  onClick
}: {
  onClick: (event: MouseEvent) => void;
}): JSX.Element {
  return (
    <div className="MyWalletCTA">
      <div className="MyWalletCTA__text">
        <div className="MyWalletCTA__title">
          Users: subscribe to alerts for your wallet
        </div>
        <div className="MyWalletCTA__description">
          Get monitoring for your wallet and receive notifications in real-time.
        </div>
      </div>
      <div className="MyWalletCTA__action">
        <button className="MonitorContractCTA__action-button" onClick={onClick}>
          Monitor my wallet
        </button>
      </div>
    </div>
  );
}

function MonitorContractCTA({
  onClick
}: {
  onClick: (groupId: string, event: MouseEvent) => void;
}): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <div className="MonitorContractCTA">
      <div className="MonitorContractCTA__text">
        <div className="MonitorContractCTA__title">
          Developers: subscribe to alerts for your smart contracts
        </div>
        <div className="MonitorContractCTA__description">
          Subscribe your smart contracts to get alerts and protection from a
          curated group of security related detection bots.
        </div>
      </div>
      <div className={`MonitorContractCTA__action ${expanded ? 'hidden' : ''}`}>
        <button
          className="MonitorContractCTA__action-button"
          onClick={() => setExpanded(true)}
        >
          Monitor my contracts
        </button>
      </div>
      <div
        className={`MonitorContractCTA__action-list ${
          expanded ? '' : 'hidden'
        }`}
      >
        {(currentENV === 'production'
          ? SECURITY_GROUPS['production']
          : SECURITY_GROUPS['staging']
        ).map((group) => {
          return (
            <button
              key={`cta-group-${group.id}`}
              className="MonitorContractCTA__action-button"
              onClick={onClick.bind(null, group.id)}
            >
              {group.name}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default function StartBuildingCTA(): JSX.Element {
  const web3React = useWeb3React();
  const dispatch = useAppDispatch();
  let jwt = useJWT();

  return (
    <div className="StartBuildingCTA">
      <div className="StartBuildingCTA__title">Start Using Forta</div>
      <div className="StartBuildingCTA__items">
        <MyWalletCTA
          onClick={async () => {
            if (!jwt) {
              const result = await signMessage(web3React);
              if (result) {
                jwt = await loginAddress(result);
                dispatch(
                  setLoginData({
                    jwt
                  })
                );
              }
            }
            subscribeToAddress(web3React.account || '', dispatch, web3React);
          }}
        />
        <MonitorContractCTA
          onClick={async (groupId: string) => {
            if (!jwt) {
              const result = await signMessage(web3React);
              if (result) {
                jwt = await loginAddress(result);
                dispatch(
                  setLoginData({
                    jwt
                  })
                );
              }
            }
            subscribeToGroup(groupId, dispatch, web3React);
          }}
        />
      </div>
    </div>
  );
}
