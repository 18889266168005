export interface NetworkInfo {
  name: string;
  label: string;
  color: string;
  explorerUrl: string;
  explorerName: string;
  txExplorerUrl: string;
  chainId: number;
  rpcProviderUrl?: string;
  icon: string;
}

export enum Network {
  Mainnet = 1,
  Ropsten = 3,
  Rinkeby = 4,
  Sepolia = 11155111,
  Kovan = 42,
  PolygonMainnet = 137,
  BaseSepolia = 84532,
  Bsc = 56,
  Avax = 43114,
  Optimism = 10,
  Fantom = 250,
  Arbitrum = 42161,
  Base = 8453,
  Manta = 169,
  Metis = 1088,
  Mantle = 5000,
  Linea = 59144,
  Gnosis = 100,
  Celo = 42220,
  Canto = 7700,
  Moonbeam = 1284,
  Aurora = 1313161554,
  Core = 1116,
  Blast = 81457
}

export const NetworksInfo: { [key: string]: NetworkInfo } = {
  mainnet: {
    name: 'mainnet',
    label: 'Ethereum',
    color: '#0770fb',
    explorerUrl: 'https://etherscan.io',
    txExplorerUrl: 'https://phalcon.blocksec.com/explorer/tx/eth',
    explorerName: 'Etherscan',
    chainId: 1,
    icon: 'rsz_ethereum',
    rpcProviderUrl: 'https://rpc.ankr.com/eth'
  },
  ropsten: {
    name: 'ropsten',
    label: 'Ropsten',
    color: 'red',
    explorerUrl: 'https://ropsten.etherscan.io',
    txExplorerUrl: 'https://ropsten.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 3,
    icon: 'rsz_ethereum'
  },
  rinkeby: {
    name: 'rinkeby',
    label: 'Rinkeby',
    color: 'yellow',
    explorerUrl: 'https://rinkeby.etherscan.io',
    txExplorerUrl: 'https://rinkeby.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 4,
    icon: 'rsz_ethereum'
  },
  sepolia: {
    name: 'sepolia',
    label: 'Sepolia',
    color: 'green',
    explorerUrl: 'https://sepolia.etherscan.io',
    txExplorerUrl: 'https://sepolia.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 11155111,
    icon: 'rsz_ethereum'
  },
  kovan: {
    name: 'kovan',
    label: 'Kovan',
    color: 'blue',
    explorerUrl: 'https://kovan.etherscan.io',
    txExplorerUrl: 'https://kovan.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 42,
    icon: 'rsz_ethereum'
  },
  'polygon-mainnet': {
    name: 'polygon-mainnet',
    label: 'Polygon',
    color: '#8247e5',
    explorerUrl: 'https://polygonscan.com',
    txExplorerUrl: 'https://phalcon.blocksec.com/explorer/tx/polygon',
    explorerName: 'Polygonscan',
    chainId: 137,
    icon: 'rsz_polygon',
    rpcProviderUrl: 'https://polygon-bor-rpc.publicnode.com'
  },
  'base-sepolia': {
    name: 'base-sepolia',
    label: 'Base Sepolia',
    color: '#8247e5',
    explorerUrl: 'https://sepolia.basescan.org',
    txExplorerUrl: 'https://sepolia.basescan.org/tx',
    explorerName: 'BaseScan',
    chainId: 84532,
    icon: 'rsz_base',
    rpcProviderUrl: 'https://base-sepolia.blockpi.network/v1/rpc/public'
  },
  bsc: {
    name: 'bsc',
    label: 'BSC',
    color: '#f0b90b',
    explorerUrl: 'https://bscscan.com',
    txExplorerUrl: 'https://phalcon.blocksec.com/explorer/tx/bsc',
    explorerName: 'BSCScan',
    chainId: 56,
    icon: 'rsz_binance',
    rpcProviderUrl: 'https://rpc.ankr.com/bsc'
  },
  avax: {
    name: 'avax',
    label: 'Avalanche',
    color: '#e84142',
    explorerUrl: 'https://snowtrace.io',
    txExplorerUrl: 'https://phalcon.blocksec.com/explorer/tx/avax',
    explorerName: 'Snowtrace',
    chainId: 43114,
    icon: 'rsz_avalanche',
    rpcProviderUrl: 'https://rpc.ankr.com/avalanche'
  },
  optimism: {
    name: 'optimism',
    label: 'Optimism',
    color: '#FE0729',
    explorerUrl: 'https://optimistic.etherscan.io',
    txExplorerUrl: 'https://optimistic.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 10,
    icon: 'rsz_optimism',
    rpcProviderUrl: 'https://mainnet.optimism.io'
  },
  fantom: {
    name: 'fantom',
    label: 'Fantom',
    color: '#1a68fe',
    explorerUrl: 'https://ftmscan.com',
    txExplorerUrl: 'https://ftmscan.com/tx',
    explorerName: 'FMTScan',
    chainId: 250,
    icon: 'rsz_fantom',
    rpcProviderUrl: 'https://rpc.fantom.network'
  },
  arbitrum: {
    name: 'arbitrum',
    label: 'Arbitrum',
    color: '#349de0',
    explorerUrl: 'https://arbiscan.io',
    txExplorerUrl: 'https://phalcon.blocksec.com/explorer/tx/arbitrum',
    explorerName: 'Arbiscan',
    chainId: 42161,
    icon: 'rsz_arbitrum',
    rpcProviderUrl: 'https://rpc.ankr.com/arbitrum'
  },
  base: {
    name: 'base',
    label: 'Base',
    color: '#0052FF',
    explorerUrl: 'https://basescan.org/',
    txExplorerUrl: 'https://phalcon.blocksec.com/explorer/tx/base',
    explorerName: 'BaseScan',
    chainId: 8453,
    icon: 'rsz_base',
    rpcProviderUrl: 'https://rpc.ankr.com/base'
  },
  manta: {
    name: 'manta',
    label: 'Manta',
    color: '#350D28',
    explorerUrl: 'https://manta.socialscan.io/',
    txExplorerUrl: 'https://manta.socialscan.io/tx/',
    explorerName: 'MantaSocialScan',
    chainId: Network.Manta,
    icon: 'rsz_manta',
    rpcProviderUrl: 'https://manta-pacific.drpc.org'
  },
  metis: {
    name: 'metis',
    label: 'Metis',
    color: '#21D5C3',
    explorerUrl: 'https://explorer.metis.io/',
    txExplorerUrl: 'https://explorer.metis.io/tx/',
    explorerName: 'Metis Explorer',
    chainId: Network.Metis,
    icon: 'rsz_metis',
    rpcProviderUrl: 'https://metis.drpc.org'
  },
  mantle: {
    name: 'mantle',
    label: 'Mantle',
    color: '#000000',
    explorerUrl: 'https://explorer.mantle.xyz/',
    txExplorerUrl: 'https://explorer.mantle.xyz/tx/',
    explorerName: 'Mantle Explorer',
    chainId: Network.Mantle,
    icon: 'rsz_mantle',
    rpcProviderUrl: 'https://mantle.drpc.org'
  },
  linea: {
    name: 'linea',
    label: 'Linea',
    color: '#121212',
    explorerUrl: 'https://lineascan.build/',
    txExplorerUrl: 'https://lineascan.build/tx/',
    explorerName: 'LineaScan',
    chainId: Network.Linea,
    icon: 'rsz_linea',
    rpcProviderUrl: 'https://linea.drpc.org'
  },
  gnosis: {
    name: 'gnosis',
    label: 'Gnosis',
    color: '#07785E',
    explorerUrl: 'https://gnosisscan.io/',
    txExplorerUrl: 'https://gnosisscan.io/tx/',
    explorerName: 'GnosisScan',
    chainId: Network.Gnosis,
    icon: 'rsz_gnosis',
    rpcProviderUrl: 'https://gnosis.drpc.org'
  },
  celo: {
    name: 'celo',
    label: 'Celo',
    color: '#FCFF52',
    explorerUrl: 'https://celoscan.io/',
    txExplorerUrl: 'https://celoscan.io/tx/',
    explorerName: 'CeloScan',
    chainId: Network.Celo,
    icon: 'rsz_celo',
    rpcProviderUrl: 'https://forno.celo.org'
  },
  canto: {
    name: 'canto',
    label: 'Canto',
    color: '#06FC99',
    explorerUrl: 'https://cantoscan.com/',
    txExplorerUrl: 'https://cantoscan.com/tx/',
    explorerName: 'CantoScan',
    chainId: Network.Canto,
    icon: 'rsz_canto',
    rpcProviderUrl: 'https://canto.slingshot.finance'
  },
  moonbeam: {
    name: 'moonbeam',
    label: 'Moonbeam',
    color: '#791E57',
    explorerUrl: 'https://moonscan.io/',
    txExplorerUrl: 'https://moonscan.io/tx/',
    explorerName: 'MoonbeamScan',
    chainId: Network.Moonbeam,
    icon: 'rsz_moonbeam',
    rpcProviderUrl: 'https://canto.slingshot.finance'
  },
  aurora: {
    name: 'aurora',
    label: 'Aurora',
    color: '#73D04B',
    explorerUrl: 'https://explorer.aurora.dev/',
    txExplorerUrl: 'https://explorer.aurora.dev/tx/',
    explorerName: 'Aurora Explorer',
    chainId: Network.Aurora,
    icon: 'rsz_aurora',
    rpcProviderUrl: 'https://mainnet.aurora.dev'
  },
  core: {
    name: 'core',
    label: 'Core',
    color: '#000000',
    explorerUrl: 'https://scan.coredao.org/',
    txExplorerUrl: 'https://scan.coredao.org/tx/',
    explorerName: 'CoreScan',
    chainId: Network.Core,
    icon: 'rsz_core',
    rpcProviderUrl: 'https://rpc.coredao.org'
  },
  blast: {
    name: 'blast',
    label: 'Blast',
    color: '#F7F703',
    explorerUrl: 'https://blastscan.io/',
    txExplorerUrl: 'https://blastscan.io/tx/',
    explorerName: 'BlastScan',
    chainId: Network.Blast,
    icon: 'rsz_blast',
    rpcProviderUrl: 'https://rpc.blastblockchain.com'
  },
  forta: {
    name: 'Forta',
    label: '',
    color: 'gray',
    explorerUrl: 'https://explorer.forta.network',
    txExplorerUrl: 'https://explorer.forta.network/tx',
    explorerName: 'Forta Explorer',
    chainId: 1337,
    icon: 'rsz_forta_light'
  }
};

export const getNetworkByChainId = (
  chainId: number,
  defaultName = 'Chain'
): NetworkInfo => {
  const networks = Object.values(NetworksInfo);
  const network = networks.find((network) => network.chainId === chainId);
  if (network) return network;
  return {
    ...NetworksInfo['forta'],
    chainId,
    name: chainId.toString(),
    label: defaultName + ' ' + chainId
  };
};

export const V1_BOT_NETWORKS: Network[] = [
  Network.Mainnet,
  Network.Bsc,
  Network.Avax,
  Network.Fantom,
  Network.PolygonMainnet,
  Network.Arbitrum,
  Network.Optimism
];

export const FEATURED_BOT_NETWORKS: Network[] = [
  Network.Mainnet,
  Network.Bsc,
  Network.Avax,
  Network.Fantom,
  Network.PolygonMainnet,
  Network.Arbitrum,
  Network.Optimism,
  Network.Base,
  Network.Manta,
  Network.Metis,
  Network.Mantle,
  Network.Linea,
  Network.Gnosis,
  Network.Celo,
  Network.Canto,
  Network.Moonbeam,
  Network.Aurora,
  Network.Core,
  Network.Blast
];

export const FEATURED_NETWORK_ENTITIES = Object.values(NetworksInfo)
  .map((network) => ({ ...network, value: network.chainId }))
  .filter((network) => FEATURED_BOT_NETWORKS.includes(network.chainId));

export const V1_BOT_NETWORK_ENTITIES = Object.values(NetworksInfo)
  .map((network) => ({ ...network, value: network.chainId }))
  .filter((network) => V1_BOT_NETWORKS.includes(network.chainId));

// const INFURA_KEY = '';

// export const NETWORK_URLS: {
//   [chainId in ChainId]: string
// } = {
//   [ChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
//   [ChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
//   [ChainId.POLYGON_MAINNET]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
//   [ChainId.POLYGON_TESTNET]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
// };
