import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import './Base.scss';

import ModalV2 from 'common/components-v2/Modal/ModalV2';
import Button from 'common/components-v2/Button/Button';

type BaseFilterModalProps<T> = {
  open?: boolean;
  title?: string;
  value?: T;
  disabled?: boolean;
  children: (props: {
    value: T | undefined;
    setValue: (val: T | undefined) => unknown;
  }) => JSX.Element | JSX.Element[];
  onChange: (value?: T) => unknown;
  onClose: () => unknown;
  className?: string;
};

function BaseFilterModal<T>(props: BaseFilterModalProps<T>): JSX.Element {
  const {
    open,
    title,
    value,
    disabled,
    children,
    onChange,
    onClose,
    className
  } = props;

  const [selectedValue, setSelectedValue] = useState<T | undefined>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [open, value]);

  return (
    <ModalV2
      open={open}
      size="sm"
      title={title}
      className={cn('BaseFilterModal', className)}
      onClose={onClose}
    >
      <div className="BaseFilterModal__content">
        {children({ value: selectedValue, setValue: setSelectedValue })}
      </div>
      <div className="BaseFilterModal__actions">
        <Button
          type="button"
          size="lg"
          variant="default"
          onClick={onClose}
          className="BaseFilterModal__action-button"
        >
          Cancel
        </Button>
        <Button
          type="button"
          size="lg"
          variant="primary"
          disabled={disabled}
          onClick={() => onChange(selectedValue)}
          className="BaseFilterModal__action-button"
        >
          Apply
        </Button>
      </div>
    </ModalV2>
  );
}

type FilterModalDescriptionProps = {
  children: React.ReactNode;
};

export const FilterModalDescription = (
  props: FilterModalDescriptionProps
): JSX.Element => (
  <div className="BaseFilterModal__description">{props.children}</div>
);

export default BaseFilterModal;
