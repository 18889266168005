import React from 'react';
import cn from 'classnames';

import './Score.scss';

export type ScoreColor = 'grey' | 'yellow' | 'green';

type ScoreProps = {
  value: string | number;
  color: ScoreColor;
  digits?: number;
  className?: string | number;
};

function Score(props: ScoreProps): JSX.Element {
  const { value, color, digits, className } = props;

  return (
    <div className={cn('Score', `Score--${color}`, className)}>
      {digits ? Number(value).toFixed(digits) : value}
    </div>
  );
}

export default Score;
