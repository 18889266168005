import { useState, useCallback, ReactNode, useEffect } from 'react';

export interface InlineInputModalProps {
  id: string;
  opened: boolean;
  title: string;
  submitText: string;
  children: ReactNode;
  defaultValue: string;
  onOpenedChange: (value: boolean) => void;
  onValidate: (value: string) => string;
  onSubmit: (value: string) => void;
}

export default function InlineInputModal({
  id,
  children,
  title,
  defaultValue,
  submitText,
  opened,
  onOpenedChange,
  onSubmit,
  onValidate
}: InlineInputModalProps): JSX.Element {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (): void => {
    const validationResult = onValidate(inputValue);
    if (validationResult === '') {
      setInputValue('');
      setError('');
      onOpenedChange(false);
      onSubmit(inputValue);
    } else {
      setError(validationResult);
    }
  };

  const handleEnter = (key: string): void => {
    if (key === 'Enter') {
      handleSubmit();
    }
  };

  const handleEscape = useCallback(
    (event: KeyboardEvent): void => {
      if (event.code === 'Escape') {
        onOpenedChange(false);
      }
    },
    [onOpenedChange]
  );

  const close = (): void => {
    onOpenedChange(false);
  };

  useEffect(() => {
    if (opened) {
      document.addEventListener('keydown', handleEscape, false);
      setInputValue(defaultValue);
    } else {
      document.removeEventListener('keydown', handleEscape, false);
    }
  }, [opened, defaultValue, handleEscape]);

  return (
    <>
      <div className="flex">{children}</div>
      {opened ? (
        <>
          <div className="relative z-20">
            <div className="p-5 bg-gray-800 rounded-2xl flex flex-col text-white text-sm absolute right-0">
              <p className="text-left font-semibold">{title}</p>
              <input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => handleEnter(e.key)}
                type="url"
                name={`text-field-_${id}`}
                className="w-80 px-4 py-2 mt-1 mb-2 rounded-full bg-black"
              />
              <button
                onClick={handleSubmit}
                onKeyDown={handleSubmit}
                className="w-80 px-4 py-2 rounded-full bg-purple-600 font-semibold hover:bg-purple-700"
              >
                {submitText}
              </button>
              {error ? (
                <span className="text-xs text-red-400 mt-2">{error}</span>
              ) : (
                <></>
              )}
            </div>
          </div>
          <button
            onClick={close}
            className="w-full min-h-full fixed inset-0 z-10 bg-black bg-opacity-50 backdrop-filter backdrop-blur-xs"
          ></button>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
