import { useWeb3React } from '@web3-react/core';
import Modal from 'common/components/Modal';
import ContractCallButton from '../ContractCallButton';
import RewardsDistributor from 'forta-app/lib/contract-interactors/rewardsDistributor';
import './ClaimRewardsModal.scss';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import PoolEntity from 'common/components-v2/Entities/Pool/Pool';
import Table from 'common/components-v2/Table/Table';
import TableHead from 'common/components-v2/Table/TableHead';
import TableHeading from 'common/components-v2/Table/TableHeading';
import TableSubHeading from 'common/components-v2/Table/TableSubHeading';
import TableBody from 'common/components-v2/Table/TableBody';
import TableRow from 'common/components-v2/Table/TableRow';
import TableCell from 'common/components-v2/Table/TableCell';
import TableSubCell from 'common/components-v2/Table/TableSubCell';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import { Epoch } from './RewardsTable';
import { BigNumber, providers } from 'ethers';
import { formatFORT } from '../../lib/utils';

interface ClaimRewardsModalProps {
  opened: boolean;
  subjectType: SubjectType;
  pool: ScannerPool;
  epochs: Epoch[];
  onClose: () => void;
  onSuccess: (tx?: providers.TransactionResponse) => void;
}

const ClaimRewardsModal = ({
  opened,
  subjectType,
  pool,
  epochs,
  onClose,
  onSuccess
}: ClaimRewardsModalProps): JSX.Element => {
  const web3React = useWeb3React();
  const rewardsDistributor = new RewardsDistributor(web3React);

  const rewards = epochs.map((e) => BigNumber.from(e.rewards));

  const totalRewards = rewards.reduce((acc, v) => {
    return acc.add(BigNumber.from(v));
  }, BigNumber.from(0));

  const epochsArray = epochs.map((e) => e.epoch);

  return (
    <Modal
      opened={opened}
      title="Claim Rewards"
      onCloseModal={onClose}
      className={'ClaimRewardsModal'}
    >
      <div className="ClaimRewardsModal__container">
        <div className="ClaimRewardsModal__pool">
          <div className="ClaimRewardsModal__pool-label">{`Scanner Pool ${
            subjectType === SubjectType.SCANNERPOOL
              ? ' (Owner)'
              : ' (Delegator)'
          }`}</div>
          <PoolEntity
            chainId={pool.chainId}
            id={pool.id}
            className="ClaimRewardsModal__pool-entity"
          />
        </div>
        <Table minWidth={841}>
          <TableHead>
            <TableHeading flex={4}>
              <TableSubHeading title="Epoch" flex={1} />
              <TableSubHeading title="Rewards" flex={1} />
            </TableHeading>
          </TableHead>
          <TableBody>
            {epochs.map((epoch, index) => {
              return (
                <TableRow key={index}>
                  <TableCell flex={4}>
                    <TableSubCell value={epoch.epoch.toString()} flex={1} />
                    <TableSubCell
                      value={`${formatFORT(epoch.rewards)} FORT`}
                      flex={1}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className="ClaimRewardsModal__claim">
          <div className="ClaimRewardsModal__claim-total">
            <div className="ClaimRewardsModal__claim-total-label">
              Total tokens
            </div>
            <div className="ClaimRewardsModal__claim-total-value">
              {formatFORT(totalRewards)} FORT
            </div>
          </div>
          <div className="ClaimRewardsModal__claim-submit">
            <ContractCallButton
              size="lg"
              onCall={() =>
                rewardsDistributor.claimRewards(
                  subjectType,
                  pool.id,
                  epochsArray
                )
              }
              onCompleted={(tx?: providers.TransactionResponse) => {
                onSuccess(tx);
                onClose();
              }}
            >
              Claim
            </ContractCallButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClaimRewardsModal;
