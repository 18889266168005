import { FunctionComponent } from 'react';
import cn from 'classnames';

import './TabIntro.scss';

import {
  BaseComponentProps,
  createSubComponent
} from 'common/components-v2/utils';

type TabIntroSubComponents = {
  Title: typeof TabIntroTitle;
  Description: typeof TabIntroDescription;
  Content: typeof TabIntroContent;
};

type TabIntroType = FunctionComponent<BaseComponentProps> &
  TabIntroSubComponents;

const TabIntro: TabIntroType = function TabIntro(
  props: BaseComponentProps
): JSX.Element {
  const { children, className } = props;
  return <div className={cn('TabIntro', className)}>{children}</div>;
};

const TabIntroTitle: FunctionComponent<BaseComponentProps> = createSubComponent(
  'TabIntroTitle',
  'TabIntro__title'
);

const TabIntroDescription: FunctionComponent<BaseComponentProps> =
  createSubComponent('TabIntroDescription', 'TabIntro__description');

const TabIntroContent: FunctionComponent<BaseComponentProps> =
  createSubComponent('TabIntroContent', 'TabIntro__content');

TabIntro.Title = TabIntroTitle;
TabIntro.Description = TabIntroDescription;
TabIntro.Content = TabIntroContent;

export default TabIntro;
