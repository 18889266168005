import { Routes } from 'common/routes';

import './Footer.scss';

export default function Footer(): JSX.Element {
  return (
    <footer className="Footer">
      <div className="Footer__company">
        <ul className="Footer__links">
          <li className="Footer__link">
            <a href="https://forta.org/" target="_blank" rel="noreferrer">
              <span></span>©️ 2024 Forta Foundation
            </a>
          </li>
        </ul>
      </div>
      <div className="Footer__links">
        <div className="Footer__links-item">
          <a href={Routes.external.discord} target="_blank" rel="noreferrer">
            Discord
          </a>
        </div>
        <div className="Footer__links-item">
          <a href={Routes.flatPages.legal} target="_blank" rel="noreferrer">
            Terms of Service
          </a>
        </div>
        <div className="Footer__links-item">
          <a
            href={Routes.flatPages.privacyPolicy}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}
