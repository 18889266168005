import { useWeb3React } from '@web3-react/core';
import Modal from 'common/components/Modal';
import ContractCallButton from '../ContractCallButton';
import RewardsDistributor from 'forta-app/lib/contract-interactors/rewardsDistributor';
import { useEffect, useState } from 'react';
import NumberInput from 'common/components-v2/Form/Input/NumberInput';
import './ScanNodePoolActionsButton.scss';
import MessageBox from 'common/components/MessageBox';
import Button from 'common/components-v2/Button/Button';
import { useJWT } from 'forta-app/app/hooks';
import { ScannerPool } from 'common/hooks/useScannerPoolsQuery';
import CommissionDisplay from './CommissionDisplay';

interface UpdateCommissionModalProps {
  opened: boolean;
  delegation?: boolean;
  onClose: () => void;
  pool: ScannerPool;
}

export const UpdateCommissionModal = ({
  opened,
  pool,
  onClose
}: UpdateCommissionModalProps): JSX.Element => {
  const web3React = useWeb3React();
  const rewardsDistributor = new RewardsDistributor(web3React);
  const jwt = useJWT();
  const [feeInput, setFeeInput] = useState<string>('');
  const [epochAlreadyChanged, setEpochAlreadyChanged] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (jwt) {
      setLoading(true);
      rewardsDistributor
        .canEditCommission(pool.commissionSinceEpoch)
        .then((canEdit) => {
          setEpochAlreadyChanged(!canEdit);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, pool.commissionSinceEpoch]);

  return (
    <Modal
      opened={opened}
      title="Update Scan node Pool commission"
      onCloseModal={onClose}
    >
      <div className="UpdateCommissionModal">
        <div className="UpdateCommissionModal__description">
          Indicate the amount that you take from the rawards originated from
          delegated stake.
        </div>
        <div className="UpdateCommissionModal__current-commission">
          Current commission:{' '}
          <span className="UpdateCommissionModal__current-commission-amount">
            <CommissionDisplay pool={pool} />
          </span>
        </div>
        <div className="UpdateCommissionModal__commission-form">
          <div className="UpdateCommissionModal__commission-label">
            New commission:
          </div>
          <div className="UpdateCommissionModal__commission-input">
            <NumberInput
              name="input-new-commission"
              type="percentage"
              value={feeInput}
              onChange={(event: { target: { value: string } }) =>
                setFeeInput(event.target.value)
              }
            />
          </div>
          <div className="UpdateCommissionModal__commission-submit">
            <ContractCallButton
              disabled={epochAlreadyChanged || loading}
              onCall={() =>
                rewardsDistributor.setDelegationFeeBps(
                  pool.id,
                  String(Math.floor(Number(feeInput) * 100))
                )
              }
            >
              {loading ? 'Loading...' : 'Update Commission'}
            </ContractCallButton>
          </div>
          {epochAlreadyChanged ? (
            <MessageBox title="Commission already set" messageType="warning">
              A new commission has already been set. You can change the
              commission again at the end of the cooldown period.
            </MessageBox>
          ) : null}
          <MessageBox title="Scheduled Update">
            Commission updates are effectively changed in the subsequent epoch
            to allow delegators time to react to changes.
          </MessageBox>
          <MessageBox title="Cooldown Period">
            Once the commission is changed, it cannot be changed again for two
            epochs (excluding current epoch).
          </MessageBox>
        </div>
      </div>
    </Modal>
  );
};

export const UpdateCommissionButton = ({
  pool,
  className
}: {
  pool: ScannerPool;
  className?: string;
}): JSX.Element => {
  const [opened, setOpened] = useState<boolean>(false);
  return (
    <div className={className}>
      <Button
        className="ScanNodePoolActionsButton__option-button"
        variant="default"
        size="sm"
        onClick={() => setOpened(true)}
      >
        Change commission rate
      </Button>
      <UpdateCommissionModal
        opened={opened}
        pool={pool}
        onClose={() => setOpened(false)}
      />
    </div>
  );
};
