import React, { useMemo } from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { ContractTransaction, ethers } from 'ethers';
import { TransactionResponse } from '@ethersproject/providers';
import BigNumberJs from 'bignumber.js';

import './DepositForm.scss';

import InfoPopover from 'common/components/InfoPopover';
import { formatFORT, formatFORTToNumber } from '../../../lib/utils';
import Input from 'common/components-v2/Form/Input/Input';
import Button from 'common/components-v2/Button/Button';
import ContractCallButton from '../../ContractCallButton';
import MessageBox from 'common/components/MessageBox';

type DepositFormProps = {
  formTitleText: string;
  formSubmitButtonText: string;
  // this prop is used for allowance warning
  actionName?: string;

  inputValue: string;
  balance: ethers.BigNumber | null;
  allowance: ethers.BigNumber;
  loading?: boolean;

  onInputValueChange: (value: string) => unknown;
  onApprove: (
    value: string
  ) => Promise<TransactionResponse | ContractTransaction>;
  onApproveDone: () => unknown;
  onDeposit: (
    value: string
  ) => Promise<TransactionResponse | ContractTransaction>;
  onDepositDone: () => unknown;
  className?: string;
};

function DepositForm(props: DepositFormProps): JSX.Element {
  const {
    formTitleText,
    formSubmitButtonText,
    actionName,

    balance,
    allowance,
    inputValue,

    loading = false,

    onInputValueChange,
    onApprove,
    onApproveDone,
    onDeposit,
    onDepositDone,

    className
  } = props;

  const isAllowanceEnough = useMemo(() => {
    try {
      const allowanceBN = new BigNumberJs(allowance.toString());
      const inputBN = new BigNumberJs(inputValue.trim() || '0').multipliedBy(
        new BigNumberJs(10).pow(18)
      );
      return allowanceBN.isGreaterThanOrEqualTo(inputBN);
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [inputValue, allowance]);

  return (
    <div className={cn('DepositForm', className)}>
      <div className="DepositForm__header">
        <span className="DepositForm__title">{formTitleText}</span>
        <span className="DepositForm__balance">
          Balance:{' '}
          {balance ? (
            <InfoPopover content="Click to insert value">
              <button
                onClick={() =>
                  onInputValueChange(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    formatFORTToNumber(balance!).toString()
                  )
                }
                className="DepositForm__value-button"
              >
                {formatFORT(balance) + ' FORT'}
              </button>
            </InfoPopover>
          ) : (
            <Skeleton width={100} />
          )}
        </span>
      </div>
      <Input
        variant="dark"
        name="fort-amount-stake"
        value={inputValue}
        placeholder="Enter FORT amount..."
        components={{
          right: [
            {
              width: 65,
              render: function MaxButton() {
                return (
                  <Button
                    variant="tertiary-primary"
                    size="sm"
                    disabled={!balance || balance.isZero()}
                    onClick={() =>
                      onInputValueChange(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        formatFORTToNumber(balance!).toString()
                      )
                    }
                  >
                    MAX
                  </Button>
                );
              }
            }
          ]
        }}
        pattern="^[0-9]*[.]?[0-9]*$"
        onChange={(e) => onInputValueChange(e.target.value)}
        className="DepositForm__input"
      />
      <div className="DepositForm__actions">
        {allowance.isZero() || !isAllowanceEnough ? (
          <ContractCallButton
            waitForConfirmation
            size="md"
            loading={loading}
            disabled={!Number(inputValue) || loading}
            onCall={() => onApprove(inputValue)}
            onCompleted={onApproveDone}
          >
            Approve
          </ContractCallButton>
        ) : (
          <ContractCallButton
            waitForConfirmation
            size="md"
            loading={loading}
            disabled={!Number(inputValue) || loading}
            onCall={() => onDeposit(inputValue)}
            onCompleted={onDepositDone}
          >
            {formSubmitButtonText}
          </ContractCallButton>
        )}
      </div>
      <div className="DepositForm__allowance">
        <div className="DepositForm__allowance-label">
          Approved amount:{' '}
          <InfoPopover content="Click to insert value">
            <button
              onClick={() =>
                onInputValueChange(formatFORTToNumber(allowance).toString())
              }
              className="DepositForm__value-button"
            >
              {allowance ? (
                formatFORT(allowance) + ' FORT'
              ) : (
                <Skeleton width={100} />
              )}
            </button>
          </InfoPopover>
        </div>
      </div>
      {!isAllowanceEnough && (
        <MessageBox
          title="Approve your tokens to continue"
          messageType="warning"
        >
          The amount of FORT input exceeds the amount of FORT you&apos;ve
          approved{actionName ? ` to ${actionName}.` : '.'}
        </MessageBox>
      )}
    </div>
  );
}

export default DepositForm;
