import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getSubjectStake, SubjectResult } from 'forta-app/lib/apis/subgraphAPI';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';

// TODO Refactor to use useGraphQuery()

type UseSubjectStakeOpts = {
  params: {
    botId: string;
    subjectType: SubjectType;
  };
  enabled?: boolean;
};

type Result = {
  stakeSubject: SubjectResult | null;
};

function useSubjectStake(opts: UseSubjectStakeOpts): Result {
  const {
    params: { botId, subjectType },
    enabled
  } = opts;

  const [stakeSubject, setStakeSubject] = useState<SubjectResult | null>(null);

  useEffect(() => {
    if (enabled) {
      (async () => {
        try {
          setStakeSubject(await getSubjectStake(botId, subjectType));
        } catch (error) {
          toast.error(error);
        }
      })();
    }
  }, [botId, subjectType, enabled]);

  return {
    stakeSubject
  };
}

export default useSubjectStake;
