import React from 'react';
import cn from 'classnames';

import { keyDownA11y } from '../utils';
import './TableRow.scss';

interface TableRowProps {
  variant?: 'default' | 'transparent';
  clickable?: boolean;
  highlighted?: boolean;
  className?: string;
  onClick?: () => unknown;
  children: React.ReactNode;
}

function TableRow(props: TableRowProps): JSX.Element {
  const {
    variant = 'default',
    clickable,
    highlighted,
    onClick,
    children
  } = props;

  function handleClick(): void {
    if (onClick) onClick();
  }

  const elementProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > = clickable
    ? {
        role: 'button',
        tabIndex: 0,
        onClick: handleClick,
        onKeyDown: keyDownA11y(handleClick)
      }
    : {};

  return (
    <div
      className={cn('TableRow', `TableRow--${variant}`, {
        ['TableRow--highlighted']: highlighted,
        [props.className || '']: props.className
      })}
      {...elementProps}
    >
      {children}
    </div>
  );
}

export default TableRow;
