import Table from 'common/components-v2/Table/Table';
import TableBody from 'common/components-v2/Table/TableBody';
import TableRow from 'common/components-v2/Table/TableRow';
import TableCell from 'common/components-v2/Table/TableCell';
import React, { useEffect, useState } from 'react';
import Modal from '../../common/components/Modal';
import './MyAPIKeysPage.scss';
import { ArrowLeft2Icon, TrashIcon } from 'common/components/Icons';
import useGraphQuery from 'common/hooks/useGraphQuery';
import { useJWT } from 'forta-app/app/hooks';
import { gql } from '@apollo/client';
import { ApolloClientName } from 'common/components/ApolloClientProvider';
import { deleteAPIKey, generateAPIKey } from 'forta-app/lib/apis/apiKeysAPI';
import Button from 'common/components-v2/Button/Button';
import CopyButton from 'common/components/CopyButton';
import { toast } from 'react-toastify';
import Loader from 'common/components/Loader';

const GET_API_KEYS_QUERY = gql`
  query GetAPIKeys($input: GetAPIKeysInput) {
    getAPIKeys(input: $input) {
      keyId
      createdAt
    }
  }
`;

type ModalView = 'main' | 'newApiKey' | 'deleteApiKey';

const MyAPIKeysPage = ({
  opened,
  onCloseModal
}: {
  opened: boolean;
  onCloseModal: () => void;
}): JSX.Element => {
  const [modalView, setModalView] = useState<ModalView>('main');
  const [isNewApiKeyLoading, setIsNewApiKeyLoading] = useState(false);
  const [newApiKey, setNewApiKey] = useState<null | {
    id: string;
    createdAt: string;
    secret: string;
  }>(null);
  const [hasCopiedSecret, setHasCopiedSecret] = useState(false);
  const [deleteAPIKeyID, setDeleteAPIKeyID] = useState('');
  const [isDeleteAPIKeyLoading, setDeleteAPIKeyLoading] = useState(false);
  const jwt = useJWT();

  const {
    data: _apiKeysData,
    loading: isLoading,
    error,
    refetch: fetchApiKeys
  } = useGraphQuery<
    { input: { jwt: string } },
    {
      getAPIKeys: {
        keyId: string;
        createdAt: string;
      }[];
    }
  >({
    query: GET_API_KEYS_QUERY,
    variables: {
      input: {
        jwt
      }
    },
    refetchOnMount: true,
    enabled: !!jwt,
    clientName: ApolloClientName.Forta
  });
  const apiKeys = _apiKeysData?.getAPIKeys || [];

  if (error) {
    console.error(error);
  }

  const handleReturnToMainView = (): void => {
    setModalView('main');
    setNewApiKey(null);
    setDeleteAPIKeyID('');
    setHasCopiedSecret(false);
    setIsNewApiKeyLoading(false);
    setDeleteAPIKeyLoading(false);
  };

  useEffect(() => {
    if (opened) {
      handleReturnToMainView();
    }
  }, [opened]);

  const handleCreateNewApiKey = async (): Promise<void> => {
    setModalView('newApiKey');
    setIsNewApiKeyLoading(true);
    try {
      const newKey = await generateAPIKey(jwt);
      setNewApiKey({
        id: newKey.keyId,
        createdAt: new Date().toISOString(),
        secret: newKey.secret
      });
      setIsNewApiKeyLoading(false);
    } catch (error) {
      if (error.message === 'Unexpected error value: "api key limit reached"') {
        toast.error('API key limit reached');
      }
      console.error(error);
      handleReturnToMainView();
    }
  };

  const handleConfirmNewApiKey = (): void => {
    if (!hasCopiedSecret) {
      toast.warn('Please copy the secret key before confirming.');
      return;
    }

    if (newApiKey) {
      handleReturnToMainView();
      fetchApiKeys();
    }
  };

  const handleDeleteApiKey = async (keyId: string): Promise<void> => {
    setDeleteAPIKeyID(keyId);
    setModalView('deleteApiKey');
    setDeleteAPIKeyLoading(false);
  };

  const handleDeleteApiKeyConfirmed = async (): Promise<void> => {
    setDeleteAPIKeyLoading(true);
    try {
      // Implement API call to delete an API key here
      await deleteAPIKey({ jwt, keyId: deleteAPIKeyID });
      handleReturnToMainView();
      fetchApiKeys();
    } catch (error) {
      toast.error('Error while trying to delete key');
      console.error(error);
      setDeleteAPIKeyLoading(false);
    }
  };

  const renderMainView = (): JSX.Element => (
    <>
      <div className="MyAPIKeysPage__header">
        <span className="MyAPIKeysPage__title">My API Keys</span>
      </div>
      <div className="MyAPIKeysPage__content">
        <Table minWidth={600}>
          <TableBody empty={apiKeys.length === 0} loading={isLoading}>
            {apiKeys.map((key) => (
              <TableRow key={key.keyId}>
                <TableCell flex={12}>
                  <div className="MyAPIKeysPage__cell">
                    <div className="MyAPIKeysPage__keyId">{key.keyId}</div>
                    <div className="MyAPIKeysPage__actions">
                      <button
                        className="MyAPIKeysPage__deleteIcon"
                        onClick={() => handleDeleteApiKey(key.keyId)}
                      >
                        {TrashIcon}
                      </button>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="MyAPIKeysPage__newApiKeyButton-container">
          <Button
            variant="primary"
            size="sm"
            className="MyAPIKeysPage__newApiKeyButton"
            onClick={handleCreateNewApiKey}
          >
            Create new
          </Button>
        </div>
      </div>
    </>
  );

  const renderNewApiKeyView = (): JSX.Element => (
    <>
      <div className="MyAPIKeysPage__header">
        <div className="MyAPIKeysPage__title">
          <Button
            icon={ArrowLeft2Icon}
            size="sm"
            variant="tertiary"
            onClick={handleReturnToMainView}
          />
          New API Key
        </div>
      </div>
      {!isNewApiKeyLoading && newApiKey ? (
        <>
          <div className="MyAPIKeysPage__content">
            <p className="MyAPIKeysPage__modal-description">
              You should copy the API key below. This is the only time you will
              be able to see it.{' '}
              <a
                href="https://docs.forta.network/en/latest/api-keys/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read documentation.
              </a>
            </p>
            <div>
              <div className="MyAPIKeysPage__modal-item">
                <div className="MyAPIKeysPage__modal-item-label">Key ID</div>
                <div className="MyAPIKeysPage__modal-item-value">
                  {newApiKey.id}
                </div>
              </div>
              <div className="MyAPIKeysPage__modal-item">
                <div className="MyAPIKeysPage__modal-item-label">API key</div>
                <div className="MyAPIKeysPage__modal-item-value">
                  {newApiKey.secret}
                </div>
                <CopyButton text={newApiKey.secret} />
              </div>
            </div>
            <label className="MyAPIKeysPage__confirm-copy">
              <input
                name="hasCopiedSecret"
                type="checkbox"
                checked={hasCopiedSecret}
                onChange={() => setHasCopiedSecret(!hasCopiedSecret)}
              />
              <span>I&apos;ve copied the API key</span>
            </label>
          </div>
          <div className="MyAPIKeysPage__footer">
            <div>
              <Button
                startIcon={ArrowLeft2Icon}
                size="sm"
                variant="tertiary"
                onClick={handleReturnToMainView}
              >
                Cancel &amp; back
              </Button>
            </div>
            <div className="MyAPIKeysPage__confirmButton">
              <Button
                variant="primary"
                size="sm"
                onClick={handleConfirmNewApiKey}
                disabled={!hasCopiedSecret}
              >
                Confirm
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="MyAPIKeysPage__loader">
          <Loader />
        </div>
      )}
    </>
  );

  const renderDeleteApiKeyView = (): JSX.Element => (
    <>
      <div className="MyAPIKeysPage__header">
        <div className="MyAPIKeysPage__title">
          <Button
            icon={ArrowLeft2Icon}
            size="sm"
            variant="tertiary"
            onClick={handleReturnToMainView}
          />
          Delete API Key
        </div>
      </div>
      <div className="MyAPIKeysPage__content">
        <div className="MyAPIKeysPage__delete-modal-description">
          Are you sure you want to delete the following API Key?
        </div>
        <div className="MyAPIKeysPage__delete-modal-key">{deleteAPIKeyID}</div>
      </div>
      <div className="MyAPIKeysPage__footer">
        <div>
          <Button
            startIcon={ArrowLeft2Icon}
            size="sm"
            variant="tertiary"
            onClick={handleReturnToMainView}
          >
            Cancel &amp; back
          </Button>
        </div>
        <div className="MyAPIKeysPage__delete-modal-confirm">
          <Button
            variant="primary"
            size="sm"
            onClick={handleDeleteApiKeyConfirmed}
            disabled={isDeleteAPIKeyLoading}
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  );

  const renderModalContent = (): JSX.Element => {
    switch (modalView) {
      case 'newApiKey':
        return renderNewApiKeyView();
      case 'deleteApiKey':
        return renderDeleteApiKeyView();
      default:
        return renderMainView();
    }
  };

  return (
    <Modal
      opened={opened}
      onCloseModal={onCloseModal}
      className="MyAPIKeysPage__modal"
    >
      <div className="MyAPIKeysPage">{renderModalContent()}</div>
    </Modal>
  );
};

export default MyAPIKeysPage;
