import { gql } from '@apollo/client';
import useGraphQuery, { GraphQueryResult } from './useGraphQuery';
import { ApolloClientName } from '../components/ApolloClientProvider';
import { ScannerAlert } from '../lib/apis/alertAPI';

export const SCANNER_ALERTS_QUERY = gql`
  query ScannerAlert($input: GetScannerAlertsInput) {
    getScannerAlert(input: $input) {
      scannerAlerts {
        scanner
        alert_timestamp
        metadata
        batch {
          tx_hash
          timestamp
          reference
          block {
            chain_id
          }
        }
        signature {
          signature
          algorithm
          signer
        }
      }
      nextPageValues {
        timestamp
        tx_hash
      }
      currentPageValues {
        timestamp
        tx_hash
      }
    }
  }
`;

type UseScannerAlertParams = {
  hash: string;
  pageValues?: {
    timestamp: string;
    tx_hash: string;
  };
};

type ScannerAlertsResponse = {
  getScannerAlert: {
    scannerAlerts: ScannerAlert[];
  };
};

function useScannerAlertsQuery(opts: {
  params: UseScannerAlertParams;
  enabled?: boolean;
  refetchOnMount?: boolean;
}): GraphQueryResult<
  { input: UseScannerAlertParams },
  ScannerAlertsResponse
> & {
  scannerAlerts: ScannerAlert[];
} {
  const { params, refetchOnMount, enabled = true } = opts || {};

  const queryResult = useGraphQuery<
    { input: UseScannerAlertParams },
    ScannerAlertsResponse
  >({
    query: SCANNER_ALERTS_QUERY,
    variables: {
      input: params
    },
    enabled: enabled,
    refetchOnMount: refetchOnMount,
    clientName: ApolloClientName.Forta
  });

  return {
    scannerAlerts: queryResult.data?.getScannerAlert.scannerAlerts || [],
    ...queryResult
  };
}

export default useScannerAlertsQuery;
