import React, { FunctionComponent } from 'react';
import cn from 'classnames';

import { BaseComponentProps, createSubComponent } from '../../utils';

import './Intro.scss';

import Button from 'common/components-v2/Button/Button';
import { ExternalLinkIcon } from 'common/components/Icons';

type IntroComponentType = FunctionComponent<BaseComponentProps> & {
  Title: typeof Title;
  Description: typeof Description;
  DocsButton: typeof DocsButton;
};

const Intro: IntroComponentType = function Intro(
  props: BaseComponentProps
): JSX.Element {
  const { children, className } = props;
  return <div className={cn('Intro', className)}>{children}</div>;
};

type DocsButtonProps = {
  label: string;
  href?: string;
  to?: string;
  icon?: JSX.Element;
  onClick?: () => unknown;
  className?: string;
};

function DocsButton(props: DocsButtonProps): JSX.Element {
  const {
    label,
    icon = ExternalLinkIcon,
    href,
    to,
    onClick,
    className
  } = props;

  return (
    <Button
      size="lg"
      variant="outline"
      to={to}
      href={href}
      endIcon={icon}
      target={href ? '_blank' : undefined}
      onClick={onClick}
      className={cn('Intro__docs-button', className)}
    >
      {label}
    </Button>
  );
}

const Title = createSubComponent('IntroTitle', 'Intro__title', 'h1');
const Description = createSubComponent(
  'IntroDescription',
  'Intro__description',
  'p'
);

Intro.Title = Title;
Intro.Description = Description;
Intro.DocsButton = DocsButton;

export default Intro;
