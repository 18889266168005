import React from 'react';
import cn from 'classnames';

import './FileSelector.scss';

import { UploadIcon } from 'common/components/Icons';
import InfoPopover from './InfoPopover';

const FILE_TYPES = '.md,.markdown';

type FileSelectorProps = {
  name: string | null;
  onChange: (file: File | null) => void;
  errored?: boolean;
  subtext?: string;
  description?: string;
  className?: string;
};

export default function FileSelector(props: FileSelectorProps): JSX.Element {
  const { name, onChange, errored, subtext, description, className } = props;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event?.target?.files && event?.target?.files[0]) {
      onChange(event.target.files[0]);
    } else {
      onChange(null);
    }
  };

  return (
    <>
      <label
        className={cn('FileSelector', className, {
          ['FileSelector--errored']: errored
        })}
      >
        <div className="FileSelector__box">
          <input
            name="file"
            type="file"
            multiple={false}
            accept={FILE_TYPES}
            onChange={handleChange}
            value={[]}
          />
          {!name && (
            <div className="FileSelector__upload-text">
              <div className="FileSelector__upload-icon">{UploadIcon}</div>
              Upload Markdown documentation files
              {description && (
                <InfoPopover
                  className="FileSelector__tooltip"
                  content={description}
                />
              )}
            </div>
          )}
          {name && <div className="FileSelector__file-name">{name}</div>}
        </div>
        {subtext && <div className="FileSelector__subtext">{subtext}</div>}
      </label>
    </>
  );
}
