import {
  ApolloError,
  gql,
  NetworkStatus,
  QueryResult,
  useQuery
} from '@apollo/client';

import {
  Alert,
  AlertListApiResponse,
  AlertsApiParams
} from 'common/lib/apis/alertAPI';

export const ALERTS_QUERY = gql`
  query RetrieveAlerts($getListInput: GetAlertsInput) {
    getList(input: $getListInput) {
      alerts {
        hash
        description
        severity
        protocol
        name
        alert_id
        scanner_count
        alert_document_type
        alert_timestamp
        metadata
        detected_at
        source {
          tx_hash
          agent {
            id
          }
          block {
            chain_id
            number
            timestamp
          }
          transactions {
            chainId
            hash
          }
          source_alert {
            hash
            timestamp
          }
          chains {
            chainId
          }
        }
      }
      nextPageValues {
        timestamp
        id
      }
      currentPageValues {
        timestamp
        id
      }
    }
  }
`;

type AlertPageValues = {
  timestamp: string;
  id: string;
};

// this function clears Apollo system properties
const preparePageValues = (
  obj?: AlertPageValues
): AlertPageValues | undefined => {
  if (!obj) return undefined;
  return { id: obj.id, timestamp: obj.timestamp };
};

function useAlertsQuery(opts: { params: AlertsApiParams }): {
  data: { getList: AlertListApiResponse } | undefined;
  alerts: Alert[];
  currentPageValues: AlertPageValues | undefined;
  nextPageValues: AlertPageValues | undefined;
  refetch: () => Promise<unknown>;
  error: ApolloError | undefined;
  loading: boolean;
  refetching: boolean;
  queryResult: QueryResult;
} {
  const { params = {} } = opts || {};

  const queryResult = useQuery<{ getList: AlertListApiResponse }>(
    ALERTS_QUERY,
    {
      variables: { getListInput: params },
      notifyOnNetworkStatusChange: true
    }
  );

  const { loading, error, networkStatus, refetch } = queryResult;
  const data = queryResult.data || queryResult.previousData;

  return {
    data: data,
    alerts: data?.getList.alerts || [],
    currentPageValues: preparePageValues(data?.getList.currentPageValues),
    nextPageValues: preparePageValues(data?.getList.nextPageValues),
    error,
    loading,
    refetching: networkStatus === NetworkStatus.refetch,
    queryResult,
    refetch
  };
}

export default useAlertsQuery;
