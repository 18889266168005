import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import ConfirmationModal, {
  ConfirmationResultMessage
} from 'common/components/ConfirmationModal';
import InfoPopover from 'common/components/InfoPopover';
import Loader from 'common/components/Loader';

import { useAppDispatch, useAppSelector, useJWT } from 'forta-app/app/hooks';
import {
  deleteEmail,
  triggerEmailVerification
} from 'forta-app/lib/apis/notificationAPI';
import { loginAddress } from 'forta-app/lib/apis/loginAPI';
import { setLoginData } from 'forta-app/slices/walletSlice';
import { retrieveNotifications } from 'forta-app/slices/notificationSlice';
import { signMessage } from 'forta-app/components/wallet/WalletConnector';
import './NotificationEmailList.scss';
import Button from 'common/components-v2/Button/Button';

export default function NotificationEmailList(): JSX.Element {
  const dispatch = useAppDispatch();
  const jwt = useJWT();
  const web3React = useWeb3React();
  const emails = useAppSelector((state) => state.notification.emails);
  const notificationsLoading = useAppSelector(
    (state) => state.notification.notificationsLoading
  );
  const [loadingItem, setLoadingItem] = useState<number>(-1);
  const [emailToRemove, setEmailToRemove] = useState<string>('');
  const [removeModalOpened, setRemoveModalOpened] = useState<boolean>(false);

  const loadEmails = useCallback((): void => {
    dispatch(retrieveNotifications({ jwt }));
  }, [jwt, dispatch]);

  const onResendVerificationClick = async (index: number): Promise<void> => {
    setLoadingItem(index);
    const result = await triggerEmailVerification({
      email: emails[index].email,
      jwt
    });
    setLoadingItem(-1);
    if (result.success) {
      toast.success('Email verification sent.');
    } else {
      toast.error(result.error);
    }
  };

  const onRemoveNotificationClick = useCallback(
    async (index: number): Promise<void> => {
      setEmailToRemove(emails[index]['email']);
      setRemoveModalOpened(true);
    },
    [emails]
  );

  const onRemoveEmail =
    useCallback(async (): Promise<ConfirmationResultMessage> => {
      let _jwt = jwt;
      if (!_jwt) {
        const result = await signMessage(web3React);
        if (result) {
          _jwt = await loginAddress(result);
          dispatch(
            setLoginData({
              jwt: _jwt
            })
          );
        }
      }

      const email = emailToRemove;
      const result = await deleteEmail({ jwt: _jwt, email });
      if (result.success) {
        await loadEmails();
        return {
          type: 'success',
          message: `Email ${email} was removed.`
        };
      } else {
        return {
          type: 'error',
          message: result.error
        };
      }
    }, [emailToRemove, jwt, loadEmails, dispatch, web3React]);

  let content = null;

  if (!jwt || !emails.length || notificationsLoading) {
    content = <></>;
  } else {
    content = (
      <>
        <div className="NotificationEmailList__title">
          Verified emails{' '}
          <InfoPopover content="Unverified emails will not receive notifications" />
        </div>
        <div className="NotificationEmailList__list">
          {emails.map(({ email, isVerified }, index) => (
            <div
              className="NotificationEmailList__email-row"
              key={`email-${email}-${index}`}
            >
              <div className="NotificationEmailList__email-content">
                <div className="NotificationEmailList__email-value">
                  {email}
                </div>
                <div
                  className="NotificationEmailList__email-state"
                  data-verified={isVerified}
                >
                  {isVerified ? 'Verified' : 'Unverified'}
                </div>
              </div>
              <div className="NotificationEmailList__email-actions">
                {!isVerified ? (
                  loadingItem == index ? (
                    <Loader />
                  ) : (
                    <Button
                      size="sm"
                      variant="secondary"
                      className="NotificationActionButton"
                      onClick={() => onResendVerificationClick(index)}
                    >
                      Resend verification
                    </Button>
                  )
                ) : null}
                <Button
                  size="sm"
                  variant="secondary"
                  className="NotificationActionButton"
                  onClick={() => onRemoveNotificationClick(index)}
                >
                  Remove
                </Button>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="NotificationEmailList">
      {content}
      <ConfirmationModal
        title="Remove email"
        description={`Are you sure you want to remove ${emailToRemove}? All notifications that use this email will be removed too.`}
        actionButtonText="Remove"
        opened={removeModalOpened}
        onClose={() => setRemoveModalOpened(false)}
        onCancel={() => setRemoveModalOpened(false)}
        onSubmit={onRemoveEmail}
      />
    </div>
  );
}
