import moment from 'moment';

import { Agent } from 'common/lib/apis/agentAPI';
import ls from 'common/lib/localStorage';

import {
  AgentUpdate,
  AgentUpdateType
} from 'forta-app/components/agent-management/BotMenuCell';

interface PendingAgent {
  agentId: string;
  address: string;
  name: string;
}

const PENDING_AGENTS_LS_KEY = 'pending_agents_v2_';
const PENDING_AGENT_UPDATE_KEY = 'pending_agent_updates_v2_';

export const getPendingAgents = (): PendingAgent[] => {
  const agentsRaw = ls.getItem(PENDING_AGENTS_LS_KEY) || '[]';

  try {
    const agents = JSON.parse(agentsRaw);
    if (!Array.isArray(agents)) {
      return [];
    } else {
      return agents
        .map((agent) => {
          return {
            agentId: agent?.agentId || '',
            address: agent?.address || '',
            name: agent?.name || ''
          };
        })
        .filter((agent) => agent.address && agent.agentId && agent.name);
    }
  } catch (error) {
    return [];
  }
};

export const addPendingAgent = (agent: PendingAgent): void => {
  const pendingAgents = getPendingAgents();
  const newPendingAgentList = pendingAgents.filter(
    (_agent) => _agent.agentId !== agent.agentId
  );
  newPendingAgentList.push({
    agentId: agent.agentId,
    address: agent.address,
    name: agent.name
  });
  ls.setItem(PENDING_AGENTS_LS_KEY, JSON.stringify(newPendingAgentList));
};

export const removePendingAgents = (agentIds: string[]): void => {
  const pendingAgents = getPendingAgents();
  const newPendingAgentList = pendingAgents.filter(
    (_agent) => !agentIds.includes(_agent.agentId)
  );
  ls.setItem(PENDING_AGENTS_LS_KEY, JSON.stringify(newPendingAgentList));
};

export const getPendingUpdates = (): AgentUpdate[] => {
  const updatesRaw = ls.getItem(PENDING_AGENT_UPDATE_KEY) || '[]';

  try {
    const updates = JSON.parse(updatesRaw);
    if (!Array.isArray(updates)) {
      return [];
    } else {
      return updates
        .map((agentUpdate) => {
          return {
            agentId: agentUpdate?.agentId || '',
            type: agentUpdate?.type || 0,
            date: agentUpdate?.date || 0,
            txHash: agentUpdate?.txHash || ''
          };
        })
        .filter((agentUpdate) => agentUpdate.agentId);
    }
  } catch (error) {
    return [];
  }
};

export const addPendingUpdate = (agentUpdate: AgentUpdate): void => {
  const pendingUpdates = getPendingUpdates();
  const newPendingUpdates = pendingUpdates.filter(
    (_update) => _update.agentId !== _update.agentId
  );
  newPendingUpdates.push({
    agentId: agentUpdate.agentId,
    type: agentUpdate.type,
    date: agentUpdate.date,
    txHash: agentUpdate.txHash
  });
  ls.setItem(PENDING_AGENT_UPDATE_KEY, JSON.stringify(newPendingUpdates));
};

export const removePendingUpdates = (agentIds: string[]): void => {
  const pendingUpdates = getPendingUpdates();
  const newPendingUpdates = pendingUpdates.filter(
    (_update) => !agentIds.includes(_update.agentId)
  );
  ls.setItem(PENDING_AGENT_UPDATE_KEY, JSON.stringify(newPendingUpdates));
};

export const clearUpdates = (agentList: Agent[]): AgentUpdate[] => {
  const updates = getPendingUpdates();

  const toRemove: string[] = [];
  const result = updates.filter((_update) => {
    const agent = agentList.find((agent) => agent.id === _update.agentId);
    if (agent) {
      switch (_update.type) {
        case AgentUpdateType.DISABLE:
          if (!agent.enabled) {
            toRemove.push(agent.id);
            return false;
          }
          break;
        case AgentUpdateType.ENABLE:
          if (agent.enabled) {
            toRemove.push(agent.id);
            return false;
          }
          break;
        case AgentUpdateType.EDIT:
          if (moment(agent.updated_at).unix() >= moment(_update.date).unix()) {
            toRemove.push(agent.id);
            return false;
          }
          break;
      }
    }
    return true;
  });

  if (toRemove.length) removePendingUpdates(toRemove);

  return result;
};
