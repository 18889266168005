import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useWeb3React } from '@web3-react/core';
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import config from 'common/config';
import { getNetworkByChainId } from 'common/lib/networks';
import { MINUTE_TIME, shortenHash } from 'common/lib/utils';
import { ExternalLinkIcon, Plus2Icon } from 'common/components/Icons';
import { Agent, getAgents } from 'common/lib/apis/agentAPI';

import { useJWT } from 'forta-app/app/hooks';
import { getJWTAddress } from 'forta-app/slices/walletSlice';
import {
  addPendingUpdate,
  clearUpdates,
  getPendingAgents,
  getPendingUpdates,
  removePendingAgents
} from 'forta-app/lib/pendingAgents';
import BotMenuCell, {
  AgentUpdate,
  AgentUpdateCallback,
  AgentUpdateType
} from 'forta-app/components/agent-management/BotMenuCell';
import {
  BotStaking,
  SubjectType
} from 'forta-app/lib/contract-interactors/stakingContract';
import './MyAgentsPage.scss';
import { formatEther } from 'ethers/lib/utils';
import Button from 'common/components-v2/Button/Button';
import ChainIcon from 'common/components/stats/ChainIcon';
import { hasRequiredBotStake } from 'forta-app/lib/utils';
import PlanLabel from 'common/components-v2/PlanLabel/PlanLabel';
import Table from 'common/components-v2/Table/Table';
import TableHead from 'common/components-v2/Table/TableHead';
import TableSubHeading from 'common/components-v2/Table/TableSubHeading';
import TableBody from 'common/components-v2/Table/TableBody';
import TableRow from 'common/components-v2/Table/TableRow';
import TableCell from 'common/components-v2/Table/TableCell';
import TableSubCell from 'common/components-v2/Table/TableSubCell';
import TableHeading, {
  TableActionHeading
} from 'common/components-v2/Table/TableHeading';
import Pagination from 'common/components-v2/Pagination/Pagination';
import useBotCount from 'common/hooks/useBotCount';
import useQueryPagination from 'common/hooks/useQueryPagination';
import useOffsetPagination from 'common/hooks/useOffsetPagination';
import CopyButton from 'common/components/CopyButton';

const clearPendingAgents = (_agents: Agent[], address: string): Agent[] => {
  removePendingAgents(_agents.map((_agent) => _agent.id));
  const pendingAgents = getPendingAgents();
  return pendingAgents
    .map((agent) => ({
      id: agent.agentId,
      name: agent.name,
      developer: agent.address
    }))
    .filter((agent) => agent.developer === address);
};

const PAGE_SIZE = 10;
const TABLE_EL_ID = 'my-bots-table';

export default function MyAgentsPage(): JSX.Element {
  const jwt = useJWT();
  const address = getJWTAddress(jwt);
  const web3React = useWeb3React();
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingAgents, setPendingAgents] = useState<Agent[]>([]);
  const [pendingUpdates, setPendingUpdates] = useState<AgentUpdate[]>(() =>
    getPendingUpdates()
  );

  const pagination = useQueryPagination(useOffsetPagination(PAGE_SIZE));

  const { botCount, enabledBotCount } = useBotCount({
    params: { developer: address },
    enabled: !!jwt
  });

  const botsPageQuery = useQuery({
    enabled: !!jwt,
    queryKey: ['paginatedBots', address, pagination.page],
    queryFn: () =>
      getAgents({
        developer: address,
        pageSize: PAGE_SIZE,
        pageNumber: pagination.page
      }),
    onError: () => toast.error('Error trying to retrieve your agents')
  });

  const bots = useMemo(() => botsPageQuery.data || [], [botsPageQuery.data]);

  const handleAgentUpdate: AgentUpdateCallback = async (
    agentUpdate /*, tx*/
  ) => {
    setLoading(true);
    // Should we wait for one confirmation?
    // await tx.wait(1);
    addPendingUpdate(agentUpdate);
    setPendingUpdates(getPendingUpdates());
    setLoading(false);
  };

  useEffect(() => {
    if (bots.length === 0) return;

    setPendingUpdates(clearUpdates(bots));
    setPendingAgents(clearPendingAgents(bots, address));
  }, [bots, address]);

  if (!jwt || web3React.chainId !== config.chainId) {
    return (
      <div className="MyAgentsPage">
        <div className="MyAgentsPage__header">
          <div className="MyAgentsPage__title">My Detection Bots</div>
          <div className="MyAgentsPage__signin-message">
            Please sign in first
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="MyAgentsPage">
      <div className="MyAgentsPage__header">
        <div className="MyAgentsPage__title">My Detection Bots</div>
        <div className="MyAgentsPage__description">
          Manage bots deployed by{' '}
          <span className="MyAgentsPage__address">{address}</span>
        </div>
      </div>
      <div className="MyAgentsPage__metadata">
        <div className="MyAgentsPage__metrics">
          <div className="MyAgentsPage__metric">
            <div className="MyAgentsPage__metric-value">
              {botCount != null ? botCount : <Skeleton width={30} />}
            </div>
            <div className="MyAgentPage__metric-label">Bots</div>
          </div>
          <div className="MyAgentsPage__metric">
            <div className="MyAgentsPage__metric-value">
              {enabledBotCount != null ? (
                enabledBotCount
              ) : (
                <Skeleton width={30} />
              )}
            </div>
            <div className="MyAgentsPage__metric-label">Active</div>
          </div>
        </div>
        <div className="MyAgentsPage__deploy">
          <Button
            size="md"
            variant="primary"
            startIcon={Plus2Icon}
            to="/deploy-agent"
          >
            Deploy Bot
          </Button>
        </div>
      </div>
      <Table id={TABLE_EL_ID} minWidth={1300} className="MyAgentsPage__table">
        <TableHead>
          <TableHeading flex={29}>
            <TableSubHeading name="id" title="Bot / ID" flex={14} />
            <TableSubHeading name="stakedFort" title="Staked" flex={3} />
            <TableSubHeading name="dataPlan" title="Data plan" flex={4} />
            <TableSubHeading name="lastUpdate" title="Last update" flex={4} />
            <TableSubHeading name="status" title="Status" flex={3} />
            <TableActionHeading />
          </TableHeading>
        </TableHead>
        <TableBody
          loading={botsPageQuery.isLoading}
          empty={!bots.length && !pendingAgents.length}
          emptyMessage="You have no bots deployed."
        >
          {pendingAgents.map((agent) => {
            return (
              <TableRow
                key={`agent-row-${agent.id}`}
                className="MyAgentsPage__row"
              >
                <TableCell flex={29}>
                  <TableSubCell
                    flex={14}
                    value={
                      <div className="MyAgentsPage__agent-name">
                        {agent.name}{' '}
                        <span className="MyAgentsPage__agent-pending">
                          Pending deployment
                        </span>
                        <div className="MyAgentsPage__agent-id">{agent.id}</div>
                      </div>
                    }
                  />
                  <TableSubCell
                    flex={3}
                    value={
                      <div className="MyAgentsPage__agent-stake">Waiting</div>
                    }
                  />
                  <TableSubCell
                    flex={4}
                    value={
                      <div className="MyAgentsPage__agent-plan">Waiting</div>
                    }
                  />
                  <TableSubCell
                    flex={4}
                    value={
                      <div className="MyAgentsPage__agent-date">Waiting</div>
                    }
                  />
                  <TableSubCell
                    flex={3}
                    value={
                      <div className="MyAgentsPage__agent-status">
                        <div className="MyAgentsPage__status-box MyAgentsPage__status-box--pending">
                          Deploying
                        </div>
                      </div>
                    }
                  />
                  <TableSubCell
                    flex={1}
                    value={<div className="MyAgentsPage__agent-edit"></div>}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          {[...bots].map((agent) => {
            const pendingUpdate = pendingUpdates.find(
              (_update) => _update.agentId === agent.id
            );
            let failedUpdate = false;

            if (
              pendingUpdate &&
              moment(pendingUpdate.date).unix() <
                moment().unix() - MINUTE_TIME / 100
            ) {
              failedUpdate = true;
            }

            return (
              <BotRow
                agent={agent}
                pendingUpdate={pendingUpdate}
                key={`agent-row-${agent.id}`}
                failedUpdate={failedUpdate}
                handleAgentUpdate={handleAgentUpdate}
              />
            );
          })}
        </TableBody>
      </Table>

      {pendingUpdates.length ? (
        <Table id={'pending-bots-table'} className="PendingBotsTable">
          <TableHead>
            <TableHeading
              title="Pending updates"
              className="PendingBotsTable__header"
            ></TableHeading>
          </TableHead>
          <TableBody
            loading={loading}
            empty={!pendingUpdates.length}
            emptyMessage="You have no pending bots."
          >
            {pendingUpdates.map((_update) => {
              let updateTypeLabel = '';
              switch (_update.type) {
                case AgentUpdateType.ENABLE:
                  updateTypeLabel = 'Enable';
                  break;
                case AgentUpdateType.DISABLE:
                  updateTypeLabel = 'Disable';
                  break;
                case AgentUpdateType.EDIT:
                  updateTypeLabel = 'Update';
                  break;
              }
              return (
                <TableRow key={`${_update.date}`}>
                  <TableCell>
                    <TableSubCell
                      value={
                        <div className="MyAgentsPage__update-agent">
                          {updateTypeLabel} {_update.agentId}
                        </div>
                      }
                    />
                    <TableSubCell
                      value={
                        <div className="MyAgentsPage__update-date">
                          {moment(_update.date).fromNow()}
                        </div>
                      }
                    />
                    <TableSubCell
                      value={
                        <div className="MyAgentsPage__update-link">
                          <a
                            href={`${
                              getNetworkByChainId(config.chainId).explorerUrl
                            }/tx/${_update.txHash}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {ExternalLinkIcon}
                          </a>
                        </div>
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : null}
      <Pagination
        limit={PAGE_SIZE}
        page={pagination.page}
        itemsCount={bots.length}
        hasNextPage={bots.length ? bots.length === PAGE_SIZE : false}
        onNextPage={() => pagination.goNextPage()}
        onPrevPage={() => pagination.goPrevPage()}
      />
    </div>
  );
}

function BotRow({
  agent,
  pendingUpdate,
  failedUpdate,
  handleAgentUpdate
}: {
  agent: Agent;
  pendingUpdate: AgentUpdate | undefined;
  failedUpdate: boolean;
  handleAgentUpdate: AgentUpdateCallback;
}): JSX.Element {
  const [stake, setStake] = useState<string>('0');
  const [loadingStake, setLoadingStake] = useState<boolean>(true);
  const web3React = useWeb3React();

  useEffect(() => {
    if (!web3React.account) return;
    setLoadingStake(true);
    const contract = new BotStaking(web3React);
    contract
      .getBotActiveStake(SubjectType.BOT, agent.id)
      .then((_stake) => {
        setStake(formatEther(_stake));
        setLoadingStake(false);
      })
      .catch(() => {
        setLoadingStake(false);
        toast.error(`Error trying to get staked amount of agent.id`);
      });
  }, [agent.id, web3React]);

  return (
    <TableRow className="MyAgentsPage__row">
      <TableCell flex={29}>
        <TableSubCell
          flex={14}
          value={
            <div className="MyAgentsPage__agent-name">
              <div className="MyAgentsPage__agent-name-row">
                <NavLink to={`/bot/${agent.id}`}>{agent.name}</NavLink>
                <span className="MyAgentsPage__agent-version">
                  {agent.version}
                </span>
                {agent && agent.protocol_version !== 2 && !agent.external && (
                  <div className="MyAgentsPage__bot-chain-icons">
                    {(agent.chainIds || []).map((chainId) => {
                      const network = getNetworkByChainId(Number(chainId));
                      return (
                        <div
                          className="MyAgentsPage__bot-chain-icon"
                          key={`chain-id-${agent.id}-${chainId}`}
                        >
                          <ChainIcon network={network} />
                        </div>
                      );
                    })}
                  </div>
                )}
                {pendingUpdate ? (
                  <span className="MyAgentsPage__agent-pending">
                    Pending update
                  </span>
                ) : null}
              </div>
              <div className="MyAgentsPage__agent-id" title={agent.id}>
                <CopyButton text={agent.id}>
                  {shortenHash(agent.id, 8)}
                </CopyButton>
              </div>
            </div>
          }
        />
        <TableSubCell
          flex={3}
          value={
            <div className="MyAgentsPage__agent-stake">
              <div className="MyAgentsPage__stake-status">
                <div
                  className={classNames({
                    'MyAgentsPage__stake-box': true,
                    'MyAgentsPage__stake-box--unstaked': loadingStake
                      ? false
                      : !hasRequiredBotStake(stake),
                    'MyAgentsPage__stake-box--staked': loadingStake
                      ? true
                      : hasRequiredBotStake(stake)
                  })}
                >
                  •
                </div>
              </div>
              <div className="MyAgentsPage__agent-stake-value">
                {loadingStake ? <Skeleton width={30} /> : stake} FORT
              </div>
            </div>
          }
        />
        <TableSubCell
          flex={4}
          value={
            <div className="MyAgentsPage__agent-plan">
              <PlanLabel planType={agent.subscription_type} />
            </div>
          }
        />
        <TableSubCell
          flex={4}
          value={
            <div className="MyAgentsPage__agent-date">
              <div>{moment(agent.updated_at).fromNow()}</div>
              <div>{moment(agent.updated_at).format('D MMM YYYY, h:mm a')}</div>
            </div>
          }
        />
        <TableSubCell
          flex={3}
          value={
            <div className="MyAgentsPage__agent-status">
              <div
                className={classNames({
                  'MyAgentsPage__status-box': true,
                  'MyAgentsPage__status-box--enabled': agent.enabled
                })}
              >
                {agent.enabled ? 'Enabled' : 'Disabled'}
              </div>
            </div>
          }
        />
        <BotMenuCell
          agent={agent}
          disabled={!!pendingUpdate && !failedUpdate}
          onUpdate={handleAgentUpdate}
          stake={Number(stake)}
        />
      </TableCell>
    </TableRow>
  );
}
