import { BigNumber, ContractTransaction, ethers } from 'ethers';

import ContractInteractor from './contract-interactor';

import config from 'common/config';
import ERC20ABI from 'forta-app/data/abis/ERC20ABI.json';
import { ContractContext as Erc20Contract } from 'forta-app/data/abis/erc20-contract';

export default class FortaToken extends ContractInteractor<Erc20Contract> {
  constructor(props: {
    contractAddress?: string;
    provider: ethers.providers.JsonRpcProvider;
    signer?: ethers.providers.JsonRpcSigner;
  }) {
    const {
      provider,
      signer,
      contractAddress = config.FORTContractAddress
    } = props;

    super(provider, ERC20ABI, contractAddress, signer);
  }

  public async balanceOf(owner: string): Promise<BigNumber> {
    return await this.contract.balanceOf(owner);
  }

  async approve(
    spender: string,
    value: string | ethers.BigNumber
  ): Promise<ContractTransaction> {
    return await this.contract.approve(spender, value);
  }

  async allowance(owner: string, spender: string): Promise<BigNumber> {
    return await this.contract.allowance(owner, spender);
  }
}
