import React from 'react';
import cn from 'classnames';

import './Switch.scss';

import { FormElementProps } from '../form';

type SwitchProps = Omit<FormElementProps<string | undefined>, 'value'> & {
  label?: string;
  labelPosition?: 'start' | 'end';
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
};

function Switch(props: SwitchProps): JSX.Element {
  const {
    name,
    value,
    checked,
    disabled,
    label,
    labelPosition = 'start',
    className,
    onChange
  } = props;

  return (
    <label
      className={cn('Switch', className, { ['Switch--disabled']: disabled })}
    >
      {labelPosition === 'start' && label && (
        <span className="Switch__label">{label}</span>
      )}
      <input
        name={name}
        type="checkbox"
        checked={checked}
        value={value}
        className="Switch__input"
        disabled={disabled}
        onChange={onChange}
      />
      <span
        className={cn('Switch__slider', {
          ['Switch__slider--on']: checked
        })}
      />
      {labelPosition === 'end' && label && (
        <span className="Switch__label">{label}</span>
      )}
    </label>
  );
}

export default Switch;
