import classNames from 'classnames';
import { SearchIcon } from 'common/components/Icons';
import Input from 'common/components/Input';
import { useState } from 'react';
import { Agent } from 'common/lib/apis/agentAPI';
import BotList, { BotListColumn } from 'common/components/BotList';
import EventList from 'forta-app/components/EventList';
import { ScannerState } from 'forta-app/pages/ScanNodePage';

interface ScanNodeListSpec {
  name: string;
  searchPlaceholder: string;
  render: (props: {
    scanner: ScannerState;
    bots: Agent[];
    search: string;
  }) => JSX.Element;
}

const LISTS_SPECS: ScanNodeListSpec[] = [
  {
    name: 'Bots',
    searchPlaceholder: '',
    render: ({ bots: _bots, scanner, search }) => {
      const bots = search
        ? _bots.filter((bot) => bot.id === search || bot.name.includes(search))
        : _bots;
      return (
        <div>
          <div className="ScanNodePage__bots-title">Assigned Bots</div>
          <BotList
            bots={bots}
            loading={scanner.loading}
            hide={[BotListColumn.STATUS]}
          />
        </div>
      );
    }
  },
  {
    name: 'Events',
    searchPlaceholder: '',
    render: ({ scanner }: { scanner: ScannerState }) => (
      <EventList scannerId={scanner.id} />
    )
  }
];

export default function ScanNodeLists({
  bots,
  scanner
}: {
  bots: Agent[];
  scanner: ScannerState;
}): JSX.Element {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const ComponentList = LISTS_SPECS[selectedIndex].render;

  return (
    <div className="ScanNodeLists">
      <div className="ButtonGroupMenu">
        {LISTS_SPECS.map((listSpec, index) => {
          return (
            <button
              className={classNames({
                ButtonGroupMenu__item: true,
                ButtonGroupMenu__item__selected: selectedIndex === index
              })}
              key={`listSpec-${listSpec.name}`}
              onClick={() => {
                setSelectedIndex(index);
                setSearch('');
              }}
            >
              {listSpec.name}
            </button>
          );
        })}
      </div>
      <div className="ScanNodeLists__content">
        {LISTS_SPECS[selectedIndex].searchPlaceholder ? (
          <div className="ScanNodeLists__search">
            <Input
              name="scan-node-list-search"
              value={search}
              onChange={(value) => setSearch(value)}
              placeholder={LISTS_SPECS[selectedIndex].searchPlaceholder}
              icon={SearchIcon}
            />
          </div>
        ) : null}
        <ComponentList scanner={scanner} bots={bots} search={search} />
      </div>
    </div>
  );
}
