import { useMemo } from 'react';
import cn from 'classnames';
import ReactSelect, {
  components,
  ControlProps,
  IndicatorProps,
  ValueType
} from 'react-select';

import { FormElementProps, Option } from '../form';
import './Select.scss';

import { ChevronDownIcon, CrossIcon } from 'common/components/Icons';
import { MenuPortalProps } from 'react-select/src/components/Menu';

type SelectProps<FormValue, IsMulti extends boolean = false> = FormElementProps<
  IsMulti extends true ? FormValue[] : FormValue
> & {
  options: Option<FormValue>[];
  icon?: JSX.Element;
  placeholder?: string;
  searchable?: boolean;
  menuPosition?: 'fixed' | 'absolute';
  clearable?: boolean;
  disabled?: boolean;
  closeMenuOnSelect?: boolean;
  disableOptionSelection?: boolean;
  invalid?: boolean;
  multi?: IsMulti;
  className?: string;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
function MenuPortal(props: MenuPortalProps<any, any>): JSX.Element {
  return (
    <components.MenuPortal {...props} className="Select__menu-portal">
      {props.children}
    </components.MenuPortal>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function DropdownIndicator(props: IndicatorProps<any, any>): JSX.Element {
  return (
    <components.DropdownIndicator {...props}>
      {ChevronDownIcon}
    </components.DropdownIndicator>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function ClearIndicator(props: IndicatorProps<any, any>): JSX.Element {
  return (
    <components.ClearIndicator {...props}>
      {CrossIcon}
    </components.ClearIndicator>
  );
}

function Select<ElementValue, IsMulti extends boolean = false>(
  props: SelectProps<ElementValue, IsMulti>
): JSX.Element {
  const {
    name,
    value,
    options,
    placeholder,
    menuPosition = 'absolute',
    icon,
    multi,
    clearable = true,
    searchable,
    disabled,
    closeMenuOnSelect,
    invalid,
    className,
    onChange
  } = props;

  // make the component work with form values instead of options
  const selectedOptions = useMemo(() => {
    if (Array.isArray(value)) {
      return options.filter((o) => value.includes(o.value));
    } else {
      return options.find((o) => o.value === value);
    }
  }, [value, options]);

  function handleChange(value: ValueType<Option<ElementValue>, IsMulti>): void {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const preparedValue: any = Array.isArray(value)
      ? (value as Option<ElementValue>[]).map((i) => i.value)
      : (value as Option<ElementValue>)?.value || null;

    onChange({ target: { name, value: preparedValue } });
  }

  const Control = useMemo(() => {
    return function Control({
      children,
      className,
      ...props
    }: ControlProps<Option<ElementValue>, IsMulti>): JSX.Element {
      return (
        <components.Control
          className={cn(className, {
            ['Select__control--is-invalid']: invalid
          })}
          {...props}
        >
          {icon && <div className="Select__icon">{icon}</div>}
          {children}
        </components.Control>
      );
    };
  }, [icon, invalid]);

  const containerClasses = cn('SelectContainer', className, {
    ['SelectContainer--invalid']: invalid
  });

  const shouldCloseOnMenuSelect =
    closeMenuOnSelect !== undefined ? closeMenuOnSelect : true;

  return (
    <ReactSelect<Option<ElementValue>, IsMulti>
      name={name}
      className={containerClasses}
      classNamePrefix="Select"
      options={options}
      menuPosition={menuPosition}
      value={selectedOptions}
      isSearchable={searchable}
      placeholder={placeholder}
      isDisabled={disabled}
      menuPortalTarget={document.body}
      components={{
        Control,
        DropdownIndicator,
        ClearIndicator,
        MenuPortal
      }}
      isClearable={clearable}
      isMulti={multi}
      onChange={handleChange}
      closeMenuOnSelect={shouldCloseOnMenuSelect}
    />
  );
}

export default Select;
