import React, { ReactNode, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  Strategy,
  useFloating
} from '@floating-ui/react-dom-interactions';
import useOutsideClick from 'react-cool-onclickoutside';

import './Popover.scss';
import { Placement } from '@floating-ui/core/src/types';

type PopoverProps = {
  open: boolean;
  anchorElRef: React.RefObject<HTMLElement>;
  containerElRef: React.RefObject<HTMLElement>;
  strategy?: Strategy;
  placement?: Placement;
  preferredWidth?: number;
  maxHeight?: number;
  children: ReactNode;
  className?: string;
  onClose: () => unknown;
};

function Popover(props: PopoverProps): JSX.Element {
  const {
    open,
    anchorElRef,
    containerElRef,
    strategy: strategyProp = 'absolute',
    placement = 'bottom-end',
    preferredWidth = 150,
    maxHeight = 500,
    className,
    children,
    onClose
  } = props;

  const contentRef = useRef<HTMLDivElement>(null);
  const { x, y, reference, floating, strategy } = useFloating({
    strategy: strategyProp,
    whileElementsMounted: autoUpdate,
    placement: placement,
    middleware: [
      offset(4),
      shift(),
      flip(),
      size({
        padding: 8,
        apply({ availableWidth, availableHeight }) {
          Object.assign(contentRef.current?.style, {
            width: `${Math.min(preferredWidth, availableWidth)}px`,
            maxHeight: `${Math.min(maxHeight, availableHeight)}px`
          });
        }
      })
    ]
  });

  useLayoutEffect(() => {
    if (open) {
      reference(anchorElRef.current);
    }
  }, [open, anchorElRef, reference]);

  useOutsideClick(onClose, {
    refs: [containerElRef]
  });

  return (
    <div
      ref={floating}
      className={cn('Popover', { ['Popover--open']: open })}
      style={{
        top: y ?? 0,
        left: x ?? 0,
        position: strategy
      }}
    >
      <div
        ref={contentRef}
        className={cn('Popover__content', className, {
          ['Popover__content--open']: open
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default Popover;
