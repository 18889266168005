import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Link, NavLink, Redirect } from 'react-router-dom';
import { getAddress } from '@ethersproject/address';

import InfoPopover from 'common/components/InfoPopover';
import Loader from 'common/components/Loader';
import Message from 'common/components/Message';
import CopyButton from 'common/components/CopyButton';
import {
  ArrowRightIcon,
  ExternalLinkIcon,
  ReportIcon
} from 'common/components/Icons';
import { getNetworkByChainId, Network, NetworkInfo } from 'common/lib/networks';
import MetadataContainer from 'common/components-v2/Metadata/Container';
import { isAlertId, parseMetadata } from 'common/lib/utils';

import {
  Alert,
  AlertDocumentType,
  getAlert,
  getScannerAlerts,
  getTimestampFromAlert,
  ScannerAlert,
  SeveritiesInfo
} from 'common/lib/apis/alertAPI';

import './AlertPage.scss';
import { toast } from 'react-toastify';
import { Routes } from '../../../../common/routes';
import Source from 'common/components-v2/Entities/Source/Source';

export function AlertBadge({
  name,
  severity
}: {
  name: string;
  severity: string;
}): JSX.Element {
  return (
    <div
      className="AlertBadge"
      style={{ backgroundColor: SeveritiesInfo[severity].color }}
    >
      {name}
    </div>
  );
}

export function getTransactionExternalLink(
  network: NetworkInfo,
  txHash: string
): string {
  return `${network.txExplorerUrl}/${txHash}`;
}

function getBlockExternalLink(
  network: NetworkInfo,
  blockNumber: number
): string {
  return `${network.explorerUrl}/block/${blockNumber}`;
}

function getAddressExternalLink(network: NetworkInfo, address: string): string {
  return `${network.explorerUrl}/address/${address}`;
}

function AlertDetailsTable({ alert }: { alert: Alert }): JSX.Element {
  const network: NetworkInfo = getNetworkByChainId(
    alert.chain_id ||
      (alert.source.chains?.length === 1 && alert.source.chains[0].chainId) ||
      0
  );
  const isExternalBot = alert.alert_document_type === AlertDocumentType.API;

  function PrintTxRow({
    txHash,
    chainId
  }: {
    txHash: string;
    chainId: number;
  }): JSX.Element {
    return (
      <tr className="AlertDetailsTable__row AlertDetailsTable__transaction">
        <td className="AlertDetailsTable__name">
          <InfoPopover content="Transaction hash where this threat was detected." />{' '}
          Transaction
        </td>
        <td className="AlertDetailsTable__value">
          <Link to={Routes.alerts.index({ txHash })}>{txHash}</Link>
          <CopyButton text={txHash} />
          <InfoPopover content="See in blockchain explorer" rightPositioned>
            <a
              className="external-icon"
              href={getTransactionExternalLink(
                getNetworkByChainId(chainId),
                txHash
              )}
              target="_blank"
              rel="noreferrer"
            >
              {ExternalLinkIcon}
            </a>
          </InfoPopover>
        </td>
      </tr>
    );
  }

  return (
    <table className="AlertDetailsTable">
      <tr className="AlertDetailsTable__row">
        <td className="AlertDetailsTable__name">
          <InfoPopover content="Name of the alert." /> Alert
        </td>
        <td className="AlertDetailsTable__value">
          <div className="AlertDetailsTable__alert-name">{alert.name}</div>
          <div className="AlertDetailsTable__alert-severity">
            <AlertBadge
              name={`${alert.severity.toLowerCase()} Severity`}
              severity={alert.severity}
            />
          </div>
          {alert.type && (
            <div className="AlertDetailsTable__alert-type">
              <AlertBadge name={alert.type} severity={'LOW'} />
            </div>
          )}
        </td>
      </tr>
      <tr className="AlertDetailsTable__row">
        <td className="AlertDetailsTable__name">
          <InfoPopover content="Unique hash of this alert." /> Alert id
        </td>
        <td className="AlertDetailsTable__value">
          {alert.hash} ({alert.alert_id})
          <CopyButton text={alert.hash} />
        </td>
      </tr>
      <tr className="AlertDetailsTable__row">
        <td className="AlertDetailsTable__name">
          <InfoPopover content="Place from where this alert was triggered" />{' '}
          Alert type
        </td>
        <td className="AlertDetailsTable__value">
          {alert.alert_document_type || 'BLOCK'}
        </td>
      </tr>
      <tr className="AlertDetailsTable__row">
        <td className="AlertDetailsTable__name">
          <InfoPopover content="ID of the Bot that detected this threat." /> Bot
          ID
        </td>
        <td className="AlertDetailsTable__value AlertDetailsTable__agent">
          <div className="AgentInfo">
            <Link to={Routes.alerts.index({ agents: [alert.source.agent.id] })}>
              {alert.source.agent.id}
            </Link>
            <CopyButton text={alert.source.agent.id} />
            <InfoPopover content="Bot report page">
              <NavLink
                className="external-icon"
                to={`/bot/${alert.source.agent.id}`}
              >
                {ReportIcon}
              </NavLink>
            </InfoPopover>
          </div>
          <div className="AlertDetailsTable__subscribe-cta">
            <NavLink
              className="button-cta"
              to={`/notifications?scopeId=agent|${alert.source.agent.id}`}
            >
              Subscribe to this bot
            </NavLink>
          </div>
        </td>
      </tr>
      <tr className="AlertDetailsTable__row AlertDetailsTable__timestamp">
        <td className="AlertDetailsTable__name">
          <InfoPopover content="Timestamp of the block where the threat was found." />{' '}
          Timestamp
        </td>
        <td className="AlertDetailsTable__value">
          {alert ? new Date(getTimestampFromAlert(alert)).toUTCString() : ''}
        </td>
      </tr>
      <tr className="AlertDetailsTable__row">
        <td className="AlertDetailsTable__name">
          <InfoPopover content="Threat description given by the agent." />{' '}
          Description
        </td>
        <td className="AlertDetailsTable__value">
          <textarea className="AlertDetailsTable__alert-description" readOnly>
            {alert.description}
          </textarea>
        </td>
      </tr>
      {isExternalBot && alert.metadata && alert.metadata !== 'null' && (
        <tr className="AlertDetailsTable__row">
          <td className="AlertDetailsTable__name">
            <InfoPopover content="Metadata on the alert report." /> Metadata
          </td>
          <td className="AlertDetailsTable__value">
            <MetadataContainer
              titleElement={<div></div>}
              value={parseMetadata(alert.metadata)}
              maxHeight={500}
              className="AlertPage__alert-metadata"
            />
          </td>
        </tr>
      )}
      {(alert.projects && alert.projects.length && (
        <tr className="AlertDetailsTable__row">
          <td className="AlertDetailsTable__name">
            <InfoPopover content="List of projects associated to this alert." />{' '}
            Projects
          </td>
          <td className="AlertDetailsTable__value">
            <div className="AlertDetailsTable__projects">
              {alert.projects.map((project) => {
                return (
                  <div
                    className="AlertDetailsTable__project"
                    key={`alert-${project.id}`}
                  >
                    <Link to={Routes.alerts.index({ project: project.id })}>
                      {project.name}
                    </Link>
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
      )) ||
        null}
      <tr>
        <td className="AlertDetailsTable__sources-title">Sources</td>
      </tr>
      {alert.source.tx_hash ? (
        <PrintTxRow txHash={alert.source.tx_hash} chainId={alert.chain_id} />
      ) : null}
      {alert.source.transactions?.length
        ? alert.source.transactions.map(({ hash, chainId }) => (
            <PrintTxRow
              txHash={hash}
              key={`row-tx-${hash}`}
              chainId={chainId}
            />
          ))
        : null}
      {alert.source.block.number > 0 && (
        <tr className="AlertDetailsTable__row AlertDetailsTable__block">
          <td className="AlertDetailsTable__name">
            <InfoPopover content="Block number where this threat was detected." />{' '}
            Block
          </td>
          <td className="AlertDetailsTable__value">
            {alert.source.block.number}
            <InfoPopover content="See in blockchain explorer" rightPositioned>
              <a
                className="external-icon"
                href={getBlockExternalLink(network, alert.source.block.number)}
                target="_blank"
                rel="noreferrer"
              >
                {ExternalLinkIcon}
              </a>
            </InfoPopover>
          </td>
        </tr>
      )}
      {alert.source.source_alert.hash && (
        <tr className="AlertDetailsTable__row AlertDetailsTable__block">
          <td className="AlertDetailsTable__name">
            <InfoPopover content="The alert that triggered this alert." />{' '}
            Source Alert
          </td>
          <td className="AlertDetailsTable__value">
            {alert.source.source_alert.hash}
            <InfoPopover content="Alert page" rightPositioned>
              <Link
                to={Routes.alerts.details.index({
                  id: alert.source.source_alert.hash
                })}
                className="external-icon"
              >
                {ReportIcon}
              </Link>
            </InfoPopover>
          </td>
        </tr>
      )}
      {alert.source.chains && alert.source.chains.length > 0 && (
        <tr className="AlertDetailsTable__row">
          <td className="AlertDetailsTable__name">
            <InfoPopover content="The UI displays a maximum of 50 addresses. Use the API to get the full list of associated addresses." />
            Chains
          </td>
          <td className="AlertDetailsTable__value">
            <div className="AlertDetailsTable__sources">
              {alert.source.chains.map((chain, index) => {
                return <Source chainId={chain.chainId} key={index} />;
              })}
            </div>
          </td>
        </tr>
      )}
      {alert.addresses && (
        <tr className="AlertDetailsTable__row">
          <td className="AlertDetailsTable__name">
            <InfoPopover content="The UI displays a maximum of 50 addresses. Use the API to get the full list of associated addresses." />{' '}
            Addresses
          </td>
          <td className="AlertDetailsTable__value">
            <Addresses network={network} alert={alert} />
          </td>
        </tr>
      )}
      {alert.source.externalLinks?.map(({ source, path }) => {
        let url = '';

        switch (source) {
          case 'twitter':
            url = `https://twitter.com/${path}`;
            break;
        }

        if (!url) return null;

        return (
          <tr
            className="AlertDetailsTable__row AlertDetailsTable__block"
            key={`row-link-${name}-${url}`}
          >
            <td className="AlertDetailsTable__name">{source}</td>
            <td className="AlertDetailsTable__value">
              {alert.source.block.number}
              <InfoPopover content="See in blockchain explorer" rightPositioned>
                <a
                  className="external-icon"
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {ExternalLinkIcon}
                </a>
              </InfoPopover>
            </td>
          </tr>
        );
      }) || null}
    </table>
  );
}

function Addresses({
  network,
  alert
}: {
  network: NetworkInfo;
  alert: Alert;
}): JSX.Element {
  const addresses = alert.addresses
    .map((address) => {
      const contract = alert.contracts?.find(
        (contract) => contract.address.toLowerCase() === address.toLowerCase()
      );
      let contractName = contract?.name || address;
      if (contract?.name === 'Pair') {
        contractName = 'Swap pair';
      }
      return {
        displayValue: contractName,
        value: address
      };
    })
    .sort((a, b) => {
      return b.displayValue.length - a.displayValue.length;
    });

  return (
    <div className="AlertDetailsTable__addreses">
      {addresses.map((address) => {
        return (
          <SingleAddress
            key={`addr-${address.value}`}
            address={address.value}
            displayValue={address.displayValue}
            network={network}
            alert={alert}
          />
        );
      })}
    </div>
  );
}

function SingleAddress({
  address,
  displayValue,
  network,
  alert
}: {
  address: string;
  displayValue: string;
  network: NetworkInfo;
  alert: Alert;
}): JSX.Element {
  const [error, setError] = useState<boolean>(false);

  const formattedAddress: string | null = useMemo(() => {
    try {
      return getAddress(address);
    } catch (e) {
      return null;
    }
  }, [address]);

  return (
    <div
      className="AlertDetailsTable__address"
      key={`address-list-${alert.hash}-${address}`}
    >
      <div className="AlertDetailsTable__address-label">
        <Link to={Routes.alerts.index({ addresses: [address] })}>
          {!error && formattedAddress && (
            <img
              src={`/token-images/${formattedAddress}.png`}
              alt="icon"
              onError={() => setError(true)}
            />
          )}{' '}
          {displayValue}
        </Link>
      </div>
      <div className="AlertDetailsTable__address-actions">
        <CopyButton text={address} />
        {network.chainId in Network && (
          <InfoPopover content="See in blockchain explorer" rightPositioned>
            <a
              className="external-icon"
              href={getAddressExternalLink(network, address)}
              target="_blank"
              rel="noreferrer"
            >
              {ExternalLinkIcon}
            </a>
          </InfoPopover>
        )}
      </div>
    </div>
  );
}

function NetworkBadge({ chainId }: { chainId: number }): JSX.Element {
  const network = getNetworkByChainId(chainId);
  const classes = {
    NetworkBadge: true,
    'NetworkBadge--ethereum': network.name === 'mainnet',
    'NetworkBadge--bsc': network.name === 'bsc',
    'NetworkBadge--polygon': network.name === 'polygon-mainnet'
  };

  const icon = {
    ethereum: null,
    bsc: null,
    polygon: null
  }[network.name];

  if (!(network.chainId in Network)) return <></>;

  return (
    <div className={classNames(classes)}>
      on {icon} {network.label.toLowerCase()} network
    </div>
  );
}

function ScannerAlerts(props: {
  scanners: ScannerAlert[];
  loaded: boolean;
  count: number;
}): JSX.Element | null {
  const { scanners, loaded, count } = props;
  let content = null;

  if (!loaded) {
    content = (
      <div className="AlertPage__scanners-loader">
        <Loader />
      </div>
    );
  } else {
    content = (
      <div className="AlertPage__scanners-list">
        {scanners.map((scanner) => {
          const metadata = parseMetadata(scanner.metadata);

          const scannerNetwork = getNetworkByChainId(
            scanner.batch.block.chain_id || 137
          );

          return (
            <div
              className="AlertPage__scanner"
              key={scanner.signature.signature}
            >
              <div className="AlertPage__scanner-row">
                <div className="AlertPage__scanner-item">
                  <div className="AlertPage__scanner-item-label">
                    Node Operator Address{' '}
                    <InfoPopover content="Address of the node operator." />
                  </div>
                  <div className="AlertPage__scanner-item-value">
                    {scanner.scanner}
                    <CopyButton text={scanner.scanner} />
                    <a
                      className="external-icon"
                      href={getAddressExternalLink(
                        scannerNetwork,
                        scanner.scanner
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {ExternalLinkIcon}
                    </a>
                  </div>
                </div>
                <div className="AlertPage__scanner-item AlertPage__scanner-date">
                  <div className="AlertPage__scanner-item-label">
                    <InfoPopover
                      content="Timestamp this node operator reported the threat."
                      rightPositioned
                    />{' '}
                    Mined at
                  </div>
                  <div className="AlertPage__scanner-item-value">
                    {scanner.alert_timestamp}
                  </div>
                </div>
              </div>
              {scanner.batch.tx_hash && (
                <div className="AlertPage__scanner-item AlertPage__scanner-batch">
                  <div className="AlertPage__scanner-item-label">
                    Alert batch{' '}
                    <InfoPopover content="Transaction where the batch containing this alert was reported." />
                  </div>
                  <div className="AlertPage__scanner-item-value">
                    {scanner.batch.tx_hash}
                    <CopyButton text={scanner.batch.tx_hash} />
                    <InfoPopover content="See in blockchain explorer">
                      <a
                        className="external-icon"
                        href={getTransactionExternalLink(
                          scannerNetwork,
                          scanner.batch.tx_hash
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {ExternalLinkIcon}
                      </a>
                    </InfoPopover>
                  </div>
                </div>
              )}
              <div className="AlertPage__scanner-item">
                <div className="AlertPage__scanner-item-label">
                  Signature [{scanner.signature.algorithm}]{' '}
                  <InfoPopover content="Signature from the node operator." />
                </div>
                <div className="AlertPage__scanner-item-value AlertPage__scanner-signature">
                  {scanner.signature.signature}
                </div>
              </div>
              <div className="AlertPage__scanner-item">
                <MetadataContainer
                  titleElement={
                    <div className="AlertPage__scanner-item-label">
                      Metadata{' '}
                      <InfoPopover content="Metadata on the alert report." />{' '}
                    </div>
                  }
                  value={metadata}
                  maxHeight={500}
                  className="AlertPage__scanner-metadata"
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  if (!scanners.length) return null;

  return (
    <div className="AlertPage__scanners">
      <div className="AlertPage__scanners-title">
        Node Operators
        <span className="AlertPage__scanners-count"> ({count})</span>
      </div>
      {content}
    </div>
  );
}

interface AlertPageProps {
  history: {
    goBack: () => void;
  };
  match: {
    params: {
      id: string;
    };
  };
}

export default function AlertPage(props: AlertPageProps): JSX.Element {
  const [alert, setAlert] = useState<Alert | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [scannerNodes, setScannerNodes] = useState<ScannerAlert[]>([]);
  const [scannerNodesLoaded, setScannerNodesLoaded] = useState<boolean>(false);
  const alertId = isAlertId(props.match.params.id) ? props.match.params.id : '';

  useEffect(() => {
    if (alertId) {
      const fetchData = async (): Promise<void> => {
        try {
          setError(null);
          const alert = await getAlert(alertId);
          if (!alert) setError('Alert not found.');
          else {
            setAlert(alert);
            const isExternalBot =
              alert.alert_document_type === AlertDocumentType.API;

            if (isExternalBot) return;

            const scannerAlertData = await getScannerAlerts({ hash: alertId });
            setScannerNodes(scannerAlertData.scannerAlerts);
            setScannerNodesLoaded(true);
          }
        } catch (error) {
          toast.error(error);
          setAlert(null);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line
  }, [alertId]);

  if (!alertId) return <Redirect to={'/page-not-found'} />;

  const alertDetails = alert ? <AlertDetailsTable alert={alert} /> : <Loader />;

  const scannerAlerts = alert ? (
    <ScannerAlerts
      scanners={scannerNodes}
      loaded={scannerNodesLoaded}
      count={alert.scanner_count}
    />
  ) : null;

  const classes = classNames({
    AlertPage: true
  });
  const boxShadow = alert
    ? `0 0 20px ${SeveritiesInfo[alert.severity].color}B2`
    : '';

  return (
    <div className={classes} style={{ boxShadow }}>
      <div className="AlertPage__header">
        <div className="AlertPage__header-label">
          <button
            className="AlertPage__go-back-btn"
            onClick={() => props.history.goBack()}
          >
            {ArrowRightIcon}
          </button>
          Alert
          <div className="AlertPage__header-hash">{alertId}</div>
        </div>
        {alert && (
          <div className="AlertPage__header-protocol">
            <NetworkBadge chainId={alert.chain_id} />
          </div>
        )}
      </div>
      <div className="AlertPage__details">
        {error ? (
          <Message type="error">
            <span>
              <b>Error:</b> {error}
            </span>
          </Message>
        ) : (
          alertDetails
        )}
      </div>
      {scannerAlerts}
    </div>
  );
}
