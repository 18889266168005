import { isTransactionId } from 'common/lib/utils';
import TextFilter from './TextFilter';

interface FilterParams {
  onChange: (value: string) => void;
  selection: string;
}

export default function TransactionFilter({
  onChange,
  selection
}: FilterParams): JSX.Element {
  return (
    <TextFilter
      selection={selection}
      onChange={onChange}
      placeholder="Search by tx hash"
      name="transaction-filter"
      validation={isTransactionId}
    />
  );
}
