import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import './BotLabels.scss';

import LabelTable from 'common/components/LabelTable/LabelTable';
import Input from 'common/components-v2/Form/Input/Input';
import Button from 'common/components-v2/Button/Button';
import Pagination from 'common/components-v2/Pagination/Pagination';
import useLabelQuery, { UseLabelQueryParams } from 'common/hooks/useLabelQuery';
import useCursorPagination from 'common/hooks/useCursorPagination';
import { RefreshIcon, SearchIcon } from 'common/components/Icons';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from 'common/constants';
import { extractUrl } from 'common/lib/utils';

type BotLabelsProps = {
  botId: string;
  className?: string;
};

const LABELS_PER_PAGE = 10;

const WHITELISTED_BOTS = [
  // scam detector
  '0x1d646c4045189991fdfd24a66b192a294158b839a6ec121d740474bdacb3ab23',
  // medium-noisy-bot on the dev server
  '0x2bee737433c0c8cdbd924bbb68306cfd8abcf0e46a6ce8994fa7d474361bb186'
];

function BotLabels(props: BotLabelsProps): JSX.Element {
  const { botId, className } = props;

  const [searchInput, setSearchInput] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [endCursor, setEndCursor] = useState<
    { pageToken: string } | undefined
  >();

  const filterParams: UseLabelQueryParams = useMemo(() => {
    const params: UseLabelQueryParams = {
      botIds: [botId],
      first: LABELS_PER_PAGE,
      after: endCursor
    };

    const normalizedValue = searchValue.trim();

    if (!normalizedValue) return params;

    const url = extractUrl(normalizedValue);
    if (url) {
      params.entities = [url];
    } else {
      params.entities = [normalizedValue];
    }

    return params;
  }, [searchValue, botId, endCursor]);

  const query = useLabelQuery({ params: filterParams });
  const {
    labels,
    loading,
    refetching,
    refetch,
    hasNextPage,
    currPageCursor,
    nextPageCursor
  } = query;

  const { page, goPrevPage, goNextPage, resetPagination } = useCursorPagination(
    {
      queryResult: query,
      pageValues: [currPageCursor, nextPageCursor],
      onPageValuesChange: async (endCursor, page) => {
        if (page === 0) {
          await query.client.cache.reset();
          resetPagination();
        }
        setEndCursor(endCursor);
      }
    }
  );

  const handleSearchDebounced = useDebouncedCallback((value: string) => {
    setSearchValue(value);
    resetPagination();
  }, DEFAULT_SEARCH_DEBOUNCE_TIME);

  function handleSearch(value: string): void {
    setSearchInput(value);
    handleSearchDebounced(value);
  }

  return (
    <div className={cn('BotLabels', className)}>
      <div className="BotLabels__filters">
        <Input
          resettable
          name="label-search"
          variant="gray"
          value={searchInput}
          icon={{ left: SearchIcon }}
          placeholder="Search by Address / Entity"
          onChange={(e) => handleSearch(e.target.value)}
          className="BotLabels__search-input"
        />
        <Button
          variant="default"
          size="md"
          startIcon={RefreshIcon}
          disabled={refetching || loading}
          loadingPosition="start"
          loading={refetching || loading}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </div>
      <LabelTable
        labels={labels}
        showConfidence={WHITELISTED_BOTS.includes(botId.toLowerCase())}
        loading={loading || refetching}
        empty={labels.length === 0}
        className="BotLabels__labels"
      />
      <Pagination
        page={page}
        limit={LABELS_PER_PAGE}
        itemsCount={labels.length}
        loading={loading}
        hasNextPage={hasNextPage}
        onNextPage={() => goNextPage()}
        onPrevPage={() => goPrevPage()}
      />
    </div>
  );
}

export default BotLabels;
