import React, { useEffect } from 'react';

import './Header.scss';

import Header, { MenuItem } from 'common/components/Header';
import useViewPort from 'common/hooks/useViewPort';
import { useAppDispatch } from 'forta-app/app/hooks';
import { retrieveBotGroups } from 'forta-app/slices/notificationSlice';
import TransactionsWidget from './transactions/TransactionsWidget';
import WalletConnector from './wallet/WalletConnector';
import { Routes } from 'common/routes';
import {
  AlarmIcon,
  BotIcon,
  NetworkIcon,
  PuzzleIcon
} from '../../common/components/Icons';

function FortaAppHeader(): JSX.Element {
  const { isMdDown } = useViewPort();
  const dispatch = useAppDispatch();

  const menuItems: MenuItem[] = [
    {
      label: 'Firewall',
      href: Routes.firewall.index(),
      exact: true,
      new: true
    },
    {
      label: 'Stake',
      to: Routes.stake.index(),
      exact: true,
      new: false
    },
    {
      label: 'Network',
      nested: [
        {
          title: 'Data Feeds',
          to: Routes.dataFeeds.index(),
          description:
            'Discover and purchase data feeds created and maintained by a community of developers and security experts.',
          icon: PuzzleIcon
        },
        {
          title: 'Health & Activity',
          to: Routes.network.index(),
          description:
            'Information about the health and activity of the Forta Network, including blockchain coverage and active node operators.',
          icon: NetworkIcon
        },
        {
          title: 'Bots',
          to: Routes.bots.index({}),
          description:
            'Independent bots that scan all transactions and block-by-block state changes for threats and anomalies.',
          icon: BotIcon
        },
        {
          title: 'Alerts',
          to: Routes.alerts.index({}),
          description:
            'Forta alerts are real-time notifications generated by the Forta network to detect and report suspicious activities or anomalies in blockchain transactions.',
          icon: AlarmIcon
        }
      ]
    },
    {
      href: Routes.docs.index(),
      label: 'Docs'
    }
  ];

  useEffect(() => {
    dispatch(retrieveBotGroups());
  }, [dispatch]);

  // TODO Implementing a component responsible for the layout of pages
  useEffect(() => {
    const rootEl: HTMLDivElement | null = document.querySelector('.App');

    if (!rootEl) {
      console.error('Cannot find the root element: .App');
      return;
    }

    // Add a bottom indent to the app content so that the fixed footer doesn't overlap it
    if (isMdDown) {
      rootEl.style.paddingBottom = '60px';
    } else {
      rootEl.style.paddingBottom = '0';
    }
  }, [isMdDown]);

  return (
    <>
      <Header
        logoUrl="/forta_white.png"
        menuItems={menuItems}
        renderAdditionalContent={
          !isMdDown
            ? () => (
                <>
                  <TransactionsWidget />
                  <WalletConnector className="AppHeader__wallet" />
                </>
              )
            : undefined
        }
        className="AppHeader"
      />
      {isMdDown && (
        <div className="AppFixedFooter">
          <TransactionsWidget />
          <WalletConnector className="AppFixedFooter__wallet" />
        </div>
      )}
    </>
  );
}

export default FortaAppHeader;
