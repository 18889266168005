import { ExternalLinkIcon } from 'common/components/Icons';
import React from 'react';
import {
  ProtocolTransaction,
  TransactionType
} from '../../lib/transactions-storage/TransactionsStorage';
import { ProgressBar } from './ProgressBar';
import { getNetworkByChainId } from 'common/lib/networks';
import config from 'common/config';
import { Tooltip } from '@mui/material';
import { formatEther } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';
import { formatCommission } from '../scan-node-pool/CommissionDisplay';

import './PendingTransactionList.scss';

interface PendingTransactionListProps {
  transactions: ProtocolTransaction[];
  latestBlock: number | null;
  lastSyncedBlock: number | null;
}

const currentChainTxExplorer =
  getNetworkByChainId(config.chainId)?.txExplorerUrl ||
  'https://polygonscan.com/tx';

function getTransactionContent(transaction: ProtocolTransaction): [string] {
  let transactionLabel = 'Unknown';

  switch (transaction.transactionType) {
    case 'BOT_CREATED':
      transactionLabel = 'Bot Created';
      break;
    case 'BOT_UPDATED':
      transactionLabel = 'Bot Updated';
      break;
    case 'BOT_ENABLED':
      transactionLabel = 'Bot Enabled';
      break;
    case 'BOT_DISABLED':
      transactionLabel = 'Bot Disabled';
      break;
    case 'NODEPOOL_CREATED':
      transactionLabel = 'Nodepool Created';
      break;
    case 'NODEPOOL_NODE_REGISTERED':
      transactionLabel = 'Nodepool Node Registered';
      break;
    case 'NODEPOOL_COMISSION':
      transactionLabel = 'Nodepool Commission';
      break;
    case 'STAKE_DEPOSIT':
      transactionLabel = 'Stake Deposit';
      break;
    case 'STAKE_INITIATED_WITHDRAW':
      transactionLabel = 'Stake Initiated Withdraw';
      break;
    case 'STAKE_WITHDRAW':
      transactionLabel = 'Stake Withdraw';
      break;
    case 'NODEPOOL_ENABLE_SCANNER':
      transactionLabel = 'Nodepool Enable Scanner';
      break;
    case 'NODEPOOL_DISABLE_SCANNER':
      transactionLabel = 'Nodepool Disable Scanner';
      break;
    case 'CLAIM_REWARD':
      transactionLabel = 'Claim Reward';
      break;
    case 'ALLOCATE_OWN_STAKE':
      transactionLabel = 'Allocate Own Stake';
      break;
    case 'ALLOCATE_DELEGATED_STAKE':
      transactionLabel = 'Allocate Delegated Stake';
      break;
    case 'UNALLOCATE_OWN_STAKE':
      transactionLabel = 'Unallocate Own Stake';
      break;
    case 'UNALLOCATE_DELEGATED_STAKE':
      transactionLabel = 'Unallocate Delegated Stake';
      break;
    default:
      transactionLabel = 'Unknown';
  }

  return [transactionLabel];
}

const DEFAULT_BLOCK_WINDOW_PROGRESSBAR = 450; // 30 min

const TransactionItem = ({
  transaction,
  lastSyncedBlock
}: {
  transaction: ProtocolTransaction;
  latestBlock: number;
  lastSyncedBlock: number;
}): JSX.Element => {
  const { blockNumber, amount, confirmed, transactionType } = transaction;
  const blocksLeft = blockNumber - lastSyncedBlock;
  const [transactionLabel] = getTransactionContent(transaction);

  return (
    <Tooltip
      title={!confirmed ? 'Unconfirmed' : `${blocksLeft} blocks left`}
      placement="left"
    >
      <div className="TransactionItem">
        <div className="TransactionItem__label">
          {transactionLabel}{' '}
          {transactionType === TransactionType.NODEPOOL_COMISSION ? (
            <span className="TransactionItem__label__amount">
              {formatCommission(BigNumber.from(amount).toNumber())} FORT
            </span>
          ) : !BigNumber.from(amount).isZero() ? (
            <span className="TransactionItem__label__amount">
              {formatEther(amount)} FORT{' '}
            </span>
          ) : null}
          <a
            className="TransactionItem__link"
            href={`${currentChainTxExplorer}/${transaction.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {ExternalLinkIcon}
          </a>
        </div>
        <div>
          <ProgressBar now={!confirmed ? 75 : 95} secondary={!confirmed} />
        </div>
      </div>
    </Tooltip>
  );
};

export const PendingTransactionList: React.FC<PendingTransactionListProps> = ({
  transactions,
  latestBlock,
  lastSyncedBlock
}) => {
  if (latestBlock === null || lastSyncedBlock === null) {
    return null;
  }

  const blocksLeft = latestBlock - lastSyncedBlock;
  const blocksTotal = Math.max(
    latestBlock - lastSyncedBlock,
    DEFAULT_BLOCK_WINDOW_PROGRESSBAR
  );

  const percentageSynced = Math.min(
    Math.round(Math.min(1 - blocksLeft / blocksTotal) * 1000) / 10,
    99.9
  );

  return (
    <div className="PendingTransactionList">
      <div className="PendingTransactionList__syncing">
        Syncing ({percentageSynced}%)
      </div>
      <div>
        {transactions.map((transaction) => (
          <TransactionItem
            key={transaction.hash}
            transaction={transaction}
            latestBlock={latestBlock}
            lastSyncedBlock={lastSyncedBlock}
          />
        ))}
      </div>
      <div className="PendingTransactionList__description">
        Forta indexing is {blocksLeft} {blocksLeft === 1 ? 'block' : 'blocks'}{' '}
        behind the latest block.
      </div>
    </div>
  );
};
