import classNames from 'classnames';
import CopyButton from 'common/components/CopyButton';
import Loader from 'common/components/Loader';
import ChainIcon from 'common/components/stats/ChainIcon';
import { Agent } from 'common/lib/apis/agentAPI';
import { getNetworkByChainId } from 'common/lib/networks';
import { shortenHash } from 'common/lib/utils';
import moment from 'moment';
import './BotList.scss';
import { NavLink } from 'react-router-dom';

export enum BotListColumn {
  ID,
  DEVELOPER,
  UPDATE,
  STATUS
}

export default function BotList({
  bots,
  loading,
  hide: _hide
}: {
  bots: Agent[];
  loading: boolean;
  hide?: BotListColumn[];
}): JSX.Element {
  const hide = _hide || [];
  return (
    <div className="BotList">
      <div className="BotList__table">
        <div className="BotList__head">
          <div className="BotList__heading-id">
            <p>Bot{!hide.includes(BotListColumn.ID) ? ' / ID' : ''}</p>
          </div>
          {!hide.includes(BotListColumn.DEVELOPER) ? (
            <div className="BotList__heading-dev">
              <p>Developer</p>
            </div>
          ) : null}
          {!hide.includes(BotListColumn.UPDATE) ? (
            <div className="BotList__heading-update">
              <p>Last Update</p>
            </div>
          ) : null}
          {!hide.includes(BotListColumn.STATUS) ? (
            <div className="BotList__heading-status">
              <p>Status</p>
            </div>
          ) : null}
        </div>
        <div className="BotList__body">
          {[...bots].map((bot) => {
            return (
              <div className="BotList__bot-row" key={`bot-row-${bot.id}`}>
                <div className="BotList__bot-description">
                  <div>
                    <div className="BotList__bot-chain">
                      <NavLink to={`/bot/${bot.id}`}>{bot.name}</NavLink>
                      <div className="BotList__bot-chain-icons">
                        {(bot.chainIds || []).map((chainId) => {
                          const network = getNetworkByChainId(Number(chainId));
                          return (
                            <div
                              className="BotList__bot-chain-icon"
                              key={`chain-id-${bot.id}-${chainId}`}
                            >
                              <ChainIcon network={network} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {!hide.includes(BotListColumn.ID) ? (
                    <p className="BotList__bot-id">
                      {shortenHash(bot.id)} <CopyButton text={bot.id} />
                    </p>
                  ) : null}
                </div>
                {!hide.includes(BotListColumn.DEVELOPER) ? (
                  <div className="BotList__dev">
                    <p>
                      {shortenHash(bot.developer)}{' '}
                      <CopyButton text={bot.developer} />
                    </p>
                  </div>
                ) : null}
                {!hide.includes(BotListColumn.UPDATE) ? (
                  <div className="BotList__bot-update">
                    <div className="BotList__bot-from-now">
                      {moment(bot.updated_at).fromNow()}
                    </div>
                  </div>
                ) : null}
                {!hide.includes(BotListColumn.STATUS) ? (
                  <div className="BotList__bot-status">
                    <div
                      className={classNames({
                        'BotList__status-box': true,
                        'BotList__status-box--enabled': bot.enabled
                      })}
                    >
                      {bot.enabled ? 'Enabled' : 'Disabled'}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
          {!bots.length ? (
            <div className="BotList__list-empty">No bots found.</div>
          ) : null}
          {loading ? (
            <div className="BotList__loading">
              <Loader />
              <div className="BotList__loading-text">loading bots...</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
