import classNames from 'classnames';
import './Message.scss';

export default function Message(props: {
  type: string;
  children: JSX.Element | string;
}): JSX.Element {
  const classes = {
    Message: true,
    'Message--error': props.type === 'error'
  };

  return <div className={classNames(classes)}>{props.children}</div>;
}
