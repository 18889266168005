import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import './StakingPage.scss';

import Loader from 'common/components/Loader';
import { Stake } from 'forta-app/lib/apis/subgraphAPI';
import { useJWT } from 'forta-app/app/hooks';
import { BigNumber, providers } from 'ethers';
import StakeModal from 'forta-app/components/staking/StakingModal';
import { RefreshIcon, ReportIcon } from 'common/components/Icons';
import config from 'common/config';
import { getTransactionExternalLink } from 'forta-app/pages/alerts/id/AlertPage';
import { getNetworkByChainId } from 'common/lib/networks';
import WithdrawButton from 'forta-app/components/staking/WithdrawButton';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import useStakeQuery from 'common/hooks/useStakeQuery';
import Button from 'common/components-v2/Button/Button';
import Pagination from 'common/components-v2/Pagination/Pagination';
import useOffsetPagination, {
  UseOffsetPaginationResult
} from 'common/hooks/useOffsetPagination';
import { formatFORT } from '../lib/utils';

interface StakeListProps {
  title: string;
  subjectLabel: string;
  account: string;
  loading: boolean;
  stakes: Stake[];
  pagination: UseOffsetPaginationResult & { hasNextPage: boolean };
  onUpdated: (tx: providers.TransactionResponse) => void;
}

const StakeList = ({
  title,
  subjectLabel,
  stakes,
  account,
  loading,
  pagination,
  onUpdated
}: StakeListProps): JSX.Element => {
  return (
    <div className="EventList">
      <div className="EventList__filters">
        <div className="EventList__title">{title}</div>
      </div>
      <div className="EventList__heading">
        <div className="EventList__heading-item EventList__heading-subject">
          {subjectLabel}
        </div>
        <div className="EventList__heading-item EventList__heading-stake">
          Active Stake
        </div>
        <div className="EventList__heading-item EventList__heading-stake">
          Inactive Stake
        </div>
        <div className="EventList__heading-item EventList__heading-actions"></div>
      </div>
      <div className="EventList__content">
        <div className="EventList__events">
          {stakes.map((stake, index) => {
            let subjectUrl = '#';
            switch (stake.subject.subjectType) {
              case SubjectType.BOT:
                subjectUrl = `/bot/${stake.subject.id}`;
                break;
              case SubjectType.SCANNERPOOL:
              case SubjectType.SCANNERPOOL_DELEGATOR:
                subjectUrl = `/nodePool/${stake.subject.id}`;
                break;
            }

            return (
              <div
                className="EventList__event"
                key={`stake-row-${account}-${stake.subject.id}-${index}`}
              >
                <div className="EventList__event-type">
                  {stake.subject.id}
                  <NavLink className="EventList__external-icon" to={subjectUrl}>
                    {ReportIcon}
                  </NavLink>
                </div>
                <div className="EventList__event-stake">
                  {formatFORT(
                    BigNumber.from(stake.shares)
                      .mul(BigNumber.from(stake.subject.activeStake))
                      .div(
                        BigNumber.from(stake.subject.activeShares).isZero()
                          ? '1'
                          : BigNumber.from(stake.subject.activeShares)
                      )
                  )}{' '}
                  FORT
                </div>
                <div className="EventList__event-stake">
                  {formatFORT(
                    BigNumber.from(stake.inactiveShares)
                      .mul(BigNumber.from(stake.subject.inactiveStake))
                      .div(
                        BigNumber.from(stake.subject.inactiveShares).isZero()
                          ? '1'
                          : BigNumber.from(stake.subject.inactiveShares)
                      )
                  )}{' '}
                  FORT
                </div>
                <div className="EventList__event-action">
                  <WithdrawButton stake={stake} onDone={onUpdated} />
                </div>
              </div>
            );
          })}
        </div>
        {loading ? (
          <div className="EventList__loading">
            <Loader />
            <div className="EventList__loading-text">loading stakes...</div>
          </div>
        ) : stakes.length ? null : (
          <div className="EventList__load-more--no-events">No stakes</div>
        )}
      </div>
      <Pagination
        loading={loading}
        itemsCount={stakes.length}
        limit={pagination.pageSize}
        page={pagination.page}
        hasNextPage={pagination.hasNextPage}
        onNextPage={pagination.goNextPage}
        onPrevPage={pagination.goPrevPage}
      />
    </div>
  );
};

interface StakingPageProps {
  address: string;
}

const StakingPage = (props: StakingPageProps): JSX.Element => {
  const { address } = props;

  const jwt = useJWT();
  const searchQuery = queryString.parse(window.location.search, {
    arrayFormat: 'none'
  });
  const defaultSubjectId = `${searchQuery['stake_subject'] || ''}`;
  const defaultSubjectType = `${searchQuery['stake_subject_type'] || ''}`;
  const [stakeModalOpened, setStakeModalOpened] = useState<boolean>(
    !!defaultSubjectId
  );
  const [stakeModalSubjectId] = useState<string>(defaultSubjectId);
  const [stakeModalSubjectType] = useState<number | undefined>(
    Number(defaultSubjectType)
  );

  const poolPagination = useOffsetPagination();

  const {
    stakes: poolStakes,
    loading: isPoolStakeLoading,
    hasNextPage: hasNextPoolsPage,
    error: poolStakeError,
    refetch: refetchPoolStake
  } = useStakeQuery({
    params: {
      address,
      subjectTypes: [SubjectType.SCANNERPOOL],
      ...poolPagination.offset
    },
    enabled: !!address && !!jwt
  });

  const botPagination = useOffsetPagination();

  const {
    stakes: botStakes,
    hasNextPage: hasNextBotsPage,
    loading: isBotStakeLoading,
    error: botStakeError,
    refetch: refetchBotStake
  } = useStakeQuery({
    params: {
      address,
      subjectTypes: [SubjectType.BOT],
      ...botPagination.offset
    },
    enabled: !!address && !!jwt
  });

  useEffect(() => {
    if (poolStakeError) toast.error('Error retrieving stakes');
  }, [poolStakeError]);

  useEffect(() => {
    if (botStakeError) toast.error('Error retrieving stakes');
  }, [botStakeError]);

  useEffect(() => {
    history.pushState('', document.title, window.location.pathname);
  }, []);

  function refresh(): void {
    refetchBotStake();
    refetchPoolStake();
  }

  function StakingPageHeader(props: {
    renderActionButton?: () => JSX.Element;
  }): JSX.Element {
    const { renderActionButton } = props;

    return (
      <div className="StakingPage__header">
        <div className="StakingPage__header-text">
          <div className="StakingPage__header-title">Staking</div>
          <div className="StakingPage__header-description">
            Here you have the list of scan nodes and bots where you have staked
            FORT.
          </div>
        </div>
        <div className="StakingPage__header-actions">
          {renderActionButton && renderActionButton()}
          {jwt ? (
            <div className="StakingActions">
              <StakeModal
                opened={stakeModalOpened}
                onClose={(tx) => {
                  setStakeModalOpened(false);
                  if (tx) {
                    tx.wait(1).then(() => {
                      toast.success(() => (
                        <div>
                          FORT deposited:
                          <a
                            href={getTransactionExternalLink(
                              getNetworkByChainId(config.chainId),
                              tx.hash
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {tx.hash}
                          </a>
                        </div>
                      ));
                      refresh();
                    });
                  }
                }}
                subjectId={stakeModalSubjectId}
                subjectType={stakeModalSubjectType}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  if (!jwt) {
    return (
      <div className="StakingPage">
        <StakingPageHeader />
        <div className="MyAgentsPage__signin-message">Please sign in first</div>
      </div>
    );
  }

  return (
    <div className="StakingPage">
      <StakingPageHeader
        renderActionButton={() => (
          <Button
            size="md"
            variant="default"
            startIcon={RefreshIcon}
            disabled={isBotStakeLoading || isPoolStakeLoading}
            loading={isBotStakeLoading || isPoolStakeLoading}
            loadingPosition="start"
            onClick={refresh}
          >
            Refresh
          </Button>
        )}
      />
      <div className="StakingPage__body">
        <StakeList
          account={address}
          title="Scan Node Pool Stakes"
          subjectLabel="Scan Node Pool ID"
          stakes={poolStakes}
          loading={isPoolStakeLoading}
          pagination={{ ...poolPagination, hasNextPage: hasNextPoolsPage }}
          onUpdated={() => refetchPoolStake()}
        />
        <StakeList
          account={address}
          title="Bot Stakes"
          subjectLabel="Bot ID"
          stakes={botStakes}
          loading={isBotStakeLoading}
          pagination={{ ...botPagination, hasNextPage: hasNextBotsPage }}
          onUpdated={() => refetchBotStake()}
        />
      </div>
    </div>
  );
};

export default StakingPage;
