import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames';

import Modal from 'common/components/Modal';

import { signMessage } from 'forta-app/components/wallet/WalletConnector';
import './ConnectModal.scss';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useJWT } from 'forta-app/app/hooks';
import {
  getJWTAddress,
  isJWTExpired,
  login
} from 'forta-app/slices/walletSlice';
import {
  ConnectionType,
  getConnection,
  tryActivateConnector,
  tryDeactivateConnector
} from './connections';
import ls, {
  CONNECTION_TYPE,
  FILTERS_LS_KEY,
  clearLS
} from 'common/lib/localStorage';

interface ConnectModalParams {
  opened: boolean;
  setOpened: (value: boolean) => void;
}

export default function ConnectModal({
  opened,
  setOpened
}: ConnectModalParams): JSX.Element {
  const web3React = useWeb3React();
  const jwt = useJWT();
  const jwtAddress = getJWTAddress(jwt);
  const expired = isJWTExpired(jwt) || jwtAddress !== web3React.account;
  const dispatch = useAppDispatch();
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);

  const metamaskConnectorClasses = classNames({
    ConnectWalletDialog__connector: true
  });

  const walletconnectConnectorClasses = classNames({
    ConnectWalletDialog__connector: true
  });

  const onSignMessageClick = useCallback(async (): Promise<void> => {
    const signResult = await signMessage(web3React);
    if (signResult) {
      dispatch(login(signResult));
    }
  }, [web3React, dispatch]);

  useEffect(() => {
    if (buttonClicked && web3React.isActive && web3React.account && expired) {
      onSignMessageClick();
      setButtonClicked(false);
    }
  }, [
    buttonClicked,
    onSignMessageClick,
    web3React.account,
    web3React.isActive,
    expired
  ]);

  const handleOptionClick = async (
    connectionType: ConnectionType
  ): Promise<void> => {
    setOpened(false);
    await tryDeactivateConnector(web3React.connector);
    clearLS([FILTERS_LS_KEY]);
    const activation = await tryActivateConnector(
      getConnection(connectionType).connector
    );
    if (activation) {
      setButtonClicked(true);
      ls.setItem(CONNECTION_TYPE, connectionType);
    }
  };

  return (
    <Modal
      opened={opened}
      shouldCloseOnOverlayClick
      hideCloseButton
      title="Connect Wallet"
      onCloseModal={() => setOpened(false)}
      className="ConnectWalletDialog__container"
    >
      <div className="ConnectWalletDialog">
        <button
          className={metamaskConnectorClasses}
          onClick={() => handleOptionClick(ConnectionType.INJECTED)}
        >
          <div className="ConnectWalletDialog__connector-name">Metamask</div>
          <img
            className="ConnectWalletDialog__connector-logo"
            src="/metamask.png"
            alt="metamask"
          />
        </button>
        <button
          className={walletconnectConnectorClasses}
          onClick={() => handleOptionClick(ConnectionType.WALLET_CONNECT)}
        >
          <div className="ConnectWalletDialog__connector-name">
            WalletConnect
          </div>
          <img
            className="ConnectWalletDialog__connector-logo"
            src="/walletconnect.svg"
            alt="walletconnect"
          />
        </button>
      </div>
    </Modal>
  );
}
