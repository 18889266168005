import React from 'react';
import cn from 'classnames';

import './TableHeading.scss';
import InfoPopover from 'common/components/InfoPopover';

interface TableHeadingProps {
  title?: string;
  flex?: number;
  width?: number;
  children?: React.ReactNode;
  tooltip?: string;
  tooltipEnabled?: boolean;
  className?: string;
  tooltipTopPositioned?: boolean;
}

const TableHeading = (props: TableHeadingProps): JSX.Element => {
  const {
    title,
    tooltip,
    tooltipEnabled,
    flex,
    width,
    className,
    tooltipTopPositioned,
    children
  } = props;

  return (
    <div className={cn('TableHeading', className)} style={{ flex, width }}>
      {title && (
        <div className="TableHeading__title">
          {title}{' '}
          {tooltipEnabled && tooltip && (
            <InfoPopover
              content={tooltip}
              className="TableHeading__tooltip"
              topPositioned={tooltipTopPositioned}
            />
          )}
        </div>
      )}

      <div className="TableHeading__sub-headings">{children}</div>
    </div>
  );
};

export function TableActionHeading(): JSX.Element {
  return <TableHeading width={70} />;
}

export function TableCheckableHeading(): JSX.Element {
  return <TableHeading className="TableCheckableHeading" />;
}

export default TableHeading;
