import { gql } from '@apollo/client';

import { ApolloClientName } from 'common/components/ApolloClientProvider';
import useGraphQuery, { GraphQueryResult } from 'common/hooks/useGraphQuery';
import { planExpired } from 'forta-app/data/plans';

export const UNLOCK_MEMBERSHIPS_QUERY = gql`
  query GetPlans($owner: String!) {
    keys(where: { owner: $owner }) {
      id
      expiration
      createdAt
      cancelled
      lock {
        name
        price
        address
        tokenAddress
      }
    }
  }
`;

export interface UnlockKeyResult {
  id: string;
  expiration: string;
  createdAt: string;
  cancelled: boolean;
  lock: {
    address: string;
    name: string;
    price: string;
    tokenAddress: string;
  };
}

type ResponseData = { keys: UnlockKeyResult[] };

function useUnlockMembershipsQuery(opts: {
  params: { owner: string };
  enabled?: boolean;
  enablePolling?: boolean;
}): GraphQueryResult<{ owner: string }, ResponseData> & {
  keys: UnlockKeyResult[];
  activeMembershipAddresses: string[];
} {
  const { enabled, enablePolling, params } = opts;

  const queryResult = useGraphQuery<{ owner: string }, ResponseData>({
    enabled,
    clientName: ApolloClientName.Unlock,
    query: UNLOCK_MEMBERSHIPS_QUERY,
    enablePolling,
    variables: params
  });

  const keys = queryResult.data?.keys || [];

  const activeMembershipAddresses = keys
    .filter((key) => !planExpired(key) && !key.cancelled)
    .map((key) => key.lock.address.toLowerCase());

  return {
    ...queryResult,
    keys,
    activeMembershipAddresses
  };
}

export default useUnlockMembershipsQuery;
