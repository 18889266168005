import { BigNumber, BigNumberish, ethers } from 'ethers';

import ContractInteractor from './contract-interactor';

import config from 'common/config';
import DispatchABI from 'forta-app/data/abis/Dispatch.json';
import { DispatchContract } from '../../data/abis/dispatch-contract';

export default class Dispatch extends ContractInteractor<DispatchContract> {
  constructor(
    provider: ethers.providers.JsonRpcProvider,
    contractAddress = config.dispatchContractAddress,
    signer?: ethers.providers.JsonRpcSigner
  ) {
    super(provider, DispatchABI, contractAddress, signer);
  }

  public async numScannersFor(agentId: string): Promise<BigNumber> {
    return await this.contract.numScannersFor(agentId);
  }

  public async scannerAt(
    agentId: string,
    pos: BigNumberish
  ): Promise<BigNumber> {
    return await this.contract.scannerAt(agentId, pos);
  }
}
