import React, { useMemo } from 'react';
import cn from 'classnames';

import './Source.scss';

import { getNetworkByChainId } from '../../../lib/networks';
import ChainIcon from 'common/components/stats/ChainIcon';

type SourceProps = {
  chainId: number;
  className?: string;
};

function Source(props: SourceProps): JSX.Element {
  const { chainId, className } = props;

  const network = useMemo(() => {
    return getNetworkByChainId(chainId);
  }, [chainId]);

  return (
    <div className={cn('Source', className)}>
      <ChainIcon network={network} className="Source__icon" />
      <span>{network.label}</span>
    </div>
  );
}

export default Source;
