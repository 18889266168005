import { useWeb3React } from '@web3-react/core';
import ConfirmationModal, {
  ConfirmationResultMessage
} from 'common/components/ConfirmationModal';
import { useAppDispatch, useJWT } from 'forta-app/app/hooks';
import { signMessage } from 'forta-app/components/wallet/WalletConnector';
import { loginAddress } from 'forta-app/lib/apis/loginAPI';
import { deleteNotification } from 'forta-app/lib/apis/notificationAPI';
import { retrieveNotifications } from 'forta-app/slices/notificationSlice';
import { setLoginData } from 'forta-app/slices/walletSlice';
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useCallback
} from 'react';

interface DeleteNotificationContextProps {
  opened: boolean;
  open: (idToDelete: string) => void;
  close: () => void;
}

const DeleteNotificationContext = createContext<
  DeleteNotificationContextProps | undefined
>(undefined);

export const useDeleteNotificationModal =
  (): DeleteNotificationContextProps => {
    const context = useContext(DeleteNotificationContext);
    if (!context) {
      throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
  };

export function DeleteNotificationModalProvider({
  children
}: {
  children: ReactNode;
}): JSX.Element {
  const [opened, setOpened] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string>('');

  const web3React = useWeb3React();
  const jwt = useJWT();
  const dispatch = useAppDispatch();

  const onDeleteNotification =
    useCallback(async (): Promise<ConfirmationResultMessage> => {
      let _jwt = jwt;
      if (!_jwt) {
        const result = await signMessage(web3React);
        if (result) {
          _jwt = await loginAddress(result);
          dispatch(
            setLoginData({
              jwt: _jwt
            })
          );
        }
      }

      const result = await deleteNotification({
        notificationId: idToDelete,
        jwt: _jwt
      });

      if (result.success) {
        dispatch(retrieveNotifications({ jwt: _jwt }));
        return {
          type: 'success',
          message: 'Subscription removed'
        };
      } else {
        return {
          type: 'error',
          message: result.error
        };
      }
    }, [dispatch, jwt, idToDelete, web3React]);

  const open = (_idToDelete: string): void => {
    setIdToDelete(_idToDelete);
    setOpened(true);
  };

  const close = (): void => {
    setIdToDelete('');
    setOpened(false);
  };

  return (
    <DeleteNotificationContext.Provider value={{ opened, open, close }}>
      {children}
      <ConfirmationModal
        title="Unsubcribe"
        description="Please confirm that you want to unsubscribe this alert."
        actionButtonText="Confirm Unsubscribe"
        opened={opened}
        onClose={() => close()}
        onCancel={() => close()}
        onSubmit={onDeleteNotification}
        className="NotificationList__confirmationModal"
      />
    </DeleteNotificationContext.Provider>
  );
}
