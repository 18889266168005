import CreatableSelectFilter, { Option } from './CreatableSelectFilter';
import { isAddress } from 'common/lib/utils';

interface FilterParams {
  onChange: (selection: string[]) => void;
  selection: string[];
}

const createOption = (label: string): Option => ({
  label,
  value: label,
  valid: isAddress(label)
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function AddressFilter({
  onChange,
  selection
}: FilterParams): JSX.Element {
  const styles = {
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: data.valid ? 'white' : '#ff003c',
        cursor: 'pointer'
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: data.valid ? 'black' : 'white',
      backgroundColor: data.valid ? 'white' : '#ff003c'
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      backgroundColor: data.valid ? 'white' : '#ff003c',
      borderRadius: '0 50px 50px 0',
      paddingRight: '8px',
      marginRight: '2px',
      ':hover': {
        color: '#ccc',
        cursor: 'pointer'
      }
    }),
    control: (styles: any) => ({
      ...styles,
      padding: '10px'
    })
  };

  return (
    <CreatableSelectFilter
      onChange={onChange}
      selection={selection}
      selectionToOption={createOption}
      styles={styles}
      placeholder="Search by addresses..."
    />
  );
}
