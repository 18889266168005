import { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from './Modal';

export interface ConfirmationResultMessage {
  type: 'error' | 'success';
  message: string;
}

interface ConfirmationModalProps {
  opened: boolean;
  title: string;
  description: string | JSX.Element;
  actionButtonText: string;
  onCancel?: () => void;
  onClose?: () => void;
  onSubmit?: () => Promise<ConfirmationResultMessage>;
  className?: string;
}

export default function ConfirmationModal(
  props: ConfirmationModalProps
): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    opened,
    title,
    description,
    actionButtonText,
    onCancel,
    onClose,
    onSubmit,
    className
  } = props;

  const onCloseModal = (): void => {
    if (onCancel) {
      onCancel();
    }
  };

  const onSubmitModal = async (
    event: React.FormEvent<HTMLElement>
  ): Promise<void> => {
    event.preventDefault();

    if (onSubmit) {
      setLoading(true);
      const result = await onSubmit();
      setLoading(false);
      if (result.type === 'error') {
        toast.error(result.message, { hideProgressBar: true });
      } else {
        toast.success(result.message || 'Success');
        if (onClose) {
          onClose();
        }
      }
    }
  };

  return (
    <Modal
      opened={opened}
      loading={loading}
      title={title}
      submitButtonText={actionButtonText}
      onCloseModal={onCloseModal}
      onSubmit={onSubmitModal}
      className={className}
    >
      {description}
    </Modal>
  );
}
