import React from 'react';
import cn from 'classnames';

import './TableBody.scss';
import Shimmer from '../Shimmer/Shimmer';
import Fade from '../Transitions/Fade';

interface TableBodyProps {
  loading?: boolean;
  empty?: boolean;
  minHeight?: number;
  emptyMessage?: string;
  children: React.ReactNode;
}

// TODO Replace loader with Shimmer component

function TableBody(props: TableBodyProps): JSX.Element {
  const {
    loading,
    empty,
    emptyMessage = 'No data',
    minHeight,
    children
  } = props;

  return (
    <div
      className={cn('TableBody', {
        ['TableBody--loading']: loading,
        ['TableBody--empty']: empty
      })}
      style={{ minHeight }}
    >
      {!empty ? (
        children
      ) : (
        <div className="TableBody__notification">
          <div className="TableBody__notification-message">{emptyMessage}</div>
        </div>
      )}
      <Fade visible={loading}>
        {(ref) => (
          <div ref={ref}>
            <Shimmer />
          </div>
        )}
      </Fade>
      {loading && (
        <div className="TableBody__loader">
          <div className="TableBody__loader__shimmer" />
        </div>
      )}
    </div>
  );
}

export default TableBody;
