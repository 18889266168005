import React from 'react';
import cn from 'classnames';

import Scanner, { ScannerEntityLinkProps } from '../Scanner/Scanner';

type NodeEntityProps = ScannerEntityLinkProps & {
  chainId: number;
  address: string;
  enabled?: boolean;
  className?: string;
};

function NodeEntity(props: NodeEntityProps): JSX.Element {
  const { to, href, enabled, target, chainId, address, className } = props;

  return (
    <Scanner
      to={to}
      href={href}
      target={target}
      chainId={chainId}
      enabled={enabled}
      className={cn('NodeEntity', className)}
    >
      {address}
    </Scanner>
  );
}

export default NodeEntity;
