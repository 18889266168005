import { useMemo } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';

import { getNetworkByChainId, Network } from '../lib/networks';

function useProvider(
  chainId = Network.Mainnet
): ethers.providers.JsonRpcProvider {
  const { provider } = useWeb3React();

  if (!getNetworkByChainId(chainId)?.rpcProviderUrl)
    console.error(`Cannot find network info for chain id: ${chainId}`);

  return useMemo(() => {
    if (
      provider &&
      [provider.network?.chainId, provider._network?.chainId].includes(chainId)
    ) {
      return provider;
    }

    // fallback to public url
    return new ethers.providers.StaticJsonRpcProvider(
      getNetworkByChainId(chainId)?.rpcProviderUrl,
      'any'
    );
  }, [chainId, provider]);
}

export default useProvider;
