import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { Web3ContextType, useWeb3React } from '@web3-react/core';

import { setModalState } from 'forta-app/slices/notificationSlice';
import { useAppDispatch, useJWT } from 'forta-app/app/hooks';
import type { AppDispatch } from 'forta-app/app/store';
import NotificationList from 'forta-app/components/notifications/NotificationList';
import NotificationEmailList from 'forta-app/components/notifications/NotificationEmailList';

import './NotificationsPage.scss';
import Button from 'common/components-v2/Button/Button';
import DataFeesSubscriptionsAlert from 'forta-app/components/notifications/DataFeesSubscriptionsAlert';
import { useConnectModal } from 'forta-app/components/wallet/useConnectModal';

const VALID_TOKEN_MESSAGE = 'email-verified';
const INVALID_TOKEN_MESSAGE = 'invalid-verify-token';

export const subscribeToAgent = async (
  agentId: string,
  dispatch: AppDispatch,
  web3React: Web3ContextType,
  openConnectModal: () => void
): Promise<void> => {
  const isWeb3Active = web3React.isActive;
  if (!isWeb3Active) {
    openConnectModal();
  }

  if (isWeb3Active) {
    dispatch(
      setModalState({
        id: -1,
        opened: true,
        loading: false,
        notificationType: 'BOT',
        sources: [agentId],
        endpointType: 'EMAIL',
        endpoint: ''
      })
    );
  }
};

export const subscribeToAddress = async (
  address: string,
  dispatch: AppDispatch,
  web3React: Web3ContextType,
  openConnectModal: () => void
): Promise<void> => {
  const isWeb3Active = web3React.isActive;
  if (!isWeb3Active) {
    openConnectModal();
  }

  if (isWeb3Active) {
    dispatch(
      setModalState({
        id: -1,
        opened: true,
        loading: false,
        notificationType: 'ADDRESS',
        sources: [address],
        endpointType: 'EMAIL',
        endpoint: ''
      })
    );
  }
};

export const subscribeToGroup = async (
  groupId: string,
  dispatch: AppDispatch,
  web3React: Web3ContextType,
  openConnectModal: () => void
): Promise<void> => {
  const isWeb3Active = web3React.isActive;
  if (!isWeb3Active) {
    openConnectModal();
  }

  if (isWeb3Active) {
    dispatch(
      setModalState({
        id: -1,
        opened: true,
        loading: false,
        notificationType: 'KIT',
        source: '',
        groupId: groupId,
        endpointType: 'EMAIL',
        endpoint: ''
      })
    );
  }
};

export default function NotificationsPage(): JSX.Element {
  const dispatch = useAppDispatch();
  const web3React = useWeb3React();
  const jwt = useJWT();
  const expired = !jwt;
  const history = useHistory();
  const { open: openConnectModal, close: closeConnectModal } =
    useConnectModal();

  useEffect(() => {
    const query = queryString.parse(history.location.search);

    if (query.message) {
      switch (query.message) {
        case INVALID_TOKEN_MESSAGE:
          toast.error('Invalid verification link', {
            position: 'top-center'
          });
          return;
        case VALID_TOKEN_MESSAGE:
          toast.success('Email has been verified', {
            position: 'top-center'
          });
          return;
      }
    }
  }, [history.location.search]);

  useEffect(() => {
    const query = queryString.parse(history.location.search);

    if (query.scopeId && typeof query.scopeId === 'string') {
      const [scope, value] = query.scopeId.split('|');
      if (scope && value) {
        switch (scope) {
          case 'address':
            subscribeToAddress(value, dispatch, web3React, openConnectModal);
            break;
          case 'agent':
            subscribeToAgent(value, dispatch, web3React, openConnectModal);
            break;
        }
      }
    }
  }, [history.location.search, dispatch, web3React, openConnectModal]);

  useEffect(() => {
    if (web3React.account && !expired) {
      closeConnectModal();
    }
  }, [closeConnectModal, expired, web3React.account]);

  const onAddNotificationClick = (): void => {
    subscribeToAgent('', dispatch, web3React, openConnectModal);
  };

  return (
    <div className="NotificationsPage">
      <div className="NotificationsPage__header">
        <div className="NotificationsPage__title">My Subscriptions</div>
        {!expired && (
          <div className="NotificationsPage__add-notification">
            <Button
              variant="primary"
              size="md"
              onClick={onAddNotificationClick}
            >
              Add subscription
            </Button>
          </div>
        )}
      </div>
      <div className="NotificationsPage__body">
        <NotificationList />
        <NotificationEmailList />
      </div>
      <DataFeesSubscriptionsAlert />
    </div>
  );
}
