import { DocumentNode, gql } from '@apollo/client';

import { ApolloClientName } from 'common/components/ApolloClientProvider';
import useGraphQuery, { GraphQueryResult } from 'common/hooks/useGraphQuery';

const cache: { [query: string]: DocumentNode } = {};

function getQuery(props: {
  owner?: string;
  id_in?: string[];
  chainId?: number;
}): DocumentNode {
  const { owner, chainId, id_in } = props;

  const str = `
    query ScannerPoolsQuery(
      $orderBy: String = "id"
      $orderDirection: String = "asc"
      $first: Int = 12
      $id_gt: String = ""
      ${id_in ? `$id_in: [String!]` : ''}
      ${owner ? `$owner: String` : ''}
      $chainId: Int
      $skip: Int
    ) {
      scannerPools(
        orderBy: $orderBy
        orderDirection: $orderDirection
        where: { ${[
          id_in && `id_in: $id_in`,
          owner && `owner: $owner`,
          chainId && `chainId: $chainId`
        ]
          .filter((v) => v)
          .join(', ')} }
        first: $first
        skip: $skip
      ) {
        id
        chainId
        status
        chainId
        stakeAllocated
        stakeDelegated
        stakeOwned
        stakeOwnedAllocated
        apyForLastEpoch
        oldCommission
        commission
        commissionSinceEpoch
        owner {
          id
        }
        scanNodes {
          id
          enabled
        }
      }
    }`;
  const query = cache[str] || gql(str);
  cache[str] = query;
  return query;
}

export type ScannerPoolsRequestParams = {
  skip?: number;
  orderBy?: string;
  orderDirection?: string;
  first?: number;
  id_gt?: number;
  id_in?: string[];
  owner?: string;
  chainId?: number;
};

export type ScannerPool = {
  id: string;
  status: string;
  chainId: number;
  stakeAllocated: string;
  stakeDelegated: string;
  stakeOwned: string;
  stakeOwnedAllocated: string;
  apyForLastEpoch: number;
  owner: {
    id: string;
  };
  oldCommission: string;
  commission: string;
  commissionSinceEpoch: number;
  scanNodes: {
    enabled: boolean;
    id: string;
  }[];
};

type ResponseData = { scannerPools: ScannerPool[] };

function useScannerPoolsQuery(opts?: {
  params: ScannerPoolsRequestParams;
  enabled?: boolean;
  enablePolling?: boolean;
}): GraphQueryResult<ScannerPoolsRequestParams, ResponseData> & {
  scannerPools: ScannerPool[];
  hasNextPage: boolean;
} {
  const { enabled, enablePolling, params = {} } = opts || {};

  // Unfortunately, some fields in the filter cannot be optional (e.g. id_in, owner),
  // so we have to generate the query dynamically.
  const query = getQuery(params);
  const pageSize = params.first ?? 12;

  const queryResult = useGraphQuery<ScannerPoolsRequestParams, ResponseData>({
    enabled,
    query: query,
    enablePolling: enablePolling,
    clientName: ApolloClientName.Subgraph,
    variables: {
      owner: params.owner?.toLowerCase(),
      id_in: params.id_in,
      id_gt: params.id_gt,
      chainId: params.chainId,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      skip: params.skip,
      first: pageSize + 1
    }
  });

  const hasNextPage = queryResult.data?.scannerPools.length === pageSize + 1;

  const scannerPools =
    queryResult.data?.scannerPools.filter((v, i) => i < pageSize) || [];

  return {
    ...queryResult,
    scannerPools,
    hasNextPage
  };
}

export default useScannerPoolsQuery;
