import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { range } from 'lodash';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import './BotSearchPage.scss';

import BotCard, { BotCardSkeleton } from 'common/components-v2/BotCard/BotCard';
import Input from 'common/components-v2/Form/Input/Input';
import Button from 'common/components-v2/Button/Button';
import Chip, { ChipContainer } from 'common/components-v2/Chip/Chip';
import useBotsQuery, { BotsQueryParams } from 'forta-app/hooks/useBotsQuery';
import { isAgentId, isDeveloperId } from 'common/lib/utils';
import { currentENV } from 'common/config';
import {
  ExternalLinkIcon,
  SearchIcon,
  SortIcon,
  StarIcon
} from 'common/components/Icons';
import { QueryParamConfig } from 'serialize-query-params/src/types';
import Switch from 'common/components-v2/Form/Switch/Switch';
import BaseButton from 'common/components-v2/Button/BaseButton';
import { useHistory } from 'react-router-dom';

enum BotSorting {
  Popular = 'popular',
  Recent = 'recent'
}

const FEATURED_BOTS = [
  // scam detector feed
  '0x1d646c4045189991fdfd24a66b192a294158b839a6ec121d740474bdacb3ab23',
  // attack detector
  '0x80ed808b586aeebe9cdd4088ea4dea0a8e322909c0e4493c993e060e89c09ed1',
  // Funding Laundering Detector (vvlovsky)
  '0x186f424224eac9f0dc178e32d1af7be39506333783eec9463edd247dc8df8058',
  // scammer-nft-trader
  '0x513ea736ece122e1859c1c5a895fb767a8a932b757441eff0cadefa6b8d180ac',
  // Attack Simulation bot
  '0xe8527df509859e531e58ba4154e9157eb6d9b2da202516a66ab120deabd3f9f6',
  // Malicious Smart Contract ML Bot v2
  '0x0b241032ca430d9c02eaa6a52d217bbff046f0d1b3f3d2aa928e42a97150ec91',
  // forta-ice-phishing-starter-kit
  '0x8badbf2ad65abc3df5b1d9cc388e419d9255ef999fb69aac6bf395646cf01c14',
  // flashloan-detection-bot
  '0x55636f5577694c83b84b0687eb77863850c50bd9f6072686c8463a0cbc5566e0',
  // Reentrancy Calls Detection Bot
  '0x492c05269cbefe3a1686b999912db1fb5a39ce2e4578ac3951b0542440f435d9',
  // asset-drained
  '0xe4a8660b5d79c0c64ac6bfd3b9871b77c98eaaa464aa555c00635e9d8b33f77f'
];

const TEST_BOTS = [
  '0xe5d1a9f6da9140c7762a2cd470e0f150db57550f3d51c5e56cba1941c1e1fa3e',
  '0x2bee737433c0c8cdbd924bbb68306cfd8abcf0e46a6ce8994fa7d474361bb186',
  '0x787359342480d50ef94087169bca59fd583a73e36c1d2247464cbd27932ec803',
  '0xd9fc5c3d88160d862fb18fb54df1aa1957c45235e4702c49a58c7bfc307716e1',
  '0x09376b3eab0ed8bd08abd9c90433fc57b693b51faedb35742c43d45ff2159b71',
  '0xb1a6793fa9d35c09cde2d6d171af7666d9de4c239dafc335dff67d8b9620460a',
  '0xdb0ef5850c96f461b1c35a5c5622acd12c3dca7b6d0fcfb170bf90bddaac6fe0'
];

function getFilteredBots(sorting: BotSorting): string[] | undefined {
  if (sorting !== BotSorting.Popular) return undefined;

  return currentENV === 'production' ? FEATURED_BOTS : TEST_BOTS;
}

export const SortingParam: QueryParamConfig<BotSorting> = {
  encode(value): string {
    return value || BotSorting.Popular;
  },
  decode(value): BotSorting {
    if (Object.values(BotSorting).includes(value as BotSorting))
      return value as BotSorting;
    return BotSorting.Popular;
  }
};

export default function BotSearchPage(): JSX.Element {
  const [searchParam, setSearchParam] = useQueryParam('search', StringParam);
  const [sortingParam, setSortingParam] = useQueryParam('sort', SortingParam);
  const [shouldShowDisabledParam, setShouldShowDisabledParam] = useQueryParam(
    'include-disabled',
    BooleanParam
  );

  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState(searchParam || '');
  const [filterQuery, setFilterQuery] = useState<BotsQueryParams>(() => ({
    ids: getFilteredBots(sortingParam),
    pageSize: sortingParam === BotSorting.Recent ? 10 : FEATURED_BOTS.length
  }));
  const history = useHistory();

  // Apply query params to filter on initial load
  useLayoutEffect(() => {
    if (searchInput) {
      search();
      setSortingParam(BotSorting.Recent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    bots: unsortedBots,
    error,
    loading,
    isFetchingMore,
    hasNextPage,
    invalidate,
    fetchMore
  } = useBotsQuery({
    params: useMemo(
      () => ({
        ...filterQuery,
        enabled: shouldShowDisabledParam ? undefined : true,
        ids:
          filterQuery.text || filterQuery.developer
            ? undefined
            : filterQuery.ids
      }),
      [filterQuery, shouldShowDisabledParam]
    )
  });

  const bots = useMemo(() => {
    const bots = unsortedBots.slice();
    if (
      !loading &&
      !filterQuery.text &&
      !filterQuery.developer &&
      sortingParam === BotSorting.Popular
    ) {
      bots.sort(
        (b1, b2) => FEATURED_BOTS.indexOf(b1.id) - FEATURED_BOTS.indexOf(b2.id)
      );
    }
    return bots;
  }, [unsortedBots, sortingParam, filterQuery, loading]);

  useLayoutEffect(() => {
    setPage(0);
    setFilterQuery((v) => ({
      ...v,
      ids: getFilteredBots(sortingParam)
    }));
  }, [sortingParam]);

  useEffect(() => {
    if (error) {
      toast.error('Error trying to retrieve your bots');
    }
  }, [error]);

  function search(): void {
    if (isAgentId(searchInput)) {
      history.push(`/bot/${searchInput}`);
    } else if (isDeveloperId(searchInput)) {
      setFilterQuery((v) => ({ ...v, text: '', developer: searchInput }));
    } else {
      setFilterQuery((v) => ({ ...v, text: searchInput, developer: '' }));
    }
    setPage(0);
    setSearchParam(searchInput);
  }

  async function handleSearch(
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    e.preventDefault();

    await search();
  }

  async function loadMore(): Promise<void> {
    const newPage = (page ?? 1) + 1;
    setPage(newPage);
    return fetchMore(newPage);
  }

  async function changeSorting(sorting: BotSorting): Promise<void> {
    await invalidate();
    setSortingParam(sorting);
    setFilterQuery({
      ids: getFilteredBots(sorting),
      pageSize: sorting === BotSorting.Recent ? 10 : FEATURED_BOTS.length
    });
  }

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px'
  });

  return (
    <div className="BotSearchPage">
      <Button
        size="lg"
        variant="outline"
        href="https://docs.forta.network/en/latest/getting-started/"
        endIcon={ExternalLinkIcon}
        target="_blank"
        className="BotSearchPage__docs-button"
      >
        Build your own bot
      </Button>
      <h1 className="BotSearchPage__title">
        Detection <span className="color-purple">Bots</span>
      </h1>
      <p className="BotSearchPage__description">
        Forta comprises a decentralized network of independent bots that scan
        all transactions and block-by-block state changes for threats and
        anomalies. When an issue is detected, scan bots send alerts to
        subscribers, which enables them to take action.
      </p>
      <form onSubmit={handleSearch} className="BotSearchPage__form">
        <Input
          resettable
          name="search"
          variant="light"
          value={searchInput}
          placeholder="Search by Bot Name / Bot ID / Developer ID"
          onChange={(e) => setSearchInput(e.target.value)}
          className="BotSearchPage__form__input"
        />
        <Button
          type="submit"
          variant="primary"
          size="lg"
          icon={SearchIcon}
          className="BotSearchPage__form__submit"
        />
      </form>
      <div className="BotSearchPage__filter-panel">
        <ChipContainer alignment="row" className="BotSearchPage__chips">
          <Chip
            startIcon={StarIcon}
            active={sortingParam === BotSorting.Popular}
            onClick={() => changeSorting(BotSorting.Popular)}
          >
            Popular bots
          </Chip>
          <Chip
            startIcon={SortIcon}
            active={sortingParam === BotSorting.Recent}
            onClick={() => changeSorting(BotSorting.Recent)}
          >
            Recent bots
          </Chip>
          <Chip variant="outline" onClick={() => history.push(`/discover`)}>
            Discover Protocols
          </Chip>
          <Switch
            name="disabled-bots"
            label="Include disabled"
            labelPosition="end"
            onChange={() => setShouldShowDisabledParam((v) => !v)}
            checked={!!shouldShowDisabledParam}
          />
        </ChipContainer>
      </div>
      <div className="BotSearchPage__body">
        {((bots || []).length > 0 || loading) && (
          <ul className="BotSearchPage__bots">
            {(bots || []).map((bot) => (
              <li key={bot.agent_id}>
                <BotCard bot={bot} />
              </li>
            ))}
            {loading &&
              range(0, 6).map((i) => (
                <li key={i}>
                  <BotCardSkeleton />
                </li>
              ))}
          </ul>
        )}
        {!loading && bots?.length === 0 && (
          <p className="BotSearchPage__notification">
            <div>
              No bots found{' '}
              <span role="img" aria-label="ok-emoji">
                ✅{' '}
              </span>
            </div>
            {!shouldShowDisabledParam && (
              <div>
                Should it{' '}
                <BaseButton
                  className="BotSearchPage__notification-button"
                  onClick={() => setShouldShowDisabledParam(true)}
                >
                  Include disabled
                </BaseButton>{' '}
                bots?
              </div>
            )}
          </p>
        )}
        {hasNextPage && (
          <div ref={sentryRef} className="BotSearchPage__pagination">
            <Button
              size="lg"
              variant="default"
              loading={isFetchingMore}
              disabled={isFetchingMore}
              onClick={() => loadMore()}
            >
              {loading ? 'Loading more...' : 'Load more'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
