import React from 'react';
import cn from 'classnames';
import BigNumber from 'bignumber.js';
import Skeleton from 'react-loading-skeleton';

import './VaultTable.scss';

import TableHead from 'common/components-v2/Table/TableHead';
import TableHeading, {
  TableActionHeading
} from 'common/components-v2/Table/TableHeading';
import TableSubHeading from 'common/components-v2/Table/TableSubHeading';
import TableBody from 'common/components-v2/Table/TableBody';
import Table from 'common/components-v2/Table/Table';
import TableCell from 'common/components-v2/Table/TableCell';
import TableSubCell from 'common/components-v2/Table/TableSubCell';
import TableRow from 'common/components-v2/Table/TableRow';
import ActionCell, { Option } from 'common/components-v2/Table/ActionCell';
import { formatFORT } from '../../../lib/utils';
import VaultStatusEntity, { VaultStatus } from './Status/VaultStatus';

type VaultTableProps = {
  apy: number | null;
  userAssets: BigNumber | null;
  totalAssets: BigNumber | null;
  userPendingAssets: BigNumber | null;
  userClaimableAssets: BigNumber | null;
  loading?: boolean;
  empty?: boolean;
  actions: Option[];
  className?: string;
};

function VaultTable(props: VaultTableProps): JSX.Element {
  const {
    apy,
    userAssets,
    totalAssets,
    userPendingAssets,
    userClaimableAssets,
    empty,
    loading,
    actions,
    className
  } = props;

  return (
    <Table minWidth={1000} className={cn('VaultTable', className)}>
      <TableHead>
        <TableHeading flex={15}>
          <TableSubHeading title="Est. Annual Rewards" flex={3} />
          <TableSubHeading title="Your Holdings" flex={3} />
          <TableSubHeading title="Pending Withdrawal" flex={3} />
          <TableSubHeading title="Total in Vault" flex={3} />
          <TableSubHeading title="Status" flex={3} />
        </TableHeading>
        <TableActionHeading />
      </TableHead>
      <TableBody
        empty={empty}
        loading={loading}
        emptyMessage="There is currently no FORT delegated in this Vault"
      >
        <TableRow>
          <TableCell flex={15}>
            <TableSubCell
              value={apy != null ? `${apy} %` : <Skeleton width={60} />}
              flex={3}
            />
            <TableSubCell
              value={
                userAssets ? (
                  `${formatFORT(userAssets.toString())} FORT`
                ) : (
                  <Skeleton width={60} />
                )
              }
              flex={3}
            />
            <TableSubCell
              value={
                userPendingAssets ? (
                  `${formatFORT(userPendingAssets.toString())} FORT`
                ) : (
                  <Skeleton width={60} />
                )
              }
              flex={3}
            />
            <TableSubCell
              value={
                totalAssets ? (
                  formatFORT(totalAssets.toString()) + ' FORT'
                ) : (
                  <Skeleton width={60} />
                )
              }
              flex={3}
            />
            <TableSubCell
              value={
                userAssets ? (
                  <VaultStatusEntity
                    status={(() => {
                      if (userPendingAssets?.isGreaterThan(BigNumber(0))) {
                        return VaultStatus.WITHDRAWAL_IN_PROCESS;
                      } else if (
                        userClaimableAssets?.isGreaterThan(BigNumber(0))
                      ) {
                        return VaultStatus.ASSETS_CLAIMABLE;
                      } else if (userAssets.isZero()) {
                        return VaultStatus.WAITING_FOR_DEPOSIT;
                      } else {
                        return VaultStatus.ACTIVE_DEPOSIT;
                      }
                    })()}
                  />
                ) : (
                  <Skeleton width={100} />
                )
              }
              flex={3}
            />
          </TableCell>
          <ActionCell options={actions} />
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default VaultTable;
