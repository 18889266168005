declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

window.dataLayer = window.dataLayer || [];

export type EventName =
  | 'global_search'
  | 'filter_search'
  | 'connect_wallet'
  | 'data_plan_click'
  | 'unlock_buy_click'
  | 'unlock_free_trial_click'
  | 'explore_product_click'
  | string;

export const trackEvent = (
  eventName: EventName,
  data: Record<string, unknown>
): void => {
  if (!window.dataLayer) {
    console.error('No data layer');
    return;
  }

  window.dataLayer.push({
    event: eventName,
    data: data || {}
  });
};
