import classNames from 'classnames';
import Select from 'react-select';
import './DropDown.scss';

interface Option {
  value: string;
  label: string | JSX.Element;
}

interface DropdownProps {
  options: Option[];
  name: string;
  value?: Option;
  onChange?: (value: Option | null) => void;
  flavor?: 'dark';
  isSearchable?: boolean;
  placeHolder?: string;
}

export default function DropDown(props: DropdownProps): JSX.Element {
  const containerClasses = classNames({
    'Dropdown-container': true,
    'Dropdown-container--dark': props.flavor === 'dark'
  });

  return (
    <Select
      key={`${props.name}-${props.placeHolder}`}
      className={containerClasses}
      classNamePrefix="Dropdown"
      defaultValue={props.placeHolder ? null : props.options[0]}
      name={props.name}
      options={props.options}
      value={props.value}
      onChange={props.onChange}
      isSearchable={props.isSearchable}
      placeholder={props.placeHolder}
    />
  );
}
