import Modal from 'common/components/Modal';
import './VaultStakingModal.scss';
import { VaultStakingForm } from './VaultStakingForm';

interface StakeModalProps {
  opened: boolean;
  onClose: () => void;
}

export default function VaultStakeModal({
  opened,
  onClose
}: StakeModalProps): JSX.Element {
  return (
    <Modal
      opened={opened}
      title={'Deposit to Vault'}
      className="StakeModal__container"
      onCloseModal={onClose}
    >
      <div className="StakeModal">
        <VaultStakingForm />
      </div>
    </Modal>
  );
}
