import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { CheckIcon, CheckRadioIcon } from '../../../components/Icons';

import './Mark.scss';

type MarkProps = {
  variant?: 'checkbox' | 'radio';
  checked?: boolean;
  disabled?: boolean;
  className?: string;
};

function Mark(props: MarkProps): JSX.Element {
  const { checked, disabled, variant, className } = props;

  const classNames = cn('Mark', `Mark--${variant}`, className, {
    ['Mark--disabled']: disabled,
    ['Mark--checked']: checked
  });

  return (
    <span className={classNames} aria-hidden="true">
      {checked && (variant === 'checkbox' ? CheckIcon : CheckRadioIcon)}
    </span>
  );
}

Mark.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  className: PropTypes.string
};

export default Mark;
