import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import config from 'common/config';
import { BigNumber } from 'ethers';
import { isHexString } from 'ethers/lib/utils';
import { SubjectType } from '../contract-interactors/stakingContract';

export interface StakeResult {
  id: string;
  shares: BigNumber;
}

export interface SubjectResult {
  id: string;
  subjectType: SubjectType;
  activeShares: string;
  activeStake: string;
  inactiveStake: string;
  inactiveShares: string;
  slashedTotal: string | number;
  isFrozen: boolean;
}

const toGraphHexString = (hex: string): string =>
  '0x' + hex.toLocaleLowerCase().replace('0x', '');
export const toEthersHexString = (hex: string): string =>
  isHexString(hex) ? BigNumber.from(hex).toHexString() : hex;

export async function getSubjectStake(
  subjectId: string,
  subjectType: SubjectType
): Promise<SubjectResult> {
  const subgraphClient = new ApolloClient({
    uri: config.subgraphAPIEndpoint,
    cache: new InMemoryCache()
  });

  const apiResult = await subgraphClient.query({
    variables: {
      subjectId:
        subjectType === SubjectType.SCANNERPOOL ||
        subjectType === SubjectType.SCANNERPOOL_DELEGATOR
          ? subjectId
          : toGraphHexString(subjectId),
      subjectType: subjectType.toString()
    },
    query: gql`
      query Subject($subjectId: String!, $subjectType: String!) {
        subject(id: $subjectId, subjectType: $subjectType) {
          id
          subjectId
          subjectType
          activeShares
          activeStake
          inactiveStake
          inactiveShares
          slashedTotal
          isFrozen
        }
      }
    `
  });
  let subject: SubjectResult = apiResult?.data?.subject || {
    id: '',
    activeShares: '0',
    activeStake: '0',
    inactiveStake: '0',
    inactiveShares: '0',
    slashedTotal: '0',
    isFrozen: false
  };
  subject = {
    ...subject,
    id: subject.id ? subject.id : ''
  };
  return subject;
}

export async function getStakers(
  subjectId: string,
  page = 0
): Promise<StakeResult[]> {
  const subgraphClient = new ApolloClient({
    uri: config.subgraphAPIEndpoint,
    cache: new InMemoryCache()
  });
  const pageSize = 10;

  const apiResult = await subgraphClient.query({
    variables: {
      subjectId: toGraphHexString(subjectId),
      first: pageSize,
      skip: pageSize * page
    },
    query: gql`
      query Stakes($subjectId: String!, $first: Int!, $skip: Int!) {
        stakes(where: { subject: $subjectId }, first: $first, skip: $skip) {
          staker {
            id
          }
          shares
        }
      }
    `
  });
  const stakers: StakeResult[] = (apiResult?.data?.stakes || [])
    .map((stake: { staker: { id: string }; shares: string }): StakeResult => {
      return {
        id: stake?.staker?.id ? toEthersHexString(stake?.staker?.id) : '',
        shares: BigNumber.from(stake?.shares || 0)
      };
    })
    .filter((stake: StakeResult) => stake.id);
  return stakers;
}

export interface Stake {
  subject: {
    id: string;
    subjectId: string;
    subjectType: number;
    activeShares: string;
    activeStake: string;
    inactiveShares: string;
    inactiveStake: string;
  };
  shares: string;
  inactiveShares: string;
}

export interface StakesOfResult {
  id: string;
  stakes: Stake[];
}

export async function getStakesOf(stakerId: string): Promise<StakesOfResult> {
  const subgraphClient = new ApolloClient({
    uri: config.subgraphAPIEndpoint,
    cache: new InMemoryCache()
  });
  const apiResult = await subgraphClient.query({
    variables: {
      stakerId: toGraphHexString(stakerId)
    },
    query: gql`
      query Subject($stakerId: String!) {
        staker(id: $stakerId) {
          id
          stakes(where: { subject_: { subjectType_in: [3, 2, 1] } }) {
            subject {
              id
              subjectType
              activeShares
              activeStake
              inactiveShares
              inactiveStake
            }
            shares
            inactiveShares
          }
        }
      }
    `
  });
  let result: StakesOfResult = apiResult?.data?.staker || {
    id: '',
    stakes: []
  };
  result = {
    id: result.id ? toEthersHexString(result.id) : '',
    stakes: result.stakes.map((stake) => {
      return {
        ...stake,
        subject: {
          ...stake.subject,
          id: stake.subject.id ? toEthersHexString(stake.subject.id) : ''
        }
      };
    })
  };
  return result;
}
