/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import cn from 'classnames';

import './Scanner.scss';

import { getNetworkByChainId } from 'common/lib/networks';
import ChainIcon from 'common/components/stats/ChainIcon';
import BaseButton from 'common/components-v2/Button/BaseButton';

export type ScannerEntityLinkProps = {
  to?: string;
  href?: string;
  target?: '_blank' | '_self' | '_parent';
};

type ScannerEntityProps = ScannerEntityLinkProps & {
  chainId?: number;
  enabled?: boolean;
  children?: ReactNode;
  className?: string;
};

function ScannerEntity(props: ScannerEntityProps): JSX.Element {
  const { to, href, target, chainId, children, enabled, className } = props;

  return (
    <BaseButton
      to={to}
      href={href}
      target={target}
      className={cn('ScannerEntity', className, {
        ['ScannerEntity--link']: to || href
      })}
    >
      {chainId != null && (
        <div className="ScannerChain">
          <ChainIcon
            network={getNetworkByChainId(chainId)}
            className={cn('ScannerChain__img')}
          />
          {enabled != null && (
            <div
              className={cn('ScannerChain__indicator', {
                ['ScannerChain__indicator--enabled']: enabled
              })}
            />
          )}
        </div>
      )}
      <span className="ScannerEntity__children">{children}</span>
    </BaseButton>
  );
}

export default ScannerEntity;
