import { Web3ReactHooks } from '@web3-react/core';
import { AddEthereumChainParameter, Connector } from '@web3-react/types';

import { buildInjectedConnector } from './injected';
import { buildWalletConnectConnector } from './wallet-connect';
import config from 'common/config';

export interface Connection {
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
}

export enum ConnectionType {
  INJECTED = 'INJECTED',
  WALLET_CONNECT = 'WALLET_CONNECT'
}

export function getHasMetaMaskExtensionInstalled(): boolean {
  return window.ethereum?.isMetaMask ?? false;
}

export function onConnectionError(error: Error): void {
  console.debug(`web3-react error: ${error}`);
}

export const PRIORITIZED_CONNECTORS: { [key in ConnectionType]: Connection } = {
  [ConnectionType.INJECTED]: buildInjectedConnector(),
  [ConnectionType.WALLET_CONNECT]: buildWalletConnectConnector()
};

export function getConnection(c: Connector | ConnectionType): Connection {
  if (c instanceof Connector) {
    const connection = Object.values(PRIORITIZED_CONNECTORS).find(
      (connection) => connection.connector === c
    );
    if (!connection) {
      throw Error('Unsupported Connector');
    }
    return connection;
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return PRIORITIZED_CONNECTORS[ConnectionType.INJECTED];
      case ConnectionType.WALLET_CONNECT:
        return PRIORITIZED_CONNECTORS[ConnectionType.WALLET_CONNECT];
    }
  }
}

export const switchNetwork = async (
  chainId: number,
  connectionType: ConnectionType | null
): Promise<void> => {
  if (connectionType) {
    const { connector } = getConnection(connectionType);

    if (connectionType === ConnectionType.WALLET_CONNECT) {
      await connector.activate(chainId);
      return;
    }

    const addChainParameter: AddEthereumChainParameter = {
      chainId,
      chainName: config.chainLabel,
      rpcUrls: [config.rpcEndpoint],
      nativeCurrency: config.chainNativeCurrency,
      blockExplorerUrls: [config.chainExplorer]
    };
    await connector.activate(addChainParameter);
  }
};

export const tryActivateConnector = async (
  connector: Connector
): Promise<ConnectionType | undefined> => {
  await connector.activate();
  const connectionType = getConnection(connector).type;
  return connectionType;
};

export const tryDeactivateConnector = async (
  connector: Connector
): Promise<null | undefined> => {
  connector.deactivate?.();
  connector.resetState();
  return null;
};
