import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './AlertRow.scss';

import InfoPopover from 'common/components/InfoPopover';
import ChainIcon from 'common/components/stats/ChainIcon';
import { MuteIcon } from 'common/components/Icons';
import { getNetworkByChainId, Network, NetworkInfo } from 'common/lib/networks';
import {
  Alert,
  AlertDocumentType,
  AlertsApiParams,
  SeveritiesInfo
} from 'common/lib/apis/alertAPI';
import { Routes } from '../../../../../routes';

type AlertRowProps = {
  alert: Alert;
  filter?: AlertsApiParams;
  changeable?: boolean;
  onFilterChange?: (filter: AlertsApiParams) => void;
};

interface SetChainIconProps {
  alert: Alert;
  network: NetworkInfo;
}

export function SetChainIcon({
  alert,
  network
}: SetChainIconProps): JSX.Element {
  const isCombiner =
    alert.alert_document_type === AlertDocumentType.COMBINATION;
  const isExternal = alert.alert_document_type === AlertDocumentType.API;
  if ((isCombiner || isExternal) && !(network.chainId in Network)) {
    const popoverDescription = isExternal
      ? `Notice the blockchain missing? These alerts are not tied to a specific blockchain txn, this alert was sent via our API.`
      : `Notice the blockchain missing? These alerts are not tied to a specific blockchain txn, they are produced by combining multiple alerts emitted by other base bots.`;
    return (
      <InfoPopover content={popoverDescription} rightPositioned>
        <Link to={Routes.alerts.details.index({ id: alert.hash })}>
          <ChainIcon network={network} />
        </Link>
      </InfoPopover>
    );
  } else {
    const externalSourcePath = alert.source.tx_hash
      ? `${network.txExplorerUrl}/${alert.source.tx_hash}`
      : alert.source.transactions?.length === 1
      ? `${network.txExplorerUrl}/${alert.source.transactions[0].hash}`
      : alert.source.block.chain_id in Network
      ? `${network.explorerUrl}/block/${alert.source.block.number}`
      : '';
    if (externalSourcePath) {
      return (
        <InfoPopover content={'View on Explorer'} rightPositioned>
          <a
            target="_blank"
            rel="noreferrer"
            className="AlertRow__external-link"
            onClick={(e) => e.stopPropagation()}
            href={`${externalSourcePath}`}
          >
            <ChainIcon network={network} />
          </a>
        </InfoPopover>
      );
    }
    const popoverDescription = isExternal
      ? `This alert is not tied to a specific blockchain txn, it was sent via our API.`
      : `This alert is not tied to a specific blockchain txn, it was produced by combining multiple alerts emitted by other base bots.`;
    return (
      <InfoPopover content={popoverDescription} rightPositioned>
        <Link to={Routes.alerts.details.index({ id: alert.hash })}>
          <ChainIcon network={network} />
        </Link>
      </InfoPopover>
    );
  }
}

function AlertRow(props: AlertRowProps): JSX.Element {
  const {
    alert,
    filter,
    changeable = false,
    onFilterChange = () => undefined
  } = props;
  const network: NetworkInfo = getNetworkByChainId(
    alert.chain_id ||
      (alert.source.chains?.length === 1 && alert.source.chains[0].chainId) ||
      0
  );
  const severity = SeveritiesInfo[alert.severity];

  function handleChange(patchObj: Partial<AlertsApiParams>): void {
    if (changeable && onFilterChange && filter) {
      onFilterChange({ ...filter, ...patchObj });
    }
  }

  return (
    <div className="AlertRow">
      <div className="AlertRow__severity" data-name="Severity">
        <p
          className="AlertRow__severity-label"
          style={{ color: severity.color }}
        >
          {severity.label}
        </p>
      </div>
      <div className="AlertRow__name" data-name="Alert">
        <Link
          to={Routes.alerts.details.index({ id: alert.hash })}
          target="_blank"
        >
          {alert.name}
        </Link>
        {changeable && (
          <div className="AlertRow__mute">
            <button
              onClick={(e) => {
                e.preventDefault();
                handleChange({ muted: [...(filter?.muted || []), alert.name] });
              }}
            >
              {MuteIcon}
            </button>
          </div>
        )}
      </div>
      <div className="AlertRow__description" data-name="Details">
        <div>{alert.description}</div>
      </div>
      <div className="AlertRow__scanners" data-name="Node Operators">
        <div>{alert.scanner_count}</div>
      </div>
      <div className="AlertRow__dates" data-name="Date">
        <p className="AlertRow__time">
          {moment(alert.source.block.timestamp).fromNow()}
        </p>
      </div>
      <div className="AlertRow__actions" data-name="Actions">
        <SetChainIcon alert={alert} network={network} />
      </div>
    </div>
  );
}

export default AlertRow;
