import { CrossIcon } from 'common/components/Icons';
import './MutedFilter.scss';

export interface MutedFilterProps {
  onChange: (value: string[]) => void;
  selection: string[];
}

export default function MutedFilter({
  selection,
  onChange
}: MutedFilterProps): JSX.Element {
  const onButtonClick = (index: number): void => {
    const newSelection = [...selection];
    newSelection.splice(index, 1);
    onChange(newSelection);
  };

  return (
    <div className="MutedFilter">
      <div className="MutedFilter__title">Muted Alerts: </div>
      <div className="MutedFilter__alerts">
        {selection.map((name, index) => {
          return (
            <div key={`${name}-${index}`} className="MutedFilter__alert">
              {name}{' '}
              <button
                className="MutedFilter__alert-btn"
                onClick={() => onButtonClick(index)}
              >
                {CrossIcon}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
