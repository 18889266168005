import { useWeb3React } from '@web3-react/core';
import { TransactionResponse } from '@ethersproject/providers';
import Modal from 'common/components/Modal';
import InformationBox from 'common/components/InformationBox';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { toFORTPrecision } from 'forta-app/lib/utils';
import { useCallback, useEffect, useState } from 'react';
import Button from 'common/components-v2/Button/Button';
import NumberInput from 'common/components-v2/Form/Input/NumberInput';
import { BigNumber } from 'ethers';
import ContractCallButton from '../ContractCallButton';
import StakeAllocator from 'forta-app/lib/contract-interactors/stakeAllocator';
import './AllocateStakeButton.scss';

interface AllocateStakeModalProps {
  opened: boolean;
  scannerPoolId: string;
  allocation: Allocation;
  scanNodes: number;
  onClose: () => void;
}

export interface Allocation {
  stakeAllocated: BigNumber;
  stakeDelegated: BigNumber;
  stakeOwned: BigNumber;
  stakeOwnedAllocated: BigNumber;
}

const AllocateStakeModalInfo = ({
  allocation
}: AllocateStakeModalProps): JSX.Element => {
  return (
    <div className="AllocateStakeModal__info">
      <InformationBox.Container>
        <InformationBox.Title>Total • Allocated</InformationBox.Title>
        <InformationBox.Value>
          {toFORTPrecision(formatEther(allocation.stakeAllocated))}{' '}
          <small>FORT</small>
        </InformationBox.Value>
        <InformationBox.Group>
          <InformationBox.Item>
            <InformationBox.Label>Allocated from Owner</InformationBox.Label>
            <InformationBox.Value>
              {toFORTPrecision(formatEther(allocation.stakeOwnedAllocated))}{' '}
              <small>FORT</small>
            </InformationBox.Value>
          </InformationBox.Item>
          <InformationBox.Item>
            <InformationBox.Label>
              Allocated from Delegators
            </InformationBox.Label>
            <InformationBox.Value>
              {toFORTPrecision(
                formatEther(
                  allocation.stakeAllocated.sub(allocation.stakeOwnedAllocated)
                )
              )}{' '}
              <small>FORT</small>
            </InformationBox.Value>
          </InformationBox.Item>
        </InformationBox.Group>
      </InformationBox.Container>
      <InformationBox.Container>
        <InformationBox.Title>Total • Unallocated</InformationBox.Title>
        <InformationBox.Value>
          {toFORTPrecision(
            formatEther(
              allocation.stakeOwned
                .add(allocation.stakeDelegated)
                .sub(allocation.stakeAllocated)
            )
          )}{' '}
          <small>FORT</small>
        </InformationBox.Value>
        <InformationBox.Group>
          <InformationBox.Item>
            <InformationBox.Label>Unallocated from Owner</InformationBox.Label>
            <InformationBox.Value>
              {toFORTPrecision(
                formatEther(
                  allocation.stakeOwned.sub(allocation.stakeOwnedAllocated)
                )
              )}{' '}
              <small>FORT</small>
            </InformationBox.Value>
          </InformationBox.Item>
          <InformationBox.Item>
            <InformationBox.Label>
              Unallocated from Delegators
            </InformationBox.Label>
            <InformationBox.Value>
              {toFORTPrecision(
                formatEther(
                  allocation.stakeDelegated
                    .add(allocation.stakeOwnedAllocated)
                    .sub(allocation.stakeAllocated)
                )
              )}{' '}
              <small>FORT</small>
            </InformationBox.Value>
          </InformationBox.Item>
        </InformationBox.Group>
      </InformationBox.Container>
    </div>
  );
};

const AllocateStakeModalForm = (
  props: AllocateStakeModalProps
): JSX.Element => {
  const [amount, setAmount] = useState<string>('');
  const [allocationAction, setAllocationAction] = useState<
    'allocate' | 'unallocate'
  >('allocate');
  const [allocationSubject, setAllocationSubject] = useState<
    'delegators' | 'owner'
  >('owner');
  useEffect(() => setAmount(''), [allocationSubject, allocationAction]);
  const web3React = useWeb3React();
  const { scannerPoolId, allocation, scanNodes } = props;
  const { stakeAllocated, stakeDelegated, stakeOwned, stakeOwnedAllocated } =
    allocation;

  const maxAmountForAllocation =
    allocationAction === 'allocate'
      ? allocationSubject === 'owner'
        ? stakeOwned.sub(stakeOwnedAllocated)
        : stakeDelegated.add(stakeOwnedAllocated).sub(stakeAllocated)
      : allocationSubject === 'owner'
      ? stakeOwnedAllocated
      : stakeAllocated.sub(stakeOwnedAllocated);

  const hasScanNodes = scanNodes > 0;
  const allocationDisabled =
    (allocationAction === 'allocate' && !hasScanNodes) ||
    maxAmountForAllocation.lt(parseEther(amount || '0'));

  const onContractCallClicked = useCallback(
    (_amount: string): Promise<TransactionResponse> => {
      const stakeAllocator = new StakeAllocator(web3React);
      if (allocationAction === 'allocate') {
        if (allocationSubject === 'owner') {
          return stakeAllocator.allocateOwnStake(
            scannerPoolId,
            parseEther(_amount)
          );
        } else {
          return stakeAllocator.allocateDelegatorStake(
            scannerPoolId,
            parseEther(_amount)
          );
        }
      } else {
        if (allocationSubject === 'owner') {
          return stakeAllocator.unallocateOwnStake(
            scannerPoolId,
            parseEther(_amount)
          );
        } else {
          return stakeAllocator.unallocateDelegatorStake(
            scannerPoolId,
            parseEther(_amount)
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [amount, scannerPoolId, allocationAction, setAllocationSubject]
  );

  return (
    <div className="AllocateStakeModal__form">
      <div className="AllocateStakeModal__form__select">
        <div className="AllocateStakeModal__form__select-label">
          Action to perform:
        </div>
        <div className="ButtonGroupMenu">
          <button
            onClick={() => setAllocationAction('allocate')}
            className={`ButtonGroupMenu__item ${
              allocationAction === 'allocate'
                ? 'ButtonGroupMenu__item__selected'
                : ''
            }`}
          >
            Allocate
          </button>
          <button
            onClick={() => setAllocationAction('unallocate')}
            className={`ButtonGroupMenu__item ${
              allocationAction === 'unallocate'
                ? 'ButtonGroupMenu__item__selected'
                : ''
            }`}
          >
            Unallocate
          </button>
        </div>
      </div>
      <div className="AllocateStakeModal__form__select">
        <div className="AllocateStakeModal__form__select-label">Subject:</div>
        <div className="ButtonGroupMenu">
          <button
            onClick={() => setAllocationSubject('owner')}
            className={`ButtonGroupMenu__item ${
              allocationSubject === 'owner'
                ? 'ButtonGroupMenu__item__selected'
                : ''
            }`}
          >
            Owner
          </button>
          <button
            onClick={() => setAllocationSubject('delegators')}
            className={`ButtonGroupMenu__item ${
              allocationSubject === 'delegators'
                ? 'ButtonGroupMenu__item__selected'
                : ''
            }`}
          >
            Delegators
          </button>
        </div>
      </div>
      <div className="AllocateStakeModal__form__allocation-amount">
        <NumberInput
          name="input-new-commission"
          type="fort"
          value={amount}
          onChange={(event: { target: { value: string } }) =>
            setAmount(event.target.value)
          }
        />
        <div className="AllocateStakeModal__form__max">
          Max amount: {toFORTPrecision(formatEther(maxAmountForAllocation))}{' '}
          FORT
        </div>
      </div>
      <ContractCallButton
        size="lg"
        disabled={allocationDisabled}
        onCall={onContractCallClicked.bind(this, amount)}
      >
        {allocationAction === 'allocate' ? 'Allocate' : 'Unallocate'}
      </ContractCallButton>
      {!hasScanNodes && allocationAction === 'allocate' ? (
        <div className="AllocateStakeModal__form-error">
          You need to have at least one Scan node to allocate stake.
        </div>
      ) : null}
    </div>
  );
};

export const AllocateStakeModal = (
  props: AllocateStakeModalProps
): JSX.Element => {
  const { opened, onClose } = props;

  return (
    <Modal
      opened={opened}
      title="Allocate Scan node Pool Stake"
      onCloseModal={onClose}
    >
      <div className="AllocateStakeModal">
        <div className="AllocateStakeModal__description">
          Indicate the amount that you want to allocate to the Scan node Pool.
        </div>
        <div className="AllocateStakeModal__content">
          <AllocateStakeModalInfo {...props} />
          <AllocateStakeModalForm {...props} />
        </div>
      </div>
    </Modal>
  );
};

export const AllocateStakeButton = ({
  scannerPoolId,
  allocation,
  scanNodes,
  className
}: {
  scannerPoolId: string;
  allocation: Allocation;
  scanNodes: number;
  className?: string;
}): JSX.Element => {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <div className={className}>
      <Button onClick={() => setOpened(true)} variant="default" size="sm">
        Allocate stake
      </Button>
      <AllocateStakeModal
        opened={opened}
        scannerPoolId={scannerPoolId}
        allocation={allocation}
        scanNodes={scanNodes}
        onClose={() => setOpened(false)}
      />
    </div>
  );
};
