import './InformationBox.scss';

import { createSubComponent } from '../components-v2/utils';

export const Container = createSubComponent('Container', 'InformationBox');
export const Row = createSubComponent('Row', 'InformationBox__row');
export const Title = createSubComponent('Title', 'InformationBox__title');
export const Item = createSubComponent('Item', 'InformationBox__item');
export const Label = createSubComponent('Label', 'InformationBox__item-label');
export const Value = createSubComponent('Value', 'InformationBox__item-value');
export const Group = createSubComponent('Group', 'InformationBox__group');

export default {
  Container,
  Title,
  Item,
  Label,
  Value,
  Group
};
