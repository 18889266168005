import { Link } from 'react-router-dom';
import { getAddress } from '@ethersproject/address';
import { groupBy } from 'lodash';

import projects, { HighlightedProject } from 'forta-app/data/projects';
import './DiscoverPage.scss';

export default function DiscoverPage(): JSX.Element {
  const sortedProjects = projects.sort((a, b) => a.name.localeCompare(b.name));
  const groups = groupBy(
    sortedProjects,
    (project) => project.category || 'Others'
  );

  return (
    <div className="DiscoverPage">
      <div className="DiscoverPage__header">
        <div className="DiscoverPage__title">Top Protocols</div>
        <div className="DiscoverPage__description">
          Discover customized detection bots that cover operational and security
          monitoring for specific protocols. Subscribe and get real-time alerts
          on conditions and events on your favorite projects.
        </div>
      </div>
      <div className="DiscoverPage__content">
        <div className="DiscoverPage__list">
          {Object.keys(groups)
            .sort()
            .map((groupKey) => {
              return (
                <div
                  className="DiscoverPage__group"
                  key={`projects-group-${groupKey}`}
                >
                  <div className="DiscoverPage__group-name">{groupKey}</div>
                  <div className="DiscoverPage__group-list">
                    {groups[groupKey].map((project) => (
                      <Link
                        key={`project-${project.projectId}`}
                        to={`/agents/${project.projectId}`}
                      >
                        <ProjectBox project={project} />
                      </Link>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* <div className="DiscoverPage__content">
        <a href={config.connectApp}
          target="_blank" rel="noreferrer"
          className="DiscoverPage__content-see-all-button"
        >See all agents</a>
      </div> */}
    </div>
  );
}

function ProjectBox({ project }: { project: HighlightedProject }): JSX.Element {
  return (
    <div className="DiscoverPage__project" key={project.projectId}>
      <div className="DiscoverPage__project-logo">
        <img
          width={64}
          src={
            project.logo
              ? project.logo
              : `/token-images/${getAddress(project.token)}.png`
          }
          alt="logo"
        />
      </div>
      <div className="DiscoverPage__project-details">
        <div className="DiscoverPage__project-name">{project.name}</div>
      </div>
    </div>
  );
}
