import { gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';

export async function generateAPIKey(
  jwt: string
): Promise<{ keyId: string; secret: string; createdAt: string }> {
  const client = getExplorerAPIClient();
  const apiResult = await client.mutate({
    variables: {
      jwt
    },
    mutation: gql`
      mutation GenerateAPIKey($jwt: String) {
        generateAPIKey(input: { jwt: $jwt }) {
          keyId
          secret
          createdAt
        }
      }
    `
  });
  return apiResult.data.generateAPIKey;
}

export async function deleteAPIKey(params: {
  keyId: string;
  jwt: string;
}): Promise<boolean> {
  const client = getExplorerAPIClient();
  const apiResult = await client.mutate({
    variables: {
      deleteAPIKeyInput: params
    },
    mutation: gql`
      mutation DeleteAPIKey($deleteAPIKeyInput: DeleteAPIKeyInput) {
        deleteAPIKey(input: $deleteAPIKeyInput)
      }
    `
  });
  return apiResult.data.deleteAPIKey;
}
