import React, { useState } from 'react';
import Table from 'common/components-v2/Table/Table';
import TableBody from 'common/components-v2/Table/TableBody';
import TableCell from 'common/components-v2/Table/TableCell';
import TableHead from 'common/components-v2/Table/TableHead';
import TableHeading from 'common/components-v2/Table/TableHeading';
import TableRow from 'common/components-v2/Table/TableRow';
import config from 'common/config';
import { getNetworkByChainId } from 'common/lib/networks';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import {
  ProtocolTransaction,
  TransactionType
} from 'forta-app/lib/transactions-storage/TransactionsStorage';
import moment from 'moment';
import { StatusDot } from '../delegations/DelegationsTable';
// import DropDown from 'common/components/Dropdown';
import './TransactionTable.scss';
import { isBigNumberish } from '@ethersproject/bignumber/lib/bignumber';
import cn from 'classnames';
import useFetchUserTxs from 'common/hooks/useFetchUserTxs';
import { shortenHash } from 'common/lib/utils';
import Link from 'common/components-v2/Button/Link';
import Button from 'common/components-v2/Button/Button';
import { formatNumber } from '../../lib/utils';

//TODO Fetch ALL transactions via the Forta subgraph

export interface ReceiptTransaction extends ProtocolTransaction {
  lockAddress: string;
}

const network = getNetworkByChainId(config.chainId);

function isCredit(eventType: TransactionType): boolean {
  return (
    eventType === TransactionType.STAKE_WITHDRAW ||
    eventType === TransactionType.CLAIM_REWARD
  );
}

function formatToken(
  amount: string,
  token: string,
  txType: TransactionType
): string {
  if (!isBigNumberish(amount)) {
    return 'Invalid Value';
  }

  if (!isCredit(txType)) {
    if (token.toLowerCase() === 'fort') {
      return `-${formatNumber(formatEther(amount))} ${token || ''}`;
    } else if (token.toLowerCase() === 'usdc') {
      return `-${formatNumber(formatUnits(amount, 6))} ${token || ''}`;
    } else {
      return 'Unknown token';
    }
  } else {
    if (token.toLowerCase() === 'fort') {
      return `+${formatNumber(formatEther(amount))} ${token || ''}`;
    } else if (token.toLowerCase() === 'usdc') {
      return `+${formatNumber(formatUnits(amount, 6))} ${token || ''}`;
    } else {
      return 'Unknown token';
    }
  }
}

export default function TransactionTable({
  address
}: {
  address: string;
}): JSX.Element {
  const [pageSize, setPageSize] = useState(10);
  const { transactionEvents, loading, fetched } = useFetchUserTxs({ address });
  const moreToLoad = pageSize < transactionEvents.length;
  const displayEvents = transactionEvents.slice(0, pageSize);

  return (
    <div className="TransactionTable">
      <div className="TransactionTable__header">
        <div className="TransactionTable__title">Transaction History</div>
        {/* <div className="TransactionTable__filter">
          <DropDown
            name="transactionType"
            flavor="dark"
            options={[
              { label: 'All', value: '' },
              ...Object.entries(transactionTypeDetails).map(
                ([key, { label }]) => ({
                  label,
                  value: key
                })
              )
            ]}
            onChange={(option) =>
              onFilter((option?.value || null) as TransactionType | null)
            }
          />
        </div> */}
      </div>
      <Table minWidth={1000}>
        <TableHead>
          <TableHeading title="Transaction ID" flex={5} />
          <TableHeading title="Date" flex={3} />
          <TableHeading title="Type" flex={7} />
          <TableHeading title="Amount" flex={3} />
        </TableHead>
        <TableBody
          empty={fetched && transactionEvents.length === 0}
          loading={loading}
        >
          {displayEvents.map((event) => {
            const eventTypeColor: string = isCredit(event.transaction.type)
              ? 'lime'
              : 'yellow';

            return (
              <TableRow
                key={event.transaction.id}
                className="TransactionTable__row"
              >
                <TableCell flex={5}>
                  <div className="TransactionTable__cell-txid">
                    <Link
                      href={`${network.explorerUrl}/tx/${event.transaction.id}`}
                      target="_blank"
                    >
                      {shortenHash(event.transaction.id, 8)}
                    </Link>
                  </div>
                </TableCell>
                <TableCell flex={3}>
                  <div className="TransactionTable__cell-date">
                    {moment(event.timestamp).format('LL')}
                  </div>
                </TableCell>
                <TableCell flex={7}>
                  <div className="TransactionTable__cell-transactionType">
                    <StatusDot color={eventTypeColor} /> {event.label}
                  </div>
                </TableCell>
                <TableCell flex={3}>
                  <div
                    className={cn('TransactionTable__cell-amount', {
                      'TransactionTable__cell-amount--increment': isCredit(
                        event.transaction.type
                      )
                    })}
                  >
                    {event.transaction.token
                      ? formatToken(
                          event.amount,
                          event.transaction.token,
                          event.transaction.type
                        )
                      : 'Data Unavailable'}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {moreToLoad && (
            <TableRow clickable className="TransactionTable__load-more-row">
              <Button
                variant="default"
                size="md"
                loading={loading}
                disabled={loading}
                className="TransactionTable__load-more"
                onClick={() => setPageSize(pageSize + 10)}
              >
                See More Transactions
              </Button>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
