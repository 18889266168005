import React from 'react';
import './ProgressBar.scss';

interface ProgressBarProps {
  now: number;
  secondary?: boolean;
}

export const ProgressBar = ({
  now,
  secondary
}: ProgressBarProps): JSX.Element => {
  const classes = `ProgressBar ${secondary ? 'ProgressBar--secondary' : ''}`;
  return (
    <div className={classes}>
      <div className="ProgressBar__bar" style={{ width: `${now}%` }}></div>
    </div>
  );
};
