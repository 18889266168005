import { useEffect, useMemo, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { getAddress } from '@ethersproject/address';
import moment from 'moment';

import { BackIcon } from 'common/components/Icons';
import Loader from 'common/components/Loader';
import Counter from 'common/components/stats/Counter';
import NotFoundPage from 'common/pages/NotFoundPage';
import { Agent, getAgents } from 'common/lib/apis/agentAPI';
import { Routes } from 'common/routes';

import { MetricKey } from 'forta-app/data/metrics';
import highlightedProjects, {
  Developer,
  developers,
  HighlightedProjectAgent
} from 'forta-app/data/projects';
import useBotMetrics from 'forta-app/hooks/useBotMetrics';

import './ProjectPage.scss';

interface ProjectPageProps {
  match: {
    params: {
      projectId: string;
    };
  };
}

function AgentItem(props: HighlightedProjectAgent): JSX.Element {
  const developer: Developer = developers.find(
    (developer) => developer.address === props.developer
  ) || { name: props.developer, address: props.developer };

  const [chainId, setChainId] = useState<number>();
  const [createdAt, setCreatedAt] = useState<string>('');
  const [updatedAt, setUpdatedAt] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [scannerCount, setScannerCount] = useState<number>(0);
  const [loadingAgentData, setLoadingAgentData] = useState<boolean>(true);

  const { scanners: findingScanners, fetched: isFindingsFetched } =
    useBotMetrics({
      params: {
        agentId: props.id,
        chainId: chainId || 1,
        metricKey: MetricKey.Finding,
        timeFrame: 'day'
      },
      enabled: chainId != null
    });

  const { scanners: transactionScanners, fetched: isTransactionsFetched } =
    useBotMetrics({
      params: {
        agentId: props.id,
        chainId: chainId || 1,
        metricKey: MetricKey.TxSuccess,
        timeFrame: 'day'
      },
      enabled: chainId != null
    });

  useEffect(() => {
    getAgents({ ids: [props.id] })
      .then((agents: Agent[]) => {
        setCreatedAt(agents[0]?.created_at || '');
        setUpdatedAt(agents[0]?.updated_at || '');
        setDescription(agents[0]?.description || '');
        setScannerCount(agents[0]?.scanners?.length || 0);
        if (agents[0].chainIds && agents[0].chainIds.length > 0) {
          setChainId(Number(agents[0].chainIds[0]));
        }
        setLoadingAgentData(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingAgentData(false);
      });
    // eslint-disable-next-line
  }, []);

  const { findingCount, transactionCount } = useMemo(() => {
    const sum = (arr: { sum: number }[]): number =>
      arr.reduce((total, item) => total + Number(item.sum), 0);

    return {
      findingCount: sum(findingScanners?.[0]?.interval || []),
      transactionCount: sum(transactionScanners?.[0]?.interval || [])
    };
  }, [findingScanners, transactionScanners]);

  return (
    <div className="ProjectPage__agent">
      <div className="ProjectPage__agent-header">
        <div className="ProjectPage__agent-name">
          <NavLink to={`/bot/${props.id}`} target="_blank" rel="noreferrer">
            {props.name}
          </NavLink>
          <div className="ProjectPage__agent-timeframe">in last 24 hours</div>
        </div>
        <div className="ProjectPage__agent-developer">by {developer.name}</div>
        <div className="ProjectPage__agent-description">{description}</div>
      </div>
      <div className="ProjectPage__agent-content">
        {loadingAgentData ? (
          <Loader />
        ) : (
          <div className="ProjectPage__agent-date">
            {createdAt ? (
              <div>
                Created:{' '}
                <span className="ProjectPage__agent-date-value">
                  {moment(createdAt).format('MMMM Do YYYY')}
                </span>
              </div>
            ) : null}
            {updatedAt &&
            moment(createdAt).format('MMMM Do YYYY') !==
              moment(updatedAt).format('MMMM Do YYYY') ? (
              <div>
                Updated:{' '}
                <span className="ProjectPage__agent-date-value">
                  {moment(updatedAt).format('MMMM Do YYYY')}
                </span>
              </div>
            ) : null}
          </div>
        )}
        <div className="ProjectPage__agent-metrics">
          {!isFindingsFetched || !isTransactionsFetched ? (
            <Loader />
          ) : (
            <>
              <div className="ProjectPage__agent-metric-labels">
                <div className="ProjectPage__agent-metric">Findings</div>
                <div className="ProjectPage__agent-metric">Node operators</div>
                <div className="ProjectPage__agent-metric">
                  Inspected transactions
                </div>
              </div>
              <div className="ProjectPage__agent-metric-values">
                <div className="ProjectPage__agent-metric-value">
                  {findingCount}
                </div>
                <div className="ProjectPage__agent-metric-value">
                  {scannerCount}
                </div>
                <div className="ProjectPage__agent-metric-value">
                  <Counter decimals={0} value={transactionCount} duration={1} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default function ProjectPage({
  match: {
    params: { projectId }
  }
}: ProjectPageProps): JSX.Element {
  const project = highlightedProjects.find(
    (project) => project.projectId === projectId
  );

  if (!project) {
    return <NotFoundPage />;
  }

  return (
    <div className="ProjectPage">
      <div className="ProjectPage__go-back">
        <Link to="/discover" className="ProjectPage__go-back-button">
          {BackIcon}
          Back to All Projects
        </Link>
      </div>
      <div className="ProjectPage__header">
        <div className="ProjectPage__info">
          <div className="ProjectPage__logo">
            <img
              src={
                project.logo
                  ? project.logo
                  : `/token-images/${getAddress(project.token)}.png`
              }
              alt={`${project.name} logo`}
            />
          </div>
          <div className="ProjectPage__description">
            <div className="ProjectPage__name">{project.name}</div>
            <div className="ProjectPage__website">
              <a href={project.website} target="_blank" rel="noreferrer">
                {project.website}
              </a>
            </div>
          </div>
        </div>
        <div className="ProjectPage__stats">
          <div className="ProjectPage__agent-stats">
            <Counter
              decimals={0}
              value={project.agents.length}
              duration={0.8}
            />{' '}
            bots
          </div>
          {project.agents.length ? (
            <div className="ProjectPage__actions">
              <a
                className="ProjectPage__see-alerts-button"
                target="_blank"
                rel="noreferrer"
                href={Routes.alerts.index({
                  agents: project.agents.map((a) => a.id)
                })}
              >
                See all alerts
              </a>
            </div>
          ) : null}
        </div>
      </div>
      <div className="ProjectPage__content">
        <div className="ProjectPage__agents">
          <div className="ProjectPage__agents-title">
            Detection Bot Coverage
          </div>
          <div className="ProjectPage__agents-list">
            {project.agents.map((agent) => {
              return <AgentItem {...agent} key={`agent-${agent.id}`} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
