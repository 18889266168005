import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAppDispatch, useJWT } from 'forta-app/app/hooks';
import {
  getJWTAddress,
  getJWTmsToExpiration,
  isJWTExpired,
  logout
} from 'forta-app/slices/walletSlice';
import { Web3ContextType, useWeb3React } from '@web3-react/core';
import config from 'common/config';
import { Link } from 'react-router-dom';
import { ArrowDownIcon } from 'common/components/Icons';
import { shortenHash } from 'common/lib/utils';
import { getNetworkByChainId } from 'common/lib/networks';
import MyAPIKeysPage from 'forta-app/pages/MyAPIKeysPage';
import {
  ConnectionType,
  getConnection,
  switchNetwork,
  tryDeactivateConnector
} from './connections';
import { getNonce } from 'forta-app/lib/apis/loginAPI';
import { SiweMessage } from 'siwe';
import cookie from 'common/lib/cookies';
import './WalletConnector.scss';
import { toast } from 'react-toastify';
import {
  CONNECTION_TYPE,
  FILTERS_LS_KEY,
  clearLS
} from 'common/lib/localStorage';
import ls from 'common/lib/localStorage';
import { useConnectModal } from './useConnectModal';

interface WalletConnectorParams {
  expired?: boolean;
  className?: string;
}

const WalletConnector = ({ className }: WalletConnectorParams): JSX.Element => {
  const web3React = useWeb3React();
  const jwt = useJWT();
  const jwtAddress = getJWTAddress(jwt);
  const expired = isJWTExpired(jwt) || jwtAddress !== web3React.account;
  const dispatch = useAppDispatch();
  const { open: openConnectModal, close: closeConnectModal } =
    useConnectModal();
  const [menuExpanded, setMenuExpanded] = useState<boolean>(false);
  const [apiKeysOpened, setApiKeysOpened] = useState<boolean>(false);

  const handleConnectClick = (): void => {
    openConnectModal();
  };

  const disconnectWallet = useCallback(async (): Promise<void> => {
    await tryDeactivateConnector(web3React.connector);
    dispatch(logout());
    clearLS([FILTERS_LS_KEY]);
  }, [dispatch, web3React.connector]);

  const classes = {
    WalletConnector: true,
    'WalletConnector--connected': !expired && web3React.isActive,
    'WalletConnector--menu-expanded': menuExpanded
  };

  useEffect(() => {
    if (web3React.account && !expired) {
      closeConnectModal();
      setTimeout(() => {
        const currentJWT = cookie.getJWT() || '';
        if (currentJWT === jwt) {
          toast.warn('Your session has expired. Please reconnect your wallet.');
          disconnectWallet();
        }
      }, getJWTmsToExpiration(jwt));
    }
  }, [
    expired,
    web3React.account,
    jwt,
    dispatch,
    disconnectWallet,
    closeConnectModal
  ]);

  useEffect(() => {
    const previousConnectionType = ls.getItem(CONNECTION_TYPE);
    if (!web3React.isActive && previousConnectionType) {
      try {
        getConnection(
          previousConnectionType as ConnectionType
        ).connector.connectEagerly?.();
      } catch (error) {
        console.debug(
          `Failed to connect eagerly to ${previousConnectionType}`,
          error
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classNames(classes, className)}>
        {(!web3React.isActive || expired) && (
          <div className="WalletConnector__button-container">
            <button
              className="WalletConnector__button"
              onClick={handleConnectClick}
            >
              Connect your wallet
            </button>
          </div>
        )}
        {web3React.account && !expired ? (
          web3React.chainId === config.chainId ? (
            <div
              role="button"
              tabIndex={0}
              onClick={() => setMenuExpanded(!menuExpanded)}
              onKeyDown={() => setMenuExpanded(!menuExpanded)}
              onBlur={() => setMenuExpanded(false)}
              className={classNames('WalletConnector__hash-container', {
                ['WalletConnector__hash-container--expanded']: menuExpanded
              })}
            >
              <div className="WalletConnector__hash">
                {shortenHash(web3React.account)}
              </div>
              <div className="WalletConnector__menu-container">
                <div className="WalletConnector__menu-icon">
                  {ArrowDownIcon}
                </div>
                <div className="WalletConnector__menu">
                  <Link
                    to={`/profile/${web3React.account}`}
                    onMouseDown={(e) => e.preventDefault()}
                    className="WalletConnector__menu-item"
                  >
                    <p>My Profile</p>
                  </Link>
                  <Link
                    to={`/profile/${web3React.account}/bots`}
                    onMouseDown={(e) => e.preventDefault()}
                    className="WalletConnector__menu-item"
                  >
                    <p>My Detection Bots</p>
                  </Link>
                  <Link
                    to={`/profile/${web3React.account}/pools`}
                    onMouseDown={(e) => e.preventDefault()}
                    className="WalletConnector__menu-item"
                  >
                    <p>My Node Pools</p>
                  </Link>
                  <Link
                    className="WalletConnector__menu-item"
                    to={`/profile/${web3React.account}/staking`}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <p>My Stakes</p>
                  </Link>
                  <Link
                    to={`/profile/${web3React.account}/delegations`}
                    onMouseDown={(e) => e.preventDefault()}
                    className="WalletConnector__menu-item"
                  >
                    <p>My Delegations</p>
                  </Link>
                  <Link
                    to={`/profile/${web3React.account}/rewards`}
                    onMouseDown={(e) => e.preventDefault()}
                    className="WalletConnector__menu-item"
                  >
                    <p>My Rewards</p>
                  </Link>
                  <Link
                    className="WalletConnector__menu-item"
                    to={`/profile/${web3React.account}/subscriptions`}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <p>My Subscriptions</p>
                  </Link>
                  <Link
                    className="WalletConnector__menu-item"
                    to={`/profile/${web3React.account}/account`}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <p>My Billing</p>
                  </Link>
                  <div
                    className="WalletConnector__menu-item"
                    onClick={() => setApiKeysOpened(true)}
                    onMouseDown={(e) => e.preventDefault()}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && setApiKeysOpened(true)
                    }
                    tabIndex={0}
                    role="button"
                  >
                    <p>My API Keys</p>
                  </div>
                  <div
                    className="WalletConnector__menu-item"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={disconnectWallet}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && disconnectWallet()
                    }
                    tabIndex={0}
                    role="button"
                  >
                    <p style={{ color: '#c94b4b' }}>Disconnect</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="WalletConnector__wrong-network">
              <div className="WalletConnector__wrong-network-label">
                Wrong Network
              </div>
              <button
                className="WalletConnector__wrong-network-button"
                onClick={() =>
                  switchNetwork(
                    config.chainId,
                    ls.getItem(CONNECTION_TYPE) as ConnectionType
                  )
                }
              >
                Switch to {getNetworkByChainId(config.chainId).label}
              </button>
            </div>
          )
        ) : null}
      </div>
      <MyAPIKeysPage
        opened={apiKeysOpened}
        onCloseModal={() => setApiKeysOpened(false)}
      />
    </>
  );
};

export interface SignMessageData {
  signature: string;
  timestamp: string;
  message: string;
  address: string;
}

export const signMessage = async (
  web3React: Web3ContextType
): Promise<SignMessageData | false> => {
  const signer = web3React.provider?.getSigner();
  if (signer) {
    const statement =
      'I accept the Forta Terms of Use: https://forta.org/legal/';
    const siweMessage = new SiweMessage({
      domain: window.location.host,
      address: web3React.account || undefined,
      statement,
      uri: window.location.origin,
      version: '1',
      chainId: config.chainId,
      nonce: await getNonce()
    });
    const timestamp = new Date();
    let message = siweMessage.prepareMessage();
    const signedMessage = await signer.signMessage(message);

    if (window.location.search.includes('MOCK_METAMASK=1')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      message = window.web3MockMessage;
    }

    return {
      signature: signedMessage,
      timestamp: timestamp.toString(),
      message,
      address: web3React.account || ''
    };
  } else {
    return false;
  }
};

export default WalletConnector;
