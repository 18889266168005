import React from 'react';

import './BotHealth.scss';

import BotMetrics from 'forta-app/components/bot/BotMetrics';
import Button from 'common/components-v2/Button/Button';
import { ExternalLinkIcon } from 'common/components/Icons';
import useBotMetricChains from 'common/hooks/useBotMetricChains';
import { currentENV } from 'common/config';

type BotHealthProps = {
  botId: string;
};

function BotHealth(props: BotHealthProps): JSX.Element {
  const { botId } = props;

  const { chainIds, loading } = useBotMetricChains({
    params: {
      timeFrame: 'month',
      botId: botId
    }
  });

  return (
    <div className="BotHealth">
      <BotMetrics
        botId={botId}
        chainIds={chainIds}
        loading={loading}
        className="BotHealth__metrics-chart"
      />
      <div className="BotHealth__logs">
        <Button
          href={
            currentENV === 'production'
              ? `https://alerts.forta.network/logs/agents/${botId}`
              : `https://alerts-dev.forta.network/logs/agents/${botId}`
          }
          target="_blank"
          size="lg"
          variant="default"
          endIcon={ExternalLinkIcon}
          className="BotHealth__log-button"
        >
          See bot logs
        </Button>
      </div>
    </div>
  );
}

export default BotHealth;
