import { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { getProjects } from 'forta-app/lib/apis/projectsAPI';
import './ProjectSelector.scss';
import { useApolloClient } from '@apollo/client';

interface ProjectOption {
  value: string;
  label: string;
}

export default function ProjectSelector({
  onChange
}: {
  onChange: (projects: string[]) => void;
}): JSX.Element {
  const client = useApolloClient();

  const loadOptions = useCallback(
    (inputValue: string, callback: (list: ProjectOption[]) => void): void => {
      getProjects(inputValue, client).then((result) => {
        callback(
          result.projects.map((project) => ({
            value: project.projectId,
            label: project.name
          }))
        );
      });
    },
    [client]
  );

  return (
    <AsyncSelect
      className="ProjectSelector"
      classNamePrefix="ProjectSelector"
      cacheOptions
      defaultOptions
      isMulti
      loadOptions={loadOptions}
      onChange={(options) => onChange(options.map((options) => options.value))}
    />
  );
}
