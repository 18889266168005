import React from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';

import './CopyButton.scss';
import InfoPopover from './InfoPopover';

import { CopyIcon } from 'common/components/Icons';

export default function CopyButton({
  text,
  children,
  ellipsis,
  className
}: {
  text: string;
  ellipsis?: boolean;
  children?: React.ReactNode;
  className?: string;
}): JSX.Element {
  const handleClick: () => void = async () => {
    await navigator.clipboard.writeText(text);
    toast(`Copied to clipboard!`);
  };

  const handleKeyDown = (event: { code: string }): void => {
    if (event.code === 'Enter') {
      handleClick();
    }
  };

  return (
    <InfoPopover
      content="Copy to clipboard"
      className={cn('CopyPopover', className, {
        ['CopyPopover--ellipsis']: ellipsis
      })}
      arrow
    >
      <div
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        className={cn('CopyPopover__container')}
      >
        {children ? children : <div className="copy-icon">{CopyIcon}</div>}
      </div>
    </InfoPopover>
  );
}
