import React from 'react';
import cn from 'classnames';

import './Table.scss';

export interface TableSorting {
  orderBy: string;
  orderDirection: 'asc' | 'desc';
}

interface TableProps {
  id?: string;
  variant?: 'default' | 'transparent';
  size?: 'md' | 'sm';
  minWidth?: number;
  children: React.ReactNode;
  sorting?: TableSorting;
  onSortingChange?: (sorting: TableSorting) => unknown;
  className?: string;
}

type TableContext = {
  sorting?: TableSorting;
  onSortingChange?: (sorting: TableSorting) => unknown;
};

// TODO Need to refactor Table component
// We need to develop a more convenient way to specify column sizes, which could be dynamically changed depending on the screen size or some states.
// An example of a challenging problem that needs to be refactored is displaying the action column depending on whether or not the user owns scan nodes.
// Conditionally disabling the display of one of the columns leads to many changes and conditions in the code, often duplicated.

export const TableContext = React.createContext<TableContext>({});

const Table = (props: TableProps): JSX.Element => {
  const {
    id,
    sorting,
    children,
    minWidth,
    size = 'md',
    variant = 'default',
    className,
    onSortingChange
  } = props;

  const context: TableContext = {
    sorting,
    onSortingChange
  };

  return (
    <TableContext.Provider value={context}>
      <div
        id={id}
        className={cn(
          'Table',
          `Table--${variant}`,
          `Table--${size}`,
          className
        )}
      >
        <div className="Table__container" style={{ minWidth }}>
          {children}
        </div>
      </div>
    </TableContext.Provider>
  );
};

export default Table;
