import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import React, { ReactNode } from 'react';
import { Connector } from '@web3-react/types';

import { PRIORITIZED_CONNECTORS } from './connections';

const connectors: [Connector, Web3ReactHooks][] = Object.values(
  PRIORITIZED_CONNECTORS
).map((connector) => [connector.connector, connector.hooks]);

export const Web3ContextProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>
  );
};
