import { gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';

export interface Reward {
  epoch: number;
  rewards: string;
}

export interface GetRewardsInput {
  subjectType: number;
  address: string;
  poolId: number;
  fromEpoch: number;
  toEpoch: number;
}

export const UNCLAIMED_REWARDS_QUERY = gql`
  query Retrive($getRewardsInput: GetRewardsInput) {
    getRewards(input: $getRewardsInput) {
      epoch
      rewards
    }
  }
`;

export async function getRewards(query: GetRewardsInput): Promise<Reward[]> {
  try {
    const client = getExplorerAPIClient();
    const apiResult = await client.query({
      variables: {
        getRewardsInput: query
      },
      query: UNCLAIMED_REWARDS_QUERY
    });

    const rewards: Reward[] =
      apiResult?.data?.getRewards.filter(
        (r: Reward) => parseInt(r.rewards) > 0
      ) || [];
    return rewards;
  } catch (err) {
    return [];
  }
}
