import React, { useState } from 'react';
import cn from 'classnames';

import './Container.scss';
import Metadata from './Metadata';
import Switch from '../Form/Switch/Switch';
import Fade from '../Transitions/Fade';
import Shimmer from '../Shimmer/Shimmer';

type MetadataContainerProps = {
  value: Record<string, unknown>;
  titleElement?: JSX.Element;
  maxHeight?: number;
  loading?: boolean;
  className?: string;
};

function MetadataContainer(props: MetadataContainerProps): JSX.Element {
  const { value, maxHeight, titleElement, loading, className } = props;

  const [isAllExpanded, setIsAllExpanded] = useState(false);

  return (
    <div className={cn('MetadataContainer', className)}>
      <div className="MetadataContainer__header">
        {titleElement ? (
          titleElement
        ) : (
          <div className="MetadataContainer__title">Metadata</div>
        )}
        <div>
          <Switch
            name="metadata-expand-switch"
            checked={isAllExpanded}
            label="Expand all"
            labelPosition="start"
            onChange={() => setIsAllExpanded((v) => !v)}
          />
        </div>
      </div>
      <div className="MetadataContainer__metadata" style={{ maxHeight }}>
        <div className="MetadataContainer__metadata">
          <Fade visible={loading}>
            {(ref) => (
              <div ref={ref}>
                <Shimmer />
              </div>
            )}
          </Fade>
          <Fade visible={!loading}>
            {(ref) => (
              <Metadata
                ref={ref}
                collapsed={isAllExpanded ? false : 2}
                value={value}
              />
            )}
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default MetadataContainer;
