import React, { useMemo } from 'react';
import queryString from 'query-string';

import './EndpointSelector.scss';
import './NotificationModal.scss';

import InfoPopover from 'common/components/InfoPopover';
import { NotificationEndpointType } from 'forta-app/lib/apis/notificationAPI';
import Input from 'common/components-v2/Form/Input/Input';
import Tabs, { Tab } from 'common/components-v2/Tabs/Tabs';

export const TELEGRAM_API_BASE = 'https://api.telegram.org';

export default function EndpointSelector({
  value,
  valueType,
  title,
  onChange,
  onTypeChange,
  error
}: {
  value: string;
  valueType: NotificationEndpointType;
  title?: string;
  onChange: (value: string) => void;
  onTypeChange: (value: NotificationEndpointType) => void;
  error?: string;
}): JSX.Element {
  const handleTypeChange = (type: NotificationEndpointType): void => {
    onTypeChange(type);
    onChange('');
  };

  const inputPlaceholder = useMemo(() => {
    switch (valueType) {
      case 'SLACK':
        return 'https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX';
      case 'EMAIL':
        return 'Enter Email Address...';
      case 'DISCORD':
        return 'https://discord.com/api/webhooks/XXXX/XXXX';
      case 'WEBHOOK':
        return 'Enter Custom Webhook...';
      default:
        return '';
    }
  }, [valueType]);

  const InputWrapper = valueType === 'TELEGRAM' ? TelegramInput : Input;

  return (
    <div className="EndpointSelector">
      <div className={`EndpointSelector__title`}>
        <div className="NotificationModal__form-source-label">
          {title || 'Notify me via'}
        </div>
        <InfoPopover content={'Endpoint where the notification will be sent'} />
      </div>
      <div className="EndpointSelector__content">
        <Tabs<NotificationEndpointType>
          value={valueType}
          onChange={(tab) => handleTypeChange(tab)}
          className="BotData__tabs"
        >
          <Tab
            label="Email"
            value={'EMAIL'}
            icon={<img src="/email.png" alt="email" />}
          />
          <Tab
            label="Slack"
            value={'SLACK'}
            icon={<img src="/slack.png" alt="slack" />}
          />
          <Tab
            label="Discord"
            value={'DISCORD'}
            icon={<img src="/discord.png" alt="discord" />}
          />
          <Tab
            label="Telegram"
            value={'TELEGRAM'}
            icon={<img src="/telegram.png" alt="telegram" />}
          />
          <Tab
            label="Webhook"
            value={'WEBHOOK'}
            icon={<img src="/webhook.png" alt="webhook" />}
          />
        </Tabs>
        <div className="EndpointSelector__input-text">
          <InputWrapper
            value={value}
            invalid={!!error}
            placeholder={inputPlaceholder}
            name={`forta-endpoint-selector-value-${valueType}`}
            subtext={error || undefined}
            variant="dark"
            onChange={(event) => {
              if (typeof event === 'string') {
                onChange(event);
              } else {
                onChange(event.target.value);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

interface TelegramInputProps {
  name: string;
  placeholder: string;
  value?: string;
  errored?: boolean;
  subtext?: string;
  onKeyDown?: (value: string, e: KeyboardEvent) => void;
  onChange?: (value: string) => void;
}

export const getTelegramParamValues = (value?: string): [string, string] => {
  const searchString = (value || '').replace(TELEGRAM_API_BASE, '');
  const query = queryString.parse(searchString, { arrayFormat: 'none' });
  const token =
    (Array.isArray(query.token) ? query.token[0] : query.token) || '';
  const chatId =
    (Array.isArray(query.chat_id) ? query.chat_id[0] : query.chat_id) || '';
  return [token, chatId];
};

function TelegramInput(props: TelegramInputProps): JSX.Element {
  const [token, chatId] = getTelegramParamValues(props.value);

  const handleChange = (endpoint: string): void => {
    if (props.onChange) {
      props.onChange(endpoint);
    }
  };

  return (
    <div className="TelegramInput">
      <Input
        variant="dark"
        name={`${props.name}-telegram-token`}
        placeholder="Enter Token..."
        value={token}
        invalid={props.errored && !token}
        onChange={(event) =>
          handleChange(
            `${TELEGRAM_API_BASE}?token=${event.target.value}&chat_id=${chatId}`
          )
        }
      />
      <Input
        variant="dark"
        name={`${props.name}-telegram-chatid`}
        placeholder="Enter Chat ID..."
        value={chatId}
        invalid={props.errored && !chatId}
        onChange={(event) =>
          handleChange(
            `${TELEGRAM_API_BASE}?token=${token}&chat_id=${event.target.value}`
          )
        }
      />
    </div>
  );
}
