import cn from 'classnames';

import './Spinner.scss';

type SpinnerProps = {
  variant: 'dual-ring';
  size: 'lg' | 'md' | 'sm' | 'xs';
  color: 'white' | 'gray' | 'inherit';
  className?: string;
};

function Spinner({
  variant,
  size,
  color = 'inherit',
  className
}: SpinnerProps): JSX.Element {
  return (
    <div
      className={cn(
        'Spinner',
        'Spinner--' + variant,
        'Spinner--' + size,
        'Spinner--' + color,
        className
      )}
    />
  );
}

export default Spinner;
