import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { gql } from '@apollo/client';

import StakingVault from 'forta-app/lib/contract-interactors-2/staking-vault';
import useProvider from './useProvider';
import config from '../config';
import useGraphQuery from './useGraphQuery';

export type UseVaultResult = {
  apy: number | null;
  totalAssets: BigNumber | null;
  userAssets: BigNumber | null;
  userPendingAssets: BigNumber | null;
  userClaimableAssets: BigNumber | null;
  isAssetsLoading: boolean;
  isApyLoading: boolean;
  fetchAssets: () => Promise<void>;
  vaultContract: StakingVault;
};

const VAULT_APY_QUERY = gql`
  query SystemInfoQuery {
    getSystemInfo {
      vaultApy
    }
  }
`;

function useVault(props: { account: string }): UseVaultResult {
  const { account = '' } = props;

  const provider = useProvider(config.chainId);
  const [isAssetsLoading, setIsAssetsLoading] = useState(true);
  const [totalAssets, setTotalAssets] = useState<BigNumber | null>(null);
  const [userAssets, setUserAssets] = useState<BigNumber | null>(null);
  const [userPendingAssets, setUserPendingAssets] = useState<BigNumber | null>(
    null
  );
  const [userClaimableAssets, setUserClaimableAssets] =
    useState<BigNumber | null>(null);

  const { data: systemData, loading: isApyLoading } = useGraphQuery<
    Record<string, unknown>,
    { getSystemInfo: { vaultApy: number } }
  >({
    query: VAULT_APY_QUERY,
    variables: {}
  });

  const apy = useMemo(() => {
    const value = systemData?.getSystemInfo.vaultApy;
    if (value == null) return null;
    return value;
  }, [systemData]);

  const contract = useMemo(
    () =>
      new StakingVault({
        provider: provider
      }),
    [provider]
  );

  async function fetchAssets(): Promise<void> {
    try {
      setIsAssetsLoading(true);

      const totalAssets = await contract.totalAssets();
      setTotalAssets(totalAssets);

      if (account) {
        const userShares = await contract.balanceOf(account);
        const userAssets = await contract.previewRedeem(userShares);
        setUserAssets(userAssets);
        const userPendingAssets = await contract.getExpectedAssets(account);
        setUserPendingAssets(userPendingAssets);
        const userClaimableAssets = await contract.claimableAssets(account);
        setUserClaimableAssets(userClaimableAssets);
      } else {
        setUserAssets(new BigNumber(0));
        setUserPendingAssets(new BigNumber(0));
      }
    } catch (e: unknown) {
      console.error('fetchAssets()', e);
    } finally {
      setIsAssetsLoading(false);
    }
  }

  useEffect(() => {
    fetchAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return {
    isAssetsLoading: isAssetsLoading,
    isApyLoading: isApyLoading,
    totalAssets,
    userAssets,
    userPendingAssets,
    userClaimableAssets,
    apy,

    vaultContract: contract,

    fetchAssets
  };
}

export default useVault;
