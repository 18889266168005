/* eslint-disable @typescript-eslint/ban-ts-comment */

// This is a slightly changed hook of the existing library:
// https://github.com/imbhargav5/rooks/blob/48c8a15fe1f13847166f8f6ba1b6b3a31031ef3e/packages/rooks/src/hooks/useWindowSize.ts#L1

// Unfortunately, the library used "document.clientWidth", which does not include the size of the scrollbar.
// In the code below, the hook was made to use  "window.innerWidth".

import { useDebounce } from '@react-hook/debounce';
import useEvent from '@react-hook/event';

const emptyObj = {};

export interface DebouncedWindowSizeOptions {
  initialWidth?: number;
  initialHeight?: number;
  wait?: number;
  leading?: boolean;
}

const win = typeof window === 'undefined' ? null : window;
const wv =
  win && typeof win.visualViewport !== 'undefined' ? win.visualViewport : null;
const getSize = () => [window.innerWidth, window.innerHeight] as const;

export const useWindowSize = (
  options: DebouncedWindowSizeOptions = emptyObj
): readonly [number, number] => {
  const { wait, leading, initialWidth = 0, initialHeight = 0 } = options;
  const [size, setDebouncedSize] = useDebounce<readonly [number, number]>(
    /* istanbul ignore next */
    typeof window === 'undefined' ? [initialWidth, initialHeight] : getSize,
    wait,
    leading
  );
  const setSize = (): void => setDebouncedSize(getSize);

  useEvent(win, 'resize', setSize);
  // @ts-ignore
  useEvent(wv, 'resize', setSize);
  useEvent(win, 'orientationchange', setSize);

  return size;
};

export const useWindowHeight = (
  options?: Omit<DebouncedWindowSizeOptions, 'initialWidth'>
): number => useWindowSize(options)[1];

export const useWindowWidth = (
  options?: Omit<DebouncedWindowSizeOptions, 'initialHeight'>
): number => useWindowSize(options)[0];
