import { useMemo, useRef } from 'react';
import useScannerPoolsApyQuery from './useScannerPoolsApyQuery';
import useScannerPoolsQuery, { ScannerPool } from './useScannerPoolsQuery';

function useScannerPoolsWithApyQuery(opts: {
  enabled?: boolean;
  enablePolling?: boolean;
  params: {
    id_in?: string[];
    chainId?: number;
    first?: number;
    skip?: number;
    owner?: string;
    orderBy?: string | 'apyForLastEpoch';
    orderDirection?: 'asc' | 'desc';
  };
}): {
  scannerPools: ScannerPool[];
  loading: boolean;
  polling: boolean;
  refetching: boolean;
  fetched: boolean;
  hasNextPage: boolean;
  refetch: () => Promise<void>;
} {
  const { enabled = true, enablePolling = false, params } = opts;
  const isOrderByApi = params.orderBy === 'apyForLastEpoch';
  const first = params.first ?? 10;

  const stateRef = useRef<{ previousData: null | ScannerPool[] }>({
    previousData: null
  });

  const scannerApyQuery1 = useScannerPoolsApyQuery({
    params: {
      owner: params.owner,
      chainId: params.chainId,
      nodePoolIds: params.id_in,
      first: first,
      skip: params.skip,
      orderDirection: params.orderDirection,
      orderBy: isOrderByApi ? 'apy' : undefined
    },
    enablePolling: enablePolling,
    enabled: enabled && isOrderByApi
  });
  const scannerPoolQuery1 = useScannerPoolsQuery({
    params: {
      id_in: scannerApyQuery1.scannerPoolApyList.map((v) => v.id),
      first: scannerApyQuery1.scannerPoolApyList.length
    },
    enablePolling: enablePolling,
    enabled: enabled && isOrderByApi && scannerApyQuery1.fetched
  });

  const scannerPoolQuery2 = useScannerPoolsQuery({
    params: {
      owner: params.owner,
      id_in: params.id_in,
      chainId: params.chainId,
      first: first,
      skip: params.skip,
      orderDirection: params.orderDirection,
      orderBy: params.orderBy
    },
    enablePolling: enablePolling,
    enabled: enabled && !isOrderByApi
  });
  const scannerApyQuery2 = useScannerPoolsApyQuery({
    params: {
      nodePoolIds: scannerPoolQuery2.scannerPools.map((v) => v.id),
      first: scannerPoolQuery2.scannerPools.length
    },
    enablePolling: enablePolling,
    enabled: enabled && !isOrderByApi && scannerPoolQuery2.fetched
  });

  const [scannerPoolQuery, scannerApyQuery] = isOrderByApi
    ? [scannerPoolQuery1, scannerApyQuery1]
    : [scannerPoolQuery2, scannerApyQuery2];

  const isIdle = [scannerPoolQuery, scannerApyQuery]
    .map((v) => v.fetched && !v.loading && !v.refetching)
    .reduce((prev, curr) => prev && curr);

  const scannerPools = useMemo(() => {
    if (!isIdle) return stateRef.current.previousData;

    const pools: ScannerPool[] = [];
    if (isOrderByApi) {
      for (const pool of scannerApyQuery.scannerPoolApyList) {
        const scannerPool = scannerPoolQuery.scannerPools.find(
          (p) => p.id === pool.id
        );

        if (!scannerPool) {
          continue;
        }

        pools.push({
          ...scannerPool,
          apyForLastEpoch: pool.apy
        });
      }
    } else {
      for (const pool of scannerPoolQuery.scannerPools) {
        const poolApy = scannerApyQuery.scannerPoolApyList.find(
          (p) => p.id === pool.id
        );

        if (!poolApy) {
          continue;
        }

        pools.push({
          ...pool,
          apyForLastEpoch: Number(poolApy.apy)
        });
      }
    }

    return pools;
  }, [
    isIdle,
    isOrderByApi,
    scannerApyQuery.scannerPoolApyList,
    scannerPoolQuery.scannerPools
  ]);

  stateRef.current.previousData = scannerPools;

  async function refetch(): Promise<void> {
    await Promise.all([scannerPoolQuery.refetch(), scannerApyQuery.refetch()]);
  }

  return {
    fetched: scannerPoolQuery.fetched && scannerApyQuery.fetched,
    loading: scannerPoolQuery.loading || scannerApyQuery.loading,
    polling: scannerPoolQuery.polling || scannerApyQuery.polling,
    refetching: scannerPoolQuery.refetching || scannerPoolQuery.refetching,
    hasNextPage: isOrderByApi
      ? scannerApyQuery.hasNextPage
      : scannerPoolQuery.hasNextPage,
    scannerPools: scannerPools || [],
    refetch: refetch
  };
}

export default useScannerPoolsWithApyQuery;
