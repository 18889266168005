import cn from 'classnames';
import React, { ReactNode } from 'react';

export type BaseComponentProps = {
  className?: string;
  children?: ReactNode;
};

export const createSubComponent: (
  displayName: string,
  baseClassName: string,
  ElementType?: keyof JSX.IntrinsicElements
) => ({ children, className }: BaseComponentProps) => JSX.Element = (
  displayName: string,
  baseClassName: string,
  ElementType: keyof JSX.IntrinsicElements = 'div'
) => {
  const fn = ({ children, className }: BaseComponentProps): JSX.Element => (
    <ElementType className={cn(baseClassName, className)}>
      {children}
    </ElementType>
  );
  fn.displayName = displayName;
  return fn;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function keyDownA11y(handler: (event: any) => unknown) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  return (event: any) => {
    if (
      ['keydown', 'keypress'].includes(event?.type) &&
      ['Enter', ' '].includes(event?.key)
    ) {
      handler(event);
    }
  };
}
