import { gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';

interface getMultiSlaApiResult {
  getMultiSLAs: ScannerMultiSlaResult[];
}

export interface GetSlaInput {
  scanner_ids?: string[];
  hours?: number;
}

export interface ScannerMultiSlaResult {
  id: string;
  hourBucketsFound: number;
  slaAvgOverTime: {
    uptime: {
      avgUptime: number;
      numberOfHours: number;
    };
    sla: {
      avgSLA: number;
      numberOfHours: number;
    };
  };
}

export async function getMultiSLAs(
  input: GetSlaInput
): Promise<ScannerMultiSlaResult[]> {
  const client = getExplorerAPIClient();

  const apiResult = await client.query<getMultiSlaApiResult>({
    variables: {
      getMultiSLAsInput: input
    },
    query: gql`
      query Retrive($getMultiSLAsInput: GetSlaInput) {
        getMultiSLAs(input: $getMultiSLAsInput) {
          id
          hourBucketsFound
          slaAvgOverTime {
            uptime {
              avgUptime
              numberOfHours
            }
            sla {
              avgSLA
              numberOfHours
            }
          }
        }
      }
    `
  });

  const slaResults: ScannerMultiSlaResult[] = apiResult.data.getMultiSLAs;

  return slaResults;
}
