import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import './DataPlanList.scss';
import DataPlanCard from './DataPlanCard';
import SuccessDataPlanModal from './SuccessDataPlanModal';

import Button from 'common/components-v2/Button/Button';
import useWallet from 'common/hooks/useWallet';
import { useConnectModal } from '../wallet/useConnectModal';
import { getPlanEntities, getUnlockConfig, paywall } from '../../data/plans';
import useUnlockMembershipsQuery from 'common/hooks/useUnlockMembershipsQuery';
import { DataPlan } from '../../data/plans/types';

export interface DataPlanListProps {
  cardClassName?: string;
  className?: string;
}

export default function DataPlanList(props: DataPlanListProps): JSX.Element {
  const { cardClassName, className } = props;

  const {
    ScamDetectorPlan,
    SpamDetectorPlan,
    TokenSnifferPlan,
    SybilDefenderPlan,
    GeneralPlan
  } = useMemo(() => getPlanEntities(), []);

  const { signed, web3React, ensureConnection } = useWallet();
  const [openedSuccessModal, setOpenedSuccessModal] = useState<boolean>(false);
  const { open: openConnectModal, close: closeConnectModal } =
    useConnectModal();

  const unlockMembershipsQuery = useUnlockMembershipsQuery({
    params: { owner: web3React.account || '' }
  });

  const alreadyPurchasedPlanAddresses = useMemo(
    () =>
      unlockMembershipsQuery.activeMembershipAddresses.map((a) =>
        a.toLowerCase()
      ),
    [unlockMembershipsQuery.activeMembershipAddresses]
  );

  const checkIfPurchased = (plan: DataPlan): boolean => {
    return (
      signed &&
      (alreadyPurchasedPlanAddresses.includes(
        plan.unlockAddresses.FORT.toLowerCase()
      ) ||
        alreadyPurchasedPlanAddresses.includes(
          plan.unlockAddresses.USDC.toLowerCase()
        ))
    );
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = function (e: any): void {
      // Transaction Hash of the unlock plan purchase
      const transactionHash = e.detail?.hash;
      if (transactionHash) {
        setOpenedSuccessModal(true);
      }
    };

    window.addEventListener('unlockProtocol.transactionSent', listener);
    return () =>
      window.removeEventListener('unlockProtocol.transactionSent', listener);
  }, []);

  useEffect(() => {
    if (signed) {
      closeConnectModal();
    }
  }, [closeConnectModal, signed]);

  const handleSuccessModalClose = (): void => {
    setOpenedSuccessModal(false);
  };

  const handleBuyGeneralPlan = async (): Promise<void> => {
    if (signed) {
      await ensureConnection();
      const unlockConfig = getUnlockConfig([
        {
          ...GeneralPlan,
          unlockAddresses: {
            USDC: GeneralPlan.unlockAddresses['USDC'],
            FORT: GeneralPlan.unlockAddresses['FORT'],
            trial: GeneralPlan.unlockAddresses['trial']
          }
        }
      ]);

      paywall.connect(web3React.provider?.provider);
      paywall.loadCheckoutModal(unlockConfig);
    } else {
      openConnectModal();
    }
  };

  return (
    <div className={cn('DataPlanList', className)}>
      <div className={cn('DataPlanList__list')}>
        {[
          ScamDetectorPlan,
          SpamDetectorPlan,
          TokenSnifferPlan,
          SybilDefenderPlan
        ].map((plan, i) => {
          return (
            <div
              data-testid={`data-plan-list-card-${i + 1}`}
              className={cn(
                `DataPlanList__data-plan ${
                  plan.author === 'you' ? 'transparent' : ''
                }`,
                cardClassName
              )}
              key={plan.name}
            >
              <DataPlanCard
                author={plan.author || ''}
                icon={plan.icon}
                title={plan.name}
                description={plan.description}
                customPlan={plan.customPlan}
                link={plan.link}
                priceUSDC={plan.price['USDC']}
                priceFORT={plan.price['FORT']}
                unlockAddress={
                  plan.unlockAddresses['USDC'] || plan.unlockAddresses['FORT']
                }
                attributes={plan.attributes}
              />
            </div>
          );
        })}
        <div
          data-testid={`data-plan-list-card-5`}
          className={cn(`DataPlanList__data-plan`, cardClassName)}
        >
          <DataPlanCard
            author={GeneralPlan.author || ''}
            icon={GeneralPlan.icon}
            title={GeneralPlan.name}
            description={GeneralPlan.description}
            customPlan={GeneralPlan.customPlan}
            linkLabel="Explore bots"
            priceUSDC={GeneralPlan.price['USDC']}
            priceFORT={GeneralPlan.price['FORT']}
            unlockAddress={
              GeneralPlan.unlockAddresses['USDC'] ||
              GeneralPlan.unlockAddresses['FORT']
            }
            attributes={GeneralPlan.attributes}
            renderActions={() => {
              const isPurchased = checkIfPurchased(GeneralPlan);
              return (
                <>
                  <Button
                    size="sm"
                    variant="light-outline"
                    className="DataPlanList__buy-button"
                    onClick={handleBuyGeneralPlan}
                    disabled={isPurchased}
                  >
                    {isPurchased ? 'Already purchased' : 'Buy plan'}
                  </Button>
                </>
              );
            }}
            className={cn('DataPlanList__general-plan', cardClassName)}
          />
        </div>
      </div>
      <SuccessDataPlanModal
        planName={GeneralPlan.name}
        opened={openedSuccessModal}
        onClose={handleSuccessModalClose}
      />
    </div>
  );
}
