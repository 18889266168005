import cn from 'classnames';
import './PlanLabel.scss';
import { SubscriptionType } from 'common/enums';

interface PlanLabelProps {
  planType: string | undefined;
  className?: string;
}

const PlanLabel = ({ planType, className }: PlanLabelProps): JSX.Element => {
  switch (planType) {
    case SubscriptionType.General:
      return (
        <span className={cn('PlanLabel--general', className)}>
          General Data
        </span>
      );
    case SubscriptionType.ScamFeed:
      return (
        <span className={cn('PlanLabel--scam', className)}>Scam Detector</span>
      );
    case SubscriptionType.SpamFeed:
      return (
        <span className={cn('PlanLabel--spam', className)}>Spam Detector</span>
      );
    case SubscriptionType.RugPull:
      return (
        <span className={cn('PlanLabel--rugpull', className)}>
          Rug Pull Detector
        </span>
      );
    case SubscriptionType.AttackFeed:
      return (
        <span className={cn('PlanLabel--attack', className)}>Attack Feed</span>
      );
    case SubscriptionType.SybilDefender:
      return (
        <span className={cn('PlanLabel--sybil-defender', className)}>
          Sybil Defender
        </span>
      );
    default:
      return (
        <span className={cn('PlanLabel--general', className)}>
          General Data
        </span>
      );
  }
};

export default PlanLabel;
