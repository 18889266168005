import classNames from 'classnames';
import { KeyboardEvent } from 'react';
import './Input.scss';

interface InputProps {
  placeholder: string;
  name: string;
  icon?: JSX.Element;
  testId?: string;
  subtext?: string;
  disabled?: boolean;
  errored?: boolean;
  pattern?: string;
  textarea?: boolean;
  rows?: number;
  value?: string;
  className?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onKeyDown?: (value: string, e: KeyboardEvent) => void;
  onIconClick?: () => void;
}

export default function Input(props: InputProps): JSX.Element {
  const {
    className,
    testId,
    placeholder,
    icon,
    name,
    subtext,
    errored,
    pattern,
    textarea,
    value,
    rows,
    disabled,
    onChange,
    onKeyDown,
    onBlur,
    onIconClick
  } = props;

  const classes = classNames({
    Input: true,
    'Input--error': errored,
    [className || '']: className || false
  });

  const Component = textarea ? 'textarea' : 'input';

  return (
    <div className={classes}>
      <Component
        data-testid={testId}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value)}
        onKeyDown={(e) => onKeyDown && onKeyDown(e.key, e)}
        onBlur={onBlur}
        rows={rows}
        type="text"
        autoComplete="off"
        pattern={pattern}
        name={name}
        className={'Input__input'}
      />
      {icon && (
        <button className="Input__button" onClick={onIconClick}>
          {icon}
        </button>
      )}
      {subtext && <div className="Input__subtext">{subtext}</div>}
    </div>
  );
}
