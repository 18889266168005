import DropDown from 'common/components/Dropdown';
import ChainIcon from 'common/components/stats/ChainIcon';
import { Agent, getAgents } from 'common/lib/apis/agentAPI';
import { getNetworkByChainId } from 'common/lib/networks';
import { is0xHash, isAddress, isAgentId } from 'common/lib/utils';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import { getScanner } from 'forta-app/lib/apis/networkAPI';
import { useCallback, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import './StakingSubjectSelector.scss';

interface StakingSubjectSelectorTypeOption {
  value: string;
  label: string;
  subjectType: SubjectType;
}

interface StakingSubjectSelectorOption {
  value: string;
  label: JSX.Element | string;
}
const options: StakingSubjectSelectorTypeOption[] = [
  { value: 'scanner', label: 'Scan node', subjectType: SubjectType.SCANNER },
  { value: 'bot', label: 'Bot', subjectType: SubjectType.BOT }
];

export default function StakingSubjectSelector({
  onSubjectSelected
}: {
  onSubjectSelected: (subjectId: string, subjectType: SubjectType) => void;
}): JSX.Element {
  const [selectedType, setSelectedType] =
    useState<StakingSubjectSelectorTypeOption>(options[0]);
  const [selectedOption, setSelectedOption] = useState<OptionTypeBase | null>(
    null
  );
  const disabledStakeButton =
    selectedType.subjectType === SubjectType.BOT
      ? !is0xHash(selectedOption?.value || '')
      : !isAddress(selectedOption?.value || '');

  const loadOptions = useCallback(
    (
      inputValue: string,
      callback: (list: StakingSubjectSelectorOption[]) => void
    ): void => {
      if (selectedType.subjectType === SubjectType.BOT) {
        if (!isAgentId(inputValue)) {
          getAgents({ text: inputValue })
            .then((_bots: Agent[]) => {
              callback(
                _bots.map((_bot) => {
                  return {
                    value: _bot.id,
                    label: (
                      <div style={{ color: 'white' }}>
                        <div>{_bot.name}</div>
                        <div>{_bot.id}</div>
                      </div>
                    )
                  };
                })
              );
            })
            .catch(() => {
              toast.warn('Error trying to retrieve bots');
              callback([]);
            });
        } else {
          getAgents({ ids: [inputValue] })
            .then((_bots: Agent[]) => {
              callback(
                _bots.map((_bot) => {
                  return {
                    value: _bot.id,
                    label: (
                      <div style={{ color: 'white' }}>
                        <div>{_bot.name}</div>
                        <div>{_bot.id}</div>
                      </div>
                    )
                  };
                })
              );
            })
            .catch(() => {
              callback([]);
            });
        }
      } else if (isAddress(inputValue)) {
        getScanner(inputValue)
          .then((scanner) => {
            const network =
              getNetworkByChainId(scanner?.scanner?.chain_id || 1) || null;
            callback([
              {
                value: scanner.scanner.id,
                label: (
                  <span style={{ color: 'white' }}>
                    {network ? (
                      <span>
                        <ChainIcon network={network} />
                      </span>
                    ) : null}
                    <span>{scanner.scanner.id}</span>
                  </span>
                )
              }
            ]);
          })
          .catch(() => {
            callback([]);
          });
      } else {
        callback([]);
      }
    },
    [selectedType.subjectType]
  );

  return (
    <div className="StakingSubjectSelector">
      <div className="StakingSubjectSelector__form">
        <div className="StakingSubjectSelector__type">
          <DropDown
            name="StakingSubjectSelector__type"
            flavor="dark"
            options={options}
            value={selectedType}
            onChange={(option) => {
              if (option?.value === 'scanner') {
                setSelectedType(options[0]);
                setSelectedOption(null);
              }
              if (option?.value === 'bot') {
                setSelectedType(options[1]);
                setSelectedOption(null);
              }
            }}
          />
        </div>
        <div className="StakingSubjectSelector__value">
          <AsyncSelect
            className="ProjectSelector"
            classNamePrefix="ProjectSelector"
            value={selectedOption}
            loadOptions={loadOptions}
            placeholder={
              selectedType.subjectType === SubjectType.SCANNER
                ? 'Select scan node address...'
                : 'Select Bot...'
            }
            onChange={(option) => setSelectedOption(option)}
          />
        </div>
      </div>
      <div className="StakingSubjectSelector__action">
        <button
          className="button-cta-2"
          disabled={disabledStakeButton}
          onClick={() =>
            onSubjectSelected(
              selectedOption?.value || '',
              selectedType.subjectType
            )
          }
        >
          Stake
        </button>
        {disabledStakeButton ? (
          <p>Please select a valid {selectedType.label}.</p>
        ) : null}
      </div>
    </div>
  );
}
