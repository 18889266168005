import CreatableSelect from 'react-select/creatable';
import { useState } from 'react';

export interface Option {
  label: string;
  value: string;
  valid?: boolean;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface FilterParams {
  onChange: (selection: string[]) => void;
  selectionToOption: (value: string) => Option;
  selection: string[];
  styles?: any;
  placeholder?: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function CreatableSelectFilter({
  onChange,
  selection,
  selectionToOption,
  styles,
  placeholder
}: FilterParams): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('');

  const addOption = (option: string): void => {
    if (option && option !== ' ') {
      const newSelection = selection.includes(option)
        ? selection
        : [...selection, option];
      onChange(newSelection);
      setInputValue('');
    }
  };

  return (
    <CreatableSelect
      className="react-select-container"
      classNamePrefix="react-select"
      isMulti
      onCreateOption={(option) => addOption(option)}
      onKeyDown={(event) =>
        event.key == 'Enter' || event.key == ' ' ? addOption(inputValue) : null
      }
      onChange={(_value) => onChange(_value.map((option) => option.value))}
      onInputChange={(_inputValue) => setInputValue(_inputValue)}
      onBlur={() => addOption(inputValue)}
      inputValue={inputValue}
      value={selection.map(selectionToOption)}
      formatCreateLabel={(value) => `Add ${value}`}
      styles={styles}
      placeholder={placeholder}
      menuIsOpen={false}
      components={{
        DropdownIndicator: null
      }}
    />
  );
}
