import { TransactionResponse } from '@ethersproject/providers';

import config from 'common/config';
import agentRegistryABI from 'forta-app/data/abis/agentRegistryABI.json';
import { ContractContext as AgentRegistryContract } from 'forta-app/data/abis/agent-registry-contract';
import ContractInteractor from './contractInteractor';
import addProtocolTransaction from '../transactions-storage/addProtocolTransaction';
import { Web3ContextType } from '@web3-react/core';

export default class AgentRegistry extends ContractInteractor<AgentRegistryContract> {
  constructor(web3React: Web3ContextType) {
    super(web3React, agentRegistryABI, config.agentRegistryContractAddress);
  }

  async createAgent(
    agentId: string,
    metadata: string,
    _chainIds: number[]
  ): Promise<TransactionResponse> {
    const chainIds = _chainIds.sort((a, b) => a - b);
    const address = this.web3React?.account;
    if (!address) throw 'Address does not exist.';
    const gas = await this.contract.estimateGas.createAgent(
      agentId,
      address,
      metadata,
      chainIds
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.createAgent(
      agentId,
      address,
      metadata,
      chainIds,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }

  async agentExists(agentId: string): Promise<boolean> {
    const agent = await this.contract.getAgent(agentId);
    return !!agent.metadata;
  }

  async updateAgent(
    agentId: string,
    reference: string,
    _chainIds: number[]
  ): Promise<TransactionResponse> {
    const chainIds = _chainIds.sort((a, b) => a - b);
    const gas = await this.contract.estimateGas.updateAgent(
      agentId,
      reference,
      chainIds
    );
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.updateAgent(
      agentId,
      reference,
      chainIds,
      txOptions
    );
    addProtocolTransaction(tx);
    return tx;
  }

  async isEnabled(agentId: string): Promise<boolean> {
    return this.contract.isEnabled(agentId);
  }

  async disableAgent(agentId: string): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.disableAgent(agentId, 1);
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.disableAgent(agentId, 1, txOptions);
    addProtocolTransaction(tx);
    return tx;
  }

  async enableAgent(agentId: string): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.enableAgent(agentId, 1);
    const txOptions = await this.getTxOptions(gas);
    const tx = await this.contract.enableAgent(agentId, 1, txOptions);
    addProtocolTransaction(tx);
    return tx;
  }
}
