import React from 'react';
import cn from 'classnames';

import { FormElementProps } from '../../Form/form';

import './Tabs.scss';

export type TabItem<T> = {
  label: string;
  value: T;
};

type ChartTabsProps<T> = FormElementProps<T> & {
  items: TabItem<T>[];
  separator?: boolean;
  className?: string;
};

function ChartTabs<T>({
  name,
  items,
  value,
  separator = false,
  onChange,
  className
}: ChartTabsProps<T>): JSX.Element {
  function handleChange(item: TabItem<T>): void {
    onChange({
      target: {
        name,
        value: item.value
      }
    });
  }

  return (
    <div role="tablist" className={cn('ChartTabs', className)}>
      {items.map((item, i) => {
        const isActive = item.value === value;
        return (
          <React.Fragment key={item.label}>
            {separator && i > 0 && (
              <span aria-hidden="true" className="ChartTabs__separator" />
            )}
            <button
              type="button"
              role="tab"
              aria-selected={isActive}
              data-label={item.label}
              tabIndex={isActive ? 0 : -1}
              className={cn('ChartTabs__tab', {
                ['ChartTabs__tab--active']: isActive
              })}
              onClick={() => handleChange(item)}
            >
              {item.label}
            </button>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default ChartTabs;
