import { useCallback, useEffect, useState } from 'react';
import { providers } from 'ethers';

import './StakingModal.scss';
import { DelegatedFormProps, StakingForm } from './StakingForm';
import StakingSubjectSelector from './StakingSubjectSelector';

import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import Loader from 'common/components/Loader';
import Modal from 'common/components/Modal';
import { getSubjectStake, SubjectResult } from 'forta-app/lib/apis/subgraphAPI';

interface StakeModalProps {
  opened: boolean;
  onClose: (tx?: providers.TransactionResponse) => void;
  subjectId?: string;
  subjectType?: SubjectType;
  delegatedForm?: DelegatedFormProps;
}

export default function StakeModal({
  opened,
  onClose,
  subjectId,
  subjectType,
  delegatedForm
}: StakeModalProps): JSX.Element {
  const [subject, setSubject] = useState<SubjectResult | null>(null);
  const [subjectLoading, setSubjectLoading] = useState<boolean>(false);

  const updateSubject = useCallback(async function (
    subjectId: string,
    subjectType: SubjectType
  ) {
    setSubjectLoading(true);
    const _stake = await getSubjectStake(subjectId, subjectType);
    setSubjectLoading(false);
    if (_stake.id) {
      setSubject(_stake);
    } else {
      setSubject({
        id: subjectId,
        subjectType,
        activeShares: '0',
        inactiveShares: '0',
        activeStake: '0',
        inactiveStake: '0',
        slashedTotal: '0',
        isFrozen: false
      });
    }
  },
  []);

  useEffect(() => {
    if (subjectId) {
      updateSubject(subjectId, subjectType || 0);
    } else {
      setSubject(null);
      setSubjectLoading(false);
    }
  }, [subjectId, subjectType, updateSubject]);

  return (
    <Modal
      opened={opened}
      title={
        subjectType === SubjectType.SCANNERPOOL_DELEGATOR
          ? 'Delegate Staking'
          : 'Add Staking'
      }
      onCloseModal={() => {
        onClose();
      }}
      className="StakeModal__container"
    >
      <div className="StakeModal">
        {subjectLoading ? (
          <div className="StakeModal__loading">
            <Loader />
          </div>
        ) : null}
        {subject ? (
          <StakingForm
            subject={subject}
            delegatedStakingProps={delegatedForm}
            onSuccess={(tx?: providers.TransactionResponse) => {
              updateSubject(subject.id, subject.subjectType);
              onClose(tx);
            }}
          />
        ) : (
          <StakingSubjectSelector onSubjectSelected={updateSubject} />
        )}
      </div>
    </Modal>
  );
}
