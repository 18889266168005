export interface HighlightedProjectAgent {
  id: string;
  name: string;
  developer: string;
}

export interface HighlightedProject {
  name: string;
  projectId: string;
  token: string;
  logo?: string;
  website: string;
  agents: HighlightedProjectAgent[];
  category?: string;
}

export interface Developer {
  name: string;
  address: string;
}

export const developers: Developer[] = [
  {
    name: 'Nethermind',
    address: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
  },
  {
    name: 'Nethermind',
    address: '0xd3Abd0d36D6f55aD01BCd54D3f0c054F2B23de08'
  },
  {
    name: 'Nethermind',
    address: '0xaaa8334B378A8B6D8D37cFfEF6A755394B4C89DF'
  },
  {
    name: 'Solidus Labs',
    address: '0xA7CB7DCb70A256f02df24bCe0ddcddd735249cF7'
  },
  {
    name: 'Lido',
    address: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
  },
  {
    name: 'Instadapp',
    address: '0x137D5E3D0e6fDD02d5944468C09d9A6FE2D9ef1d'
  },
  {
    name: 'Limechain',
    address: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
  },
  {
    name: 'Arbitrary Execution',
    address: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
  },
  {
    name: 'Artem Kovalchuk',
    address: '0x183d13c4fCb5133EA52345A9037C9c25A5Aa139D'
  },
  {
    name: 'VVlovsky',
    address: '0x70358461af09dCb10B003e8e0a6033de34387A2a'
  },
  {
    name: 'Cipio',
    address: '0x000000000088E0120F9E6652CC058AeC07564F69'
  },
  {
    name: 'Alpaca Finance',
    address: '0x2DD872C6f7275DAD633d7Deb1083EDA561E9B96b'
  },
  {
    name: 'Forta Foundation',
    // Christian
    address: '0xad7547d09015664943d0A7c1BeA47f28dF298EC0'
  },
  {
    name: 'frwd labs',
    address: '0x3b8315f6144bd2b0a2657908cb41945233058d05'
  }
];

const highlightedProjects: HighlightedProject[] = [
  {
    name: 'Curve Finance',
    projectId: 'curve',
    token: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    website: 'https://www.curve.fi/',
    category: 'DEXes',
    agents: [
      {
        name: 'Transfer Ownership Bot for Registry Contract',
        id: '0x8722da3936bef2aefad087287e7bea422723cf9a932f1cd0268cc85a6b0ee07e',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Create Lock',
        id: '0x72ddc53c0b2c8f726be826fd786934b2f0f221dc5b029a78f2f49df680bdf540',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'DAO Kill Gauge',
        id: '0x551f408e53cebce30c329fa1e21a2d94260b3ec3b6df7cf47ddf556d72f846a4',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'DAO Set Gauge Rewards',
        id: '0x78d0b979b533a86eea166d881feaf252be20eb1c770478aad3b9282136dbe319',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: '3Pool Kill_Me',
        id: '0xfacd67ae9ced2397d01cd0d4b39d8f294806ce8f97124736ccce7b696d48b078',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Pool Ramps',
        id: '0x2391e9d9d2205fedb2058714c1ee94feb9238c70611e5303bc5abd81a916d984',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'ClaimMany',
        id: '0x54cf6dbd2c2178bddb1c84bcc4582cab7b16179eea1a51fb191e4f9694b73a3d',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: '3Pool Remove Imbalance Liquidity',
        id: '0x8735035a18f33143baa04a76e63fd33af4167a72b0ce4bda60341f977aaece64',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'UnkillMe',
        id: '0xa4606939d44cf0992b72cad8ac541685eaab9211937406a3d0d5e39714d855f2',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Cross Assets Swap',
        id: '0x99c0ca9634283dec965bfd51c15e6659e1f73bdd17c3417a98fa6add34bb1f21',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Deploy Metapool',
        id: '0x0ea3e3b0a0b58a42d948bc4501c1783cedfc7698d2f638705caf37bed505e780',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Pool Migration',
        id: '0x93704daf6c821d690601e44b1124c17a75e43a7ff904063320f50fd92f7e5c61',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'New Fee Bot',
        id: '0x1d5aa6e0085208814ac479fb389fe1295a35ef698c2543eaf93ca27e2e5a84a1',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Curve Registry Events',
        id: '0x15306a2f9ebc53ba1862336745e0c5d3e9e0087f6af385c39f248c17049cf0b2',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      }
    ]
  },
  {
    name: 'Lido',
    projectId: 'lido',
    token: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    website: 'https://lido.fi/',
    category: 'Assets',
    agents: [
      {
        name: 'Lido Detection Bot for Apps on Arbitrum',
        id: '0xdcaefe86732393e980bcfb930911fcc18f3e193456bf1d6263b834bff6077dfb',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Voting Watcher Bot',
        id: '0x6d34d7de8bc1b29606d2ed1d0a24e6690d0d22a1c31a1071da8cf8e79677010a',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Storage Watcher Bot',
        id: '0x9e6821ecae17159229d471bac73b47dc4ee14e262d9971c20563315ec6969dcd',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Detection Bot for L2 Bridges Balances',
        id: '0x83c46fb964876b21f410e18cb87a9bcddf26efbdc6a67351a462eb161ece5c68',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Detection Bot for Ethereum Side of L2 bridges',
        id: '0xb423c1f347541a7ce374d106adff0fb9f103fee9b2456095c6d1c73d928835bd',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Detection Bot for Arbitrum Side of L2 bridge',
        id: '0x684821e67baa3471cec2cfd1b8251c377fb9af77ca99c16a21fa5cb557967e14',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Detection Bot for Apps on Polygon',
        id: '0xf17137065f8c9d02900768153d56abddfe4746d9f469198a3b87ae357a217f34',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Financial Detection Bot for Forta',
        id: '0xcdcced8ef7fb6df819adbf78d8271da29d8a848f16036b4ac3642c5cb5996e16',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Huge TX Monitor',
        id: '0xfa7f60b5b1f9bb5758268506a978bacc17c42af6b7d0beb61ac64c681d9c9126',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido on Polygon Detection Bot for Forta',
        id: '0x5b25acc178d90f63554fb8541a7d1c188444ba64a6b6d3c79fcb10b11e3ebfd3',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Detection Bot for Phishing Detection',
        id: '0x219fcef9d4e4c040b1dd2feb61d5513e6957420b5f25d690e39a78004330af86',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Detection Bot for Rewards Programs',
        id: '0x59413fe6f6be7eb100e40f607fb448ef2a5bb838a5d19e6246fe70e3269ffd56',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      },
      {
        name: 'Lido Detection Bot for Forta',
        id: '0xddb7c17e370ecd5f99cadcddb39cfa51264e989c5133c490046d63a299dd68f0',
        developer: '0x6aea36C4a9Cf8AC053c07E662fa27e8BDF047121'
      }
    ]
  },
  {
    name: 'Instadapp',
    projectId: 'instadapp',
    token: '0x6f40d4A6237C257fff2dB00FA0510DeEECd303eb',
    website: 'https://instadapp.io/',
    category: 'Assets',
    agents: [
      {
        name: 'Large INST transfer Tracking Bot',
        id: '0xcb6d2f0a4961602835f5a592a93358be5f12dfef8b7936ca4659a4abe12cc1f1',
        developer: '0x137D5E3D0e6fDD02d5944468C09d9A6FE2D9ef1d'
      },
      {
        name: 'Governance Contract Event Bot',
        id: '0x41f6414749abd5df13b2b954796b2ac14964cd8e10fc4812e5f457f6506b017f',
        developer: '0x137D5E3D0e6fDD02d5944468C09d9A6FE2D9ef1d'
      },
      {
        name: 'Implementation Contract Event Bot',
        id: '0x36c962b41bdc05788994a24a485e1438ea1a106822fc7fb785c5079128daf3a8',
        developer: '0x137D5E3D0e6fDD02d5944468C09d9A6FE2D9ef1d'
      },
      {
        name: 'Token transfer tracking Bot',
        id: '0xf556db3c4536374af917f3af0eb9a2bd567faf6cde5a3661b6f8e7acabee7292',
        developer: '0x137D5E3D0e6fDD02d5944468C09d9A6FE2D9ef1d'
      }
    ]
  },
  {
    name: 'Balancer',
    projectId: 'balancer',
    token: '0xba100000625a3754423978a60c9317c58a424e3d',
    website: 'https://balancer.fi/',
    category: 'DEXes',
    agents: [
      {
        name: 'Pool Swap Fee Percentage Changed',
        id: '0x8fe07f1a4d33b30be2387293f052c273660c829e9a6965cf7e8d485bcb871083',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'LBP Swap Enabled Bot',
        id: '0x324e694b557ed964895179ef10d7ec3b730ca6b7c1f360bbf1017bf9be544bac',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Pool TVL Drastic Change',
        id: '0x9065acd6410d224d625e90e5b6a93d79e0cde3bcfde7a36fa116fc4418639506',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Tokens Registered Bot',
        id: '0x100c6c6447aeec71018d1a2d1e825449ed66815a6ff47ded2e6e44300aaf15a3',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Oracle Disparity Bot',
        id: '0xdbe39fd96d639c7ff8e067946c267958bdf66e6f5d9ae982c1c80c4d512da849',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Pool Role Granted/Revoked',
        id: '0x910c5c2320704dc113fbca65e5162907328e0a5ef4449681ea3058e8837de342',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Large BAT Transfer',
        id: '0xd19f14cd82be697606dab29263ac16613255c0093936960f9f098b9bb73f3083',
        developer: '0x59c44Ac5A7BBb7b50C04a648F7a8F4D1771705bA'
      },
      {
        name: 'Large Flash Loan',
        id: '0xf2de9ee6b6b9491fc2e5ff278221d53059f03ce17a4c3fc886652acbe6d21329',
        developer: '0x59c44Ac5A7BBb7b50C04a648F7a8F4D1771705bA'
      },
      {
        name: 'Large Delegation',
        id: '0xdf7610eecdadd16220ed82a3061629729cf84b8f966944879e2403cf8bad135c',
        developer: '0x59c44Ac5A7BBb7b50C04a648F7a8F4D1771705bA'
      },
      {
        name: 'Large Internal Balance Change',
        id: '0x096876c2b8933973226fbea07837c64867c3fe3a9afb915c28df4351a888f0bd',
        developer: '0x59c44Ac5A7BBb7b50C04a648F7a8F4D1771705bA'
      },
      {
        name: 'Large Pool Balance Change',
        id: '0x7f40c3de92e06d42a292fa45de5625b11e577591226ab0736eca38a73318423e',
        developer: '0x59c44Ac5A7BBb7b50C04a648F7a8F4D1771705bA'
      },
      {
        name: 'Large Swap',
        id: '0x50bd7f09b304f6c192270db9d18710fde46823925788b5578b0f2520ae65d68c',
        developer: '0x59c44Ac5A7BBb7b50C04a648F7a8F4D1771705bA'
      },
      {
        name: 'Pool Registered',
        id: '0x4ef9c20d783bff29e68f3410350b5b1850991c2350de41e1afd214f2a6eb6d32',
        developer: '0x59c44Ac5A7BBb7b50C04a648F7a8F4D1771705bA'
      },
      {
        name: 'Stable Pool Depegging',
        id: '0x233b60bef2437b4982c342cb92563f4863d5e109496331d23ed6315b1d3fa9cb',
        developer: '0x59c44Ac5A7BBb7b50C04a648F7a8F4D1771705bA'
      },
      {
        name: 'Swap Flashloan Fee Change',
        id: '0x838fdaeddabe8471d28fd139371bd2744ba019db3c07bc04b6095530fd75efeb',
        developer: '0x59c44Ac5A7BBb7b50C04a648F7a8F4D1771705bA'
      }
    ]
  },
  {
    name: 'Poly Network',
    projectId: 'polynetwork',
    token: '',
    logo: '/polynetwork.png',
    website: 'https://poly.network/',
    agents: [
      {
        name: 'Poly Asset Unlock Bot',
        id: '0x33e506374d1f512f72b1b158b5e8c994daa2b88ab133293b00c4c1e58cef03d3',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Poly Cross-Chain Event',
        id: '0xc2a8766421d33eeff47cff3143e998c6c2b14815f40061083b7e4dbd88c86c10',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Poly Total Locked Asset Amount Bot',
        id: '0xe97dd4bb604c044fe0eed9dc3a3450da7907aae63f20378ca5cf1a96750bed76',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      }
    ]
  },
  {
    name: 'AAVE',
    projectId: 'aave',
    token: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    website: 'https://aave.com/',
    category: 'Lending',
    agents: [
      {
        name: 'AE Aave Address Watch',
        id: '0x9c6bf105abfabd108d9774b099e63833af5e426ec4ef96859f31f0839ff80188',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE Aave Admin Events',
        id: '0x4f8aa7e320e339c94c7d9ec3b92843150d45b605fda3fe54c8216bf5425565de',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE Aave Anomalous Value',
        id: '0x143a008710810c6953fb455d9148ac4ee068a784fbc12d754481799e3c65a42f',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE Aave Stale Price Oracle',
        id: '0x28dbedae50c1372a5ed058ea0ec9766c5144c926ce6e92e0b200806bd1f42741',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE Aave Fallback Oracle Disparity',
        id: '0xddff56d9eb6765f79a684bb2049f1b86b9eab2957a4b1c2be6d3038f9d0c32d1',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE Aave New Contract Interaction',
        id: '0x6be4eeaab177ebf2634aa3766ca4b8a58a298c72725ef0c48e3ce9a5961bd3b9',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE Aave Treasury Fees Monitor',
        id: '0x5c7e0f553ca7cdb9da595315a513dba1a24ce84b489b529f2b4d6068f9745004',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE Aave Loan Events Monitor',
        id: '0x07ed69d9280bcf5664009d8b3b22a06c22f4934dfe7b95ecb9c6d2addf912074',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE Aave Governance Monitor',
        id: '0xa0e72a48366b274bae78ddd4db67fe43a78b6fcbdfebd5f20aab55555e7bf35b',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      }
    ]
  },
  {
    name: 'UMA',
    projectId: 'uma',
    token: '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
    website: 'https://umaproject.org/',
    agents: [
      {
        name: 'AE UMA Admin Events',
        id: '0x612eca115f64bd27a821ad57f8a7960f750f528392534a9221530fc4656cb4f2',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE UMA Deployer Watch',
        id: '0x9c7b384549d90588fb45e31d0a51b0f3375f266ca760e8222a037ae22e019a9c',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE UMA Optimistic Oracle',
        id: '0x8dce5f48baa5afa518c9e4c12b1e915bd57f1f4ed2a6b54fc3cd29bfd4bd871b',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE UMA Mint Call Monitor',
        id: '0xe345be65978f3512d90d560d3395aab783dcbdf2b9d071edfca8b70f7ad73fe5',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE UMA Disputor',
        id: '0xfc9f7f394940621b853b887a188a54803afaca812a88c623d6df2cffbe60f24c',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'AE UMA Liquidator',
        id: '0x848e51c42a4760ed67418365c04aaffff83e9469a796b641183112010c5488f7',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      }
    ]
  },
  {
    name: 'Uniswap',
    projectId: 'uniswap',
    token: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    website: 'https://uniswap.org/',
    category: 'DEXes',
    agents: [
      {
        name: 'Uniswap V3 Large Flash Swap Bot',
        id: '0x4338f9b8eeb6fb92eeb260bf949646876a9d665a7d631fc45715707143cdcc7b',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Large Flash Swap Bot',
        id: '0x9c09bae0d8f0cb9402cec97fe472ff090f34563841ceb71ae28447e66893bb98',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Admin Events Bot',
        id: '0x6568d1a7c9caca006cfdc3b0ea4c3821210cc7793c36ad177d165258e47f6943',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Address Watch Bot',
        id: '0x05d44bd29ae3ac5094dd45430c68500cb0ee638d8696432ba1ccb01680dc6a38',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Influencing Governance Proposals Bot',
        id: '0xbc0c316435cc8ada0b44a94f3d0cb75518d2270396eb5617d3784bb77c0c8e22',
        developer: '0x70358461af09dCb10B003e8e0a6033de34387A2a'
      }
    ]
  },
  {
    name: 'Vesper',
    projectId: 'vesper',
    token: '0x1b40183EFB4Dd766f11bDa7A7c3AD8982e998421',
    website: 'https://vesper.finance/',
    category: 'Assets',
    agents: [
      {
        name: 'Loss Reporter Bot - Ethereum',
        id: '0xbcbe45b998540b51dced80ee0da104b785ca75234c9b1bac4daab87819c63a64',
        developer: '0xd3Abd0d36D6f55aD01BCd54D3f0c054F2B23de08'
      },
      {
        name: 'Loss Reporter Bot - Avalanche',
        id: '0x8696edf726f5a74cec215f8ae21a29b7dc897f7403906fbab1a87ee4c03ebb13',
        developer: '0xd3Abd0d36D6f55aD01BCd54D3f0c054F2B23de08'
      },
      {
        name: 'Unhealthy Borrow Reporter - Ethereum',
        id: '0xec42861f777513e2d641e656705d264cac320eee10bfac64a70009d1da57cc31',
        developer: '0xd3Abd0d36D6f55aD01BCd54D3f0c054F2B23de08'
      },
      {
        name: 'Unhealthy Borrow Reporter - Avalanche',
        id: '0xccab4617826d739ca8c800a61cb73ef1d82034086dce255970f238f9bde2ff03',
        developer: '0xd3Abd0d36D6f55aD01BCd54D3f0c054F2B23de08'
      },
      {
        name: 'Fund Bot',
        id: '0x9af353a67b3ba55007d585c3271c3ba095d0a0680b0d9a11a58352680480d189',
        developer: '0xd3Abd0d36D6f55aD01BCd54D3f0c054F2B23de08'
      }
    ]
  },
  {
    name: 'Yearn',
    projectId: 'yearn',
    token: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
    website: 'https://yearn.finance/',
    category: 'Assets',
    agents: [
      {
        name: 'Yearn multisigs',
        id: '0x92f7f17376d1004f0f9006fd15967a8bb95c591570055f7eefa65c30b29e7bfa',
        developer: '0xaaa8334B378A8B6D8D37cFfEF6A755394B4C89DF'
      },
      {
        name: 'Debt Strategies',
        id: '0x3606efa19b0c4f065e9fd331a475dc8a80c61ac73df58513710e409c43cce224',
        developer: '0xaaa8334B378A8B6D8D37cFfEF6A755394B4C89DF'
      },
      {
        name: 'Yearn Protocol Specific Alerts',
        id: '0x2a556f0e64724b8d3d1f839d9bbf9d47dd8a463dc132609671c245671da1646e',
        developer: '0xaaa8334B378A8B6D8D37cFfEF6A755394B4C89DF'
      },
      {
        name: 'Yearn Vault Deposit Limit Bot',
        id: '0x1a664a74b2488a07707f87b451c40afaed6991715f799f6e7baa397191c9607e',
        developer: '0xaaa8334B378A8B6D8D37cFfEF6A755394B4C89DF'
      },
      {
        name: 'Yearn Vault Idle Funds',
        id: '0x40967bdac8cb47f466818968bc3df96dc900e189f6f8680a298328443e37204c',
        developer: '0xaaa8334B378A8B6D8D37cFfEF6A755394B4C89DF'
      },
      {
        name: 'Large Deposit/Withdraw Bot',
        id: '0xab6c40f7e921e6a0254d88a3375f31fe7752433256e2365200109d86f21481f0',
        developer: '0xaaa8334B378A8B6D8D37cFfEF6A755394B4C89DF'
      },
      {
        name: 'PPS Ambiguity Bot',
        id: '0x5abf4f19200754144c16ba05e58fdb20102e9b67ceb484bc86742ffac6c415eb',
        developer: '0xaaa8334B378A8B6D8D37cFfEF6A755394B4C89DF'
      },
      {
        name: 'Yearn Vault Management Changes',
        id: '0x5183af1b07ffaef98a776c27df3c915d5bcc9cdc62c5db8a2e7a5bc6a28bf793',
        developer: '0xaaa8334B378A8B6D8D37cFfEF6A755394B4C89DF'
      }
    ]
  },
  {
    name: 'Olympus DAO',
    projectId: 'ohm',
    token: '0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5',
    website: 'https://www.olympusdao.finance/',
    category: 'Assets',
    agents: [
      {
        name: 'OlympusDAO Bonds - Policy Functions',
        id: '0xe7662cdcf453a8bf1f88955d6ce7a585ca107f7174b73efb92be6cfdac6a8f6a',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Bonds - Ownership changes',
        id: '0x7e2cc774a2690b47d7f5b26a6ef5ee6706b8d6a98ba0b203e85f2623ce08fd09',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Bonds - ControlVariableAdjustment event detector',
        id: '0xa4bdc98fdfb289b9621000d8a95cec5917abcbc879e2adf4bdc8782b9266531b',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Bonds - Price Changes',
        id: '0xab6521ca2b809650e7c2e32c6f12b76140cc86285dd9427a8157cd24b3a46cdd',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Policy and DAO - Events detector',
        id: '0xd2828748b76471ad3839c806c10737e105e6d17147d5e6deab6e02b4a8e6d36e',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Treasury - Deposits and Withdraws',
        id: '0x8b4fec0cfdb29ff108d5d3da686f3280f8a91de495af1483e2428b10119a4e46',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Treasury - initializeBondsTerms detector',
        id: '0xa88c690f83bf687e4db250564a063a9c171723dba22630681cef14b5feeec4eb',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Treasury - Ownership TransfersBot to detect ownership transfers in olympusDAO treasury',
        id: '0x61333d4c5fcd6625708e99d7dd57374b62a544a6e7b5c955a565e05d83ef6db2',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Staking - Managment function',
        id: '0x76b9d0fb66a20ff6dd6c4b5df601c43f076243c8f6294407292b0ed4ea5dc0b7',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Treasury - totalReserves monitor',
        id: '0xdde001353706a72943dcde58ff2c0537dcb74f7ec724936645d9ac0be61b259e',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'OlympusDAO Staking - setAdjustment function',
        id: '0xfe83663c9f76e721edd72a2c2bb508c9d8ff36622325b177eb3a07bf8fb38e42',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      }
    ]
  },
  {
    name: 'Compound',
    projectId: 'compound',
    token: '0xc00e94cb662c3520282e6f5717214004a7f26888',
    website: 'https://compound.finance/',
    category: 'Lending',
    agents: [
      {
        name: 'Compound cToken Event Monitor',
        id: '0xab39733ddf86340b8e7940ebb933bb48506a341485c7f8c77da17bc1400fe028',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound cToken Underlying Asset Monitor',
        id: '0xfa3044aa08927163ff8578fb5c108978dfde3a12e0b21834e53111e2859f3a59',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound Low Liquidity Market Attack Monitor',
        id: '0xe49ab07879658c258d5007ac6b88428a2b88cc5cfef206222ad94690840be87a',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound Multisig Transaction Monitor Bot',
        id: '0x2e7f036f3495fec41a3eabae03b3efd378f6834bbb2976b99dfed6d3c7de7458',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound Oracle Price Monitor Bot',
        id: '0x32facccd163300ad76c7fe88b559b812dca9050a569417b42fced95594dda08e',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound v3 Liquidatable Positions',
        id: '0x52b24dd829bf3aa7b18a74f23947377af6203f09b6553ef054df7c633f961210',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound Prop 117 Liquidatable Positions',
        id: '0x4219feb3691c014948ad19d3b54aca97dfa34d1682ac8031b5f87559d61a02f0',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound Liquidatable Positions Monitor',
        id: '0x3c61101f1d349661298a58ba59a58fbce5a3626c5c7af10b091796969e0d6c59',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound Delegations Monitor',
        id: '0x0d3cdcc2757cd7837e3b302a9889c854044a80835562dc8060d7c163fbb69d53',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound Large Borrows Governance Monitor Bot',
        id: '0xb6bdedbae67cc82e60aad02a8ffab3ccbefeaa876ca7e4f291c07c798a95e339',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound Governance Monitor Bot',
        id: '0xa0424dfee87cc34b9ff6a1dfa2cb22dbf1b20a238698ae0eeffbf07f869e5b39',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'Compound Distribution Monitor Bot',
        id: '0x34c27c43e0a45bced8f8a941b3d552f5e6feae62afd7e2e88b5024f7de5a8ba0',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      },
      {
        name: 'USDC-ETH Liquidity Monitor',
        id: '0xdff18ae470ccefbeb8298a950210b816f6f1c0139cede1775c5ddb9cf264c600',
        developer: '0xF56df8FEbc5dB60433516539F50231FF7242AC87'
      }
    ]
  },
  {
    name: 'Pickle Finance',
    projectId: 'pickle',
    token: '0x429881672B9AE42b8EbA0E26cD9C73711b891Ca5',
    website: 'https://www.pickle.finance/',
    category: 'Assets',
    agents: [
      {
        name: 'Pickle Finance Vaults PPS Monitor',
        id: '0x58701b1a11a4ab8aabb32be50dbada27ded4586854ab02bc78dab0a0b97befff',
        developer: '0x000000000088E0120F9E6652CC058AeC07564F69'
      },
      {
        name: 'Pickle Finance Multisigs Bots',
        id: '0x7a1417b69395714cd87132a69bb5e944064e5160a9765c7310a22a1d791c5a8a',
        developer: '0x000000000088E0120F9E6652CC058AeC07564F69'
      },
      {
        name: 'Pickle Jars Large Deposits/Withdraws',
        id: '0xc59e454153062de8c33a31ff3a81d6225aa5dc3595b1de9eebf4e6f2764d3eea',
        developer: '0x000000000088E0120F9E6652CC058AeC07564F69'
      },
      {
        name: 'Pickle Finance Management Calls Bot',
        id: '0xafcd309604f08fb6a080f889284edfaebb23d5717f0611c9657363c74ca5fdee',
        developer: '0x000000000088E0120F9E6652CC058AeC07564F69'
      },
      {
        name: 'Pickle Finance Idle Funds Bot',
        id: '0x5fb88d3c95b34f009fae3b835a140cf9c7059194f00bcb6c01fa7f0981bab24c',
        developer: '0x000000000088E0120F9E6652CC058AeC07564F69'
      },
      {
        name: 'Pickle Volatility Monitor',
        id: '0x64e1c03e7f9de8334b63cf70917257dfdcffb2a110fc168edcdb05de2d604a8c',
        developer: '0x000000000088E0120F9E6652CC058AeC07564F69'
      },
      {
        name: 'Pickle Range Management Monitor',
        id: '0x761d333bbbddc4561c99d71d97c0b0ff005da2306fa3090863cb2d3c67e941e3',
        developer: '0x000000000088E0120F9E6652CC058AeC07564F69'
      },
      {
        name: 'Keeper Topup Monitor',
        id: '0x9cf5b135ef85307baf3afc9df8c7f953fa61671d3a3843907856629f4ea7fac8',
        developer: '0x000000000088E0120F9E6652CC058AeC07564F69'
      },
      {
        name: 'UniswapV3 Strategies Idle Funds',
        id: '0x1d40c7236204d5bc0ed2ca63a755110d1dec2b6d1a923b8b35cde6c0b3d5bce0',
        developer: '0x000000000088E0120F9E6652CC058AeC07564F69'
      }
    ]
  },
  {
    name: 'Alpaca Finance',
    projectId: 'alpaca',
    token: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    website: 'https://www.alpacafinance.org/',
    category: 'Lending',
    agents: [
      {
        name: 'Alpaca Finance Bot',
        id: '0xb98e467f02997fdcb035db290347852540799f7ff99655159eed7c0632b5c6e2',
        developer: '0x2DD872C6f7275DAD633d7Deb1083EDA561E9B96b'
      },
      {
        name: 'Alpaca Finance: High volume transaction',
        id: '0x0e8e0f1c6e897487bca11038704ad316c9e0135693a4e4e717704d23b6845771',
        developer: '0x2DD872C6f7275DAD633d7Deb1083EDA561E9B96b'
      },
      {
        name: 'Alpaca Finance: Guard Alert',
        id: '0x4bc8273b69f070c209c4866907d5def4c6f899af4cb47dee5e263aba6defad69',
        developer: '0x2DD872C6f7275DAD633d7Deb1083EDA561E9B96b'
      }
    ]
  },
  {
    name: 'Solace',
    projectId: 'solace',
    token: '0x501acE9c35E60f03A2af4d484f49F9B1EFde9f40',
    website: 'https://solace.fi/',
    agents: [
      {
        name: 'Whale Monitoring',
        id: '0x7d631d5f2c51d939b6d38cee614c535da1d84606fdf46bd75973099ddc251f07',
        developer: '0x70358461af09dCb10B003e8e0a6033de34387A2a'
      },
      {
        name: 'BondDepository monitoring',
        id: '0x143f7fd87abb8aff430bdf0a5d94ce8da09159c2fc509f72f6a34838eb9bc15d',
        developer: '0x70358461af09dCb10B003e8e0a6033de34387A2a'
      },
      {
        name: 'StakingRewards monitoring',
        id: '0xda27257407055ba19ddf476e199ba03ff10d6a6ac140c0495e2c487cddbbe6ee',
        developer: '0xBc281b36D91Aa9c7a6D0eF6312845c01c4666a7c'
      },
      {
        name: 'BondTeller Monitoring Whale Alert',
        id: '0x407cf0397de5fc49f8e1329b556dcc5b91286d66532a8ecd979214d7cbc3c276',
        developer: '0xd9Feacff1229Fd44d4660373147733d0f4E85e2B'
      },
      {
        name: 'BondTeller Monitoring for Basic Functions',
        id: '0x022eb176480a2cd02ef5cb928a48fce47578afa79a9a7d861c7d22ff8426aee9',
        developer: '0x208801Eb22843a9a7d34623472f8c8dF95280D58'
      },
      {
        name: 'StakingRewards Governance',
        id: '0x86157a05c1daefb541349374ea658008b187d288c4c3f69d70f97eadd995d2e0',
        developer: '0x208801Eb22843a9a7d34623472f8c8dF95280D58'
      }
    ]
  },
  {
    name: 'Idle Finance',
    projectId: 'idle',
    token: '0x875773784Af8135eA0ef43b5a374AaD105c5D39e',
    website: 'https://idle.finance/',
    category: 'Assets',
    agents: [
      {
        name: 'Idle BY deposit or redeem with flashloan',
        id: '0x7311e01fac81668d5ccfd8b3420a6d29a85d080abdfa82348d3632b8f4182428',
        developer: '0xc5924Db956E47bfe3E33C00d3147e976638ea0Da'
      },
      {
        name: 'Idle BY price bot',
        id: '0xcceb7abc4cc38ec43a19cc5bf8b3581415e04b6a2822f058b65f590806be72c6',
        developer: '0xc5924Db956E47bfe3E33C00d3147e976638ea0Da'
      },
      {
        name: 'Idle PYT strategy price decrease',
        id: '0x338f9063083951962435b40bf6bdd4de20299b1deb137ec34d1825c14e050a44',
        developer: '0xc5924Db956E47bfe3E33C00d3147e976638ea0Da'
      },
      {
        name: 'Idle PYTs tranche tranchePrice decrease',
        id: '0x10347fe2fb22da65e027e919dcf5dcfadfbb24c05905961b3322fabb9678c282',
        developer: '0xc5924Db956E47bfe3E33C00d3147e976638ea0Da'
      },
      {
        name: 'Idle PYTs large change in TVL',
        id: '0x1b73d0e4a59b3e4b804d0e26c19754a0ba2e2831a700506d7b7c0fb21717e7d0',
        developer: '0xc5924Db956E47bfe3E33C00d3147e976638ea0Da'
      },
      {
        name: 'Idle PYTs tranche virtualPrice decrease',
        id: '0xff0a58ffd0598057ada7b2b3185738268448a529911108e58151130eb045a8b5',
        developer: '0xc5924Db956E47bfe3E33C00d3147e976638ea0Da'
      },
      {
        name: 'Idle BY big deposit or redeem',
        id: '0xbab255d7cd80bfc85e76f35070bede895551b7253e2a9c77d06f0604ff97ae25',
        developer: '0xc5924Db956E47bfe3E33C00d3147e976638ea0Da'
      }
    ]
  },
  {
    name: 'Zodiac',
    projectId: 'zodiac',
    token: '',
    logo: '/gnosis.png',
    website:
      'https://gnosisguild.mirror.xyz/OuhG5s2X5uSVBx1EK4tKPhnUc91Wh9YM0fwSnC8UNcg',
    agents: [
      {
        name: 'Zodiac Reality Module -- call monitoring',
        id: '0x633216554ab6bc8a03891c00333e39165736a0d7cf6c81739b7d0b72cbdfb112',
        developer: '0x53bcFaEd43441C7bB6149563eC11f756739C9f6A'
      },
      {
        name: 'Zodiac Reality Module -- Cooldown alerts',
        id: '0xe63914e7c900435be0253917a33a45a174106796bfeb9045cd8fb64ac724f4e9',
        developer: '0x53bcFaEd43441C7bB6149563eC11f756739C9f6A'
      }
    ]
  },
  {
    name: 'Iron Bank',
    projectId: 'iron-bank',
    token: '',
    logo: '/ironbank.png',
    website: 'https://ib.xyz/',
    category: 'Lending',
    agents: [
      {
        name: 'High Number of Interactions by Account',
        id: '0x108d9737c7eea24c3772a5dfef2413f741a37b77b9f32bcc775e78ca1df35b98',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'High Mint or Borrow Amount',
        id: '0xff006f92ce95eb18f9eb38634c4715617ea27cc89997cc44dc30127d4c4bc477',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'High Number of Accounts',
        id: '0x7b9536934980b69504f4d8031f5f51c55b0523ba0e6c6f6bb71ac881b22c2f8c',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'Credit Limit Changed',
        id: '0xfbd2d5728d46510acd0fe2153a77c308216baf9c261e336e330509a91f9fb220',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'High Number of Markets',
        id: '0x0be400e5494546240264fa31161a9805079b2e89d8d56fe36f3afce2719ba22f',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'Drastic Liquidity Change',
        id: '0x108b160a7258765ba6a461e5746590e627620912ca0bd468250b3d9f9a5abe50',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'Mint or Borrow With Flashloan',
        id: '0x0a5aa85c53ed623cf5dade8c82068f690f9bf6a009a3b20f340fd54ea831838d',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'Drastic Price Change',
        id: '0x54fbc115dbfc297d211230688765a41632699aab9d12179f000252b13e7a6b82',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'Drastic Liquidity Change',
        id: '0xea970f5384b60e8b2dcdf23f973589e18f8d49899d36ae8fd8b7f9d9528ca6a8',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'System Related Changes',
        id: '0x8d04e1730f56e18f144536a9c3fa94463453122da3be9bb3544d182ebb1c27d6',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'Bad Debt',
        id: '0x458f760ed34b3c14aaf8b9d8cb2ca04a422fd54f96b3a6e15333dccd79abbf7b',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'Tornado Cash User',
        id: '0xac61127f418035c58f37fda440b7fb4078cc6975ba5ba67a6621f163fcb68e5d',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'High Number of Market Interactions',
        id: '0x6ff6b6af4ae4b1222da9e31d950164c517ae9daae9a791aca6a9a9be041870d4',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'Reentry',
        id: '0xd72ee43db5d4ec444123d5ed07b4df52d89f4109a9cb49530455d5a75b2b0f9c',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'Borrow Amount Close to Total Supply',
        id: '0x23c932a84edb564bcc5582f950ea9803099ed85ecd2681298bd114a04eccafba',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      },
      {
        name: 'High Gas',
        id: '0xf4958a88ea7f2b6b4c8aed0f54bb69712322bc8e2699e2294d2b3d0edae352b4',
        developer: '0xE34dC9DB5F87f1854F687D94FF37db6993ef1441'
      }
    ]
  },
  {
    name: 'Maker DAO',
    projectId: 'maker',
    token: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    website: 'https://makerdao.com/',
    category: 'Lending',
    agents: [
      {
        name: 'Governance Module',
        id: '0xc8a9a972ee078baef363dd1601e0c22e058700de1553eac616013389055844a6',
        developer: '0x51c9e0D122102b7babB647b294CB63DC6af9216E'
      },
      {
        name: 'ESM',
        id: '0x59eb82c657e2d1b17fb9e360da56a25d4138ef8a10df7c0acec93831dc43f4a5',
        developer: '0x51c9e0D122102b7babB647b294CB63DC6af9216E'
      },
      {
        name: 'OSM',
        id: '0x8332f99181ddfc11151e41b890bc8dc3a928d6503a178ac4f0db268a1c8669b1',
        developer: '0x51c9e0D122102b7babB647b294CB63DC6af9216E'
      },
      {
        name: 'DAI Bridge Bot Eff',
        id: '0x8373bf9a8693bd5aa0454625e55a94c9493c2fddae9cdb46b3ba93061e2f6e7d',
        developer: '0x9316A23864A75660fa019C65aC8bC21a33F2c307'
      },
      {
        name: 'DAI Bridge Bot',
        id: '0xc33cfbe2c914e3d3c760ed46e8a546a3e3ccaa263a9a4e357bd5b7d877b49e9e',
        developer: '0x9316A23864A75660fa019C65aC8bC21a33F2c307'
      },
      {
        name: 'DAI Bridged Balance Checker',
        id: '0xda07e20f41b66467c8e16dbfda9ed047570a4dd43becf760d54db866695427ca',
        developer: '0x9316A23864A75660fa019C65aC8bC21a33F2c307'
      },
      {
        name: 'DAI Arbitrum Supply Checker',
        id: '0xe83234e6d5182610b4165bccb1b0dcf29c4582a18cf0c097bfc1d056edbe07b3',
        developer: '0x9316A23864A75660fa019C65aC8bC21a33F2c307'
      },
      {
        name: 'DAI Optimism Supply Checker',
        id: '0x4ee97e15449fe5c14fd556f2b8a51a41f826f9c8df189d4328e92fa955a703dd',
        developer: '0x9316A23864A75660fa019C65aC8bC21a33F2c307'
      },
      {
        name: 'Forta Bot Starter',
        id: '0x5326aeca48c7306e1e628819a8f0336652bbaf9f6ec91c1075b7cedc375133c5',
        developer: '0x9316A23864A75660fa019C65aC8bC21a33F2c307'
      }
    ]
  },
  {
    name: 'Flexa',
    projectId: 'flexa',
    token: '',
    logo: '/flexa.png',
    website: 'https://flexa.network/',
    agents: [
      {
        name: 'Flexa Collateral Manager Withdrawal Root Published',
        id: '0x6bff7a3c1c624e81b15ba62c008c7d9b0bc1eae0e9984e48e349da5d18190271',
        developer: '0x250CBf806b4C5589D9F27B89c9dc22E2C2A2b155'
      },
      {
        name: 'Collateral Pool Large Unstake',
        id: '0xa1e3d08b8919efbaaf6cc07d5682b768671ebfe1fab96238db283c113dd5a99f',
        developer: '0x250CBf806b4C5589D9F27B89c9dc22E2C2A2b155'
      },
      {
        name: 'Collateral Pool Large Stake',
        id: '0x3d77084ab3909e41b7038587696068cadd32917ae8171aea3dccda4141b0b154',
        developer: '0x250CBf806b4C5589D9F27B89c9dc22E2C2A2b155'
      },
      {
        name: 'Collateral Pool Large Deposit or Withdrawal',
        id: '0xe21350eec290df93ef7d447bd5b6469b4ecb33f383a878c296708e2c50e31a3e',
        developer: '0x250CBf806b4C5589D9F27B89c9dc22E2C2A2b155'
      },
      {
        name: 'Collateral Manager Fallback State',
        id: '0x4af3855d3f8985cbf301060147a68bd8b5d41b0d7ba68628215abcf6a604eca5',
        developer: '0x250CBf806b4C5589D9F27B89c9dc22E2C2A2b155'
      }
    ]
  },
  {
    name: 'Forta',
    projectId: 'forta',
    token: '',
    logo: '/favicon.png',
    website: 'https://forta.org/',
    agents: [
      {
        name: 'Forta Access Control Role Changed',
        id: '0x8e5cfc52606ac22590cf872711f81df8a0d81e3e110dee4f3fb00fafadc962c2',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Access Manager - Router Updated',
        id: '0xacd82110ea6551078e40b58cebd83b9f29c09b5cf85200a5ec9244e374035e6c',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Admin Bot Scanner.scss Disable',
        id: '0x15022cd09034e6247336e5937f2c738d572d5f42a9c3fd53551f8c6c1766994b',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Bot Updated',
        id: '0x2f2d455136d8584088e2c5466d5c7b5a77f95ef40dca3e8c1ba0990b363c24e9',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Bots Linked',
        id: '0x9e1e98b397bcbe38e1604f03f36e91aeb1e9a2a719d5a68dc7ae327d2bf33ca8',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Core Monitoring',
        id: '0x6dbd2e5c9e7c2261c6d36d7f8a2bd66974d8917b311dff1738589e6d6a1fced2',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Emitting Upgraded',
        id: '0x04feb3b868f738cfac42faa29a24179ddd25018be8e09c5ef05655c7cfd69522',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Mint Mainnet',
        id: '0xd76cb2ce08c467ad328caa9582bfc3c226964ae8dd922ee7ae0bcdb9e8b4f5ce',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Scanner.scss Node Software Updated',
        id: '0x33bb8d279150938d062ce3d98dea48514828971611a5b9a64789576bb0a0152c',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Staking Events',
        id: '0x83fad460c7e30ad0a4ec8f082fbd068edfe6e0f02cacd4e136120b201ed83a05',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Staking Parameters',
        id: '0x16d30698283b688f7266b2fcd9d6c5edc8e0551a212edcd2d010e54a8ffcf0ba',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta High Number Of Bot Deployments',
        id: '0xef163df6a354c8166d51eba7fb3b16bfc21e1b7be414fa0fe5c19e53794f65de',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Routing Updated',
        id: '0xef163df6a354c8166d51eba7fb3b16bfc21e1b7be414fa0fe5c19e53794f65de',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Stake Controller Changed',
        id: '0xc44bf7b5f04444e8939ed0c29a90fc772baf3df6d2abb3edbd03adc31e9b5cf6',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Stake Threshold Changed',
        id: '0xe600b501cad9eae7e6885721cb44d0d79e98d7413f5cf8b75f848692ebb635ad',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Token Role Changes',
        id: '0xe6eebc466ba3dc71bf701719f8012bd9e1c80bfa63224684271be80ea2f93338',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Whitelist Disabling',
        id: '0x11b5412d0d56b1b7bec8b15f18e8976416b93482961c50cf1161fbf9c4445c70',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      },
      {
        name: 'Forta Whitelist Disabling',
        id: '0x11b5412d0d56b1b7bec8b15f18e8976416b93482961c50cf1161fbf9c4445c70',
        developer: '0x40e98Fe8BCc8c0fF48A585bc6f28d504fF17A927'
      }
    ]
  },
  {
    name: 'Perpetual Finance',
    projectId: 'perp',
    token: '0xbC396689893D065F41bc2C6EcbeE5e0085233447',
    logo: '/perp-finance.png',
    website: 'https://perp.com/',
    category: 'DEXes',
    agents: [
      {
        name: 'USDC Balance Change',
        id: '0xde79f8b310ab8551d83b284ba1eb314b1d687cc8017210d50bfd718968209ff7',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Price Spread Ratio',
        id: '0xce246cbf927dcd0492fa9c2db2b9e93f2b37a116e6356c465ec967e74c1f8cca',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Pending Transactions',
        id: '0x1176d714ae641c16a336eed770151dc118e1301ab4e93a828ad4098acc11a9a3',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Failed Transactions',
        id: '0xb0fb83a649f287561cc6b8fc8c22bc9734966ffa8be2cf69598fb0d76ee2f508',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Admin Events',
        id: '0x4c34722f3ad997eef64acebfe68ab88cdf6e61663f207ba528448ef4146e5104',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Account Balance',
        id: '0x0d189308275bfdcbaeaa05574121918d81ec66c683120ae84e0121bf281236cb',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      }
    ]
  },
  {
    name: 'Dodo',
    projectId: 'dodo',
    token: '0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd',
    website: 'https://dodoex.io/',
    logo: '/dodo.svg',
    category: 'DEXes',
    agents: [
      {
        name: 'Dodo Reward Vault',
        id: '0xe982355a3b0483cf0474883590b3b9ee722bdac94316d603bfd54e7e2f33a38d',
        developer: '0xbbFfe763CE756880731Dc5875BaE071DC1317d1D'
      },
      {
        name: 'Dodo Mine Monitor',
        id: '0x06b462de990d8793b4dccf1ab18a3ce7147a35701f7ff119dd7c3813161e6a3d',
        developer: '0xbbFfe763CE756880731Dc5875BaE071DC1317d1D'
      },
      {
        name: 'Dodo Miner Polygon',
        id: '0x9295b571945937bcbd4060d62f5969cca51a0c419a48a3c64795b802b2dca6b9',
        developer: '0x55483C180181b68c3F4213E8f4C774FB0D393148'
      },
      {
        name: 'Dodo Monitor Miner',
        id: '0xa58805ae8ff028a6e95801391c2bd280853f68c20115545ce17d8bfdeb7fc6fa',
        developer: '0x55483C180181b68c3F4213E8f4C774FB0D393148'
      }
    ]
  },
  {
    name: 'ApeSwap',
    projectId: 'apeswap',
    token: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    website: 'https://apeswap.finance/',
    logo: '/apeswap.svg',
    category: 'DEXes',
    agents: [
      {
        name: 'Address Inclusion-Exclusion',
        id: '0xcc645aff1cada4e2061a28a4fe8fa0e0c0cf2824ed72f87dcb18b4e9ffd5f64e',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Banana-Token Mint',
        id: '0x5579887dd8124e2411db3355ef645ecfdb292fb24b646cb7457e6a485a6a0891',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'GNANA Fee Update',
        id: '0x50285a1412242b2efb15f92430e8a7180bf1a9572f0e6e64cb4712073618e148',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large GNANA Balance',
        id: '0x7ff634e741b7b9e91bfe8fd77bb703c5ac0ca9a2c49983c1c0622841182e6ddf',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Tokens Ownership Changes',
        id: '0x67ddefb8f76120d7b21738ed62642c4d3833e64cec60006acbccf699d021b20f',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Fee-related Factory Changes',
        id: '0x473949f4a3f624370e0b0b8c9f9fb40c5addaec5c4791067529f4466813de1d6',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Pair Creation',
        id: '0x3acc4541cb30880ba570608eb1d684e0f8bcedc827df2b5f2fe58e4b8519c95d',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large LP Deposits-Withdrawals',
        id: '0xf4e2c306cf4676b7be2564cc0eb28e9d6da1b789b32f27c78c6f0772e59e526d',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Role Changes',
        id: '0x2e789e098b9e0b3c73105fb1827cb6dde4bea66ca7f2c2c43fd24ed356ac75df',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'New Bonus Multiplier',
        id: '0x7ce0359b813e5dde6fafb069a4a4b448effa979d0f96e60a84d5b396481d99c2',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Timelock minDelay Changes',
        id: '0x36e1b2b2d47cc73f4d7ea199648403d795b022ff2268e151a6054446adde7ecf',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      }
    ]
  },
  {
    name: 'Impossible Finance',
    projectId: 'impossible',
    token: '0xB0e1fc65C1a741b4662B813eB787d369b8614Af1',
    website: 'https://impossible.finance/',
    logo: '/impossible-finance.png',
    category: 'DEXes',
    agents: [
      {
        name: 'Swap Fees',
        id: '0x0cd954477ce1c6c90965c85b93c25dfb28a718e13bf7b4b68a5e255e583eb359',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'staxMigrate',
        id: '0x843b5c3e9f546839208ead399b4c313d3a536b3b66fb61b8cb700dd2425bb6c1',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Ownership Transfer',
        id: '0xf60c259a712b8d9b9372667c16ecc205508fc46fa858f3e951af634e8ed0b3c8',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'New Pair',
        id: '0x5a6d434f1252e320fd16955450fce2f3a4d69fdbb489f5576fe737dbaea46bf9',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Dangerous Activities',
        id: '0x5daf7406bd9f8d80bcdebb84d2c6588f6f43838c69abbc06b3352dbc7f1879f6',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Flash Loan',
        id: '0xe3bec46574fc5210ca21607d7e895e612384707f3ab6d98cee402c822c3c3670',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Gas Control',
        id: '0x91c1b4b761116979f40518a5dd47e6db06ea57a47322ae7f0b114d30687856f6',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Deposit-Withdraw on Pairs',
        id: '0x593dd078932930302ca8577483e55f6eca17e42c14c73f0b9883d649452cf41e',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Deposits-Withdrawals',
        id: '0xc0a65f0288da4dc73e2f15cec94915eaf2e839b1ad3b971572012cd044282c40',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      }
    ]
  },
  {
    name: 'Ondo Finance',
    projectId: 'ondo',
    token: '',
    website: 'https://ondo.finance/',
    logo: '/ondo.svg',
    category: 'Assets',
    agents: [
      {
        name: 'Ondo Role Granted Bot',
        id: '0xf71bb95cd4cd0823c1fbeff4dd0659bed33bdd1a0b812ec8975f57c1ef1ebc67',
        developer: '0x2F31cC89502ee12F10FB9dD90a9A93C735B4c8bd'
      }
    ]
  },
  {
    name: 'dYdX',
    projectId: 'dydx',
    token: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
    website: 'https://dydx.exchange/',
    logo: '/dydx.svg',
    category: 'DEXes',
    agents: [
      {
        name: 'Large Inactive Balance in Safety Module',
        id: '0xf16504781a9c28c4c5d44b4ece4d812f1ce2d9d3bbccfcbab77e2796709cddaa',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Slashed dYdX Bot',
        id: '0x2738db78e076752f74cfc394c1baa89e4b1af1b14e763a02ee5c84c6af65be9b',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Role Changes Monitor Bot',
        id: '0x4845504f50446f8f35fb4c6692266e844ea62c956b57f76b6b88130e2d1f1888',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Large Deposit/Withdrawal on Perpetual Exchange',
        id: '0xc95631306d6fa87c0ec9a4c198aa0b3f166329e30f542ae3a6dacb2428369ca7',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Parameter Changes Bot',
        id: '0x55db3d3da01430a057933348b769471b362a7b58eeb2177ca5aa8db137d61f77',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'DYDX Operator Monitor',
        id: '0xc3ca2237c212832c6af4b17fe0f6c8bb55c45f3071fc455ff2a7c60c95e2bdfc',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Large DYDX Staking Withdrawal',
        id: '0xc2a57288526b901e940cffff276f4ae9a6fb13ea1bd3513d900367770727c44f',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Large USDC Staking Withdrawal',
        id: '0xbb34c89f51bba941ef7ad54daccbc82eadb6177ffd2183d0939561499e295424',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Large Borrower Allocation Bot',
        id: '0xd7840b2cfda468d94ad5c8dd7fb01d6c92cea5dbd0d197f877b7a6a71dfdd973',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Insolvent Contract Bot',
        id: '0x9339fb25c1ff0ad0185890bcbc0283845f99f73d19267506a5aec805a7b11a81',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Frozen State Monitor',
        id: '0x07811871b57af338447b83ef6c6a84ae8e176efc51aff72eb50b1b617a8d3137',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Global Configuration Monitor',
        id: '0x7235bf73ba8dfdf4bf2715a73ec9d474f910ff0d760fadda7438326bb9c46a35',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      },
      {
        name: 'Spending Approval Monitor',
        id: '0x8511ab8047b2d4572c8d9593961b16e3e6ca0e6ebe898125b192abaf9b306593',
        developer: '0xBc9ebd3d23Fd48b521b03343a12e31F3D6093F70'
      }
    ]
  },
  {
    name: 'Benqi Finance',
    projectId: 'benqi',
    token: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    website: 'https://benqi.fi/',
    logo: '/benqi.png',
    category: 'Lending',
    agents: [
      {
        name: 'Qi Tokens Interest Rates Monitor',
        id: '0xd95c28bb11ef69b285bfdc96a12024f64f98c1440262383d57977424117a68a5',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Qi Transfers',
        id: '0xbdc3fa0b3b8196246b97dd95a37ba27e4659c3ff804566d734e67720607695ae',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Pool Withdrawal',
        id: '0xcf66665a733bc76160807294f06ba40c86dcb2a31fe7ac3fad6a33c9d8534d9d',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Mint-Burn',
        id: '0x7b63086bf4bcc9ae1d275f0f53502d77a02a86321d81528eb8d727f3aab3f5f6',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Grant',
        id: '0x88c5c3b7101824e5716be9fd3bf04eb9699f2c208b2757c4b9d243445df2aa72',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Deposits-Redeems',
        id: '0x40a03890c7afd006fb4ec0d77e8691f4b31b361a96c2f060c39d507ea3dfc59e',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Delegate Votes Change',
        id: '0x656b976f478808cc1e0c4cf2987392faf083b305d994d40109136456f06e578f',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Flash Swap',
        id: '0xe9564965a6d371705a53643060f3ed5d2398ed83c0fd9083696a88e55f733701',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Delegation Monitor',
        id: '0x145d124d11c0725a7cfaf6ce544a7694f39a30691532ee83c46a5dd2786711e2',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      }
    ]
  },
  {
    name: 'TrueFi',
    projectId: 'truefi',
    token: '0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784',
    website: 'https://truefi.io/',
    logo: '/truefi.jpeg',
    category: 'Lending',
    agents: [
      {
        name: 'TrueFi Admin Changes',
        id: '0x7edb16e51ca0e6a211a655356ec863ae7445104553a20349873ce9145d3c6eb0',
        developer: '0x2C4536B866782d2A3D0B75d66852955a8e8beB35'
      },
      {
        name: 'TrueFi Funded and Reclaimed Loans',
        id: '0x49c6ab438e89e22aba5b2ce5cbe1196a3d4ee4738949b68c2d7c916bb11dc197',
        developer: '0x2C4536B866782d2A3D0B75d66852955a8e8beB35'
      },
      {
        name: 'TrueFi Implementation Upgraded',
        id: '0x8228e877d8cf5f5d43edfb1bbced477bc666d6ed5ef041da63ae4f04631c772a',
        developer: '0x2C4536B866782d2A3D0B75d66852955a8e8beB35'
      },
      {
        name: 'TrueFi Large Joins and Exits',
        id: '0xd4bf700f1d0ea6a4431b4f44fb4eabb2f4ce4486f9e6dcdb50ef779c6f4621c4',
        developer: '0x2C4536B866782d2A3D0B75d66852955a8e8beB35'
      }
    ]
  },
  {
    name: 'Umee',
    projectId: 'umee',
    token: '0xc0a4Df35568F116C370E6a6A6022Ceb908eedDaC',
    logo: '/umee.png',
    website: 'https://www.umee.cc/',
    category: 'Lending',
    agents: [
      {
        name: 'Detect Bad Debt Bot',
        id: '0xde47938eddcd62ed99cc4c7ba5375f9f9c2db0239114c0122cbff08934a2173d',
        developer: '0x286a227Ae4f31c586E2935f1C0B0c36a0AD30340'
      },
      {
        name: 'Deposit/Borrow In The Same Transaction as Flash Loan',
        id: '0x063ee2b501850ce659daef284da611d4eaec38b11e00fde64c4698c0af50ca4d',
        developer: '0x286a227Ae4f31c586E2935f1C0B0c36a0AD30340'
      }
    ]
  },
  {
    name: 'Trader Joe',
    projectId: 'joe',
    token: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    logo: '/trader-joe.png',
    website: 'https://traderjoexyz.com/',
    category: 'DEXes',
    agents: [
      {
        name: 'Large Swap Bot',
        id: '0x85ff3d34d1c0fcf8538c837e31226456816675b889c11fc3f988aa660e91f74a',
        developer: '0x1119fbb02F38764CD90F2d9fB35FeDcd8378ac2A'
      },
      {
        name: 'Ownership Transfer Monitor Bot',
        id: '0xdd18fad5bb4a536385f8f59715ad207730084bbfddc5f75bbc572b751af00610',
        developer: '0x1119fbb02F38764CD90F2d9fB35FeDcd8378ac2A'
      },
      {
        name: 'BankerJoe Large Transfers Bot',
        id: '0x53356352816ac24844322ed858e749561ea5561c93eb5a578004a84da6bbb0f6',
        developer: '0x1119fbb02F38764CD90F2d9fB35FeDcd8378ac2A'
      },
      {
        name: 'Token Transfer Re-entrancy Bot',
        id: '0x36c6cf54f2177d84754ce8e35510b55d3c5cfeffae7b602025664124f7193d6d',
        developer: '0x1119fbb02F38764CD90F2d9fB35FeDcd8378ac2A'
      }
    ]
  },
  {
    name: 'Liquity',
    projectId: 'liquity',
    token: '0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D',
    website: 'https://www.liquity.org/',
    category: 'Lending',
    agents: [
      {
        name: 'Big Redemption Bot',
        id: '0xd6709e40805ae7b2b8fd634d14540e599cdf18c46ed3a162614497591d809ec0',
        developer: '0x9B5715C99d3A9db84cAA904f9f442220651436e8'
      },
      {
        name: 'Big Borrowing Fee Bot',
        id: '0x477d3dbd5d51a5d46ea2edeae4747b87c028d440d769a0d17e8f24b73f782dce',
        developer: '0x9B5715C99d3A9db84cAA904f9f442220651436e8'
      },
      {
        name: 'Undercollateralized Troves Bot',
        id: '0x8daa3fa3ebb4c9fd6d9ec685c196bcdb3fc23b80143adbef0805a011fccbb351',
        developer: '0x9B5715C99d3A9db84cAA904f9f442220651436e8'
      },
      {
        name: 'Recovery Mode Bot',
        id: '0xfd887509add8caa6711f8396f6cb295902774e7933dddcf7804a453db4ea27c8',
        developer: '0x9B5715C99d3A9db84cAA904f9f442220651436e8'
      }
    ]
  },
  {
    name: 'pSTAKE',
    projectId: 'pstake',
    token: '0xfB5c6815cA3AC72Ce9F5006869AE67f18bF77006',
    website: 'https://pstake.finance/',
    logo: '/pstake.png',
    category: 'Assets',
    agents: [
      {
        name: 'stkBNB Admin Events Bot',
        id: '0x5f798596b2c37dabfdbd66313eede4b025fadd3ae37241f6fc645f8af8e7ea1a',
        developer: '0x5354012663b2e0859e89125AeA2E8317e57f48cc'
      },
      {
        name: 'stkBNB Operational Events Bot',
        id: '0x68a5b098ee8416986bcda0739857bafb071b33eafe715d052d1bd8c1503d67ab',
        developer: '0x5354012663b2e0859e89125AeA2E8317e57f48cc'
      },
      {
        name: 'stkBNB Substantial Value Bot',
        id: '0x65b2580b43899f87c1d347a9ca3d917991c4906305de0c22a969a716eaf6d1a8',
        developer: '0x5354012663b2e0859e89125AeA2E8317e57f48cc'
      },
      {
        name: 'PCS Large Swap Bot',
        id: '0x28ab88d2c338a4aac3828d0dc4294ceea85c43473d294c7cab456a9516587ec1',
        developer: '0x5354012663b2e0859e89125AeA2E8317e57f48cc'
      },
      {
        name: 'Large Liquidity Pool Deposit/Withdrawal Bot',
        id: '0xb8b0bf89f277c6dc7635e0c3f94e174320baadacc2bef5e86212342587e2a442',
        developer: '0x5354012663b2e0859e89125AeA2E8317e57f48cc'
      }
    ]
  },
  {
    name: 'PancakeSwap',
    projectId: 'pancake',
    token: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    website: 'https://pancakeswap.finance/',
    category: 'DEXes',
    agents: [
      {
        name: 'Auto CAKE Admin Operations',
        id: '0x1004987efcfbfccb705f3c3604e2d7f5aa30827e6cb2d953d63662cc6abe7629',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Delegators Vote Change',
        id: '0xc68475c73383b3d06ace7a4ae81f2f40338076ff6f3a084763622a62d0429d74',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large LP Deposit-Withdrawal',
        id: '0xf7654c3b2b173a43107eaabdf4b659fdcb741bdec7bf016460fe6e381b6a7dc1',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large LP Token Deposit-Withdraw',
        id: '0x198e899737a2cf2e9005323448e73621169a0d13b6e6763bb94db47e2327af57',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Swap',
        id: '0x9a277d87bc25b29865fbd80a7035f5a53031c64528acc6c99aa7ad246fefc8d5',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Lottery Admin Operations',
        id: '0x6e3c3ad0f0163140d7b97ed7b99fad0f9ff82de71ed82b308a77c684d3a9e832',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Pair Created',
        id: '0xb85f0dd6f4f5375dee4c353c10c62cce3e0258f1fc0324a74a8367d261ae5666',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Prediction Admin Operations',
        id: '0x4211ce6fd920692c3e3a439647cefa081cc6708db7eca0fb6661d00c09d99087',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Set Masterchef Settings',
        id: '0x8f1f893cdcd68d78b82675995f8b7d52ffe46adc5c66e7dfa74039a6c1d264c7',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      }
    ]
  },
  {
    name: 'GMX',
    projectId: 'gmx',
    token: '',
    website: 'https://gmx.io/',
    logo: '/GMX.png',
    category: 'DEXes',
    agents: [
      {
        name: 'Unusual PnL',
        id: '0xdc2d3d2ae6a7f80ac4662e57c796f2eaf4ce745d4fe5381d7a41bce0a4c802c2',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Size Decrease',
        id: '0x10b4eafee02f2cdb4953e36296c5ca882f0b547606ab18fc4cf32fc907c80728',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Large Open and Increase Positions',
        id: '0x19f3119e0cd7849803ef356b25fbc47e7effaa5fccca1df1d79f3cd6a68acc68',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Detect Profitable Trades',
        id: '0x417f4a06505d571d92074b1079eb1994a977f28e357c64f04a1172ba1e5d2ef2',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Detect Many Opening',
        id: '0x416638bbbfcb42fd312586f091022ccac1d59a845970a6a7bf5d788f9d5d312d',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Detect Many Closing',
        id: '0x3b9c154a51afceeef6266269fbb0c3f50a39536a343c82e34dcb8f164300df81',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      },
      {
        name: 'Detect Frontrun',
        id: '0x11ad33569e50f36829cd09f375a2423bc7d939c9b06404860e6b66b56ac13531',
        developer: '0x88dC3a2284FA62e0027d6D6B1fCfDd2141a143b8'
      }
    ]
  },
  {
    name: 'Polygon',
    projectId: 'polygon',
    token: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    website: 'https://polygon.technology/',
    category: 'Assets',
    agents: [
      {
        name: 'Deposit Bot',
        id: '0x9b9c81c01d65d747258fb482d8bdf199369dd88a7711508ce0eeb8f999aa55ed',
        developer: '0x5Fe4c217cC315C86fe5ad1ceE93Da6a8BDed8deF'
      },
      {
        name: 'ChainLink Bot',
        id: '0xa5b3bf43d562b59daf7e3c85d14e5aa555a8085fd75ce76faa617ce6af6f8deb',
        developer: '0x5Fe4c217cC315C86fe5ad1ceE93Da6a8BDed8deF'
      },
      {
        name: 'MaticPriceChange Bot',
        id: '0x0551bea0bdf069b9a626a441cbda45c3f375bee9f0ba766ea922fb8f4e445a79',
        developer: '0x5Fe4c217cC315C86fe5ad1ceE93Da6a8BDed8deF'
      }
    ]
  },
  {
    name: 'Stader Labs',
    projectId: 'stader-labs',
    logo: '/staderlabs.png',
    token: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
    website: 'https://staderlabs.com/',
    category: 'Assets',
    agents: [
      {
        name: 'BNBx Suspicious Amount Bot',
        id: '0x1b10e9f5cd672d1b759031ac829aaac2d4466ee01724254a62e479259ac03d93',
        developer: '0x9C8ED6c23c26dECE8484D56617f807A32636Df00'
      },
      {
        name: 'BNBx Operations Bot',
        id: '0xb62f2ab23098094bf40db49a7379222d44f94bc656cd67906cf173b2eadb2e9c',
        developer: '0x9C8ED6c23c26dECE8484D56617f807A32636Df00'
      },
      {
        name: 'MaticX Childpool Bot',
        id: '0xdb30038aa161ac5703ba12f6cc0bf1a6665effe028cbf8019f75e1a4fe8004b6',
        developer: '0x81813Bd41459c0Bb757308C855c0521A4e308E36'
      },
      {
        name: 'MaticX On Ethereum Bot',
        id: '0x60cfaeedd7561f2f2cb6f4a508be32598b877c715646c3f175b6a3eac46f94e9',
        developer: '0x81813Bd41459c0Bb757308C855c0521A4e308E36'
      }
    ]
  }
];

export default highlightedProjects;
