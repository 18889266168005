import React, { useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useApolloClient } from '@apollo/client';
import cn from 'classnames';

import './AlertsPage.scss';

import Alerts from 'common/components-v2/Alerts/Alerts';
import AdvancedFilters from 'forta-app/components/alerts/Filters';
import useAlertsQuery from 'forta-app/hooks/useAlertsQuery';
import { AlertsApiParams, AlertsPageInfo } from 'common/lib/apis/alertAPI';
import useCursorPagination from 'common/hooks/useCursorPagination';
import {
  ExternalLinkIcon,
  FilterIcon,
  RefreshIcon,
  SearchIcon
} from 'common/components/Icons';
import Button from 'common/components-v2/Button/Button';
import Pagination from 'common/components-v2/Pagination/Pagination';
import Input from 'common/components-v2/Form/Input/Input';
import { trackEvent } from 'common/lib/analytics';
import { isAddress, isAgentId, scrollToElement } from 'common/lib/utils';
import Select from 'common/components-v2/Form/Select/Select';
import { FEATURED_NETWORK_ENTITIES } from 'common/lib/networks';
import useAlertFilter from '../../components/alerts/useFilterParams';

const FILTER_PANEL_EL_ID = 'filter-panel';
const ALERTS_EL_ID = 'alerts';
const ALERTS_PER_PAGE = 15;

export default function AlertsPage(): JSX.Element {
  const client = useApolloClient();

  const [searchInput, setSearchInput] = useState('');
  const [pageValues, setPageValues] = useState<AlertsPageInfo>();
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);
  const { filter, setFilter, filterCount } = useAlertFilter({
    limit: ALERTS_PER_PAGE
  });

  const {
    alerts,
    loading,
    refetching,
    queryResult,
    currentPageValues,
    nextPageValues,
    refetch
  } = useAlertsQuery({ params: { ...filter, pageValues } });

  const { page, goPrevPage, goNextPage, resetPagination } = useCursorPagination(
    {
      queryResult,
      pageValues: [currentPageValues, nextPageValues],
      onPageValuesChange: async (pageValues, page) => {
        if (page === 0) {
          // reset cursor, cache and fetch most actual data
          await client.cache.reset();
          resetPagination();
          setPageValues(undefined);
        } else {
          setPageValues(pageValues);
        }
      },
      scrollToElementId: ALERTS_EL_ID
    }
  );

  async function handleChange(filters: AlertsApiParams): Promise<void> {
    setFilter(filters);
    resetPagination();
    setPageValues(undefined);
    await client.cache.reset();
  }

  function handleFilterButtonClick(): void {
    const isExpanded = !isFiltersExpanded;
    setIsFiltersExpanded(isExpanded);
    if (isExpanded) {
      scrollToElement(FILTER_PANEL_EL_ID);
    }
  }

  async function handleSearch(
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    e.preventDefault();

    const value = searchInput.trim();

    trackEvent('global_search', {
      label: isAddress(value) ? 'address' : isAgentId(value) ? 'agent' : 'text'
    });

    if (isAddress(value)) {
      setFilter((v) => ({ ...v, addresses: [value] }));
    } else if (isAgentId(value)) {
      setFilter((v) => ({ ...v, agents: [value] }));
    } else {
      setFilter((v) => ({ ...v, text: value }));
    }

    setSearchInput('');
    resetPagination();
    setPageValues(undefined);
    await client.cache.reset();

    if (value) {
      setIsFiltersExpanded(true);
    }

    setTimeout(() => {
      scrollToElement(FILTER_PANEL_EL_ID);
    }, 200);
  }

  const networkOptions = useMemo(() => {
    const options: {
      label: string;
      value: number | undefined;
    }[] = [];

    options.push({ label: 'All networks', value: undefined });

    for (const network of FEATURED_NETWORK_ENTITIES) {
      options.push({ label: network.label, value: network.chainId });
    }

    return options;
  }, []);

  return (
    <div className="AlertsPage">
      <Button
        size="lg"
        variant="outline"
        href="https://docs.forta.network/en/latest/api/"
        endIcon={ExternalLinkIcon}
        target="_blank"
        className="AlertsPage__docs-button"
      >
        Access alerts data
      </Button>
      <h1 className="AlertsPage__title">
        The Forta <br />
        Network <span className="color-purple">Explorer</span>
      </h1>
      <form onSubmit={handleSearch} className="SearchBar">
        <Input
          resettable
          name="search"
          variant="light"
          value={searchInput}
          placeholder="Search by Address / Bot ID / Text"
          onChange={(e) => setSearchInput(e.target.value)}
          className="SearchBar__input"
        />
        <Button
          type="submit"
          variant="primary"
          size="lg"
          icon={SearchIcon}
          className="SearchBar__submit"
        />
      </form>
      <div className="FilterPanel" id={FILTER_PANEL_EL_ID}>
        <div className="FilterPanel__header">
          <Select
            name="network-select"
            value={filter.chain_id}
            options={networkOptions}
            onChange={(e) =>
              handleChange({ ...filter, chain_id: e.target.value })
            }
            className="FilterPanel__network-select"
          />
          <div className="FilterPanel__main-controls">
            <Button
              size="md"
              variant="default"
              startIcon={FilterIcon}
              className={cn('FilterPanel__filter-button', {
                'FilterPanel__filter-button--active': isFiltersExpanded
              })}
              onClick={handleFilterButtonClick}
            >
              Filters
              {filterCount > 0 && (
                <span className="FilterPanel__filter-counter">
                  {filterCount}
                </span>
              )}
            </Button>
            <Button
              size="md"
              variant="default"
              startIcon={RefreshIcon}
              className="AlertsPage__refresh-button"
              loading={refetching || loading}
              loadingPosition="start"
              disabled={refetching || loading}
              onClick={() => refetch()}
            >
              Refresh
            </Button>
          </div>
        </div>
        <Collapse
          isOpened={isFiltersExpanded}
          theme={{
            collapse: 'FilterPanel__collapse',
            content: 'FilterPanel__body'
          }}
        >
          <AdvancedFilters onChange={handleChange} filters={filter} />
        </Collapse>
      </div>
      <Alerts
        mutable
        id={ALERTS_EL_ID}
        alerts={alerts}
        empty={alerts.length === 0 && !loading}
        loading={loading || refetching}
        currentSort={filter.sort}
        onMute={(alertId) => {
          setFilter({
            ...filter,
            muted: [...(filter.muted || []), alertId]
          });
          refetch();
        }}
      />
      <Pagination
        page={page}
        loading={loading || refetching}
        limit={filter.limit}
        hasNextPage={!!nextPageValues?.id}
        itemsCount={alerts.length}
        onNextPage={goNextPage}
        onPrevPage={goPrevPage}
      />
    </div>
  );
}
