import { Web3ContextType } from '@web3-react/core';
import { ethers } from 'ethers';

const GAS_MULTIPLIER = 1.15;
const GAS_PRICE_MULTIPLIER = 1.5;

// This class was designed to interact through the use of a web3 provider, which is not always available.
// For example, sometimes we need to display a user's balance on a page without authorization.
// I didn't want to change this class before release, as it would have affected most contract interactions,
// so I created another variant of this class that uses JsonRpcProvider (see contract-interactors-2 folder).

// TODO Need to refactor this class to support both Web3ReactContext and JsonRpcProvider

export default class ContractInteractor<T> {
  protected web3React: Web3ContextType;
  protected contract: T;

  constructor(
    web3React: Web3ContextType,
    abi: ethers.ContractInterface,
    address: string
  ) {
    this.web3React = web3React;
    this.contract = new ethers.Contract(
      address,
      abi,
      this.web3React.provider?.getSigner(web3React.account || '')
    ) as unknown as T;
  }

  protected async getTxOptions(
    gasLimit: ethers.BigNumber
  ): Promise<{ gasLimit: number; gasPrice: number } | undefined> {
    if (this.web3React.provider) {
      const gasPrice = await this.web3React.provider?.getGasPrice();
      return {
        gasLimit: Math.round(gasLimit.toNumber() * GAS_MULTIPLIER),
        gasPrice: Math.round(gasPrice.toNumber() * GAS_PRICE_MULTIPLIER)
      };
    }
  }
}
