import { useMemo } from 'react';

import useBotMetricChains from './useBotMetricChains';
import { BotProtocolVersion } from '../enums';
import { Agent } from '../lib/apis/agentAPI';

export type UseBotChainsParams = {
  bot?: Agent;
};

export type UseBotChainsResult = {
  chainIds: number[];
  isChainIdsFetching: boolean;
};

function useBotChains(params: UseBotChainsParams): UseBotChainsResult {
  const { bot } = params;

  const botChainsQuery = useBotMetricChains({
    params: {
      botId: bot?.id || '',
      timeFrame: 'hour'
    },
    enabled: !!bot && bot.protocol_version === BotProtocolVersion.V2
  });

  const chainIds: number[] = useMemo(() => {
    if (!bot || bot.external) {
      return [];
    }

    if (bot.protocol_version == BotProtocolVersion.V2) {
      return botChainsQuery.chainIds;
    }

    return bot.chainIds?.map((id) => Number(id)) || [];
  }, [bot, botChainsQuery.chainIds]);

  const isChainIdsFetching = useMemo(
    () =>
      !bot ||
      (bot.protocol_version == BotProtocolVersion.V2 && !botChainsQuery.data),
    [bot, botChainsQuery.data]
  );

  return {
    chainIds,
    isChainIdsFetching
  };
}

export default useBotChains;
