import { useCallback, useEffect } from 'react';
import { ethers } from 'ethers';
import Skeleton from 'react-loading-skeleton';

import './VaultStakingForm.scss';

import InformationBox from 'common/components/InformationBox';
import { formatFORT } from 'forta-app/lib/utils';
import useVault from 'common/hooks/useVault';
import config from 'common/config';
import useWallet from 'common/hooks/useWallet';
import { trackEvent } from 'common/lib/analytics';
import StakingVault from '../../lib/contract-interactors-2/staking-vault';
import useDepositForm from '../delegations/DepositForm/useDepositForm';
import DepositForm from '../delegations/DepositForm/DepositForm';
import FortaToken from '../../lib/contract-interactors-2/forta-token';

export function VaultStakingForm(): JSX.Element {
  const { address: walletAddress, ensureConnection } = useWallet();
  const { apy, userAssets, totalAssets, fetchAssets } = useVault({
    account: walletAddress
  });

  const {
    balance,
    allowance,
    inputValue,
    fetchBalance,
    fetchAllowance,
    onInputValueChange
  } = useDepositForm({
    walletAddress: walletAddress,
    contractAddress: config.stakingVault
  });

  const handleApprove =
    useCallback(async (): Promise<ethers.ContractTransaction> => {
      const { provider, signer } = await ensureConnection();

      const valueEther = (inputValue || '0').trim();
      const valueWei = ethers.utils.parseUnits(valueEther);

      const connectedFortContract = new FortaToken({ provider, signer });
      return connectedFortContract.approve(config.stakingVault, valueWei);
    }, [ensureConnection, inputValue]);

  const handleApproveDone = useCallback(async (): Promise<void> => {
    await fetchAllowance();
  }, [fetchAllowance]);

  const handleDeposit = async (): Promise<ethers.ContractTransaction> => {
    trackEvent(`vault_modal_deposit_click`, {
      new_deposit: inputValue,
      user_deposit: formatFORT(userAssets?.toString(10) || '0')
    });

    const { provider, signer } = await ensureConnection();

    const valueEther = (inputValue || '0').trim();
    const valueWei = ethers.utils.parseUnits(valueEther);

    const connectedVaultContract = new StakingVault({ provider, signer });

    return await connectedVaultContract.deposit(valueWei, walletAddress);
  };

  const handleDepositDone = useCallback(async (): Promise<void> => {
    onInputValueChange('');
    fetchAssets();
    fetchAllowance();
    fetchBalance();
  }, [fetchAllowance, fetchAssets, fetchBalance, onInputValueChange]);

  useEffect(() => {
    ensureConnection();
  }, [ensureConnection]);

  return (
    <div className="VaultStakingForm">
      <div className="VaultStakingForm__content">
        <div className="VaultStakingForm__information">
          <div className="VaultStakingForm__description">
            Enter the amount of FORT you wish to deposit to the vault.
          </div>
          <InformationBox.Container className="VaultStakingForm__infobox">
            <InformationBox.Title>Delegation Parameters</InformationBox.Title>
            <InformationBox.Group>
              <InformationBox.Item>
                <InformationBox.Label>Est. Annual Rewards</InformationBox.Label>
                <InformationBox.Value>
                  {apy != null ? `${apy}%` : <Skeleton width={50} />}
                </InformationBox.Value>
              </InformationBox.Item>
            </InformationBox.Group>
            <InformationBox.Group>
              <InformationBox.Item>
                <InformationBox.Label>Your Deposited FORT</InformationBox.Label>
                <InformationBox.Value>
                  {userAssets ? (
                    `${formatFORT(userAssets.toString())} FORT`
                  ) : (
                    <Skeleton width={100} />
                  )}{' '}
                </InformationBox.Value>
              </InformationBox.Item>
              <InformationBox.Item>
                <InformationBox.Label>
                  Total Deposited FORT
                </InformationBox.Label>
                <InformationBox.Value>
                  {totalAssets ? (
                    formatFORT(totalAssets.toString()) + ' FORT'
                  ) : (
                    <Skeleton width={100} />
                  )}{' '}
                </InformationBox.Value>
              </InformationBox.Item>
            </InformationBox.Group>
          </InformationBox.Container>
        </div>
        <div className="VaultStakingForm__form-container">
          <div className="VaultStakingForm__form">
            <DepositForm
              allowance={allowance}
              balance={balance}
              formSubmitButtonText="Deposit"
              formTitleText="Deposit"
              actionName="deposit"
              inputValue={inputValue}
              onInputValueChange={onInputValueChange}
              onApprove={handleApprove}
              onApproveDone={handleApproveDone}
              onDeposit={handleDeposit}
              onDepositDone={handleDepositDone}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
