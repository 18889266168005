import cn from 'classnames';

import './InputComponent.scss';
import { calcPadding } from '../utils';

export type InputComponentRenderProps = {
  marginLeft?: number;
  marginRight?: number;
  width: number; // how much space the component takes up; we need that info to add paddings to the input
  render: (props: { value: string; invalid: boolean }) => JSX.Element; // render any component
  noMouse?: boolean; // pointer-events: none;
};

type InputComponentProps = {
  position: 'right' | 'left';
  component: InputComponentRenderProps;
  previousComponents: InputComponentRenderProps[];
  inputState: {
    value: string;
    invalid: boolean;
  };
};

function InputComponent(props: InputComponentProps): JSX.Element {
  const { component, position, previousComponents, inputState } = props;

  const indent = calcPadding(
    previousComponents,
    position,
    position === 'left' ? component.marginLeft : component.marginRight
  );

  return (
    <span
      className={cn('InputComponent', {
        ['InputComponent--no-mouse']: component.noMouse
      })}
      style={{ [position]: indent }}
    >
      <span className="InputComponent__container">
        {component.render(inputState)}
      </span>
    </span>
  );
}

export default InputComponent;
