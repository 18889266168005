import { ethers } from 'ethers';

import ContractInteractor from './contract-interactor';

import config from 'common/config';
import ScannerPoolRegistryABI from '../../data/abis/scannerPoolRegistryABI.json';
import {
  GetScannerStateResponse,
  ScannerPoolRegistryContract
} from '../../data/abis/scanner-pool-registry-contract';

export default class ScannerPoolRegistry extends ContractInteractor<ScannerPoolRegistryContract> {
  constructor(
    provider: ethers.providers.JsonRpcProvider,
    contractAddress = config.scannerPoolRegistryAddress,
    signer?: ethers.providers.JsonRpcSigner
  ) {
    super(provider, ScannerPoolRegistryABI, contractAddress, signer);
  }

  public async getScannerState(
    scanner: string
  ): Promise<GetScannerStateResponse> {
    return await this.contract.getScannerState(scanner);
  }
}
