import { gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';

export interface NotificationListAPIParams {
  jwt: string;
}

export type NotificationSourceType = 'ADDRESS' | 'AGENT';
export type NotificationEndpointType =
  | 'EMAIL'
  | 'SLACK'
  | 'DISCORD'
  | 'TELEGRAM'
  | 'WEBHOOK';

export interface NotificationFilter {
  includeFilters: Filter[];
  excludeFilters: Filter[];
}
export interface Filter {
  matchType: 'all' | 'any';
  botId?: string;
  rules: FilterRule[];
}
export interface FilterRule {
  field: FilterField;
  type: 'field';
  operator: FilterRuleOperator;
  value: string;
}

type FilterRuleOperator =
  | 'equals'
  | 'contains'
  | 'containsIgnoreCase'
  | 'containsAny'
  | 'containsAnyIgnoreCase'
  | 'regex'
  | 'greaterThanOrEquals';
type FilterField =
  | 'alertId'
  | 'severity'
  | 'addresses'
  | 'description'
  | 'name'
  | 'chainId'
  | 'confirmations'
  | 'confirmationPercentage';

export const mainNets: { [chainId: number]: string } = {
  1: 'Ethereum',
  10: 'Optimism',
  56: 'BSC',
  137: 'Polygon',
  250: 'Fantom',
  43114: 'Avalanche C-Chain',
  42161: 'Arbitrum'
};

export type SubscriptionType = 'general' | 'scam_feed' | 'attack_feed';

export interface Notification {
  id: string;
  ownerAddress: string;
  source: {
    subscription_type: SubscriptionType;
    type: NotificationSourceType;
    value: string;
    name: string;
  };
  endpoint: {
    type: NotificationEndpointType;
    value: string;
  };
  filter?: NotificationFilter;
  groupIds?: string[];
}

export interface Email {
  email: string;
  isVerified: boolean;
}

export interface BotGroup {
  id: string;
  groupId: string;
  name: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
  bots: { id: string; name: string }[];
}

export async function getNotificationList(
  params: NotificationListAPIParams
): Promise<Notification[]> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getNotificationListInput: params
    },
    query: gql`
      query Retrive($getNotificationListInput: GetNotificationListInput) {
        getNotificationList(input: $getNotificationListInput) {
          id
          ownerAddress
          source {
            type
            value
            name
          }
          endpoint {
            type
            value
          }
          filter {
            includeFilters {
              matchType
              botId
              rules {
                field
                type
                operator
                value
              }
            }
          }
          groupIds
        }
      }
    `
  });
  const notifications: Notification[] = apiResult.data.getNotificationList;
  return notifications;
}

export interface AddNotificationParams {
  notificationSourceType: string;
  notificationSourceValue: string;
  notificationEndpointType: string;
  notificationEndpointValue: string;
  notificationFilter?: NotificationFilter;
  groupIds?: string[];
  jwt: string;
}

export async function addNotification(
  params: AddNotificationParams
): Promise<{ success: boolean; error: string }> {
  const client = getExplorerAPIClient();
  const apiResult = await client.mutate({
    variables: {
      addNotificationInput: params
    },
    mutation: gql`
      mutation Update($addNotificationInput: AddNotificationInput) {
        addNotification(input: $addNotificationInput) {
          success
          error
        }
      }
    `
  });
  return apiResult.data.addNotification;
}
export interface UpdateNotificationParams {
  notificationId: number;
  notificationSourceType: string;
  notificationSourceValue: string;
  notificationEndpointType: string;
  notificationEndpointValue: string;
  notificationFilter?: NotificationFilter;
  groupIds?: string[];
  jwt: string;
}

export async function updateNotification(
  params: UpdateNotificationParams
): Promise<{ success: boolean; error: string }> {
  const client = getExplorerAPIClient();
  const apiResult = await client.mutate({
    variables: {
      updateNotificationInput: params
    },
    mutation: gql`
      mutation Update($updateNotificationInput: UpdateNotificationInput) {
        updateNotification(input: $updateNotificationInput) {
          success
          error
        }
      }
    `
  });
  return apiResult.data.updateNotification;
}

export interface DeleteNotificationInput {
  notificationId: string;
  jwt: string;
}

export async function deleteNotification(
  params: DeleteNotificationInput
): Promise<{ success: boolean; error: string }> {
  const client = getExplorerAPIClient();
  const apiResult = await client.mutate({
    variables: {
      deleteNotificationInput: params
    },
    mutation: gql`
      mutation Update($deleteNotificationInput: DeleteNotificationInput) {
        deleteNotification(input: $deleteNotificationInput) {
          success
          error
        }
      }
    `
  });
  return apiResult.data.deleteNotification;
}

export interface GetEmailsInput {
  jwt: string;
}

export async function getEmails(params: GetEmailsInput): Promise<Email[]> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {
      getEmailsInput: params
    },
    query: gql`
      query Retrive($getEmailsInput: GetEmailsInput) {
        getEmails(input: $getEmailsInput) {
          email
          isVerified
        }
      }
    `
  });
  const emails: Email[] = apiResult.data.getEmails;
  return emails;
}

export interface MutateEmailInput {
  jwt: string;
  email: string;
}

export async function triggerEmailVerification(
  params: MutateEmailInput
): Promise<{ success: boolean; error: string }> {
  const client = getExplorerAPIClient();
  const apiResult = await client.mutate({
    variables: {
      triggerEmailVerificationInput: params
    },
    mutation: gql`
      mutation Update(
        $triggerEmailVerificationInput: TriggerEmailVerificationInput
      ) {
        triggerEmailVerification(input: $triggerEmailVerificationInput) {
          success
          error
        }
      }
    `
  });
  return apiResult.data.triggerEmailVerification;
}

export async function deleteEmail(
  params: MutateEmailInput
): Promise<{ success: boolean; error: string }> {
  const client = getExplorerAPIClient();
  const apiResult = await client.mutate({
    variables: {
      deleteEmailInput: params
    },
    mutation: gql`
      mutation Update($deleteEmailInput: DeleteEmailInput) {
        deleteEmail(input: $deleteEmailInput) {
          success
          error
        }
      }
    `
  });
  return apiResult.data.deleteEmail;
}

export async function getBotGroups(): Promise<BotGroup[]> {
  const client = getExplorerAPIClient();
  const apiResult = await client.query({
    variables: {},
    query: gql`
      query Retrive {
        getBotGroups {
          id
          groupId
          name
          owner
          createdAt
          updatedAt
          bots {
            id
            name
          }
        }
      }
    `
  });
  const botGroups: BotGroup[] = apiResult.data.getBotGroups;
  return botGroups;
}
