import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import config from 'common/config';

export const getExplorerAPIClient = (): ApolloClient<NormalizedCacheObject> => {
  const client = new ApolloClient({
    uri: config.apiEndpoint,
    cache: new InMemoryCache()
  });

  return client;
};
