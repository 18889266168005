import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import {
  CHART_INTERVAL,
  CHART_INTERVAL_OPTIONS
} from 'common/components-v2/Chart/constants';
import useTimeSeries, {
  boundsFromNow
} from 'common/components-v2/Chart/useTimeSeries';
import { formatEther } from 'ethers/lib/utils';
import FancyContainer from 'common/components-v2/Markup/FancyContainer/FancyContainer';
import ChartTabs from 'common/components-v2/Chart/Tabs/Tabs';
import LineChart from 'common/components-v2/Chart/Line/LineChart';
import { formatNumber } from 'forta-app/lib/utils';
import { AccountStakeQueryResult } from 'common/hooks/useAccountStakeQuery';

enum StakingChartTab {
  Total = 'total',
  Bots = 'bots',
  Pools = 'pools',
  Delegations = 'delegations'
}

const STAKING_CHART_OPTIONS = [
  { label: 'Total', value: StakingChartTab.Total },
  { label: 'Bots', value: StakingChartTab.Bots },
  { label: 'Pools', value: StakingChartTab.Pools },
  { label: 'Delegations', value: StakingChartTab.Delegations }
];

type StakingChartProps = {
  stake?: AccountStakeQueryResult;
  loading?: boolean;
  className?: string;
};

function StakingChart(props: StakingChartProps): JSX.Element {
  const { stake, loading, className } = props;

  const [interval, setInterval] = useState(CHART_INTERVAL.OneMonth);
  const [chartType, setChartType] = useState<StakingChartTab>(
    StakingChartTab.Total
  );

  const records = useMemo(() => stake?.history[chartType], [stake, chartType]);

  const seriesData = useTimeSeries(records, {
    xAccessor: 'timestamp',
    yAccessor: 'amount',
    cumulativeSum: true,
    bounds: boundsFromNow({ interval }),
    prepare: (d) => ({
      ...d,
      amount: Number(formatEther(d.amount.toString(10)))
    }),
    deps: [interval]
  });

  return (
    <FancyContainer variant="gradient" className={className}>
      <FancyContainer.Title>Staking</FancyContainer.Title>
      <FancyContainer.Filters>
        <ChartTabs
          separator
          name="chart-type"
          value={chartType}
          items={STAKING_CHART_OPTIONS}
          onChange={(e) => setChartType(e.target.value)}
        />
        <ChartTabs
          name="interval"
          value={interval}
          items={CHART_INTERVAL_OPTIONS}
          onChange={(e) => setInterval(e.target.value)}
        />
      </FancyContainer.Filters>
      <FancyContainer.FullWidth>
        <LineChart
          type="time"
          data={seriesData}
          xAccessor="timestamp"
          loading={loading}
          lines={[
            {
              label: 'Stake',
              yAccessor: 'amount',
              formatter: (v) => `${formatNumber(v)} FORT`
            }
          ]}
          tooltipTitle={(v) => dayjs.unix(Number(v)).format('MMM D, YYYY')}
        />
      </FancyContainer.FullWidth>
    </FancyContainer>
  );
}

export default StakingChart;
