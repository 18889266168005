import { useMemo } from 'react';

import { DocumentationItem } from 'forta-app/components/agent-management/BotEditForm';

// {
//   documentation: "[
//     { title: 'General', ipfsUrl: 'ipfs://...' /*.. future props */ },
//     { title: 'API Examples', ipfsUrl: 'ipfs://...' }
//   ]"
// }
function useDocumentationItems<T extends DocumentationItem>(
  documentation?: string
): T[] {
  return useMemo(() => {
    if (!documentation) return [] as T[];

    // check if serialized JSON
    if (documentation.indexOf('[') === 0) {
      try {
        return JSON.parse(documentation) as T[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.error('Cannot parse documentation object', documentation);
        console.error(e);
        return [];
      }
    }

    return [
      {
        title: 'General',
        ipfsHash: documentation
      }
    ] as T[];
  }, [documentation]);
}

export default useDocumentationItems;
