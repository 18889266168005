import { ReactNode } from 'react';
import cn from 'classnames';

import './Link.scss';
import BaseButton from './BaseButton';
import { ArrowRight2Icon } from '../../components/Icons';

type LinkProps = {
  children: ReactNode;
  to?: string;
  href?: string;
  showArrow?: boolean;
  target?: '_blank' | '_self' | '_parent';
  onClick?: () => unknown;
  className?: string;
};

function Link(props: LinkProps): JSX.Element {
  const { children, to, href, target, showArrow, onClick, className } = props;

  return (
    <BaseButton
      to={to}
      href={href}
      target={target}
      onClick={onClick}
      className={cn('Link', className)}
    >
      {children}
      <span className="Link__arrow-icon">{showArrow && ArrowRight2Icon}</span>
    </BaseButton>
  );
}

export default Link;
