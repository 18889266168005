import React from 'react';
import cn from 'classnames';

import './VaultStatus.scss';
import { StatusDot } from '../../DelegationsTable';

export enum VaultStatus {
  ACTIVE_DEPOSIT = 'active-deposit',
  WAITING_FOR_DEPOSIT = 'waiting-for-deposit',
  WITHDRAWAL_IN_PROCESS = 'withdrawal-in-process',
  ASSETS_CLAIMABLE = 'assets-claimable'
}

type VaultStatusEntityProps = {
  status: VaultStatus;
  className?: string;
};

function VaultStatusEntity(props: VaultStatusEntityProps): JSX.Element {
  const { status, className } = props;

  return (
    <span className={cn('VaultStatus', className)}>
      {(() => {
        switch (status) {
          case VaultStatus.ACTIVE_DEPOSIT:
            return (
              <>
                <StatusDot color="lime" /> Active deposit
              </>
            );
          case VaultStatus.WAITING_FOR_DEPOSIT:
            return (
              <>
                <StatusDot color="yellow" /> Waiting for deposit
              </>
            );
          case VaultStatus.WITHDRAWAL_IN_PROCESS:
            return (
              <>
                <StatusDot color="yellow" /> Withdrawal in process
              </>
            );
          case VaultStatus.ASSETS_CLAIMABLE:
            return (
              <>
                <StatusDot color="lime" /> Assets claimable
              </>
            );
        }
      })()}
    </span>
  );
}

export default VaultStatusEntity;
