import React from 'react';
import cn from 'classnames';

import './Button.scss';

import Spinner from '../Spinner/Spinner';
import BaseButton, { BaseButtonProps } from './BaseButton';

export type ButtonProps = BaseButtonProps & {
  variant:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'tertiary'
    | 'tertiary-primary'
    | 'light-outline';
  size: 'sm' | 'md' | 'lg';
  icon?: JSX.Element;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  fluid?: boolean;
  round?: boolean;
  loading?: boolean;
  loadingPosition?: 'start' | 'end';
  className?: string;
  testId?: string;
  children?: React.ReactNode;
};

const Button = React.forwardRef<HTMLElement, ButtonProps>((props, ref) => {
  const {
    variant,
    size,
    icon,
    startIcon,
    loading,
    loadingPosition = 'end',
    fluid,
    round,
    children,
    className,
    testId,
    ...baseProps
  } = props;

  let endIcon = props.endIcon;
  if (icon && !endIcon) {
    endIcon = icon;
  }

  const classNames = cn(
    'Button',
    'Button--' + variant,
    'Button--' + size,
    {
      'Button--fluid': fluid,
      'Button--round': round,
      'Button--icon': children == null && (startIcon || endIcon)
    },
    className
  );

  return (
    <BaseButton ref={ref} testId={testId} className={classNames} {...baseProps}>
      {loading && loadingPosition === 'start' && (
        <Spinner
          size="sm"
          variant="dual-ring"
          color="inherit"
          className="Button__spinner"
        />
      )}
      {startIcon && !(loading && loadingPosition === 'start') && (
        <div className="Button__icon">{startIcon}</div>
      )}
      {children != null && <div className="Button__content">{children}</div>}
      {endIcon && !(loading && loadingPosition === 'end') && (
        <div className="Button__icon">{endIcon}</div>
      )}
      {loading && loadingPosition === 'end' && (
        <Spinner
          size="sm"
          variant="dual-ring"
          color="inherit"
          className="Button__spinner"
        />
      )}
    </BaseButton>
  );
});

Button.displayName = 'Button';

export default Button;
