import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

import './AlertRow.scss';
import { SetChainIcon } from '../AlertsContainer/AlertsTable/Row/AlertRow';

import MetadataContainer from 'common/components-v2/Metadata/Container';
import TableCell from 'common/components-v2/Table/TableCell';
import TableSubCell from 'common/components-v2/Table/TableSubCell';
import TableExpandableRow from 'common/components-v2/Table/TableExpandableRow';
import Button from 'common/components-v2/Button/Button';
import Severity from 'common/components-v2/Entities/Severity/Severity';
import { Alert, getTimestampFromAlert } from 'common/lib/apis/alertAPI';
import { getNetworkByChainId, NetworkInfo } from 'common/lib/networks';
import { Routes } from 'common/routes';
import useScannerAlertsQuery from 'common/hooks/useScannerAlertsQuery';
import { parseMetadata } from 'common/lib/utils';
import { MuteIcon } from 'common/components/Icons';

type AlertRowProps = {
  alert: Alert;
  mutable: boolean;
  expanded?: boolean;
  onMute: () => void;
  onExpand: () => unknown;
  className?: string;
};

function AlertRowV2(props: AlertRowProps): JSX.Element {
  const {
    alert,
    expanded = false,
    mutable,
    onMute,
    onExpand,
    className
  } = props;

  const isExternal = alert.alert_document_type === 'API';
  const network: NetworkInfo = getNetworkByChainId(
    alert.source?.block?.chain_id ||
      alert.chain_id ||
      (alert.source.chains?.length === 1 && alert.source.chains[0].chainId) ||
      0
  );

  // Expand description logic
  const descriptionElRef = useRef<HTMLButtonElement>(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [rowHeight, setRowHeight] = useState<undefined | number>(undefined);

  // Metadata logic
  const [isMetadataQueryEnabled, setIsMetadataQueryEnabled] = useState(false);
  const { loading, scannerAlerts } = useScannerAlertsQuery({
    params: { hash: alert.hash },
    enabled: isMetadataQueryEnabled
  });
  const currentScanner = scannerAlerts[0];

  // Enable query once it has been expanded and do not disable it afterward
  useLayoutEffect(() => {
    if (expanded && !isExternal) {
      setIsMetadataQueryEnabled(true);
    }
  }, [isExternal, expanded]);

  const metadata = useMemo(() => {
    if (isExternal) return parseMetadata(alert.metadata);
    if (!currentScanner) return {};

    return parseMetadata(currentScanner.metadata);
  }, [currentScanner, isExternal, alert]);

  function longDescriptionToggle(): void {
    if (!descriptionElRef.current) return;

    if (isDescriptionExpanded) {
      setIsDescriptionExpanded(false);
      setRowHeight(undefined);
    } else {
      setIsDescriptionExpanded(true);
      setRowHeight(Math.min(120, descriptionElRef.current.scrollHeight + 24));
    }
  }

  return (
    <TableExpandableRow
      size="md"
      expanded={expanded}
      content={
        <div className="AlertRowV2__details">
          <div className="AlertRowV2__metadata">
            <MetadataContainer
              loading={loading}
              value={metadata}
              maxHeight={500}
              className="AlertRowV2__metadata-container"
            />
          </div>
        </div>
      }
      style={{ '--row-height': rowHeight ? rowHeight + 'px' : undefined }}
      styles={{ Row: 'AlertRowV2__row' }}
      className={cn('AlertRowV2', className, {
        'AlertRowV2--long-description': isDescriptionExpanded
      })}
    >
      <TableCell className="AlertRowV2__cell">
        <TableSubCell
          value={<Severity severity={alert.severity} />}
          flex={1}
          className="AlertRowV2__sub-cell"
        />
        <TableSubCell
          value={
            <div className="AlertRowV2__name">
              <Link
                className="AlertRowV2__name-link"
                to={Routes.alerts.details.index({ id: alert.hash })}
              >
                {alert.name}
              </Link>
              {mutable && (
                <Button
                  size="sm"
                  startIcon={MuteIcon}
                  variant="tertiary"
                  onClick={onMute}
                  className="AlertRowV2__name-mute-button"
                />
              )}
            </div>
          }
          flex={3}
          className="AlertRowV2__sub-cell"
        />
        <TableSubCell
          scroll
          value={
            <button
              ref={descriptionElRef}
              className="AlertRowV2__expand-description-button"
              onClick={longDescriptionToggle}
            >
              {alert.description}
            </button>
          }
          flex={5}
          className="AlertRowV2__sub-cell"
        />
        <TableSubCell
          ellipsis
          value={alert.scanner_count}
          flex={1}
          className="AlertRowV2__sub-cell"
        />
        <TableSubCell
          ellipsis
          value={moment(getTimestampFromAlert(alert)).fromNow()}
          flex={1.4}
          className="AlertRowV2__sub-cell"
        />
        <TableSubCell
          alignment="right"
          value={
            <div className="AlertRowV2__actions">
              <Button
                variant="default"
                size="sm"
                className="AlertRowV2__details-button"
                onClick={onExpand}
              >
                {expanded ? 'Collapse' : 'Details'}
              </Button>
              <SetChainIcon alert={alert} network={network} />
            </div>
          }
          className="AlertRowV2__actions-cell AlertRowV2__sub-cell"
          flex={1.7}
        />
      </TableCell>
    </TableExpandableRow>
  );
}

export default AlertRowV2;
