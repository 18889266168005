import React, { useLayoutEffect, useState } from 'react';
import { ethers } from 'ethers';
import cn from 'classnames';
import queryString from 'query-string';

import { Agent } from 'common/lib/apis/agentAPI';
import { TransactionResponse } from '@ethersproject/providers';
import BotEditForm, {
  BotFormData,
  DocumentationItem
} from 'forta-app/components/agent-management/BotEditForm';
import useDocumentationItems from 'forta-app/pages/bot/Documentation/useDocumentationItems';
import BotDeploymentPreview from 'forta-app/components/agent-management/BotDeploymentPreview';
import config from 'common/config';
import MessageBox from 'common/components/MessageBox';
import useWallet from 'common/hooks/useWallet';
import { BotType } from 'common/enums';

export const DEFAULT_BOT_TYPE: BotType = BotType.V2;

export function BotDeploymentContent({
  agent,
  onDeploy,
  onStepChange,
  className
}: {
  agent?: Agent;
  onDeploy?: (date: number, tx: TransactionResponse) => void;
  onStepChange?: (step: string) => unknown;
  className?: string;
}): JSX.Element {
  const { jwt, web3React } = useWallet();

  const searchQuery = queryString.parse(window.location.search, {
    arrayFormat: 'none'
  });
  const imageHashFromQuery =
    searchQuery['imagehash']?.length && searchQuery['imagehash'].toString();

  const documentationItems: DocumentationItem[] = useDocumentationItems(
    agent?.doc_ipfs
  );

  const [formData, setFormData] = useState<BotFormData>(() => {
    let botType = DEFAULT_BOT_TYPE;

    if (agent) {
      botType =
        agent.protocol_version === 2
          ? BotType.V2
          : agent.external
          ? BotType.External
          : BotType.V1;
    }

    return {
      id: agent?.id || ethers.utils.keccak256(ethers.utils.randomBytes(36)),
      name: agent?.name || '',
      description: agent?.description || '',
      longDescription: agent?.long_description || '',
      promoUrl: agent?.promo_url || '',
      licenseUrl: agent?.license_url || '',
      selectedNetworks:
        agent?.chainIds?.map((chainId) => Number(chainId)) || [],
      networksToShard:
        agent?.chainIds?.map((chainId) => Number(chainId)).toString() || '',
      projects: agent?.projects || [],
      version: agent?.version || '',
      repository: agent?.repository || '',
      image: agent?.image || imageHashFromQuery || '',
      documentationItems: documentationItems,
      botType: botType
    };
  });

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  // This is a quick fix to scroll the position when the step is changed.
  // In future versions, it is necessary to think in advance how the change of scroll position will be implemented.
  useLayoutEffect(() => {
    if (onStepChange) {
      onStepChange(isPreviewModalOpen ? 'preview' : 'form');
    } else {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviewModalOpen]);

  if (
    !web3React.isActive ||
    !web3React.account ||
    !jwt ||
    web3React.chainId !== config.chainId
  ) {
    return (
      <div
        data-testid="bot-deployment-login-message"
        className="AgentDeploymentPage__denied"
      >
        You need to log in to access this page
      </div>
    );
  }

  return (
    <div className={cn(className)}>
      {isPreviewModalOpen ? (
        <>
          <BotDeploymentPreview
            formData={formData}
            isUpdate={!!agent}
            onDeploy={onDeploy}
            onClose={() => setIsPreviewModalOpen(false)}
          />
          <div className="AgentDeploymentPage__message">
            <MessageBox title="Remember to stake 100 FORT">
              Deployed bots need a minimum stake of 100 FORT to operate. You
              will need to provide that amount in order to have your bot
              running.
              <br />
              Learn
              <a
                className="AgentDeploymentPage__message-link"
                href="https://docs.forta.network/en/latest/stake-on-detection-bot/"
                target="_blank"
                rel="noreferrer"
              >
                how to stake
              </a>
              .
            </MessageBox>
          </div>
        </>
      ) : (
        <BotEditForm
          formData={formData}
          onChange={setFormData}
          onSubmit={() => setIsPreviewModalOpen(true)}
        />
      )}
    </div>
  );
}

export default BotDeploymentContent;
