import React, { useState } from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import './BotData.scss';

import Tabs, { Tab } from '../../../../common/components-v2/Tabs/Tabs';
import BotAlerts from './Alerts/BotAlerts';
import BotLabels from './Labels/BotLabels';

enum BotDataTab {
  Alerts = 'alerts',
  Labels = 'labels'
}

type BotDataProps = {
  botId?: string;
  className?: string;
};

function BotData(props: BotDataProps): JSX.Element {
  const { botId, className } = props;

  const [currentTab, setCurrentTab] = useState(BotDataTab.Alerts);

  if (!botId) {
    return (
      <div className={cn('BotData', className)}>
        <Skeleton height={400} />
      </div>
    );
  }

  return (
    <div className={cn('BotData', className)}>
      <Tabs<BotDataTab>
        value={currentTab}
        onChange={(tab) => setCurrentTab(tab)}
        className="BotData__tabs"
      >
        <Tab label="Alerts" value={BotDataTab.Alerts} />
        <Tab label="Labels" value={BotDataTab.Labels} />
      </Tabs>
      {currentTab === BotDataTab.Alerts ? (
        <BotAlerts botId={botId} />
      ) : (
        <BotLabels botId={botId} />
      )}
    </div>
  );
}

export default BotData;
