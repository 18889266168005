import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import InfoPopover from 'common/components/InfoPopover';
import InlineInputModal from 'common/components/InlineInputModal';
import Input from 'common/components/Input';
import Modal from 'common/components/Modal';
import { CrossIcon, StarIcon } from 'common/components/Icons';
import ls, { FILTERS_LS_KEY } from 'common/lib/localStorage';

import './SavedFilters.scss';

interface SavedFilter {
  name: string;
  value: string;
}

const getFiltersFromLS = (): SavedFilter[] => {
  const filtersFromLSRaw: string = ls.getItem(FILTERS_LS_KEY) || '[]';
  let filtersFromLS: SavedFilter[] = [];
  try {
    const filtersFromLSParsed = JSON.parse(filtersFromLSRaw);
    if (Array.isArray(filtersFromLSParsed)) {
      filtersFromLS = filtersFromLSParsed
        .map((item: SavedFilter) => {
          return {
            name: item && item['name'],
            value: item && item['value']
          };
        })
        .filter((item) => item.name);
    }
  } catch (_error) {
    console.error('Error parsing JSON file');
  }
  return filtersFromLS;
};

export default function SavedFilters({
  onSelect
}: {
  onSelect: (value: string) => void;
}): JSX.Element {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [filters, setFilters] = useState<SavedFilter[]>([]);
  const [bookmarkFieldOpened, setBookmarkFieldOpened] =
    useState<boolean>(false);

  useEffect(() => setFilters(getFiltersFromLS()), []);

  const savedFiltersInLS = (_filters: SavedFilter[]): void =>
    ls.setItem(FILTERS_LS_KEY, JSON.stringify(_filters));

  const onSaveFilters = useCallback(
    (event?: React.FormEvent<HTMLElement>) => {
      event && event.preventDefault();
      savedFiltersInLS(filters);
      setModalOpened(false);
      toast.success('Bookmarks updated');
    },
    [filters]
  );

  const updateFilter = useCallback(
    (index, name, value) => {
      const newFilters = [...filters];
      newFilters[index] = { name, value };
      setFilters(newFilters);
    },
    [filters]
  );

  const onRemoveFilterClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
      const newFilters = [...filters];
      newFilters.splice(index, 1);
      setFilters(newFilters);
    },
    [filters]
  );

  const onCloseModal = useCallback(() => {
    setModalOpened(false);
    setFilters(getFiltersFromLS());
  }, []);

  const addFilter = useCallback(
    (name: string) => {
      const newFilters = [...filters, { name, value: window.location.search }];
      savedFiltersInLS(newFilters);
      setFilters(newFilters);
      toast.success(`"${name}" bookmark added`);
    },
    [filters]
  );

  const isBookmarkable =
    Object.values(
      queryString.parse(window.location.search, { arrayFormat: 'bracket' })
    ).some((value) => value) &&
    filters.findIndex(({ value }) => window.location.search === value) === -1;

  return (
    <div className="SavedFilters">
      <div className="SavedFilters__filters">
        {filters
          .filter(({ name }) => name)
          .map(({ name, value }) => {
            const classes = classNames({
              'SavedFilters__saved-filter': true,
              'SavedFilters__saved-filter--selected':
                window.location.search === value
            });
            return (
              <button
                className={classes}
                key={`custom-filter-button-${name}-${value}`}
                onClick={() => onSelect(value)}
              >
                {name}
              </button>
            );
          })}
      </div>
      <button
        className="SavedFilters__edit-button"
        onClick={() => setModalOpened(true)}
      >
        Edit bookmarks
      </button>
      {isBookmarkable ? (
        <InlineInputModal
          id="custom filter"
          title="Set bookmark name"
          submitText="Save bookmark"
          defaultValue={'My Filter'}
          opened={bookmarkFieldOpened}
          onOpenedChange={setBookmarkFieldOpened}
          onSubmit={addFilter}
          onValidate={(value: string) => value && ''}
        >
          <InfoPopover
            className="SavedFilters__popover"
            content="Bookmark current filters"
            rightPositioned
          >
            <button
              className="SavedFilters__bookmark"
              onClick={() => setBookmarkFieldOpened(true)}
            >
              {StarIcon}
            </button>
          </InfoPopover>
        </InlineInputModal>
      ) : null}
      <Modal
        opened={modalOpened}
        loading={false}
        title="Edit bookmarks"
        submitButtonText="Save"
        className="SavedFilters__modal"
        onCloseModal={onCloseModal}
        onSubmit={onSaveFilters}
      >
        <div className="SavedFilters__modal-content">
          <div className="SavedFilters__subtext">
            Here is the list of filtering configurations you&apos;ve bookmarked.
          </div>
          <div className="SavedFilters__form">
            <div className="SavedFilters__form-items">
              {filters.map(({ name, value }, index) => (
                <div key={`custom-filter-form-item-${index}`}>
                  <div className="SavedFilters__form-item">
                    <Input
                      className="SavedFilters__form-item__name"
                      placeholder="Filter name..."
                      value={name}
                      onChange={(newName) =>
                        updateFilter(index, newName, value)
                      }
                      name={`custom-filter-${name}`}
                    />
                    <button
                      className="SavedFilters__form-item__remove"
                      onClick={(event) => onRemoveFilterClick(event, index)}
                    >
                      {CrossIcon}
                    </button>
                  </div>
                  <span className="SavedFilters__form-item__value">
                    {value}
                  </span>
                </div>
              ))}
            </div>
            {!filters.length ? (
              <div className="SavedFilters__form-empty">No bookmarks.</div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}
