import EulerLogo from './images/projects/euler-logo.png';
import SushiSwapLogo from './images/projects/sushiswap-logo.png';
import AbracadabraLogo from './images/projects/abracadabra-logo.svg';
import HunderedFinanceLogo from './images/projects/hundered-finance-logo.png';
import BorderApeLogo from './images/projects/bored-ape-logo.png';
import GammaLogo from './images/projects/gamma-logo.jpg';

export const DetectedExploits = [
  {
    project: 'Euler Finance',
    description:
      'Forta’s monitoring was effective in detecting the largest attack of 2023 in advance, before $197 million were drained. ' +
      'Despite Euler Finance taking all the necessary precautions, including six audits and a bug bounty program, the protocol was still vulnerable to attacks, which reinforces the need for real-time threat detection. ',
    date: '03/13/2023',
    losses: '$197 Million',
    sourceName: 'Blog post',
    image: EulerLogo,
    sourceLink:
      'https://forta.org/blog/detecting-a-197-million-hack-before-exploitation-euler-finance-hack-retrospective/'
  },
  {
    project: 'SushiSwap',
    description: `Forta's Attack Detector V3 successfully flagged the $3.3M SushiSwap attack last night, identifying RouteProcessor2 as the compromised contract after several Forta alerts fired on suspicious contracts discovered by machine learning and Flashbots usage`,
    date: '04/09/2023',
    losses: '$3.3 Million',
    image: SushiSwapLogo,
    sourceName: 'Twitter',
    sourceLink: 'https://twitter.com/FortaNetwork/status/1645522583637426209'
  },
  {
    project: 'Abracadabra MIM',
    description:
      'Forta’s early detection systems alerted on the hack right after the first exploit, if ' +
      'they was subscribed to Forta alerts they could have saved 44% of stolen funds',
    date: '01/30/2024',
    losses: '$6.49 Million',
    image: AbracadabraLogo,
    sourceName: 'Twitter',
    sourceLink: 'https://twitter.com/FortaNetwork/status/1752794695275855935'
  },
  {
    project: 'Hundred Finance',
    description:
      "On April 14th, Hundred Finance was hacked via a flash loan attack, draining the protocol for $7M Forta's Attack Detector fired based off of alerts from Forta's flashloan, suspicious contract and Tornado Cash funding bots.",
    date: '04/14/2023',
    losses: '$7 Million',
    image: HunderedFinanceLogo,
    sourceName: 'Twitter',
    sourceLink: 'https://twitter.com/FortaNetwork/status/1648676990457618434'
  },
  {
    project: 'NFT Trader',
    description:
      'On December 16th, several prominent NFTs worth $3M+ were stolen from users. ' +
      'Forta’s Attack Detector flagged the malicious contract ahead of exploitation (and the attacker’s EOA two months ago), assets could have been saved.',
    date: '12/16/2023',
    losses: '$3 Million',
    image: BorderApeLogo,
    sourceName: 'Twitter',
    sourceLink: 'https://twitter.com/FortaNetwork/status/1737568044225093810'
  },
  {
    project: 'Gamma Strategies',
    description:
      'Forta’s Attack Detector fired halfway through the attack and could have saved most funds if the Gamma team had been subscribed to Forta threat intelligence',
    date: '01/04/2024',
    losses: '$4.6 Million',
    image: GammaLogo,
    sourceName: 'Twitter',
    sourceLink: 'https://twitter.com/FortaNetwork/status/1743028527719731481'
  }
];
