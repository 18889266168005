import { useCallback, useEffect, useMemo, useState } from 'react';
import { BigNumber, ethers } from 'ethers';

import useProvider from 'common/hooks/useProvider';
import config from 'common/config';
import FortaToken from 'forta-app/lib/contract-interactors-2/forta-token';

export type UseDepositFormProps = {
  walletAddress: string;
  contractAddress: string;
};

function useDepositForm(props: UseDepositFormProps): {
  inputValue: string;
  inputValueWei: ethers.BigNumber;
  balance: BigNumber | null;
  allowance: BigNumber;
  fetchBalance: () => Promise<void>;
  fetchAllowance: () => Promise<void>;
  onInputValueChange: (_value: string) => void;
} {
  const { contractAddress, walletAddress } = props;

  const [inputValue, setInputValue] = useState<string>('');
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0));
  const [balance, setBalance] = useState<BigNumber | null>(null);

  const inputValueWei = useMemo(() => {
    try {
      const valueEther = Number(inputValue.trim()) || '0';
      return ethers.utils.parseUnits(valueEther.toString());
    } catch (e) {
      console.error(e);
      return BigNumber.from(0);
    }
  }, [inputValue]);

  const provider = useProvider(config.chainId);

  const handleInputChange = (_value: string): void => {
    if (isNaN(Number(_value)) || Number(_value) < 0 || _value.includes(' '))
      return;
    setInputValue(_value);
  };

  const fortTokenContract = useMemo(
    () => new FortaToken({ provider }),
    [provider]
  );

  const fetchAllowance = useCallback(async (): Promise<void> => {
    const fortAllowance = await fortTokenContract.allowance(
      walletAddress,
      contractAddress
    );
    setAllowance(fortAllowance);
  }, [fortTokenContract, walletAddress, contractAddress]);

  const fetchBalance = useCallback(async (): Promise<void> => {
    const fortBalance = await fortTokenContract.balanceOf(walletAddress);
    setBalance(fortBalance);
  }, [fortTokenContract, walletAddress]);

  useEffect(() => {
    fetchAllowance();
  }, [fetchAllowance]);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  return {
    inputValue: inputValue,
    inputValueWei: inputValueWei,

    balance,
    allowance,

    fetchAllowance,
    fetchBalance,

    onInputValueChange: handleInputChange
  };
}

export default useDepositForm;
