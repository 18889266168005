export enum CssColor {
  Neutral10 = '#0f0f0f',
  Neutral15 = '#222222',
  Neutral20 = '#242627',
  Neutral30 = '#424448',
  Neutral40 = '#707070',
  Neutral50 = '#b6b6b6',
  Neutral70 = '#dadada',
  Neutral90 = '#e9e9e9',
  Neutral100 = '#fff',
  AccentPink10 = '#d82c55',
  AccentGreen40 = '#30f98d',
  AccentBlue30 = '#247dfb',
  AccentBlue40 = '#40abe9'
}

export enum CssFont {
  NeueHaasText = 'neuehaas',
  NeueHaasDisplay = 'neue-haas-display'
}
