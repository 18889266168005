import React, { useRef, useState } from 'react';

import Button from 'common/components-v2/Button/Button';
import { Plus2Icon } from 'common/components/Icons';
import Popover from 'common/components-v2/Popover/Popover';
import Menu from 'common/components-v2/Menu/Menu';
import { AlertFilterOption } from 'forta-app/slices/notificationSlice';

export type FilterPickerProps = {
  loading?: boolean;
  disabled?: boolean;
  onClick: (option: AlertFilterOption) => unknown;
};

export function FilterPicker(props: FilterPickerProps): JSX.Element {
  const { loading, disabled, onClick } = props;

  const buttonRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <span ref={containerRef}>
      <Button
        type="button"
        ref={buttonRef}
        variant="default"
        startIcon={Plus2Icon}
        disabled={disabled}
        loading={loading}
        loadingPosition="start"
        size="md"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        Add filters
      </Button>
      <Popover
        strategy="fixed"
        placement="bottom-start"
        preferredWidth={200}
        open={isExpanded}
        anchorElRef={buttonRef}
        containerElRef={containerRef}
        onClose={() => setIsExpanded(false)}
      >
        <Menu onClose={() => setIsExpanded(false)}>
          <Menu.Item onClick={() => onClick('severity')}>
            Min Severity
          </Menu.Item>
          <Menu.Item onClick={() => onClick('consensus')}>Consensus</Menu.Item>
          <Menu.Item onClick={() => onClick('chain')}>Chain</Menu.Item>
          <Menu.Item onClick={() => onClick('alertId')}>Alert ID</Menu.Item>
        </Menu>
      </Popover>
    </span>
  );
}

export default FilterPicker;
