import React, { useMemo } from 'react';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import 'github-markdown-css/github-markdown-dark.css';

import './Markdown.scss';
import HeadingRenderer from './HeadingRenderer';

type MarkdownProps = {
  children: string;
  trusted?: boolean;
  className?: string;
};

function Markdown(props: MarkdownProps): JSX.Element {
  const { children, trusted, className } = props;

  const rehypePlugins = useMemo(() => {
    if (!trusted) return [];
    return [rehypeRaw];
  }, [trusted]);

  return (
    <ReactMarkdown
      components={{
        h1: HeadingRenderer,
        h2: HeadingRenderer,
        h3: HeadingRenderer,
        h4: HeadingRenderer,
        h5: HeadingRenderer,
        h6: HeadingRenderer
      }}
      rehypePlugins={rehypePlugins}
      remarkPlugins={[remarkGfm]}
      className={cn('markdown-body', className)}
    >
      {children}
    </ReactMarkdown>
  );
}

export default Markdown;
