import { SubjectType } from '../contract-interactors/stakingContract';

// Define the transaction type constants
export enum TransactionType {
  BOT_CREATED = 'BOT_CREATED',
  BOT_UPDATED = 'BOT_UPDATED',
  BOT_ENABLED = 'BOT_ENABLED',
  BOT_DISABLED = 'BOT_DISABLED',
  NODEPOOL_CREATED = 'NODEPOOL_CREATED',
  NODEPOOL_NODE_REGISTERED = 'NODEPOOL_NODE_REGISTERED',
  NODEPOOL_COMISSION = 'NODEPOOL_COMISSION',
  STAKE_DEPOSIT = 'STAKE_DEPOSIT',
  STAKE_INITIATED_WITHDRAW = 'STAKE_INITIATED_WITHDRAW',
  STAKE_WITHDRAW = 'STAKE_WITHDRAW',
  NODEPOOL_ENABLE_SCANNER = 'NODEPOOL_ENABLE_SCANNER',
  NODEPOOL_DISABLE_SCANNER = 'NODEPOOL_DISABLE_SCANNER',
  CLAIM_REWARD = 'CLAIM_REWARD',
  ALLOCATE_OWN_STAKE = 'ALLOCATE_OWN_STAKE',
  ALLOCATE_DELEGATED_STAKE = 'ALLOCATE_DELEGATED_STAKE',
  UNALLOCATE_OWN_STAKE = 'UNALLOCATE_OWN_STAKE',
  UNALLOCATE_DELEGATED_STAKE = 'UNALLOCATE_DELEGATED_STAKE',
  PURCHASE_PLAN = 'PURCHASE_PLAN',
  RENEW_PLAN = 'RENEW_PLAN'
}

export const transactionTypeDetails: {
  [key: string]: {
    id: TransactionType;
    methodId: string;
    label: string;
    description: string;
  };
} = {
  [TransactionType.BOT_CREATED]: {
    id: TransactionType.BOT_CREATED,
    methodId: '0x7935d5b4',
    label: 'Bot Created',
    description: 'A new bot has been created'
  },
  [TransactionType.BOT_UPDATED]: {
    id: TransactionType.BOT_UPDATED,
    methodId: '0xaa9ac6c6',
    label: 'Bot Updated',
    description: 'A bot has been updated'
  },
  [TransactionType.BOT_ENABLED]: {
    id: TransactionType.BOT_ENABLED,
    methodId: '0xd0c6464b',
    label: 'Bot Enabled',
    description: 'A bot has been enabled'
  },
  [TransactionType.BOT_DISABLED]: {
    id: TransactionType.BOT_DISABLED,
    methodId: '0x21095d65',
    label: 'Bot Disabled',
    description: 'A bot has been disabled'
  },
  [TransactionType.NODEPOOL_CREATED]: {
    id: TransactionType.NODEPOOL_CREATED,
    methodId: '0x113f65c8',
    label: 'ScanNode Pool Created',
    description: 'A new scan node pool has been created'
  },
  [TransactionType.NODEPOOL_NODE_REGISTERED]: {
    id: TransactionType.NODEPOOL_NODE_REGISTERED,
    methodId: '0x7dfe7c42',
    label: 'ScanNode Registered',
    description: 'A scan node has been registered to a node pool'
  },
  [TransactionType.NODEPOOL_ENABLE_SCANNER]: {
    id: TransactionType.NODEPOOL_ENABLE_SCANNER,
    methodId: '0xa9048b57',
    label: 'ScanNode Enabled',
    description: 'A scan node has been enbaled'
  },
  [TransactionType.NODEPOOL_DISABLE_SCANNER]: {
    id: TransactionType.NODEPOOL_DISABLE_SCANNER,
    methodId: '0x408e35f0',
    label: 'ScanNode Disabled',
    description: 'A scan node has been disabled'
  },
  [TransactionType.NODEPOOL_COMISSION]: {
    id: TransactionType.NODEPOOL_COMISSION,
    methodId: '0x2210ea79',
    label: 'ScanNode Pool Commission',
    description: 'A commission has been paid from a scan node pool'
  },
  [TransactionType.CLAIM_REWARD]: {
    id: TransactionType.CLAIM_REWARD,
    methodId: '0x45a6544f',
    label: 'Reward claimed',
    description: 'A reward has been claimed'
  },
  [TransactionType.STAKE_DEPOSIT]: {
    id: TransactionType.STAKE_DEPOSIT,
    methodId: '0x2cb31144',
    label: 'Stake Deposit',
    description: 'A stake deposit has been made'
  },
  [TransactionType.STAKE_INITIATED_WITHDRAW]: {
    id: TransactionType.STAKE_INITIATED_WITHDRAW,
    methodId: '0xedea0bac',
    label: 'Stake Withdraw Initiated',
    description: 'A stake withdraw has been initiated'
  },
  [TransactionType.STAKE_WITHDRAW]: {
    id: TransactionType.STAKE_WITHDRAW,
    methodId: '0x3f489914',
    label: 'Stake Withdraw',
    description: 'A stake has been withdrawn'
  },
  [TransactionType.ALLOCATE_OWN_STAKE]: {
    id: TransactionType.ALLOCATE_OWN_STAKE,
    methodId: '0x499572af',
    label: 'Allocate own stake',
    description: 'Your stake has been allocated'
  },
  [TransactionType.UNALLOCATE_OWN_STAKE]: {
    id: TransactionType.UNALLOCATE_OWN_STAKE,
    methodId: '0x4edd0f24',
    label: 'Unallocate own stake',
    description: 'Your stake has been unallocated'
  },
  [TransactionType.ALLOCATE_DELEGATED_STAKE]: {
    id: TransactionType.ALLOCATE_DELEGATED_STAKE,
    methodId: '0xb18d6d53',
    label: 'Allocate delegated stake',
    description: 'A delegated stake has been allocated'
  },
  [TransactionType.UNALLOCATE_DELEGATED_STAKE]: {
    id: TransactionType.UNALLOCATE_DELEGATED_STAKE,
    methodId: '0xa14d787c',
    label: 'Unallocate delegated stake',
    description: 'A delegated stake has been unallocated'
  },
  [TransactionType.PURCHASE_PLAN]: {
    id: TransactionType.PURCHASE_PLAN,
    methodId: '0x33818997',
    label: 'Purchase plan',
    description: 'A plan has been purchased'
  },
  [TransactionType.RENEW_PLAN]: {
    id: TransactionType.RENEW_PLAN,
    methodId: '0x8505fe95',
    label: 'Renew plan',
    description: 'A plan has been renewed'
  }
};

export const subjectTypeDetails = {
  [SubjectType.BOT]: {
    id: SubjectType.BOT,
    label: 'Bot',
    description: 'A bot subject type'
  },
  [SubjectType.SCANNERPOOL]: {
    id: SubjectType.SCANNERPOOL,
    label: 'ScanNode Pool',
    description: 'A scan node pool subject type'
  },
  [SubjectType.SCANNERPOOL_DELEGATOR]: {
    id: SubjectType.SCANNERPOOL_DELEGATOR,
    label: 'ScanNode Pool Delegator',
    description: 'A scan node pool delegator subject type'
  }
};

// Define the ProtocolTransaction interface
export interface ProtocolTransaction {
  amount: string;
  token?: string;
  transactionType: TransactionType;
  subjectType?: SubjectType;
  subjectId?: string;
  timestamp: number;
  blockNumber: number;
  hash: string;
  confirmed: boolean;
}

// The main class for interacting with the local storage

type Subscription = (transactions: ProtocolTransaction[]) => void;

class ProtocolTransactionStorage {
  private storageKey: string;
  private subscriptions: [Subscription, number][] = [];

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  private save(transactions: ProtocolTransaction[]): void {
    const serializedTransactions = JSON.stringify(transactions);
    localStorage.setItem(this.storageKey, serializedTransactions);
    this.subscriptions.forEach(([subscription]) => subscription(transactions));
  }

  private loadAll(): ProtocolTransaction[] {
    const serializedTransactions = localStorage.getItem(this.storageKey);
    if (serializedTransactions === null) {
      return [];
    }

    const transactions: ProtocolTransaction[] = JSON.parse(
      serializedTransactions
    );
    return transactions;
  }

  load(blockNumber: number): ProtocolTransaction[] {
    const transactions = this.loadAll();
    return transactions.filter((tx) => tx.blockNumber > blockNumber);
  }

  addTransaction(transaction: ProtocolTransaction): void {
    const transactions = this.loadAll();
    transactions.push(transaction);
    this.save(transactions);
  }

  confirmTransaction(hash: string, blockNumber: number): void {
    const transactions = this.loadAll();
    const transactionIndex = transactions.findIndex((tx) => tx.hash === hash);

    if (transactionIndex !== -1) {
      transactions[transactionIndex].blockNumber = blockNumber;
      transactions[transactionIndex].confirmed = true;
      this.save(transactions);
    }
  }

  subscribe(subscription: Subscription): number {
    const subscriptionId = Date.now();
    this.subscriptions.push([subscription, subscriptionId]);
    return subscriptionId;
  }

  unsubscribe(subscriptionId: number): void {
    this.subscriptions = this.subscriptions.filter(
      ([, id]) => id !== subscriptionId
    );
  }
}

const BASE_STORAGE_KEY = `forta_app_protocol_transactions`;

const storages = new Map<string, ProtocolTransactionStorage>();

export function getProtocolTransactionStorage(
  address: string
): ProtocolTransactionStorage {
  const _address = address.toLowerCase();

  if (storages.has(_address)) {
    return storages.get(_address) as ProtocolTransactionStorage;
  }

  const storage = new ProtocolTransactionStorage(
    `${BASE_STORAGE_KEY}_${_address}`
  );
  storages.set(_address, storage);
  return storage;
}
