import classNames from 'classnames';
import ReactModal from 'react-modal';
import { useEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { CrossIcon } from 'common/components/Icons';
import Loader from './Loader';

import './Modal.scss';

interface ModalProps {
  opened: boolean;
  title?: string;
  submitButtonText?: string;
  onSubmit?: (event: React.FormEvent<HTMLElement>) => void;
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  onCloseModal?: () => void;
  shouldCloseOnOverlayClick?: boolean;
  hideCloseButton?: boolean;
  style?: ReactModal.Styles;
}

export default function Modal(props: ModalProps): JSX.Element {
  const {
    className,
    opened,
    onCloseModal,
    onSubmit,
    submitButtonText,
    title,
    children,
    shouldCloseOnOverlayClick,
    loading,
    hideCloseButton,
    style
  } = props;

  const [contentRef, setContentRef] = useState<HTMLDivElement>();

  const classes = classNames({
    Modal: true,
    'Modal--close-button-hidden': hideCloseButton,
    [className || '']: true
  });

  useEffect(() => {
    if (!contentRef) return;

    if (opened) {
      disableBodyScroll(contentRef, {
        reserveScrollBarGap: true
      });

      return () => enableBodyScroll(contentRef);
    }
  }, [opened, contentRef]);

  return (
    <ReactModal
      isOpen={opened}
      contentRef={setContentRef}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onCloseModal}
      className={classes}
      overlayClassName="Modal__overlay"
      contentLabel="Modal"
      bodyOpenClassName={null}
      htmlOpenClassName={null}
      style={style}
    >
      {loading ? (
        <div className="Modal__loader">
          <Loader />
        </div>
      ) : null}
      <div className="Modal__content">
        {!hideCloseButton ? (
          <button
            className="Modal__close-button"
            onClick={onCloseModal}
            aria-label="close modal"
          >
            {CrossIcon}
          </button>
        ) : null}
        <div className="Modal__header">
          <h2>{title}</h2>
        </div>
        <div className="Modal__children">{children}</div>
        {submitButtonText ? (
          <form className="Modal__form" onSubmit={onSubmit}>
            <div className="Modal__submit-button">
              <button type="submit">{submitButtonText}</button>
            </div>
          </form>
        ) : null}
      </div>
    </ReactModal>
  );
}
